/**
 * Error thrown when failed to refresh tokens due to network error
 * or other unknown error.
 * In this case we can retry refreshing tokens later.
 */
export default class RefreshTokensNetworkError extends Error {
  constructor(cause: unknown) {
    super(`Failed to refresh tokens, network error. Cause: {${cause}}`);
  }
}
