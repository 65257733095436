import cs from 'classnames';
import React from 'react';

import { Spacing } from '../../styles';
import { AddAttachmentButtonProps } from './types';
import IconButton from './Icon';

const AddAttachment = ({ variant, onPress }: AddAttachmentButtonProps) => {
  return (
    <IconButton
      testID="add-attachment-button"
      icon="AddAttachment"
      onPress={onPress}
      size={Spacing.small01}
      variant="darkGrey03"
      className={cs(
        'p-1',
        {
          rounded: 'bg-light-grey rounded-full',
          square: 'bg-off-white rounded-10',
        }[variant],
      )}
    />
  );
};

export default AddAttachment;
