import cs from 'classnames';
import React, { memo } from 'react';

import Env from '@advisor/api/env';
import { useTheme } from '@advisor/design/Theme';
import { LogoProps, OrganizationLogoProps } from './types';

const Logo: React.FC<LogoProps> = (props) => {
  const { type = 'platform', enforceDefault, fit = 'center' } = props;

  const theme = useTheme();

  const logo = (() => {
    if (theme.organizationLogo && !enforceDefault) {
      return <OrganizationLogo url={theme.organizationLogo} />;
    }

    if (type === 'organization') {
      return <DefaultOrganizationLogo />;
    }

    if (type === 'full') {
      return <DefaultFullLogo />;
    }

    return <DefaultPlatformLogo />;
  })();

  return (
    <div
      className={cs(
        'flex flex-col w-full h-full justify-center',
        fit === 'center' && 'items-center',
      )}
    >
      {logo}
    </div>
  );
};

export default memo(Logo);

/**
 * Partials
 */

const OrganizationLogo: React.FC<OrganizationLogoProps> = (props) => {
  const { url } = props;

  return (
    <img
      src={url}
      alt="Logo"
      className="object-contain max-w-full max-h-full"
    />
  );
};

const DefaultFullLogo: React.FC = () => (
  <div className="flex flex-col">
    <DefaultPlatformLogo className="w-full" />
    <DefaultOrganizationLogo className="w-[126px]" />
  </div>
);

const DefaultPlatformLogo: React.FC<{ className?: string }> = ({
  className,
}) => (
  <img
    src={Env.theme.platformLogo}
    alt={Env.productName}
    className={cs('block', className)}
  />
);

const DefaultOrganizationLogo: React.FC<{ className?: string }> = ({
  className,
}) => (
  <img
    alt={Env.organizationName}
    src={Env.theme.organizationLogo}
    className={cs('block', className)}
  />
);
