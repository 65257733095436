import { useMemo } from 'react';
import { ExtractAtomValue, useAtomValue } from 'jotai';

import { Lazy, nullAtom } from '@advisor/utils/atoms';
import { AutotranslationState, TranslationAtoms } from './types';

function useContentTranslation<A extends TranslationAtoms>(
  translationAtoms: A | null | undefined,
) {
  const { shouldTranslationBeDisabledAtom } = translationAtoms ?? {};

  const translationAtom = translationAtoms?.translationAtom
    ? Lazy.valueOr(translationAtoms?.translationAtom, null)
    : nullAtom;

  const statusAtom = translationAtoms?.statusAtom
    ? Lazy.valueOr(translationAtoms?.statusAtom, null)
    : nullAtom;

  const autotranslationStateAtom = translationAtoms?.autotranslationStateAtom
    ? Lazy.valueOr(translationAtoms?.autotranslationStateAtom, null)
    : nullAtom;

  const translation = useAtomValue(translationAtom);
  const status = useAtomValue(statusAtom);
  const autotranslationState =
    useAtomValue(autotranslationStateAtom) ?? AutotranslationState.Disabled;

  const isDisabled =
    useAtomValue(
      Lazy.valueOr(shouldTranslationBeDisabledAtom ?? nullAtom, null),
    ) ?? true;

  return useMemo(
    () => ({
      content: translation as
        | Awaited<ExtractAtomValue<A['translationAtom']>>
        | undefined,
      autotranslationState,
      status: status ?? { type: 'raw' as const },
      isDisabled,
    }),
    [translation, autotranslationState, status, isDisabled],
  );
}

export default useContentTranslation;
