import cs from 'classnames';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';

import { ChatRoomStatusInput } from '@advisor/api/generated/graphql';
import {
  Form,
  Modal,
  Layout,
  StatusIcon,
  DeprecatedButton,
} from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

import editStatusesAtom from '../editStatusesAtom';
import { NameInput } from '../components';
import { TabProps } from '../types';

const NewStatus: React.FC<TabProps> = ({ onClose, onGoBack }) => {
  const { t } = useTranslation('common');
  const dispatch = useSetAtom(editStatusesAtom);

  const [input, setInput] = useState<ChatRoomStatusInput>({
    color: '',
    name: '',
  });

  const updateInput = useCallback((update: Partial<ChatRoomStatusInput>) => {
    setInput((prev) => ({
      ...prev,
      ...update,
    }));
  }, []);

  const onSave = useCallback(async () => {
    await dispatch({ type: 'createStatus', input });
    onGoBack();
  }, [dispatch, input, onGoBack]);

  return (
    <>
      <Modal.Header
        title={t('add-new-status')}
        onBack={onGoBack}
        onClose={onClose}
      />
      {/* Form */}
      <div className="mx-8 my-6 p-4 rounded bg-light-grey border border-grey">
        {/* Form header */}
        <div className="flex items-center gap-3">
          <StatusIcon color={!input.color ? '#fff' : input.color} />
          <p
            className={cs(
              'font-sora text-sm leading-[1.125rem] font-bold',
              input.name === ''
                ? 'text-dark-grey-01 select-none'
                : 'text-dark-grey-03',
            )}
          >
            {input.name !== '' ? input.name : t('status-name')}
          </p>
        </div>
        <Layout.Spacer.Vertical size={Spacing.tiny} />

        {/* Name input */}
        <NameInput
          value={input.name}
          onSetValue={(name) => updateInput({ name })}
        />
        <Layout.Spacer.Vertical size={Spacing.micro02} />

        {/* Color input */}
        <Form.ColorInput
          value={input.color ?? '#FFFFFF'}
          onChange={(color) => updateInput({ color })}
        />
      </div>

      <Modal.Footer>
        <DeprecatedButton.Large
          variant="primary"
          disabled={!input.name}
          onPress={onSave}
        >
          {t('save')}
        </DeprecatedButton.Large>
      </Modal.Footer>
    </>
  );
};

export default NewStatus;
