import { molecule } from 'bunshi';

import { meAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { atomWithMutation } from '@advisor/api/apollo';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import {
  TranslationStatusType,
  TranslateMilestoneCategoryDocument,
  UpdateMilestoneCategoryTranslationStatusDocument,
} from '@advisor/api/generated/graphql';
import { LanguageCode } from '@advisor/language';
import { showToast } from '@advisor/design/components/Toast';
import {
  optimisticTranslationStatusFields,
  localTranslationStatusOverrideAtoms,
  makeContentTranslationAtoms,
  type TranslateOptimisticFields,
} from '@advisor/chat/contentTranslation';
import { chatRoomLanguageAtoms } from '@advisor/chat/chatLanguage';
import {
  milestoneCategoryAtoms,
  requireMilestoneCategoryScope,
} from '../milestoneCategory';
import { journeyTranslationStateAtoms } from './journeyTranslationStateAtoms';
import { MilestoneCategoryTranslations } from './types';

const translateMutationAtom = atomWithMutation(
  TranslateMilestoneCategoryDocument,
);

const makeTranslateContentAtom = (categoryId: string) => {
  return actionAtom(
    async (
      { set },
      _targetLanguage: LanguageCode,
      optimisticFields: TranslateOptimisticFields,
    ) => {
      try {
        await set(translateMutationAtom, {
          variables: {
            milestoneCategoryId: categoryId,
          },
          optimisticResponse: () => ({
            __typename: 'Mutation',
            translateMilestoneCategory: {
              __typename: 'MilestoneCategory',
              id: categoryId,
              ...optimisticFields,
            },
          }),
        });
      } catch (err) {
        Sentry.captureException(err);
        showToast({
          messageI18Key: 'something-went-wrong-try-again',
          variant: 'rose',
          iconName: 'X',
        });
        return false;
      }

      return true;
    },
  );
};

const updateStatusMutationAtom = atomWithMutation(
  UpdateMilestoneCategoryTranslationStatusDocument,
);

const makeUpdateStatusAtom = (id: string) =>
  actionAtom(async ({ get, set }, status: TranslationStatusType | null) => {
    const me = await get(meAtom);

    try {
      await set(updateStatusMutationAtom, {
        variables: {
          id,
          status,
        },
        optimisticResponse: me
          ? {
              __typename: 'Mutation',
              updateMilestoneCategoryTranslationStatus: {
                __typename: 'MilestoneCategory',
                ...optimisticTranslationStatusFields(me.id, id, status),
              },
            }
          : undefined,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  });

const makeUniqueKey = (categoryId: string) => `milestoneCategory_${categoryId}`;

const MilestoneCategoryTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const categoryId = requireMilestoneCategoryScope();

  const milestoneCategoryTranslationAtoms = makeContentTranslationAtoms({
    translationsSchema: MilestoneCategoryTranslations,
    targetLanguageAtom: chatRoomLanguageAtoms(chatRoomId),
    autotranslationStateAtom: journeyTranslationStateAtoms(chatRoomId),
    translatableAtom: milestoneCategoryAtoms(categoryId),
    statusOverrideAtom: localTranslationStatusOverrideAtoms(
      makeUniqueKey(categoryId),
    ),

    translateContentAtom: makeTranslateContentAtom(categoryId),
    updateStatusAtom: makeUpdateStatusAtom(categoryId),
  });

  return milestoneCategoryTranslationAtoms;
});

export default MilestoneCategoryTranslationMolecule;
