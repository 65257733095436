import { z } from 'zod';

import { UserMetadataLocation } from '@advisor/api/userMetadata';

export const MagicWandTooltipLocation = new UserMetadataLocation(
  'tooltip.magicWandButton',
  z.boolean(),
);

export const RefreshSuggestionTooltipLocation = new UserMetadataLocation(
  'tooltip.refreshSuggestion',
  z.boolean(),
);
