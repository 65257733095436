import { atomFamily } from 'jotai/utils';

import { UserInfoFragment } from '@advisor/api/generated/graphql';
import { lazyAtom } from '@advisor/utils/atoms';
import { meAtom } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { Scope } from '@advisor/api/scope';

const canKickFromChatRoomScopeAtom = atomFamily(
  ({ user, chatRoomId }: { user: UserInfoFragment; chatRoomId: string }) => {
    return lazyAtom(async (get) => {
      const me = await get(meAtom);
      const amILeadingAdvisor = await get(Scope.leadingAdvisor(chatRoomId));

      return (
        user.id !== me?.id && Role.isVerifiedAdvisor(user) && amILeadingAdvisor
      );
    });
  },
  (a, b) => a.chatRoomId === b.chatRoomId && a.user.id === b.user.id,
);

export default canKickFromChatRoomScopeAtom;
