import { useEffect, useMemo } from 'react';

import { useChatRoomQuery } from '@advisor/api/generated/graphql';
import { useConnectedParticipants } from '../../Participants';
import { useReflowLayout } from '../ParticipantTile';

interface UseParticipantsGridParams {
  tilesPerPage?: number;
}

// eslint-disable-next-line import/prefer-default-export
export function useChatMembersMap(chatId: string) {
  const { data } = useChatRoomQuery({
    variables: { chatRoomId: chatId },
  });

  return useMemo(() => {
    return data
      ? Object.fromEntries(
          data.chatRoom.members.map((member) => [member.member.id, member]),
        )
      : {};
  }, [data]);
}

export function useParticipantsGrid(params: UseParticipantsGridParams) {
  const { tilesPerPage = 8 } = params;

  const participants = useConnectedParticipants();

  const remoteParticipants = useMemo(
    () => participants.filter((p) => !p.session.isLocal),
    [participants],
  );

  const localParticipant = useMemo(
    () => participants.find((p) => p.session.isLocal),
    [participants],
  );

  const [participantsInGrid, corneredParticipant] = useMemo(() => {
    if (!localParticipant) {
      return [[], null];
    }

    if (remoteParticipants.length < 3) {
      return [remoteParticipants, localParticipant];
    }

    return [[localParticipant, ...remoteParticipants], null];
  }, [localParticipant, remoteParticipants]);

  const gridParticipantsOnPage = useMemo(() => {
    // TODO: Implement the activity algorithm to
    //       prioritize active speakers
    return participantsInGrid.slice(0, tilesPerPage);
  }, [participantsInGrid, tilesPerPage]);

  const rows = useMemo(() => {
    return participantsInGrid.length < 3 ? 1 : 2;
  }, [participantsInGrid]);

  const columns = useMemo(() => {
    return Math.ceil(participantsInGrid.length / rows);
  }, [participantsInGrid, rows]);

  const reflow = useReflowLayout();
  useEffect(() => {
    reflow();
  }, [rows, columns, reflow]);

  return { corneredParticipant, gridParticipantsOnPage, rows, columns };
}
