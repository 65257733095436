/* eslint-disable import/prefer-default-export */

export class BlockParticipantsTimeoutError extends Error {
  constructor() {
    super(
      'Could not ensure all requested participants have been blocked in a reasonable time frame.',
    );

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BlockParticipantsTimeoutError.prototype);
  }
}
