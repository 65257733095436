import { Role } from '@advisor/api/user';
import { meAtom } from '@advisor/api/me';
import { derive } from '@advisor/utils/atoms';
import { enabledFeaturesAtom } from '@advisor/api/feature';

const communityDirectoryAccessAtom = derive(
  [meAtom, enabledFeaturesAtom],
  (me, enabledFeatures) => {
    return (
      Role.isPrimaryAdvisor(me) &&
      !!enabledFeatures.microbots &&
      (!!enabledFeatures.microbotAstro ||
        !!enabledFeatures.microbotCarl ||
        !!enabledFeatures.microbotPam)
    );
  },
);

export default communityDirectoryAccessAtom;
