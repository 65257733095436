import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import screenWidthAtom from './screenWidthAtom';
import { ScreenBreakpoints, type ScreenBreakpoint } from './types';

/**
 * Cannot use media queries? Use this to efficiently determine whether a certain
 * screen width breakpoint has been reached or not from within JS.
 *
 * The `useScreenBreakpoint` hook gives access to this atom through React.
 */
const screenBreakpointAtoms = atomFamily((breakpoint: ScreenBreakpoint) => {
  return atom((get) => {
    const screenWidth = get(screenWidthAtom);

    return screenWidth >= ScreenBreakpoints[breakpoint];
  });
});

export default screenBreakpointAtoms;
