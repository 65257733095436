/*
 * ====================================
 * AUTO-GENERATED, DO NOT EDIT DIRECTLY
 * ====================================
 * To regenerate this file, run `yarn run translate` from
 * the `instances/` directory.
 */

/// <reference types="vite/client" />
const metadataLoaders = import.meta.glob('./**/metadata.json');

export default function getLoader(instanceKey: string) {
  return metadataLoaders[`./${instanceKey}/metadata.json`];
}
