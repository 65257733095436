import React from 'react';
import cs from 'classnames';
import { useAtom } from 'jotai';

import { ChatRoomWithMembersFragment } from '@advisor/api/generated/graphql';
import { Member } from '@advisor/api/chatRoom';
import { Avatar, Icon, Layout } from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import chatRoomStatusAtom from '../atoms/chatRoomStatus';

type SelectProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

type StudentRowProps = {
  chatRoom: ChatRoomWithMembersFragment;
};

export const Select: React.FC<SelectProps> = ({ value, onChange }) => {
  return (
    <button
      type="button"
      className="hover:opacity-80 transition-opacity"
      onClick={() => onChange(!value)}
    >
      <div
        className={cs(
          'rounded flex items-center justify-center w-6 h-6',
          value ? 'bg-primary' : 'border-2 border-grey',
        )}
      >
        {value && (
          <Icon name="Check" color={Colors.white} size={Spacing.small01} />
        )}
      </div>
    </button>
  );
};

export const StudentRow: React.FC<StudentRowProps> = ({ chatRoom }) => {
  const student = Member.student(chatRoom);
  const [state, setState] = useAtom(chatRoomStatusAtom);

  const onChangeValue = (value: boolean) => {
    setState((prev) => ({
      ...prev,
      [chatRoom.id]: value,
    }));
  };

  return (
    <div className="mx-8 py-4 flex flex-row items-center justify-between select-none border-b border-light-grey last:border-b-0">
      <div className="flex flex-row items-center">
        <Avatar
          avatarUrl={student?.avatarUrl}
          bordered={false}
          size={Spacing.small02}
        />
        <Layout.Spacer.Horizontal size="micro02" />
        <p className="font-sora text-dark-grey-03 text-sm leading-[1.125rem] font-bold">
          {chatRoom.name}
        </p>
      </div>
      <Select value={state[chatRoom.id]} onChange={onChangeValue} />
    </div>
  );
};
