import { Gender, IdentityType, UpdateUserMutation } from '../generated/graphql';

export default function getUpdateUserOptimisticResponse(
  replace: Partial<Omit<UpdateUserMutation['updateUser'], '__typename'>>,
): UpdateUserMutation {
  return {
    __typename: 'Mutation',
    updateUser: {
      id: '',
      __typename: 'User',
      name: '',
      country: '',
      avatarUrl: '',
      gender: Gender.Undefined,
      language: '',
      isEmailVerified: false,
      isPhoneNumberVerified: false,
      primaryIdentityType: IdentityType.Email,
      isBlocked: false,
      createdAt: '',
      updatedAt: '',
      lastLoginAt: '',
      phoneNumber: '',
      email: '',
      metadata: '{}',
      timezone: '',
      isPending: false,
      ...replace,
    },
  };
}
