import React from 'react';

import useUserColor from '@advisor/ui/hooks/useUserColor';
import { useMicrobotMessage } from '../../../messaging/messageMolecule';
import { AssistFooterLinkProps } from './types';

const AssistFooterLink: React.FC<AssistFooterLinkProps> = (props) => {
  const { url } = props;

  const { author } = useMicrobotMessage('required');
  const color = useUserColor(author);

  return (
    <div className="px-4 pb-2.5 truncate" style={{ color }}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        {url}
      </a>
    </div>
  );
};

export default AssistFooterLink;
