import dayjs from 'dayjs';
import { useMemo } from 'react';
import { forEachRight } from 'lodash-es';

import {
  SystemMessageType,
  MessageInfoFragment,
  LatestMessagesQuery,
  SystemMessageInfoFragment,
  ChatRoomMemberInfoFragment,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphql';
import { getCategoryId } from '../Message/SystemMessage/utils';
import { MessageBlock } from './types';

const MaxBlockLength = 10;
const MaxBlockMinuteDifference = 5;

const GroupableSystemMessageTypes = [
  SystemMessageType.CategoryEdited,
  SystemMessageType.MilestoneAdded,
  SystemMessageType.NewCommentAdded,
  SystemMessageType.MilestoneEdited,
  SystemMessageType.NewCategoryAdded,
  SystemMessageType.MilestoneMarkedCompleteByAdvisor,
  SystemMessageType.MilestoneMarkedCompleteByStudent,
  SystemMessageType.MilestoneMarkedIncompleteByAdvisor,
  SystemMessageType.MilestoneMarkedIncompleteByStudent,
];

function getEarliestMessage(block: MessageBlock) {
  return block.data[block.data.length - 1];
}

/*
  Append message to group:
    - If it is system message and:
      - sent within 5 minutes from first message in the group
      - if it is milestone related, is the milestone from the same category
*/

function shouldAppendMessage(
  lastBlock: MessageBlock | undefined,
  message: MessageInfoFragment,
): boolean {
  if (!lastBlock || lastBlock.type !== message.__typename) {
    return false;
  }

  // If it is a microbot message it shouldn't be appended
  if (message.__typename === 'MicrobotMessage') {
    return false;
  }

  const lastMessage = getEarliestMessage(lastBlock);
  const timeDifference = dayjs(lastMessage.sentAt).diff(
    message.sentAt,
    'minute',
    true,
  );

  // If it is user message check author id, time difference, block length
  // and if both messages have no associated microbot memories
  if (message.__typename === 'UserMessage') {
    return (
      message.author.id === lastBlock.authorId &&
      timeDifference < MaxBlockMinuteDifference &&
      lastBlock.data.length <= MaxBlockLength &&
      !message.microbotMemoryId &&
      !(lastBlock.data[0] as UserMessageInfoFragment).microbotMemoryId
    );
  }

  if (
    !GroupableSystemMessageTypes.includes(message.data.type) ||
    message.data.type !== (lastMessage as SystemMessageInfoFragment).data.type
  ) {
    return false;
  }

  // do not enforce same category id for new category and edited added events :)
  if (
    [
      SystemMessageType.NewCategoryAdded,
      SystemMessageType.CategoryEdited,
    ].includes(message.data.type)
  ) {
    return timeDifference < MaxBlockMinuteDifference;
  }

  const messageCategoryId = getCategoryId(message);

  const lastMessageCategoryId = getCategoryId(
    lastMessage as SystemMessageInfoFragment,
  );

  return (
    messageCategoryId === lastMessageCategoryId &&
    timeDifference < MaxBlockMinuteDifference
  );
}

function groupMessages(
  query: LatestMessagesQuery,
  readingChatMember: ChatRoomMemberInfoFragment,
) {
  const blocks: MessageBlock[] = [];
  // Determines if most recent block (of current member messages)
  // has already been found on the list
  let mostRecentFound = false;

  forEachRight(query.messages.edges, ({ node: message }) => {
    const lastBlock = blocks[blocks.length - 1];

    if (shouldAppendMessage(lastBlock, message)) {
      lastBlock.data.push(message);
      return;
    }

    const authorId =
      message.__typename === 'UserMessage' ? message.author.id : undefined;
    const isCurrentUser = authorId === readingChatMember.member.id;

    const isMostRecent = !mostRecentFound && isCurrentUser;

    if (isMostRecent) {
      mostRecentFound = true;
    }

    const newBlock: MessageBlock = {
      type: message.__typename,
      data: [message],
      authorId,
      showFooter: false,
      isMostRecent,
    };

    blocks.push(newBlock);

    if (lastBlock) {
      const lastBlockEarliestMessage = getEarliestMessage(lastBlock);

      lastBlock.showFooter =
        dayjs(lastBlockEarliestMessage?.sentAt)
          .startOf('day')
          .diff(dayjs(message.sentAt).startOf('day')) !== 0;
    }
  });

  if (blocks.length > 0) {
    blocks[blocks.length - 1].showFooter = true;
  }

  return blocks;
}

export default function useMessageBlocks(
  queryData?: LatestMessagesQuery,
  readingChatMember?: ChatRoomMemberInfoFragment,
) {
  return useMemo(() => {
    if (!readingChatMember || !queryData) {
      return [];
    }

    return groupMessages(queryData, readingChatMember);
  }, [queryData, readingChatMember]);
}
