import { Layout, Skeleton } from '@advisor/design/components';
import React from 'react';

const CommentsListLoader: React.FC = () => {
  return (
    <>
      <CommentSkeleton />
      <CommentSkeleton />
    </>
  );
};

const CommentSkeleton: React.FC = () => {
  return (
    <Skeleton.Ping>
      <Layout.Row className="mt-6 px-6 gap-3 items-start w-full min-w-0">
        <div className="rounded-full w-6 h-6 bg-grey" />
        <div className="flex-1 min-w-0 flex flex-col gap-4">
          <Layout.Row natural className="w-full gap-3">
            <div className="flex-1">
              <Skeleton.Text fontSize={14} lineHeight={24} size="large" />
            </div>
            <Skeleton.Text fontSize={12} lineHeight={16} size="small02" />
          </Layout.Row>
          <div>
            <Skeleton.Text fontSize={14} lineHeight={24} />
            <Skeleton.Text fontSize={14} lineHeight={24} />
            <Skeleton.Text fontSize={14} lineHeight={24} size="giant" />
          </div>
        </div>
      </Layout.Row>
    </Skeleton.Ping>
  );
};

export default CommentsListLoader;
