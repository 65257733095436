import React, { useCallback, useMemo } from 'react';
import type { ListRenderItem } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';

import { ChatRoomWithMembersFragment } from '@advisor/api/generated/graphql';
import { useChatRoomsByTypename } from '@advisor/api/chatRoom';
import {
  Layout,
  Loader,
  FlatList,
  SearchInput,
  DeprecatedButton,
} from '@advisor/design/components';
import { useIsMicrobotActive } from '../../../hooks';
import chatRoomStatusAtom from '../atoms/chatRoomStatus';
import searchAtom from '../atoms/searchAtom';
import { ConversationAccessProps } from './types';
import { StudentRow } from './components';

const ConversationAccess: React.FC<ConversationAccessProps> = ({
  anyRemoved,
  personality,
  setAll,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'microbot']);

  const isMicrobotActive = useIsMicrobotActive(personality);

  const statusAtom = useAtomValue(chatRoomStatusAtom);

  const [search, setSearch] = useAtom(searchAtom);
  const { chatRooms, hasMore, loading, loadMore } = useChatRoomsByTypename(
    'StudentChatRoom',
    search,
  );

  const anySelected = useMemo(
    () => Object.values(statusAtom).some((value) => value),
    [statusAtom],
  );

  const selectAll = !isMicrobotActive || !anySelected;

  const renderStudent: ListRenderItem<ChatRoomWithMembersFragment> =
    useCallback(({ item }) => {
      return <StudentRow chatRoom={item} />;
    }, []);

  const renderLoadingIndicator = useCallback(() => {
    if (!hasMore && !loading) {
      return null;
    }

    return (
      <div className="flex items-center justify-center">
        <Loader />
      </div>
    );
  }, [hasMore, loading]);

  return (
    <div className="py-8">
      {/* Title */}
      <div className="flex flex-row justify-between items-center mx-8">
        <h3 className="font-sora text-base leading-5 text-dark-grey-03 font-bold">
          {t('microbot:conversation-access')}
        </h3>
        <DeprecatedButton.Icon
          icon="X"
          onPress={onClose}
          variant="darkGrey01"
        />
      </div>
      <Layout.Spacer.Vertical size="small01" />

      <div className="px-8">
        {/* Search input */}
        <SearchInput
          className="text-sm leading-[0.875rem] ltr:pl-10 rtl:pr-10"
          value={search}
          onChange={(newValue) => setSearch(newValue)}
          placeholder={t('microbot:search-conversation')}
        />
        <Layout.Spacer.Vertical size="tiny" />

        {/* Select/deselect all button */}
        <button
          type="button"
          className="font-sora text-primary text-sm leading-[1.125rem] font-bold"
          onClick={() => setAll(selectAll)}
        >
          {selectAll ? t('common:select-all') : t('common:deselect-all')}
        </button>
        <Layout.Spacer.Vertical size="tiny" />
      </div>

      {/* Chat room list */}
      <FlatList
        className="max-h-[16.25rem] overflow-y-auto overflow-x-hidden"
        data={chatRooms}
        keyExtractor={(item) => item.id}
        renderItem={renderStudent}
        onEndReached={hasMore ? loadMore : null}
        ListFooterComponent={renderLoadingIndicator}
      />
      <Layout.Spacer.Vertical size="tiny" />

      <div className="px-8">
        <DeprecatedButton.Large
          className="w-full"
          label={anyRemoved ? t('microbot:save-changes') : t('microbot:invite')}
          onPress={() => onSave(statusAtom)}
          variant="primary"
        />
      </div>
    </div>
  );
};

export default ConversationAccess;
