import React, { memo } from 'react';
import cs from 'classnames';

import { StatusProps } from './types';

const Status = ({ className, color, active, transparentBody }: StatusProps) => {
  return (
    <div
      className={cs(
        className,
        !transparentBody && 'bg-current',
        'w-2 h-2 rotate-45',
        'after:block after:w-2 after:h-2 after:border after:transition-transform',
        active && 'after:origin-center after:scale-[1.6]',
      )}
      style={{ color }}
    />
  );
};

export default memo(Status);
