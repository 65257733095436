import cs from 'classnames';
import { pipe } from 'remeda';
import React, { CSSProperties } from 'react';

import type { ColumnProps } from './types';
import { withPadding } from '../../hoc';

function Column(props: ColumnProps) {
  const {
    children,
    reversed,
    items,
    justify = 'start',
    style,
    ...restProps
  } = props;

  return (
    <div
      {...restProps}
      style={style as CSSProperties}
      className={cs(
        'flex',
        reversed ? 'flex-col-reverse' : 'flex-col',
        {
          start: 'items-start',
          center: 'items-center',
          end: 'items-end',
          stretch: 'items-stretch',
        }[items],
        {
          start: 'justify-start',
          center: 'justify-center',
          end: 'justify-end',
        }[justify],
      )}
    >
      {children}
    </div>
  );
}

export default pipe(Column, withPadding());
