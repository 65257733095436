import { z } from 'zod';
import { ReactNode } from 'react';
import { type Dayjs } from 'dayjs';

import { MilestoneCategoryInfoFragment } from '@advisor/api/generated/graphql';
import { FormInputs } from '@advisor/design/components/Form/types';

function purgeTimeFromDate(dateTime: Date) {
  const date = new Date(dateTime);
  date.setHours(0, 0, 0);
  return date;
}

export type MilestoneFormData = z.infer<
  ReturnType<typeof getMilestoneFormSchema>
>;
export const getMilestoneFormSchema = (minDate: Date) =>
  z.object({
    name: z.string().min(1),
    description: z
      .string()
      // Making sure there are no empty strings, just undefined
      .transform((v) => (!v ? undefined : v)),
    dueDate: z
      .date()
      .optional()
      .transform((v) => (v ? purgeTimeFromDate(v) : undefined))
      .refine((v) => !v || v >= minDate),
  });

export interface AddMilestoneModalProps {
  ownerMilestoneCategory: MilestoneCategoryInfoFragment;
}

export enum ModalTab {
  Root,
  DatePicker,
  DeleteConsent,
}

export type MilestoneActionModalTabProps = {
  inputProps: FormInputs<ReturnType<typeof getMilestoneFormSchema>>;
  activeTab: ModalTab;

  onClose(): void;
};

export type AddTabProps = MilestoneActionModalTabProps & {
  onSubmit(): void;
  onOpenDatePicker(): void;
};

export type EditTabProps = MilestoneActionModalTabProps & {
  onSubmit(): void;
  onOpenDatePicker(): void;
  onOpenDeleteConsent(): void;
};

export type DatePickerTabProps = MilestoneActionModalTabProps & {
  chatRoomId: string;
  onOpenRoot(): void;
  minDueDate: Dayjs;
};

export type DeleteTabProps = {
  activeTab: ModalTab;
  onOpenRoot(): void;
  onDelete(): void;
};

export interface SwipeTransitionProps {
  show: boolean;
  direction: 'left' | 'right';
  children?: ReactNode;
}
