import React from 'react';
import cs from 'classnames';

import type { RadioIconProps } from './types';

const RadioIcon: React.FC<RadioIconProps> = ({ checked }) => {
  return (
    <div
      className={cs(
        'flex justify-center items-center w-6 h-6 box-content border-2 rounded-full',
        checked ? 'border-primary' : 'border-dark-grey-01',
      )}
    >
      {checked && <div className="w-3.5 h-3.5 rounded-full bg-primary" />}
    </div>
  );
};

export default RadioIcon;
