import type { File as GQLFile } from '../generated/graphql';
import { DataOrError, FileData, ResultData, ResultError } from './types';

export const isError = <T>(result: DataOrError<T>): result is ResultError => {
  return !result.ok;
};

export const isSuccessful = <T>(
  result: DataOrError<T>,
): result is ResultData<T> => {
  return !!result.ok;
};

const Megabyte = 1024 * 1024; // In B
export const toMegabytes = (n: number): number => n / Megabyte;

export const isFileInstance = (file: FileData | GQLFile): file is GQLFile =>
  '__typename' in file && file.__typename === 'File';

export const isFileDataInstance = (
  file: FileData | GQLFile,
): file is FileData =>
  ('uri' in file && 'type' in file) || file instanceof File;

export const isFileTooLarge = (file: FileData, size?: number) =>
  typeof size === 'number' && toMegabytes(file.size) > size;

export const toNullableFileData = (
  file: File | FileData | null | undefined,
): FileData | undefined => {
  if (!file) {
    return undefined;
  }

  if (isFileInstance(file)) {
    return {
      name: file.displayName,
      size: file.fileSize,
      uri: file.url,
      type: '',
    };
  }

  return file;
};

export class CancelError extends Error {
  constructor() {
    super('Upload canceled');
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  // https://stackoverflow.com/a/18650828/104380
  if (bytes <= 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
