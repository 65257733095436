import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Modal } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { MemoryBankInfoModalProps } from './types';

const MemoryBankInfoModal: React.FC<MemoryBankInfoModalProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation('microbot');

  return (
    <Modal.Base visible={visible} onClose={onClose} maxWidth={672}>
      {/* Header */}
      <div className="border-b border-grey px-8 py-6 flex flex-row justify-between">
        <div>
          <h3 className="font-sora font-semibold text-xl leading-6 text-dark-grey-03">
            {t('what-is-the-memory-bank')}
          </h3>
          <h5 className="font-outfit text-sm leading-[1.125rem] text-dark-grey-025">
            {t('a-guide')}
          </h5>
        </div>
        <DeprecatedButton.Icon
          icon="X"
          size={Spacing.small02}
          variant="darkGrey01"
          onPress={onClose}
        />
      </div>

      {/* Body */}
      <div className="px-8 pt-6 pb-10 space-y-5 font-outfit text-sm font-medium leading-4-5 text-dark-grey-02">
        <p>
          {t(
            'alpha-bot-memory-bank-stores-information-saved-by-hosts-during-conversations',
          )}
        </p>
        <p>
          {t(
            'it-serves-as-a-virtual-storage-mechanism-allowing-alpha-bot-to-access-and-recall-past-experiences',
          )}
        </p>
        <p>{t('to-contribute-to-alpha-bot-memory-short')}</p>
      </div>
    </Modal.Base>
  );
};

export default MemoryBankInfoModal;
