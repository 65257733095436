import { molecule, use } from 'bunshi';
import { atom, ExtractAtomArgs } from 'jotai';

import {
  makeContentTranslationAtoms,
  localTranslationStatusOverrideAtoms,
} from '@advisor/chat/contentTranslation';
import { chatRoomLanguageAtoms } from '@advisor/chat/chatLanguage';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import {
  makeUniqueKey,
  milestoneCommentAtoms,
  requireMilestoneCommentScope,
} from '../milestoneComments';
import { journeyTranslationStateAtoms } from './journeyTranslationStateAtoms';
import MilestoneTranslationMolecule from './milestoneTranslationMolecule';
import { CommentTranslations } from './types';

const MilestoneCommentTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const commentId = requireMilestoneCommentScope();
  const milestoneTranslationAtoms = use(MilestoneTranslationMolecule);

  const uniqueKey = makeUniqueKey(commentId);
  const localStatusOverrideAtom =
    localTranslationStatusOverrideAtoms(uniqueKey);

  const milestoneCommentTranslationAtoms = makeContentTranslationAtoms({
    translationsSchema: CommentTranslations,
    targetLanguageAtom: chatRoomLanguageAtoms(chatRoomId),
    autotranslationStateAtom: journeyTranslationStateAtoms(chatRoomId),
    translatableAtom: milestoneCommentAtoms(commentId),

    statusOverrideAtom: atom(
      async (get) => {
        const comment = get(milestoneCommentAtoms(commentId));

        if (!comment) {
          return null;
        }

        // Whenever the owner milestone is being translated, we also want the
        // comments to be translated.
        const status = await get(milestoneTranslationAtoms.statusAtom);
        const chatRoomLanguage = await get(chatRoomLanguageAtoms(chatRoomId));

        if (status?.type === 'translated') {
          return chatRoomLanguage;
        }

        return get(localStatusOverrideAtom);
      },
      (_get, set, ...args: ExtractAtomArgs<typeof localStatusOverrideAtom>) =>
        set(localStatusOverrideAtom, ...args),
    ),
    translateContentAtom: atom(null, async () => {
      /* noop */
    }),
    updateStatusAtom: atom(null, async () => {
      /* noop */
    }),
  });

  return milestoneCommentTranslationAtoms;
});

export default MilestoneCommentTranslationMolecule;
