import React from 'react';
import cs from 'classnames';

import { UserRoleSelectDropdownProps } from '../types';

const UserRoleSelectDropdown: React.FC<UserRoleSelectDropdownProps> = ({
  children,
  upwards = false,
}) => (
  <div className={cs('absolute py-3', upwards ? 'bottom-full' : 'top-full')}>
    <ul className="border border-dark-grey-01 rounded relative bg-white z-10 flex flex-col py-3 empty:border-0">
      {children}
    </ul>
  </div>
);

export default UserRoleSelectDropdown;
