import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DeprecatedButton, Icon, Layout } from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import { TooltipContentProps } from './types';

const TooltipContent: React.FC<TooltipContentProps> = ({ onTry }) => {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <div className="p-2">
      <div className="flex flex-row justify-between">
        <h1 className="font-sora text-sm font-bold leading-[1.125rem] text-dark-grey-03 max-w-[8.5rem]">
          <Trans t={t} i18nKey="microbot:tap-to-save-to-alpha-bot-memory">
            {/* eslint-disable-next-line prettier/prettier */}
            Tap to{' '}
            <span className="text-astro-dark">Save to Cosmo's Memory</span>
          </Trans>
        </h1>
        <Icon
          name="AddMemory"
          color={Colors.astroDark}
          size={Spacing.small01}
        />
      </div>

      <Layout.Spacer.Vertical size="micro02" />

      <p className="font-outfit text-sm text-dark-grey-03 font-medium">
        {t(
          'microbot:add-important-facts-or-information-you-want-alpha-bot-memory-bank',
        )}
      </p>

      <Layout.Spacer.Vertical size="micro02" />

      <Layout.Row natural>
        <DeprecatedButton.Outline
          className="text-sm leading-[0.875rem] !px-10 !py-[0.875rem]"
          label={t('common:try-it')}
          onPress={onTry}
          variant="darkGrey03"
        />
      </Layout.Row>
    </div>
  );
};

export default TooltipContent;
