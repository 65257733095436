import React from 'react';
import cs from 'classnames';
import { Transition } from '@headlessui/react';

import { Dropdown, Icon } from '@advisor/design/components';
import { MenuRootProps } from './types';
import useContextMenuState, {
  useIsTriggerVisible,
} from './useContextMenuState';

const MenuRoot: React.FC<MenuRootProps> = ({ children, triggerTestID }) => {
  const { triggerRef } = useContextMenuState();
  const isTriggerVisible = useIsTriggerVisible();

  return (
    <div className="relative mt-2 mx-2">
      <Dropdown.Root
        trigger={({ open }) => (
          <button type="button" ref={triggerRef} data-cy={triggerTestID}>
            <Transition
              show={isTriggerVisible || open}
              enterFrom="scale-0"
              enterTo="scale-100"
              enter="duration-150"
              leaveFrom="scale-100"
              leaveTo="scale-0"
              leave="duration-150"
            >
              <div
                className={cs(
                  'relative flex items-center justify-center h-7 w-7 text-dark-grey-02 transition-colors bg-off-white overflow-hidden rounded',
                  open ? 'bg-grey' : 'bg-white hover:bg-light-gray',
                )}
              >
                <Icon name="ChevronDown" className="scale-125 mx-auto" />
              </div>
            </Transition>
          </button>
        )}
      >
        <Dropdown.Items>{children}</Dropdown.Items>
      </Dropdown.Root>
    </div>
  );
};

export default MenuRoot;
