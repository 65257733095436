import React, { useEffect, useState } from 'react';

import { Modal } from '@advisor/design/components';
import ConfirmTab from './ConfirmTab';
import EditTab from './EditTab';
import { EditArtifactModalProps, Tab } from './types';

const EditArtifactModal: React.FC<EditArtifactModalProps> = ({
  visible,
  onClose,
}) => {
  const [tab, setTab] = useState<Tab>(Tab.Edit);

  // Reset the active tab on modal open
  useEffect(() => {
    if (visible) {
      setTab(Tab.Edit);
    }
  }, [visible]);

  return (
    <Modal.Base
      maxWidth={tab === Tab.Confirm ? 326 : 675}
      height={tab === Tab.Confirm ? 302 : 610}
      position={tab === Tab.Edit ? 'responsive' : 'floating'}
      visible={visible}
      onClose={onClose}
    >
      {tab === Tab.Confirm ? (
        <ConfirmTab onClose={onClose} />
      ) : (
        <EditTab onSave={() => setTab(Tab.Confirm)} />
      )}
    </Modal.Base>
  );
};

export default EditArtifactModal;
