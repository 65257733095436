import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IdentityType } from '@advisor/api/generated/graphql';
import { LoginType } from '@advisor/api/auth';
import { Form, Modal } from '@advisor/design/components';
import VerificationForm from '../VerificationForm';
import useCredentialForm from './useCredentialForm';
import { VerificationState } from './types';

function EditEmailForm() {
  const { t } = useTranslation(['common', 'onboarding']);
  const {
    value,
    hasErrors,
    showVerifyModal,
    verificationState,

    onVerify,
    onResendOTP,
    onSubmitEdit,
    onChangeValue,
    onHideVerifyModal,
    onConfirmVerification,
  } = useCredentialForm(IdentityType.Email);

  return (
    <>
      <Form.EmailAddress
        value={value}
        label={t('email-address')}
        isVerified={verificationState === VerificationState.Verified}
        onVerify={onVerify}
        onChange={onChangeValue}
        onSubmitEdit={onSubmitEdit}
        errorMessage={
          hasErrors
            ? t(
                'onboarding:verification-type-is-already-in-use-with-another-account',
                {
                  verificationType: t('email-address'),
                },
              )
            : undefined
        }
      />
      <Modal.Base
        visible={showVerifyModal}
        onClose={onHideVerifyModal}
        maxWidth={448}
        padding={{ right: 32, left: 48, y: 48 }}
      >
        <VerificationForm
          onGoBack={onHideVerifyModal}
          identity={value}
          onVerify={onConfirmVerification}
          onResendOTP={onResendOTP}
          verificationType={LoginType.EmailAddress}
        />
      </Modal.Base>
    </>
  );
}

export default memo(EditEmailForm);
