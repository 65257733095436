import { capitalize } from 'lodash-es';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import { MessageListItemHeaderProps } from './types';

const MessageListItemHeader = ({ dateTime }: MessageListItemHeaderProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="font-sora font-bold text-sm text-center text-dark-grey-01 mb-1">
      {capitalize(DateFormatter.calendar(dateTime, t))}
    </div>
  );
};

export default memo(MessageListItemHeader);
