import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';
import { Feature } from '@advisor/api/feature';

const showMagicWandButtonScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.feature(Feature.Microbots),
    Scope.feature(Feature.MicrobotAstro),
    Scope.verifiedAdvisor,
    Scope.not(Scope.microbotConversation(chatRoomId)),
  ]),
);

export default showMagicWandButtonScope;
