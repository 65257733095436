import React from 'react';

import { JoinRequestsProps } from './types';
import useJoinRequests from './useJoinRequests';
import JoinRequestBanner from './JoinRequestBanner';
import JoinRequestDisplayCount from './utils';

const JoinRequests: React.FC<JoinRequestsProps> = ({ chatRoomId }) => {
  const joinRequests = useJoinRequests(chatRoomId, JoinRequestDisplayCount);

  return (
    <>
      {joinRequests.map((joinRequest) => (
        <JoinRequestBanner
          key={joinRequest.id}
          joinRequest={joinRequest}
          chatRoomId={chatRoomId}
        />
      ))}
    </>
  );
};

export default JoinRequests;
