import React, { memo } from 'react';
import { useSetAtom } from 'jotai';
import { useMolecule } from 'bunshi/react';

import { useUserMetadata } from '@advisor/api/userMetadata';
import { DeprecatedButton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import Tooltip from '@advisor/design/components/Tooltip';
import { TooltipPosition } from '@advisor/design/components/Tooltip/types';
import { AddMicrobotMemoryMolecule } from '../../api';
import { HeaderTooltipLocation } from './metadata';
import AddMemoryModal from '../AddMemoryModal';
import TooltipContent from './TooltipContent';

const HeaderButton: React.FC = () => {
  const [hideTooltip, setHideTooltip] = useUserMetadata(HeaderTooltipLocation);
  const { openEditorAtom } = useMolecule(AddMicrobotMemoryMolecule);
  const openEditor = useSetAtom(openEditorAtom);

  const onTry = () => {
    setHideTooltip(true);
    openEditor();
  };

  return (
    <>
      <Tooltip.Root>
        <AddButton onPress={openEditor} />
        <Tooltip.Content
          className="max-w-[14.25rem]"
          elevate={<AddButton />}
          position={TooltipPosition.Below}
          showBackdrop
          variant="light"
          visible={!hideTooltip}
          onClose={() => setHideTooltip(true)}
        >
          <TooltipContent onTry={onTry} />
        </Tooltip.Content>
      </Tooltip.Root>
      <AddMemoryModal />
    </>
  );
};

export default memo(HeaderButton);

const AddButton: React.FC<{ onPress?: () => void }> = ({ onPress }) => (
  <div className="bg-white h-12 rounded-10 p-3">
    <DeprecatedButton.Icon
      icon="AddMemory"
      onPress={onPress}
      size={Spacing.small01}
      variant="astroDark"
    />
  </div>
);
