import { Helmet } from 'react-helmet-async';
import React, { FC, PropsWithChildren, memo } from 'react';

import useActiveTheme from './useActiveTheme';
import ThemeContext from './context';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const activeTheme = useActiveTheme();

  return (
    <ThemeContext.Provider value={activeTheme}>
      <Helmet>
        <style type="text/css">{`
          body {
            --theme-primary: ${activeTheme.colors.primary};
            --theme-primary-dark: ${activeTheme.colors.primaryDark};
            --theme-primary-light: ${activeTheme.colors.primaryLight};
            --theme-primary-light-05: ${activeTheme.colors.primaryLight05};

            --theme-secondary: ${activeTheme.colors.secondary};
            --theme-secondary-light: ${activeTheme.colors.secondaryLight};
            --theme-secondary-dark: ${activeTheme.colors.secondaryDark};
          }
        `}</style>
      </Helmet>
      {children}
    </ThemeContext.Provider>
  );
};

export default memo(ThemeProvider);
