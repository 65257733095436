import { setI18n } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import React, { memo, useEffect, useMemo } from 'react';

import LanguageMetadata from '../languages';
import { ProviderProps, LanguageContextType } from '../types';
import userLanguageAtom from './userLanguageAtom';
import uiLanguageAtom from './uiLanguageAtom';
import LanguageContext from './context';

const LanguageProvider = (props: ProviderProps) => {
  const { children, i18n } = props;

  setI18n(i18n);

  const [userLanguage, changeLanguage] = useAtom(userLanguageAtom);
  const uiLanguage = useAtomValue(uiLanguageAtom);

  // Keeping i18n in sync
  useEffect(() => {
    if (userLanguage) {
      i18n.changeLanguage(userLanguage);
    }
  }, [userLanguage, i18n]);

  const value: LanguageContextType = useMemo(
    () => ({
      userLanguage: userLanguage ?? 'en',
      uiLanguage,
      isRTL: LanguageMetadata[uiLanguage].rtl,
      changeLanguage,
    }),
    [userLanguage, uiLanguage, changeLanguage],
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export default memo(LanguageProvider);
