import cs from 'classnames';
import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  forwardRef,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react';

const resizeToScrollHeight = (element: HTMLElement) => {
  // eslint-disable-next-line no-param-reassign
  element.style.height = 'auto';
  // eslint-disable-next-line no-param-reassign
  element.style.height = `${element.scrollHeight}px`;
};

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const ResizableTextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, onChange, ...props }, ref) => {
    const localRef = useRef<HTMLTextAreaElement | null>();
    const resize = useCallback(() => {
      if (localRef.current) resizeToScrollHeight(localRef.current);
    }, []);

    useEffect(() => {
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
    }, [resize]);

    const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      resize();
      if (onChange) onChange(event);
    };

    useEffect(() => {
      resize();
    }, [resize, props.value]);

    return (
      <textarea
        ref={(area) => {
          localRef.current = area;
          if (!ref) return;
          if (typeof ref === 'function') ref(area);
          // eslint-disable-next-line no-param-reassign
          else ref.current = area;
        }}
        rows={1}
        className={cs('text-sm placeholder:font-medium', className)}
        onChange={handleOnChange}
        {...props}
      />
    );
  },
);

export default ResizableTextArea;
