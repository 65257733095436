import React from 'react';
import { useAtomValue } from 'jotai';

import {
  MicrobotFullInfo,
  microbotTabAtom,
} from '@advisor/microbots/hooks/useMicrobot';
import { Layout } from '@advisor/design/components';
import MicrobotActions from './MicrobotActions';
import MicrobotCapabilities from './MicrobotCapabilities';
import Section from './Section';

interface MicrobotDescriptionProps {
  microbot: MicrobotFullInfo;
  onConfigureMicrobot: () => void;
  onEditAccess: () => void;
}

const MicrobotDescription: React.FC<MicrobotDescriptionProps> = (props) => {
  const microbotTab = useAtomValue(microbotTabAtom);
  const { microbot, onConfigureMicrobot, onEditAccess } = props;

  const about = <Section section={microbot.about} />;
  const howItWorks = !!microbot.howItWorks && (
    <Section section={microbot.howItWorks} />
  );
  const capabilities = (
    <MicrobotCapabilities capabilities={microbot.capabilities} />
  );

  return (
    <>
      {/* Mobile layout */}
      <div className="xl:hidden">
        {microbotTab === 'about' && (
          <>
            {about}
            <hr className="m-6 text-grey" />
            {capabilities}
          </>
        )}
        {microbotTab === 'howItWorks' && microbot.howItWorks && (
          <Section section={microbot.howItWorks} />
        )}
        <Layout.Spacer.Vertical size="small02" />

        <div className="px-6 flex flex-row xl:hidden">
          <MicrobotActions
            personality={microbot.personality}
            onConfigure={onConfigureMicrobot}
            onEditAccess={onEditAccess}
          />
        </div>

        <Layout.Spacer.Vertical size="medium" />
      </div>
      {/* Desktop layout */}
      <div className="hidden xl:flex flex-row items-start">
        <div className="flex-[2_2_0%]">
          {about}
          {!!microbot.howItWorks && <Layout.Spacer.Vertical size="small01" />}
          {howItWorks}
        </div>
        <Layout.Spacer.Horizontal size="small01" />
        {capabilities}
      </div>
    </>
  );
};

export default MicrobotDescription;
