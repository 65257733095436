import React from 'react';

import { Layout } from '@advisor/design/components';
import ChatRoomRowSkeleton from './ChatRoomRowSkeleton';

const Loader = () => {
  return (
    <>
      <ChatRoomRowSkeleton animationDelay={0} />
      <Layout.Spacer.Vertical size="tiny" />
      <ChatRoomRowSkeleton animationDelay={300} />
      <Layout.Spacer.Vertical size="tiny" />
      <ChatRoomRowSkeleton animationDelay={600} />
      <Layout.Spacer.Vertical size="tiny" />
      <ChatRoomRowSkeleton animationDelay={900} />
    </>
  );
};

export default Loader;
