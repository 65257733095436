const Typography = {
  Sora: {
    subHeadline: {
      fontFamily: 'Sora',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 32,
    },
    title: {
      fontFamily: 'Sora',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 20,
    },
    titleSlim: {
      fontFamily: 'Sora',
      fontWeight: 400,
      fontSize: 16,
    },
    tinyTitleSemibold: {
      fontFamily: 'Sora',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 16,
    },
    tinyTitle: {
      fontFamily: 'Sora',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 16,
    },
    subtitle: {
      fontFamily: 'Sora',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 20,
    },
    subtitleBlack: {
      fontFamily: 'Sora',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 18,
    },
    bodyTiny: {
      fontFamily: 'Sora',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 16,
    },
  },
  Outfit: {
    subHeadline: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 26,
    },
    titleMedium: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      lineHeight: 26,
    },
    titleSmall: {
      fontFamily: 'Outfit',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 18,
    },
    bodySmall: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 18,
    },
    bodySmallBold: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 18,
    },
    bodySmallBlack: {
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 18,
    },
    bodyTiny: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 16,
    },
    bodyTinyBold: {
      fontFamily: 'Outfit',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 16,
    },
    bodyTinySemiblack: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 16,
    },
    bodyTinyBlack: {
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 16,
    },
  },
} as const;

export default Typography;
