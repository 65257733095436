import { useRef, useEffect, useMemo } from 'react';
import type { DebouncedFunc } from 'lodash-es';
import { throttle } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...p: any[]) => any;

export default function useThrottledFunction<T extends Callback>(
  func: T,
  by: number,
): DebouncedFunc<T> {
  const funcRef = useRef<T>(func);

  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  return useMemo(() => throttle(funcRef.current, by), [by]);
}
