import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentListProps } from './types';

const AttachmentList = ({ children }: AttachmentListProps) => {
  const { t } = useTranslation('common');
  return (
    <>
      <h6 className="text-xs font-sora my-1">{t('attachments')}</h6>
      <ul>{children}</ul>
    </>
  );
};

export default AttachmentList;
