import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Avatar, SegmentedGroup } from '@advisor/design/components';
import { TruncatedUserListItemProps } from './types';

const TruncatedUserListItem: React.FC<TruncatedUserListItemProps> = ({
  users,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const onClick = () => {
    navigate('members');
  };

  return (
    <SegmentedGroup.Item>
      <button
        type="button"
        onClick={onClick}
        className="flex flex-row flex-nowrap p-4 items-center ltr:text-left rtl:text-right w-full min-h-[4.5rem]"
      >
        <div className="relative w-20">
          <Avatar
            size={40}
            bordered={false}
            avatarUrl={users.length ? users[0].avatarUrl : ''}
          />
          <Avatar
            size={40}
            bordered={false}
            className="absolute top-0 ltr:left-5 rtl:right-5"
            avatarUrl={users.length > 1 ? users[1].avatarUrl : ''}
          />
          <div className="absolute top-0 ltr:left-10 rtl:right-10 h-10 w-10 bg-dark-grey-03 text-white grid place-content-center rounded-full">
            <span className="font-outfit text-xs font-bold">
              {t('more-count', { count: users.length - 2 })}
            </span>
          </div>
        </div>
        <span className="font-sora text-sm font-semibold text-dark-grey-03 ltr:ml-3 rtl:mr-3">
          {t('view-all-members')}
        </span>
      </button>
    </SegmentedGroup.Item>
  );
};

export default TruncatedUserListItem;
