export default function takeWhileDefined<T>(
  array: readonly (T | undefined)[],
): T[] {
  const result: T[] = [];

  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    if (item === undefined) {
      break;
    }

    result.push(item);
  }

  return result;
}
