import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { updateMessageAtom } from '../../messaging';
import MessageInputMolecule from './messageInputMolecule';

export default function useEditMessage() {
  const { messageInputAtom, editedMessageAtom, resetInputAtom } =
    useMolecule(MessageInputMolecule);
  const messageInput = useAtomValue(messageInputAtom);
  const editedMessage = useAtomValue(editedMessageAtom);
  const resetInput = useSetAtom(resetInputAtom);

  const updateMessage = useSetAtom(updateMessageAtom);

  return useEvent(async () => {
    if (!messageInput || !editedMessage) {
      return;
    }

    resetInput();

    await updateMessage(editedMessage, { message: messageInput });
  });
}
