import { Atom } from 'jotai';

/**
 * Same as atomFamily, but the keys can be garbage collected once they are
 * not used anywhere else.
 */
function weakAtomFamily<K extends object, T extends Atom<unknown>>(
  createAtom: (key: K) => T,
) {
  const weakMap = new WeakMap<K, T>();

  return (key: K) => {
    let cachedAtom = weakMap.get(key);

    if (!cachedAtom) {
      cachedAtom = createAtom(key);
      weakMap.set(key, cachedAtom);
    }

    return cachedAtom;
  };
}

export default weakAtomFamily;
