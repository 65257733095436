/* eslint-disable no-param-reassign */
import { useAtom, useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import ifExists from '@advisor/utils/ifExists';
import { useEvent } from '@advisor/utils/hooks';
import { clientAtom } from '@advisor/api/apollo';
import { actionAtom } from '@advisor/utils/atoms';
import ComplexError from '@advisor/utils/ComplexError';
import { LeaveVideoChatDocument } from '@advisor/api/generated/graphql';
import {
  RecordingRequestState,
  recordingRequestStateAtom,
} from '../RecordingManager';
import SoundNotifications from '../SoundNotifications';
import callStateAtom from './callStateAtom';
import useVideoRoomAPI from './useVideoRoomAPI';
import { VideoCallStatus } from './types';

const leaveVideoChatAtom = actionAtom(async function leaveVideoChatAtomSetter(
  { get },
  chatRoomId: string,
) {
  const client = await get(clientAtom);

  return client.mutate({
    mutation: LeaveVideoChatDocument,
    variables: {
      chatRoomId,
    },
  });
});

function useDisconnect() {
  const { leave } = useVideoRoomAPI() ?? {};

  const [callState, setCallState] = useAtom(callStateAtom);
  const setRecordingRequestState = useSetAtom(recordingRequestStateAtom);
  const leaveVideoChat = useSetAtom(leaveVideoChatAtom);

  return useEvent(async () => {
    if (
      !callState ||
      callState.status === VideoCallStatus.Joining ||
      callState.status === VideoCallStatus.Leaving
    ) {
      return;
    }

    SoundNotifications.playLeaveSound();

    setCallState(
      ifExists((draft) => {
        draft.status = VideoCallStatus.Leaving;
      }),
    );

    try {
      await leaveVideoChat(callState.chatRoomId);
    } catch (err) {
      Sentry.captureException(
        new ComplexError(
          `Could not notify backend that we are leaving video call.`,
          err,
        ),
      );
    }

    leave?.();

    setCallState(null);

    // Resetting request state when leaving video-call
    setRecordingRequestState(RecordingRequestState.IDLE);
  });
}

export default useDisconnect;
