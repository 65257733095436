import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, Layout } from '@advisor/design/components';
import MilestoneListItemSkeleton from './MilestoneListItemSkeleton';

function MilestoneListSkeleton() {
  const { t } = useTranslation('task-organiser');

  return (
    <div className="px-6">
      <Header.WithCount label={t('tasks')} />
      <MilestoneListItemSkeleton />
      <Layout.Spacer.Vertical size="tiny" />
      <MilestoneListItemSkeleton />
      <Layout.Spacer.Vertical size="tiny" />
      <MilestoneListItemSkeleton />
    </div>
  );
}

export default MilestoneListSkeleton;
