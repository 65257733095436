import React, { PropsWithChildren } from 'react';
import cs from 'classnames';

type BasicLayoutProps = PropsWithChildren<{ className?: string }>;

const BasicLayout: React.FC<BasicLayoutProps> = ({ children, className }) => {
  return (
    <div
      className={cs(
        'flex flex-col max-w-screen min-h-dynamic-screen',
        className,
      )}
    >
      {children}
    </div>
  );
};
export default BasicLayout;
