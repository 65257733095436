import React, { memo, useMemo, useState, CSSProperties } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import type { BareButtonProps, BareButtonRenderProps } from './types';
import { isPromise } from './utils';

const BareButton = ({
  forceLoading,
  className,
  children,
  disabled,
  testID,
  onPress,
  style,
}: BareButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onPressWrapper = useEvent(async () => {
    const returnValue = onPress?.();

    if (!isPromise(returnValue)) {
      return;
    }

    setIsLoading(true);
    try {
      await returnValue;
    } finally {
      setIsLoading(false);
    }
  });

  const renderProps = useMemo<BareButtonRenderProps>(
    () => ({
      pressed: false, // only used on mobile
      loading: (isLoading || forceLoading) ?? false,
    }),
    [isLoading, forceLoading],
  );

  return (
    <button
      type="button"
      onClick={onPress ? onPressWrapper : undefined}
      disabled={disabled || isLoading || forceLoading}
      data-cy={testID}
      className={
        typeof className === 'function' ? className(renderProps) : className
      }
      style={style as CSSProperties}
    >
      {typeof children === 'function' ? children(renderProps) : children}
    </button>
  );
};

export default memo(BareButton);
