import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { useLocation, useNavigate } from 'react-router';

import { useMyId } from '@advisor/api/me';
import { Scope } from '@advisor/api/scope';
import { ScopeAtom } from '@advisor/api/scope/types';
import { useEvent } from '../../hooks';
import { nullAtom } from '../../atoms';

export function useGoBackOrGoHome(
  fallbackPath: string = '/',
  replace?: boolean,
) {
  const navigate = useNavigate();
  const location = useLocation();

  return useEvent(() => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate(fallbackPath, { replace });
    }
  });
}

export function useAuthNavigationGuard(scopeAtom: ScopeAtom, homeUrl: string) {
  const goBack = useGoBackOrGoHome(homeUrl);
  const myId = useMyId();
  const inaccessible = useAtomValue(myId ? Scope.not(scopeAtom) : nullAtom);

  useEffect(() => {
    if (inaccessible) {
      goBack();
    }
  }, [goBack, inaccessible]);
}
