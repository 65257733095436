import { atom } from 'jotai';

/**
 * Works like a regular atom, except when setting the atom,
 * the incoming value gets processed by the `refine` function.
 */
function atomWithRefine<TValue, TIn>(
  initial: TValue,
  refine: (dirty: TIn) => TValue,
) {
  const rawAtom = atom(initial);

  return atom(
    (get) => get(rawAtom),
    (get, set, updater: TIn | ((prev: TValue) => TIn)) => {
      const newDirty =
        typeof updater === 'function'
          ? (updater as (prev: TValue) => TIn)(get(rawAtom))
          : updater;

      set(rawAtom, refine(newDirty));
    },
  );
}

export default atomWithRefine;
