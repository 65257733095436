import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  MeQuery,
  MeDocument,
  ChatRoomWithMembersFragment,
  ChatRoomWithMembersFragmentDoc,
  useUpdateLastSeenChatRoomOnTimestampMutation,
  UpdateLastSeenChatRoomOnTimestampMutationOptions,
} from '@advisor/api/generated/graphql';
import { identifyChatRoomCache } from '@advisor/api/graphql/customCacheKeys';

const mutationOptions: UpdateLastSeenChatRoomOnTimestampMutationOptions = {
  update(cache, result, { variables: { chatRoomId } = {} }) {
    const timestamp =
      result.data?.updateLastSeenChatRoomOnTimestamp || dayjs().format('LT');

    const meData = cache.readQuery<MeQuery>({
      query: MeDocument,
      variables: {},
    });

    const currentUserId = meData?.me?.id;

    if (!chatRoomId) {
      return;
    }

    cache.updateFragment(
      {
        fragment: ChatRoomWithMembersFragmentDoc,
        fragmentName: 'ChatRoomWithMembers',
        id: identifyChatRoomCache({ id: chatRoomId }),
      },
      (frag: ChatRoomWithMembersFragment | null) => {
        if (!frag) {
          return null;
        }

        const members = frag.members.map((member) => {
          if (member.member.id !== currentUserId) {
            return member;
          }

          return {
            ...member,
            lastSeenChatRoomOn: timestamp,
          };
        });

        return {
          ...frag,
          members,
        };
      },
    );
  },
  optimisticResponse: {
    __typename: 'Mutation',
    updateLastSeenChatRoomOnTimestamp: '',
  },
};

export default function useReadMessages(chatRoomId?: string) {
  const [readMessagesMutation] =
    useUpdateLastSeenChatRoomOnTimestampMutation(mutationOptions);

  const onReadMessage = useCallback(() => {
    if (chatRoomId) {
      readMessagesMutation({ variables: { chatRoomId } });
    }
  }, [chatRoomId, readMessagesMutation]);

  useEffect(() => {
    onReadMessage();
  }, [onReadMessage]);

  return onReadMessage;
}
