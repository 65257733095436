import { ApolloClient, useApolloClient } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import {
  DeleteFileDocument,
  DeleteFileMutation,
  DeleteFileMutationVariables,
} from '../generated/graphql';

export async function deleteFile(
  apolloClient: ApolloClient<unknown>,
  fileId: string,
) {
  try {
    await apolloClient.mutate<DeleteFileMutation, DeleteFileMutationVariables>({
      mutation: DeleteFileDocument,
      variables: { fileId },
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function useDeleteFile() {
  const client = useApolloClient();

  return useEvent(async (fileId: string) => {
    return deleteFile(client, fileId);
  });
}
