/* eslint-disable max-classes-per-file, @typescript-eslint/no-explicit-any */

export type InviteLinkDefinition<TParams extends any[] = any[]> = {
  baseUrl: string;
  encode?: (...params: TParams) => string | undefined;
};

export type EncodeParamsFromInviteLink<TInviteLink> = TInviteLink extends {
  encode?: (...params: infer P) => string;
}
  ? P
  : [string];

export function instantiateLinkDefinition<T extends InviteLinkDefinition>(
  originUrl: string,
  definition: T,
  params: EncodeParamsFromInviteLink<T>,
) {
  return `${originUrl}/${definition.baseUrl}/${(() => {
    if (definition.encode) {
      return `${definition.encode(...params)}`;
    }

    return params;
  })()}`;
}

export function parseInviteLinkDefinition(
  definition: InviteLinkDefinition<any[]>,
  inputString: string,
): string | null {
  let str = inputString;

  const index = str.indexOf(definition.baseUrl);
  if (index === -1) {
    return null;
  }

  str = str.substring(index + definition.baseUrl.length + 1); // Trimming the base url

  return str.split('/')[0];
}
