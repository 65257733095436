import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from '@advisor/design/components/Icon';
import { ErrorTextSmall } from '../components';
import type { AttachmentItemProps } from './types';

const AttachmentItem = ({
  label,
  hasErrors,
  onRemove,
}: AttachmentItemProps) => {
  const { t } = useTranslation('common');
  return (
    <li>
      <div className="flex items-center my-2">
        <StatusIcon
          variant={hasErrors ? 'error' : 'valid'}
          className="shrink-0"
        />
        <span className="grow mx-2 truncate">{label}</span>
        <RemoveButton className="shrink-0" onRemove={onRemove} />
      </div>
      {hasErrors && (
        <ErrorTextSmall>{t('this-document-is-not-supported')}</ErrorTextSmall>
      )}
    </li>
  );
};

const StatusIconVariants = {
  valid: ['CircleCheckFill', 'text-primary'],
  error: ['ErrorCircle', 'text-negative'],
} as const;

interface StatusIconProps {
  variant: keyof typeof StatusIconVariants;
  className?: string;
}

const StatusIcon = ({ variant, className }: StatusIconProps) => {
  const [name, textColor] = StatusIconVariants[variant];
  return <Icon name={name} className={cs(textColor, className)} />;
};

interface RemoveButtonProps {
  onRemove: () => void;
  className?: string;
}

const RemoveButton = ({ onRemove, className }: RemoveButtonProps) => {
  return (
    <button
      type="button"
      className={cs('text-negative', className)}
      onClick={onRemove}
    >
      <Icon name="Delete" />
    </button>
  );
};

export default AttachmentItem;
