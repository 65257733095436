import { Atom, atom } from 'jotai';
import { loadable } from 'jotai/utils';

/**
 * Used for memoizing the result of the `valueOr` function.
 * This helps when calling it within a React component's render function.
 */
const memoLazyAndFallback = new WeakMap<
  object, // inputAtom
  { fallback: unknown; resultAtom: Atom<unknown> }[]
>();

function isEmptyArray(v: unknown): v is [] {
  return v instanceof Array && v.length === 0;
}

function areEqual(a: unknown, b: unknown) {
  return a === b || (isEmptyArray(a) && isEmptyArray(b));
}

/**
 * Makes it easy to provide a value in case we do not want to handle a promise.
 */
function valueOr<Pending, Value>(
  lazy: Atom<Value>,
  fallback: Pending,
): Atom<Pending | Awaited<Value>> {
  const memoizedEntry = memoLazyAndFallback
    .get(lazy)
    ?.find(({ fallback: fb }) => areEqual(fb, fallback));

  if (memoizedEntry) {
    return memoizedEntry.resultAtom as Atom<Pending | Awaited<Value>>;
  }

  const loadableAtom = loadable(lazy);
  const resultAtom = atom((get) => {
    const result = get(loadableAtom);

    if (result.state === 'hasData') {
      return result.data;
    }

    return fallback;
  });

  let memoFallback = memoLazyAndFallback.get(lazy);
  if (memoFallback === undefined) {
    memoFallback = [];
    memoLazyAndFallback.set(lazy, memoFallback);
  }
  memoFallback.push({ fallback, resultAtom });

  return resultAtom;
}

const Lazy = {
  valueOr,
};

export default Lazy;
