import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton } from '@advisor/design/components';
import {
  Paragraph,
  Subtitle,
} from 'src/components/atoms/DeleteAccountComponents';

interface DeleteAccountContentProps {
  onStartDeleteAccount?: () => void;
}

const DeleteAccountContent: React.FC<DeleteAccountContentProps> = ({
  onStartDeleteAccount,
}) => {
  const { t } = useTranslation('onboarding');

  return (
    <div className="max-w-3xl">
      <Subtitle>{t('delete-your-account')}</Subtitle>
      <Paragraph className="md:leading-7">
        {t('delete-account-sorry-to-see-you-go')}
      </Paragraph>
      <ul className="flex flex-col gap-4 pl-5 md:pl-10 pt-5 list-disc text-sm leading-4-5 md:text-base md:leading-5 text-dark-grey-02">
        <li>
          <span className="font-bold">{t('delete-account-data-deletion')}</span>{' '}
          {t('delete-account-permanently-erase-data-including')}
        </li>
        <li>
          <span className="font-bold">
            {t('delete-account-loss-of-access')}
          </span>{' '}
          {t('delete-account-you-will-lose-access')}
        </li>
        <li>
          <span className="font-bold">{t('delete-account-messages')}</span>{' '}
          {t('delete-account-any-messages-or-communication')}
        </li>
        <li>
          <span className="font-bold">{t('delete-account-configuration')}</span>{' '}
          {t('delete-account-instead-of-deleting-your-account')}
        </li>
      </ul>
      <div className="flex flex-col gap-5 pt-5">
        <Paragraph>{t('delete-account-understand-consequences')}</Paragraph>
        <DeprecatedButton.Large
          variant="white"
          className="!text-negative !border-negative text-sm leading-[14px] border"
          onPress={onStartDeleteAccount}
        >
          {t('delete-account')}
        </DeprecatedButton.Large>
        <Paragraph>{t('delete-account-remember-once-deleted')}</Paragraph>
      </div>
    </div>
  );
};

export default DeleteAccountContent;
