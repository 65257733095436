import cs from 'classnames';
import { useNavigate } from 'react-router';
import React, { memo, useCallback } from 'react';

import {
  useHideContextMenu,
  useOrCreateContextMenuScope,
} from '@advisor/design/components/ContextMenu';
import { Layout } from '@advisor/design/components';
import {
  TranslatingIndicator,
  TranslationStatusBox,
} from '../../contentTranslation';
import MessageMenu from '../../MessageMenu';
import { useAutotranslationState } from '../../chatLanguage';
import { useAppropriateFontName } from '../shared/utils';
import MessageContent from '../shared/MessageContent';
import AvatarAndName from '../shared/AvatarAndName';
import ChatMessage from '../shared/ChatMessage';
import useUserMessageApi from './useUserMessageApi';
import TranslateTooltip from './TranslateTooltip';
import { UserMessageProps } from './types';

function UserMessage(props: UserMessageProps) {
  const navigate = useNavigate();
  const contextMenuScope = useOrCreateContextMenuScope();
  const hideMenu = useHideContextMenu(contextMenuScope);
  const { message, isFirstInGroup, isLastInGroup, latestMessageId } = props;
  const autotranslationState = useAutotranslationState(message.chatRoomId);

  const { messageText, isCurrentUser, toggleTranslation, translationStatus } =
    useUserMessageApi();

  const fontName = useAppropriateFontName(messageText);

  const handleToggleTranslation = useCallback(() => {
    hideMenu();
    toggleTranslation();
  }, [hideMenu, toggleTranslation]);

  const handleOpenMessageInfo = useCallback(() => {
    hideMenu();
    navigate(`/chat/${message.chatRoomId}/seen-at/${message.sentAt}`);
  }, [hideMenu, navigate, message]);

  return (
    <>
      <TranslateTooltip message={message} latestMessageId={latestMessageId} />
      <ChatMessage.Container isFromMe={isCurrentUser}>
        <ChatMessage.Bubble
          isFromMe={isCurrentUser}
          isFirstInGroup={isFirstInGroup}
          isLastInGroup={isLastInGroup}
          contextMenuScope={contextMenuScope}
        >
          <Layout.Spacer.Vertical size="tiny" />
          {!isCurrentUser && isLastInGroup && (
            <AvatarAndName user={message.author} />
          )}
          <TranslatingIndicator status={translationStatus.type}>
            <div
              className={cs('message-word-break', fontName)}
              data-cy="chatMessage"
            >
              <MessageContent doubleStarIsBold>{messageText}</MessageContent>
            </div>
          </TranslatingIndicator>
          <span className="absolute top-0 ltr:right-0 rtl:left-0">
            <MessageMenu onOpenInfo={handleOpenMessageInfo} />
          </span>
        </ChatMessage.Bubble>
        <TranslationStatusBox
          width="fit-content"
          status={translationStatus}
          detectedLanguage={message.detectedLanguage}
          autotranslationState={autotranslationState}
          onToggleTranslation={handleToggleTranslation}
        />
      </ChatMessage.Container>
    </>
  );
}

export default memo(UserMessage);
