import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useParticipants, usePickUniqueParticipants } from '@advisor/videoCall';
import { useAmIAdvisor } from '@advisor/api/me';
import { Icon, Skeleton } from '@advisor/design/components';
import { useEvent } from '@advisor/utils/hooks';
import {
  MinimizeVideoCallTooltip,
  RecordingControls,
} from '@advisor/videoCall/components';
import { useMinimizeVideoCallTooltip } from '@advisor/videoCall/components/MinimizeVideoCallTooltip';
import { Logo } from '@advisor/ui/Brand';
import useChatIdParam from 'src/hooks/useChatIdParam';
import UserDropDown from 'src/components/organisms/UserDropDown';

const useMinimize = () => {
  const navigate = useNavigate();
  const chatId = useChatIdParam();
  const [, setShowTooltip] = useMinimizeVideoCallTooltip();

  return useEvent(() => {
    setShowTooltip(false);
    navigate(`/chat/${chatId}`);
  });
};

const MinimizeButtonNonXL = () => {
  const handleMinimize = useMinimize();

  return (
    <div className="xl:hidden">
      <MinimizeVideoCallTooltip>
        <button
          type="button"
          className="hover:bg-grey w-6 h-6 rounded-full ltr:mr-6 rtl:ml-6"
          onClick={handleMinimize}
        >
          <Icon name="ArrowLeft" className="mx-auto" />
        </button>
      </MinimizeVideoCallTooltip>
    </div>
  );
};

const MinimizeButtonXL = () => {
  const { t } = useTranslation('common');
  const handleMinimize = useMinimize();

  return (
    <div className="hidden xl:block">
      <MinimizeVideoCallTooltip>
        <button
          type="button"
          className="flex items-center px-2.5 py-1.5 space-x-2 rounded-lg border border-grey text-primary text-xs font-outfit font-semibold transition-colors duration-75 hover:opacity-70"
          onClick={handleMinimize}
        >
          <Icon name="Minimize" className="w-5 h-5" />
          <span>{t('minimize-video')}</span>
        </button>
      </MinimizeVideoCallTooltip>
    </div>
  );
};

function MembersSkeleton() {
  return (
    <Skeleton.Ping>
      <Skeleton.Text fontSize={14} lineHeight={20} size={80} />
    </Skeleton.Ping>
  );
}

const VideoCallNavbar = () => {
  const { t } = useTranslation('common');

  const amIAdvisor = useAmIAdvisor();
  const participants = useParticipants();
  const uniqueParticipants = usePickUniqueParticipants(participants);

  const dropDownSlot = amIAdvisor && (
    <div className="flex flex-1 justify-end items-center ltr:pr-6 rtl:pl-6">
      <UserDropDown />
    </div>
  );

  const membersSlot = uniqueParticipants.length > 0 && (
    <p className="text-sm font-medium text-dark-grey-025">
      {t('members', {
        membersCount: String(uniqueParticipants.length).padStart(2, '0'),
      })}
    </p>
  );

  return (
    <VideoCallNavbarShell
      dropDownSlot={dropDownSlot}
      membersSlot={membersSlot}
    />
  );
};

export function VideoCallNavbarShell({
  dropDownSlot,
  membersSlot,
}: {
  dropDownSlot?: ReactNode;
  membersSlot?: ReactNode;
}) {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row shrink-0 h-header-height bg-white border-b border-grey">
      <div className="shrink-0 max-w-md hidden xl:flex xl:w-chat-sidebar-xl 2xl:w-chat-sidebar-2xl ltr:border-r rtl:border-l border-grey">
        <div className="ltr:pl-10 rtl:pr-10 h-full py-2">
          <Logo type="platform" fit="start" />
        </div>
        {dropDownSlot}
      </div>
      <div className="grow flex flex-row items-center px-6">
        <MinimizeButtonNonXL />
        <div className="grow self-stretch flex flex-col gap-1 justify-center">
          <h3 className="font-sora font-semibold text-sm text-secondary">
            {t('video-room')}
          </h3>
          {membersSlot ?? <MembersSkeleton />}
        </div>
        <div className="flex-1 justify-end flex sm:hidden">
          <RecordingControls variant="light" />
        </div>
        <MinimizeButtonXL />
      </div>
    </div>
  );
}

export default VideoCallNavbar;
