import { useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { ScopeTuple, useMolecule } from 'bunshi/react';

import ContextMenuMolecule, { ContextMenuScope } from './contextMenuMolecule';
import { ContextMenuScopeType } from './types';

/**
 * If no scope is passed in, the upstream scope is used.
 */
function useContextMenuState(scope?: ContextMenuScopeType) {
  const moleculeOptions = useMemo(() => {
    if (!scope) {
      return undefined;
    }

    return {
      withScope: [ContextMenuScope, scope] satisfies ScopeTuple<unknown>,
    };
  }, [scope]);

  return useMolecule(ContextMenuMolecule, moleculeOptions);
}

/**
 * If no scope is passed in, the upstream scope is used.
 */
export function useIsTriggerVisible(scope?: ContextMenuScopeType) {
  const { triggerVisibleAtom } = useContextMenuState(scope);
  return useAtomValue(triggerVisibleAtom);
}

/**
 * If no scope is passed in, the upstream scope is used.
 */
export function useSetTriggerVisibility(scope?: ContextMenuScopeType) {
  const { triggerVisibleAtom } = useContextMenuState(scope);
  return useSetAtom(triggerVisibleAtom);
}

/**
 * If no scope is passed in, the upstream scope is used.
 */
export function useHideContextMenu(scope?: ContextMenuScopeType) {
  const { hideMenuAtom } = useContextMenuState(scope);
  return useSetAtom(hideMenuAtom);
}

/**
 * If no scope is passed in, the upstream scope is used.
 */
export function useShowContextMenu(scope?: ContextMenuScopeType) {
  const { showMenuAtom } = useContextMenuState(scope);
  return useSetAtom(showMenuAtom);
}

export default useContextMenuState;
