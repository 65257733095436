import { fromPairs } from 'lodash-es';
import { atom, useAtomValue } from 'jotai';

import {
  ActiveSponsorshipsDocument,
  type AdvertisementInfoFragment,
} from '@advisor/api/generated/graphql';
import { atomOfQuery } from '@advisor/api/apollo';
import { AdvertisementWithAsset } from './types';

export const activeAdvertisementsAtom = (() => {
  const queryAtom = atomOfQuery(ActiveSponsorshipsDocument);

  return atom(async (get) => {
    const ads = ((await get(queryAtom)).data?.activeSponsorships ?? []).flatMap(
      (s) => s.advertisements,
    );

    return ads;
  });
})();

export const activeAdvertisementsMapAtom = atom(async (get) => {
  const ads = await get(activeAdvertisementsAtom);

  return fromPairs(ads.map((ad) => [ad.key, ad]));
});

export const AdRequirements = {
  withAsset(ad: AdvertisementInfoFragment): ad is AdvertisementWithAsset {
    return !!ad.assetUrl;
  },
};

export function useAdvertForPlacement(
  placementKey: string | null | undefined,
  requirement?: undefined,
): AdvertisementInfoFragment | undefined;

export function useAdvertForPlacement<T extends AdvertisementInfoFragment>(
  placementKey: string | null | undefined,
  requirement: (ad: AdvertisementInfoFragment) => ad is T,
): T | undefined;

export function useAdvertForPlacement<T extends AdvertisementInfoFragment>(
  placementKey: string | null | undefined,
  requirement?: (ad: AdvertisementInfoFragment) => ad is T,
) {
  const advertisementsMap = useAtomValue(activeAdvertisementsMapAtom);

  const advertisement = placementKey
    ? advertisementsMap[placementKey]
    : undefined;

  if (requirement && advertisement && !requirement(advertisement)) {
    return undefined;
  }

  return advertisement;
}
