import React from 'react';

import { useLanguage } from '@advisor/language';
import DeprecatedButton from '../../components/DeprecatedButton';
import { Spacing } from '../../styles';
import { ModalHeaderProps } from './types';

function ModalHeader({ title, subtitle, onClose, onBack }: ModalHeaderProps) {
  const { isRTL } = useLanguage();

  return (
    <div className="self-stretch shrink-0 flex flex-row items-start justify-between border-b border-grey p-4">
      {onBack && (
        <div className="pt-3.5">
          <DeprecatedButton.Icon
            icon={isRTL ? 'ArrowRight' : 'ArrowLeft'}
            variant="darkGrey03"
            size={Spacing.small02}
            onPress={onBack}
            className="ltr:ml-3.5 rtl:mr-3.5"
          />
        </div>
      )}
      <div className="flex-1 pt-4 px-4 pb-2">
        <h4 className="font-sora text-xl font-semibold">{title}</h4>
        {subtitle && <p className="text-dark-grey-02 text-sm">{subtitle}</p>}
      </div>
      {onClose && (
        <DeprecatedButton.Icon
          icon="X"
          variant="darkGrey01"
          size={Spacing.small02}
          onPress={onClose}
        />
      )}
    </div>
  );
}

export default ModalHeader;
