import cs from 'classnames';
import { take } from 'lodash-es';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Spacing } from '@advisor/design/styles';
import { Icon } from '@advisor/design/components';
import { DateFormatter } from '@advisor/language';
import AvatarGroup from '@advisor/ui/components/AvatarGroup';
import { HistoryListItemProps } from './types';

const HistoryListItem: React.FC<HistoryListItemProps> = (props) => {
  const { room, borderless, openDetails, showParticipants = true } = props;

  const hasRecordings = room.recordings.length > 0;

  const members = useMemo(
    () =>
      take(
        room.participants.map(({ participant }) => participant),
        2,
      ),
    [room.participants],
  );

  const details = useMemo(() => {
    if (room.recordings.length === 0) {
      return <DisabledRecording />;
    }

    const totalDuration = room.recordings.reduce(
      (acc, recording) => acc + (recording.recordingDuration ?? 0),
      0,
    );

    if (totalDuration === 0) {
      return <UnavailableRecording />;
    }

    const duration = DateFormatter.duration({ seconds: totalDuration });

    return (
      <RecordingSummary
        duration={duration}
        recordingNumber={room.count}
        recordingCount={room.recordings.length}
      />
    );
  }, [room.recordings, room.count]);

  const onOpenDetails = useCallback(() => {
    openDetails(room);
  }, [room, openDetails]);

  return (
    <button
      type="button"
      onClick={onOpenDetails}
      className={cs(
        'w-full flex flex-row gap-3 items-center p-4 bg-white rounded-lg',
        !borderless &&
          'border border-grey transition-colors hover:border-dark-grey-01',
      )}
    >
      <div
        className={cs(
          'max-w-max rounded-lg p-2 text-white',
          hasRecordings ? 'bg-primary' : 'bg-dark-grey-01',
        )}
      >
        <Icon
          name={hasRecordings ? 'VideoRoom' : 'VideoRoomCrossed'}
          size={Spacing.small01}
        />
      </div>
      {details}
      {showParticipants && (
        <AvatarGroup
          frontAvatar={members[0]?.avatarUrl}
          backAvatar={members[1]?.avatarUrl}
        />
      )}
      <Icon
        name="ChevronRight"
        size={24}
        className="rounded text-dark-grey-02 group-hover/item:text-dark-grey-03 rtl:-scale-100"
      />
    </button>
  );
};

export default memo(HistoryListItem);

const DisabledRecording: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="grow text-start text-dark-grey-02">
      <p className="font-sora font-bold text-xs 2xl:text-sm">
        {t('unavailable')}
      </p>
      <p className="text-xs">{t('recording-disabled-by-video-room-owner')}</p>
    </div>
  );
};

const UnavailableRecording: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="grow text-start text-dark-grey-02">
      <p className="font-sora font-bold text-xs 2xl:text-sm">
        {t('unavailable')}
      </p>
      <p className="text-xs">{t('recording-unavailable')}</p>
    </div>
  );
};

const RecordingSummary: React.FC<{
  recordingNumber: number;
  duration: string;
  recordingCount: number;
}> = ({ recordingNumber, duration, recordingCount }) => {
  const count = String(recordingCount).padStart(2, '0');
  const { t } = useTranslation('common');

  return (
    <div className="grow text-start">
      <p className="text-primary font-sora font-bold text-xs 2xl:text-sm">
        {t('recording-number', { number: recordingNumber })}
      </p>
      {recordingCount === 1 && <p className="text-xs">{duration}</p>}
      {recordingCount > 1 && (
        <p className="text-xs">{`${count} ${t('recordings')}`}</p>
      )}
    </div>
  );
};
