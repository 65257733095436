import React, { Suspense } from 'react';

import { useChatRoom } from '@advisor/api/chatRoom';
import { PermissionGate, Scope } from '@advisor/api/scope';
import ChatBottomBarContent from './ChatBottomBarContent';
import { ChatBottomBarProps } from './types';

const ChatBottomBar: React.FC<ChatBottomBarProps> = (props) => {
  const { chatRoomId } = props;

  const chatRoom = useChatRoom(chatRoomId ?? null);

  if (!chatRoom) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <PermissionGate scope={Scope.allowedToTalk(chatRoom.chatRoomId)}>
        {() => <ChatBottomBarContent chatRoomId={chatRoom.chatRoomId} />}
      </PermissionGate>
    </Suspense>
  );
};

export default ChatBottomBar;
