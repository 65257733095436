import { useTranslation } from 'react-i18next';

import { useMyId } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import useHasUnreadMessages from '@advisor/api/hooks/useHasUnreadMessages';
import { ChatRoomWithMembersFragment } from '@advisor/api/generated/graphql';

export enum ChatRoomActivity {
  NewConnection = 'new-connection',
  UserMessage = 'user-message',
  OtherActivity = 'other-activity',
}

export function useLatestChatRoomActivity(
  chatRoom?: ChatRoomWithMembersFragment | null,
): {
  type: ChatRoomActivity;
  description: string;
  unread: boolean;
} {
  const { t } = useTranslation(['common', 'user-role', 'microbot']);
  const myId = useMyId();
  const hasUnreadMessages = useHasUnreadMessages(chatRoom ?? {});

  if (!chatRoom) {
    return {
      type: ChatRoomActivity.OtherActivity,
      description: '',
      unread: false,
    };
  }

  const { members, createdAt, latestMessage, name } = chatRoom;

  const memberOfMe = members?.find(
    (memberInfo) => memberInfo.member.id === myId,
  );

  const mainMember = members?.find(
    (memberInfo) => memberInfo.member.name === name,
  );

  const newConnection =
    !memberOfMe?.lastSeenChatRoomOn ||
    memberOfMe?.lastSeenChatRoomOn === createdAt;

  if (chatRoom.__typename === 'MicrobotChatRoom') {
    return {
      type: ChatRoomActivity.UserMessage,
      description: t('microbot:personal-space-for-all-your-queries'),
      unread: false,
    };
  }

  if (newConnection) {
    return {
      type: ChatRoomActivity.NewConnection,
      description: (() => {
        if (Role.isStudent(mainMember?.member)) {
          return t('common:new-customer-connection');
        }

        if (Role.isAdvisor(mainMember?.member)) {
          return t('user-role:host');
        }

        return t('common:new-connection');
      })(),
      unread: true,
    };
  }

  if (latestMessage?.__typename === 'UserMessage') {
    return {
      type: ChatRoomActivity.UserMessage,
      description: latestMessage.message.replace(/\s/, ' '),
      unread: hasUnreadMessages,
    };
  }

  return {
    type: ChatRoomActivity.OtherActivity,
    description: hasUnreadMessages ? t('common:new-activity') : '',
    unread: hasUnreadMessages,
  };
}
