import { ApolloClient } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import {
  PrivateFileScope,
  RequestPrivateFileUploadDocument,
} from '../../generated/graphql';
import { FileData, UploadErrorType, FileUploadOptions } from '../types';
import { isFileTooLarge } from '../utils';

async function requestPrivateFileUpload<TScope extends PrivateFileScope>(
  client: ApolloClient<unknown>,
  scope: TScope,
  file: FileData,
  options: FileUploadOptions[TScope],
  signal?: AbortSignal,
) {
  if (isFileTooLarge(file, options.sizeThreshold)) {
    return {
      error: UploadErrorType.FileTooLarge,
    };
  }

  const variables =
    'chatRoomId' in options
      ? {
          scope,
          fileType: file.type,
          fileSource: options.fileSource,
          chatRoomId: options.chatRoomId,
        }
      : {
          scope,
          fileType: file.type,
          fileSource: options.fileSource,
        };

  try {
    const { data } = await client.mutate({
      mutation: RequestPrivateFileUploadDocument,
      variables,
      context: {
        fetchOptions: {
          signal,
        },
      },
    });

    const response = data?.requestPrivateFileUpload;

    if (!response) {
      return { error: UploadErrorType.UploadDenied };
    }

    type Fields = Record<string, string> | undefined;
    const fields = JSON.parse(response.fields) as Fields;
    return {
      ok: true as const,
      data: {
        uploadUrl: response.uploadUrl,
        fileId: response.fileId,
        formDataFields: fields ?? {},
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    return { error: UploadErrorType.UploadDenied };
  }
}

export default requestPrivateFileUpload;
