import { ConnectedCallParticipant } from '../VideoRoomAPI';
import useParticipants from './useParticipants';

function useConnectedParticipants() {
  const participants = useParticipants();

  return participants.filter(
    (p): p is ConnectedCallParticipant => p.joinState === 'Joined',
  );
}

export default useConnectedParticipants;
