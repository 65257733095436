import { createContext } from 'react';
import { DropdownContextType, DropdownNodeContextType } from './types';

export const DEFAULT_TRANSITION_CONFIG = {
  enter: 'sm:transition sm:duration-150 ease-out',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'sm:transition sm:duration-150 ease-out',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

export const DropdownContext = createContext<DropdownContextType>({
  align: 'right',
});

export const DropdownNodeContext = createContext<DropdownNodeContextType>({
  depth: 0,
  open: false,
});
