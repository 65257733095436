import { useCallback } from 'react';

import {
  MilestoneInfoFragment,
  useDeleteMilestoneMutation,
  MilestonesForCategoryQuery,
  MilestoneCategoryInfoFragment,
  MilestonesForCategoryDocument,
  DeleteMilestoneMutationOptions,
  MilestoneCategoryInfoFragmentDoc,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import dayjs from 'dayjs';

const useDeleteMilestone = () => {
  const [deleteMilestone] = useDeleteMilestoneMutation();

  return useCallback(
    async (milestone: MilestoneInfoFragment) => {
      try {
        const result = await deleteMilestone({
          ...mutationOptions,
          optimisticResponse: {
            __typename: 'Mutation',
            deleteMilestone: milestone,
          },
          variables: { milestoneId: milestone.id },
        });

        if (!!result.errors && result.errors.length > 0) {
          return false;
        }
        return true;
      } catch (e) {
        Sentry.captureException(e);
        return false;
      }
    },
    [deleteMilestone],
  );
};

const mutationOptions: DeleteMilestoneMutationOptions = {
  update(cache, result) {
    const {
      id: milestoneId,
      milestoneCategoryId,
      isCompleted,
      pendingReview,
      notificationTime,
    } = result.data?.deleteMilestone ?? {};

    if (!milestoneId || !milestoneCategoryId) {
      return;
    }

    const isOverdue =
      notificationTime && dayjs(notificationTime).diff(dayjs()) <= 0;

    cache.updateQuery<MilestonesForCategoryQuery>(
      {
        query: MilestonesForCategoryDocument,
        variables: { milestoneCategoryId },
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          milestonesForCategory: prev.milestonesForCategory.filter(
            (milestone) => milestone.id !== milestoneId,
          ),
        };
      },
    );

    cache.updateFragment<MilestoneCategoryInfoFragment>(
      {
        fragment: MilestoneCategoryInfoFragmentDoc,
        fragmentName: 'MilestoneCategoryInfo',
        id: cache.identify({
          __typename: 'MilestoneCategory',
          id: milestoneCategoryId,
        }),
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          totalMilestones: prev.totalMilestones - 1,
          completedMilestones: prev.completedMilestones - (isCompleted ? 1 : 0),
          pendingMilestones: prev.pendingMilestones - (pendingReview ? 1 : 0),
          overdueMilestones: prev.overdueMilestones - (isOverdue ? 1 : 0),
        };
      },
    );
  },
};

export default useDeleteMilestone;
