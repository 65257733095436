import { atomFamily } from 'jotai/utils';
import { createScope, use } from 'bunshi';
import { useMolecule } from 'bunshi/react';
import { ExtractAtomArgs, useAtomValue } from 'jotai';

import {
  MilestoneCategoryDocument,
  MilestoneCategoryInfoFragment,
} from '@advisor/api/generated/graphql';
import { lazyAtom } from '@advisor/utils/atoms';
import { atomOfQuery } from '@advisor/api/apollo';
import MilestoneCategoryMolecule from './milestoneCategoryMolecule';

export const MilestoneCategoryScope = createScope('');

export function requireMilestoneCategoryScope() {
  const categoryId = use(MilestoneCategoryScope);

  if (!categoryId) {
    throw new Error(
      `Tried to use a molecule scoped to a Student Journey Milestone Category without providing its scope`,
    );
  }

  return categoryId;
}

export const milestoneCategoryAtoms = atomFamily(
  (milestoneCategoryId: string) => {
    const queryAtom = atomOfQuery(MilestoneCategoryDocument, {
      milestoneCategoryId,
    });

    return lazyAtom(
      async (get) => {
        return (await get(queryAtom)).data?.milestoneCategory;
      },
      async (_get, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
        set(queryAtom, ...args),
    );
  },
);

export function useMilestoneCategory(
  flag: 'required',
): MilestoneCategoryInfoFragment;
export function useMilestoneCategory(
  flag?: 'required',
): MilestoneCategoryInfoFragment | undefined;
export function useMilestoneCategory(flag?: 'required') {
  const { milestoneCategoryAtom } = useMolecule(MilestoneCategoryMolecule);
  const milestoneCategory = useAtomValue(milestoneCategoryAtom) ?? undefined;

  if (!milestoneCategory && flag === 'required') {
    throw new Error(`No MilestoneCategory query result.`);
  }

  return milestoneCategory;
}
