import React from 'react';
import type { ParseKeys } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

import {
  Icon,
  Modal,
  Loader,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';

import { CalendarAuthState } from './webCalendarAuth';

interface Props {
  state: CalendarAuthState | null;
  onClose: () => void;
}

const ConfigureCalendarModal: React.FC<Props> = (props) => {
  const { state, onClose } = props;

  const { t } = useTranslation(['common', 'microbot']);

  const modalTitle = (() => {
    switch (state) {
      case 'success':
        return t('microbot:google-calendar-added');
      case 'error':
        return t('oops-something-went-wrong');
      case 'authorizing':
        return t('microbot:configuring-google-calendar');
      default:
        return '';
    }
  })();

  const modalContentI18nKey: ParseKeys<['microbot']> | null = (() => {
    switch (state) {
      case 'success':
        return 'microbot:you-have-successfully-added-your-google-calendar-with-google-calendar-bot';
      case 'error':
        return null;
      case 'authorizing':
        return 'microbot:google-calendar-is-being-configured-with-google-calendar-bot';
      default:
        return null;
    }
  })();

  const confirmButtonLabel = (() => {
    switch (state) {
      case 'error':
        return t('confirm');
      case 'success':
        return t('microbot:continue-configuration');
      case 'authorizing':
        return t('please-wait');
      default:
        return '';
    }
  })();

  return (
    <Modal.Base
      visible={!!state && state !== 'waiting'}
      maxWidth={328}
      padding={32}
    >
      <div className="flex flex-col items-center">
        <ModalIcon state={state} />
        <Layout.Spacer.Vertical size="small01" />
        <h3 className="text-sm font-sora font-bold text-dark-grey-03">
          {modalTitle}
        </h3>
        <Layout.Spacer.Vertical size="micro02" />
        {!!modalContentI18nKey && (
          <p className="text-center text-sm font-medium font-outfit">
            <Trans
              t={t}
              i18nKey={modalContentI18nKey}
              components={{ bold: <span className="" /> }}
            />
          </p>
        )}
        <Layout.Spacer.Vertical size="small01" />
        <DeprecatedButton.Large
          variant="primary"
          onPress={onClose}
          label={confirmButtonLabel}
          disabled={state === 'authorizing'}
        />
      </div>
    </Modal.Base>
  );
};

export default ConfigureCalendarModal;

const ModalIcon: React.FC<{ state: CalendarAuthState | null }> = ({
  state,
}) => {
  switch (state) {
    case 'authorizing':
      return <Loader />;
    case 'success':
      return <Icon name="CircleCheck" className="w-10 h-10 text-primary" />;
    case 'error':
      return <Icon name="ErrorCircle" className="w-10 h-10 text-negative" />;
    default:
      return null;
  }
};
