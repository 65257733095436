import React, { Suspense } from 'react';
import { ChatNotificationsProps } from './types';

const ChatModifiersLazy = React.lazy(() => import('./ChatNotifications'));

const ChatNotifications = (props: ChatNotificationsProps) => (
  <Suspense fallback={null}>
    <ChatModifiersLazy {...props} />
  </Suspense>
);

export default {
  /**
   * Contains only components that are rendered independently from their place in virtual DOM,
   * like modals, banners, etc.
   */
  ChatNotifications,
};
