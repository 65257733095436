import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMyId } from '@advisor/api/me';
import isValidPhoneNumberAsync from '@advisor/utils/isValidPhoneNumberAsync';
import { CountryName } from '@advisor/utils/country/internationalDiallingCodes';
import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { useAsyncMemo, useEvent } from '@advisor/utils/hooks';
import { useInvitation } from '@advisor/onboarding';
import useProfileCreation from './useProfileCreation';

export default function useDetailsStep(
  onRedirectToChat?: () => Promise<void> | void,
) {
  const { t } = useTranslation(['common', 'onboarding']);
  const showModal = useShowModal();
  const { inviteType } = useInvitation();
  const myId = useMyId();

  const {
    errors,
    setErrors,
    userProfile,
    setUserProfile,
    onCreateProfile,
    needsVerification,
    onOpenVerification,
    onSkipVerification,
  } = useProfileCreation();

  const submitEnabled = !!useAsyncMemo(
    useCallback(async () => {
      if (
        needsVerification === 'phone' &&
        !(await isValidPhoneNumberAsync(userProfile.phoneNumber))
      ) {
        return false;
      }

      return !!userProfile.country;
    }, [needsVerification, userProfile.country, userProfile.phoneNumber]),
  );

  const askForVerification = useCallback(async () => {
    const verificationType =
      needsVerification === 'phone' ? t('phone-number') : t('email-address');

    const decision = await showModal.decide({
      title: t('onboarding:verification-type-needs-verification', {
        verificationType,
      }),
      message: t(
        'onboarding:verify-your-verification-type-so-you-can-login-with-this-in-the-future',
        {
          verificationType,
        },
      ),
      options: [
        {
          variant: 'positive',
          key: 'confirm',
          label: t('onboarding:verify-now'),
        },
        {
          variant: 'neutral',
          key: 'skip',
          label: t('skip'),
        },
      ],
    });

    if (decision === 'confirm') {
      await onOpenVerification();
    } else if (decision === 'skip') {
      await onSkipVerification();

      if (onRedirectToChat) {
        await onRedirectToChat();
      }
    }
  }, [
    t,
    showModal,
    onRedirectToChat,
    needsVerification,
    onOpenVerification,
    onSkipVerification,
  ]);

  const onSubmit = useEvent(async () => {
    if (!myId || inviteType === 'student') {
      await onCreateProfile();
    }

    if (!needsVerification) {
      if (onRedirectToChat) {
        await onRedirectToChat();
      }
      return;
    }

    await askForVerification();
  });

  const onSetCountry = useEvent((country: CountryName) => {
    setUserProfile((profile) => ({ ...profile, country }));
  });

  const onSetTimezone = useEvent((timezone: TimezoneName) => {
    setUserProfile((profile) => ({ ...profile, timezone }));
  });

  const onSetEmail = useEvent((email: string) => {
    setUserProfile((profile) => ({ ...profile, email }));
    setErrors({ email: false });
  });

  const onSetPhoneNumber = useEvent((phoneNumber: string) => {
    setUserProfile((profile) => ({ ...profile, phoneNumber }));
    setErrors({ phoneNumber: false });
  });

  const onSetAgency = useEvent((agency: string) => {
    setUserProfile((profile) => ({ ...profile, agency }));
  });

  return {
    errors,
    userProfile,
    submitEnabled,
    needsVerification,
    onSubmit,
    onSetEmail,
    onSetCountry,
    onSetTimezone,
    onSetPhoneNumber,
    onSetAgency,
  };
}
