import { first } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

import {
  useMyId,
  useAmIStudent,
  useAmIFamilyMember,
  useAmIServiceProvider,
  useAmIVerifiedAdvisor,
} from '@advisor/api/me';
import { useEvent } from '@advisor/utils/hooks';
import { Layout } from '@advisor/design/components';
import { useInvitation } from '@advisor/onboarding';
import { useShowModal } from '@advisor/design/components/ActionModal';
import {
  useAdvisorQuery,
  useChatRoomsQuery,
  useChatRoomNameQuery,
} from '@advisor/api/generated/graphql';
import { useCreateChatRoom } from '../StartChatRoom';
import { InvitationMessage } from './components';

interface HandleInvitationOptions {
  onSuccess?: (chatId: string) => void;
}

export default function useHandleInvitation({
  onSuccess,
}: HandleInvitationOptions = {}) {
  const recentlyHandledInvitationId = useRef<string | undefined>();
  const showModal = useShowModal();
  const { t } = useTranslation(['common', 'onboarding', 'user-role']);

  const myId = useMyId();
  const amIStudent = useAmIStudent();
  const amIVerifiedAdvisor = useAmIVerifiedAdvisor();
  const amIServiceProvider = useAmIServiceProvider();
  const amIFamilyMember = useAmIFamilyMember();
  const invitation = useInvitation();
  const { createChatRoom, createChatRoomDisabled } = useCreateChatRoom({
    onSuccess,
  });
  const { data: { chatRooms } = {} } = useChatRoomsQuery({
    variables: {
      status: null,
      nameContains: null,
    },
  });

  const { data: advisorData } = useAdvisorQuery({
    variables: {
      advisorId: invitation.inviteId ?? '',
    },
    skip: invitation.inviteType !== 'advisor',
  });

  const { data: chatRoomNameQuery } = useChatRoomNameQuery({
    variables: {
      chatRoomId: invitation.inviteId ?? '',
    },
    skip: invitation.inviteType !== 'conversation',
  });

  const name = (() => {
    if (advisorData?.advisor.__typename === 'Advisor') {
      return advisorData.advisor.name;
    }

    return chatRoomNameQuery?.chatRoomName ?? t('user-role:customer');
  })();

  const createChatRoomStable = useEvent(createChatRoom);

  const showInvitationModal = useEvent(async (): Promise<void> => {
    const confirm = await showModal.confirm({
      title: t('onboarding:join-new-conversation-with-name', {
        name,
      }),
      message: (
        <InvitationMessage>
          {t(
            'onboarding:you-have-used-an-invite-link-to-join-a-new-conversation-space',
          )}
          <Layout.Spacer.Vertical size="small02" />
          {t('common:do-you-want-to-proceed')}
        </InvitationMessage>
      ),
      confirmActionLabel: t('common:join-new-conversation'),
      cancelActionLabel: t('common:cancel'),
      variant: 'neutral',
    });

    if (confirm) {
      await createChatRoomStable();
    } else {
      invitation.clearInvitation();
    }
  });

  useEffect(() => {
    if (
      !myId ||
      !chatRooms ||
      !invitation.inviteId ||
      !invitation.inviteType ||
      createChatRoomDisabled ||
      recentlyHandledInvitationId.current === invitation.inviteId
    ) {
      return;
    }

    recentlyHandledInvitationId.current = invitation.inviteId;

    // If user is a student account and he/she/they/them has no chat room yet
    // do nothing (invitation will be handled through start chat room button)
    if (chatRooms?.edges.length === 0 && amIStudent) {
      return;
    }

    const { inviteType } = invitation ?? {};

    // invite/student is slightly different from other invitations
    // as student account and chat room were created before student has
    // logged in for the first time. After successful login invitation
    // details are not required any more
    if (inviteType === 'student') {
      invitation.clearInvitation();
      return;
    }

    if (inviteType === 'advisor' || inviteType === 'legacy') {
      // Advisor links are only available for students
      if (!amIStudent) {
        invitation.clearInvitation();
        return;
      }

      // Show invitation modal only if student already has a chat room
      // Otherwise student has to use the onboarding lets go button
      if ((chatRooms?.edges.length ?? 0) > 0) {
        showInvitationModal();
        return;
      }
    }

    if (inviteType === 'conversation') {
      // Students aren't allowed to used conversation links
      if (amIStudent) {
        invitation.clearInvitation();
        return;
      }

      // Advisor will always see an invitation modal
      if (amIVerifiedAdvisor) {
        showInvitationModal();
        return;
      }

      // ServiceProvider will see an invitation modal only if he/she/they/them already has a chat room
      // otherwise service provider will have to use the onboarding lets go button
      if (amIServiceProvider && (chatRooms?.edges.length ?? 0) > 0) {
        showInvitationModal();
        return;
      }

      // FamilyMember can't use invitation if he/she/they/them is/are already in a chat room
      // Otherwise family member will have to use the onboarding lets go button
      if (
        amIFamilyMember &&
        first(chatRooms?.edges)?.node.__typename !== 'RequestorChatRoom'
      ) {
        invitation.clearInvitation();
      }
    }
  }, [
    myId,
    amIStudent,
    amIFamilyMember,
    amIVerifiedAdvisor,
    amIServiceProvider,
    chatRooms,
    invitation,
    createChatRoom,
    showInvitationModal,
    createChatRoomDisabled,
  ]);
}
