import React from 'react';
import { useAtomValue } from 'jotai';

import { ScopeAtom } from './types';

type PermissionGateProps = {
  scope: ScopeAtom;
  children: () => React.ReactNode;
  fallback?: () => React.ReactNode;
};

const PermissionGate: React.FC<PermissionGateProps> = (props) => {
  const { scope, children, fallback } = props;

  const isEnabled = useAtomValue(scope);

  if (!isEnabled) {
    if (fallback) {
      return <>{fallback()}</>;
    }

    return null;
  }

  return <>{children()}</>;
};

export default PermissionGate;
