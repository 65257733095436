import { useCallback } from 'react';

import {
  ChatRoomsByTypenameDocument,
  IsMicrobotActiveDocument,
  MicrobotPersonality,
  useUpdateMicrobotMembershipMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';

const useUpdateMicrobotMembership = (personality?: MicrobotPersonality) => {
  const [updateMicrobotMembership] = useUpdateMicrobotMembershipMutation();

  return useCallback(
    async (toInvite: string[], toRemove: string[]) => {
      if (!personality) {
        return true;
      }

      try {
        await updateMicrobotMembership({
          refetchQueries: [
            IsMicrobotActiveDocument,
            ChatRoomsByTypenameDocument,
          ],
          variables: {
            personality,
            toInvite,
            toRemove,
          },
        });

        return true;
      } catch (error) {
        Sentry.captureException(error);
        showToast({
          iconName: 'X',
          variant: 'rose',
          messageI18Key: 'oops-something-went-wrong',
        });

        return false;
      }
    },
    [personality, updateMicrobotMembership],
  );
};

export default useUpdateMicrobotMembership;
