import React from 'react';
import { useTranslation } from 'react-i18next';

import { Capability } from '@advisor/microbots/hooks/useMicrobot';
import { IconName } from '@advisor/design/components/Icon';
import { Layout, Icon } from '@advisor/design/components';
import withSeparators from '@advisor/utils/withSeparators';
import { Box, BoxTitle } from './components';

interface MicrobotCapabilitiesProps {
  capabilities: Capability[];
}

const MicrobotCapabilities: React.FC<MicrobotCapabilitiesProps> = ({
  capabilities,
}) => {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <Box className="flex-1">
      <BoxTitle>{t('microbot:capabilities')}</BoxTitle>
      <Layout.Spacer.Vertical size="small01" />
      {withSeparators(
        capabilities,
        (key) => (
          <Separator key={key} />
        ),
        (capability) => (
          <CapabilitySection
            icon={capability.iconName}
            key={capability.key}
            title={t(capability.title)}
            description={t(capability.description)}
          />
        ),
      )}
    </Box>
  );
};

export default MicrobotCapabilities;

const CapabilitySection: React.FC<{
  title: string;
  icon: IconName;
  description: string;
}> = ({ title, icon, description }) => (
  <div className="flex flex-row">
    <div className="w-10 h-10">
      <Icon name={icon} size={40} className="text-white xl:text-light-grey" />
    </div>
    <Layout.Spacer.Horizontal size="tiny" />
    <div>
      <h5 className="font-outfit text-sm font-semibold leading-4-5 text-dark-grey-03">
        {title}
      </h5>
      <Layout.Spacer.Vertical size="atomic" />
      <p className="font-outfit text-sm font-medium leading-4-5 text-dark-grey-02">
        {description}
      </p>
    </div>
  </div>
);

const Separator = () => (
  <hr className="my-3 text-transparent xl:my-6 xl:text-grey" />
);
