import SideModalBody from './SideModalBody';
import SideModalRoot from './SideModalRoot';
import SideModalTitle from './SideModalTitle';
import SideModalHeader from './SideModalHeader';
import SideModalDefault from './SideModalDefault';

export default {
  Root: SideModalRoot,
  Body: SideModalBody,
  Title: SideModalTitle,
  Header: SideModalHeader,
  Default: SideModalDefault,
};
