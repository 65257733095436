import cs from 'classnames';
import React, { memo } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Scope } from '@advisor/api/scope';
import { Icon } from '@advisor/design/components';
import { studentJourneyAccessScope } from '../../api/studentJourney';
import { StudentJourneyButtonProps } from './types';

const JourneyButton: React.FC<StudentJourneyButtonProps> = (props) => {
  const { onPress, chatRoomId } = props;
  const { t } = useTranslation('task-organiser');

  const isJourneyEnabled = useAtomValue(
    chatRoomId ? studentJourneyAccessScope(chatRoomId) : Scope.unavailable,
  );

  return (
    <button
      type="button"
      onClick={onPress}
      data-cy="student-journey-button"
      className={cs(
        'text-white rounded-xl p-4 flex flex-row items-center gap-3 w-full font-sora text-sm font-bold',
        'bg-gradient-to-b from-primary-light via-primary to-primary-dark bg-[length:100%_200%] enabled:hover:bg-bottom transition-[background-position] duration-300',
        'disabled:opacity-50',
      )}
      disabled={!isJourneyEnabled}
    >
      <Icon name="StudentJourney" />
      {t('task-organiser')}
    </button>
  );
};

export default memo(JourneyButton);
