import { z } from 'zod';

import { FormInputs } from '@advisor/design/components/Form/types';

export enum SearchModalState {
  FORM = 'FORM',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum SearchFormErrorType {
  INSUFFICIENT_INFO = 'INSUFFICIENT_INFO',
  MISSING_CHATROOM = 'MISSING_CHATROOM',
}

export type SearchFormError =
  | { type: SearchFormErrorType.MISSING_CHATROOM }
  | { type: SearchFormErrorType.INSUFFICIENT_INFO; studentName: string };

export const StudentSearchFormSchema = z.object({
  advisorName: z.string(),
  studentName: z.string().min(1),
  studentPhone: z.string(),
  studentEmail: z.string(),
});

export type StudentSearchFormInputs = FormInputs<
  typeof StudentSearchFormSchema
>;
