import { createContext, useContext } from 'react';

import { Colors } from '../styles';
import type { Theme } from './types';

const ThemeContext = createContext<Theme>({
  organizationLogo: null,
  colors: {
    primary: Colors.primaryBlue,
    primaryDark: Colors.primaryBlueDark,
    primaryLight: Colors.primaryBlueLight,
    primaryLight05: Colors.primaryBlueLight05,
    secondary: Colors.roseSecondary,
    secondaryLight: Colors.roseSecondaryLight,
    secondaryDark: Colors.roseSecondaryDark,
  },
});

export function useTheme() {
  return useContext(ThemeContext);
}

export default ThemeContext;
