import BareModal from './Bare';
import BaseModal from './Modal';
import PopUpHeader from './ModalPopUpHeader';
import Header from './ModalHeader';
import Footer from './ModalFooter';

const Modal = {
  Bare: BareModal,
  Base: BaseModal,
  PopUpHeader,
  Header,
  Footer,
};

export default Modal;
