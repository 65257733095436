import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';

const showPendingStudentTitle = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.verifiedAdvisor,
    Scope.pendingStudentConversation(chatRoomId),
  ]),
);

export default showPendingStudentTitle;
