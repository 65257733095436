import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { DateFormatter } from '@advisor/language';
import { Layout } from '@advisor/design/components';
import { JourneyMilestoneSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import {
  CenteredMessage,
  BoldText,
  Container,
} from '../systemMessageComponents';
import { useOpenJourneyMilestone } from '../navigation';
import type { SystemMessageProps } from '../types';
import MessageButton from '../MessageButton';

const FirstMilestoneCompleted: React.FC<SystemMessageProps> = ({ message }) => {
  const { t } = useTranslation(['common', 'system-message']);
  const data = message.data as JourneyMilestoneSystemMessageInfoFragment;
  const openMilestone = useOpenJourneyMilestone();

  const onPress = useEvent(() =>
    openMilestone(
      message.chatRoomId,
      data.milestone?.milestoneCategoryId,
      data.milestone?.id,
    ),
  );

  const datetime = DateFormatter.dateTime(message.sentAt, t);
  const milestoneName = data.milestone?.name ?? data.metadata.milestoneName;
  const categoryName =
    data.milestoneCategory?.name ?? data.metadata.categoryName;

  return (
    <Container>
      <Trans
        t={t}
        i18nKey="system-message:task-part-of-category-on-your-task-organiser-was-marked-as-complete-on-datetime"
        parent={CenteredMessage}
        values={{
          datetime,
          task: milestoneName,
          category: categoryName,
        }}
        components={{ bold: <BoldText /> }}
      />
      <Layout.Spacer.Vertical size="micro02" />
      <MessageButton label={milestoneName} onPress={onPress} />
    </Container>
  );
};

export default memo(FirstMilestoneCompleted);
