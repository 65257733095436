import cs from 'classnames';
import { noop } from 'lodash-es';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useCallback, Suspense } from 'react';

import MessagesPlaceholder from '@advisor/ui/components/MessagesPlaceholder';
import {
  useChatRoomQuery,
  useChatRoomsQuery,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { Role } from '@advisor/api/user';
import { useMe } from '@advisor/api/me';
import { Icon } from '@advisor/design/components';
import { useRoleRequest } from '@advisor/api/roleRequest';
import { PermissionGate, Scope } from '@advisor/api/scope';
import ChatBottomBar from '@advisor/chat/ChatBottomBar';
import { useShowModal } from '@advisor/design/components/ActionModal';
import Banner from '@advisor/design/components/Banner';
import { useChatRoomSignedCookies } from '@advisor/api/auth';
import useCheckLastMessages from '@advisor/api/hooks/useCheckLastMessages';
import { useHandleInvitation } from '@advisor/chat/Invitations';
import VideoCallHeader from '@advisor/chat/VideoCallHeader';
import MessageList from '@advisor/chat/MessageList';
import { useCallState, useConnect } from '@advisor/videoCall';
import useConfirmPendingStudent from '@advisor/onboarding/useConfirmPendingStudent';
import StudentJourneyLiveUpdateProvider from '@advisor/journey/components/StudentJourneyLiveUpdateProvider';
import AspiringAdvisor from '@advisor/chat/AspiringAdvisor';
import ApproveRoleRequestBanner from '@advisor/chat/ApproveRoleRequestBanner';
import JoinRequests from '@advisor/chat/JoinRequestBanner';
import PrimaryAdvisor from '@advisor/chat/PrimaryAdvisor';
import FamilyMember from '@advisor/chat/FamilyMember';
import Student from '@advisor/chat/Student';
import useChatIdParam from 'src/hooks/useChatIdParam';
import lastChatRoomAtom from 'src/atoms/lastChatRoomAtom';

const StudentOrAdvisorScope = Scope.some([
  Scope.student,
  Scope.verifiedAdvisor,
]);

type Props = {
  className?: string;
};

const NoConversationSelected = () => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col m-auto">
      <Icon name="Message" className="text-primary w-8 h-8" />
      <p>{t('no-conversation-selected')}</p>
      <p>{t('please-select-a-conversation-to-view-further-details')}</p>
    </div>
  );
};

const ChatLoader = () => (
  <div className="grow">
    <div className="xl:w-chat-width xl:mx-auto py-4">
      <MessagesPlaceholder />
    </div>
  </div>
);

const Chat = ({ className }: Props) => {
  const chatId = useChatIdParam();
  const navigate = useNavigate();
  const roleRequest = useRoleRequest();

  const setLastChatRoom = useSetAtom(lastChatRoomAtom);
  useEffect(() => {
    setLastChatRoom(chatId);
  }, [chatId, setLastChatRoom]);

  const me = useMe();
  const { data: { chatRooms } = {} } = useChatRoomsQuery();

  const onInvitationSuccess = useCallback(
    (chatRoomId: string) => {
      navigate(
        `/chat/${chatRoomId}`,
        Role.isSingleChatUser(me) ? { replace: true } : undefined,
      );
    },
    [navigate, me],
  );

  // Handle existing user trying to open referral link
  useHandleInvitation({ onSuccess: onInvitationSuccess });
  useConfirmPendingStudent();

  const content = (() => {
    if (chatId) {
      return <ChatContent chatId={chatId} />;
    }

    if (
      Role.isVerifiedAdvisor(me) ||
      (Role.isServiceProvider(me) && chatRooms && chatRooms.edges.length > 0)
    ) {
      return <NoConversationSelected />;
    }

    if (Role.isServiceProvider(me)) {
      return <ChatContent chatId={chatId} />;
    }

    if (Role.isSingleChatUser(me) && chatRooms) {
      if (chatRooms.edges.length > 0) {
        return <Navigate to={`/chat/${chatRooms?.edges[0].node.id}`} />;
      }

      if (Role.isStudent(me) && chatRooms.edges.length === 0) {
        return <ChatContent chatId={chatId} />;
      }

      if (Role.isAspiringAdvisor(me) && chatRooms.edges.length === 0) {
        return <ChatContent chatId={roleRequest?.chatRoom.id} />;
      }

      if (Role.isFamilyMember(me) && chatRooms.edges.length === 0) {
        return <ChatContent chatId={chatId} />;
      }
    }

    return <ChatLoader />;
  })();

  return (
    <div
      className={cs('bg-light-grey flex flex-col min-h-0', className)}
      data-cy="chat-root"
    >
      <Suspense fallback={<ChatLoader />}>{content}</Suspense>
    </div>
  );
};

const ChatContent = ({ chatId }: { chatId?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const showModal = useShowModal();

  const { error: chatRoomQueryError } = useChatRoomQuery({
    variables: { chatRoomId: chatId ?? '' },
    skip: !chatId,
  });

  useChatRoomSignedCookies(chatId);
  useCheckLastMessages(chatId);
  const callState = useCallState();
  const connect = useConnect();

  const openVideoCall = useCallback(async () => {
    if (!chatId) {
      return;
    }

    if (!callState) {
      connect(chatId);
    }

    navigate(`/video-call/${chatId}`);
  }, [chatId, navigate, connect, callState]);

  const handleNavigateToNewChat = useCallback(
    (newChatId: string) => {
      navigate(`/chat/${newChatId}`);
    },
    [navigate],
  );

  // Handle chat room query errors
  useEffect(() => {
    if (chatRoomQueryError) {
      const errorTypes = chatRoomQueryError.graphQLErrors.map(
        // errorType is provided by server
        (gqlError) => (gqlError as { errorType?: string }).errorType,
      );
      if (errorTypes.includes('UserIsNotChatRoomMemberError')) {
        navigate('/chat');
      } else {
        Sentry.captureException(chatRoomQueryError);
        showModal.info({
          title: t('oops-something-went-wrong'),
          theme: 'warning',
          message: '',
          options: [],
        });
      }
    }
  }, [chatRoomQueryError, showModal, navigate, t]);

  return (
    <>
      <StudentJourneyLiveUpdateProvider chatRoomId={chatId} />
      {!!chatId && (
        <VideoCallHeader chatId={chatId} onOpenVideoCall={openVideoCall} />
      )}
      <Banner />
      <PermissionGate scope={Scope.approvingAdvisor}>
        {() => !!chatId && <ApproveRoleRequestBanner chatRoomId={chatId} />}
      </PermissionGate>
      <PermissionGate scope={Scope.familyMember}>
        {() =>
          !!chatId && <FamilyMember.ChatNotifications chatRoomId={chatId} />
        }
      </PermissionGate>
      <PermissionGate scope={Scope.aspiringAdvisor}>
        {() =>
          !!chatId && <AspiringAdvisor.ChatNotifications chatRoomId={chatId} />
        }
      </PermissionGate>
      <PermissionGate scope={Scope.student}>
        {() => !!chatId && <Student.ChatNotifications chatRoomId={chatId} />}
      </PermissionGate>
      <PermissionGate scope={Scope.primaryAdvisor}>
        {() => !!chatId && <PrimaryAdvisor.ChatNotifications />}
      </PermissionGate>
      <PermissionGate scope={StudentOrAdvisorScope}>
        {() => chatId && <JoinRequests chatRoomId={chatId} />}
      </PermissionGate>
      <MessageList
        chatRoomId={chatId}
        onNavigateToNewChat={handleNavigateToNewChat}
        onOpenUserMessageMenu={noop}
      />
      <ChatBottomBar chatRoomId={chatId} />
    </>
  );
};

export default Chat;
