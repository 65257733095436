import { pipe } from 'remeda';
import { ExtractAtomArgs, Getter, atom } from 'jotai';
import type { OperationVariables } from '@apollo/client';

import soon from '@advisor/utils/soon';
import type { DocumentNode } from '@advisor/api/generated/graphql';
import { isAuthorizedAtom } from '@advisor/api/auth/isAuthorized';
import atomWithQuery from './atomWithQuery';

/**
 * A small wrapper around `atomWithQuery` that provides the necessary boilerplate.
 */
export default function atomOfQuery<
  Data,
  Variables extends object = OperationVariables,
>(
  query: DocumentNode<Data, Variables>,
  variables?: Variables | ((get: Getter) => Variables),
) {
  const queryAtom = atomWithQuery((get) => {
    const vars = typeof variables === 'function' ? variables(get) : variables;

    return {
      query,
      variables: vars,
    };
  });

  return atom(
    (get) =>
      pipe(
        // NOTE: Probably will no longer be necessary once
        // every query is gated behind authorization.
        get(isAuthorizedAtom),
        soon((isAuthorized) =>
          isAuthorized ? get(queryAtom) : { data: undefined },
        ),
      ),
    (_get, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
      set(queryAtom, ...args),
  );
}
