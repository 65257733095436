import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';

import {
  useMilestoneQuery,
  MilestoneInfoFragment,
  useCompleteMilestoneMutation,
  CompleteMilestoneMutationOptions,
  useMarkMilestoneAsIncompleteMutation,
  MarkMilestoneAsIncompleteMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { useAmIAdvisor, useAmIStudent } from '@advisor/api/me';
import { completeMilestoneModalAtom } from './atoms';

const useCompleteMilestoneModal = () => {
  const [modalState, setModalState] = useAtom(completeMilestoneModalAtom);

  const amIAdvisor = useAmIAdvisor();
  const amIStudent = useAmIStudent();
  const { data: { milestone } = {} } = useMilestoneQuery({
    variables: {
      milestoneId: modalState.visible ? modalState.milestoneId : '',
    },
    skip: !modalState.visible,
  });

  const [completeMilestone, { loading: loadingCompleteMutation }] =
    useCompleteMilestoneMutation(
      updateCategoriesAfterComplete(amIAdvisor, milestone),
    );

  const [incompleteMilestone, { loading: loadingIncompleteMutation }] =
    useMarkMilestoneAsIncompleteMutation(
      updateCategoriesAfterIncomplete(milestone),
    );

  const onCloseModal = useEvent(() => {
    setModalState({ visible: false });
  });

  const onCompleteMilestone = useEvent(async () => {
    if (!modalState.visible) {
      return;
    }

    const { milestoneId } = modalState;

    try {
      await completeMilestone({ variables: { milestoneId } });

      if (amIAdvisor) {
        onCloseModal();
        showToast({
          variant: 'blue',
          iconName: 'CircleCheck',
          namespace: 'task-organiser',
          messageI18Key: 'selected-task-was-marked-complete',
        });
      }
    } catch (err) {
      onCloseModal();

      Sentry.captureException(err);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  });

  const onIncompleteMilestone = useEvent(async () => {
    if (!modalState.visible) {
      return;
    }

    const { milestoneId } = modalState;

    try {
      await incompleteMilestone({ variables: { milestoneId } });
      onCloseModal();
      showToast({
        variant: 'blue',
        iconName: 'CircleCheck',
        namespace: 'task-organiser',
        messageI18Key: 'selected-task-was-marked-incomplete',
      });
    } catch (err) {
      onCloseModal();

      Sentry.captureException(err);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  });

  const completed = (() => {
    if (amIStudent && milestone?.pendingReview) {
      return true;
    }
    return milestone?.isCompleted ?? false;
  })();

  // Forbid closing modal when mutation is in progress
  const closeDisabled = loadingCompleteMutation || loadingIncompleteMutation;

  return {
    milestone,
    completed,
    modalState,
    closeDisabled,

    onCloseModal,
    onCompleteMilestone,
    onIncompleteMilestone,
  };
};

export const useCloseCompleteMilestoneModal = () => {
  const setModalState = useSetAtom(completeMilestoneModalAtom);

  return useEvent(() => {
    setModalState({ visible: false });
  });
};

export const useOpenCompleteMilestoneModal = (milestoneId: string) => {
  const setModalState = useSetAtom(completeMilestoneModalAtom);

  return useEvent(() => {
    setModalState({ visible: true, milestoneId });
  });
};

export default useCompleteMilestoneModal;

const updateCategoriesAfterComplete = (
  isAdvisor: boolean,
  milestone?: MilestoneInfoFragment,
): CompleteMilestoneMutationOptions => ({
  update(cache, result) {
    const categoryId = result.data?.completeMilestone.milestoneCategoryId;

    if (!categoryId) {
      return;
    }

    const id = cache.identify({
      __typename: 'MilestoneCategory',
      id: categoryId,
    });

    if (!id) {
      return;
    }

    const wasOverdue =
      milestone?.notificationTime &&
      dayjs(milestone.notificationTime).diff(dayjs()) <= 0;
    const wasPending = milestone?.pendingReview ?? false;

    cache.modify({
      id,
      fields: {
        completedMilestones(prev: number) {
          return isAdvisor ? prev + 1 : prev;
        },
        pendingMilestones(prev: number) {
          if (isAdvisor) {
            return wasPending ? prev - 1 : prev;
          }
          return prev + 1;
        },
        overdueMilestones(prev: number) {
          return wasOverdue ? prev - 1 : prev;
        },
      },
    });
  },
});

const updateCategoriesAfterIncomplete = (
  milestone?: MilestoneInfoFragment,
): MarkMilestoneAsIncompleteMutationOptions => ({
  update(cache, result) {
    const categoryId =
      result.data?.markMilestoneAsIncomplete.milestoneCategoryId;

    if (!categoryId) {
      return;
    }

    const id = cache.identify({
      __typename: 'MilestoneCategory',
      id: categoryId,
    });

    if (!id) {
      return;
    }

    const wasPending = milestone?.pendingReview ?? false;
    const wasOverdue =
      milestone?.notificationTime &&
      dayjs(milestone.notificationTime).diff(dayjs()) <= 0;

    cache.modify({
      id,
      fields: {
        completedMilestones(prev: number) {
          return wasPending ? prev : prev - 1;
        },
        pendingMilestones(prev: number) {
          return wasPending ? prev - 1 : prev;
        },
        overdueMilestones(prev: number) {
          return wasOverdue ? prev + 1 : prev;
        },
      },
    });
  },
});
