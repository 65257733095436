import { z } from 'zod';

import Sentry from '@advisor/utils/Sentry';
import type { AdvertisementInfoFragment } from '@advisor/api/generated/graphqlTypes';

export type BackgroundStyleSchema = z.infer<typeof BackgroundStyleSchema>;
export const BackgroundStyleSchema = z.object({
  color: z.string().optional(),
});

export default function parseBackgroundStyle(
  advert: AdvertisementInfoFragment,
) {
  try {
    return BackgroundStyleSchema.parse(
      JSON.parse(advert?.backgroundStyle ?? '{}'),
    );
  } catch (e) {
    Sentry.captureException(e);
  }

  return {};
}
