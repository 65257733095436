import { useMemo } from 'react';

import { UserInfoFragmentDoc } from '../generated/graphqlTypes';
import useCompoundFragment from '../apollo/useCompoundFragment';
import { UserTypeNames } from './roles';

function useCachedUser(userId: string | null | undefined) {
  const identifiers = useMemo(() => {
    if (!userId) {
      return [];
    }

    return UserTypeNames.map((typename) => ({
      __typename: typename,
      id: userId,
    }));
  }, [userId]);

  return useCompoundFragment(UserInfoFragmentDoc, identifiers, {
    optimistic: true,
  });
}

export default useCachedUser;
