import React from 'react';

import { TranslatingIndicatorProps } from './types';

function TranslatingIndicator({ status, children }: TranslatingIndicatorProps) {
  // NOTE: Right now, the default behavior for missing translations is assuming that
  // we have not received them yet, which helps with handling translatable content that
  // gets refetched.
  if (status === 'in_progress' || status === 'missing') {
    return <span className="animate-pulse opacity-20">{children}</span>;
  }

  return <>{children}</>;
}

export default TranslatingIndicator;
