import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import timezone from 'dayjs/plugin/timezone';

import {
  UpdateMilestoneMutationOptions,
  MilestoneInfoFragment,
  UpdateMilestoneDocument,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { clientAtom } from '@advisor/api/apollo';
import { actionAtom } from '@advisor/utils/atoms';
import { studentJourneyOwnerAtoms } from '../../api/studentJourney';
import { normalizeDueDateForBackend } from './utils';

dayjs.extend(timezone);

const createMutationConfig = (
  oldMilestone: MilestoneInfoFragment,
  studentTimezone: string | undefined | null,
): UpdateMilestoneMutationOptions => ({
  optimisticResponse({ milestoneId, name, description, dueDate }) {
    // Milestone query returns only on notificationTime,
    // while mutations accepts dueDate parameter
    const notificationTime = (() => {
      if (!dueDate || dueDate === 'NIL') {
        return oldMilestone.notificationTime;
      }

      if (!studentTimezone) {
        return dueDate;
      }

      // Backend calculates notification time based on DueDate,
      // it overwrites the timezone of the date to the timezone of the student.
      return dayjs(dueDate).tz(studentTimezone, true).toISOString();
    })();

    return {
      __typename: 'Mutation',
      updateMilestone: {
        ...oldMilestone,
        milestoneId,
        name: name ?? oldMilestone.name,
        description: description ?? oldMilestone.description,
        notificationTime,
        pendingReview: oldMilestone.pendingReview,
      },
    };
  },
});

const updateMilestoneAtom = actionAtom(
  async (
    { get },
    chatRoomId: string,
    milestone: MilestoneInfoFragment,
    values: {
      name?: string | undefined;
      description?: string | undefined;
      dueDate?: Date | undefined;
    },
  ) => {
    const client = await get(clientAtom);
    const studentJourneyOwner = await get(studentJourneyOwnerAtoms(chatRoomId));

    if (!studentJourneyOwner) {
      return false;
    }

    try {
      const result = await client.mutate({
        mutation: UpdateMilestoneDocument,
        ...createMutationConfig(milestone, studentJourneyOwner.member.timezone),
        variables: {
          milestoneId: milestone.id,
          name: values.name,
          description: values.description || null,
          dueDate: normalizeDueDateForBackend(values.dueDate),
        },
      });

      if (!!result.errors && result.errors.length > 0) {
        return false;
      }
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }

    return true;
  },
);

function useUpdateMilestone() {
  return useSetAtom(updateMilestoneAtom);
}

export default useUpdateMilestone;
