import Root from './TooltipRoot';
import Content from './TooltipContent';
import SimpleContent from './SimpleTooltipContent';
import { TooltipPosition } from './types';

export default {
  Root,
  Content,
  SimpleContent,
  Position: TooltipPosition,
};
