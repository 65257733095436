import { loadable } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import authStateAtom from './authStateAtom';

export const isAuthResolved = atom(
  (get) => get(loadable(authStateAtom)).state !== 'loading',
);

export function useIsAuthResolved() {
  return useAtomValue(isAuthResolved);
}
