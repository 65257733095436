import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { onMessage } from 'firebase/messaging';

import { usePushNotifications } from '@advisor/api/PushNotifications';
import * as Firebase from '@advisor/api/PushNotifications/firebase';
import { useMe } from '@advisor/api/me';

function PushNotifications() {
  const isLogged = !!useMe();
  const { enabled, registerForNotifications } = usePushNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged || !enabled) {
      return;
    }

    registerForNotifications();
  }, [registerForNotifications, isLogged, enabled]);

  useEffect(() => {
    onMessage(Firebase.messaging, (payload) => {
      if (payload.notification !== undefined) {
        const notificationTitle = payload.notification.title as string;
        const notificationOptions = {
          body: payload.notification.body,
          icon: '/logo-notifications.png',
          data: {
            chatRoomId:
              payload.data !== undefined ? payload.data.chatRoomId : '',
          },
        };

        const notification = new Notification(
          notificationTitle,
          notificationOptions,
        );

        notification.onclick = (event) => {
          if (event.target != null) {
            (event.target as Notification).close();

            navigate(`/chat/${(event.target as Notification).data.chatRoomId}`);
          }
        };
      }
    });
  }, [navigate]);

  return null;
}

export default memo(PushNotifications);
