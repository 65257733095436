import React from 'react';
import cs from 'classnames';

import { SpinnerProps } from './types';

const Spinner = ({ className }: SpinnerProps) => (
  <svg viewBox="0 0 24 24" className={cs('spinner', className)}>
    <circle
      className="spinner-circle"
      cx="12"
      cy="12"
      r="8"
      fill="none"
      strokeWidth="3"
      stroke="currentColor"
    />
  </svg>
);

export default Spinner;
