import React from 'react';
import cs from 'classnames';

import type { ModalFooterProps } from './types';

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const { children, direction = 'row' } = props;

  return (
    <div
      className={cs(
        'flex px-8 py-6 gap-6 border-t border-grey rounded-t',
        direction === 'row'
          ? 'flex-row items-center'
          : 'flex-col items-stretch',
      )}
    >
      {children}
    </div>
  );
};

export default ModalFooter;
