import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

import { primaryLanguagesAtom } from '../utils';
import userLanguageAtom from './userLanguageAtom';

const uiLanguageAtom = atom((get) => {
  const userLanguage = get(userLanguageAtom) ?? 'en';

  const primaryLanguages = get(unwrap(primaryLanguagesAtom)) ?? [];

  return primaryLanguages.includes(userLanguage) ? userLanguage : 'en';
});

export default uiLanguageAtom;
