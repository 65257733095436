import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import Env from '../env';

export const app = initializeApp({
  apiKey: Env.firebaseMessaging.apiKey,
  authDomain: Env.firebaseMessaging.authDomain,
  projectId: Env.firebaseMessaging.projectId,
  storageBucket: Env.firebaseMessaging.storageBucket,
  messagingSenderId: Env.firebaseMessaging.messagingSenderId,
  appId: Env.firebaseMessaging.appId,
  measurementId: Env.firebaseMessaging.measurementId,
});

export const messaging = getMessaging(app);
