import { uniqueId } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import {
  FileSource,
  PrivateFileScope,
  useDeleteFileMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { FileData, useFileUpload } from '@advisor/api/files';
import { showToast } from '@advisor/design/components/Toast';
import {
  isBeingUploaded,
  isUploaded,
} from '@advisor/design/components/FileAttachment';
import {
  CommentAttachment,
  CommentInputProps,
  UploadedCommentAttachment,
} from './types';
import useAddMilestoneComment from './useAddMilestoneComment';
import { ChatRoomFileSizeThresholdMB } from './utils';

const useCommentInput = (props: CommentInputProps) => {
  const { chatRoomId, milestoneCategoryId, milestoneId } = props;
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<CommentAttachment[]>([]);

  const uploadFile = useFileUpload(PrivateFileScope.ChatRoom, {
    chatRoomId,
    fileSource: FileSource.MilestoneComment,
    sizeThreshold: ChatRoomFileSizeThresholdMB,
  });
  const [deleteFile] = useDeleteFileMutation();
  const addMilestoneComment = useAddMilestoneComment(
    chatRoomId,
    milestoneCategoryId,
    milestoneId,
  );

  const canSubmit = message.length > 0 || attachments.length > 0;

  const handleAddAttachment = useCallback(
    async (file: FileData) => {
      const upload = uploadFile(file);
      const newAttachment: CommentAttachment = {
        ...upload,
        file,
        uploadId: uniqueId(),
      };
      setAttachments((prev) => [...prev, newAttachment]);

      const uploadResult = await upload.promise;

      if (uploadResult.ok) {
        const uploadedAttachment: CommentAttachment = {
          file: uploadResult.data,
          uploadId: newAttachment.uploadId,
        };

        setAttachments((prev) =>
          prev.map((attachment) =>
            attachment.uploadId === newAttachment.uploadId
              ? uploadedAttachment
              : attachment,
          ),
        );

        return;
      }

      const failedAttachment: CommentAttachment = {
        uploadId: newAttachment.uploadId,
        file: newAttachment.file,
        error: uploadResult.error,
      };

      setAttachments((prev) =>
        prev.map((attachment) =>
          attachment.uploadId === newAttachment.uploadId
            ? failedAttachment
            : attachment,
        ),
      );
    },
    [uploadFile],
  );

  const handleRemoveAttachment = useCallback(
    async (attachment: CommentAttachment) => {
      const { uploadId } = attachment;
      setAttachments((prev) => prev.filter((a) => a.uploadId !== uploadId));

      if (isBeingUploaded(attachment)) {
        attachment.cancel();
      }

      if (isUploaded(attachment)) {
        try {
          await deleteFile({ variables: { fileId: attachment.file.id } });
        } catch (e) {
          Sentry.captureException(e);
          showToast({
            messageI18Key: 'oops-something-went-wrong',
            variant: 'rose',
            iconName: 'X',
          });
        }
      }
    },
    [deleteFile],
  );

  const attachmentsUploaded = useMemo(
    () => attachments.every(isUploaded),
    [attachments],
  );

  const handleSubmit = useEvent(() => {
    const filteredAttachments = attachments.filter(
      (attachment): attachment is UploadedCommentAttachment =>
        isUploaded(attachment),
    );

    setMessage('');
    setAttachments([]);

    addMilestoneComment(message, filteredAttachments);
  });

  return {
    message,
    canSubmit,
    attachments,
    attachmentsUploaded,
    setMessage,
    handleSubmit,
    handleAddAttachment,
    handleRemoveAttachment,
  };
};

export default useCommentInput;
