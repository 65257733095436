import z from 'zod';
import React from 'react';
import { Atom } from 'jotai';

import { LanguageCode } from '@advisor/language';

type PromiseOrValue<T> = Promise<T> | T;

export const TranslationToken = {
  InProgress: 'IN_PROGRESS',
  Original: 'ORIGINAL',
} as const;

export interface RawStatus {
  type: 'raw';
}

export interface InProgressStatus {
  type: 'in_progress';
}

export interface TranslatedStatus {
  type: 'translated';
  language: LanguageCode;
}

/**
 * No translation found for the requested language.
 */
export interface MissingStatus {
  type: 'missing';
}

export type TranslationStatus =
  | RawStatus
  | InProgressStatus
  | TranslatedStatus
  | MissingStatus;

export type LangOrToken = z.infer<typeof LangOrToken>;
export const LangOrToken = z.union([
  z.literal(TranslationToken.InProgress),
  z.literal(TranslationToken.Original),
  LanguageCode,
]);

export enum AutotranslationState {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Loading = 'Loading',
}

export type ContentTranslationStatuses = z.infer<
  typeof ContentTranslationStatuses
>;
export const ContentTranslationStatuses = z.record(
  z.string(),
  LangOrToken.nullable(),
);

export enum TranslationAction {
  Translate,
  UndoTranslation,
}

export enum ToggleTranslationAction {
  PromptTranslationPreference,
  PromptUndoTranslationPreference,
  Translate,
  UndoTranslation,
}

export interface TranslationStatusBoxProps {
  /**
   * Only used on the Web side.
   */
  width: 'fit-content' | 'full';
  status: TranslationStatus;
  detectedLanguage: string;
  autotranslationState: AutotranslationState | null;
  onToggleTranslation: () => void;
}

export type TranslatingIndicatorProps = {
  status: TranslationStatus['type'];
  children?: React.ReactNode;
};

export type RawTranslatable = {
  __typename: string;
  id?: string;
  /**
   * Can hold a special value (@see DefaultItemChatRoomId) if the translatable is a default item.
   */
  chatRoomId?: string;
  translations: string;
  translationStatus?: string;
  detectedLanguage: string;
};

export type TranslateOptimisticFields = {
  translations: string;
  translationStatus: string;
};

export type TranslationAtoms = {
  statusAtom: Atom<PromiseOrValue<TranslationStatus | undefined>>;
  translationAtom: Atom<PromiseOrValue<unknown>>;
  translatableAtom: Atom<PromiseOrValue<RawTranslatable | null | undefined>>;
  targetLanguageAtom: Atom<PromiseOrValue<LanguageCode | undefined>>;
  autotranslationStateAtom: Atom<
    PromiseOrValue<AutotranslationState | undefined>
  >;
  shouldTranslationBeDisabledAtom: Atom<PromiseOrValue<boolean>>;
};

export const DefaultItemChatRoomId = 'default_chat_room';
