import { molecule, use } from 'bunshi';

import Logger from '@advisor/utils/Logger';
import { actionAtom } from '@advisor/utils/atoms';
import { ShowModal } from '@advisor/design/components/ActionModal';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import { translationAtoms } from '@advisor/language';
import {
  AutotranslationState,
  getContentTranslationToggleAction,
  ToggleTranslationAction,
  TranslationAction,
} from '../contentTranslation';
import { requireMessageScope } from '../messaging/messageMolecule';
import { autotranslationStateAtoms } from './autotranslationStateAtoms';
import MessageTranslationMolecule from './messageTranslationMolecule';
import {
  disableAutotranslationAtom,
  enableAutotranslationAtom,
} from './autotranslationActionAtoms';

const promptAtom = actionAtom(
  async ({ get, set }, action: 'translate' | 'undo') => {
    const t = await get(translationAtoms('common'));

    return ShowModal.decide(
      { get, set },
      {
        title: t(
          action === 'translate'
            ? 'would-you-like-to-translate-this-whole-conversation'
            : 'would-you-like-to-restore-translation',
        ),
        message: t(
          action === 'translate'
            ? 'this-would-translate-all-conversation-to-your-preferred-langauge'
            : 'this-will-restore-all-messages-in-conversation-back-into-original-language',
        ),
        dismissible: true,
        options: [
          {
            key: 'all' as const,
            label: t('yes'),
            variant: 'positive',
          },
          {
            key: 'single' as const,
            label: t('no-just-this-message'),
            variant: 'neutral',
          },
        ],
      },
    );
  },
);

const ToggleTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const messageId = requireMessageScope();
  const { translationAtom, statusAtom } = use(MessageTranslationMolecule);

  const toggleTranslationAtom = actionAtom(async ({ get, set }) => {
    const action = getContentTranslationToggleAction(
      (await get(statusAtom))?.type ?? 'raw',
      (await get(autotranslationStateAtoms(chatRoomId))) !==
        AutotranslationState.Disabled,
    );

    switch (action) {
      case ToggleTranslationAction.PromptTranslationPreference:
        {
          const decision = await set(promptAtom, 'translate');

          if (decision === 'all') {
            set(enableAutotranslationAtom, chatRoomId);
          } else if (decision === 'single') {
            set(translationAtom, TranslationAction.Translate);
          }
        }
        break;
      case ToggleTranslationAction.PromptUndoTranslationPreference:
        {
          const decision = await set(promptAtom, 'undo');

          if (decision === 'all') {
            set(disableAutotranslationAtom, chatRoomId);
          } else if (decision === 'single') {
            set(translationAtom, TranslationAction.UndoTranslation);
          }
        }
        break;
      case ToggleTranslationAction.Translate:
        await set(translationAtom, TranslationAction.Translate);
        break;
      case ToggleTranslationAction.UndoTranslation:
        await set(translationAtom, TranslationAction.UndoTranslation);
        break;
      default:
        Logger.warn(
          `Unknown translation state for milestone category: ${messageId}`,
        );
    }
  });

  return {
    toggleTranslationAtom,
  };
});

export default ToggleTranslationMolecule;
