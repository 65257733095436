import { parseLanguageCode } from '../languages';
import type { DetectSystemLanguageFn } from './types';

const detectSystemLanguage: DetectSystemLanguageFn = async () => {
  const result = navigator.language;
  const langCode = typeof result === 'string' ? result : result?.[0];

  return parseLanguageCode(langCode);
};

export default detectSystemLanguage;
