import React, { memo, useMemo } from 'react';
import cs from 'classnames';

import { SpinnerIcon } from '../Icon';
import type { ButtonProps } from './types';
import { Label } from './webComponents';
import Bare from './Bare';

const VariantClasses = {
  primaryBlue: ['border-primary text-primary before:bg-primary'],
  primaryBlueLight: ['border-primary-light text-primary before:bg-primary'],
  purpleDark: ['border-purple-dark text-secondary-dark before:bg-black'],
  roseSecondaryLight: [
    'border-rose-secondary-light text-rose-secondary-dark before:bg-rose-secondary-dark',
  ],
  negative: ['border-negative text-negative before:bg-black'],
  white: ['border-dark-grey-03 text-dark-grey-03 before:bg-black'],
  primary: ['border-primary text-primary'],
  primaryDark: ['border-primary-dark text-primary-dark'],
  primaryLight: ['border-primary-light text-primary-light'],
  secondary: ['border-secondary text-secondary'],
  secondaryDark: ['border-secondary-dark text-secondary-dark'],
} as const;

const OutlineButton = ({
  Icon,
  label,
  variant = 'primary',
  children,
  className,
  disabled,
  ...rest
}: ButtonProps) => {
  const variantOrDefault = useMemo(
    () =>
      variant && variant in VariantClasses
        ? (variant as keyof typeof VariantClasses)
        : 'white',
    [variant],
  );

  const [variantClass] = useMemo(
    () => VariantClasses[variantOrDefault],
    [variantOrDefault],
  );

  return (
    <Bare
      {...rest}
      disabled={disabled}
      className={cs(
        'relative rounded-lg py-2 px-3 font-medium border',
        !disabled &&
          `before:content-[''] before:absolute before:inset-0 before:opacity-0 hover:before:opacity-5 before:rounded-lg before:transition-all`,
        disabled && 'opacity-40',
        variantClass,
        className,
      )}
    >
      {({ loading }) =>
        loading ? (
          <SpinnerIcon className="w-6 h-6 mx-auto" />
        ) : (
          <>
            {!!Icon && <Icon />}
            {!!label && <Label className={variantClass}>{label}</Label>}
            {children}
          </>
        )
      }
    </Bare>
  );
};

export default memo(OutlineButton);
