import React from 'react';
import cs from 'classnames';

import Icon from '../Icon';
import studyAbroadSimplified from './onboarding-study-aboard-simplified.jpg';
import { RootProps, TextProps } from './types';

export const Title = ({ children }: TextProps) => {
  return (
    <h3 className="font-sora text-primary font-bold text-center mb-0.5">
      {children}
    </h3>
  );
};

export const SubTitle = ({ children }: TextProps) => {
  return (
    <h4 className="text-sm font font-semibold text-center mb-4">{children}</h4>
  );
};

export const Description = ({ children }: TextProps) => {
  return <p className="text-center text-xs whitespace-pre-wrap">{children}</p>;
};

export const Root = ({ border, children, onClose }: RootProps) => {
  return (
    <div
      className={cs(
        'relative rounded-10 bg-white group/onboarding overflow-clip',
        border && 'border border-grey',
      )}
    >
      <img
        src={studyAbroadSimplified}
        alt="Group of students"
        className="w-full"
      />
      {!!onClose && (
        <button
          type="button"
          onClick={onClose}
          className="text-white absolute top-2 right-2 bg-dark-grey-025 bg-opacity-0 rounded-full group-hover/onboarding:bg-opacity-40 transition-colors"
        >
          <Icon name="X" className="z-40" />
        </button>
      )}
      <div className="p-6 flex flex-col items-center">{children}</div>
    </div>
  );
};
