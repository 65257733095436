import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadingProps } from './types';

const SimpleHeading: React.FC<HeadingProps> = ({ conversationsCount }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <p
        className="font-sora leading-6 font-bold text-dark-grey-03"
        data-cy="conversations"
      >
        {t('conversations')}
      </p>
      {conversationsCount !== undefined && (
        <p className="font-outfit font-semibold text-sm leading-[0.875rem] text-dark-grey-02">
          {t('count-active', {
            chatRoomsCount: String(conversationsCount ?? 0).padStart(2, '0'),
          })}
        </p>
      )}
    </div>
  );
};

export default SimpleHeading;
