import React from 'react';

import { AssistFooterParagraphProps } from './types';

const AssistFooterParagraph: React.FC<AssistFooterParagraphProps> = (props) => {
  const { children } = props;

  return <p>{children}</p>;
};

export default AssistFooterParagraph;
