import { useNavigate } from 'react-router';
import { ReactNode, useContext } from 'react';
import { Icon } from '@advisor/design/components';

import SideModalContext from './context';

type Props = {
  children?: ReactNode;
  backButton?: boolean;
  backButtonDisabled?: boolean;
  onClose?: () => void;
};

const SideModalHeader = (props: Props) => {
  const { children, backButton, backButtonDisabled, onClose } = props;
  const navigate = useNavigate();

  const onGoBack = () => navigate('..', { replace: true, relative: 'path' });

  const { onClose: onCloseModal } = useContext(SideModalContext);

  return (
    <div className="flex h-header-height flex-row gap-4 border-b border-grey bg-white items-center justify-between px-6 shrink-0">
      {/* back arrow */}
      {backButton && (
        <button
          type="button"
          className="text-dark-grey-03 disabled:text-dark-grey-01 hover:opacity-70 transition-opacity"
          disabled={backButtonDisabled}
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              onGoBack();
            }
          }}
        >
          <Icon name="ArrowLeft" className="rtl:-scale-100" />
        </button>
      )}
      <div className="grow">{children}</div>
      {/* Close Button */}
      <button
        data-cy="closeMenuBarButton"
        type="button"
        className="text-dark-grey-01 hover:text-dark-grey-02 transition-colors"
        onClick={() => {
          if (onClose) {
            onClose();
          }
          onCloseModal();
        }}
      >
        <Icon name="X" />
      </button>
    </div>
  );
};

export default SideModalHeader;
