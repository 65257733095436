import { useMemo } from 'react';

import { useIdleTimersValue } from '../../CallTimers';
import { useConnectedParticipants } from '../../Participants';
import { useCallState, VideoCallStatus } from '../../VideoRoomAPI';
import usePermissions from '../../usePermissions';

export enum VideoRoomInfo {
  Joining,
  PermissionsRequired,
  WaitingForPeopleToJoin,
  ItsLonelyInHere,
  OopsSomethingWentWrong,
}

function useInfoToShow() {
  const callState = useCallState();
  const timeLeft = useIdleTimersValue();
  const connectedParticipantCount = useConnectedParticipants().length;
  const { hasAllPermissions } = usePermissions();

  return useMemo(() => {
    if (!hasAllPermissions) {
      return VideoRoomInfo.PermissionsRequired;
    }

    if (callState?.status === VideoCallStatus.Joining) {
      return VideoRoomInfo.Joining;
    }

    if (callState?.status === VideoCallStatus.Error) {
      return VideoRoomInfo.OopsSomethingWentWrong;
    }

    if (connectedParticipantCount > 1) {
      return null;
    }

    if (timeLeft === -1) {
      return VideoRoomInfo.WaitingForPeopleToJoin;
    }

    return VideoRoomInfo.ItsLonelyInHere;
  }, [callState, connectedParticipantCount, timeLeft, hasAllPermissions]);
}

export default useInfoToShow;
