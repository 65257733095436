import React, { ChangeEvent, useEffect, useRef } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar, DeprecatedButton, Layout } from '@advisor/design/components';
import { DateFormatter } from '@advisor/language';
import { CalendarShortFormat } from '@advisor/language/dateFormatter';
import ResizableTextArea from '@advisor/chat/ChatBottomBar/web/ResizableTextArea';
import { EditTabProps } from './types';

const EditTab: React.FC<EditTabProps> = ({
  avatarUrl,
  microbotName,
  lastUpdated,
  limitExceeded,
  primaryContext,
  onSave,
  onSetPrimaryContext,
  onViewGuide,
}) => {
  const { t } = useTranslation(['common', 'microbot']);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onSetPrimaryContext(event.target.value);
  };

  return (
    <>
      {/* Header */}
      <div className="flex-shrink-0 basis-auto pl-8 pr-6 py-6 flex justify-between border-b border-grey">
        <div>
          <h3 className="text-xl leading-6 font-sora font-semibold text-dark-grey-03">
            {t('microbot:primary-context-for-name', { name: microbotName })}
          </h3>
          <h5 className="text-sm leading-[1.125rem] font-outfit text-dark-grey-025">
            {t('microbot:last-updated', {
              dateValue: DateFormatter.calendarShort(
                lastUpdated,
                CalendarShortFormat.DMY,
              ),
            })}
          </h5>
        </div>
        <DeprecatedButton.Text family="Sora" weight={700} tall onPress={onSave}>
          {t('common:save')}
        </DeprecatedButton.Text>
      </div>

      {/* Body */}
      <div
        className={cs(
          'px-8 py-6 flex-1 overflow-y-auto relative',
          limitExceeded ? 'max-h-[29.59375rem]' : 'max-h-[32.5625rem]',
        )}
      >
        {/* Message */}
        <div className="flex flex-col items-center px-8 py-6 h-full">
          <Avatar size={60} avatarUrl={avatarUrl} />
          <Layout.Spacer.Vertical size={20} />
          <p className="font-sora font-bold text-sm leading=[1.125rem] text-dark-grey-03 text-center">
            {t('microbot:setup-primary-context')}
          </p>
          <Layout.Spacer.Vertical size="micro02" />
          <p className="font-outfit text-sm leading-[1.125rem] text-dark-grey-03 text-center md:max-w-[75%]">
            {t(
              'microbot:use-this-space-to-setup-the-default-prompt-for-conversations-with-name',
              { name: microbotName },
            )}
          </p>
          <Layout.Spacer.Vertical size="tiny" />
          <DeprecatedButton.Outline
            className="px-4 text-sm leading-[1.125rem]"
            variant="primaryLight"
            label={t('microbot:how-to-use-primary-context')}
            onPress={onViewGuide}
          />
        </div>

        <Layout.Spacer.Vertical size={28} />

        {/* Text area */}
        <ResizableTextArea
          ref={inputRef}
          className="resize-none w-full focus:outline-none caret-primary text-sm leading-[1.125rem] text-dark-grey-03 font-medium font-outfit"
          value={primaryContext}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default EditTab;
