import cs from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import React, { useContext, ReactNode } from 'react';

import { useLanguage } from '@advisor/language';
import useWindowSize from '../../hooks/useWindowSize';
import Floating from '../Floating';
import { DEFAULT_TRANSITION_CONFIG, DropdownNodeContext } from './context';
import { DropdownItemProps } from './types';

type InnerProps = {
  disabled?: boolean;
  label?: string | ReactNode;
  isRTL: boolean;
  open: boolean;
  testID?: string;
  buttonClassName?: string;
  submenu?: ReactNode;
};

const MobileBreakPoint = 640;

function DropDownItemWithSubmenu(props: InnerProps) {
  const { disabled, isRTL, buttonClassName, label, open, submenu, testID } =
    props;

  const { depth } = useContext(DropdownNodeContext);

  const [windowWidth] = useWindowSize();

  const menuButton = (
    <Menu.Button as={React.Fragment}>
      <button
        type="button"
        className={buttonClassName}
        disabled={disabled}
        data-cy={testID}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {label}
      </button>
    </Menu.Button>
  );

  if (windowWidth < MobileBreakPoint) {
    return (
      <>
        {menuButton}
        <span>
          <Transition show={open} {...DEFAULT_TRANSITION_CONFIG}>
            {/* Anchor attracted to the right of the element so that the nested list appears to the right */}
            <DropdownNodeContext.Provider value={{ depth: depth + 1, open }}>
              {submenu}
            </DropdownNodeContext.Provider>
          </Transition>
        </span>
      </>
    );
  }

  return (
    <div
      className={cs(
        'relative flex flex-col -top-3',
        isRTL ? '-left-[13.25rem]' : 'left-[13.1rem]',
      )}
    >
      <Floating.Parent>
        <span
          className={cs(
            'relative top-3',
            isRTL ? 'left-[13.25rem]' : '-left-[13.1rem]',
          )}
        >
          {menuButton}
        </span>
        <Floating.Content
          show={open}
          alignVertically={Floating.VerticalAlignment.Top}
          alignHorizontally={Floating.HorizontalAlignment.Left}
          overflowBoundsMargin={10}
          transition="fade"
        >
          <DropdownNodeContext.Provider value={{ depth: depth + 1, open }}>
            {submenu}
          </DropdownNodeContext.Provider>
        </Floating.Content>
      </Floating.Parent>
    </div>
  );
}

function DropdownItem({
  label,
  disabled,
  submenu,
  onPress,
  testID,
}: DropdownItemProps) {
  const { isRTL } = useLanguage();

  const makeLabel = (active: boolean, open: boolean) =>
    typeof label === 'function' ? label({ focused: active, open }) : label;

  if (!submenu) {
    return (
      <Menu.Item as="li" className="relative">
        {({ active, close }) => (
          <button
            type="button"
            disabled={disabled}
            className="w-full"
            data-cy={testID}
            onClick={() => {
              close();
              onPress?.();
            }}
          >
            {makeLabel(active, false)}
          </button>
        )}
      </Menu.Item>
    );
  }

  return (
    <Menu.Item as="li" className="relative">
      {({ active }) => (
        <Menu>
          {({ open }) => (
            <DropDownItemWithSubmenu
              open={open}
              isRTL={isRTL}
              testID={testID}
              disabled={disabled}
              label={makeLabel(active, open)}
              submenu={submenu && submenu({ open })}
              buttonClassName="w-full"
            />
          )}
        </Menu>
      )}
    </Menu.Item>
  );
}

export default DropdownItem;
