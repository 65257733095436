import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import suspenseHOC from '@advisor/utils/suspenseHOC';
import { Icon, Modal } from '@advisor/design/components';
import { useScreenBreakpoint } from '@advisor/design/responsive';
import {
  AppStoreLink,
  PlayStoreLink,
} from 'src/components/atoms/AppDownloadLinks';
import useDownloadAppModal from './useDownloadAppModal';

const DownloadMobileAppModal = () => {
  const { t } = useTranslation('common');
  const { visible, onClose } = useDownloadAppModal();

  const atLeastSmallScreen = useScreenBreakpoint('sm');

  return (
    <Modal.Base
      appear
      visible={visible}
      onClose={onClose}
      position="floating"
      maxWidth={atLeastSmallScreen ? 406 : 320}
    >
      <header className="pt-8 sm:pb-8 relative sm:border-b border-grey mb-3 sm:mb-6">
        <h3 className="text-center font-sora text-primary font-semibold text-2xl max-sm:text-sm">
          {t('download-our-app')}
        </h3>
        {/* Close Button */}
        <button
          type="button"
          className="absolute top-4 ltr:right-4 rtl:left-4 text-dark-grey-01 hover:text-dark-grey-02 transition-colors"
          onClick={onClose}
          data-cy="app-download-popup-close-button"
        >
          <Icon name="X" className="w-8 h-8" />
        </button>
      </header>
      <p className="text-center font-outfit text-sm font-medium mb-3 sm:mb-6 mx-8">
        {t('never-miss-an-update-from-platform')}{' '}
        {t('download-our-mobile-app-for-free-now')}
      </p>
      <div className="flex flex-row justify-center gap-4 sm:gap-5 mb-4 sm:mb-8 mx-5">
        <AppStoreLink className="h-8 sm:h-10 rounded-md sm:rounded-lg bg-light-grey hover:bg-white aspect-[3.5] flex items-center justify-center" />
        <PlayStoreLink className="h-8 sm:h-10 rounded-md sm:rounded-lg bg-light-grey hover:bg-white aspect-[3.5] flex items-center justify-center" />
      </div>
      <div className="grow-0">
        {/* A wrapper allows the div below to respect the aspect ratio, since it is not a flex item anymore */}
        <div className="mx-2.5 relative aspect-video">
          <img
            src="/images/homepage/iphone-12-max-bg.svg"
            alt="iPhone 12 Max"
            className="h-full mx-auto"
          />
          <div className="absolute bottom-[7%]">
            <img
              src="/images/homepage/hero-jumbotron.png"
              alt="Pick an advisor"
              className="object-cover object-bottom mx-auto rounded-2xl"
            />
          </div>
        </div>
      </div>
    </Modal.Base>
  );
};

export default memo(suspenseHOC(DownloadMobileAppModal));
