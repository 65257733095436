import { useMemo } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import {
  useConnectedParticipants,
  usePickUniqueParticipants,
} from '../../Participants';
import {
  useRecordingState,
  useRecordingControls,
} from '../../RecordingManager';

export default function useOnStartRecording() {
  const { startRecording } = useRecordingControls();
  const recordingState = useRecordingState();

  const participants = usePickUniqueParticipants(useConnectedParticipants());
  const areThereMultipleParticipants = participants.length > 1;

  const onStartRecording = useEvent(async () => {
    if (!areThereMultipleParticipants) {
      showToast({
        messageI18Key:
          'recording-can-be-enabled-when-there-are-two-or-more-members-in-the-room',
        variant: 'rose',
      });
      return;
    }

    if (!startRecording) {
      showToast({
        messageI18Key:
          'only-the-video-room-owner-has-permission-to-initate-recording-within-the-video-room',
        variant: 'rose',
      });
      return;
    }

    if (recordingState.type === 'ended') {
      showToast({
        messageI18Key: 'recording-can-be-started-only-once-per-session',
        variant: 'rose',
      });
      return;
    }

    if (recordingState.type === 'processing') {
      showToast({
        messageI18Key:
          'cannot-start-new-recording-while-the-previous-session-is-being-processed',
        variant: 'rose',
      });
      return;
    }

    try {
      await startRecording();
    } catch (e) {
      Sentry.captureException(e);

      showToast({
        messageI18Key: 'something-went-wrong-try-again',
        iconName: 'X',
        variant: 'rose',
      });
    }
  });

  const disabled =
    startRecording === null ||
    !areThereMultipleParticipants ||
    recordingState?.type === 'ended';

  return useMemo(
    () => ({
      onStartRecording,
      disabled,
    }),
    [onStartRecording, disabled],
  );
}
