import { Loader } from '@advisor/design/components';
import BasicLayout from './components/layouts/BasicLayout';
import { CallbackLoader, areWeInCallback } from './routes/Callback';

export default function AppLoader() {
  if (areWeInCallback()) {
    return <CallbackLoader />;
  }

  return (
    <BasicLayout>
      <div className="bg-[#F9F9F9] flex flex-col flex-1 items-center justify-center overflow-hidden">
        <Loader />
      </div>
    </BasicLayout>
  );
}
