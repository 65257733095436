import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import React, { useCallback, memo } from 'react';

import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { useAmIAdvisor } from '@advisor/api/me';
import Tooltip from '@advisor/design/components/Tooltip';
import { PendingReviewTooltipProps } from './types';
import TooltipLayout from './TooltipLayout';

const PendingReviewTooltipLocation = new UserMetadataLocation(
  'pendingReview.tooltip',
  z.boolean(),
);

const PendingReviewTooltip: React.FC<PendingReviewTooltipProps> = (props) => {
  const { t } = useTranslation('task-organiser');
  const { children, pendingReview } = props;
  const amIAdvisor = useAmIAdvisor();
  const [hideTooltip, setHideTooltip] = useUserMetadata(
    PendingReviewTooltipLocation,
  );

  const isVisible = pendingReview && !hideTooltip;

  const onClose = useCallback(() => {
    setHideTooltip(true);
  }, [setHideTooltip]);

  if (!amIAdvisor) {
    return children;
  }

  return (
    <Tooltip.Root>
      {children}
      <TooltipLayout
        title={t('complete-task')}
        body={t('please-mark-task-complete-to-update-your-customers-progress')}
        iconName="CircleCheckSmall"
        position={Tooltip.Position.Below}
        isVisible={isVisible}
        elevate={children}
        actions={[
          {
            label: t('done'),
            onPress: onClose,
            variant: 'outline',
          },
        ]}
        onClose={onClose}
      />
    </Tooltip.Root>
  );
};

export default memo(PendingReviewTooltip);
