import { ResourceKey } from 'i18next';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';

export enum Tab {
  Edit,
  Guide,
  Confirm,
}

export interface EditContextModalProps {
  personality: MicrobotPersonality;
  visible: boolean;
  onClose: () => void;
}

export interface EditTabProps {
  microbotName: string;
  avatarUrl: string | undefined | null;
  lastUpdated: string;
  limitExceeded?: boolean;
  primaryContext: string;
  onSave: () => void;
  onSetPrimaryContext: (newValue: string) => void;
  onViewGuide: () => void;
}

export interface GuideTabProps {
  onGoBack: () => void;
}

export interface ConfirmTabProps {
  microbotName: string;
  communityTitle: ResourceKey;
  onSave: () => void;
  onClose: () => void;
}
