/* eslint-disable import/prefer-default-export */

import { ResourceKey } from 'i18next';
import { BrandColor } from './types';

/**
 * @see {@link backend/lambda/utils/mime_types.py CHAT_ROOM_ALLOWED_FILE_TYPES}
 *
 * We need to use actual mime types instead of file extensions for native file picker.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types}
 */
export const SupportedFileTypes = [
  'image/png', // '.png',
  'image/jpeg', // '.jpeg',
  'image/webp', // '.webp',
  'image/svg+xml', // '.svg',
];

export const acceptFileTypes = SupportedFileTypes.join(',');

export const colorDescriptionKeys: Record<BrandColor, ResourceKey> = {
  primaryColor: 'use-this-color-for-branding-elements-like-logos-app-headers',
  secondaryColor:
    'use-this-color-for-secondary-elements-such-as-accents-icons-and-names',
};
