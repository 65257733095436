/* eslint-disable import/prefer-default-export */
import { SuggestionGenerationError } from '@advisor/api/generated/graphqlTypes';

export function suggestionGenerationErrorToTranslationKey(
  error: SuggestionGenerationError | 'TIMEOUT',
) {
  if (error === SuggestionGenerationError.RateLimit || error === 'TIMEOUT') {
    return 'the-ai-is-experiencing-high-load-and-is-unable-to-respond-at-this-moment';
  }

  return 'something-went-wrong-try-again';
}
