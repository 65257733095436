import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import DeprecatedButton from '../DeprecatedButton';
import { ErrorViewProps } from './types';

const ErrorView: React.FC<ErrorViewProps> = ({ onTryAgain }) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col items-center gap-2">
      <Icon name="ErrorCircle" className="w-16 h-16 text-dark-grey-03" />
      <h4>{t('oops-something-went-wrong')}</h4>
      <DeprecatedButton.Large
        onPress={onTryAgain}
        label={t('try-again')}
        variant="primary"
      />
    </div>
  );
};

export default ErrorView;
