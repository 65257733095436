import { compact } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback, useState } from 'react';

import { useInvitation } from '@advisor/onboarding';
import useProfileCreation from './useProfileCreation';
import {
  UserRole,
  UserRoleSelectOption,
  CreateProfileFormProps,
} from './types';

export default function useBasicStep({
  identity,
  loginType,
}: CreateProfileFormProps) {
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const { userProfile, setUserProfile, onOpenDetails } = useProfileCreation();

  const { inviteType } = useInvitation();

  const { t } = useTranslation(['user-role']);

  const updateUserAvatar = useCallback(
    (avatarUrl: string) => {
      setUserProfile((profile) => ({ ...profile, avatarUrl }));
      setUploadingAvatar(false);
    },
    [setUserProfile],
  );

  const onSetName = useCallback(
    (name: string) => {
      setUserProfile((profile) => ({ ...profile, name }));
    },
    [setUserProfile],
  );

  const onSetRole = useCallback(
    (role: UserRole) => {
      setUserProfile((profile) => ({ ...profile, role }));
    },
    [setUserProfile],
  );

  const onSubmitBasicForm = useCallback(() => {
    onOpenDetails(loginType, identity);
  }, [onOpenDetails, identity, loginType]);

  const roleOptions: UserRoleSelectOption[] = useMemo(
    () =>
      compact([
        inviteType === 'conversation'
          ? null
          : {
              value: UserRole.Student,
              roleTitle: t('user-role:customer'),
              roleDescription: t('user-role:customer-role-description'),
            },
        {
          value: UserRole.FamilyMember,
          roleTitle: t('user-role:guest-customer'),
          roleDescription: t('user-role:guest-customer-role-description'),
        },
        inviteType !== 'agency'
          ? null
          : {
              value: UserRole.Advisor,
              roleTitle: t('user-role:host'),
              roleDescription: t('user-role:host-role-description'),
            },
        {
          value: UserRole.ServiceProvider,
          roleTitle: t('user-role:guest-host'),
          roleDescription: t('user-role:guest-host-role-description'),
        },
      ]),
    [t, inviteType],
  );

  return {
    onSetName,
    onSetRole,
    userProfile,
    roleOptions,
    uploadingAvatar,
    updateUserAvatar,
    onSubmitBasicForm,
    setUploadingAvatar,
  };
}
