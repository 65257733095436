import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import ComplexError from '@advisor/utils/ComplexError';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import { createMessageAtom } from '../../messaging';
import MessageInputMolecule from './messageInputMolecule';
import { splitOutgoingMessage } from './utils';
import type { HookParams } from './types';

export default function useCreateMessage({ chatRoomId }: HookParams) {
  const { messageInputAtom, resetInputAtom } =
    useMolecule(MessageInputMolecule);
  const resetInput = useSetAtom(resetInputAtom);
  const messageInput = useAtomValue(messageInputAtom);
  const isAuthorized = useIsAuthorized();

  const createMessage = useSetAtom(createMessageAtom);

  return useEvent(async () => {
    if (!messageInput.trim().length || !isAuthorized) {
      return;
    }

    try {
      const messageChunks = splitOutgoingMessage(messageInput);

      resetInput();

      await Promise.all(
        messageChunks.map((chunk) =>
          createMessage({
            messageContent: chunk,
            chatRoomId,
          }),
        ),
      );
    } catch (err) {
      Sentry.captureException(
        new ComplexError(`Failed to create message`, err),
      );
    }
  });
}
