import React from 'react';

import { SuggestedActionItemProps } from './types';

const SuggestedActionItem: React.FC<SuggestedActionItemProps> = (props) => {
  const { title, message, imageSrc, imageAlt, onPress } = props;
  return (
    <button
      type="button"
      onClick={onPress}
      className={`
        p-4 w-full rounded-10 flex flex-row items-center gap-3
        bg-light-grey border border-grey transition-colors
        hover:bg-light-grey/75 hover:border-dark-grey-01
      `}
    >
      <img
        src={imageSrc as string}
        alt={imageAlt}
        className="rounded-10 w-14 h-14 2xl:w-20 2xl:h-20 flex-none"
      />
      <div className="text-start font-outfit w-44">
        <p className="font-semibold text-xs 2xl:text-sm text-primary">
          {title}
        </p>
        <p className="font-medium text-xs">{message}</p>
      </div>
    </button>
  );
};

export default SuggestedActionItem;
