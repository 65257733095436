import type {
  ComponentType,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ForwardRefExoticComponent,
} from 'react';
import z from 'zod';
import type { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Colors } from '../styles';

export type InternalColorPalette = z.infer<typeof InternalColorPalette>;
export const InternalColorPalette = z.object({
  primaryColor: z.string(),
  primaryDarkColor: z.string().optional(),
  primaryLightColor: z.string().optional(),
  primaryLight05Color: z.string().optional(),

  secondaryColor: z.string(),
  secondaryLightColor: z.string().optional(),
  secondaryDarkColor: z.string().optional(),
});

export type ColorPalette = z.infer<typeof ColorPalette>;
export const ColorPalette = z.object({
  primary: z.string(),
  primaryDark: z.string(),
  primaryLight: z.string(),
  primaryLight05: z.string(),
  secondary: z.string(),
  secondaryLight: z.string(),
  secondaryDark: z.string(),
});

export type Color = keyof ColorPalette | keyof typeof Colors | string;

export type Theme = z.infer<typeof Theme>;
export const Theme = z.object({
  organizationLogo: z.string().nullable(),
  colors: ColorPalette,
});

export type Style = StyleProp<ViewStyle | TextStyle | ImageStyle>;

export type ProvidedStyleFunctionProps<TemplateProps> = TemplateProps & {
  theme: Theme;
};

export type ProvidedStyle<TemplateProps> =
  | Style
  | ((props: ProvidedStyleFunctionProps<TemplateProps>) => Style);

export type ProvidedProps<TemplateProps, C extends ComponentType<any>> =
  | TemplateProps
  | Partial<ComponentPropsWithoutRef<C>>
  | ((props: TemplateProps) => Partial<ComponentPropsWithoutRef<C>>);

export type StyledComponentProps<
  TemplateProps,
  C extends ComponentType<any>,
> = ComponentPropsWithRef<C> & TemplateProps;

export type StyledComponent<
  TemplateProps,
  C extends ComponentType<any>,
> = ForwardRefExoticComponent<StyledComponentProps<TemplateProps, C>>;

export interface StyledFunctionBase<C extends ComponentType<any>> {
  <TemplateProps>(providedStyle: ProvidedStyle<TemplateProps>): StyledComponent<
    TemplateProps,
    C
  >;
}

export interface StyledFunction<C extends ComponentType<any>>
  extends StyledFunctionBase<C> {
  attrs<TemplateProps>(
    props: ProvidedProps<TemplateProps, C>,
  ): StyledFunction<
    ComponentType<Omit<ComponentPropsWithoutRef<C>, keyof TemplateProps>>
  >;
}
