import { useCallback } from 'react';

import {
  useLoginMutation,
  LoginMutationOptions,
} from '@advisor/api/generated/graphql';
import { mergeMe } from '../me';

export enum LoginResult {
  LoggedIn = 'LoggedIn',
  NewUser = 'NewUser',
  Failed = 'Failed',
}

interface LoginResponse {
  result: LoginResult;
  id?: string;
}

const loginMutationOptions: LoginMutationOptions = {
  update(cache, { data }) {
    mergeMe(cache, data?.login);
  },
};

export function useLoginToBackend() {
  const [loginMutation] = useLoginMutation();

  return useCallback(async (): Promise<LoginResponse> => {
    const { data } = await loginMutation(loginMutationOptions);

    if (!data) {
      return {
        result: LoginResult.Failed,
      };
    }

    if (data.login) {
      return {
        result: LoginResult.LoggedIn,
        id: data.login.id,
      };
    }

    return {
      result: LoginResult.NewUser,
    };
  }, [loginMutation]);
}
