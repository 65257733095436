import { atom, PrimitiveAtom } from 'jotai';

export function toggleable(innerAtom: PrimitiveAtom<boolean>) {
  return atom(
    (get) => get(innerAtom),
    (get, set, update?: boolean | undefined) => {
      const nextValue = update ?? !get(innerAtom);
      set(innerAtom, nextValue);
    },
  );
}

function atomWithToggle(initialValue: boolean) {
  return toggleable(atom(initialValue));
}

export default atomWithToggle;
