import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { Feature, enabledFeaturesAtom } from '@advisor/api/feature';
import { useMicrobotsQuery } from '@advisor/api/generated/graphql';
import { PersonalityToFeatureKey } from '@advisor/microbots/utils';

export default function useMicrobotList() {
  const { data, loading, error } = useMicrobotsQuery();
  const enabledFeatures = useAtomValue(enabledFeaturesAtom);

  const microbots = useMemo(() => {
    if (!enabledFeatures[Feature.Microbots]) {
      return [];
    }

    return (data?.microbots ?? []).filter(({ personality }) => {
      const feature = PersonalityToFeatureKey[personality];
      return enabledFeatures[feature];
    });
  }, [data?.microbots, enabledFeatures]);

  return {
    microbots,
    loading,
    error,
  };
}
