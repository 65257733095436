import {
  Atom,
  atom,
  ExtractAtomArgs,
  ExtractAtomResult,
  ExtractAtomValue,
  WritableAtom,
} from 'jotai';

import { Lifetime } from './lifetime';

export type LazyAtom<
  Value,
  Args extends unknown[] = [],
  Result = unknown,
  FreshAtom = Atom<Value>,
> = WritableAtom<Awaited<Value> | Value, Args, Result> & {
  /**
   * Atom that holds the promise for the most fresh value.
   * Best used within atom setters to avoid stale values.
   */
  fresh: FreshAtom;
  lifetime?: Lifetime;
};

export type LazyFrom<T extends Atom<unknown>> = LazyAtom<
  ExtractAtomValue<T>,
  ExtractAtomArgs<T>,
  ExtractAtomResult<T>,
  T
>;

export type LazyOptions = {
  invalidateOn?: Atom<unknown>;
};

const LOADING = Symbol('Loading state for lazy atoms');

export function isLazy<T, Args extends unknown[], Result>(
  maybeLazy: Atom<T> | WritableAtom<T, Args, Result>,
): maybeLazy is LazyAtom<T, Args, Result> {
  return 'fresh' in maybeLazy;
}

/**
 * Creates an atom that works like a normal derived async atom, meaning it returns
 * a promise of it's value, until that initial promise resolves. From then on, the
 * atom holds the value of the promise that was resolved last.
 *
 * NOTE: This is an alternative way to create a lazyAtom, but in most cases, the `lazyAtom`
 *       function should be used.
 *
 * @param sourceAtom The atom to be wrapped in lazy updates.
 * @param options The new computation result is compared with
 *   the previous one with the `areEqual` function, which defaults
 *   to `Object.is`.
 * @returns
 */
function lazyFrom<Value, T extends Atom<Value>>(
  sourceAtom: T,
  options?: LazyOptions,
) {
  const { invalidateOn } = options ?? {};

  const wrapperAtom = atom((wrapperGet) => {
    if (invalidateOn) {
      wrapperGet(invalidateOn);
    }

    const refreshAtom = atom(0);
    let prevSource: Value | null = null;
    let result: typeof LOADING | Awaited<Value> = LOADING;

    const lazyOrLoadingAtom = atom(
      (get, { setSelf, signal }) => {
        get(refreshAtom);
        const source = get(sourceAtom);

        if (source === prevSource) {
          // Already processed
          return result;
        }

        prevSource = source;

        if (!(source instanceof Promise)) {
          // No need to await
          if (result === LOADING || !Object.is(result, source)) {
            result = source as Awaited<Value>;
          }
        } else {
          source.then((v: Awaited<Value>) => {
            if (signal.aborted) {
              return;
            }

            if (result !== LOADING && Object.is(v, result)) {
              // No need to update
              return;
            }

            result = v;
            setSelf();
          });
        }

        return result;
      },
      (_, set) => {
        set(refreshAtom, (v) => 1 - v);
      },
    );

    return lazyOrLoadingAtom;
  });

  const resultAtom = atom(
    (get) => {
      const lazyOrLoadingAtom = get(wrapperAtom);

      const value = get(lazyOrLoadingAtom);
      if (value !== LOADING) {
        return value;
      }

      // If the value is still loading, return the promise.
      // This should only occur at the initial mount, when
      // promise hasn't resolved yet, or after invalidating.
      return get(sourceAtom);
    },
    (_, set, ...args: ExtractAtomArgs<T>) => {
      return set(
        sourceAtom as unknown as WritableAtom<
          Value,
          ExtractAtomArgs<T>,
          ExtractAtomResult<T>
        >,
        ...args,
      );
    },
  );

  return Object.assign(resultAtom, {
    fresh: sourceAtom,
    // Making this available to make it easier to depend on each other's lifetimes.
    lifetime: invalidateOn ? { invalidateOn } : undefined,
  }) as LazyFrom<T>;
}

export default lazyFrom;
