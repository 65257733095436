import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Layout } from '@advisor/design/components';
import AudioWaveIndicator from '../AudioWaveIndicator';
import { ParticipantBadgeProps } from './types';

function ParticipantBadge({ participant, isSpeaking }: ParticipantBadgeProps) {
  const { t } = useTranslation();

  const userName = useMemo(() => {
    if (participant.session.isLocal) {
      return t('you');
    }

    return participant.name;
  }, [participant, t]);

  const isMuted = !participant.session.tracks.audio.track;

  return (
    <div
      className="flex items-center px-2 py-1 gap-x-1.5 rounded bg-dark-grey-03 text-light-grey"
      dir="ltr"
    >
      {isMuted ? (
        <Icon name="Muted" />
      ) : (
        <AudioWaveIndicator isSpeaking={isSpeaking} />
      )}
      <span className="font-outfit text-sm">{userName}</span>
      <Layout.Spacer.Horizontal size="atomic" />
    </div>
  );
}

export default ParticipantBadge;
