import { useMemo } from 'react';
import { onError } from '@apollo/client/link/error';

import Sentry from '@advisor/utils/Sentry';

const useErrorLink = () => {
  const errorLink = useMemo(
    () =>
      onError((errorResponse) => {
        const { graphQLErrors, networkError } = errorResponse;

        if (networkError) {
          Sentry.captureException(networkError);
        } else if (graphQLErrors?.length) {
          graphQLErrors.forEach((error) => {
            Sentry.captureException(error.message);
          });
        }
      }),
    [],
  );

  return errorLink;
};

export default useErrorLink;
