import { useEffect, useState } from 'react';
import Sentry from '../Sentry';

/**
 * @param factoryAsync - A function that returns a promise, **this function should be wrapped in useCallback**
 * @returns The value of the promise, or null if the promise is not resolved yet
 */
const useAsyncMemo = <T>(factoryAsync: () => Promise<T>) => {
  const [value, setValue] = useState<T | null>(null);
  useEffect(() => {
    let cancelled = false;

    factoryAsync()
      .then((newValue) => {
        if (!cancelled) {
          setValue(newValue);
        }
      })
      .catch(Sentry.captureException);

    return () => {
      cancelled = true;
    };
  }, [factoryAsync]);

  return value;
};

export default useAsyncMemo;
