import React from 'react';
import cs from 'classnames';

import { Icon, Layout } from '@advisor/design/components';
import { FileAttachmentBaseProps } from './types';

const FileNameBreakPoint = 6;

const FileAttachmentBase: React.FC<FileAttachmentBaseProps> = (props) => {
  const { title, description, preview, hasError } = props;

  return (
    <Layout.Row
      natural
      className={cs(
        'p-4 gap-3 w-full border rounded',
        hasError ? 'border-negative' : 'border-grey',
      )}
    >
      <div className="shrink-0 border border-primary rounded-lg w-10 h-10 grid place-items-center">
        {preview ?? <Icon name="Attachments" color="primary" size={24} />}
      </div>
      <div className="min-w-0 shrink font-outfit font-medium text-sm">
        {/* Split name into two parts to add an ellipsis in the middle */}
        {/* If name is shorter than FileNameBreakPoint the the first span is empty and second is the full name */}
        {title && (
          <div className="text-primary max-w-full flex flex-row">
            <span className="truncate min-w-0">
              {title.slice(0, -FileNameBreakPoint)}
            </span>
            <span>{title.slice(-FileNameBreakPoint)}</span>
          </div>
        )}
        <p className="truncate text-dark-grey-025">{description}</p>
      </div>
    </Layout.Row>
  );
};

export default FileAttachmentBase;
