import z from 'zod';
import { atom } from 'jotai';

import { atomWithPersistence } from '@advisor/utils/atoms';
import { NotificationEnabledStatus, PermissionStatus } from './types';

export const notificationsEnabledAtom = atomWithPersistence(
  'user.notification.enabled',
  z.nativeEnum(NotificationEnabledStatus),
  NotificationEnabledStatus.ENABLED,
);

export const permissionStatusAtom = atom<PermissionStatus>(
  PermissionStatus.UNDETERMINED,
);
