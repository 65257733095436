import { useAtomValue } from 'jotai';

import { Role } from '@advisor/api/user';
import { meAtom } from '@advisor/api/me';
import { lazyAtom } from '@advisor/utils/atoms';
import { atomFamily } from 'jotai/utils';
import { personalPreferencesSlotAtom } from './personalPreferencesSlot';

export type LogoutWidgetSlot = 'sidebar-menu' | 'conversation-preferences';

export const logoutWidgetSlotAtom = lazyAtom(
  async (get): Promise<LogoutWidgetSlot> => {
    const me = await get(meAtom);
    const personalPreferencesSlot = await get(personalPreferencesSlotAtom);

    return (Role.isVerifiedAdvisor(me) || Role.isServiceProvider(me)) &&
      personalPreferencesSlot === 'sidebar-menu'
      ? 'sidebar-menu'
      : 'conversation-preferences';
  },
);

export const logoutWidgetSlotScopes = atomFamily((slot: LogoutWidgetSlot) => {
  return lazyAtom(async (get) => (await get(logoutWidgetSlotAtom)) === slot);
});

export function useLogoutWidgetSlot(slot: LogoutWidgetSlot) {
  return useAtomValue(logoutWidgetSlotAtom) === slot;
}
