import Info from './InfoModal';
import Decision from './DecisionModal';

export * from './atoms';
export { useShowModal, ShowModal } from './useActionModal';

const ActionModal = {
  Info,
  Decision,
};

export default ActionModal;
