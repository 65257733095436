import cs from 'classnames';
import { useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import type { ParseKeys } from 'i18next';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { Colors } from '@advisor/design/styles';
import {
  Icon,
  SpinnerIcon,
  DeprecatedButton,
} from '@advisor/design/components';
import { useCalendarIntegration } from '@advisor/microbots/hooks';
import AssistActionsMolecule from './assistActionsMolecule';
import { AssistActionButtonProps } from './types';

function AssistActionButton(props: AssistActionButtonProps) {
  const { action, actionIndex, color } = props;
  const { isAuthorized } = useCalendarIntegration();

  const isDisabled =
    !isAuthorized && action.__typename === 'MicrobotSaveToCalendarAction';

  const { t } = useTranslation('microbot');
  const wrapperStyle = useMemo(
    () => ({
      color: isDisabled ? Colors.darkGrey01 : color,
    }),
    [color, isDisabled],
  );
  const { performActionAtom } = useMolecule(AssistActionsMolecule);
  const performAction = useSetAtom(performActionAtom);

  const handlePress = useEvent(() => {
    performAction(action, actionIndex);
  });

  return (
    <DeprecatedButton.Bare
      className={cs(
        'isolate inline-flex items-center gap-1 relative rounded-md py-2 px-3 font-medium bg-white',
        `after:content-[''] after:absolute after:inset-0 after:opacity-0 hover:after:opacity-30 after:rounded-lg after:transition-all`,
        'after:bg-white',

        `before:content-[''] before:absolute before:inset-0 before:rounded-md before:border before:opacity-50`,
      )}
      style={wrapperStyle}
      onPress={handlePress}
      disabled={isDisabled}
    >
      {({ loading }) => (
        <>
          {t(action.label as ParseKeys<'microbot'>)}
          {loading ? (
            <SpinnerIcon className="w-6 h-6 mx-auto" />
          ) : (
            <Icon name="Stars" />
          )}
        </>
      )}
    </DeprecatedButton.Bare>
  );
}

export default AssistActionButton;
