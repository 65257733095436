import { sum } from 'lodash-es';
import { atom, WritableAtom } from 'jotai';

import { nonNullable } from '../typeUtils';

export type Lifetime = {
  invalidateOn: WritableAtom<number, [], void>;
};

/**
 * Useful for invalidating lazy atoms. If a lazy atom's dependencies change, as long as this atom's value is the same as in the previous update, the recalculating will be
 * done in the background. If the value changed since the last update, the lazy atom will discard their stale result and return a promise to its next result.
 *
 * ### Example
 *
 * ```
 * const userLifetime = makeLifetime();
 * // whenever the user is deemed to be stale, invalidate the todo-list too.
 * const todoListLifetime = makeLifetime(userLifetime);
 *
 * // All lazy atoms with 'todoListLifetime' will have their state invalidated, and
 * // will return a Promise to their new recalculation.
 * const todoItemsAtom = lazyAtom((get) => {
 *   // ...
 * }, todoListLifetime);
 *
 * const Options = () => {
 *   const invalidateUser = useSetAtom(userLifetime);
 *
 *   const logout = useEvent(() => {
 *     // other auth stuff
 *     // ...
 *
 *     // Invalidates both lazy atoms using `userLifetime` as well as lazy atoms
 *     // using `todoListLifetime`.
 *     invalidateUser();
 *   });
 *
 *   // render ui
 *   // ...
 * };
 * ```
 */
function makeLifetime(...deps: (Lifetime | undefined)[]) {
  const innerAtom = atom(0);

  const invalidateOnAtoms = deps
    .filter(nonNullable)
    .map((dep) => dep.invalidateOn);

  return {
    invalidateOn: atom(
      (get) => {
        // Summing all dependencies. Since only one atom can be updated at a time, changing
        // any one of the dependencies will change this atom's value too. (no -1, +1 business)
        return sum(invalidateOnAtoms.map(get)) + get(innerAtom);
      },
      (_get, set) => {
        set(innerAtom, (v) => 1 - v);
      },
    ),
  };
}

export default makeLifetime;
