import { useAtomValue } from 'jotai';

import { UserInfoFragment } from '@advisor/api/generated/graphqlTypes';
import { useAmIVerifiedAdvisor } from '@advisor/api/me';
import { useChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';

// Use whenever you need to display student journey name instead of student name when log in as an advisor in chat
export default function useInterpolatedUsername(
  user: UserInfoFragment | undefined | null,
  chatRoomId: string | undefined | null,
) {
  const amIVerifiedAdvisor = useAmIVerifiedAdvisor();
  const chatRoomCtx = useChatRoom(chatRoomId || null);
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);

  if (!user || !chatRoomId) {
    return user?.name;
  }

  if (
    amIVerifiedAdvisor &&
    chatRoom?.__typename === 'StudentChatRoom' &&
    user.name === chatRoom.name
  ) {
    return chatRoom.studentName ? chatRoom.studentName : user.name;
  }

  return user.name;
}
