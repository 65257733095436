import React from 'react';

import Timer from '@advisor/ui/components/Timer';
import { RecordingTimerProps } from './types';

const RecordingTimer = ({ startDate }: RecordingTimerProps) => {
  return (
    <div className="flex flex-row items-center">
      <p className="flex flex-row gap-x-1 font-outfit text-xs md:text-base">
        {/* Red "REC" text */}
        <span className="text-negative font-bold">REC</span>
        <span
          className="text-white font-medium"
          style={{ fontVariantNumeric: 'tabular-nums' }}
        >
          <Timer start={startDate} />
        </span>
      </p>
    </div>
  );
};

export default RecordingTimer;
