import React, { ReactNode } from 'react';
import cs from 'classnames';

import { Icon } from '@advisor/design/components';
import { Toaster } from '@advisor/design/components/Toast';
import { Colors } from '@advisor/design/styles';
import { useLanguage } from '@advisor/language';

interface PageHeaderProps {
  onGoBack?: () => void;
  backButtonDisabled?: boolean;
  title?: string;
  children?: ReactNode;
  transparent?: boolean;
  className?: string;
}

interface BoxProps {
  children?: ReactNode;
  className?: string;
}

const PageRoot = ({ children, className }: BoxProps) => (
  <div
    className={cs(
      'flex flex-row h-dynamic-screen max-h-dynamic-screen min-h-0 items-stretch bg-light-grey',
      className,
    )}
  >
    {children}
  </div>
);

const PageHeader = (props: PageHeaderProps) => {
  const {
    onGoBack,
    backButtonDisabled,
    title,
    children,
    transparent,
    className,
  } = props;

  const { isRTL } = useLanguage();

  return (
    <div
      className={cs(
        'h-header-height shrink-0 flex flex-row items-center px-6',
        transparent ? '' : 'bg-white border-b border-grey',
        className,
      )}
    >
      <div className="grow-self-stretch flex flex-row gap-1">
        {!!onGoBack && (
          <button
            type="button"
            className="flex flex-row items-center text-base font-semibold font-sora"
            disabled={backButtonDisabled}
            onClick={onGoBack}
          >
            <Icon
              name={isRTL ? 'ArrowRight' : 'ArrowLeft'}
              className="ltr:mr-3 rtl:ml-3"
              color={backButtonDisabled ? Colors.darkGrey01 : undefined}
            />
            {title}
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

// 'relative' class necessary for proper <Toaster /> positioning
const PageContent = ({ children, className }: BoxProps) => (
  <div
    className={cs(
      'bg-gradient-to-b from-white to-light-grey relative h-full grow flex flex-col xl:bg-none xl:bg-light-grey',
      className,
    )}
  >
    {children}
    <Toaster />
  </div>
);

const PageContentInner = ({ children, className }: BoxProps) => (
  <div className={cs('p-6', className)}>{children}</div>
);

const Page = {
  Root: PageRoot,
  Header: PageHeader,
  Content: PageContent,
  Inner: PageContentInner,
};

export default Page;
