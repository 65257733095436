import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useCallback, useState, useEffect } from 'react';

import { DeprecatedButton, Layout, Modal } from '@advisor/design/components';
import { useCallState, useDisconnect } from '../VideoRoomAPI';
import useBeforeUnload from '../useBeforeUnload';
import { TimerScopeKey, useTimer } from './useTimer';

interface VideoNavTimeoutProps {
  children: ReactNode;
}

const MinimizedTimerScopeKey = new TimerScopeKey();
const CountDownTime = 60;
const WaitTime = 300;

function isInsideVideoCallPage(pathName: string) {
  return /video-call\/\S+/.test(pathName);
}

const VideoNavTimeout: React.FC<VideoNavTimeoutProps> = (props) => {
  const { timeLeft, isRunning, startTimer, stopTimer } = useTimer(
    MinimizedTimerScopeKey,
  );
  const disconnect = useDisconnect();
  const [showModal, setShowModal] = useState(false);
  const routeLocation = useLocation();
  const callState = useCallState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { children } = props;

  const videoCallExists = !!callState;
  useBeforeUnload(callState?.chatRoomId);

  const onFinished = useCallback(async () => {
    setShowModal(false);
    await disconnect();

    stopTimer();
  }, [stopTimer, disconnect]);

  const onShowModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const onHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onReturnToCall = useCallback(() => {
    stopTimer();
    setShowModal(false);

    if (!callState) {
      return;
    }

    navigate(`/video-call/${callState.chatRoomId}`);
  }, [navigate, stopTimer, callState]);

  useEffect(() => {
    if (!videoCallExists) {
      stopTimer();
      setShowModal(false);
      return;
    }

    if (isInsideVideoCallPage(routeLocation.pathname)) {
      if (isRunning) {
        stopTimer();
      }
      return;
    }

    if (!isRunning) {
      startTimer(CountDownTime, WaitTime);
    }
  }, [
    stopTimer,
    startTimer,
    isRunning,
    videoCallExists,
    routeLocation.pathname,
  ]);

  useEffect(() => {
    if (timeLeft === CountDownTime) {
      onShowModal();
    }

    if (timeLeft === 0) {
      onFinished();
    }
  }, [onFinished, onShowModal, timeLeft]);

  return (
    <>
      {children}
      <Modal.Base visible={showModal} onClose={onHideModal} maxWidth={326}>
        <Layout.Row reversed padding={{ x: 16, top: 16 }}>
          <DeprecatedButton.Icon
            icon="X"
            variant="darkGrey01"
            onPress={onHideModal}
          />
        </Layout.Row>
        <Layout.Column items="center" padding={{ x: 32, bottom: 32 }}>
          <h4 className="text-center font-sora font-bold whitespace-pre-line mb-4">
            {t('are-you-still-there')}
          </h4>
          <p className="text-center text-sm mb-8 text-dark-grey-02">
            {t('please-return-to-the-video-room-or-you-will-be-disconnected')}
          </p>
          <p className="text-center text-sm mb-8 text-dark-grey-02 font-bold">
            {t('video-room-will-close-in-seconds', { count: timeLeft })}
          </p>
          <div className="space-x-4">
            <DeprecatedButton.Large variant="primary" onPress={onReturnToCall}>
              {t('return-to-video-room')}
            </DeprecatedButton.Large>
          </div>
        </Layout.Column>
      </Modal.Base>
    </>
  );
};

export default VideoNavTimeout;
