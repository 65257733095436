import { useSetAtom } from 'jotai';
import { useMolecule } from 'bunshi/react';

import { useContentTranslation } from '@advisor/chat/contentTranslation';
import ToggleMilestoneTranslationMolecule from './toggleMilestoneTranslation';
import MilestoneTranslationMolecule from './milestoneTranslationMolecule';

function useMilestoneTranslation() {
  const milestoneTranslationAtoms = useMolecule(MilestoneTranslationMolecule);
  const { toggleMilestoneTranslationAtom } = useMolecule(
    ToggleMilestoneTranslationMolecule,
  );

  const toggle = useSetAtom(toggleMilestoneTranslationAtom);

  return [useContentTranslation(milestoneTranslationAtoms), toggle] as const;
}

export default useMilestoneTranslation;
