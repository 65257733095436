import React from 'react';

import { PreferencesButtonProps } from './types';

const PreferencesButton = (props: PreferencesButtonProps) => {
  const { onPress } = props;

  return (
    <>
      <button
        data-cy="chat-preferences-toggle"
        onClick={onPress}
        type="button"
        className="group hover:opacity-75 disabled:opacity-20"
      >
        <span className="block w-6 h-6 relative transition-transform group-hover:scale-105">
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[3px] left-[3px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[3px] left-[10px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[3px] left-[17px]" />

          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[10px] left-[3px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[10px] left-[10px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[10px] left-[17px]" />

          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[17px] left-[3px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[17px] left-[10px]" />
          <span className="w-1 h-1 absolute transition-transform transform bg-primary rounded-full top-[17px] left-[17px]" />
        </span>
      </button>
    </>
  );
};

export default PreferencesButton;
