import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Icon, Layout, DeprecatedButton } from '@advisor/design/components';

const NotFoundWidget: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className="bg-white p-6 m-6 rounded text-center md:max-w-chat-width md:mx-auto w-full">
      <h2 className="flex flex-row items-center justify-center font-sora font-bold text-base text-dark-grey-03 md:text-lg">
        <Icon name="Warning" size={24} className="text-negative" />
        <Layout.Spacer.Horizontal size="atomic" />
        {t('oops')}...
      </h2>
      <Layout.Spacer.Vertical size="tiny" />
      <p className="font-outfit text-sm font-normal text-dark-grey-03 md:text-base">
        {t('the-page-you-are-looking-for-cannot-be-found')}
      </p>
      <Layout.Spacer.Vertical size="small01" />
      <DeprecatedButton.Large
        label={t('go-back')}
        variant="primary"
        className="max-w-[120px] py-[11px]"
        onPress={() => {
          navigate('/chat');
        }}
      />
    </div>
  );
};

export default NotFoundWidget;
