import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

// @ts-ignore
window.global = window;

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
} else {
  // eslint-disable-next-line no-console
  console.log('root html element not found!');
}
