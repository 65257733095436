import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getI18n } from 'react-i18next';

import {
  ChangeUserLanguageDocument,
  ChangeUserLanguageMutationOptions,
  UserUnion,
} from '@advisor/api/generated/graphql';
import { meAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { atomWithMutation } from '@advisor/api/apollo';
import { parseLanguageCode } from '../languages';
import detectSystemLanguage from './detectSystemLanguage';

function createChangeUserLanguageMutationOptions(
  meId: string,
  __typename: UserUnion['__typename'],
) {
  const options: ChangeUserLanguageMutationOptions = {
    optimisticResponse: ({ language }) => ({
      __typename: 'Mutation',
      changeUserLanguage: {
        __typename,
        id: meId,
        language,
      },
    }),
  };

  return options;
}

const i18nLanguageSettingAtom = (() => {
  const innerAtom = atom<string | undefined>(undefined);

  innerAtom.onMount = (update) => {
    const i18n = getI18n();
    update(i18n.language);

    i18n.on('languageChanged', update);
    return () => i18n.off('languageChanged', update);
  };

  return innerAtom;
})();

/**
 * The language that the app should preferably be in, the user's preferred language. Even if the app is not
 * officially translated in this language, it is used for rare important notices we need the user to understand.
 */
const userLanguageAtom = (() => {
  const mutationAtom = atomWithMutation(ChangeUserLanguageDocument);

  return atom(
    (get) => {
      const me = get(unwrap(meAtom));

      return (
        parseLanguageCode(me?.language) ??
        parseLanguageCode(get(i18nLanguageSettingAtom))
      );
    },
    async (get, set, language?: string) => {
      const prevCode = await get(userLanguageAtom);

      let code = parseLanguageCode(language);

      if (!code) {
        code = await detectSystemLanguage();
      }

      if (!code) {
        return;
      }

      const me = await get(meAtom);

      if (!me) {
        // Only changing the local setting when not logged in.
        await getI18n().changeLanguage(code);
        return;
      }

      try {
        // Changing the local i18n setting.
        await getI18n().changeLanguage(code);

        await set(mutationAtom, {
          variables: { language: code },
          ...createChangeUserLanguageMutationOptions(me.id, me.__typename),
        });
      } catch (e) {
        Sentry.captureException(e);
        // Reverting to previous language option
        if (prevCode) {
          await getI18n().changeLanguage(prevCode);
        }
      }
    },
  );
})();

export default userLanguageAtom;
