import React, {
  useRef,
  useMemo,
  Fragment,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
  CSSProperties,
  useImperativeHandle,
} from 'react';
import cs from 'classnames';
import type { DefaultSectionT } from 'react-native';

import useResizeObserver from '@advisor/utils/hooks/useResizeObserver';
import type {
  SectionListRef,
  SectionListProps,
  SectionListInternalItem,
} from './types';
import { fakeBaseSyntheticEvent } from './utils';

const EmptyComponent = () => null;

const fakeSeparators = {
  highlight: () => null,
  unhighlight: () => null,
  updateProps: () => null,
};

const SectionList = forwardRef(function WebSectionList<
  ItemT,
  SectionT = DefaultSectionT,
>(props: SectionListProps<ItemT, SectionT>, ref: ForwardedRef<SectionListRef>) {
  const {
    sections,
    inverted,
    className,
    scrollEnabled = true,
    contentContainerStyle,
    onEndReachedThreshold = 0.5,
    contentContainerClassName,
    ListFooterComponent,
    ListHeaderComponent,
    renderSectionHeader = EmptyComponent,
    renderSectionFooter = EmptyComponent,
    renderItem: externalRenderItem,
    keyExtractor: externalKeyExtractor,
    onLayout,
    onEndReached,
    onContentSizeChange,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => {
    const handler: SectionListRef = {
      getScrollResponder() {
        return {
          scrollTo(options) {
            if (typeof options !== 'object') {
              throw new Error('Deprecated');
            }
            const { x, y, animated } = options;
            containerRef.current?.scrollTo({
              top: y,
              left: x,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
          scrollToEnd(options) {
            const scrollHeight = containerRef.current?.scrollHeight ?? 0;
            containerRef.current?.scrollTo({
              top: inverted ? -scrollHeight : scrollHeight,
              behavior: options?.animated ? 'smooth' : 'auto',
            });
          },
        };
      },
    };
    return handler;
  });

  const flatListData = useMemo(() => {
    const data: SectionListInternalItem[] = [];

    for (let i = 0; i < sections.length; i += 1) {
      data.push({
        sectionIndex: i,
        inSectionIndex: -1,
        typename: 'header',
      });

      for (let j = 0; j < sections[i].data.length; j += 1) {
        data.push({
          sectionIndex: i,
          inSectionIndex: j,
          typename: 'item',
        });
      }

      data.push({
        sectionIndex: i,
        typename: 'footer',
        inSectionIndex: Infinity,
      });
    }

    return data;
  }, [sections]);

  const renderItem = useCallback(
    (item: SectionListInternalItem) => {
      const section = sections[item.sectionIndex];

      if (item.typename === 'header') {
        if (!renderSectionHeader) {
          return null;
        }

        return renderSectionHeader({ section });
      }

      if (item.typename === 'footer') {
        if (!renderSectionFooter) {
          return null;
        }

        return renderSectionFooter({ section });
      }

      if (!externalRenderItem) {
        return null;
      }

      return externalRenderItem({
        item: section.data[item.inSectionIndex],
        index: item.inSectionIndex,
        section,
        separators: fakeSeparators,
      });
    },
    [sections, externalRenderItem, renderSectionHeader, renderSectionFooter],
  );

  const keyExtractor = useCallback(
    (item: SectionListInternalItem) => {
      const section = sections[item.sectionIndex];

      if (item.typename === 'footer' || item.typename === 'header') {
        return `${section.key || item.sectionIndex}-${item.typename}`;
      }

      if (externalKeyExtractor) {
        return externalKeyExtractor(
          section.data[item.inSectionIndex],
          item.inSectionIndex,
        );
      }

      const exItem = section.data[item.inSectionIndex] as {
        key: string;
      };

      return exItem.key;
    },
    [externalKeyExtractor, sections],
  );

  const items = useMemo(
    () =>
      flatListData.map((internalItem) => (
        <Fragment key={keyExtractor(internalItem)}>
          {renderItem(internalItem)}
        </Fragment>
      )),
    [flatListData, keyExtractor, renderItem],
  );

  const onScroll = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    // The property `.scrollTop` is negative `flex-direction` is set to ` column-reverse`.
    const scrollTop = inverted
      ? -containerRef.current.scrollTop
      : containerRef.current.scrollTop;

    const distanceFromEnd =
      containerRef.current.scrollHeight -
      containerRef.current.clientHeight -
      scrollTop;

    const distanceThreshold =
      onEndReachedThreshold * containerRef.current.clientHeight;

    if (distanceFromEnd <= distanceThreshold) {
      onEndReached?.({ distanceFromEnd });
    }
  }, [inverted, onEndReached, onEndReachedThreshold]);

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  useResizeObserver(
    contentRef,
    useCallback(
      ({ contentRect: { width, height } }) =>
        onContentSizeChange?.(width, height),
      [onContentSizeChange],
    ),
  );

  useResizeObserver(
    containerRef,
    useCallback(
      ({ contentRect }) =>
        onLayout?.({
          ...fakeBaseSyntheticEvent,
          nativeEvent: { layout: contentRect },
        }),
      [onLayout],
    ),
  );

  return (
    <div
      ref={containerRef}
      onScroll={onScroll}
      className={cs(
        'relative min-h-0 flex',
        inverted ? 'flex-col-reverse' : 'flex-col',
        scrollEnabled ? 'overflow-auto' : 'overflow-hidden',
        className,
      )}
    >
      <div
        ref={contentRef}
        className={cs(
          'flex',
          inverted ? 'flex-col-reverse' : 'flex-col',
          contentContainerClassName,
        )}
        // This may cause some problems
        style={contentContainerStyle as CSSProperties}
      >
        {typeof ListHeaderComponent === 'function' ? (
          <ListHeaderComponent />
        ) : (
          ListHeaderComponent
        )}
        {items}
        {typeof ListFooterComponent === 'function' ? (
          <ListFooterComponent />
        ) : (
          ListFooterComponent
        )}
      </div>
    </div>
  );
});

export default SectionList as <ItemT, SectionT>(
  props: SectionListProps<ItemT, SectionT> & {
    ref?: ForwardedRef<SectionListRef>;
  },
) => ReturnType<typeof SectionList>;
