import { useTranslation } from 'react-i18next';
import { last } from 'lodash-es';

import {
  useApproveRoleRequestMutation,
  useDeclineRoleRequestMutation,
  RoleRequestsQuery,
  RoleRequestsDocument,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { showToast } from '@advisor/design/components/Toast';

export default function useRoleRequestActions() {
  const { t } = useTranslation(['common', 'onboarding']);
  const showModal = useShowModal();

  const [approveRoleRequestMutation] = useApproveRoleRequestMutation();

  const approve = async (
    id: string,
    aspiringAdvisorName: string,
  ): Promise<boolean> => {
    const confirm = await showModal.confirm({
      variant: 'neutral',
      title: t('onboarding:you-are-approving-name-as-an-host', {
        name: aspiringAdvisorName,
      }),
      message: t('onboarding:please-confirm'),
    });

    if (!confirm) {
      return false;
    }

    try {
      await approveRoleRequestMutation({
        update(cache, _result) {
          cache.updateQuery<RoleRequestsQuery>(
            { query: RoleRequestsDocument },
            (data) => {
              return data ? deleteRoleRequest(data, id) : data;
            },
          );
        },
        variables: { id },
      });

      showToast({
        variant: 'blue',
        iconName: 'Link',
        messageI18Key: 'account-has-been-approved',
        namespace: 'onboarding',
      });

      return true;
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
      return false;
    }
  };

  const [declineRoleRequestMutation] = useDeclineRoleRequestMutation();

  const decline = async (id: string): Promise<boolean> => {
    const confirm = await showModal.confirm({
      variant: 'severe',
      title: t('onboarding:are-you-sure-you-want-to-decline'),
      message: t('common:this-action-cannot-be-reversed'),
      confirmActionLabel: t('common:delete'),
    });

    if (!confirm) {
      return false;
    }

    try {
      await declineRoleRequestMutation({
        update(cache, _result) {
          cache.updateQuery<RoleRequestsQuery>(
            { query: RoleRequestsDocument },
            (data) => {
              return data ? deleteRoleRequest(data, id) : data;
            },
          );
        },
        variables: {
          id,
        },
      });

      showToast({
        variant: 'rose',
        iconName: 'Warning',
        messageI18Key: 'account-was-denied-for-host-profile',
        namespace: 'onboarding',
      });

      return true;
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
      return false;
    }
  };

  return {
    approve,
    decline,
  };
}

const deleteRoleRequest = (
  query: RoleRequestsQuery,
  roleRequestId: string,
): RoleRequestsQuery => {
  const roleRequest = query.roleRequests.edges.find(
    (edge) => edge.node.id === roleRequestId,
  )?.node;

  if (!roleRequest) {
    return query;
  }

  const count = query.roleRequests.count ?? 1;

  const edges = query.roleRequests.edges.filter(
    ({ node }) => node.id !== roleRequest.id,
  );

  let { startCursor, endCursor } = query.roleRequests.pageInfo;

  if (edges.length > 0) {
    startCursor = edges[0].cursor;
    endCursor = last(edges)?.cursor;
  }

  return {
    ...query,
    roleRequests: {
      ...query.roleRequests,
      edges,
      count: count - 1,
      pageInfo: {
        ...query.roleRequests.pageInfo,
        startCursor,
        endCursor,
      },
    },
  };
};
