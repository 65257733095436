import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MicrobotDocument,
  MicrobotPersonality,
  MicrobotQuery,
  MicrobotQueryVariables,
  ResetMicrobotContextMutationOptions,
  useResetMicrobotContextMutation,
} from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';

type Params = {
  personality: MicrobotPersonality;
  confirmModal?: boolean;
};

const useResetMicrobotContext = ({
  personality,
  confirmModal = true,
}: Params) => {
  const { t } = useTranslation(['common', 'microbot']);
  const showModal = useShowModal();

  const [resetMicrobotContext] = useResetMicrobotContextMutation({
    ...mutationOptions,
    variables: {
      personality,
    },
  });

  return useCallback(async () => {
    if (confirmModal) {
      const confirm = await showModal.confirm({
        variant: 'severe',
        title: t('microbot:are-you-sure-you-want-to-reset-primary-context'),
        message: t('common:this-action-cannot-be-reversed'),
        confirmActionLabel: t('microbot:reset'),
      });

      if (!confirm) {
        return;
      }
    }

    try {
      await resetMicrobotContext();

      showToast({
        variant: 'rose',
        iconName: 'CircleCheck',
        namespace: 'microbot',
        messageI18Key: 'primary-context-successfully-reset',
      });
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  }, [confirmModal, resetMicrobotContext, showModal, t]);
};

export default useResetMicrobotContext;

const mutationOptions: ResetMicrobotContextMutationOptions = {
  update(cache, result) {
    const microbot = result.data?.resetMicrobotContext;

    if (!microbot) {
      return;
    }

    cache.updateQuery<MicrobotQuery, MicrobotQueryVariables>(
      {
        query: MicrobotDocument,
        variables: { personality: MicrobotPersonality.Astro },
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          microbot: {
            ...prev.microbot,
            ...microbot,
          },
        };
      },
    );
  },
};
