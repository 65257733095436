import {
  MessageTextSegmentWeight,
  type MessageChunk,
  type MessageTextChunk,
} from './types';

const LINK_REGEX = /(https?:\/\/\S+)/;
const COMMON_LINK_ENDING = /[.,]/;

type MessageToken =
  | {
      type: 'text';
      value: string;
    }
  | {
      type: 'link';
      value: string;
    }
  | {
      type: 'double-star';
      value: string;
    };

export function tokenize(raw: string): MessageToken[] {
  let rawLeft = raw;
  const tokens: MessageToken[] = [];

  while (rawLeft.length > 0) {
    const doubleStarIdx = rawLeft.indexOf('**');
    const linkRegex = LINK_REGEX.exec(rawLeft);
    let nextTokenType: MessageToken['type'] | null = null;
    let nextTokenIndex = rawLeft.length;

    // Finding the next token type

    if (linkRegex && linkRegex.index < nextTokenIndex) {
      nextTokenType = 'link';
      nextTokenIndex = linkRegex.index;
    }

    if (doubleStarIdx !== -1 && doubleStarIdx < nextTokenIndex) {
      nextTokenType = 'double-star';
      nextTokenIndex = doubleStarIdx;
    }

    // Processing the token type

    if (nextTokenIndex > 0) {
      // Slicing off normal text from the beginning.
      tokens.push({
        type: 'text',
        value: rawLeft.substring(0, nextTokenIndex),
      });
      rawLeft = rawLeft.substring(nextTokenIndex);
    }

    if (nextTokenType === 'link' && /* always true in this case */ linkRegex) {
      let endOfLinkIndex = linkRegex[0].length;
      if (COMMON_LINK_ENDING.test(rawLeft[endOfLinkIndex - 1])) {
        endOfLinkIndex -= 1;
      }

      tokens.push({
        type: 'link',
        value: rawLeft.substring(0, endOfLinkIndex),
      });

      rawLeft = rawLeft.substring(endOfLinkIndex);
    } else if (nextTokenType === 'double-star') {
      tokens.push({ type: 'double-star', value: '**' });
      rawLeft = rawLeft.substring(2); // removing two star chars from start
    }
  }

  return tokens;
}

export type ParseOptions = {
  /**
   * @default false
   */
  doubleStarIsBold?: boolean;
};

function parseRawMessage(raw: string, options?: ParseOptions) {
  const { doubleStarIsBold = false } = options ?? {};

  const tokens = tokenize(raw);
  const chunks: MessageChunk[] = [];
  let currentTextChunk: MessageTextChunk | null = null;

  let bold = false;
  while (tokens.length > 0) {
    const token = tokens.shift() as MessageToken;

    if (token.type === 'link') {
      chunks.push({ type: 'link', key: token.value, href: token.value });
      currentTextChunk = null;
    } else if (token.type === 'double-star' && doubleStarIsBold) {
      bold = !bold;
    } else {
      if (currentTextChunk === null) {
        currentTextChunk = { type: 'text', key: '', segments: [] };
        chunks.push(currentTextChunk);
      }

      // appending text segments
      currentTextChunk.key += token.value;
      currentTextChunk.segments.push({
        weight: bold
          ? MessageTextSegmentWeight.Bold
          : MessageTextSegmentWeight.Normal,
        value: token.value,
      });
    }
  }

  return chunks;
}

export default parseRawMessage;
