import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';
import React, { memo, useMemo } from 'react';

import {
  useChatRoomQuery,
  useLatestMessagesQuery,
} from '@advisor/api/generated/graphql';
import { useMyId } from '@advisor/api/me';
import { useEvent } from '@advisor/utils/hooks';
import { PermissionGate } from '@advisor/api/scope';
import {
  useResetInput,
  useEditMessage,
  useCreateMessage,
  useTypingIndicator,
  useRequestSuggestion,
  useListenForSuggestions,
  MessageInputMolecule,
} from '../hooks/MessageInput';
import ChatIconButton from './web/ChatIconButton';
import MagicWandButton from './web/MagicWandButton';
import MessageInput from './web/MessageInput';
import showMagicWandButtonScope from './atoms/showMagicWandButton';
import { useMessageLengthLimit } from './hooks';
import { ChatBottomBarContentProps } from './types';

const ChatBottomBarContent: React.FC<ChatBottomBarContentProps> = (props) => {
  const { chatRoomId } = props;

  const { data: { chatRoom } = {} } = useChatRoomQuery({
    variables: { chatRoomId },
    fetchPolicy: 'cache-only',
  });

  const resetInput = useResetInput();
  useListenForSuggestions({ chatRoomId });
  const sendNewMessage = useCreateMessage({ chatRoomId });
  const sendEditedMessage = useEditMessage();
  const requestSuggestion = useRequestSuggestion();

  const { isEditingMessageAtom, isSuggestionAtom } =
    useMolecule(MessageInputMolecule);
  const isEditingMessage = useAtomValue(isEditingMessageAtom);
  const isSuggestion = useAtomValue(isSuggestionAtom);

  const myId = useMyId();

  useTypingIndicator({ chatRoomId });

  const { data: messages } = useLatestMessagesQuery({
    variables: { chatRoomId },
    fetchPolicy: 'cache-only',
  });

  const isInputDisabled = useMemo(
    () =>
      (chatRoom?.members?.length ?? 0) <= 1 ||
      chatRoom?.leadingAdvisorId === null,
    [chatRoom?.members?.length, chatRoom?.leadingAdvisorId],
  );

  const { limitReached } = useMessageLengthLimit(chatRoomId);

  const isLastMessageInvalid = useMemo(() => {
    const messageEdges = messages?.messages.edges;

    if (!Array.isArray(messageEdges) || !messageEdges.length) {
      return true;
    }

    const lastMessage = messageEdges?.[messageEdges.length - 1].node;

    return (
      lastMessage.__typename === 'UserMessage' && lastMessage.author.id === myId
    );
  }, [messages?.messages.edges, myId]);

  const disableMagicWand = isLastMessageInvalid || isEditingMessage;

  const showMagicWand = !isSuggestion && !isEditingMessage;

  const handleSend = useEvent(() => {
    if (isEditingMessage) {
      sendEditedMessage();
    } else {
      sendNewMessage();
    }
  });

  return (
    <div className="border-t border-grey">
      <div className="flex flex-row items-stretch p-4 sm:px-6 xl:px-0 gap-3 xl:w-chat-width xl:mx-auto">
        <MessageInput
          chatRoomId={chatRoomId}
          handleSend={handleSend}
          disabled={isInputDisabled}
        />
        <PermissionGate scope={showMagicWandButtonScope(chatRoomId)}>
          {() =>
            showMagicWand && (
              <MagicWandButton
                disabled={disableMagicWand}
                onClick={requestSuggestion}
              />
            )
          }
        </PermissionGate>
        <div className="flex flex-col self-end space-y-3">
          {(isSuggestion || isEditingMessage) && (
            <ChatIconButton
              iconName="Close"
              onPress={resetInput}
              disabled={isInputDisabled}
            />
          )}
          <ChatIconButton
            iconName="Send"
            onPress={handleSend}
            className="rtl:scale-x-[-1]"
            disabled={isInputDisabled || limitReached}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ChatBottomBarContent);
