import React from 'react';
import { useTranslation } from 'react-i18next';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { DeprecatedButton, Modal } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { DateFormatter } from '@advisor/language';
import { CalendarShortFormat } from '@advisor/language/dateFormatter';
import { useMicrobot } from '../../hooks';
import { PrimaryContextModalProps } from './types';

const PrimaryContextModal: React.FC<PrimaryContextModalProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation('microbot');

  const { primaryContext, updatedAt, createdAt } = useMicrobot(
    MicrobotPersonality.Astro,
    'required',
  );

  return (
    <Modal.Base
      maxWidth={675}
      maxHeight={610}
      visible={visible}
      onClose={onClose}
    >
      {/* Header */}
      <div className="px-8 py-6 border-b border-grey">
        <div className="flex flex-row items-start justify-between">
          <div>
            <h3 className="font-sora text-dark-grey-03 text-xl leading-6 font-semibold">
              {t('primary-context')}
            </h3>
            <h5 className="font-outfit text-sm leading-[1.125rem] text-dark-grey-025">
              {t('last-updated', {
                dateValue: DateFormatter.calendarShort(
                  updatedAt ?? createdAt,
                  CalendarShortFormat.DMY,
                ),
              })}
            </h5>
          </div>
          <DeprecatedButton.Icon
            icon="X"
            variant="darkGrey01"
            size={Spacing.small02}
            onPress={onClose}
          />
        </div>
      </div>

      {/* Body */}
      <div className="px-8 py-6 overflow-y-scroll md:max-h-[31.8125rem]">
        <p className="font-outfit text-sm leading-[1.125rem] font-medium text-dark-grey-03">
          {primaryContext}
        </p>
      </div>
    </Modal.Base>
  );
};

export default PrimaryContextModal;
