import AnimatedHeaderScreen from './AnimatedHeaderScreen';
import Heading from './Heading';
import Screen from './Screen';
import Spacer from './Spacer';
import Column from './Column';
import Block from './Block';
import Text from './Text';
import Row from './Row';

const Layout = {
  AnimatedHeaderScreen,
  Heading,
  Screen,
  Spacer,
  Column,
  Block,
  Text,
  Row,
};

export default Layout;
