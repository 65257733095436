class TimeoutError extends Error {
  constructor(public durationMs: number) {
    super(`Timed out after ${durationMs} milliseconds`);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export function rejectAfter(ms: number): Promise<never> {
  return new Promise((_, reject) => {
    setTimeout(() => reject(new TimeoutError(ms)), ms);
  });
}

export function resolveAfter<T>(ms: number, value: T): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(value), ms));
}
