import { atom } from 'jotai';
import Papa from 'papaparse';
import { produce } from 'immer';
import { ParseKeys } from 'i18next';
import { unwrap } from 'jotai/utils';
import { molecule, use, createScope } from 'bunshi';
import { ApolloCache, isApolloError } from '@apollo/client';

import type { FileData } from '@advisor/api/files/types';
import {
  FileSource,
  DeleteFileDocument,
  MemoryRetrievalType,
  CheckIsUrlValidDocument,
  MicrobotMemoriesDocument,
  CreateBulkUploadDocument,
  CreateMicrobotMemoryDocument,
  UpdateMicrobotMemoryDocument,
  MemoryBankBulkUploadsDocument,
  MicrobotMemoryEdgeInfoFragment,
  CreateBulkUploadMutationVariables,
  CreateMicrobotMemoryMutationVariables,
} from '@advisor/api/generated/graphqlTypes';
import Sentry from '@advisor/utils/Sentry';
import { uploadAgencyFile, readTextFileContent } from '@advisor/api/files';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { actionAtom, soloActionAtoms } from '@advisor/utils/atoms';
import { Feature, featureEnabledAtoms } from '@advisor/api/feature';
import { clientAtom, refetchQueriesOrIgnore } from '@advisor/api/apollo';
import {
  type Attachment,
  isUploaded,
  isBeingUploaded,
} from '@advisor/design/components/FileAttachment';
import { showToast } from '@advisor/design/components/Toast';
import { MicrobotMemoryFileSizeThresholdMB } from './uploadMisc';

type UploadType = 'file' | 'csv' | 'bulk';

type AddTextMemoryState = {
  mode: 'text';
  value: string;
};

type AddUrlMemoryState = {
  mode: 'url';
  value: string;
  retrievalType: MemoryRetrievalType;
};

type AddFileMemoryState = {
  mode: 'file';
  uploadType: UploadType;
  value: Attachment;
};

type ConfirmUploadState = {
  mode: 'confirm';
  uploadType: UploadType;
  value: Attachment;
};

type AddMemoryState =
  | AddTextMemoryState
  | AddUrlMemoryState
  | AddFileMemoryState
  | ConfirmUploadState
  | null;

type EditMemoryState = {
  id?: string | null;
  retrievalType?: MemoryRetrievalType | null;
};

type VariablesState =
  | undefined
  | {
      type: 'single';
      variables: CreateMicrobotMemoryMutationVariables;
    }
  | {
      type: 'bulk';
      variables: CreateBulkUploadMutationVariables;
    };

const initialEditMemoryState: EditMemoryState = {
  id: null,
  retrievalType: null,
};

const UrlTextContentRegex = /^https?:\/\/\S+/;

function isValidBulkUpload(contents: unknown[]): boolean {
  if (contents.length < 2) {
    return false;
  }

  const headerRow = contents[0];

  if (headerRow instanceof Array === false) {
    return false;
  }

  if (typeof headerRow[0] !== 'string' || typeof headerRow[1] !== 'string') {
    return false;
  }

  return (
    headerRow[0].toLocaleLowerCase() === 'url/text' &&
    headerRow[1].toLocaleLowerCase() === 'continuous retrieval'
  );
}

async function getUploadType(file: FileData, canBulkUpload?: boolean) {
  if (file.type !== 'text/csv') {
    return 'file';
  }

  try {
    const fileContent = await readTextFileContent(file);

    const csv = Papa.parse(fileContent);

    if (isValidBulkUpload(csv.data) && canBulkUpload) {
      return 'bulk';
    }

    return 'csv';
  } catch (e) {
    Sentry.captureException(e);
    return 'file';
  }
}

function updateMemoriesQuery(
  cache: ApolloCache<unknown>,
  edge: MicrobotMemoryEdgeInfoFragment,
) {
  cache.updateQuery(
    {
      query: MicrobotMemoriesDocument,
      variables: { searchQuery: '' },
    },
    (current) => {
      if (!current) {
        return null;
      }

      return produce(current, (draft) => {
        const edgeIndex = draft.microbotMemories.edges.findIndex(
          ({ node }) => node.id === edge.node.id,
        );

        if (edgeIndex === -1) {
          draft.microbotMemories.edges.unshift(edge);
          draft.microbotMemories.count += 1;
          draft.microbotMemories.pageInfo.startCursor = edge.cursor;
        } else {
          draft.microbotMemories.edges[edgeIndex] = edge;
        }
      });
    },
  );
}

export enum ModalStep {
  MemorySource,
  ChooseRetrieval,
  ConfirmUpload,
}

export const AddMicrobotMemoryScope = createScope<
  'chat-room' | 'preferences' | 'invalid'
>('invalid');

const AddMicrobotMemoryMolecule = molecule(() => {
  if (use(AddMicrobotMemoryScope) === 'invalid') {
    throw new Error('Please provide an AddMicrobotMemoryScope upstream.');
  }

  const editorStateAtom = atom<AddMemoryState>(null);
  const editMemoryStateAtom = atom<EditMemoryState>(initialEditMemoryState);
  const errorMessageKeyAtom = atom<ParseKeys<['common', 'microbot']> | null>(
    null,
  );

  const modalStepAtom = atom((get) => {
    const state = get(editorStateAtom);

    if (state?.mode === 'text' || state?.mode === 'file') {
      return ModalStep.MemorySource;
    }

    if (state?.mode === 'url') {
      return ModalStep.ChooseRetrieval;
    }

    if (state?.mode === 'confirm') {
      return ModalStep.ConfirmUpload;
    }

    return null;
  });

  const filesEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingFile);
  const urlsEnabledAtom = featureEnabledAtoms(Feature.DataHarvestingUrl);
  const bulkUploadsEnabledAtom = featureEnabledAtoms(Feature.BulkUpload);

  const canUploadFilesAtom = atom((get) => {
    const state = get(editorStateAtom);
    const { id: editingMemoryId } = get(editMemoryStateAtom);

    return (
      !editingMemoryId &&
      (get(unwrap(filesEnabledAtom)) || get(unwrap(bulkUploadsEnabledAtom))) &&
      state &&
      state.mode !== 'file' &&
      (state.mode !== 'text' || state.value.length === 0)
    );
  });

  const submitVariablesAtom = atom<VariablesState>((get) => {
    const state = get(editorStateAtom);

    const handleFile = (inState: AddFileMemoryState | ConfirmUploadState) => {
      if (!get(unwrap(filesEnabledAtom))) {
        return undefined;
      }

      if (!isUploaded(inState.value)) {
        return undefined;
      }

      return {
        type: 'single' as const,
        variables: {
          fileMicrobotMemoryInput: {
            fileId: inState.value.file.id,
          },
        },
      };
    };

    const handleBulk = (inState: ConfirmUploadState) => {
      if (!get(unwrap(bulkUploadsEnabledAtom))) {
        return undefined;
      }

      if (!isUploaded(inState.value)) {
        return undefined;
      }

      return {
        type: 'bulk' as const,
        variables: { fileId: inState.value.file.id },
      };
    };

    const handleText = (inState: AddTextMemoryState) => {
      const trimmedValue = inState.value.trim();
      if (trimmedValue.length === 0) {
        return undefined;
      }
      return {
        type: 'single' as const,
        variables: { value: trimmedValue },
      };
    };

    const handleUrl = (inState: AddUrlMemoryState) => {
      if (!inState.retrievalType) {
        return undefined;
      }

      return {
        type: 'single' as const,
        variables: {
          urlMicrobotMemoryInput: {
            retrievalType: inState.retrievalType,
            url: inState.value,
          },
        },
      };
    };

    switch (state?.mode) {
      case 'file':
        return handleFile(state);
      case 'text':
        return handleText(state);
      case 'url':
        return handleUrl(state);
      case 'confirm':
        if (state.uploadType === 'bulk') {
          return handleBulk(state);
        }

        return handleFile(state);
      case undefined:
        return undefined;
      default:
        warnExhaustive(state, 'addMemoryAtom/mutationVariablesForState');
        return undefined;
    }
  });

  const canAdvanceAtom = atom((get) => {
    const state = get(editorStateAtom);

    if (state?.mode === 'text') {
      return state.value.length > 0;
    }

    if (state?.mode === 'file') {
      return isUploaded(state.value);
    }

    if (state?.mode === 'url') {
      return true;
    }

    return false;
  });

  const openEditorAtom = actionAtom(function openEditorAtomSetter({
    get,
    set,
  }) {
    if (get(editorStateAtom) !== null) {
      return;
    }

    set(errorMessageKeyAtom, null);
    set(editMemoryStateAtom, initialEditMemoryState);
    set(editorStateAtom, { mode: 'text', value: '' });
  });

  const closeEditorAtom = actionAtom(({ set }) => {
    set(removeFileAtom); // not hanging on removing the file
    set(editMemoryStateAtom, initialEditMemoryState);
    set(editorStateAtom, null);
  });

  const openEditMemoryAtom = actionAtom(
    (
      { get, set },
      state: AddTextMemoryState,
      editMemoryState: EditMemoryState,
    ) => {
      if (get(editorStateAtom) !== null) {
        return;
      }

      set(errorMessageKeyAtom, null);
      set(editMemoryStateAtom, editMemoryState);
      set(editorStateAtom, { ...state });
    },
  );

  const textContentAtom = atom(
    (get) => {
      const state = get(editorStateAtom);
      return state?.mode === 'text' ? state.value : '';
    },
    (get, set, value: string) => {
      if (get(editorStateAtom)?.mode !== 'text') {
        // Not in text input mode
        return;
      }

      // Clearing the error message
      set(errorMessageKeyAtom, null);

      set(editorStateAtom, {
        mode: 'text',
        value,
      });
    },
  );

  const retrievalTypeAtom = atom(
    (get) => {
      const state = get(editorStateAtom);
      return state?.mode === 'url' ? state.retrievalType : undefined;
    },
    (get, set, value: MemoryRetrievalType) => {
      const state = get(editorStateAtom);
      if (state?.mode !== 'url') {
        // Not in url input mode
        return;
      }

      set(editorStateAtom, {
        ...state,
        retrievalType: value,
      });
    },
  );

  const [, uploadFileAtom] = soloActionAtoms(
    async ({ get, set }, file: FileData) => {
      const state = get(editorStateAtom);
      const canBulkUpload = get(unwrap(bulkUploadsEnabledAtom));

      if (state?.mode === 'file') {
        // Already a file uploaded.
        return;
      }

      const uploadType = await getUploadType(file, canBulkUpload);

      const client = await get(clientAtom);

      const upload = uploadAgencyFile(client, file, {
        fileSource: FileSource.MicrobotMemory,
        sizeThreshold: MicrobotMemoryFileSizeThresholdMB,
      });

      set(editorStateAtom, {
        mode: 'file',
        uploadType,
        value: upload,
      });

      const result = await upload.promise;

      if (result.ok === true) {
        set(editorStateAtom, {
          mode: 'file',
          uploadType,
          value: {
            file: result.data,
          },
        });
      } else {
        set(editorStateAtom, null);
      }
    },
  );

  const [, removeFileAtom] = soloActionAtoms(async ({ get }) => {
    const state = get(editorStateAtom);

    if (state?.mode !== 'file') {
      return;
    }

    if (isUploaded(state.value)) {
      const client = await get(clientAtom);

      try {
        await client.mutate({
          mutation: DeleteFileDocument,
          variables: { fileId: state.value.file.id },
        });
      } catch (e) {
        Sentry.captureException(e);
        showToast({
          messageI18Key: 'oops-something-went-wrong',
          variant: 'rose',
          iconName: 'X',
        });
      }
    } else if (isBeingUploaded(state.value)) {
      state.value.cancel();
    }
  });

  const attachmentAtom = atom(
    (get) => {
      const state = get(editorStateAtom);
      if (state?.mode !== 'file' && state?.mode !== 'confirm') {
        return null;
      }

      return { value: state.value, uploadType: state.uploadType };
    },
    async (_get, set, file: FileData | null) => {
      if (file === null) {
        await set(removeFileAtom);

        set(editorStateAtom, {
          mode: 'text',
          value: '',
        });
      } else {
        await set(uploadFileAtom, file);
      }
    },
  );

  const [, submitAtom] = soloActionAtoms(async ({ get, set }) => {
    const client = await get(clientAtom);
    const variablesState = get(submitVariablesAtom);
    const editMemoryState = get(editMemoryStateAtom);
    const { id: editingMemoryId } = editMemoryState;

    if (!variablesState) {
      return;
    }

    try {
      const { type, variables } = variablesState;

      if (type === 'bulk') {
        await client.mutate({
          mutation: CreateBulkUploadDocument,
          variables,
        });
      } else if (editingMemoryId) {
        await client.mutate({
          mutation: UpdateMicrobotMemoryDocument,
          variables: {
            ...variables,
            microbotMemoryId: editingMemoryId,
          },
          update(cache, result) {
            if (result.data?.updateMicrobotMemory) {
              updateMemoriesQuery(cache, result.data.updateMicrobotMemory);
            }
          },
        });
      } else {
        await client.mutate({
          mutation: CreateMicrobotMemoryDocument,
          variables,
          update(cache, result) {
            if (result.data?.createMicrobotMemory) {
              updateMemoriesQuery(cache, result.data.createMicrobotMemory);
            }
          },
        });
      }

      const messageI18Key = (() => {
        if (type === 'bulk') {
          return null;
        }

        if (editingMemoryId) {
          return 'selected-memory-was-edited';
        }

        return 'new-memory-was-added-to-memory-bank';
      })();

      await refetchQueriesOrIgnore(client, [
        MicrobotMemoriesDocument,
        MemoryBankBulkUploadsDocument,
      ]);

      set(editorStateAtom, null);

      if (messageI18Key) {
        showToast({
          iconName: 'CircleCheck',
          variant: 'blue',
          namespace: 'microbot',
          messageI18Key,
        });
      }
    } catch (error) {
      Sentry.captureException(error);

      set(editorStateAtom, null);

      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  });

  const [, advanceAtom] = soloActionAtoms(async ({ get, set }) => {
    const state = get(editorStateAtom);
    const editMemoryState = get(editMemoryStateAtom);
    const client = await get(clientAtom);
    const canBulkUpload = get(unwrap(bulkUploadsEnabledAtom));

    const { retrievalType } = editMemoryState;

    if (!state) {
      return;
    }

    // Are we sending text? Perhaps it is a URL
    if (state.mode === 'text') {
      const isUrlsEnabled = await get(urlsEnabledAtom);
      const urlRegexResult = UrlTextContentRegex.exec(state.value.trim());

      if (urlRegexResult !== null) {
        // It is a url
        if (!isUrlsEnabled) {
          set(errorMessageKeyAtom, 'microbot:urls-are-not-supported');
          return;
        }

        let valid = false;
        try {
          const result = await client.mutate({
            mutation: CheckIsUrlValidDocument,
            variables: {
              url: urlRegexResult[0],
            },
          });

          valid = result.data?.checkIsUrlValid ?? false;
        } catch (e) {
          if (!(e instanceof Error) || !isApolloError(e)) {
            Sentry.captureException(e);
          }
        }

        if (!valid) {
          set(
            errorMessageKeyAtom,
            'microbot:invalid-inaccessible-or-broken-url',
          );
          return;
        }

        set(editorStateAtom, {
          mode: 'url',
          value: urlRegexResult[0],
          retrievalType: retrievalType || MemoryRetrievalType.Once,
        });
        return;
      }
    }

    // require user confirmation for bulk upload
    if (state.mode === 'file' && state.uploadType !== 'file' && canBulkUpload) {
      set(editorStateAtom, {
        mode: 'confirm',
        value: state.value,
        uploadType: state.uploadType,
      });
      return;
    }

    // Otherwise just submit.
    await set(submitAtom);
  });

  return {
    modalStepAtom,
    canAdvanceAtom,
    canUploadFilesAtom,
    canBulkUploadAtom: bulkUploadsEnabledAtom,

    textContentAtom,
    retrievalTypeAtom,
    attachmentAtom,
    errorMessageKeyAtom,

    openEditorAtom,
    closeEditorAtom,
    openEditMemoryAtom,
    advanceAtom,

    editMemoryStateAtom: atom((get) => get(editMemoryStateAtom)), // read-only
  };
});

export default AddMicrobotMemoryMolecule;
