import React, { useCallback, memo } from 'react';
import type { ListRenderItem } from 'react-native';

import { ErrorView, FlatList, Layout } from '@advisor/design/components';
import { RoleRequestInfoFragment } from '@advisor/api/generated/graphql';
import ChatRoomsLoader from '../ChatRoomList/ChatRoomListLoader';
import { RowSeparator } from '../ChatRoomList/ChatRoomRow';
import RoleRequestRowEmpty from './RoleRequestRowEmpty';
import useRoleRequests from './useRoleRequests';
import RoleRequestRow from './RoleRequestRow';

const keyExtractor = (item: RoleRequestInfoFragment) => item.id;

const RoleRequestList: React.FC = () => {
  const { error, loading, hasMore, loadMore, roleRequests, refetch } =
    useRoleRequests();

  const renderEmptyComponent = useCallback(() => {
    if (loading) {
      return null;
    }

    return <RoleRequestRowEmpty />;
  }, [loading]);

  const renderListLoadingIndicator = useCallback(() => {
    if (loading || hasMore) {
      return (
        <div>
          <ChatRoomsLoader />
        </div>
      );
    }

    return null;
  }, [loading, hasMore]);

  const renderRoleRequest: ListRenderItem<RoleRequestInfoFragment> =
    useCallback(({ item }) => <RoleRequestRow roleRequest={item} />, []);

  if (error) {
    return (
      <div className="w-full">
        <Layout.Spacer.Vertical size="medium" />
        <ErrorView onTryAgain={() => refetch()} />
      </div>
    );
  }

  return (
    <FlatList
      className="overflow-y-auto overflow-x-visible w-full max-h-full"
      contentContainerClassName="p-6"
      data={roleRequests}
      renderItem={renderRoleRequest}
      keyExtractor={keyExtractor}
      ListEmptyComponent={renderEmptyComponent}
      ListFooterComponent={renderListLoadingIndicator}
      ItemSeparatorComponent={RowSeparator}
      onEndReached={loadMore}
    />
  );
};

export default memo(RoleRequestList);
