import React, { PropsWithChildren } from 'react';

import DailyAPIProvider from './DailyAPIProvider';
import MembraneAPIProvider from './MembraneAPIProvider';

type VideoRoomAPIProviderProps = PropsWithChildren;

const VideoRoomAPIProvider: React.FC<VideoRoomAPIProviderProps> = (props) => {
  const { children } = props;

  return (
    <DailyAPIProvider>
      <MembraneAPIProvider>{children}</MembraneAPIProvider>
    </DailyAPIProvider>
  );
};

export default VideoRoomAPIProvider;
