import React from 'react';
import useOnline from './useOnline';

/**
 * Higher order component that only renders the wrapped component if the user is online.
 * Useful for unmounting components that really on Apollo's useSubscription as this will
 * reset subscription when user regain network connection.
 */
export default function makeOnlineOnly<Props extends object>(
  Component: React.ComponentType<Props>,
) {
  const Wrapper: React.FC<Props> = (props) => {
    const isOnline = useOnline();

    if (!isOnline) {
      return null;
    }

    return <Component {...props} />;
  };

  return Wrapper;
}
