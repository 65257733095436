import { atomWithReducer } from 'jotai/utils';

import { warnExhaustive } from '../typeAssertions';

type Action<T> = ['add', T] | ['delete', T];

const collectionReducer = <T>(prev: T[], [action, param]: Action<T>) => {
  if (action === 'add') {
    if (prev.includes(param)) {
      return prev;
    }

    return [...prev, param];
  }

  if (action === 'delete') {
    return prev.filter((i) => i !== param);
  }

  warnExhaustive(action, 'utils/collectionAtom');
  return prev;
};

function collectionAtom<T>(initial: T[] = []) {
  return atomWithReducer<T[], Action<T>>(initial, collectionReducer);
}

export default collectionAtom;
