import { atom } from 'jotai';

import { atomWithToggle } from '@advisor/utils/atoms';
import { isPresentInVideoRoomAtom } from '../Presence';
import {
  RecordingConsentState,
  recordingConsentStateAtom,
} from '../RecordingManager';

export const micStatePreferenceAtom = atomWithToggle(true);
export const camStatePreferenceAtom = atomWithToggle(true);

const peripheralsBlocked = atom((get) => {
  const consent = get(recordingConsentStateAtom);

  return (
    consent === RecordingConsentState.AWAITING_DECISION ||
    consent === RecordingConsentState.DENIED
  );
});

export const isMicrophoneEnabledAtom = atom(
  (get) => get(micStatePreferenceAtom) && !get(peripheralsBlocked),
  (_get, set, value: boolean | undefined) => set(micStatePreferenceAtom, value),
);

export const isCameraEnabledAtom = atom(
  (get) =>
    get(camStatePreferenceAtom) &&
    get(isPresentInVideoRoomAtom) &&
    !get(peripheralsBlocked),
  (_get, set, value: boolean | undefined) => set(camStatePreferenceAtom, value),
);
