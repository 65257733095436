import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Form } from '@advisor/design/components';
import { useEvent } from '@advisor/utils/hooks';
import useMilestoneCategoryFrom from './useMilestoneCategoryForm';
import { MilestoneCategoryFromProps } from './types';

const MilestoneCategoryForm: React.FC<MilestoneCategoryFromProps> = (props) => {
  const { t } = useTranslation(['common', 'task-organiser']);

  const { submit, inputs } = useMilestoneCategoryFrom(props);

  const handleFormSubmit = useEvent((e?: React.FormEvent) => {
    e?.preventDefault();

    submit();
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="px-8 py-6 flex flex-col">
        <Form.Text
          label={t('task-organiser:category-name')}
          placeholder={t('common:add-name')}
          {...inputs.name}
        />
      </div>
      <div className="border border-grey px-8 py-6 rounded bg-white flex">
        <DeprecatedButton.Large
          variant="primary"
          label={t('common:save')}
          onPress={handleFormSubmit}
        />
      </div>
    </form>
  );
};

export default MilestoneCategoryForm;
