import React, { useEffect, useState } from 'react';

import { DateFormatter } from '@advisor/language';

type Props = {
  start: string;
};

const Timer = ({ start }: Props) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const tick = setTimeout(() => setTime(new Date()), 1000);
    return () => clearTimeout(tick);
  });

  return <>{DateFormatter.duration({ start, end: time })}</>;
};

export default Timer;
