import Sentry from './Sentry';

/**
 * Errors during build when the value passed in has not been
 * handled exhaustively. Useful at the end of multiple if-statements
 * or in the switch default case to make sure at build-time that we
 * have handled all cases.
 */
export function warnExhaustive(x: never, location: string) {
  Sentry.captureException(new Error(`Failed to handle ${x} at ${location}`));
}

// prettier-ignore
/** Taken from `type-fest` */
export type IsEqual<A, B> =
  (<G>() => G extends A ? 1 : 2) extends
  (<G>() => G extends B ? 1 : 2)
    ? true
    : false;

export type ExactTypeGuard<X, Y> = IsEqual<X, Y> extends true
  ? never
  : ['Types are not equal'];

/**
 * no-op during runtime
 * @example ```
 * // succeeds
 * const aTuple = ['a', 'b', 'c'] as const;
 * assertType<ExactTypeGuard<typeof aTuple, ['a', 'b', 'c']>>();
 *
 * // fails
 * const aTuple = ['a', 'b', 'c'] as const;
 * assertType<ExactTypeGuard<typeof aTuple, ['a', 'b', 'c', 'd']>>();
 *
 * // fails
 * const aTuple = ['a', 'b', 'c'] as const;
 * assertType<ExactTypeGuard<typeof aTuple, ['a', 'b']>>();
 * ```
 */
export function assertType<TGuard extends never>() {
  return undefined as TGuard;
}
