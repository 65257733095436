import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback } from 'react';

import { Layout, DeprecatedButton, Modal } from '@advisor/design/components';
import { FileInput } from '../../components/FileUpload';
import useGreyLabeling from '../useGreyLabeling';
import { customizationTabAtom, showBrandCustomizationModalAtom } from './atoms';
import { CustomizationTabs } from './types';
import { acceptFileTypes } from './utils';

const LogoTab: React.FC = () => {
  const setShowModal = useSetAtom(showBrandCustomizationModalAtom);
  const setActiveTab = useSetAtom(customizationTabAtom);
  const { t } = useTranslation('brand-customization');
  const { t: commT } = useTranslation('common');

  const greyLabeling = useGreyLabeling();

  const onSaveAndContinue = useCallback(async () => {
    await greyLabeling.updateGreyLabeling();

    setActiveTab(CustomizationTabs.Colors);
  }, [greyLabeling, setActiveTab]);

  return (
    <>
      <Modal.Header
        title={t('upload-logo')}
        subtitle={t('for-customising-brand-identity')}
        onClose={() => setShowModal(false)}
        onBack={() => setActiveTab(CustomizationTabs.Description)}
      />
      <div className="px-8 py-6">
        <div className="bg-light-grey border border-grey rounded-lg p-4 items-center">
          <h5 className="text-dark-grey-03 font-sora text-sm font-bold">
            {t('organization-logo')}
          </h5>
          <Layout.Spacer.Vertical size="micro" />
          <p className="font-outfit text-xs text-dark-grey-02">
            {t(
              'for-optimal-results-we-recommend-uploading-an-svg-or-png-with-transparent-background',
            )}
          </p>
          <Layout.Spacer.Vertical size="tiny" />
          <FileInput
            accept={acceptFileTypes}
            placeholder={t('add-svg-png')}
            isUploading={greyLabeling.isLogoUploading}
            file={greyLabeling.organizationLogo?.file}
            onFilePicked={greyLabeling.setOrganizationLogo}
            onDeleteFile={() => greyLabeling.setOrganizationLogo(null)}
          />
        </div>
      </div>
      <Modal.Footer>
        <DeprecatedButton.Large
          variant="primary"
          disabled={greyLabeling.isLogoUploading}
          onPress={onSaveAndContinue}
        >
          {commT('continue')}
        </DeprecatedButton.Large>
      </Modal.Footer>
    </>
  );
};

export default memo(LogoTab);
