/* eslint-disable no-console */
import { noop } from 'lodash-es';
import { GraphQLError } from 'graphql';

import Env from '@advisor/api/env';
import ComplexError from './ComplexError';

interface SentryOptions {
  dsn: string;
  enableInExpoDevelopment?: boolean;
  debug?: boolean;
  tracesSampleRate?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations?: any[];
  environment?: string;
}

type ExceptionContext = Record<string, string | number>;

type GQLLikeError = { errors: GraphQLError[] };

type ApolloError = {
  name?: string;
  graphQLErrors?: GraphQLError[];
};

type WrappedApolloError = { error: ApolloError };

type ApolloLikeError = ApolloError | WrappedApolloError;

type Auth0LikeError = { error: string; errorDescription: string };

type ObjectWithMessage = { message: string };

interface SimplifiedSentryBackend {
  init: (options: SentryOptions) => void;
  captureException: (exception: unknown) => void;
  setContext: (contextName: string, context: ExceptionContext) => void;
}

interface DummyBackend extends SimplifiedSentryBackend {
  context: Record<string, ExceptionContext>;
}

const sentryMaxMessageLength = 8192;

const omittableErrorMessagesRegex = [
  /User with id=\w+ doesn't exist!/,
  /Email address \w+ is already taken!/,
];

const omittableErrorMessageStrings = [
  'Timeout during authentication renew',
  'You have to be logged or provide at least on parameter',
  'UnauthorizedException',
  'The notification permission was not granted and blocked instead',
];

// Used to allow the captureException method of the class below
// to traverse the full exception parsing in local environment
// allowing to find exceptions that causes problem with the parsing
// in production env (f.e. "t is not an Object. (evaluating ''errors'in t')")
const DummyBackend: DummyBackend = {
  init: noop,
  context: {},
  captureException(e) {
    if (e instanceof ComplexError) {
      console.warn(e);
      console.warn('Caused by:');
      console.warn(e.cause);
    } else {
      console.warn(e, '\n\nContext\n', JSON.stringify(this.context, null, 2));
    }
  },
  setContext(contextName, contextValue) {
    this.context[contextName] = contextValue;
  },
};

function isGQLLikeError(error: unknown): error is GQLLikeError {
  return (
    typeof error === 'object' &&
    error !== null &&
    (error as GQLLikeError).errors !== undefined &&
    (error as GQLLikeError).errors instanceof Array
  );
}

function isApolloLikeError(error: unknown): error is ApolloLikeError {
  if (typeof error !== 'object' || !error) {
    return false;
  }

  if ((error as WrappedApolloError).error !== undefined) {
    return (
      (error as WrappedApolloError).error.graphQLErrors !== undefined &&
      (error as WrappedApolloError).error.graphQLErrors instanceof Array
    );
  }

  return (
    (error as ApolloError).graphQLErrors !== undefined &&
    (error as ApolloError).graphQLErrors instanceof Array
  );
}

function isAuth0LikeError(error: unknown): error is Auth0LikeError {
  return (
    typeof error === 'object' &&
    error !== null &&
    (error as Auth0LikeError).error !== undefined &&
    (error as Auth0LikeError).errorDescription !== undefined
  );
}

function isObjectWithMessage(error: unknown): error is ObjectWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    (error as ObjectWithMessage).message !== undefined
  );
}

function isErrorOrString(error: unknown): error is Error | string {
  return typeof error === 'string' || error instanceof Error;
}

function parsePathInfo(path?: ReadonlyArray<string | number>): string {
  if (!path) {
    return '';
  }
  let baseStr = '[Path: ';

  path.forEach((pathPart, idx) => {
    baseStr += idx > 0 ? `, ${pathPart}` : `${pathPart}`;
  });

  baseStr += ']';

  return baseStr;
}

function parseGraphQLError(error: GraphQLError, prefix?: string): string {
  let message = `${prefix ? `${prefix} ` : ''}${error.message}`;

  const pathInfo = parsePathInfo(error.path);

  if (message.length + pathInfo.length + 1 < sentryMaxMessageLength) {
    message += ` ${pathInfo}`;
  }

  return message;
}

function parseGQLLikeError(error: GQLLikeError): string[] {
  return error.errors.map((gqlE) => parseGraphQLError(gqlE));
}

function parseApolloLikeError(exception: ApolloLikeError): string[] {
  const error = (exception as WrappedApolloError).error ?? exception;
  const prefix = error.name ? `[${error.name}]` : '';

  return (
    error.graphQLErrors?.map((gqlE) => parseGraphQLError(gqlE, prefix)) ?? []
  );
}

function parseAuth0LikeError(error: Auth0LikeError): string {
  return `[${error.error}] ${error.errorDescription}`;
}

function parseErrorWithMessage(error: ObjectWithMessage): string {
  return error.message;
}

function isValuableMessage(message: string | Error) {
  const msg = message instanceof Error ? message.message : message;

  if (omittableErrorMessageStrings.some((str) => msg.includes(str))) {
    return false;
  }

  if (omittableErrorMessagesRegex.some((expr) => msg.match(expr))) {
    return false;
  }

  return true;
}

class Sentry {
  Backend: SimplifiedSentryBackend | undefined;

  exceptionContext: Record<string, ExceptionContext> = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init(backend: SimplifiedSentryBackend, dsn?: string, integrations?: any[]) {
    if (!dsn || dsn === 'false') {
      this.Backend = DummyBackend;
      return;
    }

    this.Backend = backend;

    this.Backend.init({
      dsn,
      enableInExpoDevelopment: false,
      debug: false,
      tracesSampleRate: 0.6,
      integrations,
      environment: Env.instanceKey,
    });
  }

  captureErrorMessages(messages: string | Error | string[]) {
    if (messages instanceof Array) {
      messages.forEach((message) => {
        if (!isValuableMessage(message)) {
          return;
        }

        this.Backend?.captureException(message);
      });
    } else if (isValuableMessage(messages)) {
      this.Backend?.captureException(messages);
    }
  }

  captureException(exception: unknown) {
    if (!this.Backend) {
      return;
    }

    if (isGQLLikeError(exception)) {
      this.captureErrorMessages(parseGQLLikeError(exception));
      return;
    }

    if (isApolloLikeError(exception)) {
      this.captureErrorMessages(parseApolloLikeError(exception));
      return;
    }

    if (isAuth0LikeError(exception)) {
      // Do not report invalid token errors
      if (
        exception.error === 'invalid_token' &&
        exception.errorDescription === '`state` does not match.'
      ) {
        return;
      }

      this.captureErrorMessages(parseAuth0LikeError(exception));
      return;
    }

    if (isObjectWithMessage(exception)) {
      this.captureErrorMessages(parseErrorWithMessage(exception));
      return;
    }

    if (isErrorOrString(exception)) {
      this.captureErrorMessages(exception);
      return;
    }

    try {
      // Get rid of all eventual properties that are not JSON friendly
      // or not necessary in the error report (f.e. functions)
      const serializedException = JSON.parse(JSON.stringify(exception));

      this.setContext('exception-json', serializedException);
      this.Backend.captureException('Unknown type of error occured!');
      this.setContext('exception-json', {});
    } catch {
      this.captureErrorMessages('Not serializable error occured!');
    }
  }

  setContext(key: string, value: ExceptionContext) {
    this.exceptionContext[key] = value;
    this.Backend?.setContext(key, value);
  }
}

export default new Sentry();
