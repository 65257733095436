import React from 'react';
import { useTranslation } from 'react-i18next';
import z from 'zod';

import { useEvent } from '@advisor/utils/hooks';
import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { Icon } from '@advisor/design/components';
import Tooltip from '@advisor/design/components/Tooltip';
import {
  useRecordingControls,
  useRecordingState,
  isRecordingStateTransitional,
} from '../../RecordingManager';
import RecordButton from './RecordButton';
import useOnStartRecording from './useRecordingControls';
import { RecordingControlsProps } from './types';

const RecordingMessageLocation = new UserMetadataLocation(
  'videoCall.recording.message',
  z.boolean(),
);

function BeingRecordedTooltip(props: {
  recording?: boolean;
  children?: React.ReactNode;
}) {
  const { recording, children } = props;
  const { t } = useTranslation('common');
  const [hideTooltip, setHideTooltip] = useUserMetadata(
    RecordingMessageLocation,
  );

  return (
    <Tooltip.Root>
      {children}
      <Tooltip.Content
        variant="negative"
        visible={!hideTooltip && recording}
        position={undefined}
      >
        <div className="flex flex-row gap-4 text-white max-w-full">
          <p className="whitespace-pre-line font-medium">
            {t('video-conversations-are-recorded')}
          </p>
          <button
            type="button"
            className="hover:opacity-50"
            onClick={() => setHideTooltip(true)}
          >
            <Icon name="X" className="w-8 h-8" />
          </button>
        </div>
      </Tooltip.Content>
    </Tooltip.Root>
  );
}

function RecordingControls({ variant }: RecordingControlsProps) {
  const recordingState = useRecordingState();
  const { stopRecording } = useRecordingControls();
  const { onStartRecording, disabled } = useOnStartRecording();

  const handleToggle = useEvent(async () => {
    if (isRecordingStateTransitional(recordingState)) {
      return;
    }

    if (recordingState.type === 'recording') {
      await stopRecording?.();
    } else {
      await onStartRecording();
    }
  });

  return (
    <BeingRecordedTooltip recording={recordingState.type === 'recording'}>
      <RecordButton
        variant={variant}
        readOnly={disabled}
        recordingState={recordingState}
        onToggle={handleToggle}
      />
    </BeingRecordedTooltip>
  );
}

export default RecordingControls;
