import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useOpenJourneyMilestone } from './navigation';
import { GroupMessageCommentItemProps } from './types';
import { BoldText, Message } from './systemMessageComponents';
import MessageButton from './MessageButton';

const GroupMessageCommentItem: React.FC<GroupMessageCommentItemProps> = (
  props,
) => {
  const { commentCount, chatRoomId, categoryId, milestoneId, milestoneName } =
    props;
  const openMilestone = useOpenJourneyMilestone();
  const { t } = useTranslation('system-message');

  const onPress = useCallback(() => {
    openMilestone(chatRoomId, categoryId, milestoneId);
  }, [openMilestone, categoryId, chatRoomId, milestoneId]);

  return (
    <MessageButton
      label={
        <Trans
          t={t}
          parent={Message}
          components={{ bold: <BoldText /> }}
          values={{
            count: String(commentCount).padStart(2, '0'),
            milestone: milestoneName,
          }}
          i18nKey={
            commentCount === 1
              ? 'new-comment-in-task'
              : 'count-new-comments-in-task'
          }
        />
      }
      onPress={onPress}
      showGlobeIcon={false}
      border={false}
      fontBold={false}
    />
  );
};

export default GroupMessageCommentItem;
