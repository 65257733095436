import { Getter, Setter, atom } from 'jotai';

import { clientAtom } from '@advisor/api/apollo';
import { ChatRoomStatusInfoFragment } from '@advisor/api/generated/graphql';
import { userConfigQueryAtom } from '@advisor/api/userConfig/useUserConfig';
import { showToast } from '@advisor/design/components/Toast';
import { EmptyStatus } from '../ChatRoomStatus';
import { restoreStatuses, toStatusInput, updateStatuses } from './utils';
import {
  EditStatusesState,
  EditStatusesAction,
  UpdateStatusAction,
  UpdateActiveAction,
  CreateStatusAction,
} from './types';

const internalAtom = atom<EditStatusesState>(null);

export default atom(
  (get) => get(internalAtom),
  async (get, set, action: EditStatusesAction) => {
    switch (action.type) {
      case 'save':
        return save(get, set);
      case 'open':
        return open(get, set);
      case 'close':
        return close(get, set);

      case 'restore':
        return restore(get, set);
      case 'deleteStatus':
        return deleteStatus(get, set);
      case 'updateStatus':
        return updateStatus(get, set, action);
      case 'updateActive':
        return updateActive(get, set, action);
      case 'createStatus':
        return createStatus(get, set, action);
      default:
        return null;
    }
  },
);

//
// Action handlers
//

async function open(get: Getter, set: Setter) {
  const userConfig = await get(userConfigQueryAtom);

  set(internalAtom, {
    activeIndex: 0,
    statuses: filterEmptyStatus(userConfig.data?.userConfig.chatRoomStatuses),
  });
}

async function close(_get: Getter, set: Setter) {
  set(internalAtom, null);
}

async function save(get: Getter, set: Setter) {
  const client = await get(clientAtom);

  const mutationResult = await updateStatuses(
    client,
    toStatusInput(get(internalAtom)?.statuses),
  );

  if (mutationResult) {
    showToast({
      iconName: 'CircleCheck',
      variant: 'blue',
      messageI18Key: 'selected-status-was-successfully-edited',
    });
  }
  set(internalAtom, null);
}

async function updateStatus(
  get: Getter,
  set: Setter,
  action: UpdateStatusAction,
) {
  const statuses = get(internalAtom)?.statuses;

  if (statuses && statuses.length > action.index) {
    const copy = statuses.slice();

    copy[action.index] = {
      ...copy[action.index],
      ...action.update,
    };

    set(internalAtom, {
      activeIndex: action.index,
      statuses: copy,
    });
  }
}

async function updateActive(
  get: Getter,
  set: Setter,
  action: UpdateActiveAction,
) {
  set(internalAtom, {
    activeIndex: action.index,
    statuses: get(internalAtom)?.statuses ?? [],
  });
}

async function createStatus(
  get: Getter,
  set: Setter,
  action: CreateStatusAction,
) {
  const client = await get(clientAtom);
  const { input } = action;

  const mutationResult = await updateStatuses(client, [
    ...toStatusInput(get(internalAtom)?.statuses),
    {
      name: input.name,
      i18nKey: input.i18nKey,
      color: input.color ? input.color : '#FFFFFF',
    },
  ]);

  if (mutationResult) {
    const userConfig = await get(userConfigQueryAtom);

    set(internalAtom, {
      activeIndex: 0,
      statuses: filterEmptyStatus(userConfig.data?.userConfig.chatRoomStatuses),
    });

    showToast({
      iconName: 'CircleCheck',
      variant: 'blue',
      messageI18Key: 'status-was-successfully-added',
    });
  }
}

async function deleteStatus(get: Getter, set: Setter) {
  const client = await get(clientAtom);

  const mutationResult = await updateStatuses(client, [
    ...toStatusInput(
      get(internalAtom)?.statuses?.filter(
        (_status, index) => index !== get(internalAtom)?.activeIndex,
      ),
    ),
  ]);

  if (mutationResult) {
    const userConfig = await get(userConfigQueryAtom);

    set(internalAtom, {
      activeIndex: 0,
      statuses: filterEmptyStatus(userConfig.data?.userConfig.chatRoomStatuses),
    });

    showToast({
      iconName: 'CircleCheck',
      variant: 'rose',
      messageI18Key: 'selected-status-was-deleted',
    });
  }
}

async function restore(get: Getter, set: Setter) {
  const client = await get(clientAtom);
  const mutationResult = await restoreStatuses(client);

  if (mutationResult) {
    const userConfig = await get(userConfigQueryAtom);

    set(internalAtom, {
      activeIndex: 0,
      statuses: filterEmptyStatus(userConfig.data?.userConfig.chatRoomStatuses),
    });

    showToast({
      iconName: 'CircleCheck',
      variant: 'rose',
      messageI18Key: 'statuses-were-successfully-restored-to-default',
    });
  }
}

//
// Helpers
//

function filterEmptyStatus(statuses?: null | ChatRoomStatusInfoFragment[]) {
  return (statuses ?? [])?.filter(
    (status) => status.i18nKey !== EmptyStatus.i18nKey,
  );
}
