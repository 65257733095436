import React from 'react';

import { useLanguage } from '@advisor/language';
import Icon from './Icon';
import { IconProps } from './types';

const ChevronIcon: React.FC<Omit<IconProps, 'name'>> = (props) => {
  const { isRTL } = useLanguage();

  return <Icon name={isRTL ? 'ChevronLeft' : 'ChevronRight'} {...props} />;
};

export default ChevronIcon;
