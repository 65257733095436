import { atom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { rejectAfter } from '@advisor/utils/promiseTimeouts';
import type { AuthStateAtom, AuthStateType } from './types';

let resolveAuthState: ((value: AuthStateType) => void) | undefined;
const internalAtom = atom<Promise<AuthStateType> | AuthStateType>(
  Promise.race([
    new Promise<AuthStateType>((res) => {
      resolveAuthState = res;
    }),
    // Assume we are not logged in after 10 seconds
    rejectAfter(10000),
  ]).catch((err) => {
    Sentry.captureException(
      new ComplexError(
        `Failed to resolve state of authentication after 10 seconds`,
        err,
      ),
    );
    return null;
  }),
);

const authStateAtom: AuthStateAtom = atom(
  (get) => get(internalAtom),
  (_get, set, value: AuthStateType) => {
    if (resolveAuthState) {
      // There is auth state to be resolved
      resolveAuthState(value);
      resolveAuthState = undefined;
    } else {
      // We are updating the local value after authenticating (e.g. logging out)
      set(internalAtom, value);
    }
  },
);

export default authStateAtom;
