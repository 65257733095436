import { atomFamily } from 'jotai/utils';

import { derive } from '@advisor/utils/atoms';
import enabledFeaturesAtom from './enabledFeaturesAtom';
import { FeatureKey } from './types';

const featureEnabledAtoms = atomFamily((feature: FeatureKey) =>
  derive([enabledFeaturesAtom], (flags) => flags[feature] ?? false),
);

export default featureEnabledAtoms;
