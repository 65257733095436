import cs from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { memo, useMemo, PropsWithChildren } from 'react';

import { AdvisorRole } from '@advisor/api/generated/graphql';
import { useUserRole } from '@advisor/api/user';
import AvatarGroup from '@advisor/ui/components/AvatarGroup';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import { Icon, Avatar, Layout } from '@advisor/design/components';
import useUserName from './useUserName';
import {
  UserNameProps,
  RichMessageProps,
  UserDetailsProps,
  SimpleMessageProps,
  ExpandButtonProps,
} from './types';

type GenericProps = PropsWithChildren<{ className?: string }>;

export const Container: React.FC<GenericProps> = memo(
  ({ children, className }) => (
    <div
      className={cs(
        'bg-white xl:rounded-lg py-4 mb-4 border border-light-grey',
        className,
      )}
    >
      <div className="px-6">{children}</div>
    </div>
  ),
);

export const CenteredMessage: React.FC<GenericProps> = memo(
  ({ children, className }) => (
    <div className={cs('text-center text-sm', className)}>{children}</div>
  ),
);

export const Message: React.FC<GenericProps> = memo(
  ({ children, className }) => (
    <div className={cs('text-sm max-w-[80%]', className)}>{children}</div>
  ),
);

export const MessageMD: React.FC<GenericProps> = memo(
  ({ children, className }) => (
    <div className={cs('text-sm', className)}>{children}</div>
  ),
);

export const ScrollMessage: React.FC = memo(() => {
  const { t } = useTranslation('common');

  return (
    <div className="text-xs text-dark-grey-03 font-semibold text-center">
      <Icon
        name="ArrowLeft"
        className="w-2 h-2 rotate-90 inline text-dark-grey-01 mr-1.5"
      />
      {t('you-can-scroll-up-to-see-previous-conversation')}
    </div>
  );
});

export const UserName: React.FC<UserNameProps> = memo(({ user }) => {
  const color = useUserColor(user);
  const userName = useUserName(user);

  return (
    <span className="text-sm font-bold" style={{ color }}>
      {userName}
    </span>
  );
});

export const BoldText = memo(({ children, className }: GenericProps) => (
  <span className={cs('font-bold', className)}>{children}</span>
));

export const UserDetails: React.FC<UserDetailsProps> = memo(
  ({ user, testID }) => {
    const color = useUserColor(user);
    const role = useUserRole(user);
    const name = useUserName(user);
    const { avatarUrl } = user;

    return (
      <div className="flex flex-row gap-3 items-center justify-center">
        <Avatar avatarUrl={avatarUrl} size={40} bordered={false} />
        <div>
          <p
            data-cy={testID}
            className="font-sora text-sm font-bold"
            style={{ color }}
          >
            {name}
            {user.__typename === 'Advisor' &&
              user.advisorRole === AdvisorRole.PrimaryAdvisor && (
                <Icon
                  name="Star"
                  className="inline-block align-baseline ml-1 translate-y-1/4"
                  size={16}
                />
              )}
          </p>
          <p className="text-sm text-dark-grey-025">{role}</p>
        </div>
      </div>
    );
  },
);

export const RichMessage: React.FC<RichMessageProps> = memo((props) => {
  const { users, children, chatRoomId, hasScrollInfo, userNameTestID } = props;

  const userInfo = useMemo(() => {
    if (users.length > 1) {
      return (
        <AvatarGroup
          className="w-fit mx-auto my-4"
          frontAvatar={users[0].avatarUrl}
          backAvatar={users[1].avatarUrl}
        />
      );
    }

    const user = users[0];

    return (
      <Link
        to={`/chat/${chatRoomId}/${
          user.__typename === 'User' ? 'user' : 'advisor'
        }/${user.id}`}
        className="hover:opacity-75"
      >
        <UserDetails user={user} testID={userNameTestID} />
      </Link>
    );
  }, [users, chatRoomId, userNameTestID]);

  return (
    <Container>
      <CenteredMessage>{children}</CenteredMessage>
      <Layout.Spacer.Vertical size="tiny" />
      {userInfo}
      {hasScrollInfo && <ScrollMessage />}
    </Container>
  );
});

export const SimpleMessage: React.FC<SimpleMessageProps> = memo(
  ({ children, onPress }) => {
    if (!onPress) {
      return (
        <Container>
          <CenteredMessage className="mb-0">{children}</CenteredMessage>
        </Container>
      );
    }

    return (
      <button
        type="button"
        onClick={onPress}
        className="ltr:text-left rtl:text-right"
      >
        <Container>
          <div className="flex flex-row w-full items-center justify-between">
            {children}
            <div className="w-6">
              <Icon size={24} name="ChevronRight" className="rtl:rotate-180" />
            </div>
          </div>
        </Container>
      </button>
    );
  },
);

export const ExpandButton: React.FC<ExpandButtonProps> = memo((props) => {
  const { children, onPress, isExpanded } = props;

  return (
    <button
      type="button"
      onClick={onPress}
      className="flex flex-row items-center w-full"
    >
      <div className="flex-1 ltr:text-left rtl:text-right">{children}</div>
      <div className="w-6 h-6 rounded bg-light-grey">
        <Icon size={24} name={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
      </div>
    </button>
  );
});

export const GroupMessageItemList: React.FC<GenericProps> = memo(
  ({ children }) => (
    <div className="ltr:border-l rtl:border-r border-grey mt-4">{children}</div>
  ),
);

export const ButtonIconWrapper: React.FC<GenericProps> = memo(
  ({ children }) => (
    <div className="w-10 h-10 flex shrink-0 justify-center items-center border border-grey rounded-lg">
      {children}
    </div>
  ),
);
