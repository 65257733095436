export enum PermissionStatus {
  GRANTED = 'granted',
  UNDETERMINED = 'undetermined',
  DENIED = 'denied',
}

export interface UsePermissionsReturnType {
  hasAllPermissions: boolean;
  cameraPermissions: PermissionStatus;
  microphonePermissions: PermissionStatus;
  requestPermissions: () => Promise<boolean>;
}
