import dayjs from 'dayjs';
import { useMemo } from 'react';

import { ChatRoomWithMembersFragment } from '../generated/graphql';
import { useMyId } from '../me';

type Params = Partial<ChatRoomWithMembersFragment>;

export default function useHasUnreadMessages(params: Params = {}): boolean {
  const myId = useMyId();

  const { members, latestMessage } = params;

  const lastSeenChatRoomOn = members?.find(
    (memberInfo) => memberInfo.member.id === myId,
  )?.lastSeenChatRoomOn;

  return useMemo(() => {
    if (!latestMessage) {
      return false;
    }

    return dayjs(lastSeenChatRoomOn).isBefore(dayjs(latestMessage.sentAt));
  }, [lastSeenChatRoomOn, latestMessage]);
}
