/* eslint-disable import/prefer-default-export */

import { molecule, use } from 'bunshi';

import Logger from '@advisor/utils/Logger';
import { actionAtom } from '@advisor/utils/atoms';
import {
  getContentTranslationToggleAction,
  AutotranslationState,
  ToggleTranslationAction,
  TranslationAction,
} from '@advisor/chat/contentTranslation';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import { requireMilestoneCategoryScope } from '../milestoneCategory';
import MilestoneCategoryTranslationMolecule from './milestoneCategoryTranslationMolecule';
import {
  disableJourneyTranslationAtom,
  enableJourneyTranslationAtom,
} from './journeyTranslationActionAtoms';
import { journeyTranslationStateAtoms } from './journeyTranslationStateAtoms';
import { createTranslationPromptAtoms } from './utils';

const promptAtom = createTranslationPromptAtoms(
  'task-organiser:no-just-this-category',
);

export const ToggleCategoryTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const categoryId = requireMilestoneCategoryScope();

  const { translationAtom, statusAtom } = use(
    MilestoneCategoryTranslationMolecule,
  );

  const toggleCategoryTranslationAtom = actionAtom(async ({ get, set }) => {
    const action = getContentTranslationToggleAction(
      (await get(statusAtom))?.type ?? 'raw',
      (await get(journeyTranslationStateAtoms(chatRoomId))) !==
        AutotranslationState.Disabled,
    );

    switch (action) {
      case ToggleTranslationAction.PromptTranslationPreference:
        {
          const decision = await set(promptAtom, 'translate');

          if (decision === 'all') {
            set(enableJourneyTranslationAtom, chatRoomId);
          } else if (decision === 'single') {
            set(translationAtom, TranslationAction.Translate);
          }
        }
        break;
      case ToggleTranslationAction.PromptUndoTranslationPreference:
        {
          const decision = await set(promptAtom, 'undo');

          if (decision === 'all') {
            set(disableJourneyTranslationAtom, chatRoomId);
          } else if (decision === 'single') {
            set(translationAtom, TranslationAction.UndoTranslation);
          }
        }
        break;
      case ToggleTranslationAction.Translate:
        await set(translationAtom, TranslationAction.Translate);
        break;
      case ToggleTranslationAction.UndoTranslation:
        await set(translationAtom, TranslationAction.UndoTranslation);
        break;
      default:
        Logger.warn(
          `Unknown translation state for milestone category: ${categoryId}`,
        );
    }
  });

  return {
    toggleCategoryTranslationAtom,
  };
});

export default ToggleCategoryTranslationMolecule;
