import { atom } from 'jotai';
import { molecule, use } from 'bunshi';

import { UserMessageInfoFragment } from '@advisor/api/generated/graphqlTypes';
import { ChatRoomScope } from '@advisor/api/chatRoom';

const SelectedMessageMolecule = molecule(() => {
  // Unique per chat-room, but not requiring it.
  use(ChatRoomScope);

  const selectedMessageAtom = atom<UserMessageInfoFragment | null>(null);

  return { selectedMessageAtom };
});

export default SelectedMessageMolecule;
