import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';

import { DEFAULT_TRANSITION_CONFIG } from '@advisor/design/components/Dropdown/context';
import { useInvitation } from '@advisor/onboarding';
import { UserRole, UserRoleSelectProps } from './types';
import UserRoleSelectButton from './UserRoleSelectButton';
import UserRoleSelectDropdown from './web/UserRoleSelectDropdown';
import UserRoleSelectDropdownItem from './web/UserRoleSelectDropdownItem';

const UserRoleSelect: React.FC<UserRoleSelectProps> = ({
  onSelect,
  options,
  upwards = false,
  value,
  disabled,
}) => {
  const { t } = useTranslation('onboarding');
  const [isVisible, setIsVisible] = useState(false);

  const { inviteType } = useInvitation();

  const handleOpenDropdown = () => {
    setIsVisible(!isVisible);
  };

  const handleCloseDropdown = () => {
    setIsVisible(false);
  };

  const handleItemClick = (role: UserRole) => {
    onSelect(role);
    setIsVisible(false);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement, HTMLElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleCloseDropdown();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isVisible && event.code === 'Escape') {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <div className="w-full relative" onBlur={handleBlur}>
      <UserRoleSelectButton
        disabled={disabled}
        isVisible={isVisible}
        onOpen={handleOpenDropdown}
        upwards={upwards}
        roleTitle={
          options.find((option) => option.value === value)?.roleTitle ?? ''
        }
      />
      <UserRoleSelectDropdown upwards={upwards}>
        {options.map((option) => (
          <Transition
            key={option.value}
            show={isVisible}
            {...DEFAULT_TRANSITION_CONFIG}
          >
            <UserRoleSelectDropdownItem
              onClick={() => handleItemClick(option.value)}
              active={option.value === value}
              roleTitle={option.roleTitle}
              roleDescription={option.roleDescription}
            />
          </Transition>
        ))}
        {inviteType !== 'agency' && (
          <Transition show={isVisible} {...DEFAULT_TRANSITION_CONFIG}>
            <li className="w-full pl-6 pr-5 py-3 text-dark-grey-02 text-xs">
              {t('to-join-as-an-host-please-contact')}
            </li>
          </Transition>
        )}
      </UserRoleSelectDropdown>
    </div>
  );
};

export default UserRoleSelect;
