import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback } from 'react';

import {
  formatBytes,
  isFileInstance,
  isFileDataInstance,
} from '@advisor/api/files';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { Icon, SpinnerIcon } from '@advisor/design/components';
import { FileInputProps } from './types';
import FilePicker from './FilePicker';

const FileInput: React.FC<FileInputProps> = (props) => {
  const {
    file,
    label,
    accept,
    isUploading,
    placeholder,
    onFilePicked,
    onDeleteFile,
  } = props;
  const { t } = useTranslation('common');

  const renderFileInputContent = useCallback(() => {
    if (!file) {
      return (
        <>
          <div className="font-outfit text-xs leading-4 text-dark-grey-03 font-normal">
            {label ?? t('upload-file')}
          </div>
          <div className="font-outfit text-sm leading-5 text-dark-grey-01 font-medium">
            {placeholder ?? t('file')}
          </div>
        </>
      );
    }

    if (isFileInstance(file)) {
      return (
        <>
          <div className="font-outfit text-xs text-primary leading-4 font-normal">
            {file.displayName}
          </div>
          <div className="font-outfit text-sm leading-5 text-dark-grey-01 font-medium">
            {formatBytes(file.fileSize)}
          </div>
        </>
      );
    }

    if (isFileDataInstance(file)) {
      return (
        <>
          <div className="font-outfit text-xs text-primary leading-4 font-normal">
            {file.name}
          </div>
          <div className="font-outfit text-sm leading-5 text-dark-grey-01 font-medium">
            {formatBytes(file.size)}
          </div>
        </>
      );
    }

    warnExhaustive(file, '@advisor/ui/FileUpload/FileInput.tsx');
    return null;
  }, [file, t, label, placeholder]);

  const renderFileInput = useCallback(
    (onPress: () => void) => {
      return (
        <div
          className={cs(
            'relative bg-white rounded border-dark-grey-01 border flex flex-row',
            isUploading && 'opacity-50',
          )}
        >
          {isUploading && (
            <div className="absolute top-0 ltr:right-0 rtl:left-0">
              <SpinnerIcon size={12} className="h-4 text-primary" />
            </div>
          )}
          <button
            type="button"
            onClick={onPress}
            className="p-4 flex-1 ltr:text-left rtl:text-right"
            disabled={isUploading}
          >
            {renderFileInputContent()}
          </button>
          <button
            type="button"
            onClick={file ? onDeleteFile : onPress}
            className="border border-dark-grey-01 rounded-md bg-super-light-grey self-center mx-4 w-10 h-10 flex items-center justify-center"
            disabled={isUploading}
          >
            <Icon
              name={file ? 'Delete' : 'AddAttachment'}
              size={file ? 32 : 24}
              className={file ? 'text-negative' : 'text-dark-grey-03'}
            />
          </button>
        </div>
      );
    },
    [file, renderFileInputContent, isUploading, onDeleteFile],
  );

  return (
    <FilePicker onFilePicked={onFilePicked} accept={accept}>
      {renderFileInput}
    </FilePicker>
  );
};

export default memo(FileInput);
