import type { ColorPalette } from '../useGreyLabeling';

export interface BrandCustomizationModalProps {}

export enum CustomizationTabs {
  Description = 'Description',
  Colors = 'Colors',
  Logo = 'Logo',
}

export type BrandColor = keyof ColorPalette;

export interface BrandColorItemProps {
  value: string;
  name: BrandColor;
  isActive: boolean;
  onChange: (name: BrandColor, value: string) => void;
  onSetActive: (name: BrandColor) => void;
  description: string;
}

export interface IterableColor {
  value: string;
  name: BrandColor;
}
