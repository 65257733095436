import { ApolloClient } from '@apollo/client';

import {
  UserConfigQuery,
  UserConfigDocument,
  ChatRoomStatusInput,
  UpdateUserConfigDocument,
  ChatRoomStatusInfoFragment,
  UpdateUserConfigMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import { EmptyStatus } from '../ChatRoomStatus';

export const toStatusInput = (
  statuses: ChatRoomStatusInfoFragment[] | undefined,
): ChatRoomStatusInput[] => {
  if (!statuses) {
    return [];
  }

  return statuses.map((status) => ({
    id: status.id,
    name: status.name ?? '',
    i18nKey: status.i18nKey ?? '',
    color: status.color ?? '#FFFFFF',
  }));
};

export const updateStatuses = async (
  client: ApolloClient<unknown>,
  chatRoomStatuses: ChatRoomStatusInput[],
) => {
  try {
    await client.mutate({
      ...mutationOptions,
      mutation: UpdateUserConfigDocument,
      variables: {
        chatRoomStatuses: [...chatRoomStatuses, EmptyStatus],
      },
    });

    return true;
  } catch (error) {
    Sentry.captureException(error);

    showToast({
      iconName: 'X',
      variant: 'rose',
      messageI18Key: 'oops-something-went-wrong',
    });

    return false;
  }
};

export const restoreStatuses = async (client: ApolloClient<unknown>) => {
  try {
    await client.mutate({
      ...mutationOptions,
      mutation: UpdateUserConfigDocument,
      variables: {
        chatRoomStatuses: [],
      },
    });

    return true;
  } catch (error) {
    Sentry.captureException(error);

    showToast({
      iconName: 'X',
      variant: 'rose',
      messageI18Key: 'oops-something-went-wrong',
    });

    return false;
  }
};

const mutationOptions: UpdateUserConfigMutationOptions = {
  update(cache, result) {
    const { chatRoomStatuses } = result.data?.updateUserConfig ?? {};

    if (!chatRoomStatuses) {
      return;
    }

    cache.updateQuery<UserConfigQuery>(
      {
        query: UserConfigDocument,
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          userConfig: {
            ...prev.userConfig,
            chatRoomStatuses,
          },
        };
      },
    );
  },
};
