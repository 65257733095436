/**
 * Error thrown when the refresh tokens request is successfully sent to the server
 * but server denies refreshing tokens.
 * In this case user must login again.
 */
export default class RefreshTokensDeniedError extends Error {
  constructor(cause: unknown) {
    super(`Failed to refresh tokens, must login again. Cause: {${cause}}`);
  }
}
