import SegmentedGroupRoot from './SegmentedGroupRoot';
import SegmentedGroupButton from './SegmentedGroupButton';
import SegmentedGroupCheckbox from './SegmentedGroupCheckbox';
import SegmentedGroupSeparator from './SegmentedGroupSeparator';
import SegmentedGroupRadioButton from './SegmentedGroupRadioButton';
import SegmentedGroupItem from './SegmentedGroupItem';
import SegmentedGroupToggleButton from './SegmentedGroupToggleButton';

const SegmentedGroup = {
  Root: SegmentedGroupRoot,
  Item: SegmentedGroupItem,
  Button: SegmentedGroupButton,
  Checkbox: SegmentedGroupCheckbox,
  Separator: SegmentedGroupSeparator,
  RadioButton: SegmentedGroupRadioButton,
  ToggleButton: SegmentedGroupToggleButton,
};

export default SegmentedGroup;
