import React, { memo } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { useLogout } from '@advisor/api/auth';
import { useInvitation } from '@advisor/onboarding';
import { Layout, DeprecatedButton } from '@advisor/design/components';
import { FormContainer } from '@advisor/design/components/LoginComponents';
import { ProfileCreationStep, CreateProfileFormProps } from './types';
import useProfileCreation from './useProfileCreation';
import VerificationStep from './VerificationStep';
import DetailsStep from './DetailsStep';
import BasicStep from './BasicStep';

const transitionProps = {
  enter: 'transition-opacity ease-in duration-100 delay-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity ease-in duration-100 absolute',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

const CreateProfileForm = (props: CreateProfileFormProps) => {
  const { t } = useTranslation('onboarding');
  const { onRedirectToChat } = props;
  const logout = useLogout();

  const { inviteDetails } = useInvitation();
  const profileCreation = useProfileCreation();

  return (
    <FormContainer>
      <div className="relative">
        <Transition
          {...transitionProps}
          show={profileCreation.step === ProfileCreationStep.Initial}
        >
          <BasicStep {...props} />
        </Transition>
        <Transition
          {...transitionProps}
          show={profileCreation.step === ProfileCreationStep.Details}
        >
          <DetailsStep onRedirectToChat={onRedirectToChat} />
        </Transition>
        <Transition
          {...transitionProps}
          show={profileCreation.step === ProfileCreationStep.Verification}
        >
          <VerificationStep />
        </Transition>
      </div>
      <Layout.Spacer.Vertical size="medium" />
      <Layout.Spacer.Vertical />
      {!inviteDetails?.email && !inviteDetails?.phone && (
        <div className="flex justify-center">
          <DeprecatedButton.Text onPress={logout}>
            {t('sign-in-with-a-different-account')}
          </DeprecatedButton.Text>
        </div>
      )}
    </FormContainer>
  );
};

export default memo(CreateProfileForm);
