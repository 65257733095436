import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { deviceOrientationAtom } from '@advisor/utils/deviceOrientation';
import { useLocalSession } from '../Participants';
import { calculateAspectRatio } from './utils';

function useLocalCameraProperties() {
  const localSession = useLocalSession();
  const orientation = useAtomValue(deviceOrientationAtom);

  return useMemo(() => {
    const track = localSession?.tracks.video.track;

    if (!track) {
      return { aspectRatio: undefined };
    }

    const ratio = calculateAspectRatio(track);

    if (!ratio) {
      return { aspectRatio: undefined };
    }

    return {
      aspectRatio: orientation === 'portrait' ? 1 / ratio : ratio,
    };
  }, [localSession, orientation]);
}

export default useLocalCameraProperties;
