import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatBytes } from '@advisor/api/files';
import { type Attachment, getAttachmentFile } from './types';

export const getFileName = (url: string) => {
  return url.split('/').pop()?.split('#')[0].split('?')[0];
};

export function useAttachmentDisplayName(attachment: Attachment): string {
  const { t } = useTranslation('common');

  return useMemo(() => {
    const file = getAttachmentFile(attachment);

    if (file) {
      return 'name' in file ? file.name : file.displayName;
    }

    return t('attachments');
  }, [t, attachment]);
}

export function useAttachmentSize(attachment: Attachment): number | undefined {
  return useMemo(() => {
    const file = getAttachmentFile(attachment);

    if (file) {
      return 'size' in file ? file.size : file.fileSize;
    }

    return undefined;
  }, [attachment]);
}

export function useFormattedAttachmentSize(
  file: Attachment,
): string | undefined {
  const fileSize = useAttachmentSize(file);

  return useMemo(
    () => (fileSize ? formatBytes(fileSize, 1) : undefined),
    [fileSize],
  );
}
