import { ApolloCache } from '@apollo/client';

import { MeDocument, MeQuery, MeQueryVariables } from '../generated/graphql';

function getMyCachedIdentity(cache: ApolloCache<unknown>) {
  const meQueryResult = cache.readQuery<MeQuery, MeQueryVariables>({
    query: MeDocument,
  });

  if (!meQueryResult) {
    return undefined;
  }

  return meQueryResult.me ? cache.identify(meQueryResult.me) : undefined;
}

export default getMyCachedIdentity;
