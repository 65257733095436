import React from 'react';
import cs from 'classnames';
import { SegmentedGroupRootProps } from './types';

const SegmentedGroupRoot = ({
  children,
  className,
}: SegmentedGroupRootProps) => {
  return (
    <ul
      className={cs(
        'bg-light-grey rounded-10 border border-grey shadow-drop-006 overflow-hidden',
        className,
      )}
    >
      {children}
    </ul>
  );
};

export default SegmentedGroupRoot;
