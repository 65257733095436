import { createScope, use } from 'bunshi';

const ChatRoomScope = createScope<string | null | undefined>(null);

export function requireChatRoomScope() {
  const chatRoomId = use(ChatRoomScope);

  if (!chatRoomId) {
    throw new Error(
      `Tried to use a molecule scoped to a chat-room without providing its scope`,
    );
  }

  return chatRoomId;
}

export default ChatRoomScope;
