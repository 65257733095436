import React, {
  memo,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import Icon from '../Icon';
import Layout from '../Layout';
import DeprecatedButton from '../DeprecatedButton';
import { isEmailValid } from './utils';
import { EmailAddressProps } from './types';
import { Label, Container, ErrorText, TextInput } from './components';

const EmailAddress = (props: EmailAddressProps) => {
  const {
    label,
    readonly,
    isVerified,
    isLighterColor,
    errorMessage,
    value: externalValue,

    onChange,
    onVerify,
    onSubmitEdit,
    ...textInputProps
  } = props;

  const { t } = useTranslation(['common', 'onboarding']);
  const [isValid, setIsValid] = useState(true);
  const [internalValue, setInternalValue] = useState(externalValue);

  const hasLabel = !!label;
  const value = onChange ? externalValue : internalValue;

  const onInputBlur = useCallback(() => {
    if (isEmailValid(value)) {
      setIsValid(true);
      onSubmitEdit?.(value);
    } else {
      setIsValid(false);
    }
  }, [value, onSubmitEdit]);

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      } else {
        setInternalValue(e.target.value);
      }

      if (!isValid) {
        setIsValid(true);
      }
    },
    [onChange, isValid],
  );

  const onVerifyWrapper = useCallback(async () => {
    if (onVerify && isEmailValid(value)) {
      await onVerify(value);
    }
  }, [onVerify, value]);

  useEffect(() => {
    if (!onChange) {
      setInternalValue(externalValue);
    }
  }, [onChange, externalValue]);

  if (readonly) {
    return (
      <div className="ltr:text-left rtl:text-right w-full">
        <Container className="flex items-center flex-1">
          {hasLabel && <Label isLighterColor={isLighterColor}>{label}</Label>}
          <div className={cs('p-4 flex min-w-0', hasLabel && 'pt-9')}>
            <p
              className={cs(
                'shrink truncate',
                isLighterColor ? 'text-dark-grey-01' : 'text-dark-grey-02',
              )}
            >
              {value}
            </p>
            <Layout.Spacer.Horizontal size="atomic" />
            {isVerified && (
              <Icon name="Verified" className="text-primary shrink-0" />
            )}
          </div>
        </Container>
      </div>
    );
  }

  return (
    <>
      <Container hasErrors={!isValid || !!errorMessage}>
        {hasLabel && <Label>{label}</Label>}
        <TextInput
          {...textInputProps}
          value={value}
          onBlur={onSubmitEdit ? onInputBlur : undefined}
          onChange={onChangeValue}
          className={cs(
            isVerified && 'pr-12',
            !isVerified && onVerify && 'pr-24',
            hasLabel && 'pt-9',
          )}
        />
        <div className="absolute top-0 bottom-0 right-4 flex items-center">
          {isVerified && <Icon name="Verified" className="text-primary" />}
          {!isVerified && !!onVerify && value.length > 0 && (
            <DeprecatedButton.Outline
              onPress={onVerifyWrapper}
              label={t('onboarding:verify')}
              variant="negative"
              disabled={!isValid}
            />
          )}
        </div>
      </Container>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      {!isValid && (
        <ErrorText>
          {t('common:{{fieldName}}-is-invalid', { fieldName: label })}
        </ErrorText>
      )}
    </>
  );
};

export default memo(EmailAddress);
