import { z } from 'zod';
import {
  JellyfishClient,
  Endpoint as GenericEndpoint,
  MessageEvents,
  TrackContext as GenericTrackContext,
} from '@jellyfish-dev/ts-client-sdk';

import { SessionMetadata } from '../../types';

export type TrackType = z.infer<typeof TrackType>;
export const TrackType = z.enum([
  'screenVideo',
  'screenAudio',
  'video',
  'audio',
]);

export type TrackMetadata = z.infer<typeof TrackMetadata>;
export const TrackMetadata = z.object({
  type: TrackType,
});

const jellyfishClient = new JellyfishClient({
  peerMetadataParser: (raw) => SessionMetadata.parse(raw),
  trackMetadataParser: (raw) => TrackMetadata.parse(raw),
});

export type TrackContext = GenericTrackContext<SessionMetadata, TrackMetadata>;
export type JellyfishPeer = GenericEndpoint<SessionMetadata, TrackMetadata>;

export function onMembraneEvent<
  E extends keyof MessageEvents<SessionMetadata, TrackMetadata>,
>(
  event: E,
  listener: Required<MessageEvents<SessionMetadata, TrackMetadata>>[E],
) {
  jellyfishClient.on(event, listener);

  return () => jellyfishClient.off(event, listener);
}

export default jellyfishClient;
