import { useTranslation } from 'react-i18next';

import { useForm } from '@advisor/design/components';
import { useEvent } from '@advisor/utils/hooks';
import { CategoryPayload, MilestoneCategoryFromProps } from './types';

const useMilestoneCategoryFrom = (props: MilestoneCategoryFromProps) => {
  const { t } = useTranslation('task-organiser');
  const { defaultValues, onSubmit } = props;

  const formData = useForm(CategoryPayload, defaultValues, {
    name: t('category-name'),
  });

  const submit = useEvent(() => {
    const validData = formData.validateAll();

    if (!validData) {
      return;
    }

    onSubmit(validData);
  });

  return {
    ...formData,
    submit,
  };
};

export default useMilestoneCategoryFrom;
