import { atomFamily } from 'jotai/utils';

import { UserInfoFragment } from '@advisor/api/generated/graphql';
import { Scope } from '@advisor/api/scope';
import canKickFromChatRoomScope from './canKickFromChatRoomScope';

const showUserActionsScopeAtom = atomFamily(
  ({ user, chatRoomId }: { user: UserInfoFragment; chatRoomId: string }) =>
    Scope.some([
      canKickFromChatRoomScope({ user, chatRoomId }),
      Scope.canViewUserProfileScope(user),
      Scope.hasStudentProfileInUserMenuScope(user),
    ]),
  (a, b) => a.chatRoomId === b.chatRoomId && a.user.id === b.user.id,
);

export default showUserActionsScopeAtom;
