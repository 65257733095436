/* eslint-disable import/prefer-default-export */

import { atom } from 'jotai';

import { lazyAtom } from '@advisor/utils/atoms';
import { VideoChatRecordingStatus } from '@advisor/api/generated/graphql';
import { activeVideoChatAtom } from '../atoms';
import {
  RecordingConsentState,
  RecordingRequestState,
  RoomRecordingState,
  RoomRecordingStates,
} from './types';

export const recordingRequestStateAtom = atom<RecordingRequestState>(
  RecordingRequestState.IDLE,
);

export const recordingConsentStateAtom = atom<RecordingConsentState>(
  RecordingConsentState.NOT_GIVEN_YET,
);

export const recordingStateAtom = lazyAtom(
  async (get): Promise<RoomRecordingState> => {
    const videoChat = await get(activeVideoChatAtom);

    if (!videoChat) {
      return RoomRecordingStates.NOT_IN_CALL;
    }

    const requestStatus = get(recordingRequestStateAtom);
    const hasRecordings = (videoChat?.recordings.length ?? 0) > 0;
    const ongoingRecording = videoChat.recordings.find(
      (r) => !r.recordingDuration,
    );

    if (hasRecordings && !ongoingRecording) {
      return RoomRecordingStates.ENDED;
    }

    if (ongoingRecording) {
      if (requestStatus === RecordingRequestState.PENDING) {
        return RoomRecordingStates.STOPPING;
      }

      if (videoChat.recordingStatus === VideoChatRecordingStatus.Stop) {
        return RoomRecordingStates.PROCESSING;
      }

      return RoomRecordingStates.RECORDING(ongoingRecording.startRecordingTime);
    }

    if (requestStatus === RecordingRequestState.PENDING) {
      return RoomRecordingStates.STARTING;
    }

    return RoomRecordingStates.NOT_RECORDING;
  },
);
