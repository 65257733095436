import cs from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionGate } from '@advisor/api/scope';
import { Icon, Dropdown, SimpleDropdownItem } from '@advisor/design/components';
import useUserConfig from '@advisor/api/userConfig/useUserConfig';
import {
  canArchiveChatRoomScope,
  canReassignStudentScope,
} from '../../../UserPreferences';
import { NoStatusLiteral } from '../../../ChatRoomStatus';
import { canMarkChatRoomStatusScope } from '../atoms/markStatus';
import StatusDropdownItem from './StatusDropDownItem';

interface ChatRoomMenuDropdownProps {
  chatRoomId: string;
  currentStatusId?: string;
  onArchive: () => void;
  onReassignStudent: () => void;
  onMarkStatus: (statusId: string) => void;
}

const ChatRoomMenuDropdown: React.FC<ChatRoomMenuDropdownProps> = (props) => {
  const {
    chatRoomId,
    currentStatusId,

    onArchive,
    onMarkStatus,
    onReassignStudent,
  } = props;

  const { t } = useTranslation('common');

  const { statuses } = useUserConfig();

  const handleArchiveClicked = () => {
    onArchive();
  };

  const handleMarkStatusClicked = (statusId: string) => () => {
    onMarkStatus(statusId);
  };

  return (
    <Dropdown.Root
      trigger={({ open }) => (
        <button
          data-cy="studentTileMoreInfoButton"
          type="button"
          className={cs(
            'h-6 w-6 text-primary rounded transition-colors xl:hidden xl:group-hover:block',
            open ? 'bg-grey' : 'bg-white hover:bg-off-white',
          )}
        >
          {open ? (
            <Icon
              name="ChevronDown"
              className="text-dark-grey-02 scale-125 rotate-180"
            />
          ) : (
            <Icon name="Dots" className="mx-auto" />
          )}
        </button>
      )}
    >
      <Dropdown.Items>
        <PermissionGate scope={canMarkChatRoomStatusScope(chatRoomId)}>
          {() => (
            <SimpleDropdownItem
              testID="markStatusButton"
              label={currentStatusId ? t('mark-status') : t('change-status')}
              submenu={() => (
                <Dropdown.Items>
                  {statuses.map((status) => (
                    <StatusDropdownItem
                      key={status.id}
                      status={status}
                      active={status.id === currentStatusId}
                      onPress={handleMarkStatusClicked(status.id)}
                    />
                  ))}
                  <SimpleDropdownItem
                    onPress={handleMarkStatusClicked(NoStatusLiteral)}
                    label={t('remove-status')}
                    testID="removeStatusButton"
                  />
                </Dropdown.Items>
              )}
            />
          )}
        </PermissionGate>
        <PermissionGate scope={canArchiveChatRoomScope(chatRoomId)}>
          {() => (
            <SimpleDropdownItem
              label={t('disconnect')}
              onPress={handleArchiveClicked}
              testID="disconnectStudentButton"
            />
          )}
        </PermissionGate>
        <PermissionGate scope={canReassignStudentScope(chatRoomId)}>
          {() => (
            <SimpleDropdownItem
              label={t('assign-to-new-host')}
              onPress={onReassignStudent}
              testID="assignToNewAdvisorButton"
            />
          )}
        </PermissionGate>
      </Dropdown.Items>
    </Dropdown.Root>
  );
};

export default memo(ChatRoomMenuDropdown);
