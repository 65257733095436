import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';

import communityDirectoryAccessAtom from '@advisor/ui/atoms/communityDirectoryAccessAtom';
import { useAuthNavigationGuard } from '@advisor/utils/navigation/web';
import { Icon, Layout } from '@advisor/design/components';
import { useLanguage } from '@advisor/language';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import { SidebarTab } from '@advisor/ui/atoms/sidebarTabAtom';
import ChatSidebar from 'src/components/organisms/ChatSidebar';
import MicrobotGrid from './MicrobotGrid';

const CommunityPage: React.FC = () => {
  const { t } = useTranslation(['common', 'microbot']);
  const { isRTL } = useLanguage();

  const setShowSidebar = useSetAtom(showSidebarAtom);

  useAuthNavigationGuard(communityDirectoryAccessAtom, '/chat');

  return (
    <div className="flex flex-row h-dynamic-screen max-h-dynamic-screen min-h-0 items-stretch">
      <ChatSidebar initialTab={SidebarTab.DirectoryList} />
      <div className="relative h-full grow flex flex-col bg-light-grey">
        {/* Header */}
        <div className="bg-white flex flex-row items-center px-6 border-b border-grey h-header-height shrink-0">
          <button
            type="button"
            className="xl:hidden hover:bg-grey w-6 h-6 rounded-full ltr:mr-6 rtl:ml-6"
            onClick={() => setShowSidebar(true)}
            data-cy="show-sidebar-button"
          >
            <Icon
              name={isRTL ? 'ArrowRight' : 'ArrowLeft'}
              className="mx-auto"
            />
          </button>

          <div className="grow self-stretch flex flex-col justify-center">
            <h3 className="font-sora text-sm font-semibold truncate text-dark-grey-03">
              {t('common:community')}
            </h3>
            <p className="text-sm font-medium leading-4 text-dark-grey-025">
              {t('microbot:connect-with-host-ais')}
            </p>
          </div>
        </div>

        {/* Title */}
        <div className="px-6 pt-8">
          <h3 className="font-sora font-bold text-dark-grey-03 leading-5">
            {t('microbot:featured-ai-support')}
          </h3>
          <Layout.Spacer.Vertical size="atomic" />
          <p className="font-outfit text-sm leading-[1.125rem] font-medium text-dark-grey-02">
            {t('microbot:enhanced-productivity-seamless-integration')}
          </p>
        </div>

        <Layout.Spacer.Vertical size="small01" />

        {/* Microbot grid view */}
        <MicrobotGrid />
      </div>
    </div>
  );
};

export default CommunityPage;
