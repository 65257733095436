import React from 'react';

import { Skeleton, SegmentedGroup } from '@advisor/design/components';

const ProfileSkeleton: React.FC = () => {
  return (
    <Skeleton.Ping>
      <SegmentedGroup.Root>
        <div className="flex flex-row items-center p-4 gap-3 border-b border-grey">
          <div className="w-10 h-10 rounded-full bg-grey" />
          <div className="flex-1 min-w-0 flex flex-col">
            <Skeleton.Text lineHeight={20} fontSize={14} size={100} />
            <Skeleton.Text lineHeight={20} fontSize={14} size={120} />
          </div>
        </div>
        <div className="flex flex-row p-4 gap-3">
          <div className="flex-[2_2_0%]">
            <Skeleton.Text lineHeight={16} fontSize={12} size={70} />
            <Skeleton.Text lineHeight={20} fontSize={14} size={120} />
          </div>
          <div className="flex-[3_3_0%]">
            <Skeleton.Text lineHeight={16} fontSize={12} size={70} />
            <Skeleton.Text lineHeight={20} fontSize={14} size={120} />
          </div>
        </div>
      </SegmentedGroup.Root>
    </Skeleton.Ping>
  );
};

export default ProfileSkeleton;
