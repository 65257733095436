import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { DeprecatedButton, Layout } from '@advisor/design/components';
import { editPrimaryContextAtom } from '../../atoms/editPrimaryContextAtom';
import { useMicrobot, useResetMicrobotContext } from '../../hooks';
import EditContextModal from '../EditContextModal';
import { ButtonRowProps } from './types';
import useMicrobotPermissions from './useMicrobotPermissions';

const ButtonRow: React.FC<ButtonRowProps> = ({
  personality = MicrobotPersonality.Astro,
}) => {
  const { t } = useTranslation('microbot');

  const { primaryContext } = useMicrobot(personality, 'required');
  const { editPermissions, resetPermissions } =
    useMicrobotPermissions(personality);

  const [editState, dispatch] = useAtom(editPrimaryContextAtom);

  const resetPrimaryContext = useResetMicrobotContext({ personality });

  return (
    <>
      <div className="flex items-center">
        <DeprecatedButton.Outline
          label={t('edit-context')}
          className="text-sm leading-[1.125rem]"
          variant={editPermissions ? 'primary' : 'darkGrey01'}
          disabled={!editPermissions}
          onPress={() =>
            dispatch({
              type: 'open',
              initialValue: primaryContext,
              personality,
            })
          }
        />
        <Layout.Spacer.Horizontal size="micro02" />
        <DeprecatedButton.Outline
          className="text-sm leading-[1.125rem]"
          variant={resetPermissions ? 'secondary' : 'darkGrey01'}
          label={t('reset')}
          disabled={!resetPermissions}
          onPress={resetPrimaryContext}
        />
      </div>
      <EditContextModal
        personality={personality}
        visible={!!editState}
        onClose={() => dispatch({ type: 'close' })}
      />
    </>
  );
};

export default ButtonRow;
