import {
  SystemMessageType,
  SystemMessageInfoFragment,
  JourneyMilestoneSystemMessageData,
  JourneyMilestoneCommentSystemMessageData,
} from '@advisor/api/generated/graphql';

const CompletionStudentTypes = [
  SystemMessageType.MilestoneMarkedCompleteByStudent,
  SystemMessageType.MilestoneMarkedIncompleteByStudent,
];

export function getCategoryName(info: SystemMessageInfoFragment) {
  const { data } = info;

  switch (data.__typename) {
    case 'JourneyCategorySystemMessageData':
    case 'JourneyMilestoneSystemMessageData':
      return data.milestoneCategory?.name ?? data.metadata.categoryName;
    case 'JourneyMilestoneCommentSystemMessageData':
      return data.metadata.categoryName;
    default:
      return undefined;
  }
}

export function getCategoryId(info: SystemMessageInfoFragment) {
  const { data } = info;

  switch (data.__typename) {
    case 'JourneyCategorySystemMessageData':
    case 'JourneyMilestoneSystemMessageData':
      return data.milestoneCategory?.id;
    case 'JourneyMilestoneCommentSystemMessageData':
      return data.milestone?.milestoneCategoryId;
    case 'JourneyMilestoneOverDueSystemMessageData':
      return data.metadata.categoryId;
    default:
      return undefined;
  }
}

export function getMilestoneName(info: SystemMessageInfoFragment) {
  const { data } = info;

  switch (data.__typename) {
    case 'JourneyMilestoneSystemMessageData':
    case 'JourneyMilestoneCommentSystemMessageData':
      return data.milestone?.name ?? data.metadata.milestoneName;
    case 'JourneyMilestoneOverDueSystemMessageData':
      return data.metadata.milestoneName;
    default:
      return undefined;
  }
}

export function getMilestoneId(info: SystemMessageInfoFragment) {
  const { data } = info;

  switch (data.__typename) {
    case 'JourneyMilestoneSystemMessageData':
    case 'JourneyMilestoneCommentSystemMessageData':
      return data.milestone?.id;
    case 'JourneyMilestoneOverDueSystemMessageData':
      return data.metadata.milestoneId;
    default:
      return undefined;
  }
}

export function getTranslationKey(type: SystemMessageType) {
  switch (type) {
    case SystemMessageType.MilestoneMarkedCompleteByAdvisor:
      return 'count-tasks-of-category-on-your-task-organiser-were-marked-as-complete-on';
    case SystemMessageType.MilestoneMarkedCompleteByStudent:
      return 'count-tasks-of-category-on-task-organiser-were-marked-as-complete-on';
    case SystemMessageType.NewCategoryAdded:
      return 'user-added-count-categories-in-your-task-organiser-on';
    case SystemMessageType.CategoryEdited:
      return 'user-edited-count-categories-in-your-task-organiser-on';
    case SystemMessageType.MilestoneAdded:
      return 'user-added-count-tasks-in-category-in-your-task-organiser-on';
    case SystemMessageType.NewCommentAdded:
      return 'user-made-count-comments-in-task-organiser-on';
    case SystemMessageType.MilestoneEdited:
      return 'user-made-count-new-edits-in-category-on';
    default:
      return 'there-are-count-new-updates-in-task-organiser-by-user';
  }
}

export function getItemTitle(info: SystemMessageInfoFragment) {
  const { data } = info;

  switch (data.__typename) {
    case 'JourneyMilestoneSystemMessageData':
    case 'JourneyMilestoneCommentSystemMessageData':
      return data.milestone?.name ?? data.metadata.milestoneName;
    case 'JourneyCategorySystemMessageData':
      return data.milestoneCategory?.name ?? data.metadata.categoryName;
    case 'JourneyMilestoneOverDueSystemMessageData':
      return data.metadata.milestoneName;
    default:
      return '';
  }
}

export function getUserId(info: SystemMessageInfoFragment) {
  if (info.data.type === SystemMessageType.NewCommentAdded) {
    const data = info.data as JourneyMilestoneCommentSystemMessageData;

    return data.author?.id;
  }

  if (CompletionStudentTypes.includes(info.data.type)) {
    const data = info.data as JourneyMilestoneSystemMessageData;

    return data.studentId;
  }

  const data = info.data as JourneyMilestoneSystemMessageData;

  return data.advisor?.id;
}
