import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { compact, difference } from 'lodash-es';

import {
  MicrobotPersonality,
  UserInfoFragment,
} from '@advisor/api/generated/graphql';
import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';
import useCalendarIntegration from '@advisor/microbots/hooks/useCalendarIntegration';

interface UseTruncateUserListParams {
  users: UserInfoFragment[];
  truncate?: boolean;
}

const useTruncateUserList = (params: UseTruncateUserListParams) => {
  const { truncate, users } = params;

  const me = useMe();
  const chatRoomCtx = useActiveChatRoom();
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);
  const { isAuthorized } = useCalendarIntegration();

  const shouldTruncate = truncate && users.length > 6;

  const members = useMemo(() => {
    if (!shouldTruncate) {
      return users;
    }

    const truncatedMembers: UserInfoFragment[] = [];

    // If the user is an advisor or a service provider
    // or there is no leading advisor user sees themselves
    if (
      Role.isAdvisor(me) ||
      Role.isServiceProvider(me) ||
      !chatRoom?.leadingAdvisorId
    ) {
      const meMember = users.find((user) => user.id === me?.id);

      if (meMember) {
        truncatedMembers.push(meMember);
      }
    } else {
      const leadingMember = users.find(
        (user) => user.id === chatRoom.leadingAdvisorId,
      );

      if (leadingMember) {
        truncatedMembers.push(leadingMember);
      }
    }

    // Add Carl microbot if it's present on the list
    // and the calendar isn't configured
    const carlMicrobot = users.find(
      (member) =>
        Role.isMicrobot(member) &&
        member.personality === MicrobotPersonality.Carl,
    );

    if (carlMicrobot && !isAuthorized) {
      truncatedMembers.push(carlMicrobot);
    }

    return truncatedMembers;
  }, [chatRoom?.leadingAdvisorId, me, shouldTruncate, users, isAuthorized]);

  const hiddenMembers = useMemo(() => {
    return difference(users, members);
  }, [members, users]);

  return {
    members: compact(members),
    shouldTruncate,
    truncatedMembers: compact(hiddenMembers),
  };
};

export default useTruncateUserList;
