import { useEffect, useState } from 'react';

const assetLoaders = import.meta.glob('../../../language/assets/*.json');

type AssetLibType = Record<string, string> | null;

type LoaderType = () => Promise<AssetLibType>;

function useLanguageAsset(name: string): [AssetLibType, boolean] {
  const [data, setData] = useState<AssetLibType>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cancelled = false;
    setIsLoading(true);

    const loader = assetLoaders[`../../../language/assets/${name}.json`] as
      | LoaderType
      | undefined;

    if (!loader) {
      throw new Error('Missing language asset');
    }

    loader()
      .then((translations) => {
        if (!cancelled) {
          setData(translations);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (!cancelled) {
          setData(null);
          setIsLoading(false);
        }
      });

    return () => {
      cancelled = true;
    };
  }, [name]);

  return [data, isLoading];
}

export default useLanguageAsset;
