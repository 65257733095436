import z from 'zod';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';

import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { Lazy } from '@advisor/utils/atoms';
import { MessageInfoFragment } from '@advisor/api/generated/graphql';
import MessageTranslationMolecule from './messageTranslationMolecule';
import { MessageMolecule } from '../messaging/messageMolecule';
import { useChatRoomLanguage } from './chatRoomLanguage';

interface Params {
  chatRoomId: string;
  message?: MessageInfoFragment;
  latestMessageId?: string;
}

export const TranslationTooltipMetadataLocation = new UserMetadataLocation(
  'translation.tooltip',
  z.boolean(),
);

export default function useShowTranslationTooltip(params: Params) {
  const { chatRoomId, message, latestMessageId } = params;

  const [hideTooltip] = useUserMetadata(TranslationTooltipMetadataLocation);
  const chatRoomLanguage = useChatRoomLanguage(chatRoomId);
  const { isOwnAtom } = useMolecule(MessageMolecule);
  const translationAtoms = useMolecule(MessageTranslationMolecule);

  const isOwn = useAtomValue(isOwnAtom);
  const status = useAtomValue(Lazy.valueOr(translationAtoms.statusAtom, null));

  return useMemo(() => {
    return (
      message?.id === latestMessageId &&
      message?.__typename === 'UserMessage' &&
      !isOwn &&
      status?.type === 'raw' &&
      message?.detectedLanguage !== chatRoomLanguage &&
      !hideTooltip
    );
  }, [message, isOwn, hideTooltip, latestMessageId, chatRoomLanguage, status]);
}
