import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DeprecatedButton, Icon, Layout } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { useMicrobot } from '../../../hooks';
import { ConfigureProps } from './types';

const Configure: React.FC<ConfigureProps> = ({
  onClose,
  onConfigure,
  personality,
}) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { name, communityTitle } = useMicrobot(personality, 'required');

  return (
    <div className="p-8 relative">
      <DeprecatedButton.Icon
        className="absolute top-4 ltr:right-4 rtl:left-4"
        icon="X"
        onPress={onClose}
        variant="darkGrey01"
        size={Spacing.small02}
      />

      <div className="flex flex-row justify-center">
        <Icon name="CircleCheck" color="primary" size={Spacing.iconSize} />
      </div>
      <Layout.Spacer.Vertical size="small01" />

      <h3 className="font-sora text-center text-sm leading-4 font-bold text-dark-grey-03">
        {t('microbot:invited-to-conversations')}
      </h3>
      <Layout.Spacer.Vertical size="micro02" />

      <p className="font-outfit text-center text-dark-grey-02 font-medium text-sm leading-[1.125rem]">
        <Trans
          t={t}
          i18nKey="microbot:you-have-successfully-invited-name-title-to-join-selected"
          components={{ bold: <b /> }}
          values={{ name, microbotTitle: t(communityTitle) }}
        />
      </p>
      <Layout.Spacer.Vertical size="small01" />

      <div className="px-2">
        <DeprecatedButton.Large
          className="text-sm leading-[0.875rem] w-full"
          label={t('microbot:configure-name', { name })}
          onPress={onConfigure}
          variant="primary"
        />
      </div>
    </div>
  );
};

export default Configure;
