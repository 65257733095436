import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Spacing } from '@advisor/design/styles';
import { Avatar, Layout } from '@advisor/design/components';
import scopeProviderHOC from '@advisor/utils/scopeProviderHOC';
import ContextMenu from '@advisor/design/components/ContextMenu';
import { FileAttachment } from '@advisor/design/components/FileAttachment';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import useInterpolatedUsername from '@advisor/chat/hooks/useInterpolatedUsername';
import { MilestoneCommentScope } from '../../api/milestoneComments';
import { useCommentTranslation } from '../../api/translation';
import { CommentListItemProps } from './types';
import { formatCommentDate } from './utils';
import CommentText from './CommentText';
import CommentMenu from './CommentMenu';

const CommentListItem: React.FC<CommentListItemProps> = (props) => {
  const { comment } = props;
  const { author, comment: commentText, createdAt, attachments } = comment;
  const { t } = useTranslation('common');
  const { chatRoomId } = useActiveChatRoom('required');

  const translation = useCommentTranslation();

  const wrappedAttachments = useMemo(
    () =>
      (attachments ?? []).map((attachment) => ({
        file: attachment,
      })),
    [attachments],
  );

  const interpolatedName = useInterpolatedUsername(author, chatRoomId);

  return (
    <ContextMenu.ManagedOwner className="w-full min-w-0 relative mt-6 px-6 flex gap-3 items-start">
      <Avatar
        bordered={false}
        className="shrink-0"
        size={Spacing.small01}
        avatarUrl={author.avatarUrl}
      />

      <span className="absolute top-0 ltr:right-0 rtl:left-0">
        <CommentMenu comment={comment} />
      </span>

      <div className="flex-1 min-w-0 flex flex-col gap-4">
        <Layout.Row natural className="w-full gap-3">
          <p className="flex-1 pt-0.5 min-w-0 font-semibold text-sm truncate">
            {interpolatedName}
          </p>
          <p className="font-outfit text-xs font-normal text-dark-grey-025 flex-none">
            {formatCommentDate(createdAt, t)}
          </p>
        </Layout.Row>
        {(translation.content?.comment || commentText.length > 0) && (
          <CommentText text={translation.content?.comment ?? commentText} />
        )}
        {wrappedAttachments.map((attachment) => (
          <FileAttachment key={attachment.file.id} attachment={attachment} />
        ))}
      </div>
    </ContextMenu.ManagedOwner>
  );
};

export default memo(
  scopeProviderHOC(
    CommentListItem,
    MilestoneCommentScope,
    (props) => props.comment.id,
  ),
);
