import React from 'react';
import cs from 'classnames';
import { ResourceKey } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import { IconName } from '@advisor/design/components/Icon';
import { Icon, Layout } from '@advisor/design/components';
import { MicrobotFullInfo, Role } from '@advisor/microbots/hooks/useMicrobot';
import MicrobotActions from './MicrobotActions';
import SectionTabs from './SectionTabs';

interface MicrobotBasicInfoProps {
  onConfigureMicrobot: () => void;
  onEditAccess: () => void;
  microbot: MicrobotFullInfo;
}

const MicrobotBasicInfo: React.FC<MicrobotBasicInfoProps> = (props) => {
  const { microbot, onConfigureMicrobot, onEditAccess } = props;
  const { role, name, color, avatarUrl, specialty, howItWorks, advisorCount } =
    microbot;

  return (
    <div className="xl:bg-white xl:p-8 xl:flex xl:flex-row xl:rounded-lg">
      <div className="absolute top-[65px] w-full flex justify-center xl:static xl:w-auto xl:block">
        {avatarUrl ? (
          <img
            alt="Cosmo"
            src={avatarUrl}
            style={{ width: 124, height: 124, borderRadius: 20 }}
          />
        ) : (
          <div
            className="bg-light-grey"
            style={{
              width: 124,
              height: 124,
              borderRadius: 20,
            }}
          />
        )}
        <RoleBadge role={role} className="absolute bottom-0 xl:hidden" />
      </div>
      <Layout.Spacer.Horizontal size={40} />
      <Layout.Spacer.Vertical size={64} />
      <div className="xl:flex-1">
        <RoleBadge role={role} className="hidden xl:inline-block" />
        <Layout.Spacer.Vertical size="tiny" />
        <MicrobotName
          name={name ?? ''}
          color={color}
          icon={microbot.nameIcon}
        />
        <Layout.Spacer.Vertical size="small01" />
        {!!howItWorks && (
          <>
            <SectionTabs />
            <Layout.Spacer.Vertical size="tiny" />
          </>
        )}
        <InfoBox conversationCount={advisorCount} specialty={specialty} />
      </div>

      <div className="hidden xl:flex items-start">
        <MicrobotActions
          personality={microbot.personality}
          onConfigure={onConfigureMicrobot}
          onEditAccess={onEditAccess}
        />
      </div>
    </div>
  );
};

export default MicrobotBasicInfo;

interface RoleBadgeProps {
  role: Role;
  className: string;
}

const RoleBadge: React.FC<RoleBadgeProps> = ({ role, className }) => {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <span
      className={cs(
        'px-3 py-2 rounded text-sm font-outfit text-center font-medium leading-3',
        className,
      )}
      style={{ backgroundColor: role.backgroundColor, color: role.textColor }}
    >
      {t(role.title)}
    </span>
  );
};

const MicrobotName: React.FC<{
  name: string;
  color: string;
  icon: IconName;
}> = ({ name, color, icon }) => {
  const { t } = useTranslation('common');

  return (
    <div
      className="flex flex-row items-top justify-center xl:justify-start"
      style={{ color }}
    >
      <div className="font-sora text-xl leading-5 font-bold relative">
        {`${name} `}
        <Icon name={icon} size={16} className="absolute -top-1 -right-3" />
      </div>
      <Layout.Spacer.Horizontal size="tiny" />
      <div className="font-sora text-xs font-normal text-dark-grey-03">
        ({t('they-them')})
      </div>
    </div>
  );
};

const InfoBox: React.FC<{
  conversationCount?: number;
  specialty: ResourceKey;
}> = ({ conversationCount, specialty }) => {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <div className="flex flex-row justify-center xl:justify-start bg-light-grey border-y py-4 border-[#D9DBE0] xl:bg-transparent xl:border-0 xl:p-0">
      <div>
        <div className="font-outfit text-sm font-medium leading-4-5 text-dark-grey-02">
          {t('microbot:specialty')}
        </div>
        <div className="font-outfit text-base font-medium leading-5 text-primary">
          {t(specialty)}
        </div>
      </div>
      <Layout.Spacer.Horizontal size="small02" />
      <div className="border-l border-[#D9DBE0]" />
      <Layout.Spacer.Horizontal size="small02" />
      <div>
        <div className="font-outfit text-sm font-normal leading-4-5 text-dark-grey-02">
          {t('microbot:currently-supporting')}
        </div>
        <div className="font-outfit text-base font-normal leading-5 text-primary">
          <Trans
            t={t}
            i18nKey="microbot:count-hosts"
            values={{ count: conversationCount ?? 0 }}
            components={{ bold: <span className="font-medium" /> }}
          />
        </div>
      </div>
    </div>
  );
};
