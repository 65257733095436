import { atom } from 'jotai';

export enum LoginType {
  PhoneNumber = 'PhoneNumber',
  EmailAddress = 'EmailAddress',
}

export enum LoginState {
  Initial = 'Initial',
  Verification = 'Verification',
  ConfirmDelete = 'ConfirmDelete',
}

export enum FlowType {
  Login = 'Login',
  Delete = 'Delete',
}

export interface LoginAtom {
  state: LoginState;
  flowType?: FlowType;
  phoneNumber?: string;
  emailAddress?: string;
  type: LoginType;
}

export const loginAtom = atom<LoginAtom>({
  state: LoginState.Initial,
  flowType: FlowType.Login,
  type: LoginType.PhoneNumber,
});
