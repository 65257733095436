import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { DateFormatter } from '@advisor/language';
import { useCachedUser } from '@advisor/api/user';
import { JourneyMilestoneCommentSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import { BoldText, Message, SimpleMessage } from '../systemMessageComponents';
import { useOpenJourneyMilestone } from '../navigation';
import type { SystemMessageProps } from '../types';
import useUserName from '../useUserName';

const NewCommentAdded: React.FC<SystemMessageProps> = ({ message }) => {
  const { t } = useTranslation(['common', 'user-role', 'system-message']);
  const data = message.data as JourneyMilestoneCommentSystemMessageInfoFragment;
  const openMilestone = useOpenJourneyMilestone();

  const milestone = data.milestone?.name ?? data.metadata.milestoneName;
  const cachedUser = useCachedUser(data.author?.id);
  const username =
    useUserName(cachedUser) ??
    data.author?.name ??
    data.authorName ??
    t('user-role:anonymous');
  const datetime = DateFormatter.dateTime(message.sentAt, t);

  const onPress = useEvent(() =>
    openMilestone(
      message.chatRoomId,
      data.milestone?.milestoneCategoryId,
      data.milestone?.id,
    ),
  );

  return (
    <SimpleMessage onPress={onPress}>
      <Trans
        t={t}
        parent={Message}
        i18nKey="system-message:user-commented-in-task-on"
        values={{ task: milestone, user: username, datetime }}
        components={{ bold: <BoldText /> }}
      />
    </SimpleMessage>
  );
};

export default memo(NewCommentAdded);
