const Colors = {
  primaryBlueLight: '#CCE5FF',
  primaryBlueLight05: '#C1DCFC',
  primaryBlue: '#4285F7',
  primaryBlueDark: '#0F38CD',
  roseSecondaryLight: '#FFD2CA',
  roseSecondary: '#EF8572',
  roseSecondaryDark: '#CD5742',
  softBlueLight: '#9FBDEF',
  softBlue: '#7995C5',
  softBlueDark: '#4C5F81',
  cyanLight: '#A3C9C5',
  cyan: '#578F89',
  cyanDark: '#3B6762',
  yellowLight: '#FFDF89',
  yellow: '#F7C43B',
  yellowDark: '#B6862D',
  purpleLight: '#C4BFFA',
  purple: '#797CE1',
  purpleDark: '#6544CB',
  positiveLight: '#5FB785',
  positive: '#258750',
  positiveDark: '#007038',
  negativeLight: '#F4C3CB',
  negative: '#D83A52',
  negativeDark: '#B63546',
  white: '#FFFFFF',
  superLightGrey: '#FDFDFD',
  lighterGrey: '#F7F7F7',
  lightGrey: '#F5F6F8',
  grey: '#E6E9EF',
  darkGrey005: '#E5E5E5',
  darkGrey01: '#C5C7D0',
  darkGrey015: '#BDBDBD',
  darkGrey02: '#4F5157',
  darkGrey025: '#828282',
  darkGrey03: '#323338',
  darkGrey04: '#25282B',
  darkGrey05: '#1A1A1A',
  astroDark: '#0696B7',
  pamDark: '#C77123',
  carlDark: '#7995C5',
  offWhite: '#FAFAFA',
  transparent: 'transparent',
} as const;

export default Colors;
