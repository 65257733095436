import { molecule } from 'bunshi';
import { Immutable } from 'immer';
import { useMolecule } from 'bunshi/react';
import { atomWithImmer } from 'jotai-immer';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import {
  MicrobotPersonality,
  AiResponseStreamDocument,
  AiResponseStreamInfoFragment,
} from '@advisor/api/generated/graphql';
import { Scope } from '@advisor/api/scope';
import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { useMicrobot } from '@advisor/microbots/hooks';
import takeWhileDefined from '@advisor/utils/takeWhileDefined';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { requireChatRoomScope, useActiveChatRoom } from '@advisor/api/chatRoom';
import { mockUserMessage, upsertMessage } from './cacheUtils';
import { SubscriptionParams } from './types';

type MessageStreamBuffer = {
  messageId: string;
  createdAt: string;
  chunks: (string[] | undefined)[];
  received: number;
  receivedLastChunk: boolean;
};

type Buffers = Record<string, MessageStreamBuffer>;

const StreamingMessageMolecule = molecule(() => {
  // A separate buffer per chat-room
  const chatRoomId = requireChatRoomScope();

  const buffersAtom = atomWithImmer<Immutable<Buffers>>({});

  const appendToBufferAtom = actionAtom(
    ({ get, set }, data: AiResponseStreamInfoFragment) => {
      if (data.chatRoomId !== chatRoomId) {
        throw new Error(
          `Gotten message stream chunk for wrong chatRoomId: '${data.chatRoomId}'. Expected '${chatRoomId}'`,
        );
      }

      // 1° Processing the new subscription data
      set(buffersAtom, (draft) => {
        let buffer = draft[data.messageId];
        if (!buffer) {
          buffer = {
            messageId: data.messageId,
            createdAt: new Date().toISOString(),
            chunks: [],
            received: 0,
            receivedLastChunk: false,
          };

          draft[data.messageId] = buffer;
        }

        if (!buffer.chunks[data.sequenceNumber]) {
          buffer.received += 1;
          buffer.chunks[data.sequenceNumber] = data.tokens;
        }

        if (data.isLast) {
          buffer.receivedLastChunk = true;
        }
      });

      // 2° Combining chunks to get a partial message.
      const tokens = takeWhileDefined(
        get(buffersAtom)[data.messageId]?.chunks ?? [],
      ).flat();
      const messageSoFar = tokens.length === 0 ? null : tokens.join('');

      // 3° Removing unnecessary buffers
      set(buffersAtom, (draft) => {
        const buffer = draft[data.messageId];

        if (
          buffer &&
          buffer.receivedLastChunk &&
          buffer.received === buffer.chunks.length
        ) {
          // We received all chunks, no need to keep the buffer in memory anymore
          delete draft[data.messageId];
        }
      });

      return { messageSoFar };
    },
  );

  /**
   * Useful for ignoring any future stream chunks after the full message
   * has already arrived via another subscription.
   */
  const deleteBufferAtom = actionAtom(({ set }, messageId: string) => {
    set(buffersAtom, (draft) => {
      delete draft[messageId];
    });
  });

  return { appendToBufferAtom, deleteBufferAtom };
});

const NoopMolecule = molecule(() => {
  return {
    appendToBufferAtom: atom(null, () => {}),
    deleteBufferAtom: atom(null, () => {}),
  };
});

export function useStreamingMessageSubscription({
  subscribeToMore,
}: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;
  const me = useMe();

  const { appendToBufferAtom } = useMolecule(
    chatRoomId ? StreamingMessageMolecule : NoopMolecule,
  );
  const appendToBuffer = useSetAtom(appendToBufferAtom);
  const isMicrobotConversation = useAtomValue(
    chatRoomId ? Scope.microbotConversation(chatRoomId) : Scope.unavailable,
  );

  const alphaBot = useMicrobot(
    Role.isAdvisor(me) ? MicrobotPersonality.Astro : undefined,
  );

  useMoreSubscription(
    AiResponseStreamDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const data = subscriptionData.data.aiResponseStream;

      if (!alphaBot || !chatRoomId || !data) {
        return prev;
      }

      try {
        const { messageSoFar } = appendToBuffer(data);

        if (!messageSoFar) {
          return prev;
        }

        return upsertMessage(prev, {
          ...mockUserMessage({
            author: alphaBot,
            chatRoomId,
            message: messageSoFar,
          }),
          // The id is known, we do not have to mock it.
          id: data.messageId,
        });
      } catch (err) {
        Sentry.captureException(err);
        return prev;
      }
    },
    { chatRoomId: chatRoomId! },
    !!chatRoomId && isMicrobotConversation,
  );
}

/**
 * Useful for ignoring any future stream chunks after the full message
 * has already arrived via another subscription.
 */
export function useDeleteStreamingBuffer(): (messageId: string) => void {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;
  const { deleteBufferAtom } = useMolecule(
    chatRoomId ? StreamingMessageMolecule : NoopMolecule,
  );

  return useSetAtom(deleteBufferAtom);
}
