import React, { useMemo, useRef, useEffect } from 'react';

import {
  useCallState,
  VideoCallStatus,
  type CallParticipant,
} from '../VideoRoomAPI';
import { useConnectedParticipants } from '../Participants';

interface ParticipantAudioProps {
  participant: CallParticipant;
}

const ParticipantAudio: React.FC<ParticipantAudioProps> = ({ participant }) => {
  const audioTrack = useMemo(
    () =>
      participant.joinState === 'Joined'
        ? participant.session.tracks.audio
        : undefined,
    [participant],
  );

  const screenAudioTrack = useMemo(
    () =>
      participant.joinState === 'Joined'
        ? participant.session.tracks.screenAudio
        : undefined,
    [participant],
  );

  const audioElement = useRef<HTMLAudioElement>(null);
  const screenAudioElement = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioElement.current;

    if (!audio || !audioTrack?.track) {
      return;
    }

    audio.srcObject = new MediaStream([audioTrack.track]);
  }, [audioTrack?.track]);

  useEffect(() => {
    const audio = screenAudioElement.current;

    if (!audio || !screenAudioTrack?.track) {
      return;
    }

    audio.srcObject = new MediaStream([screenAudioTrack.track]);
  }, [screenAudioTrack?.track]);

  if (participant.joinState === 'Joined' && participant.session.isLocal) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioElement} autoPlay />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={screenAudioElement} autoPlay />
    </>
  );
};

const BackgroundAudio: React.FC = () => {
  const callState = useCallState();
  const participants = useConnectedParticipants();

  return (
    <>
      {callState?.status === VideoCallStatus.Joined &&
        participants.length &&
        participants.map((participant) => (
          <ParticipantAudio key={participant.key} participant={participant} />
        ))}
    </>
  );
};

export default BackgroundAudio;
