import cs from 'classnames';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFormatter, useLanguage } from '@advisor/language';
import { Icon } from '@advisor/design/components';
import { Colors } from '@advisor/design/styles';
import { useMessageSeenBy } from '../messaging';
import { MessageListItemFooterProps } from './types';

const MessageListItemFooter = (props: MessageListItemFooterProps) => {
  const { isCurrentUser, dateTime, chatRoomId, isMostRecent, isSavedToMemory } =
    props;
  const { seenByAll, seen } = useMessageSeenBy(dateTime, chatRoomId);
  const { isRTL } = useLanguage();
  const { t } = useTranslation('common');

  const seenText = useMemo(() => {
    const formattedDate = DateFormatter.time(dateTime);

    // seen.length === 1 means that the current user only seen
    if (isMostRecent && seen.length > 1) {
      // Use seen by all only if there are more
      // than 2 chat room members
      if (seenByAll && seen.length > 2) {
        return `${t('seen-by-all')} • ${formattedDate}`;
      }

      return `${t('seen')} • ${formattedDate}`;
    }

    return formattedDate;
  }, [seenByAll, seen, t, dateTime, isMostRecent]);

  return (
    <div
      className={cs(
        'flex items-center text-xs text-dark-grey-01 ml-[1.125rem] mr-[0.625rem] mb-2',
        isCurrentUser !== isRTL ? 'justify-end' : 'justify-start',
      )}
    >
      {isSavedToMemory && (
        <Icon name="SaveMemory" color={Colors.astroDark} size={14} />
      )}
      <span>{seenText}</span>
    </div>
  );
};

export default memo(MessageListItemFooter);
