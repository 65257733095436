import { useCallback, useMemo } from 'react';

import {
  OnUserLeftVideoChatMutationOptions,
  useOnUserLeftVideoChatMutation,
  VideoChatParticipantInfoFragment,
} from '@advisor/api/generated/graphql';
import { useVideoChat } from '../useVideoChat';

const onUserLeftVideoChatOptions = (
  chatRoomId: string,
  count: number,
  participants: VideoChatParticipantInfoFragment[],
): OnUserLeftVideoChatMutationOptions => {
  return {
    optimisticResponse: ({ leftUserId }) => {
      const newParticipants = [...participants];
      const indexToDelete = newParticipants.findIndex(
        (p) => p.participant.id === leftUserId,
      );

      if (indexToDelete !== -1) {
        newParticipants.splice(indexToDelete, 1);
      }

      return {
        __typename: 'Mutation',
        onUserLeftVideoChat: {
          __typename: 'VideoChatRoom',
          chatRoomId,
          count,
          participants: newParticipants,
        },
      };
    },
  };
};

function useOnUserLeftVideoChat(chatRoomId?: string) {
  const videoChatRoom = useVideoChat(chatRoomId || '', !chatRoomId);

  const count = videoChatRoom?.count;
  const participants = videoChatRoom?.participants;

  const options = useMemo(
    () =>
      chatRoomId !== undefined &&
      count !== undefined &&
      participants !== undefined
        ? onUserLeftVideoChatOptions(chatRoomId, count, participants)
        : undefined,
    [chatRoomId, count, participants],
  );

  const [onUserLeftVideoChat] = useOnUserLeftVideoChatMutation(options);

  return useCallback(
    (leftUserId: string) => {
      if (!chatRoomId) {
        return;
      }

      onUserLeftVideoChat({
        variables: {
          chatRoomId,
          leftUserId,
        },
      });
    },
    [chatRoomId, onUserLeftVideoChat],
  );
}

export default useOnUserLeftVideoChat;
