import cs from 'classnames';
import React, { memo, useMemo } from 'react';

import Icon from '../Icon/Icon';
import SpinnerIcon from '../Icon/Spinner';
import { IconButtonProps } from './types';
import Bare from './Bare';

const VariantClasses = {
  astroDark: 'text-astro-dark',
  primaryBlue: 'text-primary',
  darkGrey01: 'text-dark-grey-01',
  darkGrey03: 'text-dark-grey-03',
  negative: 'text-negative',
  grey: 'text-grey',
} as const;

const IconButton = (props: IconButtonProps) => {
  const { icon, size, variant, className, disabled, ...rest } = props;
  const variantClassName = useMemo(() => {
    const variantOrDefault =
      variant && variant in VariantClasses
        ? (variant as keyof typeof VariantClasses)
        : 'primaryBlue';
    return VariantClasses[variantOrDefault];
  }, [variant]);

  return (
    <Bare
      {...rest}
      disabled={disabled}
      className={cs(
        'transition-opacity',
        disabled ? 'opacity-40' : 'hover:opacity-60',
        variantClassName,
        className,
      )}
    >
      {({ loading }) =>
        loading ? <SpinnerIcon size={size} /> : <Icon name={icon} size={size} />
      }
    </Bare>
  );
};

export default memo(IconButton);
