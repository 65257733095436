import React from 'react';
import cs from 'classnames';

import { BannerItemProps } from './types';

const BannerItem: React.FC<BannerItemProps> = ({ notification }) => {
  return (
    <div className="px-6 w-full xl:px-0 xl:w-chat-width xl:mx-auto">
      {notification.message && (
        <p
          className={cs(
            'font-outfit text-sm font-medium text-dark-grey-03',
            notification.title
              ? 'pt-3 text-left rtl:text-right'
              : 'text-center',
          )}
        >
          {notification.message}
        </p>
      )}
      {notification.children && (
        <div
          className={cs(
            !!notification.title || !!notification.message ? 'pt-3' : '',
          )}
        >
          {notification.children()}
        </div>
      )}
    </div>
  );
};

export default BannerItem;
