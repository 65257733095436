import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Modal,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import { InfoTabProps } from './types';

const InfoTab: React.FC<InfoTabProps> = ({ onContinue, onClose }) => {
  const { t } = useTranslation(['common', 'onboarding']);

  return (
    <>
      <Modal.PopUpHeader
        large
        title={t('onboarding:create-customer-conversation-lower')}
        message={t('onboarding:for-users-who-are-not-on-platform-yet')}
        onClose={onClose}
      />
      <div className="px-12 py-8">
        {/* Body */}
        <div className="rounded-10 bg-light-grey border border-grey p-4 flex items-center justify-between">
          <div className="flex-1">
            <span className="font-sora font-bold text-dark-grey-03 text-sm leading-[1.125rem">
              {t('onboarding:what-can-you-do')}
            </span>
            <Layout.Spacer.Vertical size="atomic" />
            <p className="text-xs font-outfit font-medium text-dark-grey-02">
              {t('onboarding:we-offer-a-dedicated-conversation-space')}
            </p>
            <Layout.Spacer.Vertical size="tiny" />
            <ul className="list-disc ltr:pl-5 rtl:pr-5 text-dark-grey-02 text-xs font-outfit font-medium">
              <li>{t('onboarding:prepare-ahead-of-the-customers-arrival')}</li>
              <li>{t('onboarding:configure-and-set-up-the-task-organizer')}</li>
              <li>{t('onboarding:track-deadlines-and-manage-documents')}</li>
            </ul>
          </div>

          <Layout.Spacer.Horizontal size="tiny" />

          <Icon
            name="UserSettings"
            color={Colors.softBlueDark}
            size={Spacing.small02}
          />
        </div>

        <Layout.Spacer.Vertical size="tiny" />

        <div className="rounded-10 bg-light-grey border border-grey p-4 flex items-center justify-between">
          <div className="flex-1">
            <span className="font-sora font-bold text-dark-grey-03 text-sm leading-[1.125rem">
              {t('onboarding:how-it-works-lower')}
            </span>
            <Layout.Spacer.Vertical size="atomic" />
            <ol className="list-decimal ltr:pl-5 rtl:pr-5 text-dark-grey-02 text-xs font-outfit font-medium">
              <li>
                {t(
                  'onboarding:invite-the-customers-to-the-conversation-using-an-email',
                )}
              </li>
              <li>
                {t('onboarding:the-customer-will-receive-the-invite-link')}
              </li>
              <li>
                {t(
                  'onboarding:once-the-customer-confirms-their-account-they-can-set-up',
                )}
              </li>
            </ol>
          </div>

          <Layout.Spacer.Horizontal size="tiny" />

          <Icon name="HelpCircle" color={Colors.cyan} size={Spacing.small02} />
        </div>

        <Layout.Spacer.Vertical size="small02" />

        {/* Continue button */}
        <div className="flex justify-center">
          <DeprecatedButton.Large
            onPress={onContinue}
            label={t('common:continue')}
            variant="primary"
            width={160}
          />
        </div>
      </div>
    </>
  );
};

export default InfoTab;
