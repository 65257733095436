import cs from 'classnames';
import React, { useMemo } from 'react';

import { Form, SizeAdaptingWrapper } from '@advisor/design/components';
import { RetrievalOptionProps } from './types';

const RetrievalOption: React.FC<RetrievalOptionProps> = ({
  label,
  description,
  checked,
  onChecked,
}) => {
  const descriptionProps = useMemo(
    () => ({
      value: description,
    }),
    [description],
  );

  return (
    <button
      className={cs(
        'flex justify-between gap-2 py-3 px-4 rounded-10 w-full text-left border border-grey',
        checked ? 'bg-light-grey' : 'bg-white',
      )}
      type="button"
      onClick={onChecked}
    >
      <div className="flex-1">
        <h1
          className={cs(
            'py-1 font-sora text-sm font-bold',
            checked ? 'text-primary' : 'text-dark-grey-03',
          )}
        >
          {label}
        </h1>
        <SizeAdaptingWrapper
          inner={checked ? Description : Empty}
          commonProps={descriptionProps}
          lockX
        />
      </div>
      <div>
        <div
          className={cs(
            'transition-all ease-in-out duration-[400ms]',
            checked ? 'pt-8' : 'pt-0',
          )}
        />
        <Form.RadioIcon checked={checked} />
      </div>
    </button>
  );
};

export default RetrievalOption;

const Description: React.FC<{ value: string }> = ({ value }) => {
  return <p className="pt-1">{value}</p>;
};

const Empty: React.FC<{ value: string }> = () => {
  return null;
};
