import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import { atomFamily } from 'jotai/utils';
import { createScope, use } from 'bunshi';
import { useMolecule } from 'bunshi/react';
import { ExtractAtomArgs, useAtom, useAtomValue } from 'jotai';

import {
  MilestoneDocument,
  MilestoneInfoFragment,
  MilestonesForCategoryDocument,
} from '@advisor/api/generated/graphql';
import { lazyAtom } from '@advisor/utils/atoms';
import { atomOfQuery, atomWithQuery } from '@advisor/api/apollo';
import MilestoneCategoryMolecule from './milestoneCategoryMolecule';
import MilestoneMolecule from './milestoneMolecule';

export const MilestoneScope = createScope<string | null | undefined>(null);

export function requireMilestoneScope() {
  const milestoneId = use(MilestoneScope);

  if (!milestoneId) {
    throw new Error(
      `Tried to use a molecule scoped to a Student Journey Milestone without providing its scope`,
    );
  }

  return milestoneId;
}

export const milestoneAtoms = atomFamily((milestoneId: string) => {
  const queryAtom = atomWithQuery(() => ({
    query: MilestoneDocument,
    variables: { milestoneId },
    fetchPolicy: 'cache-and-network',
  }));

  return lazyAtom(
    async (get) => (await get(queryAtom)).data?.milestone,
    (_, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
      set(queryAtom, ...args),
  );
});

export function useMilestone(flag: 'required'): MilestoneInfoFragment;
export function useMilestone(
  flag?: 'required',
): MilestoneInfoFragment | undefined;
export function useMilestone(flag?: 'required') {
  const { milestoneAtom } = useMolecule(MilestoneMolecule);
  const milestone = useAtomValue(milestoneAtom) ?? undefined;

  if (!milestone && flag === 'required') {
    throw new Error(`No Milestone query result`);
  }

  return milestone;
}

export const milestonesForCategoryAtoms = atomFamily(
  (milestoneCategoryId: string) => {
    const queryAtom = atomOfQuery(MilestonesForCategoryDocument, {
      milestoneCategoryId,
    });

    return lazyAtom(
      async (get) => {
        return sortBy(
          (await get(queryAtom)).data?.milestonesForCategory ?? [],
          milestoneStatusPriority,
        );
      },
      (_, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
        set(queryAtom, ...args),
    );
  },
);

export default function useMilestonesForCategory() {
  const { milestonesForCategoryAtom } = useMolecule(MilestoneCategoryMolecule);
  const [milestones, refetchMilestones] = useAtom(milestonesForCategoryAtom);

  return {
    milestones: milestones ?? [],
    refetchMilestones,
  };
}

// Lower number means higher on the list
enum MilestoneStatusPriority {
  Pending = 1,
  Overdue = 2,
  Due = 3,
  OpenEnded = 4,
  Completed = 5,
}

const milestoneStatusPriority = (
  milestone: MilestoneInfoFragment,
): MilestoneStatusPriority => {
  const { pendingReview, isCompleted, notificationTime } = milestone;

  if (pendingReview) {
    return MilestoneStatusPriority.Pending;
  }

  if (isCompleted) {
    return MilestoneStatusPriority.Completed;
  }

  if (!notificationTime) {
    return MilestoneStatusPriority.OpenEnded;
  }

  if (dayjs(notificationTime).diff(dayjs()) > 0) {
    return MilestoneStatusPriority.Due;
  }

  return MilestoneStatusPriority.Overdue;
};
