import React from 'react';

import { HeaderLayoutProps } from './types';

const variantClassnames = {
  secondary: 'bg-secondary',
  primary: 'bg-primary',
} as const;

const HeaderLayout = ({ variant, children, onPress }: HeaderLayoutProps) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={variantClassnames[variant]}
      onClick={onPress}
      onKeyDown={onPress}
    >
      <div className="flex flex-row items-center py-2.5 px-6 xl:px-0 xl:w-chat-width xl:mx-auto">
        {children}
      </div>
    </div>
  );
};

export default HeaderLayout;
