import { Transition } from '@headlessui/react';
import { memo, useEffect, useMemo, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import AnimatedOverlay from 'src/components/atoms/AnimatedOverlay';
import SideModalContext from './context';

type Props = {
  children: React.ReactNode;
  className?: string;
  show: boolean;
  onClose: () => void;
};

function useDeferredClose(shouldShow: boolean) {
  const [hiding, setHiding] = useState(false);

  useEffect(() => {
    // either mounting, or switching value
    setHiding(false);
  }, [shouldShow]);

  const hideEventually = useEvent(() => {
    setHiding(true);
  });

  return [shouldShow && !hiding, hideEventually] as const;
}

const SideModalRoot = (props: Props) => {
  const { children, className, show, onClose } = props;

  const [deferredShow, hideEventually] = useDeferredClose(show);

  const context = useMemo(
    () => ({ onClose: hideEventually }),
    [hideEventually],
  );

  return (
    <div className={className} role="dialog">
      <AnimatedOverlay show={deferredShow} appear onClose={hideEventually} />
      <Transition
        as="aside"
        show={deferredShow}
        appear
        afterLeave={onClose}
        enter="ease-out duration-300 transition-transform"
        enterFrom="max-xl:ltr:translate-x-full max-xl:rtl:-translate-x-full"
        enterTo="translate-x-0"
        leave="max-xl:ease-out max-xl:duration-300 max-xl:transition-transform"
        leaveFrom="translate-x-0"
        leaveTo="max-xl:ltr:translate-x-full max-xl:rtl:-translate-x-full"
        className="bg-white fixed xl:static inset-y-0 ltr:right-0 rtl:left-0 max-w-full w-chat-sidebar-2xl xl:w-chat-sidebar-xl 2xl:w-chat-sidebar-2xl xl:h-full ltr:xl:border-l rtl:xl:border-r xl:rounded-none flex flex-col border-grey z-10 overflow-y-hidden"
      >
        <SideModalContext.Provider value={context}>
          {children}
        </SideModalContext.Provider>
      </Transition>
    </div>
  );
};

export default memo(SideModalRoot);
