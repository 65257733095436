import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import { ConfirmTabProps } from './types';

const ConfirmTab: React.FC<ConfirmTabProps> = ({
  microbotName,
  communityTitle,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <div className="p-8 text-center">
      <h3 className="font-sora text-dark-grey-03 text-sm leading-[1.125rem] font-bold">
        {t(
          'microbot:are-you-sure-you-want-to-make-changes-to-the-primary-context-for',
          {
            name: microbotName,
            title: t(communityTitle),
          },
        )}
      </h3>
      <Layout.Spacer.Vertical size="micro02" />
      <p className="font-outfit text-sm leading-[1.125rem] text-dark-grey-02 font-medium">
        {t(
          'microbot:this-will-update-names-responses-in-all-future-conversations',
          {
            name: microbotName,
          },
        )}
      </p>

      <Layout.Spacer.Vertical size="small01" />

      <DeprecatedButton.Large
        onPress={onSave}
        variant="primary"
        label={t('microbot:make-changes')}
        className="py-4 text-sm leading-[0.875rem]"
      />
      <Layout.Spacer.Vertical size="small01" />
      <DeprecatedButton.Outline
        className="w-full py-4 text-sm leading-[0.875rem]"
        variant="darkGrey03"
        onPress={onClose}
        label={t('common:cancel')}
      />
    </div>
  );
};

export default ConfirmTab;
