import z from 'zod';

const LanguageMetadata = {
  af: { name: 'Afrikaans', native: 'Afrikaans', rtl: false },
  ak: { name: 'Akan', native: 'Akana', rtl: false },
  am: { name: 'Amharic', native: 'አማርኛ', rtl: false },
  ar: { name: 'Arabic', native: 'العربية', rtl: true },
  as: { name: 'Assamese', native: 'অসমীয়া', rtl: false },
  ay: { name: 'Aymara', native: 'Aymar', rtl: false },
  az: { name: 'Azerbaijani', native: 'Azərbaycanca / آذربايجان', rtl: false },
  be: { name: 'Belarusian', native: 'Беларуская', rtl: false },
  bg: { name: 'Bulgarian', native: 'Български', rtl: false },
  bh: { name: 'Bihari', native: 'भोजपुरी', rtl: false },
  bm: { name: 'Bambara', native: 'Bamanankan', rtl: false },
  bn: { name: 'Bengali', native: 'বাংলা', rtl: false },
  bs: { name: 'Bosnian', native: 'Bosanski', rtl: false },
  ca: { name: 'Catalan', native: 'Català', rtl: false },
  co: { name: 'Corsican', native: 'Corsu', rtl: false },
  cs: { name: 'Czech', native: 'Čeština', rtl: false },
  cy: { name: 'Welsh', native: 'Cymraeg', rtl: false },
  da: { name: 'Danish', native: 'Dansk', rtl: false },
  de: { name: 'German', native: 'Deutsch', rtl: false },
  dv: { name: 'Divehi', native: 'ދިވެހިބަސް', rtl: true },
  ee: { name: 'Ewe', native: 'Ɛʋɛ', rtl: false },
  el: { name: 'Greek', native: 'Ελληνικά', rtl: false },
  en: { name: 'English (US)', native: 'English (US)', rtl: false },
  eo: { name: 'Esperanto', native: 'Esperanto', rtl: false },
  es: { name: 'Spanish (Spain)', native: 'Español (España)', rtl: false },
  'es-419': {
    name: 'Spanish (Latin America)',
    native: 'Español (América Latina)',
    rtl: false,
  },
  et: { name: 'Estonian', native: 'Eesti', rtl: false },
  eu: { name: 'Basque', native: 'Euskara', rtl: false },
  fa: { name: 'Persian', native: 'فارسی', rtl: true },
  fi: { name: 'Finnish', native: 'Suomi', rtl: false },
  fr: { name: 'French', native: 'Français', rtl: false },
  fy: { name: 'West Frisian', native: 'Frysk', rtl: false },
  ga: { name: 'Irish', native: 'Gaeilge', rtl: false },
  gd: { name: 'Scottish Gaelic', native: 'Gàidhlig', rtl: false },
  gl: { name: 'Galician', native: 'Galego', rtl: false },
  gn: { name: 'Guarani', native: "Avañe'ẽ", rtl: false },
  gu: { name: 'Gujarati', native: 'ગુજરાતી', rtl: false },
  ha: { name: 'Hausa', native: 'هَوُسَ', rtl: true },
  he: { name: 'Hebrew', native: 'עברית', rtl: true },
  hi: { name: 'Hindi', native: 'हिन्दी', rtl: false },
  hr: { name: 'Croatian', native: 'Hrvatski', rtl: false },
  ht: { name: 'Haitian', native: 'Krèyol ayisyen', rtl: false },
  hu: { name: 'Hungarian', native: 'Magyar', rtl: false },
  hy: { name: 'Armenian', native: 'Հայերեն', rtl: false },
  id: { name: 'Indonesian', native: 'Bahasa Indonesia', rtl: false },
  ig: { name: 'Igbo', native: 'Igbo', rtl: false },
  is: { name: 'Icelandic', native: 'Íslenska', rtl: false },
  it: { name: 'Italian', native: 'Italiano', rtl: false },
  ja: { name: 'Japanese', native: '日本語', rtl: false },
  jv: { name: 'Javanese', native: 'Basa Jawa', rtl: false },
  ka: { name: 'Georgian', native: 'ქართული', rtl: false },
  kk: { name: 'Kazakh', native: 'Қазақша', rtl: false },
  km: { name: 'Cambodian', native: 'ភាសាខ្មែរ', rtl: false },
  kn: { name: 'Kannada', native: 'ಕನ್ನಡ', rtl: false },
  ko: { name: 'Korean', native: '한국어', rtl: false },
  ku: { name: 'Kurdish', native: 'Kurdî / كوردی', rtl: true },
  ky: { name: 'Kyrgyz', native: 'Кыргызча', rtl: false },
  la: { name: 'Latin', native: 'Latina', rtl: false },
  lb: { name: 'Luxembourgish', native: 'Lëtzebuergesch', rtl: false },
  lg: { name: 'Ganda', native: 'Luganda', rtl: false },
  ln: { name: 'Lingala', native: 'Lingála', rtl: false },
  lo: { name: 'Laotian', native: 'ລາວ / Pha xa lao', rtl: false },
  lt: { name: 'Lithuanian', native: 'Lietuvių', rtl: false },
  lv: { name: 'Latvian', native: 'Latviešu', rtl: false },
  mg: { name: 'Malagasy', native: 'Malagasy', rtl: false },
  mi: { name: 'Maori', native: 'Māori', rtl: false },
  mk: { name: 'Macedonian', native: 'Македонски', rtl: false },
  ml: { name: 'Malayalam', native: 'മലയാളം', rtl: false },
  mn: { name: 'Mongolian', native: 'Монгол', rtl: false },
  mo: { name: 'Moldovan', native: 'Moldovenească', rtl: false },
  mr: { name: 'Marathi', native: 'मराठी', rtl: false },
  ms: { name: 'Malay', native: 'Bahasa Melayu', rtl: false },
  mt: { name: 'Maltese', native: 'bil-Malti', rtl: false },
  my: { name: 'Burmese', native: 'မြန်မာစာ', rtl: false },
  nb: { name: 'Norwegian Bokmål', native: 'Norsk bokmål', rtl: false },
  ne: { name: 'Nepali', native: 'नेपाली', rtl: false },
  nl: { name: 'Dutch', native: 'Nederlands', rtl: false },
  no: { name: 'Norwegian', native: 'Norsk', rtl: false },
  ny: { name: 'Chichewa', native: 'Chi-Chewa', rtl: false },
  om: { name: 'Oromo', native: 'Oromoo', rtl: false },
  or: { name: 'Oriya', native: 'ଓଡ଼ିଆ', rtl: false },
  pa: {
    name: 'Panjabi / Punjabi',
    native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
    rtl: false,
  },
  pl: { name: 'Polish', native: 'Polski', rtl: false },
  ps: { name: 'Pashto', native: 'پښتو', rtl: true },
  pt: { name: 'Portuguese', native: 'Português', rtl: false },
  qu: { name: 'Quechua', native: 'Runa Simi', rtl: false },
  ro: { name: 'Romanian', native: 'Română', rtl: false },
  ru: { name: 'Russian', native: 'Русский', rtl: false },
  rw: { name: 'Rwandi', native: 'Kinyarwandi', rtl: false },
  sa: { name: 'Sanskrit', native: 'संस्कृतम्', rtl: false },
  sd: { name: 'Sindhi', native: 'सिनधि', rtl: false },
  sh: {
    name: 'Serbo-Croatian',
    native: 'Srpskohrvatski / Српскохрватски',
    rtl: false,
  },
  si: { name: 'Sinhalese', native: 'සිංහල', rtl: false },
  sk: { name: 'Slovak', native: 'Slovenčina', rtl: false },
  sl: { name: 'Slovenian', native: 'Slovenščina', rtl: false },
  sm: { name: 'Samoan', native: 'Gagana Samoa', rtl: false },
  sn: { name: 'Shona', native: 'chiShona', rtl: false },
  so: { name: 'Somalia', native: 'Soomaaliga', rtl: false },
  sq: { name: 'Albanian', native: 'Shqip', rtl: false },
  sr: { name: 'Serbian', native: 'Српски', rtl: false },
  st: { name: 'Southern Sotho', native: 'Sesotho', rtl: false },
  su: { name: 'Sundanese', native: 'Basa Sunda', rtl: false },
  sv: { name: 'Swedish', native: 'Svenska', rtl: false },
  sw: { name: 'Swahili', native: 'Kiswahili', rtl: false },
  ta: { name: 'Tamil', native: 'தமிழ்', rtl: false },
  te: { name: 'Telugu', native: 'తెలుగు', rtl: false },
  tg: { name: 'Tajik', native: 'Тоҷикӣ', rtl: false },
  th: { name: 'Thai', native: 'ไทย / Phasa Thai', rtl: false },
  ti: { name: 'Tigrinya', native: 'ትግርኛ', rtl: false },
  tk: { name: 'Turkmen', native: 'Туркмен / تركمن', rtl: false },
  tl: { name: 'Tagalog / Filipino', native: 'Tagalog', rtl: false },
  tr: { name: 'Turkish', native: 'Türkçe', rtl: false },
  ts: { name: 'Tsonga', native: 'Xitsonga', rtl: false },
  tt: { name: 'Tatar', native: 'Tatarça', rtl: false },
  tw: { name: 'Twi', native: 'Twi', rtl: false },
  ug: { name: 'Uyghur', native: 'Uyƣurqə / ئۇيغۇرچە', rtl: false },
  uk: { name: 'Ukrainian', native: 'Українська', rtl: false },
  ur: { name: 'Urdu', native: 'اردو', rtl: true },
  uz: { name: 'Uzbek', native: 'Ўзбек', rtl: false },
  vi: { name: 'Vietnamese', native: 'Tiếng Việt', rtl: false },
  xh: { name: 'Xhosa', native: 'isiXhosa', rtl: false },
  yi: { name: 'Yiddish', native: 'ייִדיש', rtl: true },
  yo: { name: 'Yoruba', native: 'Yorùbá', rtl: false },
  zh: { name: 'Chinese', native: '中文', rtl: false },
  zu: { name: 'Zulu', native: 'isiZulu', rtl: false },
} as const;

export type LanguageCode = z.infer<typeof LanguageCode>;
export const LanguageCode = z.enum([
  'en',
  ...(Object.keys(LanguageMetadata) as (keyof typeof LanguageMetadata)[]),
]);

export function isLanguageCode(value: unknown): value is LanguageCode {
  return typeof value === 'string' && value in LanguageMetadata;
}

export function parseLanguageCode(value: unknown): LanguageCode | null {
  if (isLanguageCode(value)) {
    return value;
  }

  return null;
}

export default LanguageMetadata;
