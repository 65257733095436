import React from 'react';
import cs from 'classnames';

import { WithCountProps } from './types';

const WithCount: React.FC<WithCountProps> = ({ label, count, className }) => (
  <div className={cs('flex flex-row items-center my-6 gap-2', className)}>
    <h4 className="text-dark-grey-02 text-xs font-sora font-bold uppercase">
      {label}
    </h4>
    {count !== undefined && (
      <div className="h-5 bg-secondary-light rounded-full grid place-content-center px-1 py-0.5 min-w-5">
        <p className="text-secondary text-xs">{count}</p>
      </div>
    )}
  </div>
);

export default WithCount;
