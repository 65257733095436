import { ApolloCache } from '@apollo/client';

import { LanguageCode } from '@advisor/language';
import { ChatRoomMember } from '@advisor/api/generated/graphql';
import { identifyChatRoomCache } from '@advisor/api/graphql/customCacheKeys';

export type ChatRoomMemberCacheValue = Omit<ChatRoomMember, 'member'> & {
  member: { __ref: string };
};
export type ChatRoomMemberUpdatableValues = Partial<{
  translationLanguage: LanguageCode;
  autoTranslate: boolean;
  autoTranslateStudentJourney: boolean;
}>;

function modifyCachedChatMemberField(
  cachedMembers: ChatRoomMemberCacheValue[],
  predicate: (m: ChatRoomMemberCacheValue) => boolean,
  newValues: ChatRoomMemberUpdatableValues,
) {
  return cachedMembers.map((m) =>
    predicate(m)
      ? {
          ...m,
          translationLanguage:
            newValues?.translationLanguage ?? m.translationLanguage,
          autoTranslate: newValues?.autoTranslate ?? m.autoTranslate,
          autoTranslateStudentJourney:
            newValues?.autoTranslateStudentJourney ??
            m.autoTranslateStudentJourney,
        }
      : m,
  );
}

function modifyCachedChatMember(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  chatRoomId: string | undefined,
  memberIdentity: string | undefined,
  newValues: ChatRoomMemberUpdatableValues,
) {
  if (!chatRoomId || !memberIdentity) {
    return;
  }

  cache.modify({
    id: identifyChatRoomCache({ id: chatRoomId }),
    fields: {
      members(prev) {
        return modifyCachedChatMemberField(
          prev,
          // eslint-disable-next-line no-underscore-dangle
          (m) => m.member.__ref === memberIdentity,
          newValues,
        );
      },
    },
    optimistic: true,
  });
}

export default modifyCachedChatMember;
