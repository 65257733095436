import React, { useMemo, useCallback } from 'react';
import { padStart } from 'lodash-es';
import cs from 'classnames';

import { UserInfoFragment } from '@advisor/api/generated/graphql';
import SegmentedGroup from '@advisor/design/components/SegmentedGroup';
import UserListItem from './UserListItem';
import TruncatedUserListItem from './TruncatedUserListItem';
import useTruncateUserList from './useTruncateUserList';
import { UserListProps } from './types';
import { sortUsers } from './utils';

const UserList: React.FC<UserListProps> = ({
  users,
  title,
  variant,
  showCount,
  transparent,
  callToAction,
  truncate,
}) => {
  const renderItem = useCallback(
    (item: UserInfoFragment, index: number) => (
      <SegmentedGroup.Item
        key={item.id}
        className={cs(
          'last:border-b-0',
          transparent && 'opacity-50',
          variant === 'white' ? 'bg-white' : 'bg-light-grey',
        )}
      >
        <UserListItem
          key={item.id}
          user={item}
          index={index}
          listLength={users.length}
        />
      </SegmentedGroup.Item>
    ),
    [users.length, variant, transparent],
  );

  const { members, truncatedMembers, shouldTruncate } = useTruncateUserList({
    truncate,
    users,
  });

  const userItems = useMemo(
    () => sortUsers(members).map(renderItem),
    [members, renderItem],
  );

  return (
    <div>
      <h1 className="flex justify-between my-3 uppercase text-xs text-dark-grey-02 font-sora font-normal">
        {title}{' '}
        {showCount && <span>{padStart(`${users.length}`, 2, '0')}</span>}
      </h1>
      <SegmentedGroup.Root>
        {userItems}
        {shouldTruncate && <TruncatedUserListItem users={truncatedMembers} />}
        {callToAction && <SegmentedGroup.Button {...callToAction} />}
      </SegmentedGroup.Root>
    </div>
  );
};

export default UserList;
