import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Gender } from '@advisor/api/generated/graphql';
import { CountryName } from '@advisor/utils/country/internationalDiallingCodes';
import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';
import { Layout, Form } from '@advisor/design/components';
import CountrySelect from '../CountrySelect';
import TimezoneSelect from '../TimezoneSelect';
import EditPhoneNumberForm from './EditPhoneNumberForm';
import { UseEditProfileType } from './useEditProfile';
import EditEmailForm from './EditEmailForm';
import AvatarUpload from './AvatarUpload';

const EditProfileForm: React.FC<{ props: UseEditProfileType }> = ({
  props,
}) => {
  const { t } = useTranslation('common');

  const {
    me,
    isNameValid,
    genderOptions,
    isUploadingAvatar,
    name,
    gender,
    country,
    timezone,

    onChangeName,
    onAvatarUploadStart,
    onAvatarUploadFailure,
    onAvatarUploadSuccess,
    onChangeGender,
    onChangeCountry,
    onChangeTimezone,
  } = props;

  return (
    <div data-cy="edit-profile-form">
      <AvatarUpload
        onUploadStart={onAvatarUploadStart}
        onUploadFailure={onAvatarUploadFailure}
        onUploadSuccess={onAvatarUploadSuccess}
      >
        <Form.AvatarInput
          avatarUrl={me?.avatarUrl ?? ''}
          loading={isUploadingAvatar || !me?.avatarUrl}
          variant="large"
          icon="edit"
        />
      </AvatarUpload>
      <div className="bg-white md:rounded-lg md:w-chat-width md:mx-auto md:p-6 p-4">
        <Layout.Heading title={t('basic-information')} />
        <Layout.Spacer.Vertical size="tiny" />
        <Form.Text
          label={t('name')}
          value={name ?? ''}
          onChange={onChangeName}
          maxLength={64}
          errorMessage={
            !isNameValid
              ? t('{{fieldName}}-is-invalid', { fieldName: t('name') })
              : undefined
          }
        />
        <Layout.Spacer.Vertical size="tiny" />
        <Form.Select
          label={t('pronouns')}
          value={gender ?? Gender.Undefined}
          onSelect={onChangeGender}
          options={genderOptions}
        />
        <Layout.Spacer.Vertical size="tiny" />
        <CountrySelect
          value={(country as CountryName) ?? ''}
          onSelect={onChangeCountry}
        />
        <Layout.Spacer.Vertical size="tiny" />
        <TimezoneSelect
          value={timezone as TimezoneName}
          onSelect={onChangeTimezone}
        />
        <Layout.Spacer.Vertical size="tiny" />
        <EditEmailForm />
        <Layout.Spacer.Vertical size="tiny" />
        <EditPhoneNumberForm />
        <div className="font-outfit text-sm text-dark-grey-025 mt-2 ltr:ml-4 rtl:mr-r rtl:text-right">
          {t('contact-details-are-not-shown-to-others')}
        </div>
      </div>
    </div>
  );
};

export default memo(EditProfileForm);
