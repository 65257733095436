export enum DownloadContentAsFileResult {
  Success,
  SharingNotAvailable,
  NoSpace,
  UnknownError,
}

export type DownloadContentAsFileFunction = (
  filename: string,
  content: string,
  options?: {
    fileType?: 'plain-text' | 'csv';
    charset?: 'utf-8';
  },
) => Promise<DownloadContentAsFileResult> | DownloadContentAsFileResult;
