import z from 'zod';

import { atomWithPersistence } from '@advisor/utils/atoms';
import { InvitationData, type InvitationStorageAtom } from '../types';

// Value is in local storage to maintain it even if user accidentally
// refreshes the page, or has multiple tabs open at the same time.
// Value will be moved to user.metadata when user properly signs into the application.
const invitationStorageAtom = atomWithPersistence(
  'join.invitation.data',
  z.nullable(InvitationData),
  null,
) satisfies InvitationStorageAtom;

export default invitationStorageAtom;
