import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { LanguageCode } from '@advisor/language';
import {
  UpdateChatMemberDocument,
  UpdateChatMemberMutationOptions,
} from '@advisor/api/generated/graphql';
import { actionAtom } from '@advisor/utils/atoms';
import { getMyCachedIdentity } from '@advisor/api/me';
import { atomWithMutation } from '@advisor/api/apollo';
import refetchTranslatableContentAtom from '../contentTranslation/refetchTranslatableContentAtom';
import modifyCachedChatMember, {
  ChatRoomMemberUpdatableValues,
} from './modifyCachedChatMember';

function createUpdateChatMemberOptions(
  chatRoomId: string,
  optimistic?: boolean,
): UpdateChatMemberMutationOptions {
  return {
    update(cache, _result, { variables }) {
      modifyCachedChatMember(
        cache,
        variables?.chatRoomId,
        getMyCachedIdentity(cache),
        {
          translationLanguage: variables?.translationLanguage as
            | LanguageCode
            | undefined,
          autoTranslate: variables?.autoTranslate ?? undefined,
          autoTranslateStudentJourney:
            variables?.autoTranslateStudentJourney ?? undefined,
        },
      );
    },
    optimisticResponse: optimistic
      ? () => ({
          __typename: 'Mutation',
          updateChatMember: chatRoomId,
        })
      : undefined,
  };
}

type UpdateChatMemberParams = {
  chatRoomId: string;
  values: ChatRoomMemberUpdatableValues;
  optimistic?: boolean;
};

const updateChatMemberAtom = (() => {
  const mutationAtom = atomWithMutation(UpdateChatMemberDocument);

  return actionAtom(
    async (
      { set },
      { chatRoomId, values, optimistic }: UpdateChatMemberParams,
    ) => {
      try {
        const result = set(mutationAtom, {
          variables: {
            chatRoomId,
            ...values,
          },
          ...createUpdateChatMemberOptions(chatRoomId, optimistic),
        });

        if (!!values.translationLanguage || values.autoTranslate) {
          await set(refetchTranslatableContentAtom, ['messages']);
        }

        if (
          !!values.translationLanguage ||
          values.autoTranslateStudentJourney
        ) {
          await set(refetchTranslatableContentAtom, ['task-organiser']);
        }

        return result;
      } catch (err) {
        Sentry.captureException(
          new ComplexError(
            `Failed to update chat member. chatRoomId=${chatRoomId}, values=${JSON.stringify(
              values,
            )}`,
            err,
          ),
        );
        return undefined;
      }
    },
  );
})();

export default updateChatMemberAtom;
