import React from 'react';

import { BannerView } from '@advisor/design/components/Banner';
import { JoinRequestBannerProps } from './types';
import useJoinRequestBanner from './useJoinRequestBanner';
import Content from './Content';

const JoinRequestBanner: React.FC<JoinRequestBannerProps> = ({
  joinRequest,
  chatRoomId,
}) => {
  const { onApprove, onDecline, isProcessing } = useJoinRequestBanner(
    joinRequest.id,
    chatRoomId,
  );

  return (
    <BannerView variant="primary">
      <Content
        isProcessing={isProcessing}
        onApprove={onApprove}
        onDecline={onDecline}
        requestor={joinRequest.requestor}
      />
    </BannerView>
  );
};

export default JoinRequestBanner;
