import { atom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { SearchModalState } from './types';

export const searchFormStateAtom = atom<SearchModalState | undefined>(
  undefined,
);

export const useOpenStudentSearchForm = () => {
  const setState = useSetAtom(searchFormStateAtom);

  return useCallback(
    ({ errorState }: { errorState?: boolean } = {}) => {
      if (errorState) {
        setState(SearchModalState.ERROR);
        return;
      }

      setState(SearchModalState.FORM);
    },
    [setState],
  );
};
