import cs from 'classnames';
import React, { CSSProperties, memo } from 'react';

import { useTheme } from '../../Theme';
import { SpinnerIcon } from '../Icon';
import { variantToBackground, variantToForeground } from './utils';
import type { ButtonProps } from './types';
import { Label } from './webComponents';
import Bare from './Bare';

const LargeButton = ({
  Icon,
  label,
  children,

  variant,
  outline,
  disabled,
  width = 'full',

  labelClassName,
  className,
  style,
  opacity = true,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <Bare
      {...rest}
      disabled={disabled}
      className={cs(
        'relative rounded-lg px-3 py-4 text-sm font-medium',
        !disabled &&
          `before:content-[''] before:absolute before:inset-0 before:bg-black before:opacity-0 before:rounded-lg before:transition-all`,
        disabled && 'opacity-40',
        opacity ? 'hover:before:opacity-5' : '',
        className,
        outline ? 'border bg-white' : '',
      )}
      style={{
        ...((style as CSSProperties) ?? {}),
        ...(outline
          ? {
              color: variantToBackground(theme, variant),
              borderColor: variantToBackground(theme, variant),
            }
          : {
              color: variantToForeground(variant),
              backgroundColor: variantToBackground(theme, variant),
            }),
        width: width === 'full' ? '100%' : width,
      }}
    >
      {({ loading }) =>
        loading ? (
          <SpinnerIcon className="w-6 h-6 mx-auto" />
        ) : (
          <>
            {!!Icon && <Icon />}
            {!!label && <Label className={labelClassName}>{label}</Label>}
            {children}
          </>
        )
      }
    </Bare>
  );
};

export default memo(LargeButton);
