import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';

import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import { Icon } from '@advisor/design/components';

const RoleRequestRowEmpty: React.FC = () => {
  const { t } = useTranslation('onboarding');

  return (
    <EmptyButton
      title={t('no-requests-yet')}
      description={t('your-host-approvals-will-appear-here')}
    />
  );
};

export default memo(RoleRequestRowEmpty);

type EmptyButtonProps = {
  title: string;
  description: string;
};

const EmptyButton: React.FC<EmptyButtonProps> = (props) => {
  const { title, description } = props;
  const setShowSidebar = useSetAtom(showSidebarAtom);

  return (
    <button
      type="button"
      className="rounded-10 mt-6 bg-light-grey border-grey border shadow-drop-01 p-4 flex flex-row items-center gap-3 text-start transition-colors hover:border-dark-grey-01"
      onClick={() => setShowSidebar(false)}
    >
      <div className="w-10 h-10">
        <Icon name="MessageAlt" size={40} color="primary" />
      </div>
      <div className="grow text-sm">
        <p className="text-secondary font-sora font-semibold">{title}</p>
        <p className="text-dark-grey-025">{description}</p>
      </div>
    </button>
  );
};
