import React, { useMemo } from 'react';
import cs from 'classnames';
import { useAtom } from 'jotai';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Icon, Dropdown, SimpleDropdownItem } from '@advisor/design/components';
import useUserConfig from '@advisor/api/userConfig';
import { statusFilterAtom } from '../../ChatRoomList/atoms';
import StatusDropdownItem from '../../ChatRoomList/ChatRoomListHeader/web/StatusDropdownItem';
import StatusStack from '../../ChatRoomList/ChatRoomListHeader/web/StatusStack';
import StatusModal from '../../StatusModal';
import editStatusesAtom from '../../StatusModal/editStatusesAtom';

const StatusSelect: React.FC = () => {
  const { t } = useTranslation('common');
  const [statusFilter, setStatusFilter] = useAtom(statusFilterAtom);
  const [state, dispatch] = useAtom(editStatusesAtom);
  const { statuses } = useUserConfig();

  const statusFilterName = useMemo(() => {
    if (!statusFilter) {
      return t('status');
    }

    if (statusFilter.name && statusFilter.name !== '') {
      return statusFilter.name;
    }

    return t(statusFilter.i18nKey as ResourceKey);
  }, [statusFilter, t]);

  return (
    <>
      <Dropdown.Root
        trigger={({ open }) => (
          <button
            type="button"
            className={cs(
              'flex items-center px-3 py-1 font-outfit text-sm font-semibold hover:bg-light-grey hover:text-dark-grey-025 text-dark-grey-01 rounded',
              open && 'bg-light-grey',
            )}
          >
            <StatusStack focusedStatus={statusFilter} />
            <span
              className={cs(
                'ltr:ml-2 rtl:mr-2',
                !statusFilter && 'text-dark-grey-02',
              )}
              style={
                statusFilter
                  ? { color: statusFilter.color ?? '#000000' }
                  : undefined
              }
            >
              {statusFilterName.replace(' ', '\u00A0')}
            </span>
            <Icon
              name="ChevronDown"
              className={cs(
                'ltr:ml-3 rtl:mr-3 mx-auto',
                open && 'text-dark-grey-02',
              )}
            />
          </button>
        )}
      >
        <Dropdown.Items>
          <SimpleDropdownItem
            testID="allLeadsButton"
            active={statusFilter === null}
            label={t('all-leads')}
            onPress={() => setStatusFilter(null)}
          />
          {statuses.map((status) => (
            <StatusDropdownItem
              key={status.id}
              status={status}
              active={statusFilter?.id === status.id}
              onPress={() => setStatusFilter(status)}
            />
          ))}
          <div className="h-[1px] mx-4 my-2 bg-light-grey" />
          <SimpleDropdownItem
            label={t('customise-status')}
            onPress={() => dispatch({ type: 'open' })}
          />
        </Dropdown.Items>
      </Dropdown.Root>

      <StatusModal
        visible={!!state}
        onClose={() => dispatch({ type: 'close' })}
      />
    </>
  );
};

export default StatusSelect;
