/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';

import { isOutfitApplicable } from '@advisor/design/components/Layout/Text/utils';

export function useAppropriateFontName(message: string) {
  return useMemo(() => {
    if (isOutfitApplicable(message)) {
      return 'font-outfit';
    }

    return 'font-noto';
  }, [message]);
}
