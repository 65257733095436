import React from 'react';

import { Layout } from '@advisor/design/components';
import SimpleHeading from '@advisor/chat/ConversationList/ConversationListHeader/SimpleHeading';
import AdvisorOnboardingMessage from '../../AdvisorOnboardingMessage';
import { ChatRoomRowEmpty } from '../ChatRoomRow';

const AspiringAdvisorEmptyList: React.FC = () => {
  return (
    <div className="overflow-y-auto overflow-x-visible flex flex-col gap-4 p-6 grow">
      <AdvisorOnboardingMessage />
      <div className="flex flex-row items-center">
        <Layout.Spacer.Horizontal size="micro02" />
        <SimpleHeading />
      </div>
      <ChatRoomRowEmpty />
    </div>
  );
};

export default AspiringAdvisorEmptyList;
