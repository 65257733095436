import { atom } from 'jotai';
import z, { type ZodSchema } from 'zod';
import { atomWithStorage } from 'jotai/utils';
import type {
  AsyncStorage,
  SyncStorage,
} from 'jotai/vanilla/utils/atomWithStorage';

import Sentry from '../../Sentry';
import Storage from '../../Storage';
import ComplexError from '../../ComplexError';
import type { PersistentAtom } from './types';

function parseStorageJSON(
  key: string,
  strValue: string | undefined,
  fallback: unknown,
) {
  if (strValue === undefined) {
    return fallback;
  }

  try {
    return JSON.parse(strValue);
  } catch (e) {
    Sentry.captureException(
      new ComplexError(
        `Failed to parse JSON from storage. key=${key}, val=${strValue}`,
        e,
      ),
    );
  }

  return fallback;
}

const jsonStorage = (() => {
  const storage: AsyncStorage<unknown> | SyncStorage<unknown> = {
    getItem: (key, initialValue) =>
      parseStorageJSON(key, Storage.getItem(key), initialValue),
    setItem: (key, newValue) => Storage.setItem(key, JSON.stringify(newValue)),
    removeItem: (key) => Storage.removeItem(key),
  };

  // Used for updating the atoms whenever the value changes in a different tab/via a different atom that is
  // sourcing the same Storage location.
  storage.subscribe = (key, callback, initialValue) => {
    const subscription = Storage.subscribe((updatedKey) => {
      if (updatedKey !== key) {
        return;
      }

      callback(parseStorageJSON(key, Storage.getItem(key), initialValue));
    });

    return () => subscription.unsubscribe();
  };

  return storage;
})();

/**
 * Common interface for creating atom with persistent state.
 *
 * 1) On web atom is stored in Local Storage
 * 2) On mobile atom is stored in MMKV store.
 */
function atomWithPersistence<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSchema extends ZodSchema<any>,
>(
  key: string,
  schema: TSchema,
  initialValue: z.output<TSchema>,
): PersistentAtom<TSchema> {
  // `atomWithStorage` is wrapped in an atom to refetch value from storage
  // when the provided Jotai store changes, otherwise it reverts back to
  // the value it had on app initialization.
  const jsonAtom = atom(() =>
    atomWithStorage(key, initialValue, jsonStorage, {
      getOnInit: true,
    }),
  );

  return atom(
    (get) => {
      try {
        return schema.parse(get(get(jsonAtom)));
      } catch (e) {
        Sentry.captureException(
          new ComplexError(
            `Invalid shape of value under the key '${key}' in Storage`,
            e,
          ),
        );
      }

      return initialValue;
    },
    (get, set, ...params) => set(get(jsonAtom), ...params),
  );
}

export default atomWithPersistence;
