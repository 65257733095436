import { uniqueId } from 'lodash-es';

import {
  MilestoneInfoFragment,
  MilestoneInfoFragmentDoc,
  AddMilestoneCommentMutation,
  CommentsForMilestoneDocument,
  useAddMilestoneCommentMutation,
  AddMilestoneCommentMutationOptions,
} from '@advisor/api/generated/graphql';
import { meAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { useEvent, useReadAtom } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { UploadedCommentAttachment } from './types';
import { addCommentToQuery } from './utils';

const useAddMilestoneComment = (
  chatRoomId: string,
  milestoneCategoryId: string,
  milestoneId: string,
) => {
  const [addCommentMutation] = useAddMilestoneCommentMutation();
  const readMe = useReadAtom(meAtom);

  return useEvent(
    async (comment: string, attachments?: UploadedCommentAttachment[]) => {
      const attachmentsIds = attachments?.map(
        (attachment) => attachment.file.id,
      );

      const me = await readMe();

      const optimisticResponse: AddMilestoneCommentMutation | undefined = me
        ? {
            __typename: 'Mutation',
            addMilestoneComment: {
              __typename: 'MilestoneComment',
              // Dummy id for optimistic response for milestoneComment molecules and provider to consume
              id: uniqueId('i-like-my-comments-unique-'),
              comment,
              milestoneId,
              milestoneCategoryId,
              author: me,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              attachments: attachments?.map((attachment) => attachment.file),
              detectedLanguage: 'en',
              translations: '{}',
            },
          }
        : undefined;

      try {
        await addCommentMutation({
          variables: {
            chatRoomId,
            milestoneId,
            comment,
            attachmentsIds,
          },
          optimisticResponse,
          ...updateMilestoneCommentsCache,
        });
      } catch (error) {
        Sentry.captureException(error);
        showToast({
          messageI18Key: 'oops-something-went-wrong',
          iconName: 'Warning',
          variant: 'rose',
        });
      }
    },
  );
};

const updateMilestoneCommentsCache: AddMilestoneCommentMutationOptions = {
  update: (cache, { data }) => {
    const newComment = data?.addMilestoneComment;

    if (!newComment) {
      return;
    }

    const { milestoneId } = newComment;

    // Update comments and attachments counters in MilestoneInfo
    cache.updateFragment<MilestoneInfoFragment>(
      {
        fragment: MilestoneInfoFragmentDoc,
        fragmentName: 'MilestoneInfo',
        id: cache.identify({
          __typename: 'Milestone',
          id: milestoneId,
        }),
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          commentsCount: prev.commentsCount + 1,
          attachmentsCount:
            prev.attachmentsCount + (newComment.attachments?.length ?? 0),
        };
      },
    );

    // Add comment to CommentsForMilestone
    cache.updateQuery(
      {
        query: CommentsForMilestoneDocument,
        variables: { milestoneId },
      },
      (comments) => {
        return addCommentToQuery(comments, newComment);
      },
    );
  },
};

export default useAddMilestoneComment;
