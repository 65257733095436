import cs from 'classnames';
import React, { memo } from 'react';

import { StatusBoxProps } from './types';

const StatusBox = memo(function StatusBox(props: StatusBoxProps) {
  const { width, themeColor, children, onPress } = props;

  const wrapperClassName = cs(
    'relative w-fit text-xs rounded-b-10 bg-white',
    width === 'fit-content' && 'w-fit',
    width === 'full' && 'w-full',
  );

  const content = (
    <>
      <div
        className={cs(
          'absolute inset-0 overflow-hidden opacity-50 border rounded-b-10',
        )}
        style={{ borderColor: themeColor }}
      >
        <div
          className="absolute inset-0 opacity-30"
          style={{ backgroundColor: themeColor }}
        />
      </div>
      <span className="relative">{children}</span>
    </>
  );

  if (onPress) {
    return (
      <button type="button" onClick={onPress} className={wrapperClassName}>
        {content}
      </button>
    );
  }

  return <div className={wrapperClassName}>{content}</div>;
});

export default StatusBox;
