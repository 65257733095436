import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import { showToast } from '@advisor/design/components/Toast';
import { useMicrobot } from '../../../hooks';
import chatRoomStatusAtom from '../atoms/chatRoomStatus';
import { ConfirmProps } from './types';

const Confirm: React.FC<ConfirmProps> = ({ onClose, onSave, personality }) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { name, communityTitle } = useMicrobot(personality, 'required');

  const statusState = useAtomValue(chatRoomStatusAtom);

  const onConfirm = useCallback(async () => {
    await onSave(statusState, true);
    onClose();
    showToast({
      iconName: 'X',
      variant: 'rose',
      messageI18Key: 'name-was-successfully-removed',
      values: {
        name,
      },
    });
  }, [name, onClose, onSave, statusState]);

  return (
    <div className="p-8">
      {/* Title */}
      <h3 className="font-sora text-sm leading-[1.125rem] text-dark-grey-03 font-bold text-center">
        {t(
          'microbot:are-you-sure-you-want-to-remove-name-title-from-selected',
          {
            name,
            title: t(communityTitle),
          },
        )}
      </h3>
      <Layout.Spacer.Vertical size="micro02" />

      <div className="px-2">
        {/* Message */}
        <p className="font-outfit text-sm font-medium leading-[1.125rem] text-dark-grey-02 text-center">
          <Trans
            t={t}
            i18nKey="microbot:by-confirming-you-will-remove-name-from-selected-customer-conversations"
            components={{ bold: <b /> }}
            values={{ name }}
          />
        </p>
        <Layout.Spacer.Vertical size="small01" />

        {/* Action buttons */}
        <DeprecatedButton.Large
          className="text-sm w-full py-4 shadow-drop-01 leading-[0.875rem]"
          label={t('common:remove')}
          onPress={onConfirm}
          variant="negative"
        />
        <Layout.Spacer.Vertical size="tiny" />
        <DeprecatedButton.Outline
          className="text-sm w-full py-4 shadow-drop-01 leading-[0.875rem]"
          label={t('common:cancel')}
          onPress={onClose}
          variant="darkGrey03"
        />
      </div>
    </div>
  );
};

export default Confirm;
