import getEnv from './shared';

export * from './types';

interface ImportMetaEnv {
  readonly DEV?: boolean;
}

// Workaround for app and web typescript not picking up
// declaration files from api or api/env directories
// @ts-ignore
const MetaEnv = import.meta.env as unknown as ImportMetaEnv;

const env = getEnv(process.env.instanceConfig, {
  DEBUG: MetaEnv.DEV ?? false,
});

export default env;
