import AddMilestoneModal from './AddMilestoneModal';
import EditMilestoneModal from './EditMilestoneModal';

export { milestoneEditStateAtom, milestoneAddStateAtom } from './atoms';
export { default as useOpenEditMilestoneModal } from './useOpenEditMilestoneModal';

export default {
  Add: AddMilestoneModal,
  Edit: EditMilestoneModal,
};
