import './main.css';
import {
  matchRoutes,
  useLocation,
  BrowserRouter,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';
import { Provider } from 'jotai';
import * as SentryReact from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { HelmetProvider } from 'react-helmet-async';
import React, { Suspense, useSyncExternalStore } from 'react';

import Env from '@advisor/api/env';
import jotaiStore from '@advisor/api/jotaiStore';
import { SignedCookieFetcher } from '@advisor/api/auth';
import useMaintenanceScreens from '@advisor/api/hooks/useMaintenanceScreens';
import Sentry from '@advisor/utils/Sentry';
import { VideoRoomProvider } from '@advisor/videoCall';
import OnboardingEventProvider from '@advisor/api/onboardingEvent/OnboardingEventProvider';
import { ProactiveInvitationTransfer } from '@advisor/onboarding';
import { LanguageProvider } from '@advisor/language';
import { ThemeProvider } from '@advisor/design/Theme';
import { ActionModal } from '@advisor/design/components';
import { NotificationBannerProvider } from '@advisor/design/components/Banner';
import { AuthProvider, AuthorizedApolloProvider } from './context/auth';
import RouteChatRoomProvider from './context/RouteChatRoomProvider';
import PushNotifications from './components/PushNotifications';
import GoogleAnalyticsTag from './GoogleAnalyticsTag';
import AppMaintenance from './AppMaintenance';
import FontProvider from './FontProvider';
import MainRoutes from './MainRoutes';
import AppLoader from './AppLoader';
import i18n from './i18n';

if (Env.sentryReporting.frontendDsnUrl) {
  const tracing = new BrowserTracing({
    routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  });

  Sentry.init(SentryReact, Env.sentryReporting.frontendDsnUrl, [tracing]);
}

function App() {
  // Syncing as high in the tree as possible, since hooks in `AppInner` use atoms.
  const store = useSyncExternalStore(jotaiStore.subscribe, jotaiStore.getStore);

  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <AppInner />
      </Suspense>
    </Provider>
  );
}

function AppInner() {
  const { showMaintenanceScreen } = useMaintenanceScreens();

  if (showMaintenanceScreen) {
    return (
      <HelmetProvider>
        <AuthorizedApolloProvider>
          <ThemeProvider>
            <AppMaintenance />
          </ThemeProvider>
        </AuthorizedApolloProvider>
      </HelmetProvider>
    );
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <GoogleAnalyticsTag />
        <SentryReact.ErrorBoundary>
          <ThemeProvider>
            <Suspense fallback={<AppLoader />}>
              <AuthorizedApolloProvider>
                <BrowserRouter>
                  <LanguageProvider i18n={i18n}>
                    <FontProvider />
                    <RouteChatRoomProvider>
                      <VideoRoomProvider>
                        <NotificationBannerProvider>
                          <MainRoutes />
                          <PushNotifications />
                          <ActionModal.Decision />
                          <ActionModal.Info />
                          <OnboardingEventProvider />
                        </NotificationBannerProvider>
                      </VideoRoomProvider>
                    </RouteChatRoomProvider>
                  </LanguageProvider>
                </BrowserRouter>
                <ProactiveInvitationTransfer />
                <SignedCookieFetcher />
              </AuthorizedApolloProvider>
            </Suspense>
          </ThemeProvider>
        </SentryReact.ErrorBoundary>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
