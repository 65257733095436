import z, { ZodSchema } from 'zod';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { ContentTranslationStatuses, RawTranslatable } from './types';

/**
 * Parses a raw GraphQL object with translation properties into
 * useful and typed data.
 * @param schema The shape of the expected translations field after JSON parsing.
 * @param raw The raw GraphQL object
 * @param myId The ID of the currently logged-in user.
 */
export default function parseTranslatedContent<T extends ZodSchema>(
  schema: T,
  raw: RawTranslatable,
  myId: string,
) {
  let translations: z.infer<T> = {};
  let statuses: ContentTranslationStatuses = {};

  try {
    translations = schema.parse(JSON.parse(raw.translations));
    statuses = raw.translationStatus
      ? ContentTranslationStatuses.parse(JSON.parse(raw.translationStatus))
      : {};
  } catch (e) {
    Sentry.captureException(
      new ComplexError(
        `Failed to parse translations for ${raw.__typename} id:${raw.id}`,
        e,
      ),
    );

    // Keeping the content raw, as to not flood the UI with translation errors.
    statuses[myId] = 'ORIGINAL';
  }

  return { translations, statuses };
}
