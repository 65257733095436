import { atom } from 'jotai';

import { lazyAtom, makeLifetime, weakAtomFamily } from '@advisor/utils/atoms';
import { nonNullable } from '@advisor/utils/typeUtils';
import { isLazy } from '@advisor/utils/atoms/lazyFrom';
import { ScopeAtom } from './types';

const availableAtom = atom(() => true);

const unavailableAtom = atom(() => false);

const everyAtom = (atoms: ScopeAtom[]) => {
  const lifetimes = atoms
    .map((a) => (isLazy(a) ? a.lifetime : undefined))
    .filter(nonNullable);

  return lazyAtom(async (get) => {
    const values = await Promise.all(atoms.map(get));
    return values.every(Boolean);
  }, makeLifetime(...lifetimes));
};

const someAtom = (atoms: ScopeAtom[]) => {
  const lifetimes = atoms
    .map((a) => (isLazy(a) ? a.lifetime : undefined))
    .filter(nonNullable);

  return lazyAtom(async (get) => {
    const values = await Promise.all(atoms.map(get));
    return values.some(Boolean);
  }, makeLifetime(...lifetimes));
};

const notAtom = weakAtomFamily((inAtom: ScopeAtom) => {
  const lifetime = isLazy(inAtom) ? inAtom.lifetime : undefined;

  return lazyAtom(async (get) => !(await get(inAtom)), lifetime);
});

export default {
  available: availableAtom,
  unavailable: unavailableAtom,
  every: everyAtom,
  some: someAtom,
  not: notAtom,
};
