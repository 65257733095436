import { molecule } from 'bunshi';

import { nullAtom } from '@advisor/utils/atoms';
import {
  MilestoneCategoryScope,
  milestoneCategoryAtoms,
} from './milestoneCategory';
import { milestonesForCategoryAtoms } from './milestone';

const MilestoneCategoryMolecule = molecule((_mol, scope) => {
  const categoryId = scope(MilestoneCategoryScope);

  if (!categoryId) {
    return {
      milestoneCategoryAtom: nullAtom,
      milestonesForCategoryAtom: nullAtom,
    };
  }

  const milestoneCategoryAtom = milestoneCategoryAtoms(categoryId);
  const milestonesForCategoryAtom = milestonesForCategoryAtoms(categoryId);

  return {
    milestoneCategoryAtom,
    milestonesForCategoryAtom,
  };
});

export default MilestoneCategoryMolecule;
