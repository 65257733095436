import cs from 'classnames';
import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout, Modal } from '@advisor/design/components';
import withSeparators from '@advisor/utils/withSeparators';
import SnapList from '@advisor/design/components/SnapList';
import { Screenshot } from '@advisor/microbots/hooks/useMicrobot';
import { Spacing } from '@advisor/design/styles';

interface GalleryItemProps {
  idx: number;
  item: Screenshot;
  backgroundColor?: string;
  onSelectIdx: (idx: number) => void;
}

interface GalleryModalProps {
  visible: boolean;
  items: Screenshot[];
  selectedIdx: number;
  onClose: () => void;
  backgroundColor?: string;
  onSelectIdx: (idx: number) => void;
}

interface GalleryProps {
  backgroundColor?: string;
  items: Screenshot[];
}

const GalleryItem: React.FC<GalleryItemProps> = (props) => {
  const { item, idx, onSelectIdx, backgroundColor } = props;
  const { t } = useTranslation(['common', 'microbot']);

  const onClick = () => {
    onSelectIdx(idx);
  };

  return (
    <SnapList.Item key={item.url} index={idx}>
      <button
        type="button"
        onClick={onClick}
        className="rounded-md flex items-center flex-col justify-center w-[150px] h-[150px]"
        style={{ backgroundColor }}
      >
        <img src={item.url} alt={t(item.title)} className="h-2/3" />
        {!!item.title && (
          <span
            style={{ fontSize: '8px' }}
            className="mt-2 font-medium font-outfit"
          >
            {t(item.title)}
          </span>
        )}
      </button>
    </SnapList.Item>
  );
};

const GalleryModal: React.FC<GalleryModalProps> = (props) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { visible, items, selectedIdx, onClose, onSelectIdx, backgroundColor } =
    props;

  return (
    <Modal.Bare visible={visible} onClose={onClose}>
      <div
        className="w-full h-full z-30 fixed inset-0 py-6 flex flex-col xl:w-[750px] xl:h-[750px] xl:m-auto xl:rounded-xl"
        style={{ backgroundColor }}
      >
        <div className="flex flex-row px-6 xl:flex-row-reverse">
          <DeprecatedButton.Icon
            icon="X"
            onPress={onClose}
            variant="darkGrey03"
            size={Spacing.small01}
          />
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <SnapList.Root
          scrollTo={onSelectIdx}
          className="flex-1 w-full"
          activeIndex={selectedIdx}
        >
          {items.map((item, idx) => (
            <SnapList.Item
              key={item.url}
              index={idx}
              className="snap-center xl:snap-start"
            >
              <div className="h-full w-screen xl:w-[750px]">
                <img
                  src={item.url}
                  alt={t(item.title)}
                  className="w-full h-full object-contain select-none"
                  style={{
                    // @ts-ignore-next-line
                    WebkitUserDrag: 'none',
                    khtmlUserDrag: 'none',
                    MozUserDrag: 'none',
                    OUserDrag: 'none',
                    userDrag: 'none',
                  }}
                />
              </div>
            </SnapList.Item>
          ))}
        </SnapList.Root>
        <Layout.Spacer.Vertical size="small01" />
        <div className="text-center font-sora text-base font-semibold relative h-6 text-black">
          {items.map((item, idx) => (
            <Transition
              key={item.url}
              enter="transition-opacity ease-linear duration-300 delay-300 absolute left-0 right-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300 absolute left-0 right-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={idx === selectedIdx}
            >
              {t(item.title)}
            </Transition>
          ))}
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <div className="flex flex-row justify-center">
          {items.map((item, idx) => (
            <button
              type="button"
              onClick={() => onSelectIdx(idx)}
              className="p-[2px]"
              key={item.url}
            >
              <span
                className={cs(
                  'block w-1.5 h-1.5 rounded-full',
                  idx === selectedIdx ? 'bg-dark-grey-03' : 'bg-dark-grey-01',
                )}
              />
            </button>
          ))}
        </div>
      </div>
    </Modal.Bare>
  );
};

const Gallery: React.FC<GalleryProps> = ({ items, backgroundColor }) => {
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const selectItem = (itemIdx: number) => {
    setSelectedIdx(itemIdx);
    setShowModal(true);
  };

  const renderSeparator = (key: number) => (
    <Layout.Spacer.Horizontal key={`separator-${key}`} size="micro02" />
  );

  const renderItem = (item: Screenshot, idx: number) => (
    <GalleryItem
      idx={idx}
      item={item}
      key={item.url}
      onSelectIdx={selectItem}
      backgroundColor={backgroundColor}
    />
  );

  return (
    <>
      <Layout.Spacer.Vertical size="micro02" />
      <SnapList.Root>
        {withSeparators(items, renderSeparator, renderItem)}
      </SnapList.Root>
      <GalleryModal
        visible={showModal}
        items={items}
        selectedIdx={selectedIdx}
        onSelectIdx={setSelectedIdx}
        onClose={() => setShowModal(false)}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

export default Gallery;
