import cs from 'classnames';
import React, { memo } from 'react';
import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';

import { useUserMetadata } from '@advisor/api/userMetadata';
import { Icon } from '@advisor/design/components';
import Tooltip from '@advisor/design/components/Tooltip';
import {
  MessageInputMolecule,
  useRequestSuggestion,
} from '../../hooks/MessageInput';
import { RefreshSuggestionTooltipLocation } from '../metadata';
import ChatIconButton from './ChatIconButton';

interface Props {
  className?: string;
}

const SuggestionBar = ({ className }: Props) => {
  const { t } = useTranslation('microbot');

  const [hideTooltip, setHideTooltip] = useUserMetadata(
    RefreshSuggestionTooltipLocation,
  );

  const { isSuggestionReloadDisabledAtom } = useMolecule(MessageInputMolecule);
  const requestSuggestion = useRequestSuggestion();

  const isReloadDisabled = useAtomValue(isSuggestionReloadDisabledAtom);

  return (
    <div
      className={cs(
        'flex flex-row flex-nowrap items-center min-w-0',
        className,
      )}
    >
      <div className="relative mr-4">
        <Tooltip.Root>
          <ChatIconButton
            testID="reloadGPTMessage"
            iconName="Reload"
            disabled={isReloadDisabled}
            onPress={requestSuggestion}
            small
          />
          <Tooltip.SimpleContent
            visible={!hideTooltip}
            testID="confirmTooltipButton"
            className="max-w-xs"
            variant="dark"
            title={
              <span className="text-rose-secondary whitespace-pre-line">
                {t('edit-the-suggestion')}
              </span>
            }
            description={t('hit-retry-button-to-receive-suggestion')}
            onClose={() => {
              setHideTooltip(true);
            }}
          />
        </Tooltip.Root>
      </div>
      <p className="text-dark-grey-01 font-outfit text-sm leading-4 mr-1 truncate">
        {t('suggested-by-alpha-bot')}
      </p>
      <Icon name="Wand" className="[&_path]:fill-dark-grey-01 h-3.5" />
    </div>
  );
};

export default memo(SuggestionBar);
