import React, { Fragment } from 'react';

import { MessageTextProps, MessageTextSegmentWeight } from './types';

function MessageText({ children }: MessageTextProps) {
  if (typeof children === 'string') {
    return children;
  }

  return children.map(({ value, weight }, index) =>
    weight === MessageTextSegmentWeight.Bold ? (
      // eslint-disable-next-line react/no-array-index-key
      <strong key={`${index} ${value}`}>{value}</strong>
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${index} ${value}`}>{value}</Fragment>
    ),
  );
}

export default MessageText;
