import React from 'react';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { DeprecatedButton, Modal } from '@advisor/design/components';
import { MemoryRetrievalType } from '@advisor/api/generated/graphqlTypes';
import { AddMicrobotMemoryMolecule, ModalStep } from '../../api';
import RetrievalOption from './RetrievalOption';

const ChooseMemoryRetrievalStep: React.FC = () => {
  const { t } = useTranslation(['common', 'microbot']);
  const { modalStepAtom, retrievalTypeAtom, advanceAtom, closeEditorAtom } =
    useMolecule(AddMicrobotMemoryMolecule);

  const advance = useSetAtom(advanceAtom);
  const closeEditor = useSetAtom(closeEditorAtom);
  const modalStep = useAtomValue(modalStepAtom);
  const [retrieval, setRetrieval] = useAtom(retrievalTypeAtom);

  const handleSelectAndSave = useEvent(async () => {
    await advance();
  });

  return (
    <Modal.Base
      position="floating"
      maxWidth={406}
      visible={modalStep === ModalStep.ChooseRetrieval}
      onClose={closeEditor}
    >
      <Modal.PopUpHeader
        title={t('microbot:youre-about-to-save-a-url')}
        message={t(
          'microbot:please-select-how-you-want-to-keep-its-content-updated',
        )}
        onClose={closeEditor}
      />
      <div className="flex flex-col px-4 gap-4">
        <RetrievalOption
          label={t('microbot:retrieve-once')}
          description={t('microbot:retrieve-the-data-from-the-url-just-once')}
          checked={retrieval === MemoryRetrievalType.Once}
          onChecked={() => setRetrieval(MemoryRetrievalType.Once)}
        />
        <RetrievalOption
          label={t('microbot:refresh-continuously')}
          description={t(
            'microbot:automatically-refreshes-the-link-daily-for-real-time-updates',
          )}
          checked={retrieval === MemoryRetrievalType.Continuous}
          onChecked={() => setRetrieval(MemoryRetrievalType.Continuous)}
        />
      </div>
      <div className="flex justify-center pt-6 pb-8 px-4">
        <DeprecatedButton.Large variant="primary" onPress={handleSelectAndSave}>
          {t('common:select-and-save')}
        </DeprecatedButton.Large>
      </div>
    </Modal.Base>
  );
};

export default ChooseMemoryRetrievalStep;
