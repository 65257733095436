import { LoginType } from '@advisor/api/auth';
import { atom } from 'jotai';

interface IdentityAtomType {
  type: LoginType;
  identity: string;
}

const identityAtom = atom<IdentityAtomType>({
  type: LoginType.PhoneNumber,
  identity: '',
});

export default identityAtom;
