import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { FileDropAreaProps } from './types';

const FileDropArea = ({
  children,
  className,
  onFileDropped,
}: FileDropAreaProps) => {
  const { t } = useTranslation('common');
  const [isFileDragged, setIsFileDragged] = React.useState(false);

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      Array.from(event.dataTransfer.files).forEach(onFileDropped);
    },
    [onFileDropped],
  );

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      const { dataTransfer } = event;
      const draggingFiles = dataTransfer?.types.includes('Files');
      if (draggingFiles) {
        setIsFileDragged(true);
      }
    };
    const handleDragLeave = () => {
      setIsFileDragged(false);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDragLeave);
    };
  }, []);

  return (
    <div
      className={cs('relative', className)}
      onDrop={handleFileDrop}
      onDragOver={handleFileDrop}
    >
      {children}
      <div
        className={cs(
          'absolute inset-0 bg-light-grey/80 grid place-items-center',
          'pointer-events-none transition-opacity',
          isFileDragged ? 'opacity-100' : 'opacity-0',
        )}
      >
        <p className="font-sora font-bold text-lg text-primary text-center">
          {t('drop-files-here')}
        </p>
      </div>
    </div>
  );
};

export default FileDropArea;
