import cs from 'classnames';
import React, { memo } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { useEvent, useEnterPress } from '@advisor/utils/hooks';
import { DeprecatedButton } from '@advisor/design/components';
import { PickedAttachment } from '@advisor/design/components/FileAttachment';
import { FileDropArea, FilePicker } from '@advisor/ui/components/FileUpload';
import { CommentInputProps } from './types';
import useCommentInput from './useCommentInput';
import { AcceptString } from './utils';

const CommentInput: React.FC<CommentInputProps> = (props) => {
  const { t } = useTranslation('common');
  const {
    message,
    canSubmit,
    attachments,
    attachmentsUploaded,

    setMessage,
    handleSubmit,
    handleAddAttachment,
    handleRemoveAttachment,
  } = useCommentInput(props);

  const showAttachments = attachments.length > 0;

  const onChange = useEvent((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  });

  const keyboardEventHandlers = useEnterPress(handleSubmit);

  return (
    <div className="pb-4 px-6">
      <FileDropArea
        className="rounded border-grey border overflow-hidden"
        onFileDropped={handleAddAttachment}
      >
        <textarea
          data-cy="commentInput"
          placeholder={t('place-your-comment-here')}
          spellCheck
          value={message}
          onChange={onChange}
          className={cs(
            showAttachments ? 'h-16 -mb-3' : 'h-24',
            'block rounded w-full p-4',
            'resize-none focus:outline-none',
            'font-outfit font-medium text-sm',
            'ltr:text-left rtl:text-right ltr:pr-14 rtl:pl-14',
          )}
          {...keyboardEventHandlers}
        />
        <div className="flex flex-row w-full" data-cy="dropAreaAttachment">
          <div className="px-4 pb-6 pt-3 flex-1 min-w-0 flex flex-row gap-4 overflow-x-auto overflow-y-hidden">
            {attachments.map((attachment) => (
              <PickedAttachment
                key={attachment.uploadId}
                attachment={attachment}
                onRemove={() => handleRemoveAttachment(attachment)}
              />
            ))}
          </div>
          <FilePicker accept={AcceptString} onFilePicked={handleAddAttachment}>
            {(onPress) => (
              <div className="flex-col justify-end px-4 pb-3">
                <DeprecatedButton.AddAttachment
                  variant="rounded"
                  onPress={onPress}
                />
              </div>
            )}
          </FilePicker>
        </div>
        {/* Submit button */}
        <Transition
          show={canSubmit}
          enterFrom="opacity-0 translate-y-1/2"
          enterTo="opacity-100 translate-y-0"
          enter="transition-[opacity,transform]"
          leave="transition-[opacity,transform]"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1/2"
        >
          <div className="px-4 pb-4 flex">
            <DeprecatedButton.Large
              label={t('submit')}
              variant="primary"
              className="flex-1"
              disabled={!canSubmit || !attachmentsUploaded}
              onPress={handleSubmit}
              testID="attachmentConfirmButton"
            />
          </div>
        </Transition>
      </FileDropArea>
    </div>
  );
};

export default memo(CommentInput);
