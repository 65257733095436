import cs from 'classnames';
import { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';

import callCodes, {
  CallCode,
} from '@advisor/utils/country/internationalDiallingCodes';
import { Icon } from '@advisor/design/components';
import Flag from '../atoms/Flag';

interface CallCodePickerProps {
  callCode: CallCode;
  onCodeChange: (code: CallCode) => void;
  labelClassName?: string;
}

function CallCodePicker({
  callCode,
  onCodeChange,
  labelClassName,
}: CallCodePickerProps) {
  const { t } = useTranslation('countries');

  return (
    <Listbox value={callCode} onChange={onCodeChange}>
      <Listbox.Button
        data-cy="numberCountryPicker"
        className="w-[116px] shrink-0 flex flex-row justify-between items-center pl-5 pr-4 separated rounded hover:bg-off-white focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75"
        aria-label="Select your phone country code"
      >
        {({ open }) => (
          <>
            <Flag country={callCode.name} />
            <span className={cs('font-medium', labelClassName)}>
              {callCode.dial_code}
            </span>
            <Icon
              name="ChevronDown"
              className={cs(
                'transition-transform',
                open ? 'rotate-180' : 'rotate-0',
              )}
            />
          </>
        )}
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition-opacity ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          className="absolute top-11 w-60 h-72 z-10 py-3 bg-white shadow-drop-01 rounded-10 focus:outline-none"
          as="div"
        >
          <ul className="overflow-y-scroll snap-y h-full text-xs">
            {callCodes.map((code) => (
              <Listbox.Option
                data-cy={`numberCountryPicker${code.code}`}
                key={code.code}
                value={code}
                className={({ active }) =>
                  cs(
                    'flex flex-row items-center gap-4 py-2 snap-start rounded px-5',
                    active && 'bg-off-white',
                  )
                }
              >
                <Flag country={code.name} />
                <span className="grow text-dark-grey-02 global-rtl:text-right">
                  {t(code.name)}
                </span>
                <span className="text-grey ">
                  {code.dial_code.substring(1)}
                </span>
              </Listbox.Option>
            ))}
          </ul>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}

export default memo(CallCodePicker);
