import { produce } from 'immer';

import {
  CommentsForMilestoneQuery,
  MilestoneCommentInfoFragment,
} from '@advisor/api/generated/graphql';

/**
 * @see {@link backend/lambda/utils/mime_types.py CHAT_ROOM_ALLOWED_FILE_TYPES}
 *
 * We need to use actual mime types instead of file extensions for native file picker.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types}
 */
export const SupportedFileTypes = [
  'application/zip', // '.zip',
  'application/pdf', // '.pdf',
  'application/msword', // '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // '.docx',
  'application/vnd.ms-excel', // '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // '.xlsx',
  'text/plain', // '.txt',
  'image/png', // '.png',
  'image/jpeg', // '.jpeg',
  'image/heif', // '.heif',
  'image/avif', // '.avif',
  'image/webp', // '.webp',
  'image/gif', // '.gif',
  'video/mp4', // '.mp4',
  'video/quicktime', // '.mov',
  'video/mp4; codecs="hev1.1.6.L93.B0"', // '.hevc',
  'video/mp4; codecs="hev1.2.4.L120.B0"', // '.hevc',
  'audio/x-ms-wma', // '.asf',
  'video/x-msvideo', // '.avi',
];

export const AcceptString = SupportedFileTypes.join(',');

export const ChatRoomFileSizeThresholdMB = 500;

export function createCommentCursor(node: MilestoneCommentInfoFragment) {
  return JSON.stringify({
    pk: `milestone_comment_${node.milestoneId}`,
    sk: node.createdAt,
  });
}

export function addCommentToQuery(
  query: CommentsForMilestoneQuery | null,
  newComment: MilestoneCommentInfoFragment,
) {
  return produce(query, (draft) => {
    const cursor = createCommentCursor(newComment);

    // If the comment is already in the list, we don't add it again.
    // If there are more comments to fetch, we don't add the new comment
    // because it will be fetched when the user scrolls down.
    if (
      !draft ||
      draft.commentsForMilestone.pageInfo.hasNextPage ||
      draft.commentsForMilestone.edges.some((e) => e.cursor === cursor)
    ) {
      return;
    }

    const { edges, pageInfo } = draft.commentsForMilestone;

    edges.push({
      __typename: 'MilestoneCommentEdge' as const,
      cursor,
      node: newComment,
    });

    pageInfo.startCursor = edges[0]?.cursor ?? null;
    pageInfo.endCursor = edges[edges.length - 1]?.cursor ?? null;
  });
}

export function removeCommentFromQuery(
  query: CommentsForMilestoneQuery | null,
  comment: MilestoneCommentInfoFragment,
) {
  return produce(query, (draft) => {
    if (!draft) {
      return;
    }

    const cursor = createCommentCursor(comment);

    const { commentsForMilestone } = draft;

    commentsForMilestone.edges = commentsForMilestone.edges.filter(
      (edge) => edge.cursor !== cursor,
    );

    const { edges, pageInfo } = commentsForMilestone;

    pageInfo.startCursor = edges[0]?.cursor ?? null;
    pageInfo.endCursor = edges[edges.length - 1]?.cursor ?? null;
  });
}
