import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { useTheme } from '@advisor/design/Theme';
import { Colors } from '@advisor/design/styles';
import BasicLayout from 'src/components/layouts/BasicLayout';
import Navbar from 'src/components/organisms/NavBar';
import StartMyJourneyButton from './StartMyJourneyButton';
import DefaultHomePage from './DefaultHomePage';
import Footer from './Footer';
import HomePageRedirect from './HomePageRedirect';

const HomePage: React.FC = () => {
  const { t } = useTranslation('global-study-homepage');
  const theme = useTheme();

  if (!theme.organizationLogo) {
    return <DefaultHomePage />;
  }

  return (
    <HomePageRedirect>
      <BasicLayout className="bg-off-white">
        <Helmet>
          <style type="text/css">{`html { background-color: ${Colors.darkGrey05}; }`}</style>
        </Helmet>
        <Navbar variant="dark" />
        <div className="xl:flex xl:flex-row">
          <div className="flex-1">
            <main className="md:w-[34rem] mx-8 md:mx-[7rem] z-10 relative pt-[3.75rem] md:pt-[11rem]">
              <h1 className="text-primary m-0 text-[2rem] leading-[2.5rem] font-bold font-sora md:text-[4rem] md:leading-[4.75rem]">
                Student Advisor
              </h1>
              <p className="font-sora font-medium text-base text-dark-grey-05 my-4 md:text-2xl md:my-8">
                {t('welcome-to-student-advisor-the-most-powerful-ai-powered')}
              </p>
              <StartMyJourneyButton />
            </main>
          </div>
          <div className="flex flex-row justify-end flex-1">
            <div className="pl-8 pt-[160px] mb-[-84px] flex justify-end lg:w-[80%] xl:w-[100%] xl:mb-4">
              <div className="relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 z-0 flex items-center justify-center">
                  <div
                    className="w-[80%] aspect-square rounded-full"
                    style={{ backgroundColor: `${theme.colors.primary}20` }}
                  />
                </div>
                <img
                  alt=""
                  srcSet="/images/homepage/iphone-overlays-075.png 300w, /images/homepage/iphone-overlays-100.png 600w, /images/homepage/iphone-overlays-200.png 900w"
                  sizes="(max-width: 639px) 300w, (max-width: 1023px) 600w, 900w"
                  className="pointer-none relative z-1 sharp-image max-w-[1324px] w-full h-full"
                  src="/images/homepage/iphone-overlays-100.png"
                />
              </div>
            </div>
            <div className="md:h-[550px]" />
          </div>
        </div>
        <Footer />
      </BasicLayout>
    </HomePageRedirect>
  );
};

export default HomePage;
