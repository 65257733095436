import { useCallback, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';

import {
  ChatRoomInfoFragment,
  useChatRoomsByTypenameQuery,
} from '../generated/graphql';

const useChatRoomsByTypename = (
  typename: ChatRoomInfoFragment['__typename'],
  nameContains?: string,
) => {
  const { data, loading, networkStatus, fetchMore } =
    useChatRoomsByTypenameQuery({
      variables: {
        typename,
        nameContains,
      },
    });

  const { pageInfo } = data?.chatRooms ?? {};

  const chatRooms = useMemo(
    () => (data?.chatRooms.edges ?? []).map(({ node }) => node),
    [data?.chatRooms.edges],
  );

  const loadMore = useCallback(() => {
    if (
      !loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
          nameContains,
        },
      });
    }
  }, [loading, networkStatus, pageInfo, nameContains, fetchMore]);

  return { chatRooms, loading, hasMore: !!pageInfo?.hasNextPage, loadMore };
};

export default useChatRoomsByTypename;
