import { ReactNode } from 'react';
import { ViewStyle } from 'react-native';

export type TooltipVariant =
  | 'dark'
  | 'light'
  | 'primary'
  | 'secondary'
  | 'positive'
  | 'negative'
  | 'neutral';

export interface TooltipRootProps {
  children: ReactNode;
  className?: string;
  style?: ViewStyle;
}

/**
 * Specifies the position of a tooltip from root.
 */
export enum TooltipPosition {
  Above = 'above',
  Below = 'below',
}

interface ContentProps {
  visible?: boolean;
  variant?: TooltipVariant;
  className?: string;
  position?: TooltipPosition;
  showBackdrop?: boolean;
  screenMargin?: number;
  maxWidth?: number;
  onClose?: () => void;
  inverted?: boolean;
}

export type TooltipContentProps = ContentProps & {
  children: ReactNode;
  elevate?: ReactNode;
};

export interface Box {
  width: number;
  height: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface TooltipContextType {
  rootDimensions: Rect;
}

export interface ElevateProps {
  children: ReactNode;
  /* Web only */
  containerBounds?: Rect;
}

export type SimpleTooltipContentProps = ContentProps & {
  title?: string | ReactNode;
  testID?: string;
  description?: string | ReactNode;
  confirmButtonText?: string;
};

export interface UseTooltipContentProps {
  root: Rect;
  screen: Rect;
  tooltip: Box;
  position: TooltipPosition;
}
