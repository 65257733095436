import { unwrap } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MeQuery,
  IdentityType,
  useOauthTokenMutation,
  usePasswordlessStartMutation,
} from '@advisor/api/generated/graphql';
import { meAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { useEvent, useReadAtom } from '@advisor/utils/hooks';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { parseGQLError } from '@advisor/utils/errorParsers';
import { useLanguage } from '@advisor/language';
import { VerifyError } from '../CreateProfileForm/types';
import useUpdateUserIdentity from './useUpdateUserIdentity';
import { VerificationState } from './types';

function getCredentialValueFromQuery(
  me: MeQuery['me'],
  type: IdentityType,
): string {
  if (type === IdentityType.PhoneNumber) {
    return me?.phoneNumber ?? '';
  }

  return me?.email ?? '';
}

function getVerificationState(
  me: MeQuery['me'],
  type: IdentityType,
): VerificationState {
  const isVerified =
    type === IdentityType.PhoneNumber
      ? me?.isPhoneNumberVerified
      : me?.isEmailVerified;

  return isVerified ? VerificationState.Verified : VerificationState.Unverified;
}

function parseCredentialValue(value: string, type: IdentityType): string {
  if (type === IdentityType.PhoneNumber) {
    return value.replace(/\s/g, '');
  }

  return value.toLocaleLowerCase();
}

const isPhoneVerifiedAtom = atom((get) => {
  const me = get(unwrap(meAtom));

  return me?.isPhoneNumberVerified
    ? VerificationState.Verified
    : VerificationState.Unverified;
});

const isEmailVerifiedAtom = atom((get) => {
  const me = get(unwrap(meAtom));

  return me?.isEmailVerified
    ? VerificationState.Verified
    : VerificationState.Unverified;
});

export default function useCredentialForm(identityType: IdentityType) {
  const readMe = useReadAtom(unwrap(meAtom));
  const { t } = useTranslation(['common', 'onboarding']);
  const { uiLanguage } = useLanguage();

  const showModal = useShowModal();
  const [oauthTokenMutation] = useOauthTokenMutation();
  const [updateUserIdentity] = useUpdateUserIdentity();
  const [passwordlessStartMutation] = usePasswordlessStartMutation();

  const [value, setValue] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
  const [verificationState, setVerificationState] = useState(
    VerificationState.Unverified,
  );

  const onHideVerifyModal = useEvent(() => {
    setShowVerifyModal(false);
  });

  const onChangeValue = useEvent((newValue: string) => {
    setValue(newValue);
    setVerificationState(VerificationState.Unverified);
  });

  const onUpdateIdentity = useEvent(
    async (credential: string, isVerified: boolean, linkWith?: string) => {
      if (identityType === IdentityType.Email) {
        return updateUserIdentity({
          variables: {
            isEmailVerified: isVerified,
            email: credential,
            linkWith,
          },
        });
      }

      return updateUserIdentity({
        variables: {
          isPhoneNumberVerified: isVerified,
          phoneNumber: credential,
          linkWith,
        },
      });
    },
  );

  const onVerify = useEvent(async (newValue: string) => {
    const credential = parseCredentialValue(newValue, identityType);

    try {
      // Make sure the identity is saved in user profile before
      // starting the verification. Auth0 could create
      // a new account which couldn't be linked with the existing
      // profile otherwise.
      // Can happen when user will click on verify button without
      // deselecting the text input
      await onUpdateIdentity(credential, false);

      await passwordlessStartMutation({
        variables: {
          identityType,
          credential,
          language: uiLanguage,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }

    setShowVerifyModal(true);
  });

  const onConfirmVerification = useEvent(async (otp: string) => {
    const credential = parseCredentialValue(value, identityType);

    try {
      const results = await oauthTokenMutation({
        variables: {
          identityType,
          credential,
          otp,
        },
      });

      if (!results.data?.oauthToken.idToken) {
        return { success: false };
      }

      await onUpdateIdentity(credential, true, results.data.oauthToken.idToken);

      onHideVerifyModal();
      return { success: true };
    } catch (e) {
      const errorType = parseGQLError(e);

      if (
        errorType === VerifyError.WrongCredentialsPhone ||
        errorType === VerifyError.WrongCredentailsEmail
      ) {
        // User made errors while filling the form
        // Stay at verification step
        return { success: false };
      }

      // Credential aleady taken
      // Go to details step and show the error to the user
      if (
        errorType === VerifyError.PhoneNumberAlreadyTakenError ||
        errorType === VerifyError.EmailAddressAlreadyTakenError
      ) {
        setHasErrors(true);
      } else {
        Sentry.captureException(e);
      }
    }

    return { success: false };
  });

  const onResendOTP = useEvent(async () => {
    try {
      await passwordlessStartMutation({
        variables: {
          identityType,
          credential: parseCredentialValue(value, identityType),
          language: uiLanguage,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  });

  const onSubmitEdit = useEvent(async (newValue: string) => {
    const me = readMe();

    const verificationType =
      identityType === IdentityType.PhoneNumber
        ? t('phone-number')
        : t('email-address');
    const credential = parseCredentialValue(newValue, identityType);

    if (credential === getCredentialValueFromQuery(me, identityType)) {
      setVerificationState(getVerificationState(me, identityType));
      return;
    }

    const isPrimaryIdentity = me?.primaryIdentityType === identityType;

    try {
      await onUpdateIdentity(credential, false);

      if (
        isPrimaryIdentity &&
        verificationState !== VerificationState.Verified
      ) {
        const decision = await showModal.decide({
          title: t('onboarding:verification-type-needs-verification', {
            verificationType,
          }),
          message: t('onboarding:verify-your-verification-type-to-change', {
            verificationType,
          }),
          options: [
            {
              variant: 'positive',
              key: 'confirm',
              label: t('onboarding:verify-now'),
            },
          ],
        });

        if (decision !== 'confirm') {
          return;
        }

        await onVerify(newValue);
      }
    } catch (e) {
      const errorType = parseGQLError(e);

      // Credential already taken
      // Go to details step and show the error to the user
      if (
        errorType === VerifyError.PhoneNumberAlreadyTakenError ||
        errorType === VerifyError.EmailAddressAlreadyTakenError
      ) {
        setHasErrors(true);
      } else {
        Sentry.captureException(e);
      }
    }
  });

  const isPhoneVerified = useAtomValue(isPhoneVerifiedAtom);
  const isEmailVerified = useAtomValue(isEmailVerifiedAtom);

  // TODO: Refactor to reset `value` and `verificationState` based on an event, not through useEffect.
  useEffect(() => {
    const me = readMe();

    if (!me) {
      // inconclusive, not enough data yet.
      return;
    }

    if (identityType === IdentityType.Email) {
      setValue(me.email ?? '');
      setVerificationState(isEmailVerified);
    } else {
      setValue(me.phoneNumber ?? '');
      setVerificationState(isPhoneVerified);
    }
  }, [isPhoneVerified, isEmailVerified, readMe, identityType]);

  return {
    value,
    hasErrors,
    showVerifyModal,
    verificationState,

    onVerify,
    onResendOTP,
    onSubmitEdit,
    onChangeValue,
    onHideVerifyModal,
    onConfirmVerification,
  };
}
