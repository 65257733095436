import { atom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import ComplexError from '@advisor/utils/ComplexError';
import jellyfishClient, { TrackType } from './jellyfishClient';
import { updateLocalTrackAtom } from './localSession';

type PeripheralState =
  | { status: 'disabled' }
  | { status: 'enabling' }
  | {
      status: 'enabled';
      stream: MediaStream;
      trackIds: string[];
    };

const createPeripheralAtoms = (
  peripheralLabel: string,
  peripheralType: 'user' | 'display',
  constraints: MediaStreamConstraints | DisplayMediaStreamOptions,
  options: { videoTrack?: TrackType; audioTrack?: TrackType },
) => {
  const stateAtom = atom<PeripheralState>({ status: 'disabled' });
  const statusAtom = atom(
    (get) => get(stateAtom).status,
    (_get, set, status: 'disabled' | 'enabling') => set(stateAtom, { status }),
  );

  const enablePeripheralAtom = actionAtom(async ({ get, set }) => {
    if (get(statusAtom) !== 'disabled') {
      return;
    }

    set(statusAtom, 'enabling');

    let stream: MediaStream;
    try {
      if (peripheralType === 'user') {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
      } else {
        stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      }
    } catch (e) {
      Sentry.captureException(
        new ComplexError(`Failed to get a ${peripheralLabel} stream`, e),
      );
      return;
    }

    if (get(statusAtom) !== 'enabling') {
      // Enabling could have been cancelled while we were retrieving
      // the media asynchronously.
      return;
    }

    const trackIds: string[] = [];

    if (options.videoTrack) {
      const track: MediaStreamTrack | undefined = stream.getVideoTracks()[0];

      if (track) {
        trackIds.push(
          await jellyfishClient.addTrack(track, stream, {
            type: options.videoTrack,
          }),
        );

        set(updateLocalTrackAtom, options.videoTrack, track);
      }
    }

    if (options.audioTrack) {
      const track: MediaStreamTrack | undefined = stream.getAudioTracks()[0];

      if (track) {
        trackIds.push(
          await jellyfishClient.addTrack(track, stream, {
            type: options.audioTrack,
          }),
        );

        set(updateLocalTrackAtom, options.audioTrack, track);
      }
    }

    set(stateAtom, { status: 'enabled', stream, trackIds });
  });

  const disablePeripheralAtom = actionAtom(({ get, set }) => {
    const state = get(stateAtom);

    if (state.status === 'enabled') {
      state.trackIds.forEach((id) => jellyfishClient.removeTrack(id));
      state.stream.getTracks().forEach((track) => track.stop());
    }

    set(statusAtom, 'disabled');

    if (options.videoTrack) {
      set(updateLocalTrackAtom, options.videoTrack, null);
    }

    if (options.audioTrack) {
      set(updateLocalTrackAtom, options.audioTrack, null);
    }
  });

  const togglePeripheralAtom = actionAtom(({ get, set }, value?: boolean) => {
    const shouldEnable =
      value !== undefined ? value : get(statusAtom) === 'disabled';

    if (shouldEnable) {
      set(enablePeripheralAtom);
    } else {
      set(disablePeripheralAtom);
    }
  });

  return [
    atom((get) => get(statusAtom)), // readonly
    togglePeripheralAtom,
  ] as const;
};

export const [cameraStatusAtom, toggleCameraAtom] = createPeripheralAtoms(
  'Camera',
  'user',
  // constraints
  {
    video: true,
  },
  // extracted tracks
  {
    videoTrack: 'video',
  },
);

export const [microphoneStatusAtom, toggleMicrophoneAtom] =
  createPeripheralAtoms(
    'Microphone',
    'user',
    // constraints
    {
      audio: true,
    },
    // extracted tracks
    {
      audioTrack: 'audio',
    },
  );

export const [screenShareAtom, toggleScreenShareAtom] = createPeripheralAtoms(
  'ScreenShare',
  'display',
  // constraints
  {
    video: true,
  },
  // extracted tracks
  {
    videoTrack: 'screenVideo',
    audioTrack: 'screenAudio',
  },
);

export const disableAllPeripheralsAtom = actionAtom(({ set }) => {
  set(toggleCameraAtom, false);
  set(toggleMicrophoneAtom, false);
  set(toggleScreenShareAtom, false);
});
