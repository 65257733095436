import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import Env from '@advisor/api/env';
import { Icon } from '@advisor/design/components';
import BasicLayout from 'src/components/layouts/BasicLayout';
import GlobalStudyLogo from 'src/components/atoms/GlobalStudyLogo';

interface PageLayoutProps {
  children: ReactNode;
  headerTitle: string;
  title: string;
}

const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const { children, headerTitle, title } = props;
  const navigate = useNavigate();

  return (
    <BasicLayout>
      {/* Page Head */}
      <Helmet>
        <title>{headerTitle}</title>
        <style>{`
          body {
            background: linear-gradient(180deg, #FFF 0%, #ECECED 100%);
          }
        `}</style>
      </Helmet>

      {/* Page Header */}
      <div className="bg-black p-5 xl:py-7 xl:px-12">
        <a href="/">
          <img src={Env.web.logoWhite} alt={Env.organizationName} width={170} />
        </a>
      </div>
      <div className="flex-2 bg-[#191919] w-full px-8 py-5 xl:py-12 xl:px-[132px]">
        <button
          type="button"
          onClick={() => navigate('/')}
          className="font-outfit text-white text-lg flex flex-row items-center"
        >
          <Icon name="ChevronLeft" className="w-12 h-12" />
          Go Home
        </button>
        <h1 className="font-sora text-white text-3xl xl:text-4xl mt-16 xl:mt-[116px]">
          {title}
        </h1>
      </div>

      {/* Page Body */}
      <div className="flex-3 w-full py-8 px-6 xl:px-[132px] xl:py-10">
        {children}
      </div>

      {/* Page Footer */}
      <footer className="flex flex-row justify-between items-center px-5 pt-2 lg:px-24 h-24 bg-off-black text-off-white z-10">
        <a href="/">
          <GlobalStudyLogo className="w-32" fill="currentColor" />
        </a>
        <a
          href="https://www.globalstudy.ai"
          target="_blank"
          rel="noreferrer"
          className="font-outfit"
        >
          www.globalstudy.ai
        </a>
      </footer>
    </BasicLayout>
  );
};

export default PageLayout;
