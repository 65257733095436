import React from 'react';
import cs from 'classnames';

import { UserRoleSelectDropdownItemProps } from '../types';

const UserRoleSelectDropdownItem: React.FC<UserRoleSelectDropdownItemProps> = ({
  active = false,
  onClick,
  roleTitle,
  roleDescription,
}) => (
  <li className="w-full">
    <button
      type="button"
      onClick={onClick}
      className={cs(
        'cursor-pointer w-full pl-6 pr-5 py-3 hover:transition-colors hover:bg-light-grey ltr:text-left rtl:text-right focus-visible:outline-primary',
        {
          'bg-light-grey': active,
        },
      )}
    >
      <h4
        className={cs('font-bold text-sm font-sora', {
          'text-primary': active,
          'text-dark-grey-03': !active,
        })}
      >
        {roleTitle}
      </h4>
      <p className="text-dark-grey-02 text-xs">{roleDescription}</p>
    </button>
  </li>
);

export default UserRoleSelectDropdownItem;
