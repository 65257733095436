import throttle from 'lodash-es/throttle';
import type { ApolloClient } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';

import Sentry from '@advisor/utils/Sentry';
import * as network from '@advisor/utils/network';
import { useIsAuthorized } from '../auth/isAuthorized';

const ReFetchThrottleMS = 1000 * 60 * 5;

const useReFetchQueries = (client: ApolloClient<unknown>) => {
  const isAuthorized = useIsAuthorized();
  const isOnline = network.useOnline();

  const prevIsOnline = useRef<boolean | undefined>(undefined);
  const isAuthorizedRef = useRef(isAuthorized);

  // Use ref to avoid re-fetching queries when user logs in.
  isAuthorizedRef.current = isAuthorized;

  const refresh = useMemo(
    () =>
      throttle(async () => {
        try {
          await client.reFetchObservableQueries();
        } catch (error) {
          Sentry.captureException(error);
        }
      }, ReFetchThrottleMS),
    [client],
  );

  useEffect(() => {
    if (isAuthorizedRef.current && isOnline && prevIsOnline.current === false) {
      refresh();
    }

    prevIsOnline.current = isOnline;
  }, [isOnline, refresh]);
};

export default useReFetchQueries;
