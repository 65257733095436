import { createContext } from 'react';
import type { LanguageContextType } from '../types';

const LanguageContext = createContext<LanguageContextType>({
  uiLanguage: 'en',
  userLanguage: 'en',
  isRTL: false,
  async changeLanguage() {
    throw new Error(
      `Tried to change language without a LanguageContent upstream.`,
    );
  },
});

export default LanguageContext;
