import { ChangeEventHandler, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDeleteInputProps } from './useConfirmDeleteForm';

function ConfirmDeleteInput({ onChange, value }: ConfirmDeleteInputProps) {
  const { t } = useTranslation('onboarding');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onChange(target.value);
    },
    [onChange],
  );

  return (
    <div
      className="h-12.5 w-[312px] md:w-[342px] relative border flex flex-row items-stretch rounded text-dark-grey-03 border-dark-grey-01"
      dir="ltr"
    >
      <input
        id="delete-input"
        data-cy="delete-input"
        className="px-3 w-full rounded text-sm md:text-base focus:outline-none font-bold placeholder:text-grey placeholder:font-normal global-rtl:placeholder:text-right ltr:text-left rtl:text-right"
        type="text"
        aria-label={t('confirm-delete')}
        placeholder={t('confirm-delete')}
        value={value}
        onChange={onInputChange}
      />
    </div>
  );
}

export default memo(ConfirmDeleteInput);
