import { Atom, atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { lazyAtom } from '@advisor/utils/atoms';
import { chatRoomAtoms } from '@advisor/api/chatRoom';
import { nonNullable } from '@advisor/utils/typeUtils';
import { atomOfFragment, atomOfQuery } from '@advisor/api/apollo';
import {
  LatestMessagesDocument,
  MessageInfoFragment,
  MicrobotMessageInfoFragment,
  MicrobotMessageInfoFragmentDoc,
  SystemMessageInfoFragment,
  SystemMessageInfoFragmentDoc,
  UserMessageInfoFragment,
  UserMessageInfoFragmentDoc,
} from '@advisor/api/generated/graphql';
import { PageSize } from './consts';

const latestMessagesAtoms = atomFamily((chatRoomId: string) => {
  const queryAtom = atomOfQuery(LatestMessagesDocument, {
    chatRoomId,
    last: PageSize,
  });

  return lazyAtom(async (get) => (await get(queryAtom))?.data?.messages);
});

/**
 * Gives access to a specific system message.
 * NOTE: This data-source needs a query to be active somewhere else, like the
 *       'latestMessages' query. It will not fetch the data by itself.
 */
export const systemMessageInfoAtoms = atomFamily((messageId: string) => {
  const fragmentAtom = atomOfFragment<SystemMessageInfoFragment>(() => ({
    fragment: SystemMessageInfoFragmentDoc,
    fragmentName: 'SystemMessageInfo',
    from: {
      __typename: 'SystemMessage',
      id: messageId,
    },
    optimistic: true,
  }));

  return atom((get) => get(fragmentAtom).result);
});

/**
 * Gives access to a specific user message.
 * NOTE: This data-source needs a query to be active somewhere else, like the
 *       'latestMessages' query. It will not fetch the data by itself.
 */
export const userMessageInfoAtoms = atomFamily((messageId: string) => {
  const fragmentAtom = atomOfFragment<UserMessageInfoFragment>(() => ({
    fragment: UserMessageInfoFragmentDoc,
    fragmentName: 'UserMessageInfo',
    from: {
      __typename: 'UserMessage',
      id: messageId,
    },
    optimistic: true,
  }));

  return atom((get) => get(fragmentAtom).result);
});

/**
 * Gives access to a specific user message.
 * NOTE: This data-source needs a query to be active somewhere else, like the
 *       'latestMessages' query. It will not fetch the data by itself.
 */
export const microbotMessageInfoAtoms = atomFamily((messageId: string) => {
  const fragmentAtom = atomOfFragment<MicrobotMessageInfoFragment>(() => ({
    fragment: MicrobotMessageInfoFragmentDoc,
    fragmentName: 'MicrobotMessageInfo',
    from: {
      __typename: 'MicrobotMessage',
      id: messageId,
    },
    optimistic: true,
  }));

  return atom((get) => get(fragmentAtom).result);
});

export const MessageInfoFamilies = {
  UserMessage: userMessageInfoAtoms,
  SystemMessage: systemMessageInfoAtoms,
  MicrobotMessage: microbotMessageInfoAtoms,
} satisfies Record<MessageInfoFragment['__typename'], unknown>;

export const messageInfoAtoms = atomFamily((messageId: string) => {
  const atoms: Atom<
    | UserMessageInfoFragment
    | SystemMessageInfoFragment
    | MicrobotMessageInfoFragment
    | undefined
  >[] = Object.values(MessageInfoFamilies).map((family) => family(messageId));

  return atom((get) => atoms.map((a) => get(a)).find(nonNullable));
});

/**
 * An atom family that holds the oldest foreign message (incoming messages, not from us)
 * from all messages that are currently loaded in a specific chat room
 */
export const oldestForeignMessageAtoms = atomFamily((chatRoomId: string) => {
  const latestMessagesAtom = latestMessagesAtoms(chatRoomId);
  const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);

  return lazyAtom(async (get) => {
    const latestMessages = await get(latestMessagesAtom);
    const myself = await get(memberOfMeAtom);

    if (!latestMessages || !myself) {
      return null;
    }

    return (
      latestMessages.edges.find(
        (edge) =>
          edge.node.__typename === 'UserMessage' &&
          edge.node.author.id !== myself.member.id,
      )?.node ?? null
    );
  });
});

/**
 * An atom family that holds the latest foreign message (incoming messages, not from us)
 * from all messages that are currently loaded in a specific chat room
 */
export const latestForeignMessageAtoms = atomFamily((chatRoomId: string) => {
  const latestMessagesAtom = latestMessagesAtoms(chatRoomId);
  const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);

  return lazyAtom(async (get) => {
    const latestMessages = await get(latestMessagesAtom);
    const myself = await get(memberOfMeAtom);

    if (!latestMessages || !myself) {
      return null;
    }

    return (
      latestMessages.edges.findLast(
        (edge) =>
          edge.node.__typename === 'UserMessage' &&
          edge.node.author.id !== myself.member.id,
      )?.node ?? null
    );
  });
});

export default latestMessagesAtoms;
