import React from 'react';
import { useTranslation } from 'react-i18next';

import { useResetInput } from '../../hooks/MessageInput';
import useTimeoutDisplayMessage from './useTimeoutDisplayMessage';

const TimeoutDisplay: React.FC = () => {
  const message = useTimeoutDisplayMessage();
  const resetInput = useResetInput();

  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row justify-between text-dark-grey-02">
      <span className="text-xs">{message}</span>
      <button
        type="button"
        className="text-xs hover:bg-light-grey text-negative rounded px-1"
        onClick={resetInput}
      >
        {t('cancel')}
      </button>
    </div>
  );
};

export default TimeoutDisplay;
