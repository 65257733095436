import cs from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Spacing } from '@advisor/design/styles';
import { Avatar } from '@advisor/design/components';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import { useCachedUser, useUserRole } from '@advisor/api/user';
import { isParticipantListOpenAtom } from '../../atoms';
import { useParticipants, usePickUniqueParticipants } from '../../Participants';
import { CallParticipant } from '../../VideoRoomAPI';

const ParticipantItem: React.FC<{ participant: CallParticipant }> = ({
  participant,
}) => {
  const { t } = useTranslation('common');
  const user = useCachedUser(participant.userId);
  const color = useUserColor(user);
  const userRole = useUserRole(user);

  const subtitle = useMemo(() => {
    if (participant.joinState === 'Joining') {
      return t('joining...');
    }

    if (participant.joinState === 'WaitingToJoin') {
      return t('waiting-to-join');
    }

    return userRole;
  }, [t, participant, userRole]);

  return (
    <li className="flex pt-6 px-6 gap-x-3">
      <Avatar
        className="flex-grow-0 flex-shrink-0"
        avatarUrl={participant.avatarUrl}
        size={Spacing.small02}
        bordered={false}
      />
      <div className="border-b pb-4 border-light-grey">
        <h2 className="text-sm font-sora font-bold" style={{ color }}>
          {participant.name}
        </h2>
        <p
          className={cs(
            'text-sm font-outfit font-normal',
            participant.joinState === 'WaitingToJoin'
              ? 'text-negative'
              : 'text-dark-grey-025',
          )}
        >
          {subtitle}
        </p>
      </div>
    </li>
  );
};

function ParticipantList() {
  const { t } = useTranslation('common');
  const participants = useParticipants();
  const uniqueParticipants = usePickUniqueParticipants(participants);
  const isParticipantListOpen = useAtomValue(isParticipantListOpenAtom);

  if (!isParticipantListOpen || participants.length === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg">
      <header className="p-6">
        <h1 className="text-base font-sora text-dark-grey-03">
          <Trans
            t={t}
            i18nKey="participants-count"
            components={{ bold: <strong /> }}
            values={{
              count: String(uniqueParticipants.length).padStart(2, '0'),
            }}
          />
        </h1>
      </header>
      <ul>
        {uniqueParticipants.map((p) => (
          <ParticipantItem key={p.key} participant={p} />
        ))}
      </ul>
    </div>
  );
}

export default ParticipantList;
