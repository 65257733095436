import { Getter, Setter, atom } from 'jotai';
import actionAtom from './actionAtom';

export type Context = {
  get: Getter;
  set: Setter;
};

export const AlreadyRunning = Symbol('This action is already running');

/**
 * An action atom that can have at most one instance of it running at a time.
 * Use it for cases where you do not want f.e. a mutation to be ran twice on accident.
 */
function soloActionAtoms<Params extends unknown[], Result>(
  setter: (ctx: Context, ...params: Params) => Result,
) {
  const runningAtom = atom(false);

  const runAtom = actionAtom(function soloActionAtomSetter(
    ctx,
    ...params: Params
  ) {
    if (ctx.get(runningAtom)) {
      // Currently running
      return AlreadyRunning;
    }

    ctx.set(runningAtom, true);

    try {
      return setter(ctx, ...params);
    } finally {
      ctx.set(runningAtom, false);
    }
  });

  const readonlyRunningAtom = atom((get) => get(runningAtom));

  return [readonlyRunningAtom, runAtom] as [
    typeof readonlyRunningAtom,
    typeof runAtom,
  ];
}

export default soloActionAtoms;
