/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OperationVariables,
  ApolloQueryResult,
  ObservableQuery,
  ApolloError,
  NetworkStatus,
  WatchQueryOptions,
  Observer,
} from '@apollo/client';
import { pipe } from 'remeda';
import { atomWithObservable } from 'jotai/utils';
import { atom, Getter, WritableAtom } from 'jotai';

import soon from '@advisor/utils/soon';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import storeVersionAtom from './storeVersionAtom';
import { clientAtom } from './clientAtom';

type QueryArgs<
  Variables extends object = OperationVariables,
  Data = any,
> = Omit<WatchQueryOptions<Variables, Data>, 'fetchPolicy' | 'nextFetchPolicy'>;

type AtomWithQueryAction = {
  type: 'refetch';
};

type PromiseOrValue<T> = T | Promise<T>;

const atomWithQuery = <Data, Variables extends object = OperationVariables>(
  getArgs: (get: Getter) => QueryArgs<Variables, Data>,
): WritableAtom<
  PromiseOrValue<ApolloQueryResult<Data | undefined>>,
  [AtomWithQueryAction],
  Promise<void>
> => {
  const handleActionAtom = actionAtom(
    async ({ get }, action: AtomWithQueryAction) => {
      const client = await get(clientAtom);
      const args = getArgs(get);

      if (action.type === 'refetch') {
        await client.refetchQueries({
          include: [args.query],
        });
      }
    },
  );

  const wrapperAtom = atom(async (wrapperGet) => {
    const client = await wrapperGet(clientAtom);

    const sourceAtom = atomWithObservable(
      (get) => {
        const args = getArgs(get);

        // Resetting on store-version change
        get(storeVersionAtom(client));

        return wrapObservable(
          client.watchQuery({
            ...args,
            // Limiting to these settings for now, as this is the most sane behavior for atoms with query.
            fetchPolicy: 'cache-first',
          }),
        );
      },
      {
        // If not mounted, but used anyway, the query will get unwatched after 10 seconds of inactivity
        unstable_timeout: 10000,
      },
    );

    return sourceAtom;
  });

  return atom(
    (get) =>
      pipe(
        get(wrapperAtom),
        soon(get),
        soon((result) => {
          if (result.error) {
            Sentry.captureException(result.error);
          }

          return result;
        }),
      ),
    (_get, set, action: AtomWithQueryAction) => set(handleActionAtom, action),
  );
};

export default atomWithQuery;

type Subscription = {
  unsubscribe: () => void;
};

const wrapObservable = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  observableQuery: ObservableQuery<TData, TVariables>,
) => ({
  subscribe: (
    observer: Partial<Observer<ApolloQueryResult<TData | undefined>>>,
  ): Subscription => {
    let subscription = observableQuery.subscribe(onNext, onError);

    function onNext(result: ApolloQueryResult<TData>) {
      observer.next?.(result);
    }

    function onError(error: unknown) {
      // eslint-disable-next-line prefer-destructuring
      const last = (observableQuery as any).last;
      subscription.unsubscribe();

      try {
        observableQuery.resetLastResults();
        subscription = observableQuery.subscribe(onNext, onError);
      } finally {
        (observableQuery as any).last = last;
      }

      const errorResult: ApolloQueryResult<TData | undefined> = {
        data: observableQuery.getCurrentResult().data,
        error: error as ApolloError,
        loading: false,
        networkStatus: NetworkStatus.error,
      };

      // Errors are returned as part of the result
      observer.next?.(errorResult);
    }

    return {
      unsubscribe: () => subscription.unsubscribe(),
    };
  },
});
