import { Transition } from '@headlessui/react';

type Props = {
  show: boolean;
  onClose?: () => void;
  appear?: boolean;
};

const AnimatedOverlay = ({ show, onClose, appear = false }: Props) => {
  return (
    <Transition
      as="div"
      show={show}
      enter="transition-opacity ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      appear={appear}
    >
      <div
        role="presentation"
        className="fixed inset-0 bg-dark-grey-02 xl:hidden opacity-40 z-10"
        onClick={onClose}
        onKeyPress={onClose}
      />
    </Transition>
  );
};

export default AnimatedOverlay;
