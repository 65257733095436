import React from 'react';
import cs from 'classnames';

import { ContextMenu } from '@advisor/design/components';
import useBubbleCorners from './useBubbleCorners';
import { ChatMessageBubbleProps } from './types';

function ChatMessageBubble(props: ChatMessageBubbleProps) {
  const {
    children,
    isFirstInGroup = false,
    isLastInGroup = false,
    isFromMe = false,
    noBottom,
    contextMenuScope,
  } = props;

  const { roundTopRight, roundBottomRight, roundTopLeft, roundBottomLeft } =
    useBubbleCorners({
      isFromMe,
      isFirstInGroup,
      isLastInGroup,
      noBottom,
    });

  const wrapperClassNames = cs(
    'relative px-4 pb-4 text-xs sm:text-sm font-medium whitespace-pre-wrap border border-grey',
    isFromMe ? 'bg-primary text-white ml-auto' : 'bg-white text-dark-grey-02',
    roundTopRight && 'rounded-tr-10',
    roundBottomRight && 'rounded-br-10',
    roundTopLeft && 'rounded-tl-10',
    roundBottomLeft && 'rounded-bl-10',
    noBottom && 'border-b-0',
    'z-10',
  );

  if (contextMenuScope) {
    return (
      <ContextMenu.ManagedOwner
        scope={contextMenuScope}
        className={wrapperClassNames}
      >
        {children}
      </ContextMenu.ManagedOwner>
    );
  }

  return <div className={wrapperClassNames}>{children}</div>;
}

export default ChatMessageBubble;
