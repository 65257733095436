import { invitePath, joinPath, leavePath } from './assets';
import { SoundNotificationsType } from './types';

export function loadSound(path: string) {
  return new Promise<HTMLAudioElement>((resolve, reject) => {
    const audio = new Audio(path);

    // All files are normalized to 0db, setting all notifications
    // to be quieter than the rest of the system sounds.
    audio.volume = 0.2;

    audio.addEventListener('loadeddata', () => {
      resolve(audio);
    });

    audio.addEventListener('error', (e) => {
      reject(e.error);
    });
  });
}

const getSounds = (() => {
  async function loadSounds() {
    const [invite, join, leave] = await Promise.all([
      loadSound(invitePath),
      loadSound(joinPath),
      loadSound(leavePath),
    ]);

    return {
      invite,
      join,
      leave,
    };
  }

  let pendingLoad: ReturnType<typeof loadSounds> | null = null;

  return () => {
    if (!pendingLoad) {
      pendingLoad = loadSounds();
    }

    return pendingLoad;
  };
})();

export const SoundNotifications: SoundNotificationsType = {
  playInviteSound() {
    getSounds().then((s) => s.invite.play());
  },
  playJoinSound() {
    getSounds().then((s) => s.join.play());
  },
  playLeaveSound() {
    getSounds().then((s) => s.leave.play());
  },
};

export default SoundNotifications;
