import { useAtomValue } from 'jotai';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEffect, useMemo, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { studentJourneyOwnerAtoms } from '../../api/studentJourney';
import { DatePickerTabProps } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

const useDatePickerTab = (props: DatePickerTabProps) => {
  const { onOpenRoot, inputProps, chatRoomId, minDueDate } = props;
  const { onChange, value } = inputProps.dueDate;

  const studentJourneyOwner = useAtomValue(
    studentJourneyOwnerAtoms(chatRoomId),
  );
  const studentTimezone = studentJourneyOwner?.member.timezone;

  const [internalValue, setInternalValue] = useState<Dayjs | undefined>(() =>
    value !== undefined ? dayjs(value) : undefined,
  );

  useEffect(() => {
    setInternalValue(value !== undefined ? dayjs(value) : undefined);
  }, [value]);

  const studentTzDifference = useMemo(() => {
    if (!studentTimezone) {
      return 0;
    }
    const local = dayjs();
    const studentTime = local.tz(studentTimezone, true);
    return local.diff(studentTime);
  }, [studentTimezone]);

  const handleDateClick = useEvent((date: Dayjs) => {
    setInternalValue(date);
  });

  const handleBack = useEvent(() => {
    setInternalValue(dayjs(value));
    onOpenRoot();
  });

  const handleClear = useEvent(() => {
    setInternalValue(undefined);
    onChange?.(undefined);
    onOpenRoot();
  });

  const handleSave = useEvent(() => {
    onChange?.((internalValue ?? minDueDate).toDate());
    onOpenRoot();
  });

  const selectedDate = internalValue ?? minDueDate;

  return {
    studentJourneyOwner,
    minDate: minDueDate,
    selectedDate,
    studentTzDifference,
    handleBack,
    handleSave,
    handleClear,
    handleDateClick,
  };
};

export default useDatePickerTab;
