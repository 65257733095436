import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { Suspense, useEffect } from 'react';

import ConversationList from '@advisor/chat/ConversationList';
import { PermissionGate, PermissionSelect, Scope } from '@advisor/api/scope';
import { personalPreferencesSlotScopes } from '@advisor/chat/UserPreferences';
import { Logo } from '@advisor/ui/Brand';
import AspiringAdvisorEmptyList from '@advisor/chat/ChatRoomList/web/AspiringAdvisorEmptyList';
import { Loader } from '@advisor/design/components';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import sidebarTabAtom, { SidebarTab } from '@advisor/ui/atoms/sidebarTabAtom';
import AnimatedOverlay from '../atoms/AnimatedOverlay';
import ChatSidebarStudent from '../molecules/ChatSidebarStudent';
import DirectoriesSidebarContent from './DirectoriesSidebarContent';
import UserDropDown from './UserDropDown';

const ChatSidebarContent = PermissionSelect([
  {
    condition: Scope.some([Scope.verifiedAdvisor, Scope.serviceProvider]),
    Component: ConversationList,
  },
  {
    condition: Scope.aspiringAdvisor,
    Component: AspiringAdvisorEmptyList,
  },
  {
    condition: 'default',
    Component: ChatSidebarStudent,
  },
]);

const ChatSidebar: React.FC<{ initialTab?: SidebarTab }> = ({ initialTab }) => {
  const [show, setShow] = useAtom(showSidebarAtom);
  const [sidebarTab, setSidebarTab] = useAtom(sidebarTabAtom);

  useEffect(() => {
    if (initialTab) {
      setSidebarTab(initialTab);
    }
  }, [initialTab, setSidebarTab]);

  return (
    <div role="menubar">
      <AnimatedOverlay show={show} onClose={() => setShow(false)} />
      <div
        className={cs(
          show ? 'translate-x-0' : 'ltr:-translate-x-full rtl:translate-x-full',
          'fixed ltr:left-0 rtl:right-0 transition-transform transform-gpu inset-y-0 flex flex-col w-full h-full max-w-md xl:w-chat-sidebar-xl 2xl:w-chat-sidebar-2xl ltr:border-r rtl:border-l border-grey bg-white z-10 ltr:xl:translate-x-0 rtl:xl:translate-x-0 duration-150 xl:duration-0',
        )}
      >
        <Suspense
          fallback={
            <>
              <div className="h-header-height flex border-b border-grey bg-white shrink-0">
                <div className="ltr:pl-10 rtl:pr-10 h-full py-2 w-[234px]">
                  <Logo type="platform" fit="stretch" />
                </div>
              </div>
              <Loader className="mx-auto mt-10" />
            </>
          }
        >
          <div className="h-header-height flex justify-between border-b border-grey bg-white shrink-0">
            <div className="ltr:pl-10 rtl:pr-10  h-full py-2 w-[234px]">
              <Logo type="platform" fit="stretch" />
            </div>
            <PermissionGate
              scope={personalPreferencesSlotScopes('sidebar-menu')}
            >
              {() => (
                <div className="flex flex-shrink-0 basis-auto justify-end items-center ltr:pr-6 rtl:pl-6 py-2">
                  <UserDropDown />
                </div>
              )}
            </PermissionGate>
          </div>

          {sidebarTab === SidebarTab.Conversations ? (
            <ChatSidebarContent />
          ) : (
            <DirectoriesSidebarContent />
          )}
        </Suspense>
      </div>
      <div className="max-w-md w-0 xl:w-chat-sidebar-xl 2xl:w-chat-sidebar-2xl">
        {/* Acts as a width imitator for the actual sidebar. It allows the sidebar to stay fixed, while also displacing content.
            This helps Safari deal with the window size changing. */}
      </div>
    </div>
  );
};

export default ChatSidebar;
