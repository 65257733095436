import React from 'react';
import { useAtomValue } from 'jotai';

import { Modal, SizeAdaptingWrapper } from '@advisor/design/components';
import useAddMilestoneModal from './useAddMilestoneModal';
import {
  ModalTab,
  AddTabProps,
  DatePickerTabProps,
  AddMilestoneModalProps,
} from './types';
import DatePickerTab from './DatePickerTab';
import AddMilestoneTab from './AddMilestoneTab';
import { milestoneAddStateAtom } from './atoms';

type TabProps = AddTabProps & DatePickerTabProps;

function Inner({ ownerMilestoneCategory }: AddMilestoneModalProps) {
  const milestoneAddState = useAtomValue(milestoneAddStateAtom);
  const {
    minDueDate,
    activeTab,
    inputs,
    submit,
    closeModal,
    openRootTab,
    openDatePickerTab,
  } = useAddMilestoneModal(ownerMilestoneCategory);

  return (
    <Modal.Base
      appear
      maxWidth={406}
      visible={!!milestoneAddState}
      onClose={closeModal}
    >
      <form onSubmit={submit}>
        <SizeAdaptingWrapper<TabProps>
          durationMs={200}
          lockX
          inner={
            activeTab === ModalTab.DatePicker ? DatePickerTab : AddMilestoneTab
          }
          commonProps={{
            minDueDate,
            activeTab,
            inputProps: inputs,
            chatRoomId: ownerMilestoneCategory.chatRoomId,
            onSubmit: submit,
            onClose: closeModal,
            onOpenRoot: openRootTab,
            onOpenDatePicker: openDatePickerTab,
          }}
        />
      </form>
    </Modal.Base>
  );
}

function AddMilestoneModal(props: AddMilestoneModalProps) {
  const milestoneAddState = useAtomValue(milestoneAddStateAtom);

  return (
    <Inner
      // Remounting on milestone change to reset derived state
      key={String(!!milestoneAddState)}
      {...props}
    />
  );
}

export default AddMilestoneModal;
