import z from 'zod';
import { loadable } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { unwrapLoadable } from '@advisor/utils/atoms';
import ComplexError from '@advisor/utils/ComplexError';
import Env from '../env';

type Version = z.infer<typeof Version>;
const Version = z
  .string()
  .transform((s) => s.split('.').map((part) => Number.parseInt(part, 10)))
  .refine(
    (arr) => arr.length === 3 && arr.every((part) => !Number.isNaN(part)),
  );

const schema = z.object({
  minSupportedVersion: Version,
  maintenanceMode: z.boolean(),
});

enum VersionCompatibility {
  Compatible,
  Incompatible,
}

const checkVersionCompatibility = (
  current: Version,
  min: Version,
): VersionCompatibility => {
  const majorDiff = current[0] - min[0];
  if (majorDiff > 0) {
    return VersionCompatibility.Compatible;
  }
  if (majorDiff < 0) {
    return VersionCompatibility.Incompatible;
  }
  const minorDiff = current[1] - min[1];
  if (minorDiff > 0) {
    return VersionCompatibility.Compatible;
  }
  if (minorDiff < 0) {
    return VersionCompatibility.Incompatible;
  }
  const patchDiff = current[2] - min[2];
  if (patchDiff > 0) {
    return VersionCompatibility.Compatible;
  }
  if (patchDiff < 0) {
    return VersionCompatibility.Incompatible;
  }

  return VersionCompatibility.Compatible;
};

// Remove '/uploads' from the end of the url if present, since appinfo.json is located at
// '/appinfo' directory
const parseUrl = (s3Url: string) => {
  if (s3Url.endsWith('/uploads')) {
    return s3Url.substring(0, s3Url.length - 8);
  }

  return s3Url;
};

const DefaultAppInfo: z.infer<typeof schema> = {
  maintenanceMode: false,
  minSupportedVersion: [0, 0, 0],
};

const appInfoAtom = loadable(
  atom(async () => {
    try {
      const textContent = await fetch(
        `${parseUrl(Env.api.s3Url)}/appinfo/appinfo.json?ts=${Date.now()}`,
      ).then((r) => r.text());

      let jsonContent = {};
      try {
        jsonContent = JSON.parse(textContent);
      } catch (error) {
        throw new ComplexError(
          `Not a proper JSON format: '${textContent}'`,
          error,
        );
      }

      return schema.parse(jsonContent);
    } catch (error) {
      Sentry.captureException(
        new ComplexError('Failed to retrieve appinfo.json', error),
      );
      return DefaultAppInfo;
    }
  }),
);

const useMaintenanceScreens = (currentVersion?: string) => {
  const appInfo = unwrapLoadable(useAtomValue(appInfoAtom), {
    minSupportedVersion: [0, 0, 0],
    maintenanceMode: false,
  });

  return {
    showMaintenanceScreen: appInfo.maintenanceMode,
    showNotUpToDateScreen:
      currentVersion === undefined
        ? false
        : checkVersionCompatibility(
            Version.parse(currentVersion),
            appInfo.minSupportedVersion,
          ),
  };
};

export default useMaintenanceScreens;
