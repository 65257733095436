import { Spacing } from '@advisor/design/styles';

import { Rect, TooltipPosition, UseTooltipContentProps } from './types';

export const tailWidth = 12;
const minSpace = Spacing.tiny;
const tailHalfW = tailWidth / 2;

function getTopAlign(props: UseTooltipContentProps) {
  const { position, root, tooltip } = props;

  if (position === TooltipPosition.Above) {
    return root.y - tooltip.height - tailWidth;
  }

  return root.y + root.height + tailWidth;
}

function getTailPosition(tooltip: Rect, root: Rect, position: TooltipPosition) {
  let left = 0;

  if (root.x >= tooltip.x && root.x + root.width <= tooltip.x + tooltip.width) {
    left = root.x + root.width / 2 - tooltip.x - tailWidth;
  } else {
    const lowerBound = Math.max(root.x, tooltip.x);
    const upperBound = Math.min(root.x + root.width, tooltip.x + tooltip.width);

    left = (upperBound - lowerBound) / 2 - tooltip.x - tailWidth;
  }

  const top =
    position === TooltipPosition.Above
      ? tooltip.height - tailHalfW
      : -tailHalfW;

  return {
    left,
    top,
  };
}

function calcOutsidePosition(props: UseTooltipContentProps) {
  const { position, root, tooltip, screen } = props;
  const top = getTopAlign(props);

  const left = (() => {
    if (root.x < screen.width / 2 + screen.x) {
      // align the tooltip to the left screen edge

      if (screen.x + tooltip.width < root.x + root.width + minSpace) {
        // Checks if there will be enough space for tail and whitespace around it
        return root.x + root.width + minSpace - tooltip.width;
      }

      return screen.x;
    }

    // align the tooltip to the right screen edge

    if (screen.x + screen.width - tooltip.width > root.x - minSpace) {
      // Checks if there will be enough space for tail and whitespace around it
      return root.x - minSpace;
    }

    return screen.x + screen.width - tooltip.width;
  })();

  return {
    content: { left, top },
    tail: getTailPosition({ ...tooltip, y: top, x: left }, root, position),
  };
}

function calcInsidePosition(props: UseTooltipContentProps) {
  const { position, root, tooltip, screen } = props;
  const top = getTopAlign(props);

  const left = (() => {
    if (root.x < screen.x + screen.width / 2) {
      return Math.max(screen.x, root.x);
    }

    return Math.min(
      root.x + root.width - tooltip.width,
      screen.x + screen.width - tooltip.width,
    );
  })();

  return {
    content: { left, top },
    tail: getTailPosition({ ...tooltip, y: top, x: left }, root, position),
  };
}

export default function useTooltipPositions(props: UseTooltipContentProps) {
  if (props.tooltip.width > props.root.width) {
    return calcOutsidePosition(props);
  }

  return calcInsidePosition(props);
}
