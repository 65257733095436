type Props = {
  className?: string | undefined;
  fill?: string | undefined;
};

function GlobalStudyLogo({ className, fill }: Props) {
  return (
    <svg
      className={className}
      viewBox="0 0 105 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_350_621)">
        <path
          d="M32.7823 17.0462C29.913 17.0462 27.8184 14.9299 27.8184 12.0072C27.8184 9.07023 29.9273 6.95386 32.7679 6.95386C35.1351 6.95386 36.6559 8.30719 37.2584 9.91966L35.5512 10.6395C35.1208 9.38697 34.1596 8.66711 32.7392 8.66711C30.8885 8.66711 29.6404 10.0348 29.6404 12.0504C29.6404 14.0804 30.9172 15.4482 32.9401 15.4482C34.4608 15.4482 35.5512 14.6707 35.9385 13.0726H32.7392V11.7049H37.6745C37.6601 15.0882 35.5368 17.0462 32.7823 17.0462Z"
          fill={fill}
        />
        <path
          d="M40.1413 6.62268H38.3623V16.8734H40.1413V6.62268Z"
          fill={fill}
        />
        <path
          d="M46.9133 13.1734C46.9133 11.8488 46.0669 10.913 44.79 10.913C43.4988 10.913 42.6237 11.8488 42.6237 13.1878C42.6237 14.5123 43.4988 15.4625 44.79 15.4625C46.0382 15.4625 46.9133 14.5123 46.9133 13.1734ZM40.8447 13.1878C40.8447 10.9706 42.4659 9.31494 44.7757 9.31494C47.0711 9.31494 48.7067 10.9706 48.7067 13.1878C48.7067 15.3905 47.0711 17.0462 44.7757 17.0462C42.4659 17.0462 40.8447 15.3905 40.8447 13.1878Z"
          fill={fill}
        />
        <path
          d="M55.364 13.1878C55.364 11.8632 54.4745 10.8986 53.255 10.8986C51.9782 10.8986 51.1174 11.82 51.1174 13.1878C51.1174 14.5411 51.9782 15.4769 53.255 15.4769C54.4745 15.4769 55.364 14.5123 55.364 13.1878ZM57.143 13.1878C57.143 15.3905 55.6079 17.0462 53.4989 17.0462C52.466 17.0462 51.6339 16.6431 51.06 15.9376V16.8734H49.4102V6.62268H51.1891V10.2939C51.763 9.67487 52.5521 9.31494 53.542 9.31494C55.6509 9.31494 57.143 10.9706 57.143 13.1878Z"
          fill={fill}
        />
        <path
          d="M63.4982 13.1877C63.4982 11.82 62.6374 10.8986 61.3606 10.8986C60.1411 10.8986 59.2516 11.8632 59.2516 13.1877C59.2516 14.5122 60.1411 15.4768 61.3606 15.4768C62.6374 15.4768 63.4982 14.541 63.4982 13.1877ZM65.2055 9.48764V16.8734H63.5556V15.88C63.0104 16.6142 62.1927 17.0461 61.1167 17.0461C59.0077 17.0461 57.4727 15.3905 57.4727 13.1877C57.4727 10.9705 59.0077 9.43005 61.1167 9.43005C62.1783 9.43005 63.0104 9.81878 63.5556 10.5386V9.48764H65.2055Z"
          fill={fill}
        />
        <path
          d="M68.0749 6.62268H66.2959V16.8734H68.0749V6.62268Z"
          fill={fill}
        />
        <path
          d="M68.6768 14.5843L70.3697 13.9365C70.6422 14.9299 71.3883 15.477 72.4643 15.477C73.4685 15.477 74.0711 15.0306 74.0711 14.282C74.0711 12.108 69.1072 13.1302 69.1072 9.68931C69.1072 8.07683 70.3266 6.95386 72.4069 6.95386C74.1715 6.95386 75.3623 7.80329 75.7209 9.11343L74.028 9.77569C73.7698 8.91187 73.1959 8.49435 72.3208 8.49435C71.3883 8.49435 70.9005 8.94066 70.9005 9.55974C70.9005 11.6041 75.8787 10.5675 75.8787 14.2388C75.8787 15.8945 74.5158 17.0174 72.3064 17.0174C70.4844 17.0318 69.1358 16.168 68.6768 14.5843Z"
          fill={fill}
        />
        <path
          d="M77.1564 14.1955V11.0138H75.8652V9.48769H76.5395C77.0273 9.48769 77.2712 9.22854 77.2712 8.72464V7.55847H79.0072V9.47329H80.9296V10.9994H78.9498V13.994C78.9498 14.8434 79.3802 15.3185 80.1836 15.3185H81.0013V16.8734H79.8393C78.0746 16.8734 77.1564 15.8224 77.1564 14.1955Z"
          fill={fill}
        />
        <path
          d="M88.1593 9.48767V16.8734H86.4951V16.0528C85.9787 16.6286 85.1753 17.0318 84.2284 17.0318C82.349 17.0318 81.3447 15.7648 81.3447 14.0516V9.48767H83.1237V13.85C83.1237 14.8002 83.7549 15.4625 84.7449 15.4625C85.7348 15.4481 86.3804 14.7714 86.3804 13.9652V9.48767H88.1593Z"
          fill={fill}
        />
        <path
          d="M94.9025 13.1878C94.9025 11.82 94.0417 10.8986 92.7649 10.8986C91.5454 10.8986 90.6559 11.8632 90.6559 13.1878C90.6559 14.5123 91.5454 15.4769 92.7649 15.4769C94.0274 15.4769 94.9025 14.5411 94.9025 13.1878ZM96.6098 6.62268V16.8734H94.9599V15.9376C94.386 16.6431 93.5683 17.0462 92.521 17.0462C90.412 17.0462 88.877 15.3905 88.877 13.1878C88.877 10.9706 90.369 9.31494 92.4779 9.31494C93.4535 9.31494 94.2569 9.67487 94.8308 10.2939V6.62268H96.6098Z"
          fill={fill}
        />
        <path
          d="M104.615 9.48767L101.803 16.8734L100.856 19.3929H99.1061L100.11 16.8734H99.5508L96.9541 9.48767H98.7905L100.684 15.4481L102.764 9.48767H104.615Z"
          fill={fill}
        />
        <path
          d="M15.2936 14.4548L23.6863 9.64612L10.5879 12.2376L15.2936 14.4548Z"
          fill={fill}
        />
        <path
          d="M23.6862 9.64607L17.2876 20.1416L16.1256 16.7295C15.0209 17.7229 13.5575 18.3131 11.9507 18.3131C8.47884 18.3131 5.65256 15.4913 5.65256 11.9928C5.65256 8.5087 8.46449 5.67247 11.9507 5.67247C13.6149 5.67247 15.1213 6.32034 16.2547 7.38573L20.2574 3.36893C18.1198 1.28134 15.193 0 11.9651 0C5.35128 0 0 5.37013 0 12.0072C0 18.6299 5.35128 24.0144 11.9651 24.0144C18.5645 24.0144 23.9301 18.6443 23.9301 12.0072C23.9158 11.1866 23.844 10.4091 23.6862 9.64607Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_350_621">
          <rect width="104.615" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GlobalStudyLogo;
