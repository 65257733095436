import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { Reference, isReference } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';
import {
  ChatRoomConnection,
  useArchiveConversationMutation,
  ArchiveConversationMutationOptions,
} from '@advisor/api/generated/graphql';
import { ArchivalStatus, changeArchivalStatusAtom } from './atoms';

const archiveConversationOptions: ArchiveConversationMutationOptions = {
  update(cache, _result, { variables: { chatRoomId } = {} }) {
    cache.modify({
      fields: {
        chatRooms(existing: Reference | ChatRoomConnection, { readField }) {
          if (isReference(existing)) {
            return existing;
          }

          const edges = existing?.edges?.filter(
            ({ node }) => chatRoomId !== readField('id', node),
          );

          const lastNode = existing?.edges?.[existing.edges.length - 1]?.node;
          const pageInfo = { ...existing?.pageInfo };

          if (lastNode && chatRoomId === readField('id', lastNode)) {
            pageInfo.startCursor = existing.edges?.length
              ? existing.edges[existing.edges.length - 1].cursor
              : undefined;
          }

          return {
            ...existing,
            pageInfo,
            edges,
            count: existing?.count ? existing.count - 1 : 0,
          };
        },
      },
      broadcast: false,
    });
  },
};

export interface ArchiveChatroomParams {
  name: string;
  chatRoomId: string;
}

function useArchiveChatRoom() {
  const { t } = useTranslation('common');
  const showModal = useShowModal();
  const changeArchivalStatus = useSetAtom(changeArchivalStatusAtom);

  const [archiveConversation] = useArchiveConversationMutation(
    archiveConversationOptions,
  );

  const handleArchiveConversation = useCallback(
    async (chatRoomId: string): Promise<boolean> => {
      const { data } = await archiveConversation({
        variables: { chatRoomId },
      });

      if (
        (data?.archiveConversation.__typename === 'Advisor' ||
          data?.archiveConversation.__typename === 'ServiceProvider') &&
        data?.archiveConversation.id
      ) {
        showToast({
          messageI18Key: 'selected-conversation-was-disconnected',
          variant: 'rose',
          iconName: 'Disconnect',
        });

        return true;
      }

      if (
        data?.archiveConversation.__typename ===
        'LeadingAdvisorCantLeaveChatRoomError'
      ) {
        showToast({
          messageI18Key: 'selected-conversation-cannot-be-archived',
          variant: 'rose',
          iconName: 'X',
        });

        return false;
      }

      const retry = await showModal.info({
        title: t('oops-something-went-wrong'),
        theme: 'warning',
        message: '',
        options: [
          {
            label: t('try-again'),
            key: 'try-again',
            variant: 'neutral',
          } as const,
        ],
      });

      return retry ? handleArchiveConversation(chatRoomId) : false;
    },
    [archiveConversation, showModal, t],
  );

  return useCallback(
    async ({ chatRoomId, name }: ArchiveChatroomParams) => {
      const confirmed = await showModal.confirm({
        variant: 'severe',
        title: t('are-you-sure-you-want-to-disconnect-from-user', {
          user: name,
        }),
        message: t('you-will-no-longer-have-access-to-this-conversation'),
      });

      if (!confirmed) {
        return false;
      }

      changeArchivalStatus(chatRoomId, ArchivalStatus.Pending);

      try {
        return await handleArchiveConversation(chatRoomId).catch((error) => {
          Sentry.captureException(error);
          return false;
        });
      } catch (error) {
        Sentry.captureException(error);
        return false;
      } finally {
        changeArchivalStatus(chatRoomId, ArchivalStatus.Idle);
      }
    },
    [handleArchiveConversation, changeArchivalStatus, showModal, t],
  );
}

export default useArchiveChatRoom;
