import { useEffect } from 'react';
import { RecordingConsentState } from './types';
import useConsentState from './useConsentState';

function useOnConsentDenied(cb: () => unknown) {
  const consentState = useConsentState();

  useEffect(() => {
    if (consentState === RecordingConsentState.DENIED) {
      cb();
    }
  }, [cb, consentState]);
}

export default useOnConsentDenied;
