import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { CommentTextProps } from './types';
import useCommentText from './useCommentText';

const CommentText: React.FC<CommentTextProps> = (props) => {
  const { text } = props;
  const { t } = useTranslation('common');
  const {
    visibleText,
    isShowLessVisible,
    isShowMoreVisible,

    onShowLess,
    onShowMore,
  } = useCommentText(text);

  return (
    <p
      className={cs(
        'text-sm text-dark-grey-025 font-medium',
        isShowLessVisible ? 'whitespace-pre-wrap' : 'whitespace-normal',
      )}
      data-cy="commentMessage"
    >
      {visibleText}
      {isShowLessVisible && (
        <InlineButton onClick={onShowLess}>{t('show-less')}</InlineButton>
      )}
      {isShowMoreVisible && (
        <InlineButton onClick={onShowMore}>{t('show-more')}</InlineButton>
      )}
    </p>
  );
};

type InlineButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

const InlineButton: React.FC<InlineButtonProps> = (props) => {
  const { onClick, children } = props;

  return (
    <button
      type="button"
      className="text-xs text-primary mx-1 hover:opacity-70 transition-opacity"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CommentText;
