import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import useUserRole from '@advisor/api/user/useUserRole';
import {
  UserName,
  RichMessage,
  CenteredMessage,
} from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';
import useUserName from '../useUserName';

const NewMemberMessageInStudentConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { chatRoomId, data } = message;
  const { student, advisor } = data as DefaultSystemMessageData;

  const role = useUserRole(advisor);
  const studentName = useUserName(student);
  const advisorName = useUserName(advisor);

  if (!student || !advisor) {
    return null;
  }

  return (
    <RichMessage
      hasScrollInfo
      chatRoomId={chatRoomId}
      users={[student, advisor]}
    >
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey="you-are-talking-with-user-they-have-been-getting-advice"
        values={{ user: studentName, host: advisorName, role }}
      >
        You’re now connected with <UserName user={student} />. They have been
        getting advice from <UserName user={advisor} />, {role}.
      </Trans>
    </RichMessage>
  );
};

export default memo(NewMemberMessageInStudentConversation);
