import { useMemo } from 'react';
import type { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';

import useUserConfig from '@advisor/api/userConfig/useUserConfig';
import { useMyId } from '@advisor/api/me';
import {
  ChatRoomStatusInfoFragment,
  ChatRoomWithMembersFragment,
} from '@advisor/api/generated/graphql';

export const NoStatusLiteral = 'NO_STATUS';

// empty status that is hidden in the UI which will block status reset, in case user deletes their last one status.
// Which would result in backend returning default agency status list. Instead of that, there is additional 'restore to default' button
export const EmptyStatus = {
  name: '',
  i18nKey: 'internal-empty-status',
  color: '',
};

/* eslint-disable import/prefer-default-export */
export function useStatusName(status?: ChatRoomStatusInfoFragment) {
  const { t } = useTranslation('common');

  if (!status) {
    return '';
  }

  if (status.name && status.name !== '') {
    return status.name;
  }

  return t(status.i18nKey as ResourceKey);
}

export function useStatusFromId(statusId: string | 'NO_STATUS') {
  const { statuses } = useUserConfig();

  return useMemo(
    () => statuses.find((item) => item.id === statusId),
    [statusId, statuses],
  );
}

export function useGetStatusId({
  members,
}: ChatRoomWithMembersFragment): 'NO_STATUS' | string {
  const myId = useMyId();
  const memberOfMe = members?.find(
    (memberInfo) => memberInfo.member.id === myId,
  );

  const { status } = memberOfMe ?? {};

  return status ?? NoStatusLiteral;
}
