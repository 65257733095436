import { atomFamily } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { Getter, Setter, useAtom, useSetAtom } from 'jotai';

import { actionAtom } from '@advisor/utils/atoms';
import { translationAtoms } from '@advisor/language';
import { decisionModalAtom, infoModalAtom } from './atoms';
import {
  ActionDetails,
  ActionResolver,
  ActionStoreAtom,
  ConfirmDetails,
  ModalOptions,
} from './types';

export const showActionModalAtoms = atomFamily((modalAtom: ActionStoreAtom) => {
  return actionAtom(({ set }, details: ActionDetails) => {
    let resolveAction: ActionResolver | null = null;

    const actionPromise = new Promise<string | null>((resolve) => {
      resolveAction = resolve;
    });

    set(modalAtom, {
      ...details,
      resolve: resolveAction ?? (() => null),
      resultPromise: actionPromise,
    });

    return actionPromise;
  });
});

/**
 * Specialized kind of decision modal
 */
const showConfirmModalAtom = actionAtom(
  async (
    ctx,
    {
      title,
      message,
      variant,
      confirmActionLabel,
      cancelActionLabel,
    }: ConfirmDetails,
  ) => {
    const t = await ctx.get(translationAtoms('common'));

    const decision = await ShowModal.decide(ctx, {
      title,
      message,
      options: [
        {
          key: 'confirm' as const,
          variant: variant === 'severe' ? 'severe' : 'positive',
          label: confirmActionLabel ?? t('confirm'),
        },
        {
          key: 'cancel' as const,
          variant: 'neutral',
          label: cancelActionLabel ?? t('cancel'),
        },
      ],
    });

    return decision === 'confirm';
  },
);

type ShowModalCtx = { get: Getter; set: Setter };

export const ShowModal = {
  info: async <T extends ActionDetails>({ set }: ShowModalCtx, details: T) =>
    (await set(
      showActionModalAtoms(infoModalAtom),
      details,
    )) as ModalOptions<T>,

  decide: async <T extends ActionDetails>({ set }: ShowModalCtx, details: T) =>
    (await set(
      showActionModalAtoms(decisionModalAtom),
      details,
    )) as ModalOptions<T>,

  confirm: async ({ set }: ShowModalCtx, details: ConfirmDetails) =>
    set(showConfirmModalAtom, details),
};

/**
 * @returns callbacks for showing action modals
 *  - use `info` for showing alert-like modal
 *  - use `decide` for basic decision modal
 *  - use `confirm` for confirm or cancel decision
 *
 *  Both callbacks return a promise which resolves after user chooses an option or cancels it.
 */
export const useShowModal = () => {
  const showInfo = useSetAtom(showActionModalAtoms(infoModalAtom));
  const showDecide = useSetAtom(showActionModalAtoms(decisionModalAtom));
  const showConfirm = useSetAtom(showConfirmModalAtom);

  const info = useCallback(
    <T extends ActionDetails>(details: T) =>
      showInfo(details) as Promise<ModalOptions<T>>,
    [showInfo],
  );

  const decide = useCallback(
    <T extends ActionDetails>(details: T) =>
      showDecide(details) as Promise<ModalOptions<T>>,
    [showDecide],
  );

  const confirm = useCallback(
    (details: ConfirmDetails) => showConfirm(details),
    [showConfirm],
  );

  const showModal = useMemo(
    () => ({
      info,
      decide,
      confirm,
    }),
    [info, decide, confirm],
  );

  return showModal;
};

/**
 * use this hook to implement global action modal.
 * @returns value of decision store and setter for decision store.
 */
export const useActionModal = (actionStoreAtom: ActionStoreAtom) => {
  const [actionStore, setDecisionStore] = useAtom(actionStoreAtom);
  const show = !!actionStore.resolve;

  const handleCancel = () => {
    actionStore.resolve?.(null);
    setDecisionStore({ ...actionStore, resolve: undefined });
  };

  const handleDecide = (option: string) => {
    actionStore.resolve?.(option);
    setDecisionStore({ ...actionStore, resolve: undefined });
  };

  return {
    show,
    handleCancel,
    handleDecide,
    details: actionStore,
  };
};
