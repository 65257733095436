import React from 'react';
import cs from 'classnames';

import Icon from '../Icon';
import Loader from '../Loader';
import { AvatarInputProps } from './types';

const AvatarInput = ({
  avatarUrl,
  loading,
  variant = 'small',
  icon = 'camera',
}: AvatarInputProps) => {
  return (
    <div className="relative group max-w-fit mx-auto xl:my-16 my-10 cursor-pointer">
      {loading ? (
        <div
          className={cs(
            'rounded-full border-4 border-white shadow-drop-01 group-hover:opacity-75 flex items-center justify-center',
            variant === 'small' ? 'w-24 h-24' : 'w-[114px] h-[114px]',
          )}
        >
          <Loader />
        </div>
      ) : (
        <img
          src={avatarUrl}
          alt="User Avatar"
          className={cs(
            'rounded-full border-4 border-white shadow-drop-01 group-hover:opacity-75 object-cover',
            variant === 'small' ? 'w-24 h-24' : 'w-[114px] h-[114px]',
          )}
        />
      )}
      {icon === 'camera' ? (
        <div className="bg-primary group-hover:bg-primary-light rounded-full w-8 h-8 absolute -bottom-1 right-0 flex items-center justify-center">
          <Icon name="Camera" className="text-white" />
        </div>
      ) : (
        <Icon name="Edit" className="absolute -bottom-1 right-0" />
      )}
    </div>
  );
};

export default AvatarInput;
