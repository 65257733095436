import { TypeOf, ZodRecord } from 'zod';
import { fromPairs, toPairs } from 'lodash-es';
import {
  Atom,
  Getter,
  Setter,
  WritableAtom,
  SetStateAction,
  atom,
} from 'jotai';

import { myIdAtom } from '@advisor/api/me';
import { lazyAtom } from '@advisor/utils/atoms';
import { TranslationStatusType } from '@advisor/api/generated/graphql';
import { LanguageCode, parseLanguageCode } from '@advisor/language';
import {
  AutotranslationState,
  computeContentTranslationStatus,
  parseTranslatedContent,
} from '@advisor/chat/contentTranslation';
import { decodeSpecialCharacters } from '../utils';
import {
  DefaultItemChatRoomId,
  LangOrToken,
  RawTranslatable,
  TranslateOptimisticFields,
  TranslationAction,
  TranslationToken,
} from './types';

type PromiseOrValue<T> = Promise<T> | T;

type Options<T extends ZodRecord> = {
  targetLanguageAtom: Atom<PromiseOrValue<LanguageCode | undefined>>;
  autotranslationStateAtom: Atom<
    PromiseOrValue<AutotranslationState | undefined>
  >;
  translationsSchema: T;
  translatableAtom: Atom<PromiseOrValue<RawTranslatable | null | undefined>>;
  translateContentAtom: WritableAtom<
    unknown,
    [targetLanguage: LanguageCode, optimisticFields: TranslateOptimisticFields],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise<any>
  >;
  updateStatusAtom: WritableAtom<
    unknown,
    [status: TranslationStatusType.Original | null],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise<any>
  >;
  statusOverrideAtom: WritableAtom<
    PromiseOrValue<LangOrToken | null>,
    [SetStateAction<LangOrToken | null>],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
};

function decodeTranslation<T extends ZodRecord>(
  translation: TypeOf<T>[string] | undefined,
) {
  if (!translation) {
    return undefined;
  }

  // TODO: Workaround until the string representation is no longer being used.
  return typeof translation === 'string'
    ? (decodeSpecialCharacters(translation) as TypeOf<T>[string])
    : (fromPairs(
        toPairs(translation).map(([key, value]) => {
          return [
            key,
            typeof value === 'string' ? decodeSpecialCharacters(value) : value,
          ];
        }),
      ) as TypeOf<T>[string]);
}

const makeContentTranslationAtoms = <T extends ZodRecord>({
  targetLanguageAtom,
  autotranslationStateAtom,
  translationsSchema,
  translatableAtom,
  translateContentAtom,
  updateStatusAtom,
  statusOverrideAtom,
}: Options<T>) => {
  const parsedAtom = atom(async (get) => {
    const myId = await get(myIdAtom);
    const targetLanguage = await get(targetLanguageAtom);
    const autotranslationState = await get(autotranslationStateAtom);
    const translatable = await get(translatableAtom);

    if (!myId || !translatable || !targetLanguage || !autotranslationState) {
      return undefined;
    }

    const { translations, statuses } = parseTranslatedContent(
      translationsSchema,
      translatable,
      myId,
    );

    const status = computeContentTranslationStatus({
      userStatus: (await get(statusOverrideAtom)) ?? statuses[myId],
      inherentLanguage: parseLanguageCode(translatable.detectedLanguage),
      targetLanguage,
      autotranslationState,
      translations,
    });

    return { translations, status };
  });

  const translationAtom = lazyAtom(
    async (get) => {
      const { translations, status } = (await get(parsedAtom)) ?? {};

      if (!translations || !status) {
        return undefined;
      }

      const translation =
        status.type === 'translated'
          ? (translations[status.language] as TypeOf<T>[string])
          : undefined;

      // Decoding special characters
      return decodeTranslation(translation);
    },
    async (get: Getter, set: Setter, action: TranslationAction) => {
      const { status, translations } = (await get(parsedAtom)) ?? {};

      if (!status || !translations) {
        return;
      }

      const myId = await get(myIdAtom);
      const translatable = await get(translatableAtom);
      const targetLanguage = await get(targetLanguageAtom);
      const autotranslationState = await get(autotranslationStateAtom);
      const autotranslationEnabled =
        autotranslationState !== AutotranslationState.Disabled;

      if (!myId || !translatable || !targetLanguage) {
        return;
      }

      const isDefaultItem = translatable.chatRoomId === DefaultItemChatRoomId;

      // Handling translation locally for default milestone categories
      const overrideAtom = isDefaultItem ? statusOverrideAtom : null;

      if (action === TranslationAction.Translate) {
        if (status.type !== 'missing' && status.type !== 'raw') {
          return;
        }

        if (autotranslationEnabled) {
          // Clearing the translation status, meaning the message will
          // be automatically translated.
          await set(overrideAtom ?? updateStatusAtom, null);
        } else {
          await set(overrideAtom ?? translateContentAtom, targetLanguage, {
            translations: JSON.stringify(translations),
            translationStatus: JSON.stringify({
              [myId]:
                targetLanguage in translations
                  ? targetLanguage
                  : TranslationToken.InProgress,
            }),
          });
        }
      }

      if (action === TranslationAction.UndoTranslation) {
        if (status.type !== 'translated' && status.type !== 'in_progress') {
          return;
        }

        await set(
          overrideAtom ?? updateStatusAtom,
          autotranslationEnabled ? TranslationStatusType.Original : null,
        );
      }
    },
  );

  const statusAtom = lazyAtom(async (get) => {
    return (await get(parsedAtom))?.status;
  });

  const shouldTranslationBeDisabledAtom = lazyAtom(async (get) => {
    const targetLanguage = await get(targetLanguageAtom);
    const translatable = await get(translatableAtom);

    return !targetLanguage || targetLanguage === translatable?.detectedLanguage;
  });

  return {
    translationAtom,
    translatableAtom,
    targetLanguageAtom,
    autotranslationStateAtom,
    statusAtom,
    shouldTranslationBeDisabledAtom,
  };
};

export default makeContentTranslationAtoms;
