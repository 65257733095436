import { z } from 'zod';

export interface MilestoneCategoryFromProps {
  defaultValues: CategoryPayload;
  onSubmit: (payload: CategoryPayload) => void;
}

export type CategoryPayload = z.infer<typeof CategoryPayload>;
export const CategoryPayload = z.object({
  name: z.string().min(1),
});
