import z, { ZodSchema } from 'zod';
import { get, set } from 'lodash-es';

import ComplexError from '@advisor/utils/ComplexError';
import Sentry from '@advisor/utils/Sentry';

class UserMetadataLocation<Schema extends ZodSchema> {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private readonly path: string, private readonly schema: Schema) {}

  getFrom(record: Record<string, unknown>) {
    const rawValue = get(record, this.path);

    if (rawValue === undefined) {
      return undefined;
    }

    const parsed = this.schema.safeParse(rawValue);

    if (!parsed.success) {
      Sentry.captureException(
        new ComplexError(
          `Failed to parse user metadata at path '${this.path}'`,
          parsed.error,
        ),
      );
      return undefined;
    }

    return parsed.data;
  }

  computeNext(rawRecord: Record<string, unknown>, newValue: z.infer<Schema>) {
    return set({ ...rawRecord }, this.path, newValue);
  }
}

export default UserMetadataLocation;
