import React from 'react';

import Icon from '../Icon';
import { CreateButtonProps } from './types';

const CreateButton: React.FC<CreateButtonProps> = ({
  disabled,
  onPress,
  tooltip,
}) => {
  if (!tooltip) {
    return (
      <div className="fixed bottom-6 ltr:right-6 rtl:left-6">
        <button
          type="button"
          disabled={disabled}
          className={`
          bg-primary w-12 h-12 rounded-full
            flex justify-center items-center
            transition ease-in-out duration-200
            hover:scale-[1.15] hover:active:scale-[1.3] hover:active:opacity-75
            disabled:scale-0
          `}
          onClick={onPress}
        >
          <Icon name="Add" size={24} color="white" />
        </button>
      </div>
    );
  }

  return (
    <div className="fixed bottom-6 ltr:right-6 rtl:left-6">
      {React.cloneElement(tooltip, {
        children: (
          <button
            type="button"
            disabled={disabled}
            className={`
            bg-primary w-12 h-12 rounded-full
              flex justify-center items-center
              transition ease-in-out duration-200
              hover:scale-[1.15] hover:active:scale-[1.3] hover:active:opacity-75
              disabled:scale-0
            `}
            onClick={onPress}
          >
            <Icon name="Add" size={24} color="white" />
          </button>
        ),
      })}
    </div>
  );
};

export default CreateButton;
