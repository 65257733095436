import React from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleMessage } from '../systemMessageComponents';
import { SystemMessageProps } from '../types';

const JoinStudentConversation: React.FC<SystemMessageProps> = () => {
  const { t } = useTranslation('system-message');

  return (
    <SimpleMessage>
      {t('connecting-you-to-your-requested-conversation')}
    </SimpleMessage>
  );
};

export default JoinStudentConversation;
