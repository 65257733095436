import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from '@advisor/design/components';
import { searchByNameAtom } from '../../ChatRoomList/atoms';

const SearchBar: React.FC = () => {
  const [searchByName, setSearchByName] = useAtom(searchByNameAtom);
  const { t } = useTranslation('microbot');

  return (
    <SearchInput
      value={searchByName}
      onChange={setSearchByName}
      className="h-10 color-dark-grey-03"
      placeholder={t('search-by-name')}
    />
  );
};

export default memo(SearchBar);
