/**
 * Wraps `innerFn` with a guard against null and undefined values.
 * If the passed in value is null or undefined, `fallbackReturn` gets returned.
 */
function ifExists<TArg, TReturn extends void | undefined>(
  innerFn: (value: TArg) => TReturn,
  fallbackReturn = undefined,
) {
  return (maybeValue: TArg | null | undefined) => {
    if (maybeValue !== null && maybeValue !== undefined) {
      return innerFn(maybeValue);
    }

    return fallbackReturn;
  };
}

export default ifExists;
