import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormats from 'dayjs/plugin/localizedFormat';

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormats);

export const formatTzDifference = (diff: number) => {
  // this works well with negative values
  return dayjs.duration(diff).humanize();
};

/**
 * The backend calculates milestone notification time based on DueDate.
 * It does it by ignoring the timezone of the date and then converting
 * it to the timezone of the student (Forcefully, overwrites the timezone,
 * resulting in a different point in time).
 *
 * See the code here:
 * backend/lambda/repositories/journey/milestone.py > MilestoneRepository > get_utc_notification_time
 *
 * We always send dates as ISO Strings which always are in UTC, hence
 * to achieve the desired notification time we need to convert the date
 * to UTC keeping the local time. (Forcefully overwriting the timezone to UTC)
 *
 * @returns ISO String in UTC
 */
export const normalizeDueDateForBackend = (date?: Date) => {
  if (!date) {
    return undefined;
  }

  return dayjs(date).utc(true).toISOString();
};
