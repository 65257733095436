import type { Component } from 'react';
import type { NativeMethods } from 'react-native';

type Target = Component<unknown, Record<string, never>, unknown> &
  Readonly<NativeMethods>;

// eslint-disable-next-line import/prefer-default-export
export const fakeBaseSyntheticEvent = {
  currentTarget: 0 as unknown as Target,
  target: 0 as unknown as Target,
  bubbles: false,
  cancelable: false,
  defaultPrevented: false,
  eventPhase: 0,
  isTrusted: false,
  preventDefault(): void {
    throw new Error('Function not implemented.');
  },
  isDefaultPrevented(): boolean {
    throw new Error('Function not implemented.');
  },
  stopPropagation(): void {
    throw new Error('Function not implemented.');
  },
  isPropagationStopped(): boolean {
    throw new Error('Function not implemented.');
  },
  persist(): void {
    throw new Error('Function not implemented.');
  },
  timeStamp: 0,
  type: '',
} as const;
