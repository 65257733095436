import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Sentry from '@advisor/utils/Sentry';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { showToast } from '@advisor/design/components/Toast';
import {
  useAcceptFamilyMemberJoinRequestMutation,
  useRejectFamilyMemberJoinRequestMutation,
  JoinRequestsQuery,
  JoinRequestsDocument,
  AcceptFamilyMemberJoinRequestMutationOptions,
  RejectFamilyMemberJoinRequestMutationOptions,
} from '@advisor/api/generated/graphql';
import JoinRequestDisplayCount from './utils';

const useJoinRequestBanner = (joinRequestId: string, chatRoomId: string) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation(['common', 'onboarding']);

  const showModal = useShowModal();

  const [approveJoinRequestMutation] =
    useAcceptFamilyMemberJoinRequestMutation();

  const [rejectJoinRequestMutation] =
    useRejectFamilyMemberJoinRequestMutation();

  const onApprove = useCallback(async () => {
    try {
      setIsProcessing(true);
      await approveJoinRequestMutation({
        ...joinRequestActionMutationOptions(joinRequestId, chatRoomId),
      });

      return true;
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
      return false;
    } finally {
      setIsProcessing(false);
    }
  }, [approveJoinRequestMutation, chatRoomId, joinRequestId]);

  const onDecline = useCallback(async () => {
    setIsProcessing(true);
    const confirm = await showModal.confirm({
      variant: 'severe',
      title: t('onboarding:are-you-sure-you-want-to-decline-join-req'),
      message: t('common:this-action-cannot-be-reversed'),
      confirmActionLabel: t('onboarding:decline'),
    });

    if (!confirm) {
      setIsProcessing(false);

      return false;
    }

    try {
      await rejectJoinRequestMutation({
        ...joinRequestActionMutationOptions(joinRequestId, chatRoomId),
      });

      showToast({
        iconName: 'Warning',
        variant: 'rose',
        messageI18Key: 'join-request-was-denied',
        namespace: 'onboarding',
      });

      return true;
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
      return false;
    } finally {
      setIsProcessing(false);
    }
  }, [rejectJoinRequestMutation, chatRoomId, joinRequestId, showModal, t]);

  return {
    isProcessing,
    onApprove,
    onDecline,
  };
};

export default useJoinRequestBanner;

const joinRequestActionMutationOptions = (
  joinRequestId: string,
  chatRoomId: string,
): AcceptFamilyMemberJoinRequestMutationOptions &
  RejectFamilyMemberJoinRequestMutationOptions => ({
  variables: {
    joinRequestId,
  },
  refetchQueries: [
    {
      query: JoinRequestsDocument,
      variables: {
        chatRoomId,
        limit: JoinRequestDisplayCount,
      },
    },
  ],
  update(cache) {
    cache.updateQuery<JoinRequestsQuery>(
      { query: JoinRequestsDocument },
      (data) => {
        return data ? deleteJoinRequest(data, joinRequestId) : data;
      },
    );
  },
});

const deleteJoinRequest = (
  query: JoinRequestsQuery,
  joinRequestId: string,
): JoinRequestsQuery => {
  const joinRequests = query.joinRequests.filter(
    (request) => request.id !== joinRequestId,
  );

  return {
    ...query,
    joinRequests,
  };
};
