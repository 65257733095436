import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';

import { SwipeTransitionProps } from './types';

function SwipeTransition({ show, direction, children }: SwipeTransitionProps) {
  return (
    <Transition
      appear
      as={Fragment}
      show={show}
      enter="transition-transform ease-out delay-100 duration-100"
      enterFrom={direction === 'right' ? 'translate-x-10' : '-translate-x-10'}
      enterTo="translate-x-0"
      leave="transition-transform ease-in duration-100"
      leaveFrom="translate-x-0"
      leaveTo={direction === 'right' ? 'translate-x-10' : '-translate-x-10'}
    >
      {children}
    </Transition>
  );
}

export default SwipeTransition;
