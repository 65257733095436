import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { JourneyMilestoneOverDueSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import { BoldText, Message, SimpleMessage } from '../systemMessageComponents';
import { useOpenJourneyMilestone } from '../navigation';
import type { SystemMessageProps } from '../types';

const TomorrowMilestoneDueDateReminder: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const data = message.data as JourneyMilestoneOverDueSystemMessageInfoFragment;

  const openMilestone = useOpenJourneyMilestone();

  const onPress = useEvent(() =>
    openMilestone(
      message.chatRoomId,
      data.milestone?.milestoneCategoryId,
      data.milestone?.id,
    ),
  );

  const milestone = data.milestone?.name ?? data.metadata.milestoneName;

  return (
    <SimpleMessage onPress={onPress}>
      <Trans
        t={t}
        parent={Message}
        values={{ task: milestone }}
        components={{ bold: <BoldText /> }}
        i18nKey="the-task-is-due-tomorrow-please-complete-it-on-time"
      />
    </SimpleMessage>
  );
};

export default memo(TomorrowMilestoneDueDateReminder);
