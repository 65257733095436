import React from 'react';

import { Layout, Skeleton } from '@advisor/design/components';
import { Typography } from '@advisor/design/styles';
import { ChatRoomRowSkeletonProps } from './types';

const ChatRoomRowSkeleton = ({ animationDelay }: ChatRoomRowSkeletonProps) => (
  <Skeleton.Ping animationDelay={animationDelay}>
    <div className="px-4 flex flex-row items-stretch border border-white">
      <Skeleton.Avatar size={40} />
      <Layout.Spacer.Horizontal size="micro02" />
      <div className="grow items-start justify-center gap-1">
        <Skeleton.Text {...Typography.Outfit.bodySmall} size={91} />
        <Layout.Spacer.Vertical size="atomic" />
        <Skeleton.Text {...Typography.Outfit.bodySmall} size={241} />
      </div>
    </div>
  </Skeleton.Ping>
);

export default ChatRoomRowSkeleton;
