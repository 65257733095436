import { atom, useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';

const audioContextAtom = atom(() => new AudioContext());

const MaxSamples = 20;

function useVolumeAnalysis(track: MediaStreamTrack | undefined) {
  const audioContext = useAtomValue(audioContextAtom);
  const [speaking, setSpeaking] = useState(false);
  const samplesCollected = useRef<number[]>([]);

  // TODO: Use this callback with the upcoming `useAudioLevel` hook from `@daily-co/daily-react`.
  // const handleNewVolume = useCallback(
  //   (value: number) => {
  //     samplesCollected.current.push(value);
  //     console.log({ volume: value });

  //     if (samplesCollected.current.length >= MaxSamples) {
  //       setVolume(
  //         samplesCollected.current.reduce((a, b) => a + b) /
  //           samplesCollected.current.length,
  //       );
  //       samplesCollected.current = [];
  //     }
  //   },
  //   [setVolume, samplesCollected],
  // );

  useEffect(() => {
    if (!track) return undefined;

    audioContext.resume();

    const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(
      new MediaStream([track]),
    );
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Float32Array(analyserNode.fftSize);

    let handle = window.requestAnimationFrame(onFrame);
    function onFrame() {
      handle = window.requestAnimationFrame(onFrame);

      analyserNode.getFloatTimeDomainData(pcmData);

      const sumSquares = pcmData.reduce((sum, a) => sum + a * a);

      samplesCollected.current.push(sumSquares / pcmData.length);

      if (samplesCollected.current.length >= MaxSamples) {
        const maxVolume = samplesCollected.current.reduce((a, b) =>
          Math.max(a, b),
        );

        const compressedVolume = Math.max(
          0,
          Math.min(Math.sqrt(maxVolume) * 50 - 1, 1),
        );
        setSpeaking(compressedVolume > 0);

        samplesCollected.current = [];
      }
    }

    return () => {
      cancelAnimationFrame(handle);
    };
  }, [track, audioContext]);

  return speaking;
}

export default useVolumeAnalysis;
