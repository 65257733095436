import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton } from '@advisor/design/components';
import DeleteAccountLayout from 'src/components/layouts/DeleteAccountLayout';
import {
  Subtitle,
  Paragraph,
} from '../components/atoms/DeleteAccountComponents';

const DeleteAccountSuccessPage: React.FC = () => {
  const { t } = useTranslation(['onboarding', 'common']);

  const navigate = useNavigate();

  return (
    <DeleteAccountLayout title={t('onboarding:account-deleted')}>
      <div className="max-w-3xl">
        <Subtitle>
          {t('onboarding:delete-account-successfully-deleted')}
        </Subtitle>
        <Paragraph className="md:leading-7">
          {t('onboarding:delete-account-removed-from-system')}
        </Paragraph>
        <div className="flex flex-col gap-5 pt-5">
          <Paragraph>
            {t('onboarding:delete-account-data-has-been-erased')}
          </Paragraph>
          <Paragraph>
            {t('onboarding:delete-account-remember-once-deleted')}
          </Paragraph>
          <DeprecatedButton.Large
            variant="primary"
            className="text-base leading-[21.92px] "
            onPress={() => navigate('/')}
          >
            {t('common:go-home')}
          </DeprecatedButton.Large>
        </div>
      </div>
    </DeleteAccountLayout>
  );
};

export default DeleteAccountSuccessPage;
