import { Atom, atom } from 'jotai';
import { getI18n, getDefaults } from 'react-i18next';
import { atomFamily, atomWithObservable } from 'jotai/utils';
import { i18n, Namespace, CustomTypeOptions, TFunction } from 'i18next';

import type { Observer } from '@advisor/utils/observable';

function getT(
  i18nInstance: i18n,
  fallbackNamespace: keyof CustomTypeOptions['resources'],
) {
  // NOTE: Assuming that our app will not use a `keyPrefix` anytime soon.
  return i18nInstance.getFixedT(null, fallbackNamespace, undefined);
}

const translationAtoms = (() => {
  const family = atomFamily(
    (ns: Namespace) => {
      const i18nInstance = getI18n();

      if (!i18nInstance) {
        throw new Error(`Failed to provided i18n instance to translationAtoms`);
      }

      const i18nOptions = { ...getDefaults(), ...i18nInstance.options.react };

      // prepare having a namespace
      const namespaces: readonly (keyof CustomTypeOptions['resources'])[] =
        typeof ns === 'string' ? [ns] : ns || ['translation'];

      // report namespaces as used
      if (i18nInstance.reportNamespaces?.addUsedNamespaces) {
        i18nInstance.reportNamespaces.addUsedNamespaces(namespaces);
      }

      const refreshAtom = atomWithObservable(
        () => {
          let refreshCount = 0;

          return {
            subscribe(observer: Observer<number>) {
              const { bindI18n, bindI18nStore } = i18nOptions;

              const boundReset = () => {
                refreshCount += 1;
                observer.next(refreshCount);
              };

              // bind events to trigger change, like languageChanged
              if (bindI18n && i18nInstance) {
                i18nInstance.on(bindI18n, boundReset);
              }

              if (bindI18nStore && i18nInstance) {
                i18nInstance.store.on(
                  bindI18nStore as 'added' | 'removed',
                  boundReset,
                );
              }

              return {
                unsubscribe: () => {
                  if (bindI18n && i18nInstance) {
                    bindI18n
                      .split(' ')
                      .forEach((e) => i18nInstance.off(e, boundReset));
                  }

                  if (bindI18nStore && i18nInstance) {
                    bindI18nStore
                      .split(' ')
                      .forEach((e) =>
                        i18nInstance.store.off(
                          e as 'added' | 'removed',
                          boundReset,
                        ),
                      );
                  }
                },
              };
            },
          };
        },
        { initialValue: 0 },
      );

      const tFuncAtom = atom((get) => {
        get(refreshAtom);

        return getT(i18nInstance, namespaces[0]);
      });

      return atom(async (get) => {
        await i18nInstance.loadNamespaces(namespaces);
        return get(tFuncAtom);
      });
    },
    (a, b) => {
      const arrayA = typeof a === 'string' ? [a] : a;
      const arrayB = typeof b === 'string' ? [b] : b;

      return arrayA.join() === arrayB.join();
    },
  );

  return <Ns extends Namespace>(ns: Ns) => {
    return family(ns) as Atom<Promise<TFunction<Ns, undefined>>>;
  };
})();

export default translationAtoms;
