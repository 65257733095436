/* eslint-disable react/no-unused-prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SizeAdaptingWrapper } from '@advisor/design/components';
import { ScreenShareStatus } from '../../VideoRoomAPI';
import useScreenSharingUsers from '../useScreenSharingUsers';
import useScreenShare from '../useScreenShare';
import BannerShell from './BannerShell';

type SharingBannerProps = {
  actingUsersName?: string;
  onStopSharing(): void;
};

function YouAreScreenSharingBanner({ onStopSharing }: SharingBannerProps) {
  const { t } = useTranslation('common');

  return (
    <BannerShell bordered onStopSharing={onStopSharing}>
      {t('you-are-sharing-your-screen-to-everyone')}
    </BannerShell>
  );
}

function SomeoneIsScreenSharingBanner({ actingUsersName }: SharingBannerProps) {
  const { t } = useTranslation('common');

  return (
    <BannerShell>
      {t('user-name-is-sharing-their-screen-to-everyone', {
        name: actingUsersName,
      })}
    </BannerShell>
  );
}

function EmptyBanner(_props: SharingBannerProps) {
  return null;
}

function ScreenShareStatusBanner() {
  const [screenShareStatus, changeScreenShare] = useScreenShare();
  const screenSharingUsers = useScreenSharingUsers();

  const innerComponent = useMemo(() => {
    if (screenShareStatus === ScreenShareStatus.Sharing) {
      return YouAreScreenSharingBanner;
    }
    if (screenSharingUsers.length > 0) {
      return SomeoneIsScreenSharingBanner;
    }
    return EmptyBanner;
  }, [screenShareStatus, screenSharingUsers.length]);

  const commonProps = useMemo(
    () => ({
      actingUsersName: screenSharingUsers[0]?.name,
      onStopSharing: () => changeScreenShare('unshare'),
    }),
    [screenSharingUsers, changeScreenShare],
  );

  return (
    <SizeAdaptingWrapper
      lockX
      commonProps={commonProps}
      inner={innerComponent}
    />
  );
}

export default ScreenShareStatusBanner;
