import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import {
  CarlConfiguration,
  PamConfiguration,
} from '../components/MicrobotConfiguration';

export enum ComponentPosition {
  Top,
  Bottom,
}

const ComponentMap = {
  [MicrobotPersonality.Pam]: {
    Component: PamConfiguration,
    position: ComponentPosition.Bottom,
  },
  [MicrobotPersonality.Carl]: {
    Component: CarlConfiguration,
    position: ComponentPosition.Top,
  },
  [MicrobotPersonality.Astro]: undefined,
};

const useMicrobotConfigurationComponent = (
  personality: MicrobotPersonality | undefined,
) => (personality ? ComponentMap[personality] : undefined);

export default useMicrobotConfigurationComponent;
