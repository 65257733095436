import type { FetchResult } from '@apollo/client';
import { print } from 'graphql/language/printer';

import Env from '../env';
import type { DocumentNode } from '../generated/graphql';

/*
  Note: DocumentNodes after last codegen changes no more contain .loc.body
  properties. Instead we use print function to retrieve the GQL string.
  Sources:
  - https://github.com/apollographql/graphql-tag/issues/144
  - https://graphql.org/graphql-js/language/
*/
export type UnauthorizedQueryOptions<TResult, TVariables> = {
  query: DocumentNode<TResult, TVariables>;
  variables: TVariables;
};

/**
 * Allows to execute GraphQL queries without an authorized Apollo client.
 */
async function unauthorizedQuery<TResult = never, TVariables = never>(
  options: UnauthorizedQueryOptions<TResult, TVariables>,
) {
  const response = await fetch(Env.api.graphQLUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/graphql',
      Authorization: 'UNAUTHORIZED_TOKEN',
    },
    body: JSON.stringify({
      query: print(options.query),
      variables: options.variables,
    }),
  });

  return response.json() as Promise<Omit<FetchResult<TResult>, 'context'>>;
}

export default unauthorizedQuery;
