import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';

import { useEvent } from '@advisor/utils/hooks';
import useResizeObserver from '@advisor/utils/hooks/useResizeObserver';
import { Colorspace, PointerSize } from '../utils';
import { SaturationValueSliderProps, PointerPosition } from './types';

type ContainerDimensions = {
  width: number;
  height: number;
};

const PickerBox: React.FC<SaturationValueSliderProps> = (props) => {
  const { value, setValue } = props;
  const [dimensions, setDimensions] = useState<ContainerDimensions>({
    width: 0,
    height: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const pointerPosition = useMemo<PointerPosition>(
    () => ({
      top: (1 - value.value) * dimensions.height,
      left: value.saturation * dimensions.width,
    }),
    [value.value, value.saturation, dimensions],
  );
  const [isDragged, setIsDragged] = useState(false);

  const onMouseDown = useEvent((event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragged(true);
    if (containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();

      const top = Math.max(
        0,
        Math.min(event.clientY, boundingRect.bottom) - boundingRect.top,
      );

      const left = Math.max(
        0,
        Math.min(event.clientX, boundingRect.right) - boundingRect.left,
      );

      setValue(left / boundingRect.width, 1 - top / boundingRect.height);
    }
  });

  const onMouseUp = useEvent(() => {
    setIsDragged(false);
  });

  const onMouseMove = useEvent((event: MouseEvent) => {
    if (containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();

      const top = Math.max(
        0,
        Math.min(event.clientY, boundingRect.bottom) - boundingRect.top,
      );

      const left = Math.max(
        0,
        Math.min(event.clientX, boundingRect.right) - boundingRect.left,
      );

      setValue(left / boundingRect.width, 1 - top / boundingRect.height);
    }
  });

  useResizeObserver(
    containerRef,
    useCallback(({ contentRect }) => {
      setDimensions({
        width: contentRect.width,
        height: contentRect.height,
      });
    }, []),
  );

  useEffect(() => {
    if (isDragged) {
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);
    } else {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isDragged, onMouseUp, onMouseMove]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={containerRef}
      onMouseDown={onMouseDown}
      className="h-48 relative rounded border border-grey"
    >
      <div
        className="absolute inset-0 rounded"
        style={{ backgroundColor: `hsl(${value.hue} 100% 50%)` }}
      />
      <div className="absolute inset-0 bg-gradient-to-l from-transparent to-white rounded" />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/[0.65] to-black rounded" />
      <div
        draggable="false"
        className={cs(
          'w-6 h-6 bg-white rounded-full absolute -top-2 grid place-content-center border-[1.5px] border-black/10',
          isDragged ? 'cursor-grabbing' : 'cursor-grab',
        )}
        style={{
          top: pointerPosition.top - PointerSize / 2,
          left: pointerPosition.left - PointerSize / 2,
        }}
      >
        <div
          draggable="false"
          className="w-3 h-3 rounded-full"
          style={{ backgroundColor: Colorspace.HSVtoHSL(value) }}
        />
      </div>
    </div>
  );
};

export default PickerBox;
