import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@advisor/design/Theme';

interface Props {
  className?: string;
  glow?: boolean;
}

const StartMyJourneyButton: React.FC<Props> = ({ className, glow = true }) => {
  const { t } = useTranslation(['global-study-homepage', 'common']);
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <button
      type="button"
      className={cs(
        'relative button overflow-visible text-off-white text-xs sm:text-sm font-sora font-medium tracking-widest uppercase px-10 py-6 transition-colors bg-primary hover:bg-primary-light',
        className,
      )}
      onClick={() => navigate('/login')}
      data-cy="start-my-journey"
    >
      <div
        className="absolute inset-0 rounded-10 animate-small-ping-vertical border-primary"
        style={
          glow
            ? {
                boxShadow: `0px 4px 24px rgba(255, 255, 255, 0.2), 0px 0px 12px ${theme.colors.primaryLight05}`,
              }
            : undefined
        }
      />
      <span>{t('common:start-my-journey')}</span>
    </button>
  );
};

export default StartMyJourneyButton;
