import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import { GuideTabProps } from './types';

const GuideTab: React.FC<GuideTabProps> = ({ onGoBack }) => {
  const { t } = useTranslation('microbot');

  return (
    <>
      {/* Header */}
      <div className="px-8 py-6 border-b border-grey flex gap-4">
        <DeprecatedButton.Icon
          icon="ArrowLeft"
          variant="darkGrey03"
          onPress={onGoBack}
        />
        <div>
          <h3 className="text-xl leading-6 font-sora font-semibold text-dark-grey-03">
            {t('how-to-use-primary-context')}
          </h3>
          <h5 className="font-outfit text-sm leading-[1.125rem] text-dark-grey-025">
            {t('a-guide')}
          </h5>
        </div>
      </div>

      {/* Body */}
      <div className="px-8 py-5">
        <p className="font-outfit leading-5 text-dark-grey-03 font-medium">
          {t('1-introduce-the-role-of-assistant')}
        </p>
        <Layout.Spacer.Vertical size="micro" />
        <p className="text-sm leading-[1.125rem] text-dark-grey-025 font-medium font-outfit">
          {t('when-you-start-the-ai-needs-to-know-what-its-role-is')}
        </p>

        <Layout.Spacer.Vertical size="small01" />

        <p className="font-outfit leading-5 text-dark-grey-03 font-medium">
          {t('2-define-the-customers-goal')}
        </p>
        <Layout.Spacer.Vertical size="micro" />
        <p className="text-sm leading-[1.125rem] text-dark-grey-025 font-medium font-outfit">
          {t('the-ai-needs-to-understand-that-the-ultimate-goal')}
        </p>

        <Layout.Spacer.Vertical size="small01" />

        <p className="font-outfit leading-5 text-dark-grey-03 font-medium">
          {t('3-direct-to-keep-it-short')}
        </p>
        <Layout.Spacer.Vertical size="micro" />
        <p className="text-sm leading-[1.125rem] text-dark-grey-025 font-medium font-outfit">
          {t('we-use-terms-such-as-i-want-you-to-answer-in-small-sentences')}
        </p>

        <Layout.Spacer.Vertical size="small01" />

        <p className="font-outfit leading-5 text-dark-grey-03 font-medium">
          {t('4-use-positive-descriptors')}
        </p>
        <Layout.Spacer.Vertical size="micro" />
        <p className="text-sm leading-[1.125rem] text-dark-grey-025 font-medium font-outfit">
          {t('this-ensures-that-the-ai-will-highlight-the-positive-aspects')}
        </p>

        <Layout.Spacer.Vertical size="small01" />

        <p className="font-outfit leading-5 text-dark-grey-03 font-medium">
          {t('5-define-tone')}
        </p>
        <Layout.Spacer.Vertical size="micro" />
        <p className="text-sm leading-[1.125rem] text-dark-grey-025 font-medium font-outfit">
          {t('when-it-comes-to-the-tone-you-want-to-mention')}
        </p>
      </div>
    </>
  );
};

export default GuideTab;
