import React, { memo } from 'react';
import cs from 'classnames';

type ClassNameProps = {
  className?: string;
};

const bubbleClassName = 'w-64 md:w-full max-w-xl xl:max-w-sm opacity-40';

const WhiteBubble = ({ className }: ClassNameProps) => (
  <div
    className={cs(
      'bg-white rounded-r-10 border border-light-grey ml-0 mr-auto',
      bubbleClassName,
      className,
    )}
  />
);

const BlueBubble = ({ className }: ClassNameProps) => (
  <div
    className={cs(
      'bg-primary rounded-l-10 rounded-tr-10 border border-grey ml-auto mr-0',
      bubbleClassName,
      className,
    )}
  />
);

const MessagesPlaceholder = () => (
  <div className="animate-pulse">
    <WhiteBubble className="h-20 mb-4" />
    <BlueBubble className="h-[112px] mb-2" />
    <WhiteBubble className="h-24 mb-4" />
    <WhiteBubble className="h-[128px] mb-4" />
    <BlueBubble className="h-24" />
  </div>
);

export default memo(MessagesPlaceholder);
