import { createContext } from 'react';

const ParamsContext = createContext<{
  /**
   * Equivalent to `count` from the ArchivalVideoChatRoom type, but renamed to obstruct
   * implementation details (in case they change) and add meaning to its desired use.
   */
  detailsId?: number;
}>({
  detailsId: undefined,
});

export default ParamsContext;
