import { z } from 'zod';
import { useMemo } from 'react';
import { setContext } from '@apollo/client/link/context';

import Sentry from '@advisor/utils/Sentry';
import * as network from '@advisor/utils/network';
import { AuthResult } from '../auth/types';
import RefreshTokensNetworkError from './errors/RefreshTokensNetworkError';
import RefreshTokensDeniedError from './errors/RefreshTokensDeniedError';

type Args = {
  getExpirationTime: () => number | undefined | Promise<number | undefined>;
  renewSession: () => Promise<AuthResult>;
  logout: () => void;
};

const shouldRefreshTokens = (expirationTime: number | undefined) => {
  return (
    typeof expirationTime === 'number' && expirationTime - 6000 <= Date.now()
  );
};

/**
 * Http error statuses that can be returned by auth0,
 * in case if refreshing access token is declined.
 * see {@link https://auth0.com/docs/api/authentication#standard-error-responses}
 */
const Auth0TokenErrorCodes = [400, 401, 403, 405, 429];

/**
 * Scheme of error returned by react-native-auth0
 * see {@link https://github.com/auth0/react-native-auth0/blob/master/src/auth/authError.ts}
 */
const AuthErrorRN = z.object({
  status: z.number().refine((status) => Auth0TokenErrorCodes.includes(status)),
});

const shouldLogout = (error: unknown) => {
  return AuthErrorRN.safeParse(error).success;
};

/**
 * Before every request checks if expiration time hasn't passed.
 * If it passed then awaits until renewSession finishes before making a request.
 *
 * @returns an ApolloLink with modified context
 */
const useRefreshTokensLink = ({
  getExpirationTime,
  renewSession,
  logout,
}: Args) => {
  return useMemo(() => {
    const refreshTokensLink = setContext(async () => {
      const expirationTime = await getExpirationTime();

      if (!shouldRefreshTokens(expirationTime)) {
        return {};
      }

      await network.newOnlinePromise();
      const renewResult = await renewSession();

      if (renewResult.success) {
        return {};
      }

      // Thrown errors are passed to the retry link
      // see useSubscriptionRetryLink.ts
      if (shouldLogout(renewResult.error)) {
        logout();
        Sentry.captureException(renewResult.error);
        throw new RefreshTokensDeniedError(renewResult.error);
      }

      throw new RefreshTokensNetworkError(renewResult.error);
    });

    return refreshTokensLink;
  }, [getExpirationTime, logout, renewSession]);
};

export default useRefreshTokensLink;
