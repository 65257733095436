import { atom } from 'jotai';
import type { DailyCall } from './LibProvider';

export type DailyState = {
  call: DailyCall;
  destroy: () => Promise<void>;
};

export const dailyStateAtom = atom<DailyState | undefined>(undefined);

export const callObjectAtom = atom((get) => get(dailyStateAtom)?.call);
