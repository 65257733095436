import { atom } from 'jotai';
import { molecule } from 'bunshi';

import {
  TranslateMessageDocument,
  UpdateTranslationStatusDocument,
  TranslationStatusType,
} from '@advisor/api/generated/graphql';
import { atomWithMutation } from '@advisor/api/apollo';
import ComplexError from '@advisor/utils/ComplexError';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import { showToast } from '@advisor/design/components/Toast';
import { actionAtom } from '@advisor/utils/atoms';
import { LanguageCode } from '@advisor/language';
import { myIdAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import {
  optimisticTranslationStatusFields,
  makeContentTranslationAtoms,
  localTranslationStatusOverrideAtoms,
  TranslateOptimisticFields,
} from '../contentTranslation';
import { userMessageInfoAtoms } from '../messaging/latestMessagesAtoms';
import { requireMessageScope } from '../messaging/messageMolecule';
import { autotranslationStateAtoms } from './autotranslationStateAtoms';
import { chatRoomLanguageAtoms } from './chatRoomLanguage';
import { MessageTranslations } from './types';

const translateMutationAtom = atomWithMutation(TranslateMessageDocument);

const makeTranslateContentAtom = (messageId: string) => {
  return actionAtom(
    async (
      { get, set },
      targetLanguage: LanguageCode,
      optimisticFields: TranslateOptimisticFields,
    ) => {
      const message = get(userMessageInfoAtoms(messageId));

      if (!message) {
        return false;
      }

      try {
        await set(translateMutationAtom, {
          variables: {
            messageId,
            targetLanguage,
            setTranslationStatus: true,
          },
          optimisticResponse: () => ({
            __typename: 'Mutation',
            translateMessage: {
              __typename: 'UserMessage',
              id: messageId,
              ...optimisticFields,
            },
          }),
        });
      } catch (e) {
        Sentry.captureException(e);
        showToast({
          messageI18Key: 'something-went-wrong-try-again',
          variant: 'rose',
          iconName: 'X',
        });
        return false;
      }

      return true;
    },
  );
};

const updateStatusMutationAtom = atomWithMutation(
  UpdateTranslationStatusDocument,
);

const makeUpdateStatusAtom = (id: string) => {
  return actionAtom(
    async ({ get, set }, status: TranslationStatusType | null) => {
      const myId = await get(myIdAtom);

      try {
        await set(updateStatusMutationAtom, {
          variables: {
            id,
            status,
          },
          optimisticResponse: myId
            ? {
                __typename: 'Mutation',
                updateTranslationStatus: {
                  __typename: 'UserMessage',
                  ...optimisticTranslationStatusFields(myId, id, status),
                },
              }
            : undefined,
        });
      } catch (err) {
        Sentry.captureException(
          new ComplexError(
            `Could not update message's translation status`,
            err,
          ),
        );
      }
    },
  );
};

const MessageTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const messageId = requireMessageScope();

  const messageAtom = userMessageInfoAtoms(messageId);

  const messageTranslationAtoms = makeContentTranslationAtoms({
    translationsSchema: MessageTranslations,
    targetLanguageAtom: chatRoomLanguageAtoms(chatRoomId),
    autotranslationStateAtom: autotranslationStateAtoms(chatRoomId),
    translatableAtom: atom(async (get) => {
      const myId = await get(myIdAtom);
      const message = await get(messageAtom);

      if (!message || message.author.id === myId) {
        return null;
      }

      return message;
    }),

    statusOverrideAtom: localTranslationStatusOverrideAtoms(
      `message_${messageId}`,
    ),
    translateContentAtom: makeTranslateContentAtom(messageId),
    updateStatusAtom: makeUpdateStatusAtom(messageId),
  });

  return messageTranslationAtoms;
});

export default MessageTranslationMolecule;
