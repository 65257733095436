import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useMarkMilestoneAsIncompleteMutation,
  MarkMilestoneAsIncompleteMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { useOpenCompleteMilestoneModal } from '../components/CompleteMilestoneModal';
import { useMilestone } from '../api/milestone';

const useToggleCompleteMilestone = () => {
  const { id, isCompleted } = useMilestone('required');

  const { t } = useTranslation('task-organiser');
  const showModal = useShowModal();
  const openCompleteModal = useOpenCompleteMilestoneModal(id);
  const [markAsIncomplete] = useMarkMilestoneAsIncompleteMutation({
    ...updateCategories,
    variables: { milestoneId: id },
  });

  const unCompleteMilestone = useCallback(async () => {
    const confirmed = await showModal.confirm({
      variant: 'severe',
      title: t('are-you-sure-you-want-to-undo-completion'),
      message: t(
        'task-will-revert-back-to-incomplete-for-both-you-and-the-customer',
      ),
      confirmActionLabel: t('mark-as-incomplete'),
    });

    if (!confirmed) {
      return;
    }

    try {
      await markAsIncomplete();

      showToast({
        iconName: 'X',
        variant: 'rose',
        namespace: 'task-organiser',
        messageI18Key: 'selected-task-was-marked-incomplete',
      });
    } catch (err) {
      Sentry.captureException(err);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  }, [markAsIncomplete, showModal, t]);

  return isCompleted ? unCompleteMilestone : openCompleteModal;
};

export default useToggleCompleteMilestone;

const updateCategories: MarkMilestoneAsIncompleteMutationOptions = {
  update(cache, result) {
    const milestone = result.data?.markMilestoneAsIncomplete;

    if (!milestone) {
      return;
    }

    const id = cache.identify({
      __typename: 'MilestoneCategory',
      id: milestone.milestoneCategoryId,
    });

    if (!id) {
      return;
    }

    const isOverdue =
      milestone?.notificationTime &&
      dayjs(milestone.notificationTime).diff(dayjs()) <= 0;

    // Only advisor can mark milestone as incomplete.
    // The appropriate fields update
    cache.modify({
      id,
      fields: {
        completedMilestones(prev: number) {
          return prev - 1;
        },
        overdueMilestones(prev: number) {
          return isOverdue ? prev + 1 : prev;
        },
      },
    });
  },
};
