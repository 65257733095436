import { useTranslation } from 'react-i18next';
import { ChangeEventHandler, memo, useCallback } from 'react';
import cs from 'classnames';

import { EmailInputProps } from '@advisor/chat/hooks/useEmailForm';

function EmailInput({
  emailAddress,
  onEmailChange,
  disabled,
  errorMessage,
}: EmailInputProps) {
  const { t } = useTranslation('common');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onEmailChange(target.value);
    },
    [onEmailChange],
  );

  return (
    <>
      <div
        className={cs(
          'h-12.5 w-[312px] md:w-[342px] relative border flex flex-row items-stretch rounded text-dark-grey-03 border-dark-grey-01',
          errorMessage !== undefined && 'border-negative',
        )}
        dir="ltr"
      >
        <input
          id="email-input"
          data-cy="email-input"
          className={cs(
            'px-3 w-full rounded text-sm md:text-base focus:outline-none font-bold placeholder:text-grey placeholder:font-normal global-rtl:placeholder:text-right ltr:text-left rtl:text-right',
            errorMessage !== undefined && 'text-negative',
          )}
          type="email"
          aria-label={t('email-address')}
          placeholder={t('email-address')}
          value={emailAddress}
          onChange={onInputChange}
          disabled={disabled}
        />
      </div>
      {errorMessage && (
        <p className="mt-3 font-outfit text-sm text-negative">{errorMessage}</p>
      )}
    </>
  );
}

export default memo(EmailInput);
