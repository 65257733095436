import cs from 'classnames';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SizeAdaptingWrapperProps } from './types';

function SizeAdaptingWrapper<
  P extends Record<string, unknown> = Record<string, never>,
>(props: SizeAdaptingWrapperProps<P>) {
  const { inner, commonProps, durationMs = 400, lockX, lockY } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const nextWrapperRef = useRef<HTMLSpanElement>(null);

  // Swapping this when swapping prev and next should preserve elements
  const aKeyRef = useRef('a');
  const bKeyRef = useRef('b');
  const swapKeys = useCallback(() => {
    const temp = aKeyRef.current;
    aKeyRef.current = bKeyRef.current;
    bKeyRef.current = temp;
  }, []);

  const [Prev, setPrev] = useState<ComponentType<P>>(() => inner);
  const [Next, setNext] = useState<ComponentType<P> | null>(null);

  useEffect(() => {
    if (inner === Prev || !contentRef.current || !containerRef.current) {
      return undefined;
    }

    // Has to be done via an additional function
    // for the setter to not mistake the component
    // as a function that transforms the state.
    setNext(() => inner);
    // Setting the width & height here before swapping components
    // so that the transition works smoothly.
    const bounds = contentRef.current.getBoundingClientRect();
    containerRef.current.style.width = `${bounds.width}px`;
    containerRef.current.style.height = `${bounds.height}px`;

    const handle = setTimeout(() => {
      setPrev(() => inner);
      setNext(null);
      swapKeys();
    }, durationMs);

    return () => {
      clearTimeout(handle);
      setPrev(() => inner);
    };
  }, [inner, Prev, durationMs, swapKeys]);

  const transitionDurationStyle = useMemo(
    () => ({
      transitionDuration: `${durationMs}ms`,
    }),
    [durationMs],
  );

  const transitionOutgoingDurationStyle = useMemo(
    () => ({
      transitionDuration: `${durationMs / 2}ms`,
    }),
    [durationMs],
  );

  const transitionIncomingDurationStyle = useMemo(
    () => ({
      transitionDuration: `${durationMs / 2}ms`,
      transitionDelay: `${durationMs / 2}ms`,
    }),
    [durationMs],
  );

  // Updating container size after DOM updates
  useEffect(() => {
    if (!contentRef.current || !containerRef.current) {
      return;
    }

    const bounds = contentRef.current.getBoundingClientRect();
    if (Next) {
      containerRef.current.style.width = `${bounds.width}px`;
      containerRef.current.style.height = `${bounds.height}px`;
      containerRef.current.style.overflow = 'hidden';
    } else {
      containerRef.current.style.width = '';
      containerRef.current.style.height = '';
      containerRef.current.style.overflow = '';
    }
  }, [Next]);

  const contentAlignClasses = useMemo(() => {
    if (lockX && lockY) {
      // Note: Makes this component redundant
      return 'inset-0';
    }

    if (lockX) {
      return 'inset-x-0 top-0';
    }

    if (lockY) {
      return 'inset-y-0 left-0';
    }

    return 'top-0 left-0';
  }, [lockX, lockY]);

  return (
    <div
      ref={containerRef}
      className="relative transition-[width,height]"
      style={transitionDurationStyle}
    >
      <div
        ref={contentRef}
        className={cs(contentAlignClasses, Next && 'absolute')}
      >
        <span
          key={aKeyRef.current}
          className={cs(
            !Next
              ? 'opacity-100'
              : ['opacity-0 absolute transition-opacity', contentAlignClasses],
          )}
          style={!Next ? undefined : transitionOutgoingDurationStyle}
        >
          <Prev {...commonProps} />
        </span>
        <span
          key={bKeyRef.current}
          ref={nextWrapperRef}
          className={cs(!Next ? 'opacity-0' : 'opacity-100 transition-opacity')}
          style={!Next ? undefined : transitionIncomingDurationStyle}
        >
          {Next && <Next {...commonProps} />}
        </span>
      </div>
    </div>
  );
}

export default SizeAdaptingWrapper;
