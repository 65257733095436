import dialingCodes, { CallCode } from './internationalDiallingCodes';
import TimezonesByCountryCode, {
  TimezoneName,
} from './timezonesByCountryCodes';

export const getCodeFromLanguageTag = (languageTag: string | null) => {
  return languageTag?.split('-').pop()?.toUpperCase() || '';
};

export const findCallCodeByLanguageTag = (
  languageTag: string | null,
): CallCode => {
  const countryCode = getCodeFromLanguageTag(languageTag);
  const callCode = dialingCodes.find(
    (dialingCode) => dialingCode.code === countryCode,
  );

  const USCallCode = dialingCodes.find(
    (dialingCode) => dialingCode.code === 'US',
  );

  return callCode || (USCallCode as CallCode);
};

export const findCallCodeByCountryName = (
  countryName: string | undefined | null,
) => {
  const callCode = dialingCodes.find(
    (dialingCode) => dialingCode.name === countryName,
  );

  const USCallCode = dialingCodes.find(
    (dialingCode) => dialingCode.code === 'US',
  );

  return callCode || (USCallCode as CallCode);
};

export const findCallCodeByTimezoneName = (timezoneName: TimezoneName) => {
  const countryCode = TimezonesByCountryCode.find((timezoneByCountryCode) =>
    timezoneByCountryCode.timezones.find(
      (timezone) => timezone === timezoneName,
    ),
  )?.code;

  const callCode = dialingCodes.find(
    (dialingCode) => dialingCode.code === countryCode,
  );

  return callCode;
};
