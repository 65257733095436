import cs from 'classnames';
import ReactDOM from 'react-dom';
import { Transition } from '@headlessui/react';
import React, {
  memo,
  useRef,
  useMemo,
  forwardRef,
  useContext,
  useLayoutEffect,
} from 'react';

import {
  VerticalAlignment,
  HorizontalAlignment,
  FloatingContentProps,
} from './types';
import { FloatingContext } from './web/context';
import { getBoundsShift, getX, getY } from './utils';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const floatingRoot = document.getElementById('floating-root')!;

export const FadeTransitionConfig = {
  enter: 'sm:transition sm:duration-150 ease-out',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'sm:transition sm:duration-150 ease-out',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

type PositionMimicryOptions = Partial<{
  offset: { x: number; y: number };
  alignVertically: VerticalAlignment;
  alignHorizontally: HorizontalAlignment;
  overflowBoundsMargin?: number;
  show?: boolean;
}>;

function getShadowAlignClass(
  alignH: HorizontalAlignment,
  alignV: VerticalAlignment,
): string {
  const classNames = [];

  if (alignH === HorizontalAlignment.Left) {
    classNames.push('left-0');
  } else if (alignH === HorizontalAlignment.Right) {
    classNames.push('right-0');
  }

  if (alignV === VerticalAlignment.Top) {
    classNames.push('top-0');
  } else if (alignV === VerticalAlignment.Bottom) {
    classNames.push('bottom-0');
  }

  return cs(classNames);
}

/**
 * When beginning to show contents, it copies position
 * of "source" to "target"'s left and top style properties while
 * keeping the element fully in the viewport.
 */
function usePositionMimicry(options: PositionMimicryOptions) {
  const targetRef = useRef<HTMLElement>(null);
  const targetShadowRef = useRef<HTMLElement>(null);
  const { sourceRef } = useContext(FloatingContext);

  const {
    alignHorizontally: alignH = HorizontalAlignment.Left,
    alignVertically: alignV = VerticalAlignment.Top,
    overflowBoundsMargin,
    show = true,
    offset: adjustedOffset = { x: 0, y: 0 },
  } = options;

  useLayoutEffect(() => {
    if (!sourceRef?.current || !targetRef.current || !targetShadowRef.current) {
      return;
    }

    if (!show) {
      // We do not want to adjust the position when hiding to avoid snapping
      // artifacts during 'leave' transition.
      return;
    }

    const sourceRect = sourceRef.current.getBoundingClientRect();
    const targetRect = targetShadowRef.current.getBoundingClientRect();

    const { top, left, width, height } = sourceRect;
    const adjustedTargetRect = {
      width: targetRect.width,
      height: targetRect.height,
      left: targetRect.left + adjustedOffset.x,
      top: targetRect.top + adjustedOffset.y,
    };

    const { offsetX, offsetY } = getBoundsShift(
      overflowBoundsMargin,
      adjustedTargetRect,
      { width: window.innerWidth, height: window.innerHeight },
    );

    const x = getX(left, width, alignH) + offsetX + adjustedOffset.x;
    const y = getY(top, height, alignV) + offsetY + adjustedOffset.y;

    targetRef.current.style.top = `${y}px`;
    targetRef.current.style.left = `${x}px`;
  }, [
    show,
    alignH,
    alignV,
    sourceRef,
    adjustedOffset.x,
    adjustedOffset.y,
    overflowBoundsMargin,
  ]);

  return { targetRef, targetShadowRef };
}

const FloatingContent = (props: FloatingContentProps) => {
  const {
    show,
    children,
    offset = { x: 0, y: 0 },
    alignVertically = VerticalAlignment.Top,
    alignHorizontally = HorizontalAlignment.Left,
    overflowBoundsMargin,
  } = props;

  const { targetRef, targetShadowRef } = usePositionMimicry({
    show,
    offset,
    alignVertically,
    alignHorizontally,
    overflowBoundsMargin,
  });

  const shadowAlignClass = useMemo(
    () => getShadowAlignClass(alignHorizontally, alignVertically),
    [alignHorizontally, alignVertically],
  );

  return (
    <>
      <div
        className="pointer-events-none invisible absolute inset-0 overflow-hidden"
        aria-hidden="true"
      >
        {/* A way to align the shadow target relatively to the inline content */}
        <div className={cs('w-0 h-0 absolute', shadowAlignClass)}>
          <span className="absolute top-0 left-0" ref={targetShadowRef}>
            {/* Shadow target that conforms to it's content well, therefore we can read it's bounds */}
            {children}
          </span>
        </div>
      </div>
      <FloatingInner ref={targetRef}>
        <Transition as="div" show={show} {...FadeTransitionConfig}>
          {children}
        </Transition>
      </FloatingInner>
    </>
  );
};

const FloatingInner = forwardRef<HTMLElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    return ReactDOM.createPortal(
      <span className="absolute pointer-events-auto" ref={ref}>
        {children}
      </span>,
      floatingRoot,
    );
  },
);

export default memo(FloatingContent);
