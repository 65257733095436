import { molecule } from 'bunshi';
import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';

import Env from '@advisor/api/env';
import { myIdAtom } from '@advisor/api/me';
import {
  ExternalService,
  ExternalServiceStatus,
  AuthorizeCalendarDocument,
  RevokeCalendarAccessDocument,
  CalendarIntegrationStatusQuery,
  CalendarIntegrationStatusDocument,
  useExternalServiceAuthorizationStatusChangedSubscription,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom, derive } from '@advisor/utils/atoms';
import { atomOfQuery, atomWithMutation } from '@advisor/api/apollo';

export { ExternalServiceStatus } from '@advisor/api/generated/graphql';

export const getRedirectUrl = () => {
  return `${Env.web.originUrl}/community/microbot/carl/configure/calendar`;
};

export const GoogleCalendarScope = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/calendar.events',
  'https://www.googleapis.com/auth/calendar.readonly',
];

function getGoogleAuthUrl(userId: string) {
  const options = {
    client_id: Env.google.api.webClientId,
    redirect_uri: getRedirectUrl(),
    response_type: 'code',
    scope: GoogleCalendarScope.join(' '),
    include_granted_scopes: true,
    state: userId,
    access_type: 'offline',
    prompt: 'consent',
  };

  const query = Object.entries(options)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `https://accounts.google.com/o/oauth2/v2/auth?${query}`;
}

const CalendarIntegrationMolecule = molecule(() => {
  const calendarIntegrationStatusQueryAtom = atomOfQuery(
    CalendarIntegrationStatusDocument,
  );

  const authorizeCalendarMutationAtom = atomWithMutation(
    AuthorizeCalendarDocument,
  );

  const revokeCalendarAccessMutationAtom = atomWithMutation(
    RevokeCalendarAccessDocument,
  );

  const calendarIntegrationStatusAtom = derive(
    [calendarIntegrationStatusQueryAtom],
    ({ data }) => data?.calendarIntegrationStatus,
  );

  const authUrlAtom = derive([myIdAtom], (myId) =>
    myId ? getGoogleAuthUrl(myId) : null,
  );

  const authorizeCalendarAtom = actionAtom(
    async ({ set }, authCode: string) => {
      try {
        await set(authorizeCalendarMutationAtom, {
          variables: {
            authCode,
            redirectUri: getRedirectUrl(),
          },
          update(cache, results) {
            cache.updateQuery(
              {
                query: CalendarIntegrationStatusDocument,
              },
              (data) => ({
                __typename: 'Query' as const,
                calendarIntegrationStatus:
                  results.data?.authorizeCalendar ??
                  data?.calendarIntegrationStatus ??
                  ExternalServiceStatus.Uninitialized,
              }),
            );
          },
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    },
  );

  const revokeCalendarAtom = actionAtom(async ({ set }) => {
    try {
      await set(revokeCalendarAccessMutationAtom, {
        update(cache, results) {
          cache.updateQuery<CalendarIntegrationStatusQuery>(
            {
              query: CalendarIntegrationStatusDocument,
            },
            (data) => ({
              __typename: 'Query',
              calendarIntegrationStatus:
                results.data?.revokeCalendarAccess ??
                data?.calendarIntegrationStatus ??
                ExternalServiceStatus.Uninitialized,
            }),
          );
        },
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  return {
    authUrlAtom,
    revokeCalendarAtom,
    authorizeCalendarAtom,
    calendarIntegrationStatusAtom,
  };
});

export default function useCalendarIntegration() {
  const {
    authUrlAtom,
    revokeCalendarAtom,
    authorizeCalendarAtom,
    calendarIntegrationStatusAtom,
  } = useMolecule(CalendarIntegrationMolecule);

  const calendarIntegrationStatus =
    useAtomValue(calendarIntegrationStatusAtom) ??
    ExternalServiceStatus.Uninitialized;

  const authUrl = useAtomValue(authUrlAtom);
  const revokeCalendar = useSetAtom(revokeCalendarAtom);
  const authorizeCalendar = useSetAtom(authorizeCalendarAtom);

  useExternalServiceAuthorizationStatusChangedSubscription({
    variables: {
      service: ExternalService.GoogleCalendar,
    },
    onData: ({ client, data: { data } }) => {
      if (!data || !data.externalServiceAuthorizationStatusChanged) {
        return;
      }

      const { status } = data.externalServiceAuthorizationStatusChanged;

      client.cache.updateQuery<CalendarIntegrationStatusQuery>(
        {
          query: CalendarIntegrationStatusDocument,
        },
        () => ({
          __typename: 'Query',
          calendarIntegrationStatus: status,
        }),
      );
    },
  });

  return {
    /* Values */
    authUrl,
    status: calendarIntegrationStatus,

    /* Boolean Helpers */
    isAuthorized:
      calendarIntegrationStatus === ExternalServiceStatus.Authorized,
    isUninitialized:
      calendarIntegrationStatus === ExternalServiceStatus.Uninitialized,
    isExpired:
      calendarIntegrationStatus === ExternalServiceStatus.NeedReauthorization,

    /* Actions */
    revokeCalendar,
    authorizeCalendar,
  };
}
