import { z } from 'zod';

import { useEvent } from '@advisor/utils/hooks';
import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { useCallState, VideoCallStatus } from '../../VideoRoomAPI';

const MinimizeVideoCallTooltipMetadataLocation = new UserMetadataLocation(
  'popups.minimizeVideoCallTooltip',
  z.boolean(),
);

function useMinimizeVideoCallTooltip() {
  const [hidden, setHidden] = useUserMetadata(
    MinimizeVideoCallTooltipMetadataLocation,
  );

  const setShow = useEvent((value: boolean) => {
    setHidden(!value);
  });

  const { status } = useCallState() ?? {};
  const show = !hidden && status === VideoCallStatus.Joined;

  return [show, setShow] as const;
}

export default useMinimizeVideoCallTooltip;
