/* eslint-disable import/prefer-default-export */

import { atom } from 'jotai';

import Env from '@advisor/api/env';
import getLoader from './generated/loadMetadata';
import { type LanguageCode, isLanguageCode } from './languages';

export type Metadata = { primaryLanguages: LanguageCode[] };

const metadataMemo = new Map<string, Promise<Metadata>>();

export function getMetadata() {
  let promise = metadataMemo.get(Env.instanceKey);

  if (!promise) {
    promise = getLoader(Env.instanceKey)() as Promise<Metadata>;
    metadataMemo.set(Env.instanceKey, promise);
  }

  return promise;
}

export async function getPrimaryLanguages(): Promise<LanguageCode[]> {
  const metadata = await getMetadata();
  return metadata.primaryLanguages;
}

export const primaryLanguagesAtom = atom(() => getPrimaryLanguages());

export function parseDetectedLanguage(
  language: string | null,
): LanguageCode | null {
  if (!language) {
    return null;
  }

  // Is a language code by itself
  if (isLanguageCode(language)) {
    return language;
  }

  // Otherwise split the language string by '-' and return only the main part.
  // For some reason, i18next create weird concatenations like pl-US
  const [langShortCode] = language.split('-');

  if (isLanguageCode(langShortCode)) {
    return langShortCode;
  }

  return null;
}
