import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader, Avatar, Icon } from '@advisor/design/components';
import { useAdvisorQuery } from '@advisor/api/generated/graphql';
import { useUserRole } from '@advisor/api/user';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import { ChatParams } from 'src/routes/params';
import SideModal from './SideModal';

const AdvisorInfoModal = () => {
  const { advisorId } = useParams<ChatParams>();
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const { state } = useLocation();

  const { data } = useAdvisorQuery({
    variables: { advisorId: advisorId ?? 'deleted' },
  });

  const userColor = useUserColor(
    data?.advisor.__typename !== 'UserBelongsToAnotherAgencyError'
      ? data?.advisor
      : undefined,
  );
  const userRole = useUserRole(
    data?.advisor.__typename !== 'UserBelongsToAnotherAgencyError'
      ? data?.advisor
      : undefined,
  );

  if (
    typeof advisorId !== 'string' ||
    data?.advisor?.__typename === 'UserBelongsToAnotherAgencyError'
  ) {
    return null;
  }

  const onGoBack = () => {
    navigate('../../preferences', { replace: true, relative: 'path' });
  };

  return (
    <>
      <SideModal.Header>
        <div className="flex items-center gap-4">
          {state && state.prev && state.prev === 'preferences' && (
            <button
              type="button"
              className="text-dark-grey-03 hover:opacity-70 transition-opacity"
              onClick={onGoBack}
            >
              <Icon name="ArrowLeft" className="rtl:-scale-100" />
            </button>
          )}
          <div>
            <h2 className="text-sm font-semibold font-sora">
              {t('member-details')}
            </h2>
            <p className="text-sm text-dark-grey-02">
              {/* The &nbsp; is treated as content, so it takes the same amount of
                   space as content after loading, preventing "content-pop"  */}
              {data?.advisor.name ?? <>&nbsp;</>}
            </p>
          </div>
        </div>
      </SideModal.Header>
      <SideModal.Body className="text-center">
        {data ? (
          <>
            <Avatar
              size={50}
              avatarUrl={data.advisor.avatarUrl}
              className="mx-auto border-2 mb-4"
            />
            <p className="font-bold font-sora" style={{ color: userColor }}>
              {data.advisor.name}
            </p>
            <p className="text-sm mb-6">{userRole}</p>
            <div className="text-xs max-w-2xl mx-auto">
              {data.advisor.description}
            </div>
          </>
        ) : (
          <>
            <Loader className="mx-auto mt-10" />
          </>
        )}
      </SideModal.Body>
    </>
  );
};

export default AdvisorInfoModal;
