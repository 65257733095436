import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Gender,
  type DefaultSystemMessageData,
} from '@advisor/api/generated/graphql';
import {
  UserName,
  RichMessage,
  CenteredMessage,
} from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const GenderToKey = {
  [Gender.Male]: 'you-are-talking-with-host-male',
  [Gender.Female]: 'you-are-talking-with-host-female',
  [Gender.Undefined]: 'you-are-talking-with-host-undefined',
} as const;

const FirstStudentMessageInNewConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { chatRoomId, data } = message;
  const { advisor } = data as DefaultSystemMessageData;

  if (!advisor) {
    return null;
  }

  return (
    <RichMessage
      users={[advisor]}
      chatRoomId={chatRoomId}
      userNameTestID="advisor-joins-the-room-user-details-name"
    >
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey={GenderToKey[advisor.gender]}
        values={{ context: 'female-primary', host: advisor.name }}
      >
        You're talking with <UserName user={advisor} />. They would be helping
        you get onboarded.
      </Trans>
    </RichMessage>
  );
};

export default memo(FirstStudentMessageInNewConversation);
