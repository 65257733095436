import type { LayoutChangeEvent, ListRenderItemInfo } from 'react-native';
import React, { memo, useRef, useMemo, useCallback, ForwardedRef } from 'react';
import { useAtomValue } from 'jotai';

import {
  useAmIStudent,
  useAmIFamilyMember,
  useAmIAspiringAdvisor,
  useAmIServiceProvider,
} from '@advisor/api/me';
import { nullAtom } from '@advisor/utils/atoms';
import { useChatRoom, Member } from '@advisor/api/chatRoom';
import { FlatList } from '@advisor/design/components';
import { FlatListRef } from '@advisor/design/components/FlatList/types';
import MessagesPlaceholder from '@advisor/ui/components/MessagesPlaceholder';
import MicrobotOnboardingMessage from '@advisor/microbots/components/MicrobotOnboardingMessage';
import Platform from '@advisor/utils/Platform';
import StudentOnboardingMessage, {
  useOnboardingMessageBumper,
} from '../StartChatRoom/StudentOnboardingMessage';
import FamilyMemberOnboardingMessage from '../StartChatRoom/FamilyMemberOnboardingMessage';
import ServiceProviderOnboardingMessage from '../StartChatRoom/ServiceProviderOnboardingMessage';
import { useMessageList, MessageBlock } from '../messaging';
import AdvisorOnboardingMessage from '../AdvisorOnboardingMessage';
import TypingIndicators from './TypingIndicators';
import MessageListItem from './MessageListItem';
import { MessageListProps } from './types';
import Loader from './Loader';

const messageKeyExtractor = (block: MessageBlock) =>
  `${block.type}-${block.data[0].id}-${block.data[block.data.length - 1].id}}`;

function MessageList(props: MessageListProps) {
  const { chatRoomId, onOpenUserMessageMenu, onNavigateToNewChat } = props;
  const chatRoomCtx = useChatRoom(chatRoomId ?? null);
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);
  const listRef = useRef<FlatListRef>();
  const amIStudent = useAmIStudent();
  const amIFamilyMember = useAmIFamilyMember();
  const amIAspiringAdvisor = useAmIAspiringAdvisor();
  const amIServiceProvider = useAmIServiceProvider();

  const {
    showLoader,
    messageList,
    latestMessageId,
    showMessagePlaceholders,
    loadOlder,
  } = useMessageList(chatRoomCtx ?? undefined);

  const refWithCallback: ForwardedRef<FlatListRef> = (ref: FlatListRef) => {
    if (!chatRoomCtx) {
      ref?.getScrollResponder().scrollToEnd({ animated: false });
    }
    listRef.current = ref ?? undefined;
  };

  const { setViewHeight, minScrollHeight } = useOnboardingMessageBumper();

  const scrollToBottom = useCallback(() => {
    listRef.current?.getScrollResponder().scrollTo({
      y: 0,
      animated: true,
    });
  }, []);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setViewHeight(event.nativeEvent.layout.height);
    },
    [setViewHeight],
  );

  const listHeader = useMemo(() => {
    if (!chatRoomCtx) {
      return null;
    }

    return (
      <>
        <TypingIndicators />
      </>
    );
  }, [chatRoomCtx]);

  const listFooter = useMemo(() => {
    if (showMessagePlaceholders) {
      return <MessagesPlaceholder />;
    }

    if (showLoader) {
      return <Loader />;
    }

    if (chatRoom?.__typename === 'MicrobotChatRoom') {
      const microbot = Member.microbot(chatRoom);

      return <MicrobotOnboardingMessage personality={microbot?.personality} />;
    }

    if (amIAspiringAdvisor && (Platform === 'ios' || Platform === 'android')) {
      return <AdvisorOnboardingMessage />;
    }

    if (amIStudent) {
      return (
        <StudentOnboardingMessage
          showStartChatButton={!chatRoomCtx}
          scrollToBottom={scrollToBottom}
          navigateToNewChat={onNavigateToNewChat}
        />
      );
    }

    if (amIFamilyMember) {
      return <FamilyMemberOnboardingMessage />;
    }

    if (amIServiceProvider && chatRoomCtx === null) {
      return <ServiceProviderOnboardingMessage.ChatRoom />;
    }

    return null;
  }, [
    amIAspiringAdvisor,
    amIStudent,
    amIFamilyMember,
    amIServiceProvider,
    chatRoom,
    showLoader,
    chatRoomCtx,
    scrollToBottom,
    onNavigateToNewChat,
    showMessagePlaceholders,
  ]);

  const renderItem = useCallback(
    (info: ListRenderItemInfo<MessageBlock>) => (
      <MessageListItem
        {...info}
        latestMessageId={latestMessageId}
        onOpenUserMessageMenu={onOpenUserMessageMenu}
      />
    ),
    [latestMessageId, onOpenUserMessageMenu],
  );

  return (
    <FlatList<MessageBlock>
      ref={refWithCallback}
      inverted
      className="grow"
      contentContainerClassName="xl:w-chat-width xl:mx-auto py-4"
      contentContainerStyle={{
        minHeight: minScrollHeight || '200%',
        justifyContent: 'flex-end',
      }}
      scrollEnabled
      data={messageList}
      initialNumToRender={15}
      renderItem={renderItem}
      keyExtractor={messageKeyExtractor}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      onEndReachedThreshold={0.4}
      onEndReached={loadOlder}
      onLayout={onLayout}
      extraData={{ latestMessageId, onOpenUserMessageMenu }}
    />
  );
}

export default memo(MessageList);
