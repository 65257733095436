import cs from 'classnames';
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  KeyboardEvent,
  useLayoutEffect,
  useMemo,
} from 'react';

import { Spacing } from '../../styles';
import { useTheme } from '../../Theme';
import { TooltipContentProps, TooltipPosition } from './types';
import useTooltipContent, { tailWidth } from './useTooltipContent';
import useBoundingElement from './useBoundingElement';
import { getVariantBg, getVariantFg } from './variants';
import TooltipContext from './context';
import Elevate from './Elevate';

/* eslint-disable jsx-a11y/no-static-element-interactions */
const TooltipContent = (props: TooltipContentProps) => {
  const {
    visible,
    variant = 'dark',
    elevate,
    onClose,
    children,
    className,
    showBackdrop,
    screenMargin = Spacing.tiny,
    position = TooltipPosition.Above,
  } = props;

  const [show, setShow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerBounds = useBoundingElement(containerRef);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { rootDimensions: root } = useContext(TooltipContext);
  const theme = useTheme();

  const onKeyUp = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        onClose?.();
      }
    },
    [onClose],
  );

  useLayoutEffect(() => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  const { content, tail } = useTooltipContent({
    position,
    tooltip: dimensions,
    root: {
      x: root.x - containerBounds.x,
      y: root.y - containerBounds.y,
      width: root.width,
      height: root.height,
    },
    screen: {
      x: screenMargin,
      y: screenMargin,
      width: containerBounds.width - 2 * screenMargin,
      height: containerBounds.height - 2 * screenMargin,
    },
  });

  // This effect is used to run animation on mount
  useEffect(() => {
    setShow(!!visible);
  }, [
    visible,
    dimensions.height,
    dimensions.width,
    containerBounds.width,
    containerBounds.height,
  ]);

  const backgroundColor = useMemo(
    () => getVariantBg(theme, variant),
    [theme, variant],
  );

  const foregroundColor = useMemo(() => getVariantFg(variant), [variant]);

  return (
    <div
      ref={containerRef}
      className={cs(
        'fixed z-10 top-0 left-0 right-0 bottom-0',
        show ? 'opacity-100' : 'opacity-0 invisible',
        !showBackdrop && 'pointer-events-none',
      )}
      onClick={onClose}
      onKeyUp={onKeyUp}
    >
      {showBackdrop && <div className="w-full h-full bg-black/50" />}
      {!!elevate && (
        <Elevate containerBounds={containerBounds}>{elevate}</Elevate>
      )}

      <div
        ref={contentRef}
        className={cs(
          'fixed p-4 rounded-xl transition-opacity duration-300',
          !showBackdrop && 'pointer-events-auto',
          className,
        )}
        style={{
          backgroundColor,
          top: content.top,
          left: content.left,
          color: foregroundColor,
        }}
      >
        {children}
        <div
          className={cs('absolute rounded-tl rounded-br rotate-45')}
          style={{
            backgroundColor,
            top: tail.top,
            left: tail.left,
            width: tailWidth,
            height: tailWidth,
          }}
        />
      </div>
    </div>
  );
};

export default TooltipContent;
