import React, { memo, useCallback, useMemo } from 'react';

import { LoginType } from '@advisor/api/auth';
import VerificationForm from '../VerificationForm';
import useProfileCreation from './useProfileCreation';
import { ProfileCreationStep, VerificationState } from './types';

function VerificationStep() {
  const { userProfile, setStep, onVerify, onOpenVerification } =
    useProfileCreation();

  const onGoBack = useCallback(() => {
    setStep(ProfileCreationStep.Details);
  }, [setStep]);

  const onResendOTP = useCallback(async () => {
    await onOpenVerification();
  }, [onOpenVerification]);

  const [verificationType, identity] = useMemo(() => {
    if (
      userProfile.email &&
      userProfile.emailState === VerificationState.Unverified
    ) {
      return [LoginType.EmailAddress, userProfile.email];
    }

    return [LoginType.PhoneNumber, userProfile.phoneNumber];
  }, [userProfile]);

  return (
    <VerificationForm
      onGoBack={onGoBack}
      identity={identity}
      onVerify={onVerify}
      onResendOTP={onResendOTP}
      verificationType={verificationType}
    />
  );
}

export default memo(VerificationStep);
