import z from 'zod';
import { ObservableQuery } from '@apollo/client';

import { ChatRoomContext } from '@advisor/api/chatRoom';
import { MessageInfoFragment } from '@advisor/api/generated/graphql';

export type MessageBlockType = MessageInfoFragment['__typename'];

export type MessageTranslation = z.infer<typeof MessageTranslation>;
export const MessageTranslation = z.object({
  message: z.string(),
});

export type MessageTranslations = z.infer<typeof MessageTranslations>;
export const MessageTranslations = z.record(
  z.string(), // Language code
  // TODO: Once the string representation is no longer in the database, use only the object form.
  z.union([z.string(), MessageTranslation]),
);

export interface SubscriptionParams {
  chatRoomId?: string;
  subscribeToMore: ObservableQuery['subscribeToMore'];
}

export interface UseControlOverMessageTranslationParams {
  id: string;
  chatRoomCtx: ChatRoomContext;
}
