import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { NewMessageSystemDocument } from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { SubscriptionParams } from './types';
import { appendNewMessage } from './cacheUtils';

function useNewSystemMessageSubscription({
  subscribeToMore,
}: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;

  useMoreSubscription(
    NewMessageSystemDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      if (!subscriptionData.data.newMessageSystem) {
        return prev;
      }

      const { newMessageSystem } = subscriptionData.data;

      return appendNewMessage(prev, newMessageSystem);
    },
    { chatRoomId: chatRoomId! },
    !!chatRoomId,
  );
}

export default useNewSystemMessageSubscription;
