import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { DeprecatedButton } from '@advisor/design/components';
import editArtifactAtom from '../../atoms/editArtifactAtom';
import TextAreaWithPlaceholder from './TextAreaWithPlaceholder';
import { EditTabProps } from './types';

const EditTab: React.FC<EditTabProps> = ({ onSave }) => {
  const { t } = useTranslation(['common', 'microbot']);
  const [state, dispatch] = useAtom(editArtifactAtom);

  const onChange = (value: string) => {
    dispatch({
      type: 'update',
      value,
      placeholder: false,
    });
  };

  return (
    <>
      {/* Header */}
      <div className="py-6 pl-8 pr-6 border-b border-grey flex flex-row items-center justify-between">
        <h3 className="font-sora text-xl leading-6 text-dark-grey-03 font-semibold">
          {/* As for now the only artifact is Pam's partner school list */}
          {t('microbot:partner-school-list')}
        </h3>

        <DeprecatedButton.Text family="Sora" weight={700} tall onPress={onSave}>
          {t('common:save')}
        </DeprecatedButton.Text>
      </div>

      {/* Body */}
      <div className="overflow-y-auto max-h-[33.5625rem] h-full flex flex-col">
        {/* Text area */}
        <TextAreaWithPlaceholder
          value={state?.content ?? ''}
          onChange={onChange}
          placeholder={state?.placeholder}
        />
      </div>
    </>
  );
};

export default EditTab;
