import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import cs from 'classnames';

import useResizeObserver from '@advisor/utils/hooks/useResizeObserver';
import { useEvent } from '@advisor/utils/hooks';
import { PointerSize } from '../utils';
import { HueSliderProps } from './types';

const backgroundGradient =
  'linear-gradient(270deg, #FF0008 0%, #FF00EB 16.67%, #0E00FF 32.81%, #00EDFF 49.06%, #0F0 67.97%, #FFFE00 83.12%, #FF0800 100%)';

/* eslint-disable jsx-a11y/no-static-element-interactions */
const HueSlider: React.FC<HueSliderProps> = ({ value, setValue }) => {
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const pointerPosition = useMemo(() => (value / 360) * width, [value, width]);
  const [isDragged, setIsDragged] = useState(false);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();

      const left = Math.max(
        0,
        Math.min(event.clientX, boundingRect.right) - boundingRect.left,
      );

      setValue((left / boundingRect.width) * 360);
    }
  };

  const onMouseDown = useEvent(() => {
    setIsDragged(true);
  });

  const onMouseUp = useEvent(() => {
    setIsDragged(false);
  });

  const onMouseMove = useEvent((event: MouseEvent) => {
    if (containerRef.current) {
      const boundingRect = containerRef.current.getBoundingClientRect();

      const left = Math.max(
        0,
        Math.min(event.clientX, boundingRect.right) - boundingRect.left,
      );

      setValue((left / boundingRect.width) * 360);
    }
  });

  useResizeObserver(
    containerRef,
    useCallback(({ contentRect }) => {
      if (containerRef.current) {
        setWidth(contentRect.width);
      }
    }, []),
  );

  useEffect(() => {
    if (isDragged) {
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);
    } else {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [isDragged, onMouseUp, onMouseMove]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={onClick}
      draggable="false"
      ref={containerRef}
      className="w-full h-2 rounded-full border border-black/10 relative"
      style={{ background: backgroundGradient }}
    >
      <div
        onMouseDown={onMouseDown}
        className={cs(
          'w-6 h-6 bg-white rounded-full absolute -top-2 grid place-content-center border-[1.5px] border-black/10',
          isDragged ? 'cursor-grabbing' : 'cursor-grab',
        )}
        style={{ left: pointerPosition - PointerSize / 2 }}
      >
        <div
          className="w-3 h-3 rounded-full"
          style={{ backgroundColor: `hsl(${value} 100% 50%)` }}
        />
      </div>
    </div>
  );
};

export default HueSlider;
