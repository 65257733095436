import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFragmentQueryDocument } from '@apollo/client/utilities/graphql/fragments';
import { getFragmentDefinitions } from '@apollo/client/utilities';

import { WithRequired, nonNullable } from '@advisor/utils/typeUtils';
import type { DocumentNode } from '../generated/graphql';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fragmentToQueryDocMemo = new Map<DocumentNode<any, any>, DocumentNode>();
function getQueryDocForFragment<TResult, TVariables>(
  fragmentDoc: DocumentNode<TResult, TVariables>,
  fragmentName: string,
) {
  let queryDoc = fragmentToQueryDocMemo.get(fragmentDoc);

  if (!queryDoc) {
    queryDoc = getFragmentQueryDocument(fragmentDoc, fragmentName);
    fragmentToQueryDocMemo.set(fragmentDoc, queryDoc);
  }

  return queryDoc;
}

type FragmentIdentifier<TResult extends { __typename: string }> = WithRequired<
  Partial<TResult>,
  '__typename'
>;

type Options = {
  optimistic?: boolean;
};

/**
 * Subscribes to the latest values of all `identifiers`, and returns the one that exists.
 * This is useful for using a cached value of a fragment that points to a union of types.
 *
 * @param fragment Expecting stable value
 * @param identifiers Expecting stable value
 */
function useCompoundFragment<
  TResult extends { __typename: string },
  TVariables,
>(
  fragment: DocumentNode<TResult, TVariables>,
  identifiers: FragmentIdentifier<TResult>[],
  options?: Options,
) {
  const client = useApolloClient();
  const { optimistic = false } = options ?? {};

  const fragmentName = useMemo(
    () => getFragmentDefinitions(fragment)[0].name.value,
    [fragment],
  );

  const cacheIdentities = useMemo(
    () =>
      identifiers.map((id) => client.cache.identify(id)).filter(nonNullable),
    [client, identifiers],
  );

  const computeLatestResult = useCallback(() => {
    const latestResults = cacheIdentities.map((id) =>
      client.readFragment<TResult>(
        {
          fragment,
          fragmentName,
          id,
        },
        optimistic,
      ),
    );

    return latestResults.find((result) => !!result) ?? null;
  }, [client, fragment, fragmentName, cacheIdentities, optimistic]);

  const [latestResult, setLatestResult] = useState(computeLatestResult);

  useEffect(() => {
    setLatestResult(computeLatestResult());

    const unsubs = cacheIdentities.map((id) => {
      return client.cache.watch<TResult>({
        query: getQueryDocForFragment(fragment, fragmentName),
        id,
        callback: () => setLatestResult(computeLatestResult()),
        optimistic,
        returnPartialData: true,
        immediate: true,
      });
    });

    return () => {
      unsubs.forEach((unsub) => unsub());
    };
  }, [
    client,
    fragment,
    fragmentName,
    cacheIdentities,
    computeLatestResult,
    optimistic,
  ]);

  return latestResult;
}

export default useCompoundFragment;
