import React, { useMemo } from 'react';

import { BannerAdvertProps } from './types';
import parseBackgroundStyle from './parseBackgroundStyle';
import ImpressionTag from './ImpressionTag';

function BannerAdvert({ advert }: BannerAdvertProps) {
  const backgroundStyle = useMemo(() => {
    const styles = parseBackgroundStyle(advert);

    return {
      backgroundColor: styles.color,
    };
  }, [advert]);

  return (
    <>
      <a
        href={advert.linkUrl ?? ''}
        target="_blank"
        rel="noreferrer"
        className="relative"
      >
        <img
          src={advert.assetUrl ?? undefined}
          alt={advert.altText ?? ''}
          className="w-full h-20 object-contain object-center"
          style={backgroundStyle}
        />
        <span className="absolute left-0 top-0">
          <ImpressionTag advert={advert} />
        </span>
      </a>
    </>
  );
}

export default BannerAdvert;
