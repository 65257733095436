import { createStore } from 'jotai';

type Listener = () => void;

/**
 * Recreates a Jotai store each time we `reset`, which should be done right after logging the user out.
 */
const jotaiStore = (() => {
  const listeners = new Set<Listener>();
  let store = createStore();

  return {
    getStore() {
      return store;
    },

    subscribe(listener: () => void) {
      listeners.add(listener);

      return () => {
        listeners.delete(listener);
      };
    },

    reset() {
      store = createStore();

      // notifying each listener
      listeners.forEach((listener) => listener());
    },
  };
})();

export default jotaiStore;
