import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isReference, Reference } from '@apollo/client';

import {
  MicrobotMemoryConnection,
  useDeleteMicrobotMemoryMutation,
  DeleteMicrobotMemoryMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';

const useDeleteMicrobotMemory = (
  microbotMemoryId: string,
  requireConfirm = true,
  hideMenu?: () => void,
) => {
  const { t } = useTranslation(['common', 'microbot']);
  const showModal = useShowModal();

  const [deleteMemory] = useDeleteMicrobotMemoryMutation();

  return useCallback(async () => {
    hideMenu?.();

    if (requireConfirm) {
      const confirm = await showModal.confirm({
        variant: 'severe',
        title: t('microbot:are-you-sure-you-want-to-remove-selected-memory'),
        message: t(
          'microbot:this-will-permanently-delete-the-information-from-memory-bank',
        ),
        confirmActionLabel: t('common:remove'),
      });

      if (!confirm) {
        return;
      }
    }

    try {
      await deleteMemory({
        ...mutationOptions,
        variables: { microbotMemoryId },
      });

      showToast({
        iconName: 'CircleCheck',
        variant: 'rose',
        messageI18Key: 'selected-memory-was-successfully-removed',
        namespace: 'microbot',
      });
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  }, [showModal, t, deleteMemory, microbotMemoryId, requireConfirm, hideMenu]);
};

export default useDeleteMicrobotMemory;

const mutationOptions: DeleteMicrobotMemoryMutationOptions = {
  update(cache, result) {
    const { id } = result.data?.deleteMicrobotMemory.node ?? {};

    if (!id) {
      return;
    }

    cache.modify({
      fields: {
        microbotMemories(
          existing: Reference | MicrobotMemoryConnection,
          { readField },
        ) {
          if (isReference(existing)) {
            return existing;
          }

          const edges = existing.edges.filter(
            (edge) => id !== readField('id', edge.node),
          );

          const lastNode = existing.edges[existing.edges.length - 1]?.node;
          const pageInfo = { ...existing.pageInfo };

          if (lastNode && id === readField('id', lastNode)) {
            pageInfo.endCursor = existing.edges.length
              ? existing.edges[existing.edges.length - 1].cursor
              : undefined;
          }

          return {
            ...existing,
            pageInfo,
            edges,
          };
        },
      },
      broadcast: false,
    });
  },
};
