import { useMemo } from 'react';
import { useLocation } from 'react-router';

export default function useQueryParams<T extends Record<string, unknown>>(): T {
  const { search } = useLocation();

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search],
  ) as T;
}
