import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import { MilestoneStatusProps, MilestoneStatus } from './types';

export default function useMilestoneStatus(
  props: MilestoneStatusProps,
): MilestoneStatus {
  const { notificationTime, pendingReview, isCompleted } = props;
  const { t } = useTranslation('common');

  if (isCompleted) {
    return { status: 'complete' };
  }

  if (pendingReview) {
    return { status: 'pending' };
  }

  if (!notificationTime || notificationTime === 'NIL') {
    return { status: 'still' };
  }

  if (dayjs(notificationTime).diff(dayjs()) > 0) {
    return {
      status: 'due',
      notificationTime: DateFormatter.calendar(notificationTime, t),
    };
  }

  return {
    status: 'overdue',
    notificationTime: DateFormatter.calendar(notificationTime, t),
  };
}
