import cs from 'classnames';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useRef } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import env from '@advisor/api/env';
import { FileData } from '@advisor/api/files';
import { useEvent } from '@advisor/utils/hooks';
import {
  Icon,
  Modal,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';
import { PickedAttachment } from '@advisor/design/components/FileAttachment';
import ResizableTextArea from '@advisor/chat/ChatBottomBar/web/ResizableTextArea';
import { FileDropArea, FilePicker } from '@advisor/ui/components/FileUpload';
import { AcceptString, AddMicrobotMemoryMolecule, ModalStep } from '../../api';
import useInputPlaceholder from './useInputPlaceholder';

const hasSampleFile = !!env.dataHarvesting?.bulkUploadSampleFileUrl;

const MemorySourceStep: React.FC = () => {
  const { t } = useTranslation(['common', 'microbot']);
  const {
    advanceAtom,
    modalStepAtom,
    attachmentAtom,
    canAdvanceAtom,
    closeEditorAtom,
    textContentAtom,
    canBulkUploadAtom,
    canUploadFilesAtom,
    errorMessageKeyAtom,
    editMemoryStateAtom,
  } = useMolecule(AddMicrobotMemoryMolecule);

  const modalStep = useAtomValue(modalStepAtom);
  const canSubmit = useAtomValue(canAdvanceAtom);
  const canUploadFiles = useAtomValue(canUploadFilesAtom);
  const [textContent, setTextContent] = useAtom(textContentAtom);
  const [attachment, setAttachment] = useAtom(attachmentAtom);
  const errorMessageKey = useAtomValue(errorMessageKeyAtom);
  const editMemoryState = useAtomValue(editMemoryStateAtom);
  const canBulkUpload = useAtomValue(canBulkUploadAtom);

  const inputPlaceholder = useInputPlaceholder();
  const advance = useSetAtom(advanceAtom);
  const closeEditor = useSetAtom(closeEditorAtom);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const isAdding = editMemoryState.id == null;

  const handleLabelClick = useEvent(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleChange = useEvent((event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextContent(event.target.value);
  });

  const handleFile = useEvent(async (file: FileData) => {
    await setAttachment(file);
  });

  const handleRemoveFile = useEvent(async () => {
    await setAttachment(null);
  });

  const handleSave = useEvent(async () => {
    await advance();
  });

  return (
    <Modal.Base
      visible={modalStep === ModalStep.MemorySource}
      onClose={closeEditor}
      maxWidth={406}
    >
      <Modal.Header
        title={isAdding ? t('microbot:new-memory') : t('microbot:edit-memory')}
        subtitle={t('microbot:for-memory-bank')}
        onClose={closeEditor}
      />
      {/* Input */}
      <FileDropArea
        onFileDropped={handleFile}
        className="items-start px-8 pt-6 pb-2"
      >
        <div className="border border-dark-grey-01 rounded relative pb-4">
          <button
            type="button"
            className="flex absolute inset-x-0 z-50 px-4 pt-4 cursor-text rounded-t"
            onClick={handleLabelClick}
          >
            <span
              className={cs(
                'font-outfit text-sm leading-[0.875rem] font-medium pointer-events-none',
                errorMessageKey ? 'text-negative' : 'text-dark-grey-02',
              )}
            >
              {t('microbot:memory')}
            </span>
          </button>
          {!attachment && (
            <ResizableTextArea
              ref={inputRef}
              className="max-h-[50vh] px-4 ltr:pr-16 rtl:pl-16 mt-[2.125rem] resize-none w-full focus:outline-none placeholder:text-dark-grey-01 font-semibold text-dark-grey-03 leading-5"
              placeholder={inputPlaceholder}
              value={textContent}
              onChange={handleChange}
            />
          )}
          {attachment && (
            <div className="px-4 pt-10">
              <PickedAttachment
                attachment={attachment.value}
                onRemove={handleRemoveFile}
              />
            </div>
          )}
          {canUploadFiles && (
            <div className="absolute ltr:right-4 rtl:left-4 top-8 bottom-0">
              <FilePicker accept={AcceptString} onFilePicked={handleFile}>
                {(onPress) => (
                  <DeprecatedButton.AddAttachment
                    variant="square"
                    onPress={onPress}
                  />
                )}
              </FilePicker>
            </div>
          )}
        </div>
      </FileDropArea>
      <Layout.Spacer.Vertical size="tiny" />

      {canBulkUpload && hasSampleFile && (
        <div className="px-8">
          <a
            href={env.dataHarvesting?.bulkUploadSampleFileUrl}
            className="flex flex-row items-center text-primary"
          >
            <Icon name="Download" size={24} />
            <Layout.Spacer.Horizontal size="atomic" />
            <span className="text-sm font-medium">
              {t('microbot:csv-template-for-multiple-memories')}
            </span>
          </a>
        </div>
      )}

      {errorMessageKey ? (
        <>
          <Layout.Spacer.Vertical size="atomic" />
          <p className="flex items-center gap-2 px-6 text-xs font-medium text-negative">
            <Icon
              name="Warning"
              size={24}
              className="flex-grow-0 flex-shrink-0"
            />
            {t(errorMessageKey)}
          </p>
          <Layout.Spacer.Vertical size="micro" />
        </>
      ) : (
        <Layout.Spacer.Vertical size="tiny" />
      )}

      {/* Submit button */}
      <Modal.Footer>
        <DeprecatedButton.Large
          variant="primary"
          disabled={!canSubmit}
          onPress={handleSave}
        >
          {t('common:save')}
        </DeprecatedButton.Large>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default MemorySourceStep;
