import { useAtomValue } from 'jotai';

import { Role } from '@advisor/api/user';
import { meAtom } from '@advisor/api/me';
import { lazyAtom } from '@advisor/utils/atoms';
import { atomFamily } from 'jotai/utils';

export type PersonalPreferencesSlot =
  | 'sidebar-menu'
  | 'conversation-preferences';

export const personalPreferencesSlotAtom = lazyAtom(
  async (get): Promise<PersonalPreferencesSlot> => {
    const me = await get(meAtom);

    return Role.isVerifiedAdvisor(me) || Role.isServiceProvider(me)
      ? 'sidebar-menu'
      : 'conversation-preferences';
  },
);

export const personalPreferencesSlotScopes = atomFamily(
  (slot: PersonalPreferencesSlot) => {
    return lazyAtom(
      async (get) => (await get(personalPreferencesSlotAtom)) === slot,
    );
  },
);

export function usePersonalPreferencesSlot(slot: PersonalPreferencesSlot) {
  return useAtomValue(personalPreferencesSlotAtom) === slot;
}
