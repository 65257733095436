import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  MeDocument,
  MeFullInfoFragment,
  ConfirmStudentInvitationDocument,
} from '@advisor/api/generated/graphql';
import { Scope } from '@advisor/api/scope';
import { clientAtom } from '@advisor/api/apollo';
import { soloActionAtoms } from '@advisor/utils/atoms';

const [, confirmStudentInvitationAtom] = soloActionAtoms(async ({ get }) => {
  const client = await get(clientAtom);

  await client.mutate({
    mutation: ConfirmStudentInvitationDocument,
    update: (cache, { data }) => {
      cache.updateQuery(
        {
          query: MeDocument,
        },
        (prev) => ({
          __typename: 'Query' as const,
          me: {
            ...(prev?.me ?? {}),
            ...(data?.confirmStudentInvitation.user as MeFullInfoFragment),
          },
        }),
      );
    },
  });
});

const useConfirmPendingStudent = () => {
  const amIPendingStudent = useAtomValue(Scope.pendingStudent);
  const confirmStudentInvitation = useSetAtom(confirmStudentInvitationAtom);

  useEffect(() => {
    if (!amIPendingStudent) {
      return;
    }

    confirmStudentInvitation();
  }, [amIPendingStudent, confirmStudentInvitation]);
};

export default useConfirmPendingStudent;
