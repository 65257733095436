import { unwrap } from 'jotai/utils';
import { useAtomValue } from 'jotai';

import { derive } from '@advisor/utils/atoms';
import authStateAtom from './authStateAtom';

export const isAuthorizedAtom = derive(
  [authStateAtom],
  (authState) => !!authState,
);

type Options = {
  suspend?: boolean;
};

export function useIsAuthorized(options?: Options) {
  const { suspend = false } = options ?? {};

  const isAuthorized = useAtomValue(
    suspend ? isAuthorizedAtom : unwrap(isAuthorizedAtom),
  );

  return isAuthorized ?? false;
}
