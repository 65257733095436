import { useTranslation } from 'react-i18next';

import { useMeAdvisor } from '@advisor/api/me';
import { useEvent } from '@advisor/utils/hooks';
import copyToClipboard from '@advisor/utils/copyToClipboard';
import { showToast } from '@advisor/design/components/Toast';
import { createInviteLink } from './inviteLinks';

function showSuccessToast(theThingCopied: string) {
  showToast({
    iconName: 'Link',
    messageI18Key: 'something-copied-to-clipboard',
    values: { something: theThingCopied },
    variant: 'blue',
  });
}

function showErrorToast() {
  showToast({
    iconName: 'X',
    messageI18Key: 'cant-access-clipboard',
    variant: 'rose',
  });
}

const useCopyInviteLinks = () => {
  const { t } = useTranslation('onboarding');
  const me = useMeAdvisor();

  const onCopyAgencyInviteLink = useEvent(async () => {
    if (!me) {
      return;
    }

    const link = createInviteLink('agency', me);

    try {
      await copyToClipboard(link);
      showSuccessToast(t('organization-invite-link'));
    } catch {
      showErrorToast();
    }
  });

  const onCopyPersonalInviteLink = useEvent(async () => {
    if (!me) {
      return;
    }

    const link = createInviteLink('advisor', me);

    try {
      await copyToClipboard(link);
      showSuccessToast(t('name-personal-invite-link', { name: me.name }));
    } catch {
      showErrorToast();
    }
  });

  return {
    onCopyAgencyInviteLink,
    onCopyPersonalInviteLink,
  };
};

export default useCopyInviteLinks;
