/* eslint-disable no-bitwise */

function multiplyComponent(cmp: number, by: number): number {
  return Math.floor(Math.min(Math.max(cmp * by, 0), 255));
}

function stringifyComponent(cmp: number): string {
  return cmp.toString(16).padStart(2, '0');
}

function addComponents(cmp1: number, cmp2: number): number {
  return Math.floor(Math.min(Math.max(cmp1 + cmp2, 0), 255));
}

class Color {
  private r: number;

  private g: number;

  private b: number;

  constructor(inColor: string) {
    const colorInt = parseInt(inColor.replace('#', ''), 16);

    this.r = (colorInt >> 16) & 255;
    this.g = (colorInt >> 8) & 255;
    this.b = colorInt & 255;
  }

  public multiply(by: number) {
    this.r = multiplyComponent(this.r, by);
    this.g = multiplyComponent(this.g, by);
    this.b = multiplyComponent(this.b, by);

    return this;
  }

  public alphaBlend(bgColorIn: string, fgAlpha: number) {
    const bgColor = color(bgColorIn).multiply(1 - fgAlpha);

    this.multiply(fgAlpha);

    this.r = addComponents(this.r, bgColor.r);
    this.g = addComponents(this.g, bgColor.g);
    this.b = addComponents(this.b, bgColor.b);

    return this;
  }

  public stringify(): string {
    const r = stringifyComponent(this.r);
    const g = stringifyComponent(this.g);
    const b = stringifyComponent(this.b);

    return `#${r}${g}${b}`;
  }
}

export default function color(inColor: string) {
  return new Color(inColor);
}
