import { noop } from 'lodash-es';
import { RefObject, useEffect } from 'react';

const DefaultOptions: MutationObserverInit = {
  attributes: true,
  childList: true,
  subtree: true,
};

export default function useMutationObservable<E extends HTMLElement>(
  targetElement: RefObject<E>,
  callback: MutationCallback,
  options: MutationObserverInit = DefaultOptions,
) {
  useEffect(() => {
    if (!targetElement?.current) {
      return noop;
    }

    const observer = new MutationObserver(callback);
    observer.observe(targetElement.current, options);

    return () => {
      observer.disconnect();
    };
  }, [callback, options, targetElement]);
}
