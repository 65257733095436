import { values } from 'lodash-es';
import { useAtomValue } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import TaskQueue from '@advisor/utils/TaskQueue';
import ComplexError from '@advisor/utils/ComplexError';
import { sessionsAtom } from './DailyAPIProvider/sessions';
import useVideoRoomAPI from './useVideoRoomAPI';
import { SessionMetadata } from './types';

enum UpdateUserDataResult {
  Success,
  CallEnded,
}

const taskQueue = new TaskQueue();

function useUpdateSessionMetadata() {
  const sessions = useAtomValue(sessionsAtom);
  const { updateSessionMetadata } = useVideoRoomAPI() ?? {};

  return useEvent((updates: Partial<SessionMetadata>) => {
    if (!updateSessionMetadata) {
      return UpdateUserDataResult.CallEnded;
    }

    const localSession = values(sessions).find((s) => s.isLocal);
    if (!localSession) {
      return null;
    }

    return taskQueue.enqueue(async () => {
      try {
        await updateSessionMetadata({
          ...localSession.metadata,
          ...updates,
        });

        return UpdateUserDataResult.Success;
      } catch (err) {
        Sentry.captureException(
          new ComplexError(
            'Something went wrong when updating session metadata',
            err,
          ),
        );
        return UpdateUserDataResult.CallEnded;
      }
    });
  });
}

export default useUpdateSessionMetadata;
