import { ReactNode } from 'react';

import {
  TextChunk,
  FontProps,
  FontWeight,
  FontContext,
  NodeOrChunk,
  FontWeightMap,
  InputFontFamily,
  OutputFontFamily,
} from './types';

type RNFontWeight =
  | undefined
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export const OutfitUnavailableCharactersRegex =
  /([\u0104-\u0107]|\u0118|\u0119|[\u0141-\u0144]|\u00D3|\u00F3|\u015A|\u015B|[\u0179-\u017C])/;

export const FamilyConversion = {
  ar: {
    Sora: 'Tajawal',
    Outfit: 'NotoSansArabic',
  },
  other: {
    Sora: 'Sora',
    Outfit: 'NotoSans',
  },
} as const;

export const SizeConversion = {
  Sora: 1,
  Outfit: 1,
  NotoSans: 0.9,
  Tajawal: 1.17,
  NotoSansArabic: 0.9,
};

export const LineHeightConversion = {
  Sora: 1,
  Outfit: 1,
  Tajawal: 1,
  NotoSans: 1,
  NotoSansArabic: 1.2,
};

export const WeightConversion: FontWeightMap = {
  200: { NotoSansArabic: 300, Tajawal: 300, NotoSans: 400 },
  400: { NotoSansArabic: 400, Tajawal: 400, NotoSans: 400 },
  500: { NotoSansArabic: 500, Tajawal: 500, NotoSans: 400 },
  600: { NotoSansArabic: 700, Tajawal: 700, NotoSans: 700 },
  700: { NotoSansArabic: 800, Tajawal: 800, NotoSans: 700 },
  800: { NotoSansArabic: 900, Tajawal: 900, NotoSans: 700 },
};

function getFontFamily(
  fontFamily: InputFontFamily,
  isArabic: boolean,
  canUseOutfit: boolean,
): OutputFontFamily {
  if (isArabic) {
    return FamilyConversion.ar[fontFamily];
  }

  if (!canUseOutfit) {
    return FamilyConversion.other[fontFamily];
  }

  return fontFamily;
}

function getFontWeight(
  fontWeight: FontWeight,
  fontFamily: OutputFontFamily,
): FontWeight {
  if (fontFamily === 'Outfit' || fontFamily === 'Sora') {
    return fontWeight;
  }

  return WeightConversion[fontWeight]?.[fontFamily] ?? fontWeight;
}

export function parseFontDeclaration({
  fontFamily,
  fontWeight,
  lineHeight,
  fontSize,
  color,
  canUseOutfit,
  isArabic,
}: FontProps & FontContext) {
  const appropriateFontFamily = getFontFamily(
    fontFamily,
    isArabic,
    canUseOutfit,
  );
  const appropriateWeight = getFontWeight(fontWeight, appropriateFontFamily);

  const appropriateSize =
    fontSize !== undefined
      ? Math.round(fontSize * SizeConversion[appropriateFontFamily])
      : undefined;

  const appropriateLineHeight =
    lineHeight !== undefined
      ? lineHeight * LineHeightConversion[appropriateFontFamily]
      : undefined;

  return {
    fontFamily: `${appropriateFontFamily}_${appropriateWeight}`,
    lineHeight: appropriateLineHeight,
    fontSize: appropriateSize,
    fontWeight: `${appropriateWeight}` as RNFontWeight,
    color,
  };
}

export function isOutfitApplicable(content: string) {
  return (
    !OutfitUnavailableCharactersRegex.test(content) && !isTextArabic(content)
  );
}

function isTextArabic(content: string): boolean {
  return /[\u0621-\u064A]/.test(content);
}

function isTextNeutral(character: string): boolean {
  return /\s/.test(character);
}

export function parseTextContent(text: string): TextChunk[] {
  if (!text.length) {
    return [];
  }

  const chunks: TextChunk[] = [];

  let prevChunk: TextChunk = {
    isArabic: isTextArabic(text[0]),
    content: text[0],
  };

  for (let i = 1; i < text.length; i += 1) {
    const isArabic = isTextArabic(text[i]);
    const isNeutral = isTextNeutral(text[i]);

    if (isArabic === prevChunk.isArabic || isNeutral) {
      prevChunk.content += text[i];
    } else {
      chunks.push(prevChunk);
      prevChunk = {
        isArabic,
        content: text[i],
      };
    }
  }

  chunks.push(prevChunk);

  return chunks;
}

export function parseTextChildren(inChildren: ReactNode): {
  childs: NodeOrChunk[] | null;
  canUseOutfit: boolean;
} {
  let canUseOutfit = true;

  if (!inChildren) {
    return { childs: null, canUseOutfit };
  }

  const children = inChildren instanceof Array ? inChildren : [inChildren];
  let newChildren: NodeOrChunk[] = [];

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];

    if (typeof child === 'string') {
      canUseOutfit = canUseOutfit ? isOutfitApplicable(child) : false;
      const textChunks = parseTextContent(child);

      newChildren = [...newChildren, ...textChunks];
    } else {
      newChildren.push(child);
    }
  }

  return { childs: newChildren, canUseOutfit };
}

export function isTextChunk(child: NodeOrChunk): child is TextChunk {
  return (child as TextChunk).content !== undefined;
}
