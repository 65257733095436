import { decode } from 'html-entities';

// eslint-disable-next-line import/prefer-default-export
export function decodeSpecialCharacters(msg: string) {
  return decode(msg, { level: 'all' })
    .replace(/\\n/g, '\n')
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .replace(/\\&/g, '&')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f');
}
