import React, { memo } from 'react';

import FileAttachmentBase from './FileAttachmentBase';
import { useAttachmentDisplayName, useFormattedAttachmentSize } from './utils';
import { hasUploadFailed, type FileAttachmentProps } from './types';

const FileAttachment: React.FC<FileAttachmentProps> = (props) => {
  const { attachment } = props;
  const { url } = attachment.file;

  const displayName = useAttachmentDisplayName(attachment);
  const formattedFileSize = useFormattedAttachmentSize(attachment);
  const hasError = hasUploadFailed(attachment);

  return (
    <a
      href={url}
      target="_blank"
      download
      rel="noopener noreferrer"
      className="w-full border border-grey rounded hover:border-dark-grey-01 transition-colors"
    >
      <FileAttachmentBase
        title={displayName}
        description={formattedFileSize}
        hasError={hasError}
      />
    </a>
  );
};

export default memo(FileAttachment);
