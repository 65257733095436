import React, { memo } from 'react';

import { useTheme } from '@advisor/design/Theme';

const LoginBgLogo: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="272"
      height="114"
      viewBox="0 0 272 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden md:block absolute bottom-24 md:-left-[148px] lg:left-0 global-rtl:right-0 global-rtl:-scale-x-100"
    >
      <path
        d="M249.86 29.9341L269.592 45.8044L271.641 0L249.86 29.9341Z"
        fill="url(#paint0)"
      />
      <path
        d="M250.397 30.2707L231.619 33.6314L271.641 0L250.397 30.2707Z"
        fill="url(#paint1)"
      />
      <path
        d="M-24 85.9831C77.5107 167.784 194.861 43.1908 143.532 7.39371C119.3 -9.5091 85.4552 24.0227 115.141 62.11C148.987 105.525 210.401 90.5137 248.281 35.1751"
        stroke={theme.colors.primaryLight05}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <defs>
        <linearGradient
          id="paint0"
          x1="251.99"
          y1="33.1675"
          x2="281.075"
          y2="17.1165"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary} />
          <stop offset="0.99" stopColor={`${theme.colors.primary}40`} />
        </linearGradient>
        <linearGradient
          id="paint1"
          x1="242.703"
          y1="47.6289"
          x2="198.284"
          y2="26.7956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary} />
          <stop offset="0.9992" stopColor={theme.colors.primaryDark} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default memo(LoginBgLogo);
