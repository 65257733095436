import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import callCodes, {
  CallCode,
  CountryName,
} from '@advisor/utils/country/internationalDiallingCodes';
import { Container, Label } from '@advisor/design/components/Form/components';
import {
  SearchInput,
  ChevronIcon,
  Icon,
  Modal,
  FlagIcon,
} from '@advisor/design/components';
import { CountrySelectProps } from './types';

const CountrySelect: React.FC<CountrySelectProps> = ({
  value,
  disabled,
  onSelect,
  label,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation(['common', 'countries', 'onboarding']);

  const onToggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const onCountryChange = useCallback(
    (newValue: CountryName) => {
      onSelect(newValue);
      setShowModal(false);
    },
    [onSelect],
  );

  const renderCountry = useCallback(
    (code: CallCode) => {
      return (
        <button
          type="button"
          key={code.code}
          className="flex justify-between w-full py-3"
          onClick={() => {
            onCountryChange(code.name);
          }}
        >
          <span className="font-medium font-outfit">
            {t(`countries:${code.name}`)}
          </span>
          <FlagIcon country={code.name} />
        </button>
      );
    },
    [t, onCountryChange],
  );

  const countries = useMemo(() => {
    if (!searchText.length) {
      return callCodes;
    }

    return callCodes.filter((code) =>
      t(`countries:${code.name}`)
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()),
    );
  }, [searchText, t]);

  return (
    <>
      <button
        type="button"
        className="ltr:text-left rtl:text-right w-full"
        onClick={onToggleModal}
        disabled={disabled}
      >
        <Container className="flex items-center flex-1 justify-between">
          <Label>{label ?? t('onboarding:country-of-residence')}</Label>
          {value ? (
            <div className="p-4 pt-9 flex justify-between w-full">
              {t(`countries:${value}`)}
              <FlagIcon country={value} className="object-contain" />
            </div>
          ) : (
            <>
              <div className="p-4 pt-9 text-dark-grey-01">
                {t('common:please-select')}
              </div>
              <ChevronIcon className="shrink-0" />
            </>
          )}
        </Container>
      </button>
      <Modal.Base visible={showModal} onClose={onToggleModal} maxWidth={406}>
        <div className="flex justify-between px-8 h-20 items-center border-b border-b-grey">
          <h3 className="font-bold font-outfit text-lg">
            {t('onboarding:select-country-of-residence')}
          </h3>
          <button type="button" className="" onClick={onToggleModal}>
            <Icon name="X" />
          </button>
        </div>
        <div className="px-8 pt-6">
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            placeholder={t('common:region-placeholder')}
          />
        </div>
        <div className="px-8 py-6 overflow-auto max-h-[310px]">
          {countries.map(renderCountry)}
        </div>
      </Modal.Base>
    </>
  );
};

export default memo(CountrySelect);
