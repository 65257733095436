import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { ChangeEventHandler, memo, useCallback } from 'react';

import { VERIFICATION_CODE_LENGTH } from './useVerificationForm';
import { VerificationInputProps } from './types';

function VerificationInput({
  code,
  onCodeChange,
  invalid = false,
}: VerificationInputProps) {
  const { t } = useTranslation('onboarding');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onCodeChange(target.value);
    },
    [onCodeChange],
  );

  return (
    <input
      className={cs(
        'h-12.5 relative border rounded w-full px-4 text-sm md:text-base w-[312px] md:w-[342px] focus:outline-none font-bold placeholder:font-normal placeholder:text-grey',
        invalid ? 'border-negative-light text-negative' : 'border-dark-grey-01',
      )}
      type="text"
      name="token"
      id="token"
      inputMode="decimal"
      placeholder={t('enter-verification-code')}
      pattern="[0-9]*"
      autoComplete="one-time-code"
      maxLength={VERIFICATION_CODE_LENGTH}
      value={code}
      onChange={onInputChange}
      data-cy="verificationCodeInput"
    />
  );
}

export default memo(VerificationInput);
