import { useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useMyId } from '@advisor/api/me';
import { useEvent } from '@advisor/utils/hooks';
import {
  useTurnOffVideoChatRecordingMutation,
  useTurnOnVideoChatRecordingMutation,
} from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';
import {
  useCallState,
  VideoCallState,
  useVideoRoomAPI,
  PrepareVideoRoomForRecordingResult,
} from '../VideoRoomAPI';
import { useVideoChat } from '../useVideoChat';
import { BlockParticipantsTimeoutError } from './errors';
import { recordingRequestStateAtom, recordingConsentStateAtom } from './atoms';
import { RecordingConsentState, RecordingRequestState } from './types';

function useInitiatorRequestHandler<Params extends unknown[]>(
  callback: (call: VideoCallState, ...params: Params) => Promise<void>,
) {
  const [requestState, setRequestState] = useAtom(recordingRequestStateAtom);
  const callState = useCallState();
  const myId = useMyId();
  const chatRoomId = callState?.chatRoomId;
  const videoChat = useVideoChat(chatRoomId ?? '', !chatRoomId);
  const hasPermissions =
    !!callState &&
    !!videoChat?.callInitiatorId &&
    !!myId &&
    videoChat?.callInitiatorId === myId;

  const handler = useEvent(async (...params: Params) => {
    if (!hasPermissions || requestState === RecordingRequestState.PENDING) {
      return;
    }

    setRequestState(RecordingRequestState.PENDING);

    try {
      await callback(callState, ...params);
    } catch (err) {
      // Only resetting the request state on error, because if successful,
      // the pending state will get reset when we get a successful subscription
      // down the line.
      setRequestState(RecordingRequestState.IDLE);
      throw err;
    }
  });

  return hasPermissions ? handler : null;
}

function useRecordingControls() {
  const { t } = useTranslation('common');
  const showModal = useShowModal();
  const [turnOnVideoChatRecording] = useTurnOnVideoChatRecordingMutation();
  const [turnOffVideoChatRecording] = useTurnOffVideoChatRecordingMutation();
  const setRecordingConsentState = useSetAtom(recordingConsentStateAtom);
  const { prepareVideoRoomForRecording } = useVideoRoomAPI() ?? {};

  const startRecording = useInitiatorRequestHandler(
    async (callState, promptForConfirmation?: boolean) => {
      if (!prepareVideoRoomForRecording) {
        throw new Error('No video room service provider found.');
      }

      if (promptForConfirmation) {
        setRecordingConsentState(RecordingConsentState.AWAITING_DECISION);

        const confirmed = await showModal.confirm({
          title: t('this-meeting-will-now-be-recorded'),
          message: t(
            'by-pressing-continue-you-provide-your-consent-to-have-the-meeting-recorded',
          ),
          variant: 'neutral',
          confirmActionLabel: t('continue'),
          cancelActionLabel: t('do-not-record'),
        });

        if (!confirmed) {
          // For the initiator, we do not want them to leave the room if they
          // decide not to START recording. (which the DENIED state would imply)
          setRecordingConsentState(RecordingConsentState.NOT_GIVEN_YET);
          return;
        }
      }

      setRecordingConsentState(RecordingConsentState.GIVEN);

      const result = await prepareVideoRoomForRecording();

      if (result !== PrepareVideoRoomForRecordingResult.Ready) {
        throw new BlockParticipantsTimeoutError();
      }

      await turnOnVideoChatRecording({
        variables: {
          chatRoomId: callState.chatRoomId,
        },
      });
    },
  );

  const stopRecording = useInitiatorRequestHandler(async ({ chatRoomId }) => {
    await turnOffVideoChatRecording({
      variables: {
        chatRoomId,
      },
    });
  });

  return useMemo(
    () => ({
      startRecording,
      stopRecording,
    }),
    [startRecording, stopRecording],
  );
}

export default useRecordingControls;
