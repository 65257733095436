/**
 * `jōtai 👻` instruments its promises with extra metadata,
 * which we can occasionally use to compute something *sooner*,
 * instead of postponing the calculation onto the next tick.
 */
type ExtraPromise<T> = Promise<T> & {
  status?: 'pending' | 'fulfilled' | 'rejected';
  value?: T;
  reason?: unknown;
};

function isPromise<T, S>(value: ExtraPromise<T> | S): value is ExtraPromise<T> {
  return value && typeof value === 'object' && 'then' in value;
}

function isKnown<T, S>(value: ExtraPromise<T> | S): boolean {
  if (isPromise(value)) {
    return value.status === 'fulfilled'; // only if fulfilled
  }

  return true; // not a promise, we know the value.
}

function soon<InputType, OutputType>(
  op: (input: NoInfer<Awaited<InputType>>) => OutputType,
): (input: InputType) => OutputType | Promise<Awaited<OutputType>> {
  return (input: InputType) => {
    if (isPromise(input)) {
      if (input.status === 'fulfilled') {
        // can process the value earlier
        return op(input.value as Awaited<InputType>);
      }

      if (input.status === 'rejected') {
        // To keep the error handling behavior consistent, lets
        // always return a rejected promise, even if the processing
        // can be done in sync.
        return Promise.reject(input.reason);
      }

      return input.then((value) => op(value as Awaited<InputType>)) as Promise<
        Awaited<OutputType>
      >;
    }

    try {
      return op(input as Awaited<InputType>);
    } catch (err) {
      // To keep the error handling behavior consistent, lets
      // always return a rejected promise, even if the processing
      // can be done in sync.
      return Promise.reject(err);
    }
  };
}

export type PromiseOrValue<T> = Promise<T> | T;

export type SoonAll<T extends readonly [unknown, ...unknown[]]> =
  PromiseOrValue<{
    [Index in keyof T]: Awaited<T[Index]>;
  }>;

export function soonAll<T extends readonly [unknown, ...unknown[]]>(
  input: T,
): SoonAll<T> {
  if (input.every(isKnown)) {
    return input.map((el) =>
      isPromise(el) ? el.value : el,
    ) as unknown as SoonAll<T>;
  }

  return Promise.all(input);
}

export default soon;
