import {
  AdvisorInfoFragment,
  StudentInfoFragment,
  MicrobotInfoFragment,
  FamilyMemberInfoFragment,
  ServiceProviderInfoFragment,
  ChatRoomMembersInfoFragment,
  UserInfoFragment,
  ChatRoomWithMembersFragment,
  MicrobotPersonality,
} from '../generated/graphql';
import { Role } from '../user/index';

/**
 * Who is the `chatRoom`'s representative from the point of view of `me`.
 */
const chatRoomRepresentative = (
  chatRoom: ChatRoomWithMembersFragment,
  me: UserInfoFragment | undefined | null,
) => {
  if (chatRoom.__typename === 'ServiceProviderChatRoom') {
    if (Role.isServiceProvider(me)) {
      return assistantAdvisor(chatRoom);
    }

    return serviceProvider(chatRoom);
  }

  if (chatRoom?.__typename === 'MicrobotChatRoom') {
    return microbot(chatRoom);
  }

  return chatRoom.members.find(
    (memberInfo) => memberInfo.member.name === chatRoom.name,
  )?.member;
};

const student = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members.find((m) => Role.isStudent(m.member))?.member as
    | StudentInfoFragment
    | undefined;

const advisor = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members.find((m) => Role.isAdvisor(m.member))?.member as
    | AdvisorInfoFragment
    | undefined;

const assistantAdvisor = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members.find((m) => Role.isAssistantAdvisor(m.member))?.member as
    | AdvisorInfoFragment
    | undefined;

const microbot = (
  chatRoom: ChatRoomMembersInfoFragment,
  personality?: MicrobotPersonality,
) =>
  chatRoom.members.find(
    (m) =>
      Role.isMicrobot(m.member) &&
      (!personality || m.member.personality === personality),
  )?.member as MicrobotInfoFragment | undefined;

const microbots = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members
    .filter((m) => Role.isMicrobot(m.member))
    .map((m) => m.member as MicrobotInfoFragment);

const familyMember = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members.find((m) => Role.isFamilyMember(m.member))?.member as
    | FamilyMemberInfoFragment
    | undefined;

const serviceProvider = (chatRoom: ChatRoomMembersInfoFragment) =>
  chatRoom.members.find((m) => Role.isServiceProvider(m.member))?.member as
    | ServiceProviderInfoFragment
    | undefined;

const Members = {
  chatRoomRepresentative,
  student,
  advisor,
  assistantAdvisor,
  microbot,
  microbots,
  familyMember,
  serviceProvider,
};

export default Members;
