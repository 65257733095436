import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Link, useNavigate } from 'react-router-dom';
import React, { memo, useCallback, MouseEventHandler, useMemo } from 'react';

import suspenseHOC from '@advisor/utils/suspenseHOC';
import { DateFormatter } from '@advisor/language';
import { useEvent } from '@advisor/utils/hooks';
import { PermissionGate } from '@advisor/api/scope';
import { Role } from '@advisor/api/user';
import {
  Layout,
  Avatar,
  Icon,
  DeletingIndicator,
} from '@advisor/design/components';
import { useTheme } from '@advisor/design/Theme';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import AvatarSkeleton from '@advisor/design/components/Skeleton/AvatarSkeleton';
import {
  useActiveChatRoom,
  useChatRoomRepresentative,
} from '@advisor/api/chatRoom';
import { Spacing } from '@advisor/design/styles';
import { useAmIServiceProvider } from '@advisor/api/me';
import useInterpolatedUsername from '../../hooks/useInterpolatedUsername';
import { useReassignChatRoomMolecule } from '../../ReassignStudentModal';
import { useGetStatusId, NoStatusLiteral } from '../../ChatRoomStatus';
import { ArchivalStatus, archivalStatusAtoms } from '../atoms';
import useArchiveChatRoom from '../useArchiveChatRoom';
import useMarkStatus from '../useMarkStatus';
import ChatRoomMenuDropdown from './web/ChatRoomMenuDropdown';
import { showChatRoomActionsScope } from './atoms/showChatRoomActions';
import showPendingStudentTitle from './atoms/showPendingTitle';
import { ChatRoomActivity, useLatestChatRoomActivity } from './utils';
import { ChatRoomRowProps } from './types';
import StatusLabel from './StatusLabel';

function ChatRoomRow(props: ChatRoomRowProps) {
  const { chatRoom } = props;
  const { id, name, isVideoChatActive } = chatRoom;

  const theme = useTheme();
  const activeChatRoomCtx = useActiveChatRoom();
  const navigate = useNavigate();
  const markStatus = useMarkStatus();
  const { t } = useTranslation(['common', 'user-role']);
  const statusId = useGetStatusId(chatRoom);
  const activity = useLatestChatRoomActivity(chatRoom);
  const archiveChatRoom = useArchiveChatRoom();
  const setShowSidebar = useSetAtom(showSidebarAtom);
  const { reassignChatRoomIdAtom } = useReassignChatRoomMolecule('global');
  const setReassignChatRoomId = useSetAtom(reassignChatRoomIdAtom);
  const isBeingArchived =
    useAtomValue(archivalStatusAtoms(id)) === ArchivalStatus.Pending;

  const amIServiceProvider = useAmIServiceProvider();
  const representative = useChatRoomRepresentative(id);
  const representativeName =
    useInterpolatedUsername(representative, id) ?? chatRoom.name;
  const representativeColor = useUserColor(representative);

  const isCurrentChatRoom = id === activeChatRoomCtx?.chatRoomId;

  const showAssistantIcon =
    chatRoom.__typename === 'ServiceProviderChatRoom' && amIServiceProvider;

  const displayAssistantTitle =
    showAssistantIcon && chatRoom.latestMessage?.__typename !== 'UserMessage';

  const showPendingTitle = useAtomValue(showPendingStudentTitle(id));

  const chatRoomTitle = useMemo(() => {
    if (showPendingTitle) {
      return t('user-role:customer-pending');
    }

    if (displayAssistantTitle) {
      return t('user-role:assistant-host');
    }

    return activity.description;
  }, [t, activity.description, showPendingTitle, displayAssistantTitle]);

  const hideSidebar = useCallback(() => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  const handleArchiveConversation = useEvent(async () => {
    const success = await archiveChatRoom({
      chatRoomId: id,
      name,
    });

    if (success && isCurrentChatRoom) {
      navigate(`/chat`);
    }
  });

  const preventPropagation: MouseEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleReassignStudent = useCallback(() => {
    setShowSidebar(false);
    setReassignChatRoomId(id);
  }, [setShowSidebar, setReassignChatRoomId, id]);

  const handleMarkStatus = useCallback(
    (newStatus: string) => {
      markStatus(chatRoom, newStatus);
    },
    [markStatus, chatRoom],
  );

  return (
    <DeletingIndicator deleting={isBeingArchived}>
      <Link
        to={`/chat/${id}`}
        className={cs(
          'flex flex-row px-4 group border',
          isCurrentChatRoom
            ? 'bg-light-grey border-grey rounded-md shadow-drop-01'
            : 'border-white',
        )}
        onClick={hideSidebar}
      >
        <div className="shrink-0 mt-4 mb-4 relative self-start">
          <Avatar
            size={40}
            bordered={false}
            avatarUrl={representative?.avatarUrl}
          />
          {isVideoChatActive && (
            <Icon
              name="VideoRoomFillOutline"
              className="absolute text-primary w-[1.125rem] h-[1.125rem] -right-1 bottom-0"
            />
          )}
        </div>
        <Layout.Spacer.Horizontal size="tiny" />
        <div
          data-cy="studentTileButton"
          className={cs(
            'flex-1 flex flex-row justify-between py-4 min-h-[3rem] min-w-0',
            !isCurrentChatRoom && 'border-b border-light-grey',
          )}
        >
          <div className="min-w-0">
            <div className="flex flex-row">
              <p
                className="font-semibold font-sora text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px] xl:max-w-[135px] 2xl:max-w-[230px]"
                style={{ color: representativeColor }}
              >
                {representativeName}
              </p>
              {Role.isMicrobot(representative) && (
                <Icon
                  name="StarsSup"
                  className="w-3 h-3"
                  color={representativeColor}
                />
              )}
              {showAssistantIcon && (
                <Icon
                  className="text-secondary"
                  name="Assistant"
                  size={Spacing.tiny}
                />
              )}
            </div>
            <p
              className={cs(
                'truncate text-sm text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px] xl:max-w-[135px] 2xl:max-w-[230px]',
                ([
                  ChatRoomActivity.NewConnection,
                  ChatRoomActivity.OtherActivity,
                ].includes(activity.type) ||
                  displayAssistantTitle ||
                  showPendingTitle) &&
                  'text-primary',
              )}
            >
              {chatRoomTitle}
            </p>
            <div className="shrink min-w-0 grow self-stretch">
              {statusId !== NoStatusLiteral && (
                <StatusLabel statusId={statusId} />
              )}
            </div>
          </div>
          {/* Prevent further propagation of the click event */}
          {/* Otherwise on small screens the sidebar hides when dropdown is shown */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div
            onClick={preventPropagation}
            className="shrink-0 flex flex-col items-end"
          >
            <div className="flex flex-row items-center">
              {activity.unread && (
                <span
                  className={cs('bg-primary float-right w-2 h-2 rounded-full')}
                  role="img"
                  aria-label={t('unread-messages')}
                />
              )}
              <Layout.Spacer.Horizontal size="micro" />
              {Role.isMicrobot(representative) ? (
                <p
                  className="px-2 inline text-white uppercase rounded font-sora leading-4 font-semibold"
                  style={{
                    background: `linear-gradient(86deg, ${theme.colors.primary} 32.25%, ${theme.colors.primaryLight} 154.7%)`,
                    fontSize: '9px',
                  }}
                >
                  {t('new')}
                </p>
              ) : (
                <p className="font-normal font-sora text-10 text-dark-grey-025">
                  {chatRoom.latestMessage?.sentAt
                    ? DateFormatter.relativeTime(chatRoom.latestMessage.sentAt)
                    : ''}
                </p>
              )}
            </div>
            <PermissionGate scope={showChatRoomActionsScope(chatRoom.id)}>
              {() => (
                <ChatRoomMenuDropdown
                  chatRoomId={chatRoom.id}
                  currentStatusId={statusId}
                  onMarkStatus={handleMarkStatus}
                  onArchive={handleArchiveConversation}
                  onReassignStudent={handleReassignStudent}
                />
              )}
            </PermissionGate>
          </div>
        </div>
      </Link>
    </DeletingIndicator>
  );
}

function ChatRoomRowFallback(props: ChatRoomRowProps) {
  const { chatRoom } = props;
  const { id } = chatRoom;

  return (
    <Link
      to={`/chat/${id}`}
      className={cs('flex flex-row px-4 group border', 'border-white')}
    >
      <div className="shrink-0 mt-4 mb-4 relative self-start">
        <AvatarSkeleton size={40} />
      </div>
      <Layout.Spacer.Horizontal size="tiny" />
      <div
        data-cy="studentTileButton"
        className={cs(
          'flex-1 flex flex-row justify-between py-4 min-h-[3rem] min-w-0',
          'border-b border-light-grey',
        )}
      />
    </Link>
  );
}

export default memo(suspenseHOC(ChatRoomRow, ChatRoomRowFallback));
