import { useAtomValue } from 'jotai';
import { entries, keys, omitBy, pick } from 'lodash-es';

import { useEvent } from '@advisor/utils/hooks';
import {
  EmitCustomEventResult,
  PrepareVideoRoomForRecordingResult,
} from '../types';
import subscribeToCallEvent from './subscribeToCallEvent';
import useEmitCustomEvent from './useEmitCustomEvent';
import { dailyStateAtom } from './dailyState';
import { DailyCall } from './LibProvider';
import { sessionsAtom } from './sessions';

const ParticipantEvent = [
  'participant-joined',
  'participant-updated',
  'participant-left',
  'joined-meeting',
  'left-meeting',
  'error',
] as const;

const ParticipantsBlockTimeout = 5000; // ms, 5 seconds

async function ensureParticipantsAreMuted(
  call: DailyCall,
  sessionIdsBeingBlocked: string[],
) {
  let clearEvents: () => void;
  let timeout: ReturnType<typeof setTimeout>;

  const participantCheckPromise = new Promise<boolean>((resolve) => {
    const checkParticipants = () => {
      const sessions = pick(call.participants(), sessionIdsBeingBlocked);

      const allBlocked = entries(sessions).every(
        ([, p]) => !!p.tracks.audio.off && !!p.tracks.video.off,
      );

      if (allBlocked) {
        resolve(true);
      }
    };

    const unsubscribeList = ParticipantEvent.map((eventName) =>
      subscribeToCallEvent(call, eventName, checkParticipants),
    );

    clearEvents = () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };

    // Preemptively checking if by chance all participants are already blocked.
    checkParticipants();
  });

  return Promise.race([
    // Either all participants are blocked
    participantCheckPromise,
    // or we timeout
    new Promise<boolean>((resolve) => {
      timeout = setTimeout(() => resolve(false), ParticipantsBlockTimeout);
    }),
  ]).then((value) => {
    clearTimeout(timeout);
    clearEvents();

    return value;
  });
}

function usePrepareVideoRoomForRecording() {
  const dailyState = useAtomValue(dailyStateAtom);
  const sessions = useAtomValue(sessionsAtom);
  const emitCustomEvent = useEmitCustomEvent();

  const prepareVideoRoomForRecording = useEvent(async () => {
    if (!dailyState) {
      return PrepareVideoRoomForRecordingResult.NotReady;
    }

    const nonLocalParticipantIds = keys(omitBy(sessions, (p) => p.isLocal));

    const result = emitCustomEvent({
      type: 'mute-before-recording',
    });

    if (result !== EmitCustomEventResult.Sent) {
      throw new Error(
        `Could not send app message to other participants '${result}'`,
      );
    }

    const muted = await ensureParticipantsAreMuted(
      dailyState.call,
      nonLocalParticipantIds,
    );

    if (muted) {
      return PrepareVideoRoomForRecordingResult.Ready;
    }

    return PrepareVideoRoomForRecordingResult.NotReady;
  });

  return prepareVideoRoomForRecording;
}

export default usePrepareVideoRoomForRecording;
