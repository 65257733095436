export default function optimisticTranslationStatusFields(
  meId: string,
  id: string,
  status: string | null | undefined,
) {
  return {
    id,
    /**
     * Disregarding other user's statuses, as we do
     * not use them anyway.
     */
    translationStatus: status ? `{"${meId}": "${status}"}` : '{}',
  };
}
