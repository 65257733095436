import { z } from 'zod';
import { molecule } from 'bunshi';
import { useMolecule } from 'bunshi/react';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import {
  File,
  FileCategory,
  GreyLabelingDocument,
  UpdateGreyLabelingDocument,
  UpdateGreyLabelingMutationVariables,
} from '@advisor/api/generated/graphql';
import { atomWithQuery, clientAtom } from '@advisor/api/apollo';
import {
  FileData,
  uploadFile,
  deleteFile,
  UploadErrorType,
} from '@advisor/api/files';
import Sentry from '@advisor/utils/Sentry';
import { Colors } from '@advisor/design/styles';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { actionAtom, lazyAtom } from '@advisor/utils/atoms';

export type ColorPalette = z.infer<typeof ColorPalette>;
export const ColorPalette = z.object({
  primaryColor: z.string(),
  secondaryColor: z.string(),
});

const emptyColorPalette: ColorPalette = {
  primaryColor: '',
  secondaryColor: '',
};

type LogoUpload =
  | { status: 'uploading'; file: FileData }
  | { status: 'error'; file: FileData; error: UploadErrorType }
  | { status: 'uploaded'; file: File }
  | { status: 'saved'; file: File }
  | { status: 'deleted'; file?: null };

interface GreyLabeling {
  organizationLogo: null | LogoUpload;
  colorPalette: ColorPalette;
}

function parseColors(colorPalette: string | null | undefined): ColorPalette {
  try {
    return ColorPalette.parse(JSON.parse(colorPalette ?? ''));
  } catch {
    return emptyColorPalette;
  }
}

function isLogoUpload(
  organizationLogo: null | LogoUpload | File,
): organizationLogo is LogoUpload {
  return (
    (organizationLogo && !!(organizationLogo as LogoUpload).status) ?? false
  );
}

const GreyLabelingMolecule = molecule(() => {
  const greyLabelingQueryAtom = atomWithQuery(() => ({
    query: GreyLabelingDocument,
    fetchPolicy: 'network-only',
  }));

  const greyLabelingLocalAtom = atom<GreyLabeling>({
    organizationLogo: null,
    colorPalette: {
      primaryColor: '',
      secondaryColor: '',
    },
  });

  const greyLabelingAtom = lazyAtom(async (get) => {
    const queryData = (await get(greyLabelingQueryAtom)).data?.greyLabeling;
    const localData = get(greyLabelingLocalAtom);

    const colors = parseColors(queryData?.colorPalette);

    const organizationLogo = (() => {
      if (localData.organizationLogo) {
        return localData.organizationLogo;
      }

      if (queryData?.organizationLogo) {
        return {
          status: 'saved',
          file: queryData.organizationLogo,
        };
      }

      return null;
    })();

    return {
      organizationLogo,
      colorPalette: {
        primaryColor:
          localData.colorPalette?.primaryColor ||
          colors.primaryColor ||
          Colors.primaryBlue,
        secondaryColor:
          localData.colorPalette?.secondaryColor ||
          colors.secondaryColor ||
          Colors.cyan,
      },
    } as GreyLabeling;
  });

  const setColorAtom = actionAtom(
    ({ set }, name: keyof ColorPalette, value: string) => {
      set(greyLabelingLocalAtom, (prev) => ({
        ...prev,
        colorPalette: {
          ...prev.colorPalette,
          [name]: value,
        },
      }));
    },
  );

  const setOrganizationLogoAtom = actionAtom(
    async ({ get, set }, file: FileData | null) => {
      const apolloClient = await get(clientAtom);

      // If there is existing file that is uploaded but not saved,
      // delete it from S3 too
      if (!file) {
        const logo = get(greyLabelingLocalAtom).organizationLogo;

        set(greyLabelingLocalAtom, (prev) => ({
          ...prev,
          organizationLogo: {
            status: 'deleted',
            file: undefined,
          },
        }));

        if (logo?.status === 'uploaded') {
          await deleteFile(apolloClient, logo.file.id);
        }

        return;
      }

      set(greyLabelingLocalAtom, (prev) => ({
        ...prev,
        organizationLogo: {
          file,
          status: 'uploading',
        },
      }));

      const { promise } = uploadFile(apolloClient, 'Public', file, {
        fileCategory: FileCategory.ImageOrSvg,
      });

      const uploadResults = await promise;

      if (!uploadResults.ok) {
        set(greyLabelingLocalAtom, (prev) => ({
          ...prev,
          organizationLogo: {
            file,
            status: 'error',
            error: uploadResults.error,
          },
        }));
        return;
      }

      if (uploadResults.ok) {
        set(greyLabelingLocalAtom, (prev) => ({
          ...prev,
          organizationLogo: {
            status: 'uploaded',
            file: uploadResults.data,
          },
        }));
        return;
      }

      warnExhaustive(uploadResults, '@advisor/ui/GreyLabeling/useGreyLabeling');
    },
  );

  const mutateGreyLabelingAtom = actionAtom(
    async ({ get }, variables: UpdateGreyLabelingMutationVariables) => {
      const client = await get(clientAtom);

      try {
        await client.mutate({
          mutation: UpdateGreyLabelingDocument,
          variables,
          update(cache, results) {
            cache.updateQuery(
              {
                query: GreyLabelingDocument,
                variables: {
                  email: null,
                  agency: null,
                  userId: null,
                  phoneNumber: null,
                },
              },
              () => ({
                __typename: 'Query' as const,
                greyLabeling: {
                  ...results.data?.updateGreyLabeling,
                },
              }),
            );
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  );

  const updateGreyLabelingAtom = actionAtom(async ({ get, set }) => {
    const greyLabeling = await get(greyLabelingAtom);

    const variables: UpdateGreyLabelingMutationVariables = {
      colorPalette: JSON.stringify(greyLabeling.colorPalette),
    };

    if (
      greyLabeling.organizationLogo?.status === 'uploaded' ||
      greyLabeling.organizationLogo?.status === 'saved'
    ) {
      variables.organizationLogoFileId = greyLabeling.organizationLogo.file.id;
    }

    await set(mutateGreyLabelingAtom, variables);
  });

  const deleteGreyLabelingAtom = actionAtom(async ({ set }) => {
    await set(mutateGreyLabelingAtom, {
      colorPalette: null,
      organizationLogoFileId: null,
    });
  });

  const isCustomizedAtom = atom(async (get) => {
    const queryData = (await get(greyLabelingQueryAtom)).data?.greyLabeling;

    return !!queryData?.colorPalette || !!queryData?.organizationLogo;
  });

  return {
    /* Value Atom */
    greyLabelingAtom,
    isCustomizedAtom,

    /* Action Atoms */
    setColorAtom,
    updateGreyLabelingAtom,
    deleteGreyLabelingAtom,
    setOrganizationLogoAtom,
  };
});

export default function useGreyLabeling() {
  const {
    greyLabelingAtom,
    isCustomizedAtom,

    setColorAtom,
    setOrganizationLogoAtom,

    updateGreyLabelingAtom,
    deleteGreyLabelingAtom,
  } = useMolecule(GreyLabelingMolecule);

  const greyLabeling = useAtomValue(greyLabelingAtom);
  const isCustomized = useAtomValue(isCustomizedAtom);

  const setColor = useSetAtom(setColorAtom);
  const updateGreyLabeling = useSetAtom(updateGreyLabelingAtom);
  const deleteGreyLabeling = useSetAtom(deleteGreyLabelingAtom);
  const setOrganizationLogo = useSetAtom(setOrganizationLogoAtom);

  return {
    /* Values */
    ...greyLabeling,
    isCustomized,
    isLogoUploading:
      isLogoUpload(greyLabeling.organizationLogo) &&
      greyLabeling.organizationLogo.status === 'uploading',

    /* Actions */
    setColor,
    updateGreyLabeling,
    deleteGreyLabeling,
    setOrganizationLogo,
  };
}
