/* eslint no-console:0 */

const logsEnabled = false;

const Logger = {
  log(...args: unknown[]) {
    if (logsEnabled) {
      console.log(...args);
    }
  },
  warn(...args: unknown[]) {
    if (logsEnabled) {
      console.warn(...args);
    }
  },
  logJSON(obj: unknown, nicely: boolean) {
    if (logsEnabled) {
      console.log(JSON.stringify(obj, null, nicely ? 2 : undefined));
    }
  },
};

export default Logger;
