import { useAtom } from 'jotai';
import { useEffect } from 'react';

import {
  useCallState,
  useVideoRoomAPI,
  VideoCallStatus,
  useUpdateSessionMetadata,
} from '../VideoRoomAPI';
import { isCameraEnabledAtom, isMicrophoneEnabledAtom } from './atoms';
import useLocalCameraProperties from './useLocalCameraProperties';

function PeripheralSync() {
  const callState = useCallState();
  const { toggleAudio, toggleVideo } = useVideoRoomAPI() ?? {};
  const updateSessionMetadata = useUpdateSessionMetadata();

  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useAtom(
    isMicrophoneEnabledAtom,
  );

  const [isCameraEnabled, setIsCameraEnabled] = useAtom(isCameraEnabledAtom);
  const { aspectRatio: cameraAspectRatio } = useLocalCameraProperties();

  // Synchronizing local audio and video with Jotai atoms.
  // Synchronize only when the call is in "active" state.
  // Otherwise f.e. daily default state will overwrite the local settings
  // Causing in mismatch between local and real settings
  useEffect(() => {
    if (callState?.status !== VideoCallStatus.Joined) {
      return;
    }

    toggleAudio?.(isMicrophoneEnabled);
    toggleVideo?.(isCameraEnabled);
  }, [
    toggleAudio,
    toggleVideo,
    isCameraEnabled,
    callState?.status,
    isMicrophoneEnabled,
  ]);

  // Reset local audio and video settings when the call is not active.
  // Reason: when joining a call, for a brief moment, daily uses their default settings.
  // resulting in "flickering" when microphone/camera was disabled during previous call.
  useEffect(() => {
    if (callState?.status === VideoCallStatus.Leaving) {
      setIsMicrophoneEnabled(true);
      setIsCameraEnabled(true);
    }
  }, [callState?.status, setIsCameraEnabled, setIsMicrophoneEnabled]);

  // Synchronizing camera aspect ratio with Jotai atoms.
  useEffect(() => {
    if (callState?.status !== VideoCallStatus.Joined) {
      return;
    }

    updateSessionMetadata({
      cameraAspectRatio,
    });
  }, [callState, updateSessionMetadata, cameraAspectRatio]);

  return null;
}

export default PeripheralSync;
