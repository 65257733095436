import React from 'react';
import cs from 'classnames';

import { Layout } from '@advisor/design/components';
import { useMicrobotMessage } from '../../messaging/messageMolecule';
import { useAppropriateFontName } from '../shared/utils';
import LimitedVisibilityTag from '../shared/LimitedVisibilityTag';
import MessageContent from '../shared/MessageContent';
import AvatarAndName from '../shared/AvatarAndName';
import ChatMessage from '../shared/ChatMessage';
import AssistActionsBox from './AssistActionsBox';
import { MicrobotMessageProps } from './types';

function MicrobotMessage(props: MicrobotMessageProps) {
  const { isFirstInGroup, isLastInGroup } = props;
  const { author, footerMessage, actions, message } =
    useMicrobotMessage('required');
  const fontName = useAppropriateFontName(message);

  const hasAssistFooter = !!footerMessage || actions.length > 0;

  return (
    <ChatMessage.Container isFromMe={false}>
      <ChatMessage.Bubble
        isFromMe={false}
        isFirstInGroup={isFirstInGroup}
        isLastInGroup={isLastInGroup}
        noBottom={hasAssistFooter}
      >
        {isLastInGroup ? (
          <>
            <LimitedVisibilityTag author={author} />
            <Layout.Spacer.Vertical size="micro" />
            <AvatarAndName user={author} />
          </>
        ) : (
          <Layout.Spacer.Vertical size="tiny" />
        )}
        <div className={cs('message-word-break', fontName)}>
          <MessageContent doubleStarIsBold>{message}</MessageContent>
        </div>
      </ChatMessage.Bubble>
      {hasAssistFooter && <AssistActionsBox />}
    </ChatMessage.Container>
  );
}

export default MicrobotMessage;
