import { useMemo, useRef } from 'react';
import cs from 'classnames';

import { useWindowSize } from '@advisor/design/hooks';
import useScrollTop from 'src/hooks/useScrollTop';

function MessageBubble({
  className,
  children,
  side,
}: {
  className?: string;
  children?: string;
  side: 'right' | 'left';
}) {
  const elementRef = useRef<HTMLDivElement>(null);

  const scrollTop = useScrollTop();
  const [, windowHeight] = useWindowSize();

  const showBubble = useMemo(() => {
    if (!elementRef.current) {
      return false;
    }

    const { top } = elementRef.current.getBoundingClientRect();
    return top < windowHeight * 0.75;
    // eslint-disable-next-line
  }, [scrollTop, windowHeight]);

  return (
    <div ref={elementRef} className={className}>
      <div
        className={cs(
          'relative rounded-20',
          'text-2xl md:text-3xl text-white text-center',
          'duration-200 transition-all ease-out',
          side === 'right' ? 'xl:origin-left' : 'xl:origin-right',
          showBubble
            ? 'opacity-100 translate-y-0 scale-100'
            : 'opacity-0 translate-y-10 scale-75',
        )}
        style={{
          boxShadow: '0px 10px 48px rgba(0, 0, 0, 0.13)',
        }}
      >
        <svg
          className={cs(
            'absolute -bottom-7',
            side === 'right' ? '-scale-x-100 left-0' : 'right-0',
          )}
          width="54"
          height="54"
          viewBox="0 0 54 54"
        >
          <path
            d="M54 51.1716V2C54 0.895431 53.1046 0 52 0H2.82843C1.04662 0 0.154284 2.15428 1.41421 3.41421L50.5858 52.5858C51.8457 53.8457 54 52.9534 54 51.1716Z"
            fill={side === 'right' ? '#1440D7' : '#126FF4'}
          />
        </svg>
        <div
          className={cs(
            'relative px-8 py-10 rounded-20 bg-[radial-gradient(at_bottom_left,#0F39D3,#4285F7)]',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default MessageBubble;
