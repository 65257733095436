import { derive } from '@advisor/utils/atoms';
import { atomOfQuery } from '../apollo';
import { MeDocument } from '../generated/graphql';

const meQueryAtom = atomOfQuery(MeDocument);

export const meAtom = derive([meQueryAtom], (res) => res?.data?.me);

// Commonly used properties deserve their own atoms, as it best to
// subscribe a Component to only the data that it cares about.

export const myIdAtom = derive([meAtom], (me) => me?.id);
export const myAvatarUrlAtom = derive([meAtom], (me) => me?.avatarUrl ?? null);
