import React from 'react';
import { Transition } from '@headlessui/react';
import { FadeInProps } from './types';

const FadeIn: React.FC<FadeInProps> = ({ children, show, className }) => {
  return (
    <Transition
      unmount
      show={show}
      className={className}
      enter="ease-in-out duration-300 transition-[opacity,transform]"
      enterFrom="opacity-0 -translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="ease-in-out duration-300 transition-[opacity,transform]"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {children}
    </Transition>
  );
};

export default FadeIn;
