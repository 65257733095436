import { useCallback } from 'react';

import { UseSpeakerStateHook } from './types';

// No speaker functionality on web
const useSpeakerState: UseSpeakerStateHook = () => {
  const toggle = useCallback(async (_override?: boolean) => {}, []);

  return [false, toggle];
};

export default useSpeakerState;
