import React from 'react';

import { useIsFeatureEnabled, Feature } from '@advisor/api/feature';
import { useVideoChatSubscription } from './useVideoChat';
import { ParticipantListener } from './Participants';
import RecordingManager from './RecordingManager';
import BackgroundAudio from './BackgroundAudio';
import { PeripheralSync } from './LocalSettings';
import { VideoNavTimeout } from './CallTimers';
import { VideoRoomAPIProvider } from './VideoRoomAPI';

type Props = {
  children?: React.ReactNode;
};

function VideoRoomProvider({ children }: Props) {
  const isVideoChatEnabled = useIsFeatureEnabled(Feature.VideoChat);

  useVideoChatSubscription();

  if (!isVideoChatEnabled) {
    return <>{children}</>;
  }

  return (
    <VideoRoomAPIProvider>
      <ParticipantListener />
      <RecordingManager />
      <BackgroundAudio />
      <PeripheralSync />
      <VideoNavTimeout>{children}</VideoNavTimeout>
    </VideoRoomAPIProvider>
  );
}

export default VideoRoomProvider;
