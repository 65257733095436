import { z } from 'zod';

import Platform from '@advisor/utils/Platform';
import { InstanceConfig } from '@advisor/utils/instances';
import { Env } from './types';

type InstanceConfig = z.infer<typeof InstanceConfig>;

interface Context {
  DEBUG: boolean;
  e2eTestOTP?: string;
  e2eTestPassword?: string;
}

export default function getEnv(
  rawInstanceConfig: unknown,
  context: Context,
): Env {
  let instanceConfig: InstanceConfig;
  try {
    instanceConfig = InstanceConfig.parse(rawInstanceConfig);
  } catch (e) {
    throw new Error(
      `Failed to parse instance config. Error: ${e}. Raw value: ${JSON.stringify(
        rawInstanceConfig,
        null,
        2,
      )}`,
    );
  }

  if (context.DEBUG) {
    // Not reporting to Sentry in debug mode, logging only to local console.
    instanceConfig.sentryReporting.frontendDsnUrl = undefined;
  }

  const verificationAccount = instanceConfig.verificationAccount
    ? {
        ...instanceConfig.verificationAccount,
        // Storing these here for now, but probably should change it.
        // TODO: https://app.asana.com/0/1202471703024128/1207055380809295/f
        otpCode: '123456',
        password: 'PowerfulPassword!@#$',
      }
    : undefined;

  const e2eTest =
    context.e2eTestOTP && context.e2eTestPassword
      ? {
          otpCode: context.e2eTestOTP,
          password: context.e2eTestPassword,
        }
      : undefined;

  return {
    ...instanceConfig,

    DEBUG: context.DEBUG,

    auth0: {
      ...instanceConfig.auth0,
      responseType: 'token id_token',
      redirectUri:
        Platform === 'web' ? `${window.location.origin}/callback` : '',
      scope: 'openid profile phone email',
    },

    verificationAccount,

    appsyncConfig: {
      region: 'us-east-1',
      authType: 'OPENID_CONNECT',
    },

    e2eTest,
  };
}
