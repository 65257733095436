import React, { memo, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLanguage } from '@advisor/language';

const FontProvider = () => {
  const { uiLanguage, isRTL } = useLanguage();

  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);

  const fontOverride = useMemo(() => {
    if (uiLanguage === 'ar') {
      return 'Noto Sans Arabic';
    }

    if (uiLanguage === 'pl') {
      return 'Noto Sans';
    }

    return null;
  }, [uiLanguage]);

  const soraOverride = useMemo(() => {
    if (uiLanguage === 'ar') {
      return 'Tajawal';
    }

    return null;
  }, [uiLanguage]);

  const outfitSizeOverride = useMemo(() => {
    if (fontOverride !== null) {
      return [
        ['xs', '0.69rem'],
        ['sm', '0.80rem'],
        ['base', '0.91rem'],
      ];
    }

    return null;
  }, [fontOverride]);

  return (
    <Helmet>
      <style type="text/css">
        {`
        :root {
          --outfit-font-family: ${fontOverride || 'Outfit'};
          --sora-font-family: ${soraOverride || 'Sora'};

          ${
            outfitSizeOverride
              ? outfitSizeOverride
                  .map(([key, value]) => `--outfit-${key}: ${value};\n`)
                  .join('')
              : ''
          }
        }
      `}
      </style>
    </Helmet>
  );
};

export default memo(FontProvider);
