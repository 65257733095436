import { noop } from 'lodash-es';
import { RefObject, useLayoutEffect, useState } from 'react';

import { type Rect } from './types';

const useBoundingElement = (elementRef: RefObject<HTMLDivElement>): Rect => {
  const [size, setSize] = useState<Rect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const element = elementRef.current;

    const handleResize = () => {
      if (!element) {
        return;
      }

      const rect = element?.getBoundingClientRect();

      if (rect) {
        setSize({
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
        });
      }
    };

    if (!element) {
      return noop;
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    // Update position after eventual element animations
    // f.e. if the bounding element is inside side modal
    element.addEventListener('animationend', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      element.removeEventListener('animationend', handleResize);
    };
  }, [elementRef]);

  return size;
};

export default useBoundingElement;
