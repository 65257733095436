/* eslint-disable import/prefer-default-export */

function resolveNumberConstrain(constrain?: ConstrainDouble | ConstrainULong) {
  if (!constrain) {
    return undefined;
  }

  if (typeof constrain === 'number') {
    return constrain;
  }

  return constrain.exact ?? constrain.ideal ?? constrain.max ?? constrain.min;
}

export function calculateAspectRatio(videoTrack: MediaStreamTrack) {
  if (typeof videoTrack.getConstraints !== 'function') {
    return undefined;
  }

  const constraints = videoTrack.getConstraints();

  let ratio = resolveNumberConstrain(constraints.aspectRatio);

  if (ratio === undefined) {
    const width = resolveNumberConstrain(constraints.width);
    const height = resolveNumberConstrain(constraints.height);
    ratio = width && height ? width / height : undefined;
  }

  return ratio;
}
