import { ApolloClient } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import {
  FileCategory,
  RequestFileUploadDocument,
  RequestFileUploadMutation,
  RequestFileUploadMutationVariables,
} from '../../generated/graphql';
import { FileData, UploadErrorType, FileUploadOptions } from '../types';
import { isFileTooLarge } from '../utils';

async function requestPublicFileUpload(
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions['Public'],
  signal?: AbortSignal,
) {
  const { sizeThreshold, fileCategory } = options;

  if (isFileTooLarge(file, sizeThreshold)) {
    return { error: UploadErrorType.FileTooLarge };
  }

  try {
    const { data } = await client.mutate<
      RequestFileUploadMutation,
      RequestFileUploadMutationVariables
    >({
      mutation: RequestFileUploadDocument,
      variables: {
        fileType: file.type,
        // We use Image as default but we should require to specify the exact category
        fileCategory: fileCategory ?? FileCategory.Image,
      },
      context: {
        fetchOptions: {
          signal,
        },
      },
    });

    const response = data?.requestFileUpload;

    if (!response) {
      return { error: UploadErrorType.UploadDenied };
    }

    type Fields = Record<string, string> | undefined;
    const fields = JSON.parse(response.fields) as Fields;
    return {
      ok: true as const,
      data: {
        uploadUrl: response.uploadUrl,
        fileId: response.fileId,
        formDataFields: fields ?? {},
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    return { error: UploadErrorType.UploadDenied };
  }
}

export default requestPublicFileUpload;
