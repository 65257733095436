import React from 'react';
import cs from 'classnames';

import { Icon, Avatar } from '@advisor/design/components';
import { useConnectedParticipants } from '@advisor/videoCall/Participants';

const ParticipantsAvatars = () => {
  const participants = useConnectedParticipants();

  if (participants.length === 0) {
    return null;
  }

  const twoAvatars = participants.length >= 2;

  return (
    <div className={cs('relative h-8', twoAvatars ? 'w-11' : 'w-8')}>
      <Avatar
        avatarUrl={participants[0].avatarUrl}
        className="absolute bottom-0 right-0"
        bordered={false}
        size={30}
      />
      {twoAvatars && (
        <Avatar
          avatarUrl={participants[1].avatarUrl}
          className="absolute bottom-0 left-0"
          bordered={false}
          size={30}
        />
      )}
      <Icon
        name="VideoRoomFillOutline"
        className="absolute -bottom-1 -right-0.5 w-4 h-4 text-primary"
      />
    </div>
  );
};

export default ParticipantsAvatars;
