import React, { ChangeEvent, useRef, useState } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import ResizableTextArea from '@advisor/chat/ChatBottomBar/web/ResizableTextArea';
import { TextAreaWithPlaceholderProps } from './types';

const TextAreaWithPlaceholder: React.FC<TextAreaWithPlaceholderProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onPlaceholderClick = () => {
    if (inputRef.current) {
      setShowPlaceholder(false);
      inputRef.current.focus();
      inputRef.current.setSelectionRange(value.length, value.length);
    }
  };

  const onFocus = () => {
    setShowPlaceholder(false);
  };

  const onBlur = () => {
    setShowPlaceholder(true);
  };

  const onChangeInner = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex-1 flex flex-col">
      <ResizableTextArea
        ref={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={onChangeInner}
        className={cs(
          'rounded-b-lg px-8 pt-6 resize-none w-full focus:outline-none caret-primary font-outfit text-sm leading-[1.125rem] font-medium text-dark-grey-03',
          (!placeholder || !showPlaceholder) && 'flex-1 pb-6',
        )}
      />
      {placeholder && showPlaceholder && (
        <Placeholder onClick={onPlaceholderClick} />
      )}
    </div>
  );
};

export default TextAreaWithPlaceholder;

const Placeholder: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('microbot');

  return (
    <button
      type="button"
      className="flex-1 flex flex-col px-8 -mt-4 text-dark-grey-01 font-outfit text-sm leading-[1.125rem] font-medium cursor-text select-none"
      onClick={onClick}
    >
      <p>{t('example')}</p>
      <ul className="ltr:ml-1 rtl:mr-1 w-full ltr:text-left rtl:text-right">
        <li>{t('harvard-university-usa')}</li>
        <li>{t('university-of-cambridge-uk')}</li>
        <li>{t('university-of-oxford-uk')}</li>
      </ul>
    </button>
  );
};
