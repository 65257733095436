import { map, reduce } from 'lodash-es';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';

import {
  SystemMessageInfoFragment,
  JourneyMilestoneCommentSystemMessageData,
} from '@advisor/api/generated/graphql';
import { DateFormatter } from '@advisor/language';
import { useCachedUser } from '@advisor/api/user';
import { useAmIVerifiedAdvisor } from '@advisor/api/me';
import { useActiveChatRoom } from '@advisor/api/chatRoom';

import useInterpolatedUsername from '../../hooks/useInterpolatedUsername';
import {
  getUserId,
  getItemTitle,
  getCategoryId,
  getMilestoneId,
  getCategoryName,
  getMilestoneName,
  getTranslationKey,
} from './utils';
import {
  Message,
  BoldText,
  Container,
  ExpandButton,
  GroupMessageItemList,
  SimpleMessage,
} from './systemMessageComponents';
import GroupMessageCommentItem from './GroupMessageCommentItem';
import { GroupedMessageProps, CommentItemMap } from './types';
import { useOpenJourneyMilestone } from './navigation';
import GroupMessageItem from './GroupMessageItem';

const GroupedMessage: React.FC<GroupedMessageProps> = ({ group }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const lastMessage = group.data[0] as SystemMessageInfoFragment;
  const { t } = useTranslation('system-message');
  const { t: commonT } = useTranslation('common');
  const { chatRoomId, chatRoomAtom } = useActiveChatRoom('required');
  const chatRoom = useAtomValue(chatRoomAtom);

  const amIVerifiedAdvisor = useAmIVerifiedAdvisor();
  const userId = getUserId(lastMessage);
  const category = getCategoryName(lastMessage);
  const datetime = DateFormatter.dateTime(lastMessage.sentAt, commonT);
  const translationKey = getTranslationKey(lastMessage.data.type);
  const openMilestone = useOpenJourneyMilestone();
  const cachedUser = useCachedUser(userId);
  const interpolatedName = useInterpolatedUsername(cachedUser, chatRoomId);

  const defaultItems = useMemo(() => {
    if (
      lastMessage.data.__typename === 'JourneyMilestoneCommentSystemMessageData'
    ) {
      return [];
    }

    return group.data.map((message) => ({
      key: message.id,
      title: getItemTitle(message as SystemMessageInfoFragment),
      chatRoomId: message.chatRoomId,
      categoryId: getCategoryId(message as SystemMessageInfoFragment),
      milestoneId: getMilestoneId(message as SystemMessageInfoFragment),
      dataTypename: (message as SystemMessageInfoFragment).data.__typename,
    }));
  }, [group, lastMessage.data.__typename]);

  const commentItemMap = useMemo(() => {
    if (
      lastMessage.data.__typename !== 'JourneyMilestoneCommentSystemMessageData'
    ) {
      return {};
    }

    return reduce(
      group.data,
      (acc, message) => {
        const data = (message as SystemMessageInfoFragment)
          .data as JourneyMilestoneCommentSystemMessageData;
        const authorName = data.author?.name ?? data.authorName;

        const prevInfo = acc[data.metadata.milestoneId] ?? {
          commentCount: 0,
          key: data.metadata.milestoneId,
          chatRoomId: message.chatRoomId,
          categoryId: getCategoryId(message as SystemMessageInfoFragment),
          milestoneId: getMilestoneId(message as SystemMessageInfoFragment),
          milestoneName: getMilestoneName(message as SystemMessageInfoFragment),
          dataTypename: (message as SystemMessageInfoFragment).data.__typename,
          authorName:
            amIVerifiedAdvisor &&
            chatRoom?.__typename === 'StudentChatRoom' &&
            chatRoom.name === authorName
              ? chatRoom.studentName
              : authorName,
        };

        return {
          ...acc,
          [data.metadata.milestoneId]: {
            ...prevInfo,
            commentCount: prevInfo.commentCount + 1,
          },
        };
      },
      {} as CommentItemMap,
    );
  }, [group, lastMessage.data.__typename, chatRoom, amIVerifiedAdvisor]);

  if (defaultItems.length === 0 && Object.keys(commentItemMap).length === 1) {
    const item = commentItemMap[Object.keys(commentItemMap)[0]];

    return (
      <SimpleMessage
        onPress={() => {
          openMilestone(item.chatRoomId, item.categoryId, item.milestoneId);
        }}
      >
        <Trans
          t={t}
          parent={Message}
          i18nKey="user-made-count-comments-in-task-organiser-on"
          components={{ bold: <BoldText /> }}
          values={{ count: item.commentCount, user: item.authorName, datetime }}
        />
      </SimpleMessage>
    );
  }

  return (
    <Container>
      <ExpandButton
        isExpanded={!isCollapsed}
        onPress={() => setIsCollapsed((prev) => !prev)}
      >
        <Trans
          t={t}
          i18nKey={translationKey}
          parent={Message}
          values={{
            count: String(group.data.length).padStart(2, '0'),
            category,
            datetime,
            user: interpolatedName,
          }}
          components={{ bold: <BoldText /> }}
        />
      </ExpandButton>
      {!isCollapsed && (
        <GroupMessageItemList>
          {defaultItems.map((item) => (
            <GroupMessageItem {...item} />
          ))}
          {map(commentItemMap, (item) => (
            <GroupMessageCommentItem {...item} />
          ))}
        </GroupMessageItemList>
      )}
    </Container>
  );
};

export default memo(GroupedMessage);
