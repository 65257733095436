import { memo } from 'react';

import type { LegacyTextProps, TextCreator, InputFontFamily } from './types';

// TODO: can be removed when component is implemented
// @ts-ignore
// eslint-disable-next-line
const Text = memo((props: LegacyTextProps) => {
  // eslint-disable-next-line
  return null;
});

function TextBuilder<F extends InputFontFamily>(
  _fontFamily: F,
): TextCreator<F> {
  return function Inner(_options) {
    // eslint-disable-next-line
    console.error('[Web] Layout.Text is not implemented yet!');
    return (_props) => null;
  };
}

export default Object.assign(Text, {
  Outfit: TextBuilder('Outfit'),
  Sora: TextBuilder('Sora'),
});
