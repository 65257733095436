import cs from 'classnames';
import { pipe } from 'remeda';
import { noop } from 'lodash-es';
import { Dialog, Transition } from '@headlessui/react';
import React, { CSSProperties, Fragment, memo } from 'react';

import { withPadding } from '../../hoc';
import { useLengthToCSS, useScreenBreakpoint } from '../../responsive';
import type { ModalProps } from './types';

const Modal: React.FC<ModalProps> = ({
  visible,
  onClose,
  children,
  items = 'stretch',
  justify = 'start',
  maxWidth,
  height,
  minHeight,
  maxHeight = [90, 'dvh'],
  style,
  appear = false,
  position = 'responsive',
}) => {
  const atLeastMediumScreen = useScreenBreakpoint('md');
  const maxWidthCss = useLengthToCSS(maxWidth);
  const heightCss = useLengthToCSS(height);
  const minHeightCss = useLengthToCSS(minHeight);
  const maxHeightCss = useLengthToCSS(maxHeight) ?? '100vh';

  const isFloating =
    position === 'floating' ||
    (position === 'responsive' && atLeastMediumScreen);

  return (
    <Transition show={visible} appear={appear} as={Fragment}>
      <Dialog static onClose={onClose ?? noop}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-in duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className="fixed inset-0 bg-black bg-opacity-50 z-20"
            onClick={onClose}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div
            className={cs(
              'pointer-events-none fixed inset-0 flex justify-center z-30',
              {
                bottom: 'items-end',
                floating: 'items-center',
                responsive: 'items-center max-md:items-end',
              }[position],
            )}
          >
            <section
              className={cs(
                'flex flex-col pointer-events-auto relative overflow-y-auto bg-white w-full md:-top-[5vh]',
                {
                  bottom: 'rounded-lg rounded-b-none',
                  floating: 'rounded-lg',
                  responsive: 'rounded-lg max-md:rounded-b-none',
                }[position],
                {
                  start: 'items-start',
                  center: 'items-center',
                  end: 'items-end',
                  stretch: 'items-stretch',
                }[items],
                {
                  start: 'justify-start',
                  center: 'justify-center',
                  end: 'justify-end',
                }[justify],
              )}
              style={{
                ...(style as CSSProperties),
                maxWidth: isFloating ? maxWidthCss ?? 'fit-content' : undefined,
                height: isFloating ? heightCss : undefined,
                // limiting by 90vh in case dvh units are not supported
                minHeight:
                  isFloating && minHeightCss
                    ? `min(min(${minHeightCss}, 90dvh), 90vh)`
                    : undefined,
                maxHeight: isFloating
                  ? `min(min(${maxHeightCss}, 90dvh), 90vh)`
                  : 'min(90dvh, 90vh)',
              }}
            >
              {children}
            </section>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default pipe(memo(Modal), withPadding());
