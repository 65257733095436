/**
 * Based on: https://github.com/onurzorluer/react-image-file-resizer
 */
import { ResizeOptions } from './types';

function dataURLToByteArrays(dataURL: string) {
  const sliceSize = 512;

  const byteCharacters = window.atob(
    dataURL.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ''),
  );

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return byteArrays;
}

function dataURLToFile(dataURL: string, fileName: string, contentType: string) {
  const byteArrays = dataURLToByteArrays(dataURL);

  return new File(byteArrays, fileName, {
    type: contentType,
    lastModified: Date.now(),
  });
}

function calculateNewDimensions(
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
): { width: number; height: number } {
  let { width, height } = image;

  if (width > maxWidth) {
    height = Math.round((height * maxWidth) / width);
    width = maxWidth;
  }

  if (height > maxHeight) {
    width = Math.round((width * maxHeight) / height);
    height = maxHeight;
  }

  return { width, height };
}

function loadImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('File not Found!'));
      return;
    }

    if (!file.type?.includes('image')) {
      reject(new Error('File is not an image!'));
      return;
    }

    const reader = new FileReader();

    reader.onerror = (error) => {
      reject(error);
    };

    reader.onload = () => {
      const image = new Image();

      image.onload = function onImageLoad() {
        resolve(image);
      };

      if (!reader.result) {
        reject(new Error('Error wile reading the file!'));
      }

      image.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  });
}

export default async function resizeImage(file: File, options: ResizeOptions) {
  const { quality = 1, maxWidth, maxHeight } = options;

  const contentType = 'image/jpeg';
  const image = await loadImage(file);
  const canvas = document.createElement('canvas');

  const { width, height } = calculateNewDimensions(image, maxWidth, maxHeight);

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error("Could't create cavans context!");
  }

  ctx.fillStyle = 'rgba(0, 0, 0, 0)';
  ctx.fillRect(0, 0, width, height);

  if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
    ctx.imageSmoothingQuality = 'high';
  }

  ctx.drawImage(image, 0, 0, width, height);

  const resizedImageDataUrl = canvas.toDataURL(contentType, quality);

  const fileName = file.name.replace(/(png|jpeg|jpg|webp)$/i, '').concat('jpg');

  const resizedFile = dataURLToFile(resizedImageDataUrl, fileName, contentType);

  return resizedFile;
}
