import React from 'react';

import type { LegacyTextProps } from '../Layout/Text/types';

const makeWeight =
  (className: string) =>
  ({ children }: LegacyTextProps) =>
    <strong className={className}>{children}</strong>;

const Weight = {
  Thin: makeWeight('font-thin'),
  ExtraLight: makeWeight('font-extralight'),
  Light: makeWeight('font-light'),
  Regular: makeWeight('font-normal'),
  Medium: makeWeight('font-medium'),
  SemiBold: makeWeight('font-semibold'),
  Bold: makeWeight('font-bold'),
  ExtraBold: makeWeight('font-extrabold'),
  Black: makeWeight('font-black'),
};

export default Weight;
