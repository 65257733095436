import { useMemo } from 'react';

import { ContextMenuScopeType } from './types';

/**
 * Used to explicitly create a context menu scope and pass it to a context menu owner,
 * meaning we can control the context menu from the outside.
 *
 * If a value is passed as an argument, then it just gets returned, instead of creating a
 * new unique scope.
 */
function useOrCreateContextMenuScope(
  scope?: ContextMenuScopeType,
): ContextMenuScopeType {
  return useMemo(() => scope ?? {}, [scope]);
}

export default useOrCreateContextMenuScope;
