import { atom, Atom, Getter, Setter, WritableAtom } from 'jotai';

import lazyFrom, { LazyAtom, LazyOptions } from './lazyFrom';

type AtomSetter<Args extends unknown[], Result> = (
  get: Getter,
  set: Setter,
  ...args: Args
) => Result;

/**
 * Creates an atom that works like a normal derived async atom, meaning it returns
 * a promise of it's value, until that initial promise resolves. From then on, the
 * atom holds the value of the promise that was resolved last.
 *
 * ### Advantages:
 * - **Less unnecessary computations**: The atom will only change its value once the result of `compute` returns something
 *   different than the last stored value (according to the `areEqual` function which defaults to `Object.is`).
 *   This is in contrast to regular derived async atoms, where if an atom's dependencies
 *   change, the whole tree of their dependents are invalidated.
 * - **No UI-blips due to quick suspensions**: Sometimes updating a value that a derived async atom depends on does not
 *   change its computation's result, but it still causes the components that use it to Suspend in the mean-time, causing a
 *   very short but noticeable loading state. Lazy atoms circumvent that by only updating themselves at the end of computation.
 *
 * ### Caveats:
 * - **Stale data**: Be careful not to use the lazy atom's value in one-shot actions, such as GraphQL mutations. For this, each
 *   lazy atom has a `fresh` property, which is an atom that always returns a promise to the latest value.
 *
 * [Jotai lazyAtom Code Sandbox]{@link https://codesandbox.io/s/jotai-lazyatom-usage-example-493j3z?file=/src/App.js}
 *
 *
 * ### Example usage
 * ```
 * // A basic User schema for validation
 * const User = z.object({
 *   id: z.string(),
 *   username: z.string(),
 * });
 *
 * const refreshAtom = atom(0); // Changing this will cause a refetch.
 * const userAtom = atom(async (get) => {
 *   // A frequently updated object that has to be computed asynchronously.
 *
 *   get(refreshAtom); // depending on this to recalculate when we want to.
 *
 *   return fetch(`${API_URL}/me`).then(r => r.json());
 * });
 *
 * // By using lazy here, the dependents of this atom will ONLY have to get
 * // recalculated once the user's id changes, not every time the user object
 * // changes.
 * const userIdAtom = lazyAtom(async (get) =>
 *   (await get(userAtom)).id)
 * );
 * ```
 *
 * ### Usage in atom setters
 * Values held by these atoms are stale while they are being
 * recomputed, so using them for one-off actions such as GraphQL mutations can
 * cause a waterfall of stale UI. For this, the atom returned from this
 * function has a `fresh` property, which is an atom that holds
 * a promise for the most fresh computation result.
 *
 * ```
 * const usernameAtom = lazyAtom(async (get) =>
 *   (await get(userAtom)).username),
 * );
 *
 * const uppercaseUsernameAtom = atom(
 *   async (get) => (await get(usernameAtom)).toUppercase(),
 *   async (get, set, value: string) => {
 *     // Making sure we have the most up-to-date userId.
 *     const userId = await get(userIdAtom.fresh);
 *
 *     // POSTing the new username
 *     // ...
 *
 *     // Refetching the user
 *     set(refetchAtom, v => v + 1);
 *   },
 * );
 *
 * ```
 *
 * @param compute Computes the value of this atom based on other dependencies.
 * @param options The new computation result is compared with
 *   the previous one with the `areEqual` function, which defaults
 *   to `Object.is`.
 * @returns
 */
function lazyAtom<Value, Args extends unknown[], Result>(
  compute: (get: Getter) => Value,
  options?: LazyOptions,
): LazyAtom<Value, Args, Result, Atom<Value>>;

function lazyAtom<Value, Args extends unknown[], Result>(
  compute: (get: Getter) => Value,
  setter: AtomSetter<Args, Result> | LazyOptions,
  options?: LazyOptions,
): LazyAtom<Value, Args, Result, WritableAtom<Value, Args, Result>>;

function lazyAtom<Value, Args extends unknown[], Result>(
  compute: (get: Getter) => Value,
  arg1?: AtomSetter<Args, Result> | LazyOptions,
  arg2?: LazyOptions,
) {
  const options = typeof arg1 === 'object' ? arg1 : arg2;
  const setter = typeof arg1 === 'function' ? arg1 : undefined;

  const invalidatingCompute = (get: Getter) => {
    if (options?.invalidateOn) get(options.invalidateOn);

    return compute(get);
  };

  const sourceAtom: WritableAtom<Value, Args, Result> | Atom<Value> = setter
    ? atom(invalidatingCompute, setter)
    : atom(invalidatingCompute);

  return lazyFrom(sourceAtom, options) as unknown;
}

export default lazyAtom;
