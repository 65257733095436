import AddAttachment from './AddAttachment';
import Bare from './Bare';
import Create from './Create';
import Text from './Text';
import Icon from './Icon';
import Large from './Large';
import Outline from './Outline';

const Button = {
  AddAttachment,
  Bare,
  Create,
  Text,
  Icon,
  Large,
  Outline,
};

export default Button;
