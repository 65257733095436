/* eslint-disable class-methods-use-this */
import { ValueOf } from '@advisor/utils/typeUtils';

const sizes = {
  none: 0,
  base: 4,
  md: 6,
  lg: 8,
  ten: 10,
  xxl: 16,
  twenty: 20,
} as const;

type Sizes = typeof sizes;
type Size = ValueOf<Sizes>;

// Typescript hack to obtain return types of generic functions
class StyleMaker<S extends Size> {
  makeRounded = (size: S) => ({
    borderRadius: size,
  });

  makeTopRounded = (size: S) => ({
    borderTopLeftRadius: size,
    borderTopRightRadius: size,
  });

  makeBottomRounded = (size: S) => ({
    borderBottomLeftRadius: size,
    borderBottomRightRadius: size,
  });

  makeLeftRounded = (size: S) => ({
    borderBottomLeftRadius: size,
    borderTopLeftRadius: size,
  });

  makeRightRounded = (size: S) => ({
    borderTopRightRadius: size,
    borderBottomRightRadius: size,
  });
}

const styleMaker = new StyleMaker();
const map = <Make extends keyof StyleMaker<Size>>(make: Make) =>
  Object.fromEntries(
    Object.entries(sizes).map(([key, size]) => [key, styleMaker[make](size)]),
  ) as {
    [Key in keyof Sizes]: ReturnType<StyleMaker<Sizes[Key]>[Make]>;
  };

export default {
  ...map('makeRounded'),
  top: map('makeTopRounded'),
  bottom: map('makeBottomRounded'),
  left: map('makeLeftRounded'),
  right: map('makeRightRounded'),
  sizes,
};
