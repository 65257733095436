import React, { useContext, useMemo, useState } from 'react';
import cs from 'classnames';

import { BannerContext } from './BannerContext';
import { BannerContextType } from './types';
import BannerPagination from './BannerPagination';
import { getBackgroundClass } from './utils';
import BannerItem from './BannerItem';

const Banner: React.FC = () => {
  const { notifications } = useContext(BannerContext) as BannerContextType;
  const hasNotifications = notifications && notifications.length > 0;

  const [currentTab, setCurrentTab] = useState(0);

  const currentNotification = useMemo(() => {
    if (currentTab >= notifications.length && notifications.length !== 0) {
      setCurrentTab(notifications.length - 1);
      return notifications[notifications.length - 1];
    }
    return notifications[currentTab];
  }, [notifications, currentTab]);

  const onPreviousTab = () => {
    setCurrentTab((state) => state - 1);
  };

  const onNextTab = () => {
    setCurrentTab((state) => state + 1);
  };

  return hasNotifications ? (
    <div
      className={cs(
        'flex flex-col py-4 w-full relative max-w-[100vw]',
        getBackgroundClass(currentNotification.variant),
        currentNotification.onPress ? 'cursor-pointer' : 'cursor-default',
      )}
      style={{
        backgroundColor:
          currentNotification.variant === 'custom'
            ? currentNotification.backgroundColor
            : undefined,
      }}
      onClick={currentNotification.onPress}
      onKeyDown={currentNotification.onPress}
      role="button"
      tabIndex={0}
    >
      <div className="px-6 w-full items-center justify-between xl:px-0 xl:w-chat-width xl:mx-auto flex">
        <p className="text-left flex-1 rtl:text-right font-outfit font-semibold text-sm">
          {currentNotification.title}
        </p>
        <BannerPagination
          count={notifications.length}
          current={currentTab}
          variant={currentNotification.variant}
          backgroundColor={currentNotification.backgroundColor}
          textColor={currentNotification.textColor}
          onPrevious={onPreviousTab}
          onNext={onNextTab}
        />
      </div>
      <BannerItem notification={currentNotification} />
    </div>
  ) : null;
};

export default Banner;
