import React, { useContext } from 'react';
import { Menu } from '@headlessui/react';
import cs from 'classnames';

import { DropdownNodeContext } from './context';
import { DropdownItemsProps } from './types';

function DropdownItems({ children, className }: DropdownItemsProps) {
  const { depth } = useContext(DropdownNodeContext);

  return (
    <Menu.Items
      static
      as="ul"
      className={cs(
        `relative bg-white border-grey py-3 text-sm font-medium`,
        depth === 0
          ? ['relative rounded border w-52 mt-2']
          : [
              `box-border sm:w-52 sm:-mt-[1px] bg-light-grey sm:bg-white sm:relative sm:rounded sm:border`,
            ],
        className,
      )}
    >
      {children}
    </Menu.Items>
  );
}

export default DropdownItems;
