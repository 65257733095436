import React from 'react';

import { Spacing } from '@advisor/design/styles';
import { Avatar, Icon } from '@advisor/design/components';
import leftAvatar from './images/left.png';
import middleAvatar from './images/middle.png';
import rightAvatar from './images/right.png';

const StudentAvatars = () => {
  return (
    <div dir="ltr" className="flex flex-row my-6 max-w-fit">
      <Avatar
        bordered={false}
        className="opacity-20 border-2 border-white order-1"
        size={Spacing.iconSize}
        avatarUrl={leftAvatar}
      />
      <Avatar
        bordered={false}
        className="opacity-20 border-2 border-white order-3"
        size={Spacing.iconSize}
        avatarUrl={rightAvatar}
      />
      <div className="relative order-2 -mx-6">
        <Avatar
          bordered={false}
          size={Spacing.iconSize}
          avatarUrl={middleAvatar}
          className="border-2 border-white"
        />
        <Icon
          name="StarOutline"
          className="text-positive absolute bottom-0.5 -right-1"
          size={Spacing.tiny}
        />
      </div>
    </div>
  );
};

export default StudentAvatars;
