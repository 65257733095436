/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefaultContext,
  MutationOptions,
  OperationVariables,
} from '@apollo/client';

import { actionAtom } from '@advisor/utils/atoms';
import { clientAtom } from './clientAtom';

const atomWithMutation = <
  Data,
  Variables extends OperationVariables,
  Context extends Record<string, any> = DefaultContext,
>(
  mutation: MutationOptions<Data, Variables, Context>['mutation'],
) => {
  return actionAtom(
    async (
      { get },
      options: Omit<MutationOptions<Data, Variables, Context>, 'mutation'>,
    ) => {
      const client = await get(clientAtom);

      return client.mutate({
        ...options,
        mutation,
      });
    },
  );
};

export default atomWithMutation;
