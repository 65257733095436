import { useAtomValue } from 'jotai';

import type { ScreenBreakpoint } from './types';
import screenBreakpointAtoms from './screenBreakpointAtoms';

/**
 * Returns `true` when the screen's width is greater
 * than `breakpoint`, `false` otherwise.
 */
function useScreenBreakpoint(breakpoint: ScreenBreakpoint) {
  return useAtomValue(screenBreakpointAtoms(breakpoint));
}

export default useScreenBreakpoint;
