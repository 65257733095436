import React, { memo, useRef, useCallback, ChangeEvent } from 'react';
import { FilePickerProps } from './types';

const FilePicker = ({ accept, children, onFilePicked }: FilePickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePress = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files) {
        Array.from(files).forEach(onFilePicked);

        // reset input element
        // makes it possible to pick same file twice
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    },
    [onFilePicked],
  );

  const acceptString = Array.isArray(accept) ? accept.join(',') : accept;

  return (
    <>
      <input
        type="file"
        className="hidden"
        multiple
        ref={inputRef}
        accept={acceptString}
        onChange={handleInputChange}
      />
      {children(handlePress)}
    </>
  );
};

export default memo(FilePicker);
