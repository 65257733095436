import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';

import useEditProfile from '@advisor/ui/components/EditProfileForm/useEditProfile';
import { Toaster } from '@advisor/design/components/Toast';
import EditProfileForm from '@advisor/ui/components/EditProfileForm';
import { useGoBackOrGoHome } from '@advisor/utils/navigation/web';
import ChatSidebar from '../components/organisms/ChatSidebar';
import Page from '../components/Page';

const EditProfilePage = () => {
  const { t } = useTranslation('common');
  const onGoBack = useGoBackOrGoHome('/chat');

  const props = useEditProfile();

  const { onSaveProfile } = props;

  useEffect(() => {
    return () => {
      onSaveProfile();
    };
  }, [onSaveProfile]);

  return (
    <Page.Root>
      <ChatSidebar />
      <Page.Content>
        <Page.Header
          backButtonDisabled={!props.isNameValid}
          onGoBack={onGoBack}
          title={t('edit-profile')}
        />
        <div className="grow overflow-y-auto pb-10">
          <EditProfileForm props={props} />
        </div>
        <Toaster />
      </Page.Content>
      <Outlet />
    </Page.Root>
  );
};

export default EditProfilePage;
