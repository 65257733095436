import React, { memo } from 'react';

import { FlagProps } from './types';

function Flag({ country, className }: FlagProps) {
  return (
    <img
      src={`/images/flags/${country}.jpg`}
      alt={`${country} flag`}
      className={className}
      width={21.33}
      height={16}
    />
  );
}

export default memo(Flag);
