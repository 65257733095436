import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';

import { FamilyJoinRequestStatus } from '@advisor/api/generated/graphqlTypes';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';
import { Spacing } from '@advisor/design/styles';
import { DeprecatedButton, Form } from '@advisor/design/components';
import FadeIn from './FadeIn';
import ErrorMessage from './ErrorMessage';
import useSearchForm from './useSearchForm';
import { StudentSearchFormProps } from './types';
import { SearchFormErrorType } from '../types';

const StudentSearchForm: React.FC<StudentSearchFormProps> = (props) => {
  const { t } = useTranslation('onboarding');
  const {
    inputs,
    loading,
    searchError,
    showDetailedSearch,

    onClose,
    onSearchAndJoin,
    onTalkToAssistant,
  } = useSearchForm(props);
  const chatRoomCtx = useActiveChatRoom();
  const chat = useAtomValue(chatRoomCtx ? chatRoomCtx.chatRoomAtom : nullAtom);

  const status =
    chat?.__typename === 'RequestorChatRoom'
      ? chat?.joinRequest?.status
      : undefined;

  const isWithAssistant = status === FamilyJoinRequestStatus.WithAssistant;

  return (
    <form className="md:max-w-[25.5rem]">
      {/* Header */}
      <div className="border-b border-grey p-4">
        <div className="flex flex-row items-start justify-between pb-3">
          <h4 className="font-sora text-xl font-semibold px-4 pt-4 max-w-xs">
            {t('find-customer-conversation-to-join')}
          </h4>
          <DeprecatedButton.Icon
            icon="X"
            variant="darkGrey01"
            size={Spacing.small02}
            onPress={onClose}
          />
        </div>
        <div className="px-4 space-y-4">
          <p className="text-dark-grey-02 text-sm">
            {t(
              'please-enter-following-details-to-help-us-connect-you-to-conversation',
            )}
          </p>
          <ErrorMessage
            message={
              searchError?.type === SearchFormErrorType.MISSING_CHATROOM
                ? t(
                    'oops-customer-needs-to-be-in-an-active-conversation-for-guest-customer',
                  )
                : undefined
            }
          />
          <ErrorMessage
            message={
              searchError?.type === SearchFormErrorType.INSUFFICIENT_INFO
                ? t('more-than-one-customer-found', {
                    name: searchError.studentName,
                  })
                : undefined
            }
          />
        </div>
      </div>
      {/* Form Input */}
      <div className="px-8 py-6 flex flex-col gap-4">
        <Form.Text
          label={t('host-name-optional')}
          placeholder={t('please-enter-host-name')}
          {...inputs.advisorName}
        />
        <Form.Text
          label={t('customer-name')}
          placeholder={t('please-enter-customer-name')}
          {...inputs.studentName}
        />
        <FadeIn show={showDetailedSearch} className="flex flex-col gap-4">
          <Form.PhoneNumber
            label={t('customer-phone-number')}
            placeholder={t('please-phone-number-placeholder')}
            {...inputs.studentPhone}
          />
          <Form.EmailAddress
            label={t('customer-email-address')}
            placeholder={t('please-email-address-placeholder')}
            {...inputs.studentEmail}
          />
        </FadeIn>
      </div>
      <div className="border border-grey px-8 py-6 rounded bg-white flex flex-row gap-5">
        <DeprecatedButton.Outline
          variant="primary"
          className="flex-1"
          label={t('talk-to-an-assistant')}
          disabled={loading || isWithAssistant}
          onPress={onTalkToAssistant}
        />
        <DeprecatedButton.Large
          label={t('search-and-join')}
          variant="primary"
          className="flex-1"
          onPress={onSearchAndJoin}
          disabled={loading || !inputs.studentName.value.length}
        />
      </div>
    </form>
  );
};

export default StudentSearchForm;
