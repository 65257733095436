import cs from 'classnames';
import React from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { useLanguage } from '@advisor/language';
import { SegmentedGroupToggleButtonProps } from './types';

const CONTAINER_WIDTH = 36;
const CONTAINER_HEIGHT = 18;
const CONTAINER_PADDING = 1.38;
const CIRCLE_SIZE = CONTAINER_HEIGHT - CONTAINER_PADDING * 2;
const OFFSET = CONTAINER_WIDTH - CONTAINER_PADDING * 2 - CIRCLE_SIZE;

const SegmentedGroupToggleButton: React.FC<SegmentedGroupToggleButtonProps> = (
  props,
) => {
  const { isSelected, onChange } = props;

  const { isRTL } = useLanguage();
  const direction = isRTL ? -1 : 1;

  const onChangeWrapper = useEvent(() => onChange(!isSelected));

  return (
    <button
      onClick={onChangeWrapper}
      type="button"
      style={{
        width: CONTAINER_WIDTH,
        height: CONTAINER_HEIGHT,
        padding: CONTAINER_PADDING,
      }}
      className={cs(
        'flex items-center rounded-full',
        isSelected ? 'bg-primary' : 'bg-dark-grey-025',
      )}
    >
      <div
        style={{
          width: CIRCLE_SIZE,
          height: CIRCLE_SIZE,
          transform: isSelected
            ? `translateX(${OFFSET * direction}px)`
            : 'translateX(0)',
        }}
        className="bg-white rounded-full transition-all"
      />
    </button>
  );
};

export default SegmentedGroupToggleButton;
