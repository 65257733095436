/* eslint-disable import/prefer-default-export */
import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { SessionMetadata, type CallSession, type TrackState } from '../types';
import { TrackType, type JellyfishPeer } from './web/jellyfishClient';

function parseTrack(peer: JellyfishPeer, trackType: TrackType): TrackState {
  return {
    track: peer.tracks.get(trackType)?.track ?? null,
  };
}

export function membranePeerToGeneric(peer: JellyfishPeer): CallSession | null {
  const result = SessionMetadata.safeParse(peer.metadata);

  if (!result.success) {
    // Invalid session metadata
    Sentry.captureException(
      new ComplexError('Invalid peer metadata (Jellyfish)', result.error),
    );
    return null;
  }

  return {
    sessionId: peer.id,
    isLocal: false,
    metadata: result.data,
    tracks: {
      audio: parseTrack(peer, 'audio'),
      video: parseTrack(peer, 'video'),
      screenAudio: parseTrack(peer, 'screenAudio'),
      screenVideo: parseTrack(peer, 'screenVideo'),
    },
  };
}
