import cs from 'classnames';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import {
  useChatRoomLanguage,
  useSetChatRoomLanguage,
} from '@advisor/chat/chatLanguage';
import { useEvent } from '@advisor/utils/hooks';
import {
  LanguageMetadata,
  detectSystemLanguage,
  primaryLanguagesAtom,
} from '@advisor/language';
import { Form, Icon } from '@advisor/design/components';
import { LanguageCode } from '@advisor/language/languages';
import SegmentedGroup from '@advisor/design/components/SegmentedGroup';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import useLanguageAsset from 'src/hooks/useLanguageAsset';
import SideModal from '../SideModal';
import { Section } from './components';

const ChangeLanguageModal = () => {
  const { t } = useTranslation('common');
  const chatRoomCtx = useActiveChatRoom();
  const chatRoomLanguage = useChatRoomLanguage(chatRoomCtx?.chatRoomId);
  const [setChatRoomLanguage] = useSetChatRoomLanguage(chatRoomCtx?.chatRoomId);
  const [unsupportedLanguageWarning] = useLanguageAsset(
    'unsupported_language_warning',
  );
  const [languageLocalityNotice] = useLanguageAsset('language_locality_notice');
  const [searchText, setSearchText] = useState('');
  const primaryLanguages = useAtomValue(primaryLanguagesAtom);

  const onSearchLanguages = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const filteredLanguages = useMemo(
    () =>
      Object.entries(LanguageMetadata).filter(
        ([, { name, native }]) =>
          !searchText ||
          native.toLowerCase().includes(searchText.toLowerCase()) ||
          name.toLowerCase().includes(searchText.toLowerCase()),
      ) as [LanguageCode, { name: string; native: string }][],
    [searchText],
  );

  const handleChangeToDetectedLanguage = useEvent(async () => {
    setChatRoomLanguage?.((await detectSystemLanguage()) ?? 'en');
  });

  return (
    <>
      <SideModal.Header backButton>
        <SideModal.Title title={t('change-language')} />
      </SideModal.Header>
      <SideModal.Body>
        <Section>
          <SegmentedGroup.Root>
            <SegmentedGroup.Button
              onPress={handleChangeToDetectedLanguage}
              variant="neutral"
              title={t('detect-language')}
            />
          </SegmentedGroup.Root>
        </Section>
        <Section header={t('select-language')}>
          <div className="border border-dark-grey-01 rounded-10 relative">
            <div className="absolute top-0 ltr:left-3 bottom-0 rtl:right-3 flex justify-center items-center">
              <Icon name="Search" className="text-dark-grey-01" />
            </div>

            <input
              className="ltr:text-left rtl:text-right rounded-10 p-4 w-full focus-visible:outline-primary ltr:pl-9 rtl:pr-9"
              placeholder={t('search-language')}
              value={searchText}
              onChange={onSearchLanguages}
            />
          </div>
          <ul
            className={cs(
              'max-h-[27rem] mt-6 overflow-y-auto rounded-10 border border-grey',
            )}
          >
            {filteredLanguages.length === 0 && (
              <p className="py-4 font-outfit text-center text-dark-grey-01">
                {t('no-matches')}
              </p>
            )}
            {filteredLanguages.map(([langCode, { native }]) => (
              <li key={langCode}>
                <button
                  type="button"
                  className="flex justify-between items-center w-full px-4 h-14 text-left border-b border-grey transition-colors duration-75 hover:bg-off-white active:bg-white font-outfit font-semibold"
                  onClick={() => setChatRoomLanguage?.(langCode)}
                >
                  <span>
                    {!primaryLanguages.includes(langCode) ? '* ' : ''}
                    {native}
                  </span>
                  <Form.RadioIcon checked={langCode === chatRoomLanguage} />
                </button>
              </li>
            ))}
          </ul>
          {chatRoomLanguage &&
            unsupportedLanguageWarning &&
            languageLocalityNotice && (
              <>
                <p className="font-outfit text-sm mt-4 lg:mt-6">
                  {unsupportedLanguageWarning[chatRoomLanguage] ??
                    unsupportedLanguageWarning.en}
                </p>
                <p className="font-outfit text-sm mt-4 lg:mt-6">
                  {languageLocalityNotice[chatRoomLanguage] ??
                    languageLocalityNotice.en}
                </p>
              </>
            )}
        </Section>
      </SideModal.Body>
    </>
  );
};

export default ChangeLanguageModal;
