import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Gender,
  useMeQuery,
  MeDocument,
  useUpdateUserMutation,
  UpdateUserMutationOptions,
  UpdateUserMutationVariables,
  FileInfoFragment,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import getTimezone from '@advisor/utils/getTimezone';
import { UploadErrorType } from '@advisor/api/files';
import { SelectOption } from '@advisor/design/components';
import { getUpdateUserOptimisticResponse } from '@advisor/api/me';

export type UseEditProfileType = ReturnType<typeof useEditProfile>;

type FieldName = keyof UpdateUserMutationVariables;

const mutationOptions: UpdateUserMutationOptions = {
  update(cache, results) {
    cache.updateQuery(
      {
        query: MeDocument,
      },
      (data) => {
        if (!data || !data.me) {
          return null;
        }

        return {
          __typename: 'Query' as const,
          me: {
            ...data.me,
            name: results.data?.updateUser.name ?? data.me.name,
            gender: results.data?.updateUser.gender ?? data.me.gender,
            country: results.data?.updateUser.country ?? data.me.country,
            timezone: results.data?.updateUser.timezone ?? data.me.timezone,
            avatarUrl: results.data?.updateUser.avatarUrl ?? data.me.avatarUrl,
          },
        };
      },
    );
  },
  optimisticResponse(variables) {
    return getUpdateUserOptimisticResponse({
      name: variables.name ?? '',
      country: variables.country ?? '',
      timezone: variables.timezone ?? '',
      avatarUrl: variables.avatarUrl ?? '',
      gender: variables.gender ?? Gender.Undefined,
    });
  },
};

function hasMeChanged<T extends FieldName>(
  mutationVariables: Record<string, unknown>,
  me: Partial<UpdateUserMutationVariables>,
): boolean {
  return Object.keys(mutationVariables).some((key) => {
    if (key in me && mutationVariables[key as T] !== me[key as T]) {
      return true;
    }

    return false;
  });
}

export default function useEditProfile() {
  const { t } = useTranslation('common');
  const { data: { me } = {} } = useMeQuery();
  const [updateUser] = useUpdateUserMutation(mutationOptions);

  const [name, setName] = useState(me?.name ?? '');
  const [gender, setGender] = useState(me?.gender ?? Gender.Undefined);
  const [country, setCountry] = useState(me?.country ?? '');
  const [timezone, setTimezone] = useState(me?.timezone ?? getTimezone());
  const [avatarUrl, setAvatarUrl] = useState(me?.avatarUrl ?? '');

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);

  const onSaveProfile = useEvent(
    async (mutationVariables: UpdateUserMutationVariables = {}) => {
      if (!me) {
        return;
      }

      const variables = {
        name,
        country,
        timezone: timezone ?? getTimezone(),
        gender,
        avatarUrl,
        ...mutationVariables,
      };

      if (!variables.name || !hasMeChanged(variables, me)) {
        return;
      }

      try {
        await updateUser({ variables });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
  );

  const onAvatarUploadStart = useEvent(() => {
    setIsUploadingAvatar(true);
  });

  const onAvatarUploadFailure = useEvent((error: UploadErrorType) => {
    setIsUploadingAvatar(false);
    Sentry.captureException(
      new Error(`Failed to upload user's avatar because of ${error}`),
    );
  });

  const onAvatarUploadSuccess = useEvent(async ({ url }: FileInfoFragment) => {
    setIsUploadingAvatar(false);
    setAvatarUrl(url);

    onSaveProfile({ avatarUrl: url });
  });

  const onChangeName = useEvent((newName: string) => {
    if (newName.match(/^\w+[ \w]*/g) && newName.length) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }

    setName(newName);
  });

  const onChangeGender = useEvent((newGender: Gender) => {
    setGender(newGender);
  });

  const onChangeCountry = useEvent((newCountry: string) => {
    setCountry(newCountry);
  });

  const onChangeTimezone = useEvent((newTimezone: string) => {
    setTimezone(newTimezone);
  });

  const genderOptions: SelectOption<Gender>[] = useMemo(
    () => [
      { value: Gender.Male, label: t('He/ him/ his') },
      { value: Gender.Female, label: t('She/ her/ her') },
      { value: Gender.Undefined, label: t('They/ them/ their') },
    ],
    [t],
  );

  return {
    me,
    isNameValid,
    genderOptions,
    isUploadingAvatar,

    name,
    gender,
    country,
    timezone,

    onChangeName,
    onAvatarUploadStart,
    onAvatarUploadFailure,
    onAvatarUploadSuccess,
    onChangeGender,
    onChangeCountry,
    onChangeTimezone,
    onSaveProfile,
  };
}
