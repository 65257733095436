import React, { useMemo } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout, Modal } from '@advisor/design/components';
import CallCodePicker from 'src/components/atoms/CallCodePicker';
import { FormTabProps } from './types';
import { ErrorMessage, TextInput } from './components';

const FormTab: React.FC<FormTabProps> = ({
  error,
  disabled,
  profile,
  onCreate,
  onSetName,
  onSetEmail,
  onSetPhoneNumber,
  onSetCallCode,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'onboarding']);

  const errorMessages = useMemo(() => {
    if (!error.messages.length) {
      return null;
    }

    return (
      <>
        {error.messages.map((message, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ErrorMessage key={`message-${idx}`} message={t(message)} />
        ))}
      </>
    );
  }, [t, error]);

  return (
    <>
      <Modal.PopUpHeader
        large
        title={t('onboarding:create-customer-conversation-lower')}
        message={t('onboarding:for-users-who-are-not-on-platform-yet')}
        onClose={onClose}
      />
      <div className="px-12 py-8">
        {/* Form */}
        <div className="flex flex-col gap-3">
          <TextInput
            value={profile.name}
            label={t('common:name')}
            placeholder={t('onboarding:please-name-placeholder')}
            onChange={onSetName}
            hasErrors={error.fields.includes('name')}
          />

          <TextInput
            value={profile.email}
            label={t('onboarding:customer-email-address')}
            placeholder={t('onboarding:please-email-address-placeholder')}
            onChange={onSetEmail}
            type="email"
            hasErrors={error.fields.includes('email')}
          />

          <div
            className={cs(
              'relative border rounded flex items-end',
              error.fields.includes('phone')
                ? 'border-negative'
                : 'border-grey',
            )}
          >
            <span
              className={cs(
                'h-4 absolute top-4 ltr:left-4 rtl:right-4 font-outfit text-xs pointer-events-none',
                error.fields.includes('phone')
                  ? 'text-negative'
                  : 'text-dark-grey-02',
              )}
            >
              {t('onboarding:customer-phone-number')}
            </span>
            <div className="pb-3 pt-8">
              <CallCodePicker
                labelClassName="text-sm"
                onCodeChange={onSetCallCode}
                callCode={profile.callCode}
              />
            </div>
            <input
              className="pt-8 pb-[0.875rem] rtl:text-right ltr:pr-4 rtl:pl-4 w-full focus:outline-none rounded text-sm text-dark-grey-03 font-semibold placeholder:font-normal"
              type="tel"
              value={profile.phoneNumber}
              placeholder={t('onboarding:please-phone-number-placeholder')}
              onChange={(event) => onSetPhoneNumber(event.target.value)}
              data-cy="createConversationNumberInput"
            />
          </div>

          {errorMessages}
        </div>

        <Layout.Spacer.Vertical size="small02" />

        {/* Submit button */}
        <div className="flex justify-center">
          <DeprecatedButton.Large
            variant="primary"
            width={160}
            disabled={disabled}
            onPress={onCreate}
          >
            {t('onboarding:create-conversation')}
          </DeprecatedButton.Large>
        </div>
      </div>
    </>
  );
};

export default FormTab;
