import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import type { VideoChatSystemMessageData } from '@advisor/api/generated/graphql';
import { SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const MessageWhenVideoRoomEnded: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation(['common', 'system-message']);

  const { videoChat } = message.data as VideoChatSystemMessageData;

  if (!videoChat || !videoChat.endedAt) {
    return null;
  }

  return (
    <SimpleMessage>
      {t('system-message:video-room-ended-on', {
        datetime: DateFormatter.dateTime(videoChat.endedAt, t),
      })}
    </SimpleMessage>
  );
};

export default memo(MessageWhenVideoRoomEnded);
