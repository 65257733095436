import { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { Layout, Loader } from '@advisor/design/components';
import {
  Form,
  Title,
  FormContainer,
  ConfirmButton,
} from '@advisor/design/components/LoginComponents';
import { useInvitation } from '@advisor/onboarding';
import { useIsAuthResolved } from '@advisor/api/auth/isAuthResolved';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import LoginLayout from 'src/components/layouts/LoginLayout';
import useQueryParams from 'src/hooks/useQueryParams';
import { JoinAdvisorParams } from './params';

const JoinAdvisorPage = () => {
  const isAuthorized = useIsAuthorized();
  const isAuthResolved = useIsAuthResolved();
  const { advisorId } = useParams<JoinAdvisorParams>();
  const { setInvitation } = useInvitation();

  const queryParams = useQueryParams<{ 'skip-intro': boolean | undefined }>();

  const { t } = useTranslation(['common', 'onboarding']);
  const navigate = useNavigate();

  const onSubmit = useEvent(async () => {
    if (!advisorId) {
      Sentry.captureException('Unspecified advisor to join');
      return;
    }

    setInvitation({ inviteId: advisorId, inviteType: 'advisor' });
    navigate('/login');
  });

  useEffect(() => {
    const isLoggedIn = isAuthResolved && !!isAuthorized;

    if (!advisorId) {
      return;
    }

    setInvitation({ inviteId: advisorId, inviteType: 'advisor' });

    if (isLoggedIn) {
      navigate('/chat');
    } else if (queryParams['skip-intro']) {
      navigate('/login');
    }
  }, [
    navigate,
    advisorId,
    isAuthResolved,
    isAuthorized,
    queryParams,
    setInvitation,
  ]);

  return (
    <LoginLayout>
      <FormContainer className="flex flex-col">
        {!isAuthResolved && <Loader className="mx-auto" />}

        {/* Show form only when user is not logged in */}
        <Transition show={isAuthResolved} {...transitionProps}>
          <div className="flex flex-row gap-5 mb-6">
            <h2 className="text-lg md:text-base font-semibold font-sora uppercase">
              {t('onboarding:login-signup')}
            </h2>
          </div>
          <Form onSubmit={onSubmit}>
            <div>
              <Title>{t('hello-exclamation')}</Title>
              <p>
                <Trans
                  t={t}
                  i18nKey="onboarding:you-have-been-invited-to-platform-host-invite"
                  components={{ bold: <b /> }}
                />
              </p>
              <Layout.Spacer.Vertical size="tiny" />
              <p>{t('do-you-want-to-proceed')}</p>
              <ConfirmButton>{t('onboarding:proceed-to-login')}</ConfirmButton>
            </div>
          </Form>
        </Transition>
      </FormContainer>
    </LoginLayout>
  );
};

const transitionProps = {
  enter: 'transition-opacity ease-in duration-100 delay-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity ease-in duration-100 absolute',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

export default JoinAdvisorPage;
