import { useCallback } from 'react';

import {
  CreateServiceProviderChatRoomMutation,
  JoinToConversationViaReferralMutation,
  useCreateServiceProviderChatRoomMutation,
  useJoinToConversationViaReferralMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useInvitation } from '@advisor/onboarding';
import { showToast } from '@advisor/design/components/Toast';
import { CommonChatRoomMutationOptions } from '../../ChatRoomList/utils';

const useChatRoomOnboardingMessage = () => {
  const [createServiceProviderChatRoom, { loading: loadingCreate }] =
    useCreateServiceProviderChatRoomMutation();

  const [joinToConversationViaReferral, { loading: loadingJoin }] =
    useJoinToConversationViaReferralMutation();

  const invitation = useInvitation();
  const hasInvitation =
    !!invitation.inviteId && invitation.inviteType === 'conversation';

  const createChatRoom = useCallback(async () => {
    try {
      const { data } = await createServiceProviderChatRoom({
        ...createChatRoomMutationOptions,
      });

      if (!data || !data.createServiceProviderChatRoom) {
        return false;
      }

      return !!data.createServiceProviderChatRoom.node.id;
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'Warning',
      });

      return false;
    }
  }, [createServiceProviderChatRoom]);

  const joinToConversation = useCallback(async () => {
    try {
      if (!invitation.inviteId) {
        return;
      }

      await joinToConversationViaReferral({
        ...joinViaReferralMutationOptions,
        variables: { chatRoomId: invitation.inviteId },
      });
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'Warning',
      });
    }
  }, [joinToConversationViaReferral, invitation.inviteId]);

  const onPress = useCallback(async () => {
    const result = await createChatRoom();

    if (result && hasInvitation) {
      await joinToConversation();
      await invitation.clearInvitation();
    }

    return result;
  }, [hasInvitation, joinToConversation, createChatRoom, invitation]);

  return {
    hasInvitation,
    loading: loadingCreate || loadingJoin,
    onPress,
  };
};

export default useChatRoomOnboardingMessage;

const createChatRoomMutationOptions =
  CommonChatRoomMutationOptions<CreateServiceProviderChatRoomMutation>(
    (data) => data.createServiceProviderChatRoom,
  );

const joinViaReferralMutationOptions =
  CommonChatRoomMutationOptions<JoinToConversationViaReferralMutation>(
    (data) => data.joinToConversationViaReferral,
  );
