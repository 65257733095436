import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';

import { useMe } from '@advisor/api/me';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';
import { Role } from '@advisor/api/user';

const useChatRoomMembers = () => {
  const [query, setQuery] = useState('');

  const me = useMe();
  const chatRoomCtx = useActiveChatRoom();

  const memberOfMe = useAtomValue(chatRoomCtx?.memberOfMeAtom ?? nullAtom);
  const membersOtherThanMe = useAtomValue(
    chatRoomCtx?.membersOtherThanMeAtom ?? nullAtom,
  );

  const chatRoomMembers = useMemo(() => {
    if (!memberOfMe || !membersOtherThanMe) {
      return [];
    }

    if (Role.isVerifiedAdvisor(me) || Role.isServiceProvider(me)) {
      return [memberOfMe, ...membersOtherThanMe].map(
        (memberInfo) => memberInfo.member,
      );
    }

    return membersOtherThanMe.map((memberInfo) => memberInfo.member);
  }, [me, memberOfMe, membersOtherThanMe]);

  const members = useMemo(() => {
    const processedQuery = query.trim().toLowerCase();

    return chatRoomMembers.filter((member) =>
      member.name.toLowerCase().includes(processedQuery),
    );
  }, [query, chatRoomMembers]);

  const onSetQuery = useCallback((newValue: string) => {
    setQuery(newValue);
  }, []);

  return {
    members,
    query,
    onSetQuery,
  };
};

export default useChatRoomMembers;
