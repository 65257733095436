import { useMolecule } from 'bunshi/react';

import { useContentTranslation } from '@advisor/chat/contentTranslation';
import MilestoneCommentTranslationMolecule from './milestoneCommentTranslationMolecule';

function useCommentTranslation() {
  const milestoneCommentTranslationAtoms = useMolecule(
    MilestoneCommentTranslationMolecule,
  );

  return useContentTranslation(milestoneCommentTranslationAtoms);
}

export default useCommentTranslation;
