import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCallState,
  useVideoChat,
  VideoCallStatus,
} from '@advisor/videoCall';
import { Layout } from '@advisor/design/components';
import Timer from '@advisor/ui/components/Timer';
import { useMe } from '@advisor/api/me';
import ParticipantsAvatars from './ParticipantsAvatars';
import { VideoCallHeaderProps } from './types';
import HeaderLayout from './HeaderLayout';
import Participant from './Participant';
import JoinButton from './JoinButton';
import HeaderText from './HeaderText';

const VideoCallHeader = ({ chatId, onOpenVideoCall }: VideoCallHeaderProps) => {
  const callState = useCallState();
  const { t } = useTranslation('common');
  const videoChat = useVideoChat(chatId);
  const me = useMe();

  const lastParticipant = useMemo(() => {
    if (!videoChat?.participants || !me?.id) {
      return null;
    }

    return videoChat.participants.find(
      (participant) =>
        participant.state === 'PRESENT' && participant.participant.id !== me.id,
    )?.participant;
  }, [me?.id, videoChat?.participants]);

  if (
    callState &&
    callState.chatRoomId === chatId &&
    callState.status === VideoCallStatus.Joined
  ) {
    return (
      <HeaderLayout variant="secondary" onPress={onOpenVideoCall}>
        <ParticipantsAvatars />
        <Layout.Spacer.Horizontal size="micro02" />
        <HeaderText>{t('video-room-is-still-active')}</HeaderText>
        <Layout.Spacer.Horizontal />
        <HeaderText monospace>
          {!!videoChat?.createdAt && <Timer start={videoChat.createdAt} />}
        </HeaderText>
      </HeaderLayout>
    );
  }

  if (videoChat?.isActive && lastParticipant) {
    return (
      <HeaderLayout variant="primary" onPress={onOpenVideoCall}>
        <Participant {...lastParticipant} />
        <Layout.Spacer.Horizontal />
        <JoinButton />
      </HeaderLayout>
    );
  }

  return null;
};

export default memo(VideoCallHeader);
