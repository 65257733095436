import { atom } from 'jotai';

import { keyedAtomFamily } from '@advisor/utils/atoms';
import { ChatRoomStatusInfoFragment } from '@advisor/api/generated/graphql';
import { createMarkingHandler } from './utils';

type MarkingHandler = ReturnType<typeof createMarkingHandler> | null;

export const statusFilterAtom = atom<ChatRoomStatusInfoFragment | null>(null);

export const searchByNameAtom = atom<string>('');

/**
 * Allows the swipeable chat room row to remain open
 * when user is dealing with the choose chat room status modal
 * */
export const chatRoomStatusMarkingAtom = atom<MarkingHandler>(null);

export enum ArchivalStatus {
  Idle,
  Pending,
}

export const archivalStatusAtoms = keyedAtomFamily((_chatRoomId: string) =>
  atom(ArchivalStatus.Idle),
);

export const changeArchivalStatusAtom = atom(
  null,
  (_, set, chatRoomId: string, status: ArchivalStatus) => {
    set(archivalStatusAtoms(chatRoomId), status);
  },
);
