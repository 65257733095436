import React, { ReactNode, memo } from 'react';
import cs from 'classnames';

export interface LabelProps {
  className?: string;
  children?: string | ReactNode;
}

export const Label = memo(function Label({ className, children }: LabelProps) {
  return (
    <span className={cs('ltr:text-left rtl:text-right', className)}>
      {children}
    </span>
  );
});
