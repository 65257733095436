import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@advisor/design/components';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import MilestoneCategoryForm from '../MilestoneCategoryForm';
import useMilestoneCategoryEditModal from './useMilestoneCategoryEditModal';

const MilestoneCategoryEditModal = () => {
  const { t } = useTranslation('task-organiser');
  const { visible, editedCategory, defaultValues, submit, onClose } =
    useMilestoneCategoryEditModal();

  return (
    <Modal.Base maxWidth={406} visible={visible} onClose={onClose}>
      <Modal.Header
        title={t('edit-category-name')}
        subtitle={t('for-customer-profile')}
        onClose={onClose}
      />
      <MilestoneCategoryForm
        // Remounting on category change to reset derived state
        key={editedCategory?.id}
        defaultValues={defaultValues}
        onSubmit={submit}
      />
    </Modal.Base>
  );
};

export default memo(suspenseHOC(MilestoneCategoryEditModal));
