import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '@advisor/design/components';
import { Confirm, NewStatus, StatusList } from './tabs';
import { StatusModalProps, Tab } from './types';

const StatusModal: React.FC<StatusModalProps> = ({ visible, onClose }) => {
  const [tab, setTab] = useState<Tab>(Tab.StatusList);

  // Reset the current tab to the list view when the modal is opened
  useEffect(() => {
    if (visible) {
      setTab(Tab.StatusList);
    }
  }, [visible]);

  const renderTab = useCallback(
    (activeTab: Tab) => {
      if (activeTab === Tab.StatusList) {
        return (
          <StatusList
            onAddNew={() => setTab(Tab.NewStatus)}
            onDelete={() => setTab(Tab.Confirm)}
          />
        );
      }

      if (activeTab === Tab.NewStatus) {
        return (
          <NewStatus
            onClose={onClose}
            onGoBack={() => setTab(Tab.StatusList)}
          />
        );
      }

      return (
        <Confirm onClose={onClose} onGoBack={() => setTab(Tab.StatusList)} />
      );
    },
    [onClose],
  );

  return (
    <Modal.Base
      visible={visible}
      onClose={onClose}
      maxWidth={tab === Tab.Confirm ? 326 : 406}
    >
      {renderTab(tab)}
    </Modal.Base>
  );
};

export default StatusModal;
