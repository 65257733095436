import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { ColorVariant } from '@advisor/design/components/DeprecatedButton/types';

export enum ActionType {
  OpenMicrobotInfo = 'OpenMicrobotInfo',
  OpenMemoryBankInfo = 'OpenMemoryBankInfo',
}

interface ContentType {
  title?: string;
  description?: string;
  buttons: Array<{
    label: string;
    variant: ColorVariant;
    action: ActionType;
  }>;
}

export default function useContent(
  personality: MicrobotPersonality,
): ContentType {
  const { t } = useTranslation('microbot');

  return useMemo(() => {
    if (personality === MicrobotPersonality.Astro) {
      return {
        title: t('meet-alpha-bot-your-primary-advisor-ai'),
        description: t('use-this-space-to-find-answers-to-your-queries'),
        buttons: [
          {
            label: t('who-is-alpha-bot'),
            variant: 'primaryLight',
            action: ActionType.OpenMicrobotInfo,
          },
          {
            label: t('what-is-the-memory-bank'),
            variant: 'primaryLight',
            action: ActionType.OpenMemoryBankInfo,
          },
        ],
      };
    }

    // Unreachable code
    return {
      title: '',
      description: '',
      buttons: [],
    };
  }, [t, personality]);
}
