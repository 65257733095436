import { atom } from 'jotai';

import {
  MicrobotArtifactKey,
  MicrobotDocument,
  MicrobotPersonality,
  MicrobotQuery,
  MicrobotQueryVariables,
  UpdateMicrobotArtifactDocument,
  UpdateMicrobotArtifactMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { clientAtom } from '@advisor/api/apollo';
import { showToast } from '@advisor/design/components/Toast';

type EditArtifactState = {
  personality: MicrobotPersonality;
  artifactKey: MicrobotArtifactKey;
  content: string;
  placeholder?: boolean;
} | null;

type EditArtifactAction =
  | {
      type: 'open';
      personality: MicrobotPersonality;
      artifactKey: MicrobotArtifactKey;
      content: string;
      placeholder?: boolean;
    }
  | {
      type: 'close';
    }
  | { type: 'update'; value: string; placeholder?: boolean }
  | { type: 'submit' };

export default (() => {
  const internalAtom = atom<EditArtifactState>(null);

  return atom(
    (get) => get(internalAtom),
    async (get, set, action: EditArtifactAction) => {
      if (action.type === 'open') {
        set(internalAtom, {
          content: action.content,
          personality: action.personality,
          artifactKey: action.artifactKey,
          placeholder: action.placeholder,
        });
      } else if (action.type === 'close') {
        set(internalAtom, null);
      } else if (action.type === 'update') {
        set(internalAtom, {
          content: action.value,
          personality:
            get(internalAtom)?.personality ?? MicrobotPersonality.Pam,
          artifactKey:
            get(internalAtom)?.artifactKey ??
            MicrobotArtifactKey.PartnerSchoolList,
          placeholder: action.placeholder,
        });
      } else if (action.type === 'submit') {
        const client = await get(clientAtom);

        try {
          await client.mutate({
            ...mutationOptions(
              get(internalAtom)?.personality ?? MicrobotPersonality.Pam,
            ),
            mutation: UpdateMicrobotArtifactDocument,
            variables: {
              personality:
                get(internalAtom)?.personality ?? MicrobotPersonality.Pam,
              artifactKey:
                get(internalAtom)?.artifactKey ??
                MicrobotArtifactKey.PartnerSchoolList,
              content: get(internalAtom)?.content ?? '',
            },
          });

          showToast({
            iconName: 'CircleCheck',
            variant: 'blue',
            // As for now Pam's partner school list is the only artifact
            namespace: 'microbot',
            messageI18Key: 'partner-school-list-successfully-edited',
          });
        } catch (error) {
          Sentry.captureException(error);
          showToast({
            iconName: 'X',
            variant: 'rose',
            messageI18Key: 'oops-something-went-wrong',
          });
        }
      }
    },
  );
})();

const mutationOptions = (
  personality: MicrobotPersonality,
): UpdateMicrobotArtifactMutationOptions => ({
  update(cache, result) {
    const microbot = result.data?.updateMicrobotArtifact;

    if (!microbot) {
      return;
    }

    cache.updateQuery<MicrobotQuery, MicrobotQueryVariables>(
      {
        query: MicrobotDocument,
        variables: {
          personality,
        },
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          microbot: {
            ...prev.microbot,
            ...microbot,
          },
        };
      },
    );
  },
});
