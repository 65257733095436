import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  FamilyJoinRequestStatus,
  useConnectWithAssistantAdvisorMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { nullAtom } from '@advisor/utils/atoms';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { showToast } from '@advisor/design/components/Toast';
import { searchFormStateAtom } from './atoms';

const useTalkToAssistant = () => {
  const setState = useSetAtom(searchFormStateAtom);
  const chatRoomCtx = useActiveChatRoom();
  const chat = useAtomValue(chatRoomCtx ? chatRoomCtx.chatRoomAtom : nullAtom);

  const [connectWithAdvisor, { loading }] =
    useConnectWithAssistantAdvisorMutation();

  const onTalkToAssistant = useCallback(async () => {
    if (chat?.__typename !== 'RequestorChatRoom') {
      Sentry.captureException(
        `Search form expected family member's chat room but instead got "${JSON.stringify(
          chat,
        )}"`,
      );
      return;
    }

    const { joinRequest } = chat;

    if (!joinRequest) {
      return;
    }

    if (joinRequest.status === FamilyJoinRequestStatus.WithAssistant) {
      setState(undefined);
      return;
    }

    try {
      await connectWithAdvisor();
      setState(undefined);
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'Warning',
      });
    }
  }, [chat, connectWithAdvisor, setState]);

  return {
    loading,
    onTalkToAssistant,
  };
};

export default useTalkToAssistant;
