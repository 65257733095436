import { actionAtom, derive } from '@advisor/utils/atoms';
import { ChatRoomDocument } from '../generated/graphql';
import { atomOfQuery } from '../apollo';
import createMemberAtoms from './createMemberAtoms';

function createChatRoomContext(chatRoomId: string) {
  const chatRoomQueryAtom = atomOfQuery(ChatRoomDocument, {
    chatRoomId,
  });

  const chatRoomAtom = derive(
    [chatRoomQueryAtom],
    ({ data }) => data?.chatRoom,
  );

  const refetchChatRoomAtom = actionAtom(({ set }) => {
    set(chatRoomQueryAtom, { type: 'refetch' });
  });

  return {
    chatRoomId,
    chatRoomAtom,
    refetchChatRoomAtom,
    ...createMemberAtoms(chatRoomAtom),
  };
}

export type ChatRoomContext = ReturnType<typeof createChatRoomContext>;

const chatRoomContextMap = new Map<string, ChatRoomContext>();

const chatRoomAtoms = (chatRoomId: string) => {
  let chatCtx = chatRoomContextMap.get(chatRoomId);

  if (chatCtx === undefined) {
    chatCtx = createChatRoomContext(chatRoomId);
    chatRoomContextMap.set(chatRoomId, chatCtx);
  }

  return chatCtx;
};

export function useChatRoom(chatRoomId: string): ChatRoomContext;
export function useChatRoom(chatRoomId: string | null): ChatRoomContext | null;
export function useChatRoom(chatRoomId: string | null) {
  return chatRoomId !== null && chatRoomId !== ''
    ? chatRoomAtoms(chatRoomId)
    : null;
}

export default chatRoomAtoms;
