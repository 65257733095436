import { ApolloClient } from '@apollo/client';
import { unwrap } from 'jotai/utils';
import { sortBy } from 'lodash-es';
import { produce } from 'immer';
import dayjs from 'dayjs';

import { useReadAtom } from '@advisor/utils/hooks';
import {
  RoleRequestQuery,
  JoinRequestsQuery,
  RoleRequestsQuery,
  MeFullInfoFragment,
  RoleRequestDocument,
  OnboardingEventType,
  JoinRequestsDocument,
  RoleRequestsDocument,
  RoleRequestInfoFragment,
  OnboardingEventInfoFragment,
  FamilyJoinRequestInfoFragment,
  useOnboardingEventSubscription,
  FamilyJoinRequestInfoFragmentDoc,
} from '../generated/graphql';
import { Role } from '../user';
import { meAtom, useMyId } from '../me';

const joinRequestEventTypes = [
  OnboardingEventType.JoinRequestCreated,
  OnboardingEventType.JoinRequestReviewed,
];

const addRoleRequest = (
  query: RoleRequestsQuery,
  roleRequest: RoleRequestInfoFragment,
  cursor: string,
) => {
  return produce(query, (draft) => {
    const { roleRequests } = draft;

    roleRequests.count = (roleRequests.count ?? 0) + 1;
    roleRequests.edges.push({
      __typename: 'RoleRequestEdge',
      node: roleRequest,
      cursor,
    });

    roleRequests.edges = sortBy(
      roleRequests.edges,
      ({ node }) => -dayjs(node.createdAt).valueOf(),
    );
  });
};

const handleRoleRequestEvent = (
  client: ApolloClient<object>,
  event: OnboardingEventInfoFragment,
  me: MeFullInfoFragment | undefined | null,
) => {
  const { eventType, roleRequestEdge } = event;

  const roleRequest = roleRequestEdge?.node;

  if (!eventType || !roleRequest) {
    return;
  }

  if (
    eventType === OnboardingEventType.RoleRequestCreated &&
    Role.isApprovingAdvisor(me)
  ) {
    client.cache.updateQuery<RoleRequestsQuery>(
      { query: RoleRequestsDocument },
      (queryData) => {
        return queryData
          ? addRoleRequest(queryData, roleRequest, roleRequestEdge.cursor)
          : queryData;
      },
    );
  }

  if (
    (eventType === OnboardingEventType.RoleRequestReviewed ||
      eventType === OnboardingEventType.RoleRequestUpdated) &&
    Role.isAspiringAdvisor(me)
  ) {
    // RoleRequestInfoFragment is updated automatically by apollo type policy
    // We updateQuery to handle edge case when RoleRequestQuery doesn't exist in cache
    client.cache.updateQuery<RoleRequestQuery>(
      {
        query: RoleRequestDocument,
      },
      () => ({
        __typename: 'Query',
        roleRequest,
      }),
    );
  }
};

const handleFamilyJoinRequestEvent = (
  client: ApolloClient<object>,
  event: OnboardingEventInfoFragment,
  me: MeFullInfoFragment | null | undefined,
) => {
  const { eventType, joinRequest } = event;

  if (Role.isFamilyMember(me) && joinRequest?.requestor.id === me.id) {
    client.cache.updateFragment<FamilyJoinRequestInfoFragment>(
      {
        fragmentName: 'FamilyJoinRequestInfo',
        id: client.cache.identify(joinRequest),
        fragment: FamilyJoinRequestInfoFragmentDoc,
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          joinRequest,
        };
      },
    );
  }

  if (eventType === OnboardingEventType.JoinRequestCreated) {
    client.cache.updateQuery<JoinRequestsQuery>(
      {
        query: JoinRequestsDocument,
        variables: { chatRoomId: joinRequest?.requestedChatRoomId },
      },
      (data) => {
        if (!joinRequest) {
          return data;
        }

        return {
          __typename: 'Query',
          joinRequests: [...(data?.joinRequests ?? []), joinRequest],
        };
      },
    );
  }

  if (eventType === OnboardingEventType.JoinRequestReviewed) {
    client.cache.updateQuery<JoinRequestsQuery>(
      {
        query: JoinRequestsDocument,
        variables: { chatRoomId: joinRequest?.requestedChatRoomId },
      },
      (data) => {
        if (!joinRequest) {
          return data;
        }

        return {
          __typename: 'Query',
          joinRequests: (data?.joinRequests ?? []).filter(
            (request) => request.id !== joinRequest.id,
          ),
        };
      },
    );
  }
};

const useOnboardingEventsSubscription = () => {
  const myId = useMyId();
  const readMe = useReadAtom(unwrap(meAtom));

  useOnboardingEventSubscription({
    skip: !myId,
    onData: ({ client, data: { data } }) => {
      const me = readMe();

      if (!data || !data.onboardingEvent) {
        return;
      }

      if (joinRequestEventTypes.includes(data.onboardingEvent.eventType)) {
        handleFamilyJoinRequestEvent(client, data.onboardingEvent, me);
      } else {
        handleRoleRequestEvent(client, data.onboardingEvent, me);
      }
    },
  });
};

export default useOnboardingEventsSubscription;
