import { ApolloClient } from '@apollo/client';

import { PrivateFileScope } from '../../generated/graphqlTypes';
import { FileData, FileUploadOptions, FileVisibility } from '../types';
import sendS3HttpRequest from './sendS3HttpRequest';
import confirmS3Upload from './confirmS3Upload';
import requestPublicFileUpload from './requestPublicFileUpload';
import requestPrivateFileUpload from './requestPrivateFileUpload';

function uploadFile<T extends FileVisibility>(
  client: ApolloClient<unknown>,
  visibility: T,
  file: FileData,
  options: FileUploadOptions[T],
) {
  const controller = new AbortController();

  const cancel = () => {
    controller.abort();
  };

  const promise = (async () => {
    const s3Options =
      visibility === 'Public'
        ? await requestPublicFileUpload(
            client,
            file,
            options,
            controller.signal,
          )
        : await requestPrivateFileUpload(
            client,
            visibility,
            file,
            options as FileUploadOptions[PrivateFileScope],
            controller.signal,
          );

    if (!s3Options.ok) {
      return s3Options;
    }

    const uploadResult = await sendS3HttpRequest(
      file,
      s3Options.data,
      controller.signal,
    );

    if (!uploadResult.ok) {
      return uploadResult;
    }

    return confirmS3Upload(
      client,
      {
        fileId: s3Options.data.fileId,
        fileUrl: uploadResult.data.fileUrl,
        displayName: file.name,
      },
      controller.signal,
    );
  })();

  return { file, promise, cancel };
}

export default uploadFile;
