import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '@advisor/design/components';
import withSeparators from '@advisor/utils/withSeparators';
import { About } from '@advisor/microbots/hooks/useMicrobot';
import { Box, BoxTitle } from './components';
import Subsection from './Subsection';

interface SectionProps {
  section: About;
}

const Section: React.FC<SectionProps> = (props) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { section } = props;

  return (
    <Box>
      <BoxTitle className="hidden xl:block">{t(section.title)}</BoxTitle>
      <Layout.Spacer.Vertical size="small01" />
      {withSeparators(
        section.subsections,
        (i) => (
          <Layout.Spacer.Vertical size="small01" key={i} />
        ),
        (subsection) => (
          <Subsection key={subsection.key} details={subsection} />
        ),
      )}
    </Box>
  );
};

export default Section;
