import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import { UserName, SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const AdvisorMessageInExistingConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const { student, advisor } = message.data as DefaultSystemMessageData;

  if (!student || !advisor) {
    return null;
  }

  return (
    <Trans t={t} i18nKey="customer-access-referral-code" parent={SimpleMessage}>
      <UserName user={student} /> has accessed the referral code for
      <UserName user={advisor} />.
    </Trans>
  );
};

export default memo(AdvisorMessageInExistingConversation);
