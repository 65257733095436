import { useTranslation } from 'react-i18next';

import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { UserInfoFragment } from '@advisor/api/generated/graphql';
import { useMicrobotDetailsOf } from '@advisor/microbots/hooks';
import useInterpolatedUsername from '../../hooks/useInterpolatedUsername';

function useUserName(user: UserInfoFragment): string;
function useUserName(user: UserInfoFragment | null | undefined): string | null;
function useUserName(user: UserInfoFragment | null | undefined) {
  const { t } = useTranslation(['common', 'microbot']);
  const microbot = useMicrobotDetailsOf(user);
  const { chatRoomId } = useActiveChatRoom('required');

  const name = useInterpolatedUsername(user, chatRoomId);

  if (!user) {
    return null;
  }

  if (microbot) {
    return `${name} ${t(microbot.communityTitle)}`;
  }

  return name;
}

export default useUserName;
