import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Layout } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import ButtonRow from './ButtonRow';
import { OtherProps } from './types';

const Other: React.FC<OtherProps> = ({ microbot }) => {
  const { t } = useTranslation('microbot');

  return (
    <div className="p-6 bg-light-grey border border-grey rounded-10">
      <div className="flex flex-row items-center justify-between">
        <div className="flex-1">
          <h4 className="font-sora leading-5 font-bold text-dark-grey-03">
            {t('primary-context-for-name', { name: microbot.name })}
          </h4>

          <Layout.Spacer.Vertical size="quantum" />

          <p className="font-outfit text-sm flex-1 leading-[1.125rem] text-dark-grey-02 font-medium">
            {t('configure-bot-other-message', { name: microbot.name })}
          </p>

          <Layout.Spacer.Vertical size="quantum" />

          <p className="text-xs font-medium font-outfit text-dark-grey-025">
            {t('optional-parentheses')}
          </p>
        </div>

        <Layout.Spacer.Horizontal size="micro02" />

        <Icon name="StarsSup" size={Spacing.small02} color={microbot.color} />
      </div>

      <Layout.Spacer.Vertical size="micro02" />

      <ButtonRow personality={microbot.personality} />
    </div>
  );
};

export default Other;
