import React, { memo } from 'react';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { MessageChunk, MessageContentProps } from './types';
import parseRawMessage from './parseRawMessage';
import MessageText from './MessageText';
import MessageLink from './MessageLink';

function MessageContentChunk({ chunk }: { chunk: MessageChunk }) {
  if (chunk.type === 'text') {
    return <MessageText>{chunk.segments}</MessageText>;
  }

  if (chunk.type === 'link') {
    return <MessageLink>{chunk.href}</MessageLink>;
  }

  warnExhaustive(chunk, 'MessageContent/chunk');
}

function MessageContent({ children, ...parseOptions }: MessageContentProps) {
  return parseRawMessage(children, parseOptions).map((chunk) => (
    <MessageContentChunk key={chunk.key} chunk={chunk} />
  ));
}

export default memo(MessageContent);
