import { useSyncExternalStore } from 'react';

function isOnline() {
  return navigator.onLine;
}

function subscribe(callback: () => void) {
  window.addEventListener('online', callback);
  window.addEventListener('offline', callback);

  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
}

function useOnline() {
  return useSyncExternalStore(subscribe, isOnline);
}

export default useOnline;
