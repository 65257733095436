import MenuRoot from './MenuRoot';
import MenuItem from './MenuItem';
import ManagedMenuOwner from './ManagedMenuOwner';
import StaticMenuOwner from './StaticMenuOwner';

export { default as useOrCreateContextMenuScope } from './useOrCreateContextMenuScope';
export {
  default as useContextMenuState,
  useIsTriggerVisible,
  useSetTriggerVisibility,
  useShowContextMenu,
  useHideContextMenu,
} from './useContextMenuState';
export { default as managedMenuOwnerHOC } from './managedMenuOwnerHOC';

/**
 * @example
 * [Web Only] The managed owner handles hover states and hiding the menu automatically.
 *
 * ```
 *  <ContextMenu.ManagedOwner>
 *    <ContextMenu.Root>
 *      <ContextMenu.Item (...) />
 *      <ContextMenu.Item (...) />
 *      <ContextMenu.Item (...) />
 *      // ...
 *    </ContextMenu.Root>
 *  </ContextMenu.ManagedOwner>
 * ```
 *
 * @example
 * With a static owner, all visibility state is passed in declaratively.
 *
 * ```
 *  <ContextMenu.StaticOwner visible={visible} onClose={handleClose}>
 *    <ContextMenu.Root>
 *      <ContextMenu.Item (...) />
 *      <ContextMenu.Item (...) />
 *      <ContextMenu.Item (...) />
 *      // ...
 *    </ContextMenu.Root>
 *  </ContextMenu.StaticOwner>
 * ```
 */
export default {
  ManagedOwner: ManagedMenuOwner,
  StaticOwner: StaticMenuOwner,
  Root: MenuRoot,
  Item: MenuItem,
};
