import { useRef, KeyboardEvent } from 'react';

import useEvent from './useEvent';

export default function useEnterPress<E extends HTMLElement>(
  callback: () => void,
) {
  const isComposing = useRef(false);

  const onCompositionStart = useEvent(() => {
    isComposing.current = true;
  });

  const onCompositionEnd = useEvent(() => {
    isComposing.current = false;
  });

  const onKeyDown = useEvent((event: KeyboardEvent<E>) => {
    if (isComposing.current || event.shiftKey || event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    callback();
  });

  return {
    onCompositionStart,
    onCompositionEnd,
    onKeyDown,
  };
}
