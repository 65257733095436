/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';

import type { ConnectedCallParticipant } from '../../VideoRoomAPI';

export type ObjectFill = 'contain' | 'cover';
export type OptimalFit = {
  objectFill: ObjectFill;
  scale: number;
};

const DefaultMaxOverflowRatio = 0.2; // 20%

/**
 * Chooses the fit that optimizes the visible video feed while
 * trying to reduce needless black-bars.
 *
 * @param participant The participant whose video is going to be displayed.
 * @param targetAspectRatio The aspect ratio of the container.
 * @param maxAllowedOverflowRatio How big can the ratio between overflown area and visible area can get.
 */
export function useOptimalVideoMediaFit(
  participant: ConnectedCallParticipant,
  targetAspectRatio: number,
  maxAllowedOverflowRatio: number = DefaultMaxOverflowRatio,
): OptimalFit {
  const aspectRatio = participant.session.metadata.cameraAspectRatio;

  return useMemo(() => {
    if (!aspectRatio) {
      return { objectFill: 'contain', scale: 1 };
    }

    // The calculation below is equivalent to:
    //   (areaOfSource - areaOfCrop) / areaOfSource.
    const overflownRatio =
      targetAspectRatio < aspectRatio
        ? 1 - targetAspectRatio / aspectRatio
        : 1 - aspectRatio / targetAspectRatio;

    const objectFill =
      overflownRatio > maxAllowedOverflowRatio ? 'contain' : 'cover';

    return {
      objectFill,
      scale: objectFill === 'contain' ? 1.4 : 1,
    };
  }, [aspectRatio, targetAspectRatio, maxAllowedOverflowRatio]);
}
