/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/prefer-default-export */

/**
 * To be used by .ts/.js configuration scripts, or during app runtime to resolve value overrides
 * in the form of `"cond1:cond2:...:cond3:key": "value"`, where:
 * - `condN` determines whether to apply the value or not. If all `cond*` are true, `value` is assigned to `key`.
 *
 * ## Example #1:
 * config.json
 * ```jsonc
 * "productName": "Student Advisor",
 * "ios:productName": "Student Advisor (iOS)",
 * ```
 * Then in code:
 * ```ts
 * const config = preprocessInstanceConfig(...);
 * // 'Student Advisor (iOS)' is applied, because all conditions are true, and it appears last
 * assert(config.productName === 'Student Advisor (iOS)');
 * ```
 *
 * ## Example #2:
 * config.json
 * ```jsonc
 * "ios:productName": "Student Advisor (iOS)",
 * "productName": "Student Advisor",
 * ```
 * Then in code:
 * ```ts
 * const config = preprocessInstanceConfig(...);
 * // 'Student Advisor' is applied, because all conditions are true, and it appears last
 * assert(config.productName === 'Student Advisor');
 * ```
 *
 * @returns {unknown}
 */
function preprocessInstanceConfig(
  /** @type {Record<string, unknown>} */ config,
  /** @type {z.infer<typeof InstanceContext>} */ context,
) {
  if (!config || typeof config !== 'object' || Array.isArray(config)) {
    return config;
  }

  const result = {};

  Object.entries(config).forEach(([conditionedKey, value]) => {
    const conditions = conditionedKey.split(':');
    const key = conditions.pop(); // last element is the key

    if (
      conditions.length === 0 ||
      conditions.every((condition) => context[condition])
    ) {
      result[key] = preprocessInstanceConfig(value, context);
    }
  });

  return result;
}

exports.preprocessInstanceConfig = preprocessInstanceConfig;
