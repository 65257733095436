import { useSetAtom } from 'jotai';
import { produce } from 'immer';
import { type SubscriptionResult } from '@apollo/client';

import {
  VideoChatEventType,
  ChatRoomInfoFragment,
  VideoChatRoomInfoFragment,
  ChatRoomInfoFragmentDoc,
  VideoChatRoomInfoFragmentDoc,
  UpdatedVideoChatSubscription,
  useUpdatedVideoChatSubscription,
  VideoChatQuery,
  VideoChatQueryVariables,
  VideoChatDocument,
} from '@advisor/api/generated/graphql';
import { identifyChatRoomCache } from '@advisor/api/graphql/customCacheKeys';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import SoundNotifications from '../SoundNotifications';
import { useCallState } from '../VideoRoomAPI';
import {
  RecordingRequestState,
  recordingRequestStateAtom,
} from '../RecordingManager';

const updateVideoChatRoomInfoFragment = (
  result: SubscriptionResult<UpdatedVideoChatSubscription, undefined>,
  fragment: VideoChatRoomInfoFragment | null,
): VideoChatRoomInfoFragment | null => {
  const { videoChat } = result.data?.updatedVideoChat ?? {};

  if (!fragment) {
    return null;
  }

  return {
    ...fragment,
    ...(videoChat as VideoChatRoomInfoFragment),
  };
};

const updateChatRoomInfoFragment = (
  result: SubscriptionResult<UpdatedVideoChatSubscription, undefined>,
  fragment: ChatRoomInfoFragment | null,
): ChatRoomInfoFragment | null => {
  return produce(fragment, (draft) => {
    const { eventType } = result.data?.updatedVideoChat ?? {};

    if (!draft) {
      return;
    }

    if (eventType === VideoChatEventType.Create) {
      draft.isVideoChatActive = true;
    }

    if (eventType === VideoChatEventType.Delete) {
      draft.isVideoChatActive = false;
    }
  });
};

const useVideoChatSubscription = () => {
  const callState = useCallState();
  const isAuthorized = useIsAuthorized();
  const setRequestState = useSetAtom(recordingRequestStateAtom);

  useUpdatedVideoChatSubscription({
    skip: !isAuthorized,
    onData: ({ client, data }) => {
      const { eventType, videoChat, chatRoomId } =
        data.data?.updatedVideoChat ?? {};

      if (!eventType || !videoChat || !chatRoomId) {
        return;
      }

      if (eventType !== VideoChatEventType.RecordingAdded) {
        client.cache.updateFragment(
          {
            fragment: VideoChatRoomInfoFragmentDoc,
            fragmentName: 'VideoChatRoomInfo',
            id: client.cache.identify({
              __typename: 'VideoChatRoom',
              chatRoomId,
            }),
          },
          (fragment: VideoChatRoomInfoFragment | null) =>
            updateVideoChatRoomInfoFragment(data, fragment),
        );
      }

      if (
        eventType === VideoChatEventType.Create &&
        callState?.chatRoomId !== chatRoomId
      ) {
        SoundNotifications.playInviteSound();
      }

      if (videoChat.__typename !== 'ArchivalVideoChatRoom') {
        client.cache.writeQuery<VideoChatQuery, VideoChatQueryVariables>({
          query: VideoChatDocument,
          variables: {
            chatRoomId,
          },
          data: {
            __typename: 'Query',
            videoChat,
          },
        });
      }

      client.cache.updateFragment(
        {
          fragment: ChatRoomInfoFragmentDoc,
          fragmentName: 'ChatRoomInfo',
          id: identifyChatRoomCache({ id: chatRoomId }),
        },
        (fragment: ChatRoomInfoFragment | null) =>
          updateChatRoomInfoFragment(data, fragment),
      );

      if (
        callState?.chatRoomId === chatRoomId &&
        videoChat.__typename === 'VideoChatRoom'
      ) {
        if (
          eventType === VideoChatEventType.RecordingStarted ||
          eventType === VideoChatEventType.RecordingStop ||
          eventType === VideoChatEventType.RecordingStopped
        ) {
          setRequestState(RecordingRequestState.IDLE);
        }
      }
    },
  });
};

export default useVideoChatSubscription;
