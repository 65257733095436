import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { searchFormStateAtom } from '../atoms';
import { SearchModalState } from '../types';
import useTalkToAssistant from '../useTalkToAssistant';

const useConnectionErrorTab = () => {
  const setModalState = useSetAtom(searchFormStateAtom);

  const onTryAgain = useCallback(() => {
    setModalState(SearchModalState.FORM);
  }, [setModalState]);

  const { onTalkToAssistant, loading } = useTalkToAssistant();

  return {
    disabled: loading,
    onTryAgain,
    onTalkToAssistant,
  };
};

export default useConnectionErrorTab;
