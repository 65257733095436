import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SizeAdaptingWrapper, useForm } from '@advisor/design/components';
import ConnectionErrorTab from './ConnectionErrorTab';
import SearchSuccessTab from './SearchSuccessTab';
import StudentSearchForm from './StudentSearchForm';
import {
  SearchFormError,
  SearchModalState,
  StudentSearchFormSchema,
} from './types';
import { StudentSearchFormProps } from './StudentSearchForm/types';

type Props = {
  state: SearchModalState;
};

const TabsMap = {
  [SearchModalState.SUCCESS]: SearchSuccessTab,
  [SearchModalState.ERROR]: ConnectionErrorTab,
  [SearchModalState.FORM]: StudentSearchForm,
} as const;

const StudentSearchInner: React.FC<Props> = ({ state }) => {
  const InnerComponent = TabsMap[state];

  const { t } = useTranslation('onboarding');

  // Input and  Error state is kept in the parent component
  // instead  of  StudentSearchForm  to preserve the  state
  // when switching between tabs (eg. When user is directed
  // to error tab he can go  back without losing the input)
  const { inputs } = useForm(
    StudentSearchFormSchema,
    {
      advisorName: '',
      studentName: '',
      studentEmail: '',
      studentPhone: '',
    },
    {
      advisorName: t('host-name-optional'),
      studentName: t('customer-name'),
      studentEmail: t('customer-email-address'),
      studentPhone: t('customer-phone-number'),
    },
  );
  const [searchError, setSearchError] = useState<SearchFormError | undefined>(
    undefined,
  );

  return (
    <SizeAdaptingWrapper<StudentSearchFormProps>
      durationMs={200}
      inner={InnerComponent}
      commonProps={{
        inputs,
        searchError,
        setSearchError,
      }}
    />
  );
};

export default StudentSearchInner;
