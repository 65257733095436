import React, {
  memo,
  FormEvent,
  useCallback,
  createContext,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { times } from 'lodash-es';
import cs from 'classnames';

import { isPromise } from '../DeprecatedButton/utils';
import Icon, { SpinnerIcon } from '../Icon';
import {
  FormProps,
  TitleProps,
  ComponentProps,
  FormContextType,
  StepIndicatorProps,
  ConfirmButtonProps,
  CancelButtonProps,
  AlertTextProps,
  FormContainerProps,
} from './types';

const FormContext = createContext<FormContextType>({ isLoading: false });

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  className,
  insideModal,
}) => {
  return (
    <div
      className={cs(
        'flex flex-col bg-white rounded-lg shadow-lg p-6 md:h-auto md:p-12 md:min-h-[600px] w-[360px] md:w-[438px] relative',
        !insideModal && 'h-dynamic-screen md:mt-[114px]',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Title: React.FC<TitleProps> = ({ children, className }) => {
  return <h2 className={cs('font-semibold text-xl', className)}>{children}</h2>;
};

export const Form: React.FC<FormProps> = memo(
  ({ children, className, onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(
      async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const returnValue = onSubmit();

        if (!isPromise(returnValue)) {
          return;
        }

        setIsLoading(true);

        await returnValue;

        setIsLoading(false);
      },
      [onSubmit],
    );

    return (
      <FormContext.Provider value={{ isLoading }}>
        <form
          className={cs('flex flex-1 flex-col justify-between', className)}
          autoComplete="on"
          onSubmit={handleSubmit}
        >
          {children}
        </form>
      </FormContext.Provider>
    );
  },
);

export const Subtitle: React.FC<TitleProps> = ({ children, className }) => {
  return (
    <h3 className={cs('font-medium break-words', className)}>{children}</h3>
  );
};

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  step,
  ofSteps,
}) => {
  return (
    <div className="flex flex-row justify-center items-center">
      {times(ofSteps).map((currentStep) => (
        <div
          className={cs(
            'w-4 h-1 bg-primary rounded-md mx-1',
            currentStep !== step - 1 && 'opacity-10',
          )}
          key={currentStep}
        />
      ))}
    </div>
  );
};

export const ConfirmButton: React.FC<ConfirmButtonProps> = memo(
  ({ children, className, disabled }) => {
    const { isLoading } = useContext(FormContext);

    return (
      <button
        data-cy="loginSubmitButton"
        type="submit"
        className={cs(
          'text-sm md:text-base font-medium text-white whitespace-nowrap bg-primary rounded-10 mt-8 h-12.5 w-52 min-w-fit px-3 shadow-drop-01 hover:bg-primary-dark disabled:bg-primary-light',
          className,
        )}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <span className="inline-flex w-6 h-6 stroke-white">
            <SpinnerIcon />
          </span>
        ) : (
          children
        )}
      </button>
    );
  },
);

export const CancelButton: React.FC<CancelButtonProps> = memo(
  ({ children, className, disabled, onPress }) => {
    const { isLoading } = useContext(FormContext);

    return (
      <button
        data-cy="loginCancelButton"
        type="button"
        className={cs(
          'text-sm md:text-base font-medium text-dark-grey-03 border whitespace-nowrap rounded-10 mt-5 h-12.5 w-52 min-w-fit px-3 shadow-drop-01',
          className,
        )}
        disabled={disabled || isLoading}
        onClick={onPress}
      >
        {children}
      </button>
    );
  },
);

export const AlertText: React.FC<AlertTextProps> = memo(
  ({ children, className, withIcon }) => (
    <div className="flex flex-row gap-2">
      {withIcon && (
        <Icon name="Warning" size={48} color="negative" className="mt-2" />
      )}
      <p
        className={cs('text-sm text-negative mt-3 mb-6', className)}
        data-cy="alert-text"
      >
        {children}
      </p>
    </div>
  ),
);

export const TermsOfUse: React.FC = memo(() => {
  const { t } = useTranslation(['common', 'onboarding']);

  return (
    <div className="text-sm">
      <p>{t('onboarding:by-signing-in-you-agree')}</p>
      <a
        href="/terms"
        target="_blank"
        rel="noreferrer"
        className="underline text-primary"
      >
        {t('common:terms-of-use')}
      </a>{' '}
      {t('common:and')}{' '}
      <a
        href="/privacy-policy"
        target="_blank"
        rel="noreferrer"
        className="underline text-primary"
      >
        {t('common:privacy-policy')}
      </a>
    </div>
  );
});

export const Note: React.FC<ComponentProps> = memo(
  ({ children, className }) => {
    const { t } = useTranslation('onboarding');

    return (
      <div className="font-outfit text-xs font-normal">
        <p className="font-bold">{t('note-colon')}</p>
        <p className={cs(className)}>{children}</p>
      </div>
    );
  },
);
