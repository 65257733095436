import { atom, useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';
import { createScope, molecule, use } from 'bunshi';

import { myIdAtom } from '@advisor/api/me';
import { Lazy } from '@advisor/utils/atoms';
import {
  MicrobotMessageInfoFragment,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphql';
import { MessageTranslationMolecule } from '../chatLanguage';
import {
  messageInfoAtoms,
  microbotMessageInfoAtoms,
  userMessageInfoAtoms,
} from './latestMessagesAtoms';

export const MessageScope = createScope<string | null>(null);

export function requireMessageScope() {
  const messageId = use(MessageScope);

  if (!messageId) {
    throw new Error(
      `Tried to use message molecule without providing its scope`,
    );
  }

  return messageId;
}

export const MessageMolecule = molecule(() => {
  const messageId = requireMessageScope();

  const messageAtom = messageInfoAtoms(messageId);

  const isOwnAtom = atom((get) => {
    const message = get(messageAtom);
    const myId = get(Lazy.valueOr(myIdAtom, undefined));

    if (message?.__typename !== 'UserMessage' || !myId) {
      return false;
    }

    return message.author.id === myId;
  });

  return {
    messageAtom,
    isOwnAtom,
  };
});

export const UserMessageMolecule = molecule(() => {
  const messageId = requireMessageScope();
  const { translationAtom } = use(MessageTranslationMolecule);

  const messageAtom = userMessageInfoAtoms(messageId);

  const messageTextAtom = atom((get) => {
    const { message } = get(messageAtom) ?? {};
    const translation = get(Lazy.valueOr(translationAtom, undefined));

    // TODO: Workaround until the string representation is no longer being used.
    return (
      (typeof translation === 'string' ? translation : translation?.message) ??
      message ??
      ''
    );
  });

  return {
    messageAtom,
    messageTextAtom,
  };
});

export const MicrobotMessageMolecule = molecule(() => {
  const messageId = requireMessageScope();

  const messageAtom = microbotMessageInfoAtoms(messageId);

  return {
    messageAtom,
  };
});

export function useUserMessage(flag: 'required'): UserMessageInfoFragment;
export function useUserMessage(
  flag?: 'required',
): UserMessageInfoFragment | undefined;
export function useUserMessage(flag?: 'required') {
  const { messageAtom } = useMolecule(UserMessageMolecule);
  const userMessage = useAtomValue(messageAtom) ?? undefined;

  if (!userMessage && flag === 'required') {
    throw new Error(`No UserMessage query result.`);
  }

  return userMessage;
}

export function useMicrobotMessage(
  flag: 'required',
): MicrobotMessageInfoFragment;
export function useMicrobotMessage(
  flag?: 'required',
): MicrobotMessageInfoFragment | undefined;
export function useMicrobotMessage(flag?: 'required') {
  const { messageAtom } = useMolecule(MicrobotMessageMolecule);
  const microbotMessage = useAtomValue(messageAtom) ?? undefined;

  if (!microbotMessage && flag === 'required') {
    throw new Error(`No MicrobotMessage query result.`);
  }

  return microbotMessage;
}
