import Add from './Add.svg';
import AddAttachment from './AddAttachment.svg';
import AddInfo from './AddInfo.svg';
import AddMemory from './AddMemory.svg';
import AICalendar from './AICalendar.svg';
import AIChat from './AIChat.svg';
import AICheck from './AICheck.svg';
import AIClock from './AIClock.svg';
import AICustomize from './AICustomize.svg';
import AIEye from './AIEye.svg';
import AIKnowledge from './AIKnowledge.svg';
import AIMagicWand from './AIMagicWand.svg';
import AISchool from './AISchool.svg';
import Archive from './Archive.svg';
import ArrowHeadRight from './ArrowHeadRight.svg';
import ArrowLeft from './ArrowLeft.svg';
import ArrowRight from './ArrowRight.svg';
import Assistant from './Assistant.svg';
import AttachmentBroken from './AttachmentBroken.svg';
import Attachments from './Attachments.svg';
import Calendar from './Calendar.svg';
import Camera from './Camera.svg';
import CameraFlip from './CameraFlip.svg';
import CameraOff from './CameraOff.svg';
import CameraOn from './CameraOn.svg';
import CameraControl from './CameraControl.svg';
import Check from './Check.svg';
import CheckAlt from './CheckAlt.svg';
import ChevronDown from './ChevronDown.svg';
import ChevronLeft from './ChevronLeft.svg';
import ChevronRight from './ChevronRight.svg';
import ChevronUp from './ChevronUp.svg';
import CircleCheck from './CircleCheck.svg';
import CircleCheckFill from './CircleCheckFill.svg';
import CircleCheckSmall from './CircleCheckSmall.svg';
import Clock from './Clock.svg';
import Close from './Close.svg';
import ColorPalette from './ColorPalette.svg';
import Comments from './Comments.svg';
import Configure from './Configure.svg';
import CSVFile from './CSVFile.svg';
import Copy from './Copy.svg';
import Delete from './Delete.svg';
import DeleteLarge from './DeleteLarge.svg';
import Disconnect from './Disconnect.svg';
import DocumentEdit from './DocumentEdit.svg';
import Dots from './Dots.svg';
import DotsVertical from './DotsVertical.svg';
import DoubleExclamation from './DoubleExclamation.svg';
import Download from './Download.svg';
import Edit from './Edit.svg';
import EnterFullscreen from './EnterFullscreen.svg';
import ErrorCircle from './ErrorCircle.svg';
import ErrorTriangle from './ErrorTriangle.svg';
import Filter from './Filter.svg';
import GlobalStudyBadge from './GlobalStudyBadge.svg';
import Globe from './Globe.svg';
import GlobeGradient from './GlobeGradient.svg';
import Handle from './Handle.svg';
import HangUp from './HangUp.svg';
import HelpCircle from './HelpCircle.svg';
import Hide from './Hide.svg';
import InfoCircle from './InfoCircle.svg';
import Leave from './Leave.svg';
import LeaveFullscreen from './LeaveFullscreen.svg';
import Link from './Link.svg';
import LinkBroken from './LinkBroken.svg';
import LinkTriple from './LinkTriple.svg';
import ListLocked from './ListLocked.svg';
import Logout from './Logout.svg';
import Menu from './Menu.svg';
import Message from './Message.svg';
import MessageAlt from './MessageAlt.svg';
import MicrophoneOff from './MicrophoneOff.svg';
import MicrophoneOn from './MicrophoneOn.svg';
import MicrophoneControl from './MicrophoneControl.svg';
import Minimize from './Minimize.svg';
import Muted from './Muted.svg';
import Offline from './Offline.svg';
import Path from './Path.svg';
import Pause from './Pause.svg';
import Pin from './Pin.svg';
import PinFill from './PinFill.svg';
import Play from './Play.svg';
import PlayCircle from './PlayCircle.svg';
import Reload from './Reload.svg';
import ReloadMono from './ReloadMono.svg';
import Remove from './Remove.svg';
import Reorder from './Reorder.svg';
import Report from './Report.svg';
import Robot from './Robot.svg';
import SaveMemory from './SaveMemory.svg';
import SaveMemoryOutline from './SaveMemoryOutline.svg';
import School from './School.svg';
import ScreenShare from './ScreenShare.svg';
import Search from './Search.svg';
import Send from './Send.svg';
import Share from './Share.svg';
import Show from './Show.svg';
import SkipBack10 from './SkipBack10.svg';
import SkipForward10 from './SkipForward10.svg';
import SpeakerOff from './SpeakerOff.svg';
import SpeakerOn from './SpeakerOn.svg';
import Spinner from './Spinner.svg';
import Star from './Star.svg';
import StarFourPoint from './StarFourPoint.svg';
import StarLarge from './StarLarge.svg';
import StarOutline from './StarOutline.svg';
import Stars from './Stars.svg';
import StarsAlt from './StarsAlt.svg';
import StarsSup from './StarsSup.svg';
import Statuses from './Statuses.svg';
import StatusesOutline from './StatusesOutline.svg';
import StudentJourney from './StudentJourney.svg';
import ThickArrowLeft from './ThickArrowLeft.svg';
import Translate from './Translate.svg';
import Unmuted from './Unmuted.svg';
import Upload from './Upload.svg';
import UserChange from './UserChange.svg';
import UserProfile from './UserProfile.svg';
import UserProfileMultiple from './UserProfileMultiple.svg';
import UserSettings from './UserSettings.svg';
import Users from './Users.svg';
import Verified from './Verified.svg';
import VideoRoom from './VideoRoom.svg';
import VideoRoomCrossed from './VideoRoomCrossed.svg';
import VideoRoomFill from './VideoRoomFill.svg';
import VideoRoomFillOutline from './VideoRoomFillOutline.svg';
import VideoRoomHistory from './VideoRoomHistory.svg';
import Wand from './Wand.svg';
import WandFilled from './WandFilled.svg';
import Warning from './Warning.svg';
import X from './X.svg';
import XLarge from './XLarge.svg';

const svgs = {
  Add,
  AddAttachment,
  AddInfo,
  AddMemory,
  AICalendar,
  AIChat,
  AICheck,
  AIClock,
  AICustomize,
  AIEye,
  AIKnowledge,
  AIMagicWand,
  AISchool,
  Archive,
  ArrowHeadRight,
  ArrowLeft,
  ArrowRight,
  Assistant,
  AttachmentBroken,
  Attachments,
  Calendar,
  Camera,
  CameraFlip,
  CameraOff,
  CameraOn,
  CameraControl,
  Check,
  CheckAlt,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleCheck,
  CircleCheckFill,
  CircleCheckSmall,
  Clock,
  Close,
  ColorPalette,
  Configure,
  Comments,
  CSVFile,
  Copy,
  Delete,
  DeleteLarge,
  Disconnect,
  DocumentEdit,
  Dots,
  DotsVertical,
  DoubleExclamation,
  Download,
  Edit,
  EnterFullscreen,
  ErrorCircle,
  ErrorTriangle,
  Filter,
  GlobalStudyBadge,
  Globe,
  GlobeGradient,
  Handle,
  HangUp,
  HelpCircle,
  Hide,
  InfoCircle,
  Leave,
  LeaveFullscreen,
  Link,
  LinkBroken,
  LinkTriple,
  ListLocked,
  Logout,
  Menu,
  Message,
  MessageAlt,
  MicrophoneOff,
  MicrophoneOn,
  MicrophoneControl,
  Minimize,
  Muted,
  Offline,
  Path,
  Pause,
  Pin,
  PinFill,
  Play,
  PlayCircle,
  Reload,
  ReloadMono,
  Remove,
  Reorder,
  Report,
  Robot,
  SaveMemory,
  SaveMemoryOutline,
  School,
  ScreenShare,
  Search,
  Send,
  Share,
  Show,
  SkipBack10,
  SkipForward10,
  SpeakerOff,
  SpeakerOn,
  Spinner,
  Star,
  StarFourPoint,
  StarLarge,
  StarOutline,
  Stars,
  StarsAlt,
  StarsSup,
  Statuses,
  StatusesOutline,
  StudentJourney,
  ThickArrowLeft,
  Translate,
  Unmuted,
  Upload,
  UserChange,
  UserProfile,
  UserProfileMultiple,
  UserSettings,
  Users,
  Verified,
  VideoRoom,
  VideoRoomCrossed,
  VideoRoomFill,
  VideoRoomFillOutline,
  VideoRoomHistory,
  Wand,
  WandFilled,
  Warning,
  X,
  XLarge,
};

export default svgs;
