import React from 'react';

const ChatModifiersLazy = React.lazy(() => import('./ChatNotifications'));

const ChatNotifications: React.FC = () => (
  <React.Suspense fallback={null}>
    <ChatModifiersLazy />
  </React.Suspense>
);

export default {
  /**
   * Contains only components that rendered independently from their place in virtual DOM,
   * like modals, banners, etc.
   */
  ChatNotifications,
};
