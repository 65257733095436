import React, { memo } from 'react';
import cs from 'classnames';

import { DeprecatedButton, Icon } from '@advisor/design/components';
import { IconName } from '@advisor/design/components/Icon';

interface ChatIconButtonProps {
  onPress: () => void | Promise<void>;
  iconName: IconName;
  disabled?: boolean;
  testID?: string;
  className?: string;
  small?: boolean;
}
const ChatIconButton: React.FC<ChatIconButtonProps> = ({
  testID,
  onPress,
  iconName,
  disabled,
  className,
  small,
}) => (
  <DeprecatedButton.Bare
    onPress={onPress}
    disabled={disabled}
    className={cs(
      'border border-light-grey rounded-10 bg-white flex items-center justify-center shadow-drop-01 hover:shadow-drop-02 disabled:shadow-drop-01',
      small ? 'w-7.5 h-7.5' : 'w-12.5 h-12.5',
      className,
    )}
    testID={testID}
  >
    {({ loading }) => (
      <Icon
        size={small ? 16 : 32}
        name={iconName}
        className="active:opacity-80"
        color={disabled || loading ? 'grey' : 'primary'}
      />
    )}
  </DeprecatedButton.Bare>
);

export default memo(ChatIconButton);
