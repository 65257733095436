import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useResetAtom } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useOutlet, useParams } from 'react-router';

import {
  featureEnabledAtoms,
  useIsFeatureEnabled,
  Feature,
} from '@advisor/api/feature';
import { Scope } from '@advisor/api/scope';
import { useGoBackOrGoHome } from '@advisor/utils/navigation/web';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { enumFromStringValue } from '@advisor/utils/typeUtils';
import { Toaster } from '@advisor/design/components/Toast';
import { Layout } from '@advisor/design/components';
import { SidebarTab } from '@advisor/ui/atoms/sidebarTabAtom';
import { AccessModal } from '@advisor/microbots/components';
import { useMicrobot } from '@advisor/microbots/hooks';
import { microbotTabAtom } from '@advisor/microbots/hooks/useMicrobot';
import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { PersonalityToFeatureKey } from '@advisor/microbots/utils';
import SideModal from 'src/components/organisms/modals/SideModal';
import ChatSidebar from '../../components/organisms/ChatSidebar';
import NotFoundWidget from '../../components/NotFoundWidget';
import Page from '../../components/Page';
import { MicrobotInfoParams } from '../params';
import MicrobotDescription from './MicrobotDescription';
import MicrobotPageHeader from './MicrobotPageHeader';
import MicrobotBasicInfo from './MicrobotBasicInfo';

const MicrobotInfoPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { microbotName } = useParams<MicrobotInfoParams>();

  const personality = enumFromStringValue(
    MicrobotPersonality,
    (microbotName ?? '').toLocaleUpperCase(),
  );

  const featureFlag = personality
    ? PersonalityToFeatureKey[personality]
    : undefined;

  const isMicrobotEnabled = useAtomValue(
    featureFlag ? featureEnabledAtoms(featureFlag) : Scope.available,
  );
  const isMicrobotsEnabled = useIsFeatureEnabled(Feature.Microbots);

  const microbot = useMicrobot(personality);
  const resetMicrobotTab = useResetAtom(microbotTabAtom);

  const [accessModalVisible, setAccessModalVisible] = useState(false);

  const onGoBack = useGoBackOrGoHome('/community');

  const onConfigureMicrobot = () => {
    navigate('configure');
  };

  useEffect(() => {
    if (!isMicrobotsEnabled || !isMicrobotEnabled) {
      onGoBack();
    }
  }, [isMicrobotsEnabled, isMicrobotEnabled, onGoBack]);

  useEffect(() => {
    // Reset to initial tab on mount
    resetMicrobotTab();
  }, [resetMicrobotTab]);

  if (!personality || !microbot) {
    return (
      <Page.Root>
        <ChatSidebar initialTab={SidebarTab.DirectoryList} />
        <Page.Content>
          <Page.Header onGoBack={onGoBack} title={t('page-not-found')} />
          <NotFoundWidget />
          <Toaster />
        </Page.Content>
        <Outlet />
      </Page.Root>
    );
  }

  return (
    <Page.Root>
      <ChatSidebar initialTab={SidebarTab.DirectoryList} />
      <Page.Content>
        <div className="overflow-auto relative">
          <MicrobotPageHeader
            onGoBack={onGoBack}
            name={microbot.name}
            iconName={microbot.nameIcon}
            bgLight={microbot.bgLight}
            bgDark={microbot.bgDark}
          />
          <div className="grow xl:p-6">
            <MicrobotBasicInfo
              microbot={microbot}
              onConfigureMicrobot={onConfigureMicrobot}
              onEditAccess={() => setAccessModalVisible(true)}
            />
            <Layout.Spacer.Vertical size="small01" />
            <MicrobotDescription
              microbot={microbot}
              onConfigureMicrobot={onConfigureMicrobot}
              onEditAccess={() => setAccessModalVisible(true)}
            />
          </div>
        </div>
        <Toaster />
      </Page.Content>
      <MicrobotInfoPageSideModal />

      <AccessModal
        visible={accessModalVisible}
        onClose={() => setAccessModalVisible(false)}
        onConfigure={onConfigureMicrobot}
        personality={microbot.personality}
      />
    </Page.Root>
  );
};

export default suspenseHOC(MicrobotInfoPage, null);

const MicrobotInfoPageSideModal: React.FC = () => {
  const { microbotName } = useParams<MicrobotInfoParams>();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/community/microbot/${microbotName}`);
  };

  return (
    <SideModal.Root
      className="fixed inset-y-0 ltr:right-0 rtl:left-0"
      onClose={onClose}
      show={!!outlet}
    >
      <Outlet />
    </SideModal.Root>
  );
};
