import { ApolloCache } from '@apollo/client';

import { WithRequired } from '@advisor/utils/typeUtils';
import { MeDocument, MeQuery, UserUnion } from '../generated/graphqlTypes';

export default function mergeMe(
  cache: ApolloCache<unknown>,
  result: null | undefined | WithRequired<Partial<UserUnion>, '__typename'>,
) {
  cache.updateQuery(
    {
      query: MeDocument,
    },
    (prev) => {
      if (!result) {
        return prev;
      }

      if (!prev?.me) {
        return {
          __typename: 'Query' as const,
          me: result,
        } as MeQuery;
      }

      return {
        ...prev,
        me: {
          ...prev.me,
          ...result,
        },
      } as MeQuery;
    },
  );
}
