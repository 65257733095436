import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import cs from 'classnames';

import useRoleRequests from '../../RoleRequestList/useRoleRequests';
import { HeadingProps } from './types';
import { selectedTabAtom } from '../atoms';

const HeadingTabs: React.FC<HeadingProps> = ({ conversationsCount }) => {
  const roleRequestsCount = useRoleRequests().count;

  const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

  const { t } = useTranslation('common');

  const conversationCount = conversationsCount
    ? String(conversationsCount).padStart(2, '0')
    : '00';
  const requestCount = roleRequestsCount
    ? String(roleRequestsCount).padStart(2, '0')
    : '00';

  return (
    <div className="flex items-center">
      <HeadingTabsButton
        isActive={selectedTab === 'chatRooms'}
        onClick={() => setSelectedTab('chatRooms')}
        testID="conversations"
      >
        {selectedTab === 'chatRooms'
          ? t('conversation-count', { conversationCount })
          : t('conversations')}
      </HeadingTabsButton>
      <HeadingTabsButton
        isActive={selectedTab === 'roleRequests'}
        onClick={() => setSelectedTab('roleRequests')}
        testID="role-requests"
      >
        {selectedTab === 'roleRequests'
          ? t('request-count', { requestCount })
          : t('requests')}
      </HeadingTabsButton>
    </div>
  );
};

export default HeadingTabs;

type HeadingTabsButtonProps = PropsWithChildren<{
  isActive: boolean;
  onClick: () => void;
  testID?: string;
}>;

const HeadingTabsButton: React.FC<HeadingTabsButtonProps> = ({
  children,
  isActive,
  onClick,
  testID,
}) => (
  <button
    type="button"
    className={cs(
      'px-4 py-2 rounded-lg font-sora text-sm leading-[1.125rem]',
      isActive
        ? 'text-dark-grey-03 bg-light-grey font-bold'
        : 'text-dark-grey-025 font-semibold',
    )}
    onClick={() => onClick()}
    data-cy={testID}
  >
    {children}
  </button>
);
