import { Dayjs } from 'dayjs';
import { useMemo } from 'react';

import { DateDetails } from './types';
import { getCalendarRows, getPrevMonth, isMonthDisabled } from './utils';

type UseMonthOptions = {
  month: Dayjs;
  minDate?: Dayjs;
  selectedDate?: Dayjs;
};

const useMonth = (options: UseMonthOptions) => {
  const { month, selectedDate, minDate } = options;

  const calendar = useMemo(() => getCalendarRows(month), [month]);

  const calendarRows = useMemo(() => {
    return calendar.map((row) =>
      row.map(
        (date): DateDetails => ({
          ...date,
          isSelected: date.date.isSame(selectedDate, 'day'),
          isDisabled: minDate?.isAfter(date.date, 'date') ?? false,
        }),
      ),
    );
  }, [calendar, minDate, selectedDate]);

  const weekdays = useMemo(() => {
    const isThisMonth = month.isSame(selectedDate, 'month');

    return calendarRows[0].map(({ date }) => ({
      label: date.format('ddd'),
      selected: isThisMonth && date.day() === selectedDate?.day(),
    }));
  }, [calendarRows, month, selectedDate]);

  const calendarTitle = useMemo(() => {
    return month.format('MMMM YYYY');
  }, [month]);

  const isPrevDisabled = useMemo(() => {
    return isMonthDisabled(getPrevMonth(month), minDate);
  }, [minDate, month]);

  return {
    weekdays,
    calendarRows,
    calendarTitle,
    isPrevDisabled,
  };
};

export default useMonth;
