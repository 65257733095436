import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import { createMessageCursor } from '@advisor/chat/messaging';
import {
  MessageEdge,
  LatestMessagesQuery,
  LatestMessagesDocument,
  useCheckLastMessagesMutation,
  CheckLastMessagesMutationOptions,
} from '../generated/graphql';
import useAccessToken from '../auth/useAccessToken';

/**
 * Hook will update last messages if necessary after
 * authToken has changed as messages queries aren't
 * retriggered automatically by apollo.
 * This may lead to user not receiving the messages
 * sent when token was refreshing
 */
function mutationOptions(
  chatRoomId?: string,
): CheckLastMessagesMutationOptions {
  return {
    update(cache, mutationResult) {
      if (!chatRoomId) {
        return;
      }

      const data: LatestMessagesQuery | null = cache.readQuery({
        query: LatestMessagesDocument,
        variables: { chatRoomId },
      });

      if (!mutationResult.data || !data) {
        return;
      }

      const messageEdges: MessageEdge[] = [];

      const newMessageEdgesMap: { [id: string]: MessageEdge } =
        mutationResult.data.checkLastMessages.edges.reduce((reducer, edge) => {
          return {
            ...reducer,
            [edge.node.id]: edge,
          };
        }, {});

      const firstMessageEdge = mutationResult.data.checkLastMessages.edges[0];
      let hasEarlierMessages = false;

      for (let i = 0; i < data.messages.edges.length; i += 1) {
        const edge = data.messages.edges[i];

        if (dayjs(edge.node.sentAt).isBefore(firstMessageEdge.node.sentAt)) {
          hasEarlierMessages = true;
          messageEdges.push(edge as MessageEdge);
        } else if (newMessageEdgesMap[edge.node.id]) {
          // Message updated
          messageEdges.push({
            ...edge,
            ...newMessageEdgesMap[edge.node.id],
          });

          /// remove updated item from the map
          delete newMessageEdgesMap[edge.node.id];
        }
      }

      // Push all new messages to the end of the array
      Object.values(newMessageEdgesMap).forEach((edge) => {
        messageEdges.push({
          ...edge,
          cursor: createMessageCursor(edge.node),
        });
      });

      cache.writeQuery({
        query: LatestMessagesDocument,
        variables: { chatRoomId },
        data: {
          ...data,
          messages: {
            ...data.messages,
            edges: messageEdges,
            pageInfo: {
              // use original pagination info for hasPreviousPage
              ...data.messages.pageInfo,
              // Use cached cursor if data has earlier messages than mutation results
              startCursor: hasEarlierMessages
                ? data.messages.pageInfo.startCursor
                : mutationResult.data.checkLastMessages.pageInfo.startCursor,
              // use updated cursor form the mutation results
              endCursor:
                mutationResult.data.checkLastMessages.pageInfo.endCursor,
            },
          },
        },
      });
    },
  };
}

function useCheckLastMessages(chatRoomId?: string) {
  const [checkLastMessagesMutation] = useCheckLastMessagesMutation(
    mutationOptions(chatRoomId),
  );

  const accessToken = useAccessToken();
  const prevTokenRef = useRef<string>('');

  useEffect(() => {
    if (
      !chatRoomId ||
      !accessToken ||
      !prevTokenRef.current ||
      prevTokenRef.current === accessToken
    ) {
      prevTokenRef.current = accessToken ?? '';
      return;
    }

    prevTokenRef.current = accessToken;

    checkLastMessagesMutation({
      variables: { chatRoomId, last: 10 },
    });
  }, [accessToken, chatRoomId, checkLastMessagesMutation]);
}

export default useCheckLastMessages;
