import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import {
  BoldText,
  CenteredMessage,
  RichMessage,
} from '../systemMessageComponents';
import { SystemMessageProps } from '../types';

const InvitationConfirmed: React.FC<SystemMessageProps> = ({ message }) => {
  const { t } = useTranslation('system-message');

  const { chatRoomId, data } = message;
  const { advisor } = data as DefaultSystemMessageData;

  if (!advisor) {
    return null;
  }

  return (
    <RichMessage users={[advisor]} chatRoomId={chatRoomId}>
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey="hi-i-am-name"
        values={{ name: advisor.name }}
        components={{ bold: <BoldText /> }}
      />
      {'\n'}
      <CenteredMessage>{t('lets-get-started-how-can-i-help')}</CenteredMessage>
    </RichMessage>
  );
};

export default InvitationConfirmed;
