import { ParseKeys } from 'i18next';
import { atomFamily, atomWithReset } from 'jotai/utils';
import { ExtractAtomArgs, useAtomValue } from 'jotai';

import {
  MicrobotQuery,
  MicrobotPersonality,
  MicrobotDocument,
  UserInfoFragment,
} from '@advisor/api/generated/graphql';
import { IconName } from '@advisor/design/components/Icon';
import { lazyAtom, nullAtom } from '@advisor/utils/atoms';
import { Role } from '@advisor/api/user';
import { atomOfQuery } from '@advisor/api/apollo';

type ResourceKey = ParseKeys<['common', 'microbot']>;

export interface Capability {
  key: string; // to please react arrays :)
  iconName: IconName;
  title: ResourceKey;
  description: ResourceKey;
}

export interface TagDescription {
  key: string;
  color: string;
  title: ResourceKey;
  iconName?: IconName;
}

export enum IntegrationLogo {
  GoogleCalendar,
}

export interface Screenshot {
  url: string;
  title: ResourceKey;
}

export interface AboutSubsection {
  key: string; // to please react arrays :)
  title?: ResourceKey;
  subtitle?: ResourceKey;
  description?: ResourceKey;
  screenshots?: Screenshot[];
  tags?: TagDescription[];
  integrationLogo?: IntegrationLogo;
  hr?: boolean;
  galleryBgColor?: string;
}

export interface About {
  title: ResourceKey;
  subsections: AboutSubsection[];
}

export interface Role {
  title: ResourceKey;
  textColor: string;
  backgroundColor: string;
}

export interface MicrobotInfo {
  role: Role;
  color: string;
  bgDark: string;
  bgLight: string;
  nameIcon: IconName;
  specialty: ResourceKey;
  recommendedFor: ResourceKey;
  communityTitle: ResourceKey;
  messageSenderTitle: ResourceKey;

  about: About;
  howItWorks?: About;
  capabilities: Capability[];
}

export type MicrobotFullInfo = MicrobotInfo & MicrobotQuery['microbot'];

export const microbotTabAtom = atomWithReset<'about' | 'howItWorks'>('about');

const AstroInfo: MicrobotInfo = {
  specialty: 'microbot:alpha-bot-specialization',
  communityTitle: 'microbot:alpha-bot-community-title',
  messageSenderTitle: 'microbot:alpha-bot-message-title',
  recommendedFor: 'microbot:alpha-bot-recommended-for',
  nameIcon: 'StarsSup',
  color: '#0796B7',
  bgDark: '#7DC3D6',
  bgLight: '#CCEAF1',

  role: {
    title: 'microbot:alpha-bot-role-type',
    textColor: '#0696B7',
    backgroundColor: '#CDEAF1',
  },

  about: {
    title: 'common:about',
    subsections: [
      {
        key: 'about-bio',
        subtitle: 'microbot:bio',
        description: 'microbot:alpha-bot-bio',
      },
      {
        key: 'about-knows',
        subtitle: 'microbot:knows',
        description: 'microbot:all-spoken-languages',
      },
      {
        key: 'about-response-time',
        subtitle: 'microbot:response-time',
        description: 'microbot:instantly',
      },
    ],
  },

  capabilities: [
    {
      key: 'microbot:alpha-bot-capabilities-title-one',
      iconName: 'AIEye',
      title: 'microbot:alpha-bot-capabilities-title-one',
      description: 'microbot:alpha-bot-capabilities-description-one',
    },
    {
      key: 'microbot:alpha-bot-capabilities-title-two',
      iconName: 'AIMagicWand',
      title: 'microbot:alpha-bot-capabilities-title-two',
      description: 'microbot:alpha-bot-capabilities-description-two',
    },
    {
      key: 'microbot:alpha-bot-capabilities-title-three',
      iconName: 'AIKnowledge',
      title: 'microbot:alpha-bot-capabilities-title-three',
      description: 'microbot:alpha-bot-capabilities-description-three',
    },
  ],
};

const PamInfo: MicrobotInfo = {
  specialty: 'microbot:recommendation-bot-specialization',
  communityTitle: 'microbot:recommendation-bot-community-title',
  messageSenderTitle: 'microbot:recommendation-bot-message-title',
  recommendedFor: 'microbot:recommendation-bot-recommended-for',
  nameIcon: 'StarFourPoint',
  color: '#C77123',
  bgDark: '#D9A478',
  bgLight: '#E3B891',

  role: {
    title: 'microbot:recommendation-bot-role-type',
    textColor: '#4C5F81',
    backgroundColor: '#E2EBFA',
  },

  about: {
    title: 'common:about',
    subsections: [
      {
        key: 'about-bio',
        subtitle: 'microbot:bio',
        description: 'microbot:recommendation-bot-bio',
      },
      {
        key: 'about-knows',
        subtitle: 'microbot:knows',
        description: 'microbot:all-spoken-languages',
      },
      {
        key: 'about-response-time',
        subtitle: 'microbot:response-time',
        description: 'microbot:instantly',
      },
    ],
  },
  howItWorks: {
    title: 'common:how-it-works',
    subsections: [
      {
        key: 'about-screenshots',
        subtitle: 'common:screenshots',
        galleryBgColor: '#F4E3D3',
        screenshots: [
          {
            url: '/images/bots/pam-screenshot-1.jpg',
            title: 'microbot:microbot-tell-me-more-action',
          },
          {
            url: '/images/bots/pam-screenshot-2.jpg',
            title: 'microbot:download-partner-schools',
          },
        ],
      },
      {
        key: 'about-actions',
        subtitle: 'microbot:host-actions',
        description: 'microbot:recommendation-bot-learns-and-adapts',
      },
      {
        key: 'about-hr-1',
        hr: true,
      },
      {
        key: 'examples',
        title: 'common:examples',
        description:
          'microbot:you-have-option-to-request-additional-details-concerning-any-information-provided-by-recommendation-bot',
        tags: [
          {
            key: 'tell-me-more',
            title: 'microbot:microbot-tell-me-more-action',
            color: '#C77123',
            iconName: 'StarsSup',
          },
        ],
      },
      {
        key: 'about-hr-2',
        hr: true,
      },
      {
        key: 'abilities',
        description:
          'microbot:recommendation-bot-how-it-works-abilities-description',
        tags: [
          {
            key: 'view',
            title:
              'microbot:recommendation-bot-how-it-works-abilities-title-one',
            color: '#C77123',
            iconName: 'StarsSup',
          },
          {
            key: 'download',
            title:
              'microbot:recommendation-bot-how-it-works-example-description',
            color: '#C77123',
            iconName: 'StarsSup',
          },
        ],
      },
    ],
  },
  capabilities: [
    {
      key: 'microbot:recommendation-bot-capabilities-title-one',
      iconName: 'AISchool',
      title: 'microbot:recommendation-bot-capabilities-title-one',
      description: 'microbot:recommendation-bot-capabilities-description-one',
    },
    {
      key: 'microbot:recommendation-bot-capabilities-title-two',
      iconName: 'AIChat',
      title: 'microbot:recommendation-bot-capabilities-title-two',
      description: 'microbot:recommendation-bot-capabilities-description-two',
    },
    {
      key: 'microbot:recommendation-bot-capabilities-title-three',
      iconName: 'AICustomize',
      title: 'microbot:recommendation-bot-capabilities-title-three',
      description: 'microbot:recommendation-bot-capabilities-description-three',
    },
  ],
};

const CarlInfo: MicrobotInfo = {
  specialty: 'microbot:google-calendar',
  communityTitle: 'microbot:google-calendar-bot-community-title',
  messageSenderTitle: 'microbot:google-calendar-bot-message-title',
  recommendedFor: 'microbot:google-calendar-bot-recommended-for',
  nameIcon: 'StarFourPoint',
  color: '#7995C5',
  bgDark: '#DDE1E9',
  bgLight: '#E5E9EF',

  role: {
    title: 'microbot:google-calendar-bot-role-type',
    textColor: '#4C5F81',
    backgroundColor: '#E2EBFA',
  },

  about: {
    title: 'common:about',
    subsections: [
      {
        key: 'about-bio',
        subtitle: 'microbot:bio',
        description: 'microbot:google-calendar-bot-bio',
      },
      {
        key: 'about-knows',
        subtitle: 'microbot:knows',
        description: 'microbot:all-spoken-languages',
      },
      {
        key: 'about-response-time',
        subtitle: 'microbot:response-time',
        description: 'microbot:instantly',
      },
      {
        key: 'about-supports',
        subtitle: 'microbot:supports',
        description: 'microbot:google-calendar',
        integrationLogo: IntegrationLogo.GoogleCalendar,
      },
    ],
  },

  howItWorks: {
    title: 'common:how-it-works',
    subsections: [
      {
        key: 'about-screenshots',
        subtitle: 'common:screenshots',
        galleryBgColor: '#E2EBFA',
        screenshots: [
          {
            url: '/images/bots/carl-screenshot-1.jpg',
            title: 'microbot:add-to-google-calendar',
          },
          {
            url: '/images/bots/carl-screenshot-2.jpg',
            title: 'microbot:create-new-event',
          },
        ],
      },
      {
        key: 'about-actions',
        subtitle: 'microbot:host-actions',
        description:
          'microbot:google-calendar-bot-how-it-works-actions-description',
      },
      {
        key: 'about-hr-1',
        hr: true,
      },
      {
        key: 'about-examples',
        title: 'common:examples',
        description:
          'microbot:google-calendar-bot-how-it-works-examples-description',
        tags: [
          {
            key: 'add-to-google-calendar',
            title: 'microbot:add-to-google-calendar',
            color: '#7995C5',
            iconName: 'StarsSup',
          },
          {
            key: 'create-new-event',
            title: 'microbot:create-new-event',
            color: '#7995C5',
            iconName: 'StarsSup',
          },
        ],
      },
    ],
  },

  capabilities: [
    {
      key: 'google-calendar-bot-capabilities-title-one',
      iconName: 'AICalendar',
      title: 'microbot:google-calendar-bot-capabilities-title-one',
      description: 'microbot:google-calendar-bot-capabilities-description-one',
    },
    {
      key: 'google-calendar-bot-capabilities-title-two',
      iconName: 'AIClock',
      title: 'microbot:google-calendar-bot-capabilities-title-two',
      description: 'microbot:google-calendar-bot-capabilities-description-two',
    },
  ],
};

const MicrobotDetails = {
  [MicrobotPersonality.Astro]: AstroInfo,
  [MicrobotPersonality.Carl]: CarlInfo,
  [MicrobotPersonality.Pam]: PamInfo,
};

export const microbotAtoms = atomFamily((personality: MicrobotPersonality) => {
  const queryAtom = atomOfQuery(MicrobotDocument, {
    personality,
  });

  return lazyAtom(
    async (get) => {
      const query = await get(queryAtom);
      return query.data?.microbot ?? null;
    },
    (_get, set, ...args: ExtractAtomArgs<typeof queryAtom>) => {
      set(queryAtom, ...args);
    },
  );
});

export const microbotWithInfoAtoms = atomFamily(
  (personality: MicrobotPersonality) => {
    return lazyAtom(async (get) => {
      const microbot = await get(microbotAtoms(personality));

      if (!microbot) {
        return null;
      }

      return {
        ...microbot,
        ...MicrobotDetails[personality],
        isPrimaryContextDefault: microbot?.isPrimaryContextDefault ?? true,
      } satisfies MicrobotFullInfo;
    });
  },
);

export default function useMicrobot(
  personality: MicrobotPersonality | null | undefined,
  flag: 'required',
): MicrobotFullInfo;
export default function useMicrobot(
  personality: MicrobotPersonality | null | undefined,
  flag?: 'required' | undefined,
): MicrobotFullInfo | undefined;
export default function useMicrobot(
  personality: MicrobotPersonality | null | undefined,
  flag?: 'required' | undefined,
) {
  const microbot =
    useAtomValue(personality ? microbotWithInfoAtoms(personality) : nullAtom) ??
    undefined;

  if (!microbot && flag === 'required') {
    throw new Error(`Missing microbot query result.`);
  }

  return microbot;
}

export function useMicrobotDetailsOf(
  user: UserInfoFragment | null | undefined,
  flag: 'required',
): MicrobotFullInfo;
export function useMicrobotDetailsOf(
  user: UserInfoFragment | null | undefined,
  flag?: 'required' | undefined,
): MicrobotFullInfo | undefined;
export function useMicrobotDetailsOf(
  user: UserInfoFragment | null | undefined,
  flag?: 'required' | undefined,
) {
  return useMicrobot(
    Role.isMicrobot(user) ? user.personality : undefined,
    flag,
  );
}
