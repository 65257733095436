import { molecule } from 'bunshi';

import { nullAtom } from '@advisor/utils/atoms';
import { MilestoneScope, milestoneAtoms } from './milestone';

const MilestoneMolecule = molecule((_mol, scope) => {
  const milestoneId = scope(MilestoneScope);

  if (!milestoneId) {
    return {
      milestoneAtom: nullAtom,
    };
  }

  const milestoneAtom = milestoneAtoms(milestoneId);

  return {
    milestoneAtom,
  };
});

export default MilestoneMolecule;
