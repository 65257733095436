import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useUserQuery } from '@advisor/api/generated/graphql';
import {
  Avatar,
  Layout,
  FlagIcon,
  Skeleton,
  ErrorView,
  DeprecatedButton,
} from '@advisor/design/components';
import { useUserRole } from '@advisor/api/user';
import { LanguageMetadata, useLanguage } from '@advisor/language';
import { useGoBackOrGoHome } from '@advisor/utils/navigation/web';
import { CountryName } from '@advisor/utils/country/internationalDiallingCodes';
import { ChatParams } from 'src/routes/params';
import useInterpolatedUsername from '@advisor/chat/hooks/useInterpolatedUsername';

const StudentInfoModal: React.FC = () => {
  const { userId, chatId } = useParams<ChatParams>();
  const { data, loading, error, refetch } = useUserQuery({
    variables: { userId: userId ?? '', chatRoomId: chatId ?? '' },
  });
  const user = data?.user;

  const { t } = useTranslation([
    'common',
    'user-role',
    'countries',
    'onboarding',
  ]);
  const { isRTL } = useLanguage();
  const goBack = useGoBackOrGoHome(`${chatId}/preferences`);
  const userRole = useUserRole(user) ?? t('user-role:anonymous');

  const username = useInterpolatedUsername(user, chatId);

  if (error) {
    return <ErrorView onTryAgain={refetch} />;
  }

  return (
    <div className="relative overflow-y-scroll">
      {/* Modal header background */}
      <div className="h-32 flex rtl:flex-row-reverse items-end justify-between bg-primary-light rounded-b-3xl absolute top-0 inset-x-0">
        <div className="h-full w-5/12 rounded-tr-[3rem] rounded-bl-3xl bg-primary-light-05" />
        <div className="h-[47.5%] w-1/3 rounded-tl-[3rem] rounded-br-3xl bg-primary-light-05" />
      </div>

      {/* Back button */}
      <div className="mt-7 px-6 relative z-10">
        <DeprecatedButton.Icon
          size={24}
          icon={isRTL ? 'ArrowRight' : 'ArrowLeft'}
          onPress={goBack}
          variant="darkGrey03"
        />
      </div>

      {/* Avatar & role badge */}
      <div className="mt-6 flex justify-center relative">
        <Avatar
          avatarUrl={user?.avatarUrl}
          size={114}
          className="!rounded-[2rem]"
        />
        <div className="rounded bg-primary-light px-3 py-2 absolute font-outfit font-medium text-sm leading-3 bottom-[-0.875rem]">
          {userRole}
        </div>
      </div>
      {loading ? (
        <div className="mx-auto mt-[1.875rem] w-16">
          <Skeleton.Text fontSize={20} lineHeight={28} />
        </div>
      ) : (
        <p className="text-center font-sora text-xl font-bold mt-[1.875rem]">
          {username}
        </p>
      )}

      {/* Basic information */}
      <div className="px-6 pb-6">
        <Layout.Spacer.Vertical size="small02" />
        <p className="text-dark-grey-02 uppercase font-outfit text-xs font-semibold">
          {t('onboarding:country-of-residence')}
        </p>
        {loading ? (
          <ContentSkeleton />
        ) : (
          <div className="flex items-start justify-between">
            <p className="text-dark-grey-03 font-semibold font-outfit text-sm">
              {t(`countries:${user?.country as CountryName}`)}
            </p>
            <FlagIcon country={user?.country ?? ''} />
          </div>
        )}
        <Layout.Spacer.Vertical size="small01" />
        <p className="text-dark-grey-02 uppercase font-outfit text-xs font-semibold">
          {t('common:conversational-language')}
        </p>
        {loading ? (
          <ContentSkeleton />
        ) : (
          <p className="text-dark-grey-03 font-semibold font-outfit text-sm">
            {user && user.language in LanguageMetadata
              ? LanguageMetadata[user.language as keyof typeof LanguageMetadata]
                  .name
              : ''}
          </p>
        )}
        <Layout.Spacer.Vertical size="small01" />
        <div className="bg-grey rounded h-[1px]" />
        <Layout.Spacer.Vertical size="small02" />

        {/* Contact details */}
        <p className="font-sora text-sm font-semibold text-dark-grey-04">
          {t('common:contact-information')}
        </p>
        <Layout.Spacer.Vertical size="tiny" />
        <p className="text-dark-grey-02 uppercase font-outfit text-xs font-semibold">
          {t('common:email-address')}
        </p>
        {loading ? (
          <ContentSkeleton />
        ) : (
          <p className="text-dark-grey-03 font-semibold font-outfit text-sm">
            {user?.email ?? t('common:n-a')}
          </p>
        )}
        <Layout.Spacer.Vertical size="small01" />
        <p className="text-dark-grey-02 uppercase font-outfit text-xs font-semibold">
          {t('common:mobile-number')}
        </p>
        {loading ? (
          <ContentSkeleton />
        ) : (
          <p className="text-dark-grey-03 font-semibold font-outfit text-sm">
            {user?.phoneNumber ?? t('common:n-a')}
          </p>
        )}
      </div>
    </div>
  );
};

export default StudentInfoModal;

const ContentSkeleton: React.FC = () => (
  <Skeleton.Text lineHeight={20} fontSize={14} />
);
