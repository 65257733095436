import cs from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useAuthNavigationGuard } from '@advisor/utils/navigation/web';
import { VideoRoomHistory } from '@advisor/videoCall';
import { Scope } from '@advisor/api/scope';
import { ParamsContext } from '@advisor/videoCall/VideoRoomHistory';
import { type ArchivalVideoRoomInfoFragment } from '@advisor/api/generated/graphql';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import SideModal from './SideModal';

type VideoRoomDetailsParams = 'count';

const VideoRoomHistoryModal = () => {
  const { t } = useTranslation('common');
  const { chatRoomId } = useActiveChatRoom('required');
  const navigate = useNavigate();

  useAuthNavigationGuard(
    Scope.videoChatHistoryAccess(chatRoomId),
    `/chat/${chatRoomId}/preferences`,
  );

  const openDetails = useCallback(
    (room: ArchivalVideoRoomInfoFragment) => {
      navigate(
        `/chat/${room.chatRoomId}/preferences/video-room-history/${room.count}`,
      );
    },
    [navigate],
  );

  const { count } = useParams<VideoRoomDetailsParams>();
  const paramsContext = useMemo(
    () => ({
      detailsId: count !== undefined ? Number(count) : undefined,
    }),
    [count],
  );

  return (
    <ParamsContext.Provider value={paramsContext}>
      {/* Don't remove list from DOM when showing VideoRoomDetails */}
      {/* It is for an animation displaying last selected video room from the list */}
      <div className={cs(count ? 'invisible h-0' : 'contents')}>
        <SideModal.Header backButton>
          <SideModal.Title title={t('video-room-history')} />
        </SideModal.Header>
        <SideModal.Body padding={false}>
          <div className="h-full">
            <VideoRoomHistory
              chatRoomId={chatRoomId}
              openDetails={openDetails}
            />
          </div>
        </SideModal.Body>
      </div>
      <Outlet />
    </ParamsContext.Provider>
  );
};

export default VideoRoomHistoryModal;
