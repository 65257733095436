import z from 'zod';
import { useAtomValue } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { lazyAtom } from '@advisor/utils/atoms';
import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { microbotWithInfoAtoms } from '../hooks/useMicrobot';

const MicrobotArtifacts = z.object({
  PARTNER_SCHOOL_LIST: z.string(),
});

export const microbotArtifactsAtoms = atomFamily(
  (personality: MicrobotPersonality) => {
    return lazyAtom(async (get) => {
      const microbot = await get(microbotWithInfoAtoms(personality));

      if (!microbot) {
        return undefined;
      }

      const result = MicrobotArtifacts.safeParse(
        JSON.parse(microbot.artifacts),
      );

      return result.success ? result.data : undefined;
    });
  },
);

export function useMicrobotArtifacts(personality: MicrobotPersonality) {
  return useAtomValue(microbotArtifactsAtoms(personality));
}
