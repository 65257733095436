import React from 'react';
import cs from 'classnames';

import DeprecatedButton from '../DeprecatedButton';
import { Spacing } from '../../styles';
import Layout from '../Layout';
import type { ModalPopUpHeaderProps } from './types';

const ModalPopUpHeader: React.FC<ModalPopUpHeaderProps> = ({
  title,
  message,
  large,
  onClose,
}) => {
  return (
    <header className="relative">
      <h1
        className={cs(
          'px-10 text-center whitespace-pre-line text-dark-grey-03',
          large
            ? 'pt-12 font-outfit font-semibold text-2xl'
            : 'pt-8 font-sora font-bold text-sm',
        )}
      >
        {title}
      </h1>
      {!large && <Layout.Spacer.Vertical size={16} />}
      {message && (
        <h2
          className={cs(
            'px-10 mb-4 mx-auto max-w-fit font-outfit text-center',
            large ? 'text-base' : 'text-sm',
          )}
        >
          {message}
        </h2>
      )}
      {onClose && (
        <DeprecatedButton.Icon
          icon="X"
          variant="darkGrey01"
          className="absolute top-4 ltr:right-4 rtl:left-4"
          size={Spacing.small02}
          onPress={onClose}
        />
      )}
    </header>
  );
};

export default ModalPopUpHeader;
