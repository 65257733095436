import cs from 'classnames';
import { Trans } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import type { Namespace, ParseKeys } from 'i18next';
import type { _DefaultNamespace } from 'react-i18next/TransWithoutContext';

import type { ToastOptions } from './types';
import Icon from '../Icon';

/**
 * messageI18Key has to be in 'common' namespace and can't use other tags then bold
 */
function Toast<
  K extends ParseKeys<N>,
  N extends Namespace = _DefaultNamespace,
>({ variant, iconName, ...options }: ToastOptions<K, N>) {
  const optionElement = useMemo(() => {
    if ('message' in options) {
      return options.message;
    }

    /* messageI18Key can use `<bold>` tag to mark bold text */
    return (
      <Trans
        ns={options.namespace}
        i18nKey={options.messageI18Key}
        values={options.values}
        components={{ bold: <b /> }}
      />
    );
  }, [options]);

  return (
    <div
      className={cs(
        {
          rose: 'bg-rose-secondary-light border-rose-secondary',
          blue: 'bg-primary-blue-light border-primary-blue',
        }[variant],
        'flex flex-row items-center gap-4 border rounded-10 min-w-[358px] px-3 py-2.5',
      )}
    >
      {iconName && (
        <Icon
          name={iconName}
          className={cs(
            {
              rose: 'text-rose-secondary',
              blue: 'text-primary-blue',
            }[variant],
            'h-6 w-6',
          )}
        />
      )}
      <span className="text-sm text-dark-grey-03">{optionElement}</span>
    </div>
  );
}

export default memo(Toast) as typeof Toast;
