import { atom } from 'jotai';

export enum SidebarTab {
  DirectoryList,
  Conversations,
}

const sidebarTabAtom = atom<SidebarTab>(SidebarTab.Conversations);

export default sidebarTabAtom;
