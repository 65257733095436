import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom, useAtomValue } from 'jotai';

import { Icon } from '@advisor/design/components';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import { searchByNameAtom } from '../atoms';

const ChatRoomRowEmpty: React.FC = () => {
  const searchByName = useAtomValue(searchByNameAtom);
  const { t } = useTranslation('common');

  if (searchByName.length > 0) {
    return (
      <EmptyButton
        title={t('conversation-not-found')}
        description={t(
          'please-make-sure-you-have-entered-the-correct-information',
        )}
      />
    );
  }

  return (
    <EmptyButton
      title={t('no-conversations-yet')}
      description={t(
        'your-conversations-will-appear-here-when-you-have-been-connected',
      )}
    />
  );
};

export default memo(ChatRoomRowEmpty);

type EmptyButtonProps = {
  title: string;
  description: string;
};

const EmptyButton: React.FC<EmptyButtonProps> = (props) => {
  const { title, description } = props;
  const setShowSidebar = useSetAtom(showSidebarAtom);

  return (
    <button
      type="button"
      className="rounded-10 bg-light-grey border-grey border shadow-drop-01 p-4 flex flex-row items-center gap-3 text-start transition-colors hover:border-dark-grey-01"
      onClick={() => setShowSidebar(false)}
    >
      <div className="w-10 h-10">
        <Icon name="MessageAlt" size={40} color="primary" />
      </div>
      <div className="grow text-sm">
        <p className="text-secondary font-sora font-semibold">{title}</p>
        <p className="text-dark-grey-025">{description}</p>
      </div>
    </button>
  );
};
