import React, { ReactNode } from 'react';

const areChildrenEmpty = (children: ReactNode): boolean => {
  if (Array.isArray(children)) {
    return children.every(areChildrenEmpty);
  }

  return !children;
};

const withHideIfEmpty =
  <P extends { children?: ReactNode }>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const { children } = props;

    if (areChildrenEmpty(children)) {
      return null;
    }

    return <Component {...props} />;
  };

export default withHideIfEmpty;
