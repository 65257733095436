import React from 'react';

import { Logo, PlaneFirstType } from '@advisor/ui/Brand';
import { useTheme } from '@advisor/design/Theme';

const AppMaintenance: React.FC = () => {
  const theme = useTheme();

  return (
    <section className="px-6">
      <header className="h-20 flex flex-row justify-center items-center mt-10">
        <div className="w-[280px] h-full">
          <Logo type="platform" fit="stretch" />
        </div>
      </header>
      <div
        className="mt-[120px] mx-auto max-w-[640px] p-5 rounded-3xl text-white relative font-outfit"
        style={{
          backgroundImage: `radial-gradient(at bottom left, ${theme.colors.primary}, ${theme.colors.primaryLight})`,
        }}
      >
        <h3 className="text-3xl">We are under maintenance</h3>
        <PlaneFirstType className="sm:absolute hidden sm:block sm:left-[100px] sm:-top-[120px]" />
        <div style={{ width: '100%', height: '250px' }} />
        <p>
          We are improving our application. <br />
          We should be back shortly. Thank you for your patience.
        </p>
      </div>
    </section>
  );
};

export default AppMaintenance;
