import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Title,
  Subtitle,
  StepIndicator,
  ConfirmButton,
  Form as FormComponent,
} from '@advisor/design/components/LoginComponents';
import { Layout, Form } from '@advisor/design/components';
import CountrySelect from '../CountrySelect';
import TimezoneSelect from '../TimezoneSelect';
import { VerificationState, DetailsStepProps, UserRole } from './types';
import useDetailsStep from './useDetailsStep';

function DetailsStep({ onRedirectToChat }: DetailsStepProps) {
  const { t } = useTranslation(['common', 'onboarding']);

  const {
    errors,
    userProfile,
    submitEnabled,
    needsVerification,

    onSubmit,
    onSetEmail,
    onSetCountry,
    onSetTimezone,
    onSetPhoneNumber,
    onSetAgency,
  } = useDetailsStep(onRedirectToChat);

  return (
    <>
      <Title className="text-center">
        {t('onboarding:welcome-user', { user: userProfile.name })}
      </Title>
      <Subtitle className="text-center font-normal">
        {t('onboarding:sign-up-create-profile-subtitle')}
      </Subtitle>
      <Layout.Spacer.Vertical size="small01" />
      <StepIndicator step={2} ofSteps={2} />
      <Layout.Spacer.Vertical size="iconSize" />
      <CountrySelect value={userProfile.country} onSelect={onSetCountry} />
      <Layout.Spacer.Vertical size="tiny" />
      <TimezoneSelect value={userProfile.timezone} onSelect={onSetTimezone} />
      <Layout.Spacer.Vertical size="tiny" />
      <FormComponent onSubmit={onSubmit}>
        <Form.EmailAddress
          value={userProfile.email}
          onChange={onSetEmail}
          label={t('common:email-address')}
          isVerified={userProfile.emailState === VerificationState.Verified}
          readonly={userProfile.emailState === VerificationState.Verified}
          errorMessage={
            errors.email
              ? t(
                  'onboarding:verification-type-is-already-in-use-with-another-account',
                  {
                    verificationType: t('common:email-address'),
                  },
                )
              : undefined
          }
        />
        <Layout.Spacer.Vertical size="tiny" />
        <Form.PhoneNumber
          value={userProfile.phoneNumber}
          label={t('common:phone-number')}
          onChange={onSetPhoneNumber}
          isVerified={
            userProfile.phoneNumberState === VerificationState.Verified
          }
          readonly={userProfile.phoneNumberState === VerificationState.Verified}
          errorMessage={
            errors.phoneNumber
              ? t(
                  'onboarding:verification-type-is-already-in-use-with-another-account',
                  {
                    verificationType: t('common:phone-number'),
                  },
                )
              : undefined
          }
        />
        {userProfile.role === UserRole.Advisor && (
          <>
            <Layout.Spacer.Vertical size="tiny" />
            <Form.Text
              readonly
              isLighterColor
              value={userProfile.agency}
              label={t('onboarding:organisation')}
              onChange={onSetAgency}
              placeholder={t('onboarding:organisation-placeholder')}
            />
          </>
        )}
        <ConfirmButton className="mx-auto" disabled={!submitEnabled}>
          {needsVerification ? (
            <p className="w-full">{t('common:continue')}</p>
          ) : (
            <p className="w-full">{t('common:lets-go')}</p>
          )}
        </ConfirmButton>
      </FormComponent>
    </>
  );
}

export default memo(DetailsStep);
