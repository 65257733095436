import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { loginAtom, LoginType, LoginState, FlowType } from '@advisor/api/auth';
import { usePhoneForm, PhoneFormState } from '@advisor/chat/hooks/usePhoneForm';
import {
  findCallCodeByCountryName,
  findCallCodeByLanguageTag,
  findCallCodeByTimezoneName,
} from '@advisor/utils/country/callCodeSelection';
import { Layout, DeprecatedButton } from '@advisor/design/components';
import {
  Form,
  Title,
  Subtitle,
  AlertText,
  TermsOfUse,
  ConfirmButton,
  Note,
} from '@advisor/design/components/LoginComponents';
import { useInvitation } from '@advisor/onboarding';
import getTimezone from '@advisor/utils/getTimezone';
import PhoneInput from './PhoneInput';
import InviteDisclosure from './InviteDisclosure';

const PhoneForm: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const [login, setLogin] = useAtom(loginAtom);
  const { inviteDetails } = useInvitation();

  const { state, onSubmit, ...inputProps } = usePhoneForm(() =>
    inviteDetails?.phone
      ? findCallCodeByCountryName(inviteDetails?.country)
      : findCallCodeByTimezoneName(getTimezone()) ??
        findCallCodeByLanguageTag(navigator.language),
  );

  const onSignInWithEmail = () => {
    setLogin((prev) => ({
      state: LoginState.Initial,
      type: LoginType.EmailAddress,
      flowType: prev.flowType,
    }));
  };

  return (
    <Form
      className={cs(login.flowType === FlowType.Delete && 'flex-none')}
      onSubmit={onSubmit}
    >
      <div>
        <Title className="!text-2xl">{t('enter-your-mobile-number')}</Title>
        <Subtitle className="font-normal">
          {login.flowType === FlowType.Delete
            ? t('verify-your-account-before-deleting')
            : t('we-will-send-you-a-verification-code')}
        </Subtitle>
        <Layout.Spacer.Vertical size="small01" />
        <PhoneInput
          disabled={!!inviteDetails?.phone}
          state={state}
          {...inputProps}
        />
        {state === PhoneFormState.Invalid && (
          <AlertText>{t('phone-number-is-invalid')}</AlertText>
        )}
        {state === PhoneFormState.Unavailable && (
          <AlertText>
            {t('phone-number-log-in-is-currently-unavailable')}
          </AlertText>
        )}
        <ConfirmButton disabled={state !== PhoneFormState.Valid}>
          {t('send-verification-code-lower')}
        </ConfirmButton>
        <Layout.Spacer.Vertical size="small01" />
        {((!inviteDetails?.email && !inviteDetails?.phone) ||
          inviteDetails?.email) && (
          <DeprecatedButton.Text
            underline
            weight={700}
            onPress={onSignInWithEmail}
            testID="sign-in-with-email"
          >
            {login.flowType === FlowType.Delete
              ? t('delete-account-with-email')
              : t('sign-in-with-email')}
          </DeprecatedButton.Text>
        )}
      </div>
      <div>
        {inviteDetails?.phone && <InviteDisclosure />}
        {login.flowType === FlowType.Delete ? (
          <>
            <Layout.Spacer.Vertical size="small01" />
            <Note>{t('delete-account-security-note')}</Note>
          </>
        ) : (
          <TermsOfUse />
        )}
      </div>
    </Form>
  );
};

export default memo(PhoneForm);
