import React, { useCallback } from 'react';

import { Modal } from '@advisor/design/components';
import useAccessModal from './useAccessModal';
import { Configure, Confirm, ConversationAccess } from './tabs';
import { AccessModalProps, Tab } from './types';

const AccessModal: React.FC<AccessModalProps> = ({
  visible,
  onClose,
  onConfigure,
  personality,
}) => {
  const { anyRemoved, setAll, onSave, tab } = useAccessModal({
    personality,
    visible,
  });

  const handleConfigure = useCallback(() => {
    onClose();
    onConfigure();
  }, [onClose, onConfigure]);

  const renderTab = useCallback(
    (activeTab: Tab) => {
      if (activeTab === Tab.ConversationAccess) {
        return (
          <ConversationAccess
            anyRemoved={anyRemoved}
            personality={personality}
            onClose={onClose}
            onSave={onSave}
            setAll={setAll}
          />
        );
      }

      if (activeTab === Tab.Configure) {
        return (
          <Configure
            personality={personality}
            onClose={onClose}
            onConfigure={handleConfigure}
          />
        );
      }

      return (
        <Confirm personality={personality} onClose={onClose} onSave={onSave} />
      );
    },
    [anyRemoved, onClose, onSave, handleConfigure, personality, setAll],
  );

  return (
    <Modal.Base
      maxWidth={tab === Tab.ConversationAccess ? 406 : 326}
      visible={visible}
      onClose={onClose}
    >
      {renderTab(tab)}
    </Modal.Base>
  );
};

export default AccessModal;
