import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import { MessageInputMolecule } from '../../hooks/MessageInput';

/**
 * Returns the current timestamp, sampled every `sampleInterval`.
 * @param sampleInterval default 1s
 */
function useNow(sampleInterval: number = 1000) {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const handle = setInterval(() => {
      setNow(Date.now());
    }, sampleInterval);

    return () => {
      clearInterval(handle);
    };
  }, [setNow, sampleInterval]);

  return now;
}

const useTimeoutDisplayMessage = () => {
  const now = useNow();
  const { t } = useTranslation(['common', 'microbot']);
  const { suggestionAtom } = useMolecule(MessageInputMolecule);
  const suggestion = useAtomValue(suggestionAtom);

  return useMemo(() => {
    if (suggestion?.request.status !== 'Loading') {
      return null;
    }

    const requestDuration = now - suggestion.request.startedAt;

    // after 50s
    if (requestDuration > 50000) {
      // Flips every 10s between 0 and 1.
      const toggle = Math.floor(requestDuration / 10000) % 2;
      return toggle
        ? t('microbot:ai-is-taking-longer-to-answer')
        : t('common:you-can-cancel-the-request-anytime');
    }

    // after 30s
    if (requestDuration > 30000) {
      return t('microbot:ai-is-taking-longer-to-answer');
    }

    // after 10s
    if (requestDuration > 10000) {
      return t('common:this-can-take-a-while');
    }

    return t('microbot:loading-alpha-bot-suggestion');
  }, [now, suggestion, t]);
};

export default useTimeoutDisplayMessage;
