/* eslint-disable jsx-a11y/media-has-caption */
import cs from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo, useRef } from 'react';

import { Spacing } from '@advisor/design/styles';
import Advert, {
  AdRequirements,
  useAdvertForPlacement,
} from '@advisor/ui/components/Advert';
import { VIDEO_ROOM_PLACEMENT_KEY } from '@advisor/ui/components/Advert/staticPlacementKeys';
import { useStaticElementSize } from '@advisor/design/hooks';
import { Avatar } from '@advisor/design/components';
import useVolumeAnalysis from '../../useVolumeAnalysis';
import useVideoRoomAdvertLocation, {
  VideoRoomAdvertLocation,
} from '../../useVideoRoomAdvertLocation';
import ParticipantBadge from '../ParticipantBadge';
import { ParticipantTileProps } from './types';
import { useOptimalVideoMediaFit } from './utils';
import { layoutVersionAtom } from './useReflowLayout';

function ParticipantTile({ participant, rounded }: ParticipantTileProps) {
  const videoTrack = useMemo(
    () =>
      participant.joinState === 'Joined'
        ? participant.session.tracks.video
        : undefined,
    [participant],
  );

  const audioTrack = useMemo(
    () =>
      participant.joinState === 'Joined'
        ? participant.session.tracks.audio
        : undefined,
    [participant],
  );

  const isSpeaking = useVolumeAnalysis(audioTrack?.track ?? undefined);
  const videoElementRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoElementRef.current;

    if (!video || !videoTrack?.track) {
      return;
    }

    video.srcObject = new MediaStream([videoTrack.track]);
  }, [videoTrack?.track]);

  const roundedClasses = (() => {
    if (!rounded) {
      return null;
    }

    if (typeof rounded === 'object') {
      return cs(
        rounded.topLeft && 'rounded-tl-xl',
        rounded.topRight && 'rounded-tr-xl',
        rounded.bottomRight && 'rounded-br-xl',
        rounded.bottomLeft && 'rounded-bl-xl',
      );
    }

    return 'rounded-xl';
  })();

  const layoutVersion = useAtomValue(layoutVersionAtom);

  const [[width, height], recalculate] = useStaticElementSize(videoElementRef);
  const objectFit = useOptimalVideoMediaFit(
    participant,
    height > 0 ? width / height : 1,
  );

  useEffect(() => {
    recalculate();
  }, [
    // recalculating on layout version change
    layoutVersion,
    recalculate,
  ]);

  const videoStyle = useMemo(() => {
    return {
      transform: `scale(${
        participant.session.isLocal ? -objectFit.scale : objectFit.scale
      }, ${objectFit.scale})`,
    };
  }, [participant.session.isLocal, objectFit]);

  const shouldDisplayAdvertUnderAvatar =
    useVideoRoomAdvertLocation() === VideoRoomAdvertLocation.UnderAvatar &&
    !participant.session.isLocal;

  const advert = useAdvertForPlacement(
    VIDEO_ROOM_PLACEMENT_KEY,
    AdRequirements.withAsset,
  );

  return (
    <div className={cs('relative flex-1 overflow-hidden', roundedClasses)}>
      <video
        ref={videoElementRef}
        autoPlay
        muted
        playsInline
        className={cs(
          'w-full h-full object-center',
          objectFit.objectFill === 'cover' ? 'object-cover' : 'object-contain',
        )}
        style={videoStyle}
      />
      {!videoTrack?.track && (
        <div
          className={cs(
            'absolute inset-0 flex flex-col justify-start pt-12 md:pt-0 md:justify-center items-center bg-gradient-[-5deg] from-[#101015] to-[#323338]',
          )}
        >
          <Avatar avatarUrl={participant.avatarUrl} size={Spacing.huge} />
          {shouldDisplayAdvertUnderAvatar && advert && (
            <Advert.Banner advert={advert} />
          )}
        </div>
      )}
      <div
        className={cs(
          'absolute bottom-2.5 left-2.5',
          participant.session.isLocal && 'hidden sm:block',
        )}
      >
        <ParticipantBadge participant={participant} isSpeaking={isSpeaking} />
      </div>
      <div
        className={cs(
          'absolute inset-0 border-[3px] border-primary/70',
          roundedClasses,
          isSpeaking ? 'block' : 'hidden',
        )}
      />
    </div>
  );
}

export default ParticipantTile;
