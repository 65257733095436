import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextMenu } from '@advisor/design/components';
import { Colors } from '@advisor/design/styles';
import { useMe } from '@advisor/api/me';
import useDeleteComment from './useDeleteComment';
import { CommentMenuProps } from './types';

const CommentMenu: React.FC<CommentMenuProps> = ({ comment }) => {
  const { t } = useTranslation('task-organiser');
  const me = useMe();

  const isOwnComment = me?.id === comment.author.id;

  const deleteComment = useDeleteComment(comment.id);

  // We check the id for an empty string to make sure that the comment doesn't come from the optimistic response
  if (!isOwnComment || comment.id === '') {
    return null;
  }

  return (
    <ContextMenu.Root triggerTestID="commentDropDownButton">
      <ContextMenu.Item
        iconName="Delete"
        onPress={deleteComment}
        label={t('delete-comment')}
        color={Colors.negative}
        testID="deleteCommentButton"
        destructive
      />
    </ContextMenu.Root>
  );
};

export default CommentMenu;
