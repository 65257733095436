import z from 'zod';

import { UserMetadataLocation } from '@advisor/api/userMetadata';

export const HeaderTooltipLocation = new UserMetadataLocation(
  'addMemory.tooltip.header',
  z.boolean(),
);

export const MemoryBankTooltipLocation = new UserMetadataLocation(
  'addMemory.tooltip.memoryBank',
  z.boolean(),
);
