type Task<T> = {
  execute: () => Promise<T>;
  resolve: (value: T) => void;
  reject: (err: unknown) => void;
};

class TaskQueue {
  private executorRunning = false;

  private queue: Task<unknown>[] = [];

  get running() {
    return this.executorRunning;
  }

  public enqueue<T>(fn: () => Promise<T>): Promise<T> {
    const taskComplete = new Promise<T>((resolve, reject) => {
      const task: Task<T> = {
        execute: fn,
        resolve,
        reject,
      };

      this.queue.push(task as Task<unknown>);

      if (this.executorRunning) {
        return;
      }

      this.executeQueue();
    });

    return taskComplete;
  }

  private async executeQueue() {
    this.executorRunning = true;
    while (this.queue.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const task = this.queue.shift()!;

      try {
        // eslint-disable-next-line no-await-in-loop
        task.resolve(await task.execute());
      } catch (e) {
        task.reject(e);
      }
    }
    this.executorRunning = false;
  }

  get length() {
    return this.queue.length;
  }
}

export default TaskQueue;
