export enum VerticalAlignment {
  Top = 'top',
  Bottom = 'bottom',
}

export enum HorizontalAlignment {
  Left = 'left',
  Right = 'right',
}

export interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface FloatingParentProps {
  children?: React.ReactNode;
}

export interface FloatingContentProps {
  children?: React.ReactNode;
  backdrop?: React.ReactNode;
  alignVertically: VerticalAlignment;
  alignHorizontally: HorizontalAlignment;

  /**
   * Offset of the element.
   * It will be adjusted to fit content entirely in the window.
   *
   * web only, for now
   */
  offset?: { x: number; y: number };

  /**
   * If undefined, the floating element can overflow past the window.
   *
   * If defined, the element can only get that close (in pixels)
   * to the window border.
   */
  overflowBoundsMargin?: number;

  /**
   * Used to determine how floating content will appear and leave.
   */
  transition: 'fade' | 'none';

  /**
   * Should the floating content be visible? This drives the proper transition.
   * @default true
   */
  show?: boolean;
}
