import { useAtomValue } from 'jotai';

import { UserConfigDocument } from '@advisor/api/generated/graphql';
import { lazyAtom } from '@advisor/utils/atoms';
import { atomOfQuery } from '../apollo';

export const userConfigQueryAtom = atomOfQuery(UserConfigDocument);

const userConfigAtom = lazyAtom(async (get) => {
  const query = await get(userConfigQueryAtom);
  return query.data?.userConfig ?? null;
});

const useUserConfig = () => {
  const userConfig = useAtomValue(userConfigAtom);

  return {
    statuses: (userConfig?.chatRoomStatuses ?? []).filter(
      (status) => status.i18nKey !== 'internal-empty-status',
    ),
  };
};

export default useUserConfig;
