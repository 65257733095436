import cs from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  padding?: boolean;
  scroll?: boolean;
  className?: string | boolean;
};

const SideModalBody = (props: Props) => {
  const { children, padding = true, className, scroll = true } = props;
  return (
    <section
      className={cs(
        'relative grow shrink min-h-0',
        padding && 'p-7 xl:p-4 2xl:p-7',
        scroll && 'overflow-y-scroll',
        className,
      )}
    >
      {children}
    </section>
  );
};

export default SideModalBody;
