import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DeprecatedButton, Form, Modal } from '@advisor/design/components';
import { DatePickerTabProps, ModalTab } from './types';
import SwipeTransition from './SwipeTransition';
import useDatePickerTab from './useDatePickerTab';
import { formatTzDifference } from './utils';

function DatePickerTab(props: DatePickerTabProps) {
  const { onClose, activeTab } = props;
  const { t } = useTranslation(['task-organiser', 'common']);

  const {
    studentJourneyOwner,
    minDate,
    selectedDate,
    studentTzDifference,
    handleBack,
    handleSave,
    handleClear,
    handleDateClick,
  } = useDatePickerTab(props);

  return (
    <SwipeTransition direction="right" show={activeTab === ModalTab.DatePicker}>
      <div>
        <Modal.Header
          title={t('task-organiser:date-picker')}
          subtitle={t(
            'task-organiser:the-selected-date-will-be-set-according-to-your-customers-timezone',
          )}
          onBack={handleBack}
          onClose={onClose}
        />
        <div className="px-10 py-6">
          <Form.DatePicker
            minDate={minDate}
            selectedDate={selectedDate}
            onDateClick={handleDateClick}
          />
          {!!studentJourneyOwner && studentTzDifference !== 0 && (
            <p className="text-sm font-medium mt-4">
              <Trans
                ns="task-organiser"
                i18nKey={
                  studentTzDifference >= 0
                    ? 'user-is-hours-ahead-of-you'
                    : 'user-is-hours-behind-of-you'
                }
                components={{ bold: <b /> }}
                values={{
                  user: studentJourneyOwner.member.name,
                  offset: formatTzDifference(studentTzDifference),
                }}
              />
            </p>
          )}
        </div>
        <div className="border border-grey px-8 py-6 gap-6 rounded bg-white flex">
          <DeprecatedButton.Outline
            label={t('common:clear')}
            variant="primary"
            className="flex-1"
            onPress={handleClear}
          />
          <DeprecatedButton.Large
            label={t('common:save')}
            variant="primary"
            className="flex-1"
            onPress={handleSave}
          />
        </div>
      </div>
    </SwipeTransition>
  );
}

export default DatePickerTab;
