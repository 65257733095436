import cs from 'classnames';
import React, {
  useRef,
  useState,
  Dispatch,
  useEffect,
  RefObject,
  useContext,
  createContext,
  SetStateAction,
} from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { SnapListRootProps, SnapListItemProps } from './types';
import styles from './styles.module.css';

type SnapPoints = Array<{ ref: RefObject<HTMLLIElement>; index: number }>;

interface SnapListContextType {
  snapPoints: SnapPoints;
  setSnapPoints: Dispatch<SetStateAction<SnapPoints>>;
}

const SnapListContext = createContext<SnapListContextType>({
  snapPoints: [],
  setSnapPoints: () => {
    return [];
  },
});

/* eslint-disable jsx-a11y/no-static-element-interactions */
export const SnapListRoot: React.FC<SnapListRootProps> = (props) => {
  const { children, activeIndex, className, scrollTo } = props;

  const [snapPoints, setSnapPoints] = useState<SnapPoints>([]);
  const [dragging, setDragging] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const totalDriftRef = useRef(0);

  const handleMouseDown = useEvent(() => {
    setDragging(true);

    totalDriftRef.current = 0;

    function handleMouseUp() {
      setDragging(false);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(ev: MouseEvent) {
      totalDriftRef.current += ev.movementX;

      containerRef.current?.scrollBy({
        left: -ev.movementX,
        behavior: 'instant',
      });
    }

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  });

  const handleClick = useEvent((e: React.MouseEvent<HTMLDivElement>) => {
    if (Math.abs(totalDriftRef.current) > 10) {
      // moved more than 10 pixels, fair to assume we wanted to scroll not click the button.
      e.stopPropagation();
    }
  });

  const handleScrollEnd = useEvent(() => {
    const snapPoint = snapPoints.find(
      (sp) => sp.ref.current?.offsetLeft === containerRef.current?.scrollLeft,
    );

    if (snapPoint?.index !== undefined) {
      scrollTo?.(snapPoint.index);
    }
  });

  useEffect(() => {
    if (activeIndex === undefined) {
      return;
    }

    const snapPoint = snapPoints[activeIndex];

    if (!snapPoint) {
      return;
    }

    if (
      containerRef.current?.scrollLeft !== snapPoint.ref.current?.offsetLeft
    ) {
      containerRef.current?.scrollTo({
        left: snapPoint.ref.current?.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [activeIndex, snapPoints]);

  useEffect(() => {
    const containerNode = containerRef.current;

    containerNode?.addEventListener('scrollend', handleScrollEnd);

    return () => {
      containerNode?.removeEventListener('scrollend', handleScrollEnd);
    };
  }, [handleScrollEnd]);

  return (
    <SnapListContext.Provider value={{ snapPoints, setSnapPoints }}>
      <div
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onClickCapture={handleClick}
        className={cs(
          'overflow-x-auto snap-mandatory',
          styles.snapRoot,
          dragging ? 'snap-none' : 'snap-x',
          className,
        )}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        <ul className="w-max flex h-full">{children}</ul>
      </div>
    </SnapListContext.Provider>
  );
};

export const SnapListItem: React.FC<SnapListItemProps> = (props) => {
  const { setSnapPoints } = useContext(SnapListContext);
  const { index, children, className } = props;

  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (index !== undefined) {
      setSnapPoints((snapPoints) => {
        const newSnapPoints = [...snapPoints];

        newSnapPoints[index] = {
          ref,
          index,
        };

        return newSnapPoints;
      });
    }
  }, [setSnapPoints, index]);

  return (
    <li className={className ?? 'snap-center'} ref={ref}>
      {children}
    </li>
  );
};
