import { noop } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { registerSW } from 'virtual:pwa-register';

import { useEvent } from '@advisor/utils/hooks';
import { useShowModal } from '@advisor/design/components/ActionModal';

const UpdateInterval = 1000 * 60 * 60; // 1 hour

// Mostly based on documentation and examples from
// https://vite-pwa-org.netlify.app/guide/
// https://github.com/vite-pwa/vite-plugin-pwa/tree/main/examples
export default function useAppUpdate() {
  const showModal = useShowModal();
  const { t } = useTranslation('common');
  const updateServiceWorkerRef = useRef<null | ((reload: boolean) => void)>(
    null,
  );

  const askForUpdate = useEvent(async () => {
    const confirmed = await showModal.decide({
      variant: 'neutral',
      dismissible: false,
      title: t('update-available'),
      message: t('an-update-to-platform-is-available'),
      options: [
        { key: 'update', label: t('update'), variant: 'positive' },
        { key: 'cancel', label: t('cancel'), variant: 'neutral' },
      ],
    });

    if (confirmed === 'update') {
      updateServiceWorkerRef.current?.(true);
    }
  });

  useEffect(() => {
    let registration: ServiceWorkerRegistration | undefined;
    let intervalId: NodeJS.Timeout | null = null;
    let swUrl: string | undefined;

    if (updateServiceWorkerRef.current) {
      return noop;
    }

    // Fetches new service worker from CF and forces currently installed to trigger the update
    // (which results in the 'update available' modal to be present to the user)
    // Implementation based on https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
    async function checkForUpdate() {
      if (!registration || !swUrl || !(!registration.installing && navigator)) {
        return;
      }

      if ('connection' in navigator && !navigator.onLine) {
        return;
      }

      const resp = await fetch(swUrl, {
        cache: 'no-cache',
        headers: { 'Cache-Control': 'no-cache', cache: 'no-store' },
      });

      if (resp?.status === 200) {
        await registration.update();
      }
    }

    // Trigger update check when window gains focus.
    // Additionally start update checks every hour in case someone stays
    // on the open tab for long time.
    function startUpdateChecks() {
      if (intervalId === null) {
        intervalId = setInterval(checkForUpdate, UpdateInterval);
      }

      checkForUpdate();
    }

    function stopUpdateChecks() {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    }

    window.addEventListener('focus', startUpdateChecks);
    window.addEventListener('blur', stopUpdateChecks);

    updateServiceWorkerRef.current = registerSW({
      onRegisteredSW(url, reg) {
        if (!reg || !url) {
          return;
        }

        registration = reg;
        swUrl = url;
      },
      onNeedRefresh() {
        askForUpdate();
      },
      onOfflineReady() {
        // 'onOfflineReady' may sometime be called instead of `onNeedRefresh` when using the prompt strategy.
        // (Due to bug in vite-plugin-pwa or service workers itself?)
        // We don't need offline support so just ignore it.
      },
    });

    return () => {
      stopUpdateChecks();

      window.removeEventListener('blur', stopUpdateChecks);
      window.removeEventListener('focus', startUpdateChecks);
    };
  }, [askForUpdate]);
}
