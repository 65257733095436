import React from 'react';

import * as network from '@advisor/utils/network';
import useOnboardingEventsSubscription from './useOnboardingEventsSubscription';

const OnboardingEventProvider: React.FC = () => {
  useOnboardingEventsSubscription();

  return null;
};

export default network.makeOnlineOnly(OnboardingEventProvider);
