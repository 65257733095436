/* eslint-disable import/prefer-default-export */
import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';
import {
  canArchiveChatRoomScope,
  canReassignStudentScope,
} from '../../../UserPreferences';
import { canMarkChatRoomStatusScope } from './markStatus';

export const showChatRoomActionsScope = atomFamily((chatRoomId: string) =>
  Scope.some([
    canMarkChatRoomStatusScope(chatRoomId),
    canReassignStudentScope(chatRoomId),
    canArchiveChatRoomScope(chatRoomId),
  ]),
);
