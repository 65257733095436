import { atomWithObservable } from 'jotai/utils';
import { ApolloClient, Observer } from '@apollo/client';

import { weakAtomFamily } from '@advisor/utils/atoms';

/**
 * Gets incremented when the Apollo client clears the store.
 */
const storeVersionAtom = weakAtomFamily((client: ApolloClient<unknown>) => {
  return atomWithObservable(
    () => {
      let version = 0;

      return {
        subscribe(observer: Observer<number>) {
          return {
            unsubscribe: client.onClearStore(async () => {
              version += 1;
              observer.next?.(version);
            }),
          };
        },
      };
    },
    { initialValue: 0, unstable_timeout: 10000 },
  );
});

export default storeVersionAtom;
