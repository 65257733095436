import { Layout } from '@advisor/design/components';
import Env from '@advisor/api/env';
import {
  Ul,
  Li,
  Link,
  Container,
  PolicyInfo,
  SectionBody,
  PageSubtitle,
  SectionTitle,
  SectionSubtitle,
} from './components';
import PageLayout from './PageLayout';

const Terms = () => {
  return (
    <PageLayout
      title="Software Terms of Service"
      headerTitle={Env.web.termsPageTitle}
    >
      <Container>
        <Layout.Spacer.Vertical size="huge" />
        <PageSubtitle />
        <PolicyInfo>‍Last updated July 25, 2022</PolicyInfo>
        <Layout.Spacer.Vertical size="small01" />
        <SectionTitle>1. License</SectionTitle>
        <Layout.Spacer.Vertical size="small01" />
        <SectionSubtitle>1.1 Governing Agreement.</SectionSubtitle>
        <SectionBody>
          These Terms of Use ("Terms of Use") govern the relationship between
          you and Global Study Technology Limited (hereinafter "Global Study" or
          the “Company” or "Us" or "We") regarding your use of the Company’s
          website and applications (the “Application(s)”), including Student
          Advisor and any other digital products and related services (the
          "Service"). For purposes of these Terms of Use, "Global Study" means
          Global Study Technology Limited located at 25 Silverton Road, London,
          England W6 9NY.
        </SectionBody>

        <SectionSubtitle>1.2 Privacy Policy</SectionSubtitle>
        <SectionBody>
          Use of the Service is also governed by the Company’s Privacy Policy,
          which you can read here:{' '}
          <Link href="/">https://globalstudy.chat/privacy-policy</Link>. Your
          privacy is very important to us. We designed the Company’s Privacy
          Policy to make important disclosures about how we collect and use your
          content and information and how you can use the Service to share such
          information with others.
        </SectionBody>
        <SectionBody>
          We encourage you to read the Global Study Privacy Policy, carefully,
          and use it to help make informed decisions. By creating an account
          (“Account”) or accessing or using the Service you accept and agree to
          be bound by these Terms of Use and consent to the collection, use and
          storage of your information as outlined in Company’s Privacy Policy.
        </SectionBody>
        <SectionSubtitle>
          1.3. Updates to the Terms of Use and Global Study Privacy Policy
        </SectionSubtitle>
        <SectionBody>
          Global Study reserves the right, at our discretion, to change, modify,
          add or remove portions of these Terms of Use and its Privacy Policy at
          any time by posting the amended Terms on the Global Study Service. You
          may also be given additional notice, such as an e-mail message or
          messaging within the Service, of any changes. You will be deemed to
          have accepted such changes by continuing to use the Service. Except as
          otherwise stated, all amended terms shall automatically be effective
          30 days after they are initially posted. Global Study may also revise
          other policies, codes or rules at any time, and the new versions will
          be available on www.globalstudy.ai or in the Service.
        </SectionBody>
        <SectionBody>
          If you initiated a dispute with Global Study before we posted any
          amendment to the Terms of Use or Privacy Policy, the amendments will
          not apply.
        </SectionBody>
        <SectionBody>
          If at any point you do not agree to any portion of the current version
          of our Terms of Use, the Global Study Privacy Policy, or any other
          Global Study policy, rules or codes of conduct relating to your use of
          the Service, your right to use the Service shall immediately
          terminate, and you must immediately stop using the Service. To the
          extent the Terms of Use or Global Study Privacy Policy conflict with
          any other Global Study terms, policy, rules or codes of conduct, the
          terms contained in these Terms of Use and in the Global Study Privacy
          Policy shall take precedence.
        </SectionBody>
        <SectionSubtitle>
          1.4. Grant of a Limited License to Use the Service
        </SectionSubtitle>
        <SectionBody>
          Subject to your agreement and continuing compliance with these Terms
          of Use and any other relevant Global Study policies, Global Study
          grants you a non-exclusive, non-transferable, revocable limited
          license subject to the limitations in Sections 1.5-1.9 to access and
          use the Service using a Global Study supported web browser (such as
          Google Chrome), our downloadable Application or mobile device solely
          for your own personal, non-commercial purposes. You agree not to use
          the Service for any other purpose.
        </SectionBody>
        <SectionSubtitle>1.5. Accessing the Service</SectionSubtitle>
        <SectionBody>
          Before accessing or using the Service, including browsing any Global
          Study website or accessing any of our Applications, you must agree to
          these Terms of Use and the Privacy Policy. You may also be required to
          register an account on the Service (an "Account"), have a valid
          account on a social networking service ("SNS") through which you
          connect to the Service, or have an account with the applications
          provider for your mobile device. If you are between the ages of 13 and
          17, you represent that your legal guardian has reviewed and agreed to
          these Terms. You must provide all equipment and software necessary to
          connect to the Service, including, but not limited to, a mobile device
          that is suitable to connect with and use the Service, in cases where
          the Service offers a mobile component.
        </SectionBody>
        <SectionBody>
          You are responsible for any fees, including Internet connection or
          mobile fees, that you incur when accessing the Service.
        </SectionBody>
        <SectionSubtitle>1.6. Use of the Service</SectionSubtitle>
        <SectionBody>
          The following restrictions apply to the use of the Service:
          <Ul>
            <Li>
              {' '}
              You shall not create an Account or access the Service if you are
              under the age of 13;
            </Li>
            <Li>
              You shall monitor your Account to restrict use by minors, and you
              will deny access to children under the age of 13. You accept full
              responsibility for any unauthorized use of the Service by minors.
              You are responsible for any use of your credit card or other
              payment instrument (e.g. billing to your mobile phone bill) by
              minors;
            </Li>
            <Li>
              You shall not have more than one Account, per platform or SNS, at
              any given time, and shall not create an account using a false
              identity or information, or on behalf of someone other than
              yourself;
            </Li>
            <Li>
              You shall not create an Account or use the Service if you are a
              convicted sex offender;
            </Li>
            <Li>
              You shall not have an Account or use the Service if you have
              previously been removed by Global Study, or previously been banned
              from using the Application;
            </Li>
            <Li>
              You shall use your Account only for non-commercial purposes;
            </Li>
            <Li>
              You shall not use your Account to advertise, or solicit, or
              transmit any commercial advertisements, including chain letters,
              junk e-mail or repetitive messages (spim and spam) to anyone;
            </Li>
            <Li>
              You shall not use your Account to engage in any illegal conduct;
            </Li>
            <Li>
              You shall not rent, lease, sell, trade, gift, bequeath or
              otherwise transfer your Account or any virtual items or virtual
              currency associated with your Account to anyone without Company’s
              written permission;
            </Li>
            <Li>
              You shall not access or use an Account which has been rented,
              leased, sold, traded, gifted, bequeathed, or otherwise transferred
              from the Account creator without Company’s permission; and
            </Li>
            <Li>
              If you access the Service from an SNS you shall comply with its
              Terms of Use/use as well as these Terms of Use.
            </Li>
          </Ul>
        </SectionBody>
        <SectionSubtitle>1.7. Account Information</SectionSubtitle>
        <SectionBody>
          When creating or updating an Account on the Service, you are required
          to provide Global Study with certain personal information, which may
          include your name, birth date, e-mail address, and, in some cases,
          payment information. This information will be held and used in
          accordance with the Company’s Privacy Policy. You agree that you will
          supply accurate and complete information to Global Study, and that you
          will update that information promptly after it changes.
          <br />
          You understand that on our websites and in our Application, your user
          ID number, name, profile picture and other personal information may be
          publicly available and that search engines may index your name and
          profile photo.
        </SectionBody>
        <SectionSubtitle>1.8. Username and Password</SectionSubtitle>
        <SectionBody>
          During the Account creation process in the Application, you will be
          asked to enter your mobile number and may be required to select a
          username and password ("Login Information"). The following rules
          govern the security of your Login Information:
          <Ul>
            <Li>
              You shall not share the Account or the Login Information, nor let
              anyone else access your account or do anything else that might
              jeopardize the security of your Account;
            </Li>
            <Li>
              In the event you become aware of or reasonably suspect any breach
              of security, including any loss, theft, or unauthorized disclosure
              of the Login Information, you must immediately notify Global Study
              and modify your Login Information;
            </Li>
            <Li>
              You are solely responsible for maintaining the confidentiality of
              the Login Information, and you will be responsible for all uses of
              the Login Information, including purchases, whether or not
              authorized by you; and
            </Li>
            <Li>
              You are responsible for anything that happens through your
              Account. Global Study reserves the right to remove or reclaim any
              usernames at any time and for any reason, including but not
              limited to claims by a third party that a username violates the
              third party’s rights.
            </Li>
          </Ul>
        </SectionBody>
        <SectionSubtitle>1.9. License Limitations</SectionSubtitle>
        <SectionBody>
          Any use of the Service in violation of these license limitations
          (“License Limitations”) is strictly prohibited, can result in the
          immediate revocation of your limited license granted by Section 1.4,
          and may subject you to liability for violations of law. ANY ATTEMPT BY
          YOU TO DISRUPT OR INTERFERE WITH THE SERVICE INCLUDING UNDERMINING OR
          MANIPULATING THE LEGITIMATE OPERATION OF ANY COMPANY APPLICATION IS A
          VIOLATION OF COMPANY POLICY AND MAY BE A VIOLATION OF CRIMINAL AND
          CIVIL LAWS.
        </SectionBody>
        <SectionBody>
          You agree that you will not, under any circumstances:
          <br />
          <br />
          a. Engage in any act that Global Study deems to be in conflict with
          the spirit or intent of the Service, including but not limited to
          circumventing or manipulating these Terms, or Application rules and
          policies;
          <br />
          <br />
          b. Make improper use of the Company’s support services, including by
          submitting false abuse reports; or
          <br />
          <br />
          c. Use the Service in connection with any violation of any applicable
          law or regulation, or do anything that promotes the violation of any
          applicable law or regulation.
          <br />
          <br />
          CHEATING AND HACKING - You agree that you will not, under any
          circumstances: d. Use cheats, exploits, automation software, bots,
          hacks, mods or any unauthorized third-party software designed to
          modify or interfere with the Service, the Application or any Global
          Study experience;
          <br />
          <br />
          e. Use the Service in order to design or assist in the design of
          cheats, automation software, bots, hacks, mods or any other
          unauthorized third-party software designed to modify or interfere with
          the Service, the Application or any Global Study experience;
          <br />
          <br />
          f. Without Company’s express written consent, modify or cause to be
          modified any files that are a part of the Service or the Application;
          <br />
          <br />
          g. Disrupt, overburden, or aid or assist in the disruption or
          overburdening of (1) any computer or server used to offer or support
          the Service or any Global Study environment (each a "Server"); or (2)
          the enjoyment of the Service the Application by any other person;
          <br />
          <br />
          h. Institute, assist, or become involved in any type of attack,
          including without limitation distribution of a virus, denial of
          service attacks upon the Service, or other attempts to disrupt the
          Service or any other person’s use or enjoyment of the Service; or
          <br />
          <br />
          i. Attempt to gain unauthorized access to the Service, Accounts
          registered to others or to the computers, Servers, or networks
          connected to the Service by any means other than the user interface
          provided by Global Study, including but not limited to, by
          circumventing or modifying, attempting to circumvent or modify, or
          encouraging or assisting any other person to circumvent or modify, any
          security, technology, device, or software that is part of the Service;
          OFFENSIVE OR INFRINGING CONTENT - You agree that you will not, under
          any circumstances:
          <br />
          <br />
          j. Post any information that is abusive, threatening, obscene,
          defamatory, libelous, or racially, sexually, religiously, or otherwise
          objectionable or offensive;
          <br />
          <br />
          k. Post any information that contains nudity, excessive violence, or
          offensive subject matter or that contains a link to such content;
          <br />
          <br />
          l. Attempt to, or harass, abuse, or harm, or advocate or incite
          harassment, abuse, or harm of another person, group, including Global
          Study employees, including Company’s customer service representatives;
          or
          <br />
          <br />
          m. Make available through the Service any material or information that
          infringes any copyright, trademark, patent, trade secret, right of
          privacy, right of publicity, or other right of any person or entity or
          impersonates any other person, including without limitation a Global
          Study employee; COMMERCIAL ACTIVITY - You agree that you will not,
          under any circumstances:
          <br />
          <br />
          n. Without the Company’s express written consent, use the Service, the
          Application, or any part of the Service, for any commercial purpose,
          including but not limited to, (1) permitting, authorizing, or offering
          use of the Service, the Application, or any part thereof at a cyber
          café, computer gaming center or any other commercial establishment
          provided however that, for clarity, providing the means for users to
          access the Internet is not prohibited by the forgoing, or (2)
          communicating or facilitating any commercial advertisement or
          solicitation;
          <br />
          <br />
          p. Transmit unauthorized communications through the Service, including
          junk mail, chain letters, spam and any materials that promote malware,
          spyware and downloadable items; UNAUTHORIZED USE OR CONNECTION TO THE
          SERVICE - You agree that you will not, under any circumstances:
          <br />
          <br />
          q. Interfere or attempt to interfere with the proper functioning of
          the Service or connect to or use the Service in any way not expressly
          permitted by these Terms of Use;
          <br />
          <br />
          r. Use any unauthorized third-party software that accesses,
          intercepts, "mines", or otherwise collects information from or through
          the Service or that is in transit from or to the Service, including,
          without limitation, any software that reads areas of RAM or streams of
          network traffic used by the Service to store information about any
          Global Study user. Global Study may, at its sole and absolute
          discretion, allow the use of certain third-party user interfaces;
          <br />
          <br />
          s. Intercept, examine or otherwise observe any proprietary
          communications protocol used by a client, a Server, or the Service,
          whether through the use of a network analyzer, packet sniffer or other
          device;
          <br />
          <br />
          t. Make any automated use of the system, or take any action that
          imposes or may impose (in our sole discretion) an unreasonable or
          disproportionately large load on our infrastructure;
          <br />
          <br />
          u. Bypass any robot exclusion headers or other measures we take to
          restrict access to the service or use any software, technology, or
          device to send content or messages, scrape, spider, or crawl the
          Service, or harvest or manipulate data;
          <br />
          <br />
          v. Use, facilitate, create, or maintain any unauthorized connection to
          the Service, including without limitation (1) any connection to any
          unauthorized server that emulates, or attempts to emulate any part of
          the Service, or (2) any connection using programs, tools, or software
          not expressly approved by Global Study;
          <br />
          <br />
          w. Reverse engineer, decompile, disassemble, decipher or otherwise
          attempt to derive the source code for any underlying software or other
          intellectual property used to provide the Service, or to obtain any
          information from the Service using any method not expressly permitted
          by Global Study; or
          <br />
          <br />
          x. Copy, modify or distribute rights or content from any Global Study
          site of the Application, or Company’s copyrights or trademarks or use
          any method to copy or distribute the content of the Service except as
          specifically allowed in these Terms of Use; COLLECTION AND PUBLICATION
          OF PERSONAL INFORMATION - You agree that you will not, under any
          circumstances:
          <br />
          <br />
          y. Solicit or attempt to solicit personal information from other users
          of the Service; or
          <br />
          <br />
          z. Collect, harvest or post anyone’s private information, including
          personally identifiable information (whether in text, image or video
          form), identification documents, or financial information through the
          Service.
          <br />
          <br />
          aa. upload or transmit (or attempt to upload or to transmit), without
          the Company’s express permission, any material that acts as a passive
          or active information collection or transmission mechanism, including,
          without limitation, clear graphics interchange formats ("gifs"), 1x1
          pixels, web bugs, cookies or other similar devices (sometimes referred
          to as "spyware," "passive collection mechanisms" or "pcms").
        </SectionBody>

        <SectionSubtitle>
          1.10. Suspension and Termination of Account and Service
        </SectionSubtitle>
        <SectionBody>
          WITHOUT US LIMITING ANY OTHER REMEDIES THAT MAY BE AVAILABLE TO US,
          GLOBAL STUDY MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS
          OR ACCESS TO GLOBAL STUDY SERVICES OR PORTIONS THEREOF IF YOU ARE, OR
          GLOBAL STUDY SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH ANY OF
          THESE TERMS OF USE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER
          USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU. YOU CAN LOSE YOUR
          USERNAME AND PERSONA AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION,
          AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED ITEMS AND PURCHASED ITEMS
          ASSOCIATED WITH YOUR USE OF THE SERVICE, AND GLOBAL STUDY IS UNDER NO
          OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
        </SectionBody>
        <SectionBody>
          WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR
          TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT
          ACCESS TO OUR SITES, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR
          REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT
          USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING
          RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL
          PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE
          LETTER OR SPIRIT OF OUR TERMS OR POLICIES. WE MAY ALSO IN APPROPRIATE
          CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE
          ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD PARTY
          INTELLECTUAL PROPERTY RIGHTS.
        </SectionBody>
        <SectionBody>
          Global Study reserves the right to stop offering and/or supporting the
          Service or part of the Service at any time, at which point your
          license to use the Service or a part thereof will be automatically
          terminated. In such event, Global Study shall not be required to
          provide refunds, benefits or other compensation to users in connection
          with such discontinued Services.
        </SectionBody>
        <SectionBody>
          Termination of your Account can include disabling your access to the
          Service or any part thereof including any content you submitted or
          others submitted.
        </SectionBody>
        <SectionBody>
          You may cancel any Account registered to you at any time by following
          the instructions on www.GlobalStudy.ai or in the Application.
        </SectionBody>

        <SectionSubtitle>1.11. Ownership</SectionSubtitle>
        <SectionBody>
          1.11.1. The Service (including any Applications, titles, computer
          code, themes, objects, characters, dialogue, catch phrases, concepts,
          artwork, animations, sounds, musical compositions, audio-visual
          effects, methods of operation, moral rights, documentation, character
          profile information, and server software) are copyrighted works owned
          by or licensed to Global Study. Global Study reserves all rights,
          including without limitation, all intellectual property rights or
          other proprietary rights, in connection with the Service.
          <br />
          <br />
          1.11.2. Accounts
          <br />
          YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER
          PROPERTY INTEREST IN THE ACCOUNT, AND YOU ACKNOWLEDGE AND AGREE THAT
          ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND
          INURE TO THE BENEFIT OF GLOBAL STUDY. GLOBAL STUDY RESERVES THE RIGHT
          TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS.
          <br />
          <br />
          1.11.3. Content
          <br />
          Global Study owns, has licensed, or has rights to use all of the
          content that appears in the Service. You agree that you have no right
          or title in any content that appears in the Service, whether those are
          earned or purchased from Global Study, or any other attributes
          associated with an Account or stored on the Service.
          <br />
          <br />
          1.11.4. User Content <br />
          "User Content" means any communications, images, sounds, and all the
          material, data, and information that you upload or transmit through
          the Service, or that other users upload or transmit, including without
          limitation any chat text.
        </SectionBody>
        <SectionBody>
          By transmitting or submitting any User Content while using the
          Service, you affirm, represent and warrant that such transmission or
          submission is (a) accurate and not confidential; (b) not in violation
          of any laws, contractual restrictions or other third party rights, and
          that you have permission from any third party whose personal
          information or intellectual property is comprised in the User Content;
          (c) free of viruses, adware, spyware, worms or other malicious code;
          and (d) you acknowledge and agree that any of your personal
          information within such content will at all times be processed by
          Global Study in accordance with its Privacy Policy.
        </SectionBody>
        <SectionBody>
          You also grant Global Study a perpetual and irrevocable (which means,
          never-ending) (other than as provided below), worldwide, fully paid-up
          and royalty free, non-exclusive, unlimited license, including the
          right to sublicense and assign to third parties, and right to copy,
          reproduce, fix, adapt, modify, improve, translate, reformat, create
          derivative works from, manufacture, introduce into circulation,
          commercialize, publish, distribute, license, sublicense, transfer,
          rent, lease, transmit, publicly display, publicly perform, or provide
          access to electronically, broadcast, communicate to the public by
          telecommunication, display, perform, enter into computer memory, and
          use and practice, in any way now known or in the future discovered,
          your User Content as well as all modified and derivative works
          thereof. To the extent permitted by applicable laws, you hereby waive
          any moral rights you may have in any User Content. The license you
          grant Us to use user posted content (except any content you submit in
          response to Global Study promotions and competition or any other
          content specifically solicited by Global Study) ends when you delete
          your User Content or you close your Account unless your User content
          has been shared with others, and they have not deleted it. However,
          you understand and accept that removed content may persist in back-up
          copies for a reasonable period of time.
        </SectionBody>

        <SectionTitle>2. User Content</SectionTitle>
        <SectionSubtitle>2.1. Content Screening</SectionSubtitle>
        <SectionBody>
          Global Study assumes no responsibility for the conduct of any user
          submitting any User Content, and assumes no responsibility for
          monitoring the Service for inappropriate content or conduct.
          <br />
          We do not, and cannot, pre-screen or monitor all User Content.
          However, at our discretion, our representatives or technology may
          monitor and/or record your interaction with the Service or
          communications (including without limitation chat text) when you are
          using the Service.
          <br />
          By entering into these Terms of Use, you are providing your
          irrevocable consent to such monitoring and recording. You acknowledge
          and agree that you have no expectation of privacy concerning the
          transmission of any User Content, including without limitation chat
          text or voice communications.
          <br />
          If at any time Global Study chooses, in its sole discretion, to
          monitor the Service, Global Study nonetheless assumes no
          responsibility for User Content and assumes no obligation to modify or
          remove any inappropriate User Content. We have the right, but not the
          obligation, in our sole discretion to edit, refuse to post, or remove
          any User Content.
        </SectionBody>

        <SectionSubtitle>
          2.2. Information Use by Other Users of the Service
        </SectionSubtitle>
        <SectionBody>
          2.2.1. Public Discourse
          <br />
          The Service may include various forums, blogs and chat features where
          you can post User Content, including your observations and comments on
          designated topics. Global Study cannot guarantee that other users will
          not use the ideas and information that you share. Therefore, if you
          have an idea or information that you would like to keep confidential
          and/or don’t want others to use, do not post it on the Service. Global
          Study shall have no responsibility to evaluate, use or compensate you
          for any ideas or information you may choose to submit. GLOBAL STUDY IS
          NOT RESPONSIBLE FOR A USER’S MISUSE OR MISAPPROPRIATION OF ANY CONTENT
          OR INFORMATION YOU POST IN ANY FORUMS, BLOGS AND CHAT ROOMS. ‍
        </SectionBody>

        <SectionBody>
          ‍ 2.2.2. Responsible For Your Own Content
          <br />
          You are solely responsible for the information that you post on,
          through or in connection with the Service and that you provide to
          others.
          <br />
          Information, materials, products or services provided by other users
          (for instance, in their profiles) may, in whole or in part, be
          unauthorized, impermissible or otherwise violate these Terms of Use,
          and Global Study assumes no responsibility or liability for this
          material. If you become aware of misuse of the Service by any person,
          please use any "Report Abuse" link provided or contact us at
          support@global.study.
          <br />
          Global Study may reject, refuse to post or delete any User Content for
          any or no reason, including, but not limited to, User Content that in
          the sole judgment of Global Study violates these Terms of Use. Global
          Study reserves the right to limit the storage capacity of User Content
          that you post on, through or in connection with the Service.
        </SectionBody>

        <SectionSubtitle>2.3. Disclosure</SectionSubtitle>
        <SectionBody>
          You expressly agree that We have the right, at all times, and at our
          sole discretion, to disclose any User Content and other information
          (including without limitation chat text, voice communications, IP
          addresses and your personal information): (a) in response to legal
          process (for example, a court order, search warrant or subpoena) when
          We have a good faith belief that the information is required to be
          disclosed in response to legal process; (b) to allow Us to satisfy any
          applicable law, regulation or governmental request; (c) to allow Us to
          enforce these Terms of Use, the Global Study Privacy Policy or any
          other agreement, terms or policy relating to the Service, (d) to
          protect our legal rights and remedies; (e) in other circumstances in
          which We believe the Service is being used in the commission of a
          crime (including exchanging information with other companies and
          organizations for the purposes of fraud protection and credit risk
          reduction) and for the purpose of reporting the same to the
          appropriate authorities; (f) to report any other suspected crime or
          other offensive behavior to the appropriate authorities; (g) when We
          have a good faith belief that there is a threat to the health and/or
          safety of you or another person; or (h) when necessary either to
          protect the rights or property of Global Study, or for Us to render
          the Service you have requested.
        </SectionBody>

        <SectionSubtitle>2.4. User Interactions</SectionSubtitle>
        <SectionBody>
          2.4.1 User Disputes
          <br />
          You are solely responsible for your interactions with other users of
          the Service and any other parties with whom you interact through the
          Service. Global Study reserves the right, but has no obligation, to
          become involved in any way with these disputes.
        </SectionBody>
        <SectionBody>
          2.4.2 Release
          <br />
          If you have a dispute with one or more users, you release us (and our
          officers, directors, agents, subsidiaries, joint ventures and
          employees) from claims, demands and damages (actual and consequential)
          of every kind and nature, known and unknown, arising out of or in any
          way connected with such disputes. If you are a California resident,
          you waive California Civil Code § 1542, which says: ”A general release
          does not extend to claims which the creditor does not know or suspect
          to exist in his favor at the time of executing the release, which if
          known by him must have materials affected his settlement with the
          debtor.“
        </SectionBody>

        <SectionTitle>3. Fees and Purchase Terms</SectionTitle>

        <SectionSubtitle>3.1. Purchases</SectionSubtitle>
        <SectionBody>
          In the event that the Service allows you to purchase various goods or
          services ("Purchases"), ALL PURCHASES MADE THROUGH THE SERVICE ARE
          NON-REFUNDABLE.
          <br />
          The provision of goods and services through the Service is a service
          provided by Global Study that commences immediately upon acceptance by
          Global Study of your purchase. By making such Purchases, you agree and
          accept that Global Study will provide them to you immediately
          following completion of your purchase. If you reside in the European
          Union and you purchase a product or service from Global Study, you
          have the right to withdraw from a purchase within seven calendar days,
          commencing on the day after the date of purchase (the "Cooling Off
          Period"). However, you lose your right of withdrawal if the
          performance of the services begins before the end of the Cooling Off
          Period. Accordingly, please note that if you purchase Virtual Items
          from us, your right of withdrawal is lost as the performance of our
          services begins immediately once your purchase is completed.
        </SectionBody>

        <SectionSubtitle>3.2. Payment of Fees</SectionSubtitle>
        <SectionBody>
          You agree to pay all fees and applicable taxes incurred by you or
          anyone using an Account registered to you. Global Study may revise the
          pricing for the goods and services offered through the Service at any
          time. YOU ACKNOWLEDGE THAT GLOBAL STUDY IS NOT REQUIRED TO PROVIDE A
          REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER
          COMPENSATION FOR UNUSED GOODS OR SERVICES WHEN AN ACCOUNT IS CLOSED,
          WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
        </SectionBody>

        <SectionTitle>4. Third Party Advertising</SectionTitle>

        <SectionSubtitle>4.1. Third Party Advertisements</SectionSubtitle>
        <SectionBody>
          You understand that the Service may feature advertisements from Global
          Study or third parties. The Company’s disclosure of information for
          third party advertising is addressed in Company’s Privacy Policy.
        </SectionBody>

        <SectionSubtitle>
          4.2. Links to Third Party Sites And Dealings With Advertisers
        </SectionSubtitle>
        <SectionBody>
          Global Study may provide links on the Service to third party websites
          or vendors who may invite you to participate in a promotional offer in
          return for receiving optional Services and/or upgrades. Any charges or
          obligations you incur in your dealings with these third parties are
          your responsibility. Global Study makes no representation or warranty
          regarding any content, goods and/or services provided by any third
          party even if linked from our Service, and will not be liable for any
          claim relating to any third-party content, goods and/or services. The
          linked sites are not under the control of Global Study and may collect
          data or solicit personal information from you. Global Study is not
          responsible for their content, business practices or privacy policies,
          or for the collection, use or disclosure of any information those
          sites may collect. Further, the inclusion of any link does not imply
          endorsement of Global Study of these linked sites.
        </SectionBody>

        <SectionTitle>5. Copyright Notices/Complaints</SectionTitle>

        <SectionBody>
          It is the Company’s policy to respond to notices of alleged copyright
          infringement that comply with the U.S. Digital Millennium Copyright
          Act. Global Study reserves the right to terminate without notice any
          User's access to the Service if that User is determined by Global
          Study to be a "repeat infringer." In addition, Global Study
          accommodates and does not interfere with standard technical measures
          used by copyright owners to protect their materials.
        </SectionBody>

        <SectionTitle>6. Updates to the Service</SectionTitle>

        <SectionBody>
          You understand that the Service is an evolving one. Global Study may
          require that you accept updates to the Service and to Company’s
          Applications you have installed on your mobile device or computer. You
          acknowledge and agree that Global Study may update the Service, with
          or without notifying you. You may need to update third party software
          from time to time in order to receive the Service.
        </SectionBody>

        <SectionTitle>
          7. Disclaimers / Limitations / Waivers / Indemnification
        </SectionTitle>

        <SectionSubtitle>7.1. DISCLAIMER OF WARRANTIES</SectionSubtitle>
        <SectionBody>
          YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK AND
          IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE
          OR IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
          FOR A PARTICULAR PURPOSE (EXCEPT ONLY TO THE EXTENT PROHIBITED UNDER
          APPLICABLE LAW WITH ANY LEGALLY REQUIRED WARRANTY PERIOD THE SHORTER
          OF THIRTY DAYS FROM FIRST USE OR THE MINIMUM PERIOD REQUIRED).
        </SectionBody>

        <SectionBody>
          WITHOUT LIMITING THE FOREGOING, NEITHER GLOBAL STUDY NOR ITS
          AFFILIATES OR SUBSIDIARIES, OR ANY OF THEIR DIRECTORS, EMPLOYEES,
          AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS, DISTRIBUTORS,
          LICENSEES OR LICENSORS (COLLECTIVELY, "GLOBAL STUDY PARTIES") WARRANT
          THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
        </SectionBody>

        <SectionSubtitle>
          7.2. LIMITATIONS; WAIVERS OF LIABILITY
        </SectionSubtitle>
        <SectionBody>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY ANY
          APPLICABLE LAW, THE DISCLAIMERS OF LIABILITY CONTAINED HEREIN APPLY TO
          ANY AND ALL DAMAGES OR INJURY WHATSOEVER CAUSED BY OR RELATED TO USE
          OF, OR INABILITY TO USE, THE SERVICE UNDER ANY CAUSE OR ACTION
          WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS
          FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING
          NEGLIGENCE) AND THAT THE GLOBAL STUDY PARTIES SHALL NOT BE LIABLE FOR
          ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES
          IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE,
          THE SERVICE.
        </SectionBody>
        <SectionBody>
          YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT THE GLOBAL STUDY PARTIES ARE
          NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD THE GLOBAL STUDY PARTIES
          LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE
          SERVICE AND OPERATORS OF EXTERNAL SITES, AND THAT THE RISK OF THE
          SERVICE AND EXTERNAL SITES AND OF INJURY FROM THE FOREGOING RESTS
          ENTIRELY WITH YOU.
          <br />
          UNDER NO CIRCUMSTANCES WILL THE GLOBAL STUDY PARTIES BE LIABLE TO YOU
          FOR MORE THAN THE AMOUNT YOU HAVE PAID GLOBAL STUDY IN THE ONE HUNDRED
          AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU
          FIRST ASSERT ANY SUCH CLAIM.
        </SectionBody>
        <SectionBody>
          YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID GLOBAL STUDY ANY
          AMOUNTS IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY
          PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE
          AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH GLOBAL STUDY IS TO STOP
          USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.
        </SectionBody>
        <SectionBody>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
          THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES.
          THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THE SECTION MAY NOT APPLY
          TO YOU.
        </SectionBody>

        <SectionSubtitle>7.3. Indemnification</SectionSubtitle>
        <SectionBody>
          You agree to indemnify, save, and hold Global Study, its affiliated
          companies, contractors, employees, agents and its third-party
          suppliers, licensors, and partners harmless from any claims, losses,
          damages, liabilities, including legal fees and expenses, arising out
          of your use or misuse of the Service, any violation by you of these
          Terms of Use, or any breach of the representations, warranties, and
          covenants made by you herein. Global Study reserves the right, at Your
          expense, to assume the exclusive defense and control of any matter for
          which you are required to indemnify Global Study, and you agree to
          cooperate with Company’s defense of these claims. Global Study will
          use reasonable efforts to notify you of any such claim, action, or
          proceeding upon becoming aware of it. You agree that the provisions in
          this paragraph will survive any termination of your Account(s) or of
          the Service.
        </SectionBody>

        <SectionTitle>Dispute Resolution</SectionTitle>

        <SectionSubtitle>8.1. General</SectionSubtitle>
        <SectionBody>
          If a dispute arises between you and Global Study, our goal is to
          provide you with a neutral and cost-effective means of resolving the
          dispute quickly. Accordingly, you and Global Study agree that we will
          resolve any claim or controversy at law or equity that arises out of
          this Agreement or our services (a "Claim") in accordance with one of
          the subsections below or as we and you otherwise agree in writing.
          Before resorting to these alternatives, we strongly encourage you to
          first contact us directly to seek a resolution by emailing to
          support@global.study.
        </SectionBody>

        <SectionSubtitle>8.2. Law and Forum for Legal Disputes</SectionSubtitle>
        <SectionBody>
          This Agreement and any dispute arising out of or related to it or the
          Service shall be governed in all respects by the laws of England and
          Wales, without regard to conflict of law provisions. You agree that
          any claim or dispute you may have against Global Study must be
          resolved exclusively by a court located in London, England, except as
          otherwise agreed by the parties. You agree to submit to the personal
          jurisdiction of the courts located within London, England for the
          purpose of litigating all such claims or disputes.
        </SectionBody>

        <SectionSubtitle>8.3. Improperly Filed Claims</SectionSubtitle>
        <SectionBody>
          All claims you bring against Global Study must be resolved in
          accordance with this Legal Disputes Section. All claims filed or
          brought contrary to Section 8.2 shall be considered improperly filed.
          Should you file a claim contrary to Section 8.2, Global Study shall be
          entitled to recover attorneys' fees and costs up to $1,000, provided
          that Global Study has notified you in writing of the improperly filed
          claim, and you have failed to promptly withdraw the claim.
        </SectionBody>

        <SectionTitle>9. Severability</SectionTitle>
        <SectionBody>
          You and Global Study agree that if any portion of these Terms of Use
          or of the Global Study Privacy Policy is found illegal or
          unenforceable, in whole or in part by any court of competent
          jurisdiction, such provision shall, as to such jurisdiction, be
          ineffective solely to the extent of such determination of invalidity
          or unenforceability without affecting the validity or enforceability
          thereof in any other manner or jurisdiction and without affecting the
          remaining provisions of the Terms, which shall continue to be in full
          force and effect.
        </SectionBody>

        <SectionTitle>10. General Provisions</SectionTitle>

        <SectionSubtitle>10.1. Assignment</SectionSubtitle>
        <SectionBody>
          Global Study may assign or delegate these Terms of Use and/or the
          Global Study Privacy Policy, in whole or in part, to any person or
          entity at any time with or without your consent. You may not assign or
          delegate any rights or obligations under the Terms of Use or Privacy
          Policy without Company’s prior written consent, and any unauthorized
          assignment and delegation by you is ineffective.
        </SectionBody>

        <SectionSubtitle>10.2. Supplemental Policies</SectionSubtitle>
        <SectionBody>
          Global Study may publish additional policies related to specific
          services such as forums, contests or loyalty programs (the
          “Supplemental Policies”). Your right to use such services is subject
          to those specific policies and these Terms of Use.
        </SectionBody>

        <SectionSubtitle>10.3. Entire Agreement</SectionSubtitle>
        <SectionBody>
          These Terms of Use, any Supplemental Policies and any documents
          expressly incorporated by reference herein (including the Global Study
          Privacy Policy) all of which may be amended from time to time, contain
          the entire understanding of you and Global Study, and supersede all
          prior understandings of the parties hereto relating to the subject
          matter hereof, whether electronic, oral or written, or whether
          established by custom, practice, policy or precedent, between you and
          us with respect to the Service.
        </SectionBody>

        <SectionSubtitle>10.4. No Waiver</SectionSubtitle>
        <SectionBody>
          The failure of Global Study to require or enforce strict performance
          by you of any provision of these Terms of Use or the Global Study
          Privacy Policy or failure to exercise any right under them shall not
          be construed as a waiver or relinquishment of Company’s right to
          assert or rely upon any such provision or right in that or any other
          instance.
          <br />
          The express waiver by Global Study of any provision, condition, or
          requirement of these Terms of Use or the Global Study Privacy Policy
          shall not constitute a waiver of any future obligation to comply with
          such provision, condition or requirement.
        </SectionBody>

        <SectionBody>
          Except as expressly and specifically set forth in this these Terms of
          Use, no representations, statements, consents, waivers, or other acts
          or omissions by Global Study shall be deemed a modification of these
          Terms of Use nor legally binding, unless documented in physical
          writing, hand signed by You and a duly appointed officer of Global
          Study.
        </SectionBody>

        <SectionSubtitle>‍10.5. Notices</SectionSubtitle>
        <SectionBody>
          We may notify you via postings on www.GlobalStudy.ai, and via e-mail
          or any other communications means to contact information you provide
          to us. All notices given by you or required from you under these Terms
          of Use or the Global Study Privacy Policy shall be in writing and
          addressed to: Global Study Technology Limited. Attn: LEGAL, 25
          Silverton Road, London, England W6 9NY, or to legal@global.study. Any
          notices that you provide without compliance with this Section on
          Notices shall have no legal effect.
        </SectionBody>

        <SectionSubtitle>10.6. Equitable Remedies</SectionSubtitle>
        <SectionBody>
          You acknowledge that the rights granted and obligations made under
          these Terms of Use to Global Study are of a unique and irreplaceable
          nature, the loss of which shall irreparably harm Global Study and
          which cannot be replaced by monetary damages alone so that Global
          Study shall be entitled to injunctive or other equitable relief
          (without the obligations of posting any bond or surety or proof of
          damages) in the event of any breach or anticipatory breach by you.
        </SectionBody>

        <SectionBody>
          You irrevocably waive all rights to seek injunctive or other equitable
          relief, or to enjoin or restrain the operation of the Service,
          exploitation of any advertising or other materials issued in
          connection therewith, or exploitation of the Service or any content or
          other material used or displayed through the Service and agree to
          limit your claims to claims for monetary damages, limited by Section
          7.2 (if any).
        </SectionBody>

        <SectionSubtitle>10.7. Force Majeure</SectionSubtitle>
        <SectionBody>
          Global Study shall not be liable for any delay or failure to perform
          resulting from causes outside the reasonable control of Global Study,
          including without limitation any failure to perform hereunder due to
          unforeseen circumstances or cause beyond Company’s control such as
          acts of God, war, terrorism, riots, embargoes, acts of civil or
          military authorities, fire, floods, accidents, strikes, or shortages
          of transportation facilities, fuel, energy, labor or materials.
        </SectionBody>
      </Container>
    </PageLayout>
  );
};

export default Terms;
