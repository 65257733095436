import React from 'react';
import cs from 'classnames';
import { DateButtonProps } from './types';

const DateButton: React.FC<DateButtonProps> = (props) => {
  const { date, dayInMonth, isInMonth, isSelected, isDisabled, onDateClick } =
    props;

  return (
    <div className="flex-1">
      <button
        type="button"
        onClick={() => onDateClick?.(date)}
        disabled={isDisabled || !isInMonth}
        className={cs(
          'h-8 w-7 rounded-lg transition-colors',
          !isInMonth && 'invisible',
          isSelected && 'text-primary font-semibold bg-primary-light',
          isDisabled
            ? 'text-dark-grey-01'
            : 'hover:bg-primary-light/50 hover:text-primary',
        )}
      >
        {dayInMonth}
      </button>
    </div>
  );
};

export default DateButton;
