import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmIAdvisor } from '@advisor/api/me';
import { DeprecatedButton, Layout } from '@advisor/design/components';
import { StepProps } from './types';

const ConfirmStep: React.FC<StepProps> = (props) => {
  const {
    milestone,
    onCloseModal,
    onCompleteMilestone,
    onIncompleteMilestone,
  } = props;

  const { t } = useTranslation(['common', 'task-organiser']);
  const amIAdvisor = useAmIAdvisor();

  const showIncompleteButton = amIAdvisor && !!milestone?.pendingReview;

  return (
    <div className="flex flex-col items-center px-10 py-8">
      <h4 className="font-sora font-bold text-base text-center mb-3">
        {t('task-organiser:complete-task')}
      </h4>
      <p className="text-dark-grey-02 font-medium text-sm text-center mb-6">
        {amIAdvisor
          ? t(
              'task-organiser:task-will-be-marked-complete-for-both-you-and-the-customer',
            )
          : t('task-organiser:your-host-will-receive-a-request-to-review')}
      </p>
      <DeprecatedButton.Large
        variant="positiveLight"
        onPress={onCompleteMilestone}
      >
        {t('task-organiser:complete-task')}
      </DeprecatedButton.Large>
      <Layout.Spacer.Vertical size={16} />
      {showIncompleteButton ? (
        <DeprecatedButton.Large
          variant="negative"
          outline
          onPress={onIncompleteMilestone}
        >
          {t('task-organiser:mark-as-incomplete')}
        </DeprecatedButton.Large>
      ) : (
        <DeprecatedButton.Large
          variant="darkGrey03"
          outline
          onPress={onCloseModal}
        >
          {t('common:cancel')}
        </DeprecatedButton.Large>
      )}
    </div>
  );
};

export default memo(ConfirmStep);
