import { ApolloClient } from '@apollo/client';

import {
  FileCategory,
  PrivateFileScope,
  FileSource,
  FileInfoFragment,
} from '../generated/graphql';

export type FileVisibility = 'Public' | PrivateFileScope;

export type FileUploadOptions = {
  ['Public']: {
    fileCategory?: FileCategory;
    sizeThreshold?: number; // Size in MB
  };
  [PrivateFileScope.Agency]: {
    fileSource: FileSource;
    sizeThreshold?: number; // Size in MB
  };
  [PrivateFileScope.ChatRoom]: {
    chatRoomId: string;
    fileSource: FileSource;
    sizeThreshold?: number; // Size in MB
  };
  [PrivateFileScope.User]: {
    chatRoomId: string;
    fileSource: FileSource;
    sizeThreshold?: number; // Size in MB
  };
};

/**
 * In RN we can pass { uri, name, type } to FormData,
 * On web we can only use Blob object
 */

export interface RNFileData {
  uri: string;
  name: string;
  type: string;
  size: number;
}

export type FileData = File | RNFileData;

export type FileUploadProcess = {
  file: FileData;
  promise: Promise<ResultData<FileInfoFragment> | ResultError>;
  cancel: () => void;
};

export interface S3RequestOptions {
  /** Unique id in Advisor backed */
  fileId: string;
  /** Url to which the file can be uploaded */
  uploadUrl: string;
  /** Fields passed to upload data */
  formDataFields: Record<string, string>;
}

export interface S3RequestResponse {
  /** Url of the uploaded file */
  fileUrl: string;
}

export enum UploadErrorType {
  FileTooLarge = 'file-too-large',
  UploadDenied = 'upload-denied',
  XMLHttpRequestError = 'xml-http-request-error',
  UploadFailed = 'upload-failed',
  UploadCanceled = 'upload-canceled',
}

export type ResultData<T> = { ok: true; data: T };
export type ResultError = { ok?: false; error: UploadErrorType };

export type DataOrError<T> = ResultData<T> | ResultError;

export type UploadRequestFunction<T extends FileVisibility> = (
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions[T],
  signal?: AbortSignal,
) => Promise<DataOrError<S3RequestOptions>>;
