import React from 'react';
import cs from 'classnames';
import styles from './styles.module.css';

import { LoaderProps } from './types';

function Loader({ className }: LoaderProps) {
  return (
    <div className={cs(styles.container, className)}>
      <div className={styles.circle} />
      <div className={cs(styles.dot, styles.dotHorizontal)} />
      <div
        className={cs(styles.dot, styles.dotHorizontal, styles.dotDelayed)}
      />
      <div className={cs(styles.dot, styles.dotVertical)} />
      <div className={cs(styles.dot, styles.dotVertical, styles.dotDelayed)} />
    </div>
  );
}

export default Loader;
