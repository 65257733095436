import { noop } from 'lodash-es';
import { useLayoutEffect, useRef } from 'react';
import { ObservableQuery, OperationVariables } from '@apollo/client';

import { DocumentNode } from '@advisor/api/generated/graphql';
import Sentry from '../Sentry';
import useEvent from './useEvent';
import useDeepMemo from './useDeepMemo';

export interface SubscriptionInfo<Subscription> {
  subscriptionData: {
    data: Subscription;
  };
}

export type UpdateQuery<Query, Subscription> = (
  prev: Query,
  subscriptionInfo: SubscriptionInfo<Subscription>,
) => Query;

type SubscriptionHandle = { unsubscribe: () => void };

export default function useMoreSubscription<
  Query,
  Subscription,
  SubscriptionVariables extends OperationVariables = Record<string, unknown>,
>(
  document: DocumentNode<Subscription, SubscriptionVariables>,
  subscribeToMore: ObservableQuery<Query>['subscribeToMore'],
  updateQuery: UpdateQuery<Query, Subscription>,
  variables?: SubscriptionVariables,
  enabled = true,
) {
  const stableUpdateQuery = useEvent(updateQuery);
  const stableVariables = useDeepMemo(variables);

  const subscriptionRef = useRef<SubscriptionHandle | null>(null);
  const destroyTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useLayoutEffect(() => {
    if (!enabled) {
      return noop;
    }

    if (destroyTimeoutRef.current !== null) {
      // Do not want to destroy the active subscription actually, my bad.
      clearTimeout(destroyTimeoutRef.current);
    }

    if (!subscriptionRef.current) {
      subscriptionRef.current = {
        unsubscribe: subscribeToMore({
          document,
          variables: stableVariables,
          updateQuery: stableUpdateQuery,
          onError: Sentry.captureException.bind(Sentry),
        }),
      };
    }

    return () => {
      destroyTimeoutRef.current = setTimeout(() => {
        subscriptionRef.current?.unsubscribe();
        subscriptionRef.current = null;
        destroyTimeoutRef.current = null;
      }, 500);
    };
  }, [stableVariables, subscribeToMore, document, stableUpdateQuery, enabled]);
}
