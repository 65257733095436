import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, SpinnerIcon } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { JoinRequestBannerContentProps } from './types';

const JoinRequestBannerContent = (props: JoinRequestBannerContentProps) => {
  const { onApprove, onDecline, requestor, isProcessing } = props;

  const { t } = useTranslation(['common', 'onboarding']);

  return (
    <div>
      <p className="md:hidden text-sm font-semibold mb-3 basis-full">
        {t('onboarding:new-join-request-to-this-conversation')}
      </p>
      <div className="flex items-center justify-between md:w-chat-width md:mx-auto">
        <div className="flex items-center gap-2 overflow-hidden">
          <Avatar
            bordered={false}
            avatarUrl={requestor.avatarUrl}
            size={Spacing.small02}
            className="min-w-[2rem]"
          />
          <div className="font-outfit text-dark-grey-03 text-sm font-medium">
            <p className="hidden md:block text-left rtl:text-right">
              {t('onboarding:join-request-guest-customer')}
            </p>
            <p className="hidden md:block">
              <span className="font-bold truncate">{requestor.name}</span> (
              {requestor.email ?? t('common:n-a')})
            </p>
            <p className="md:hidden text-sm font-bold">{requestor.name}</p>
            <p className="md:hidden text-xs font-medium truncate">
              {requestor.email ?? t('common:n-a')}
            </p>
          </div>
        </div>
        {isProcessing ? (
          <SpinnerIcon className="w-6 h-6 text-dark-grey-03" />
        ) : (
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="py-2 px-3 font-bold font-outfit text-xs bg-white text-primary-blue rounded-lg hover:bg-off-white active:bg-off-white"
              onClick={onApprove}
            >
              {t('onboarding:approve')}
            </button>
            <button
              type="button"
              className="py-2 px-3 font-bold font-outfit text-xs bg-white text-negative rounded-lg hover:bg-off-white active:bg-off-white"
              onClick={onDecline}
            >
              {t('onboarding:decline')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JoinRequestBannerContent;
