/* eslint import/prefer-default-export:0 */
import { findLastIndex } from 'lodash-es';

export const charLimit = 5000;

function split(str: string, at: number) {
  return [str.slice(0, at), str.slice(at)];
}

function findBreakPoint(str: string): number {
  let idx = findLastIndex(str, (char) => char === '\n');

  if (idx !== -1) {
    return idx;
  }

  idx = findLastIndex(str, (char) => char === '.');

  if (idx !== -1) {
    return idx;
  }

  idx = findLastIndex(str, (char) => /\S/.test(char));

  if (idx !== -1) {
    return idx;
  }

  return Math.min(charLimit, str.length);
}

export function splitOutgoingMessage(inMsg: string): string[] {
  const chunks: string[] = [];
  let procStr = inMsg.trim();

  if (procStr.length <= charLimit) {
    return [procStr];
  }

  while (procStr.length > 0) {
    if (procStr.length < charLimit) {
      chunks.push(procStr);
      procStr = '';
    } else {
      const currentChunk = procStr.slice(0, charLimit);
      const splitIdx = findBreakPoint(currentChunk);

      const [chunk, rest] = split(
        procStr,
        Math.min(splitIdx + 1, procStr.length),
      );

      if (chunk.trim().length > 0) {
        chunks.push(chunk);
      }
      procStr = rest;
    }
  }

  return chunks;
}
