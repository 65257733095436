import { Atom } from 'jotai';

const paramConcatKeyExtractor = (...params: [unknown, ...unknown[]]) =>
  params.join(' ');

/**
 * Same as atomFamily, but atoms are identified based on a key, not searched for based on an equality condition.
 *
 * Keys are compared using `Object.is`. The default key extraction strategy is concatenating
 * all `createAtom` parameters separated by a ' ' character.
 */
function keyedAtomFamily<
  Params extends [unknown, ...unknown[]],
  T extends Atom<unknown>,
>(
  createAtom: (...params: Params) => T,
  keyExtractor: (...params: Params) => unknown = paramConcatKeyExtractor,
) {
  const map = new Map<unknown, T>();

  return (...params: Params) => {
    const key = keyExtractor(...params);

    if (map.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return map.get(key)!;
    }

    const innerAtom = createAtom(...params);

    map.set(key, innerAtom);
    return innerAtom;
  };
}

export default keyedAtomFamily;
