import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChangeEventHandler, memo, useCallback } from 'react';

import {
  PhoneFormState,
  PhoneInputProps,
} from '@advisor/chat/hooks/usePhoneForm';
import CallCodePicker from '../../atoms/CallCodePicker';

function PhoneInput({
  state,
  phoneNumber,
  onPhoneChange,
  disabled,
  ...codePickerProps
}: PhoneInputProps) {
  const { t } = useTranslation('common');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      onPhoneChange(target.value);
    },
    [onPhoneChange],
  );

  return (
    <div
      className={cs(
        'h-12.5 w-[312px] md:w-[342px] relative border flex flex-row items-stretch rounded text-dark-grey-03',
        state === PhoneFormState.Invalid
          ? 'border-negative'
          : 'border-dark-grey-01',
      )}
      dir="ltr"
    >
      <CallCodePicker {...codePickerProps} />
      <input
        id="phone-input"
        data-cy="phone-input"
        className="w-[222px] px-3 text-sm md:text-base focus:outline-none font-bold placeholder:text-grey placeholder:font-normal global-rtl:placeholder:text-right ltr:text-left rtl:text-right"
        autoComplete="tel"
        type="tel"
        aria-label={t('phone-number')}
        placeholder={t('phone-number')}
        value={phoneNumber}
        onChange={onInputChange}
        disabled={disabled}
      />
    </div>
  );
}

export default memo(PhoneInput);
