import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';

import Logger from '@advisor/utils/Logger';
import { useEvent } from '@advisor/utils/hooks';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { useCallState, useCustomEventsListener } from '../VideoRoomAPI';
import { useVideoChat } from '../useVideoChat';
import { recordingConsentStateAtom } from './atoms';
import useRecordingState from './useRecordingState';
import { RecordingConsentState } from './types';

/**
 * Singleton component
 */
function RecordingManager() {
  const { t } = useTranslation('common');
  const callState = useCallState();
  const videoChat = useVideoChat(
    callState?.chatRoomId ?? '',
    !callState?.chatRoomId,
  );
  const showModal = useShowModal();
  const [recordingConsentState, setRecordingConsentState] = useAtom(
    recordingConsentStateAtom,
  );
  const recordingState = useRecordingState();

  const showConsentModal = useEvent(async () => {
    if (recordingConsentState === RecordingConsentState.AWAITING_DECISION) {
      return;
    }

    setRecordingConsentState(RecordingConsentState.AWAITING_DECISION);

    const decision = await showModal.decide({
      title: t('this-meeting-will-now-be-recorded'),
      message: t(
        'by-pressing-continue-you-provide-your-consent-to-have-the-meeting-recorded',
      ),
      options: [
        {
          key: 'continue' as const,
          label: t('continue'),
          variant: 'positive',
        },
        {
          key: 'leave' as const,
          label: t('leave-video-room'),
          variant: 'severe',
        },
      ],
    });

    setRecordingConsentState(
      decision === 'continue'
        ? RecordingConsentState.GIVEN
        : RecordingConsentState.DENIED,
    );
  });

  useCustomEventsListener(
    'mute-before-recording',
    useCallback(
      (_msg, sender) => {
        if (!videoChat) {
          return;
        }

        // Only accept mute requests from the call initiator
        if (sender.metadata.userId !== videoChat.callInitiatorId) {
          Logger.warn(
            `A non-initiator user tried to mute you: ${sender.metadata.userId}`,
          );
          return;
        }

        showConsentModal();
      },
      [videoChat, showConsentModal],
    ),
  );

  useEffect(() => {
    if (
      recordingState?.type === 'recording' &&
      recordingConsentState === RecordingConsentState.NOT_GIVEN_YET
    ) {
      showConsentModal();
    }
  }, [recordingState, recordingConsentState, showConsentModal]);

  return <></>;
}

export default RecordingManager;
