import FloatingParent from './FloatingParent';
import FloatingContent from './FloatingContent';
import { HorizontalAlignment, VerticalAlignment } from './types';

const Floating = {
  Parent: FloatingParent,
  Content: FloatingContent,
  VerticalAlignment,
  HorizontalAlignment,
};

export default Floating;
