import {
  WebAuth,
  Auth0Error,
  Auth0DecodedHash,
  RenewAuthOptions,
  Auth0ParseHashError,
  CrossOriginLoginOptions,
  PasswordlessLoginOptions,
  PasswordlessStartOptions,
} from 'auth0-js';
import { unwrap } from 'jotai/utils';
import { atom, useSetAtom } from 'jotai';
import React, { ReactNode, useEffect, useRef } from 'react';

import { LoginType, loginAtom, AuthContext, FlowType } from '@advisor/api/auth';
import { AuthResult, AuthContextType } from '@advisor/api/auth/types';
import Env from '@advisor/api/env';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { ShowModal } from '@advisor/design/components/ActionModal';
import { translationAtoms } from '@advisor/language';
import authStateAtom from '@advisor/api/auth/authStateAtom';
import identityAtom from 'src/atoms/identityAtom';
import { getCypressState } from 'src/cypressIntegration';
import { areWeInCallback } from '../../routes/Callback';
import { AuthLoginResponse } from './types';

interface ProviderProps {
  children: ReactNode;
}

export const webAuth = new WebAuth(Env.auth0);

async function passwordlessStart(options: PasswordlessStartOptions) {
  return new Promise((resolve, reject) => {
    webAuth.passwordlessStart(options, (error, result) => {
      if (error) {
        reject(error);
      }

      resolve(result);
    });
  });
}

async function passwordlessLogin(options: PasswordlessLoginOptions) {
  return new Promise((resolve, reject) => {
    webAuth.passwordlessLogin(options, (error, result) => {
      if (error) {
        reject(error);
      }

      resolve(result);
    });
  });
}

async function passwordLogin(options: CrossOriginLoginOptions) {
  return new Promise((resolve, reject) => {
    webAuth.login(options, (error, result) => {
      if (error) {
        reject(error);
      }

      resolve(result);
    });
  });
}

async function renewAuthAsync(options: RenewAuthOptions) {
  const cypressSession = getCypressState('session');
  if (cypressSession) {
    return cypressSession;
  }

  return new Promise<AuthLoginResponse>((resolve, reject) => {
    webAuth.renewAuth(options, (error, result) => {
      if (error) {
        reject(error);
      }

      resolve(result);
    });
  });
}

interface ParseHashResult {
  error: Auth0ParseHashError | null;
  authResult: Auth0DecodedHash | null;
}
async function parseHashAsync() {
  return new Promise<ParseHashResult>((resolve) => {
    webAuth.parseHash((error, authResult) => {
      resolve({ error, authResult });
    });
  });
}

function logout(redirectTo?: string) {
  window.location.href = `https://auth.dev.globalstudy.chat/v2/logout?client_id=${
    Env.auth0.clientID
  }&returnTo=${window.location.origin}${redirectTo || ''}`;
}

async function signInWithPassword(
  login: string,
  password: string,
): Promise<AuthResult> {
  try {
    await passwordLogin({
      username: login,
      password,
      realm: 'Username-Password-Authentication',
    });
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
}

const authStartAtom = atom(
  null,
  async (get, _set, phoneOrEmail: string): Promise<AuthResult> => {
    const loginData = get(loginAtom);

    try {
      const isPhoneLogin = loginData.type === LoginType.PhoneNumber;

      await passwordlessStart(
        isPhoneLogin
          ? {
              connection: 'sms',
              send: 'code',
              phoneNumber: phoneOrEmail.replace(/ /g, ''),
            }
          : {
              connection: 'email',
              send: 'code',
              email: phoneOrEmail.toLocaleLowerCase().replace(/ /g, ''),
            },
      );

      return { success: true };
    } catch (e) {
      // @ts-ignore
      if (!e?.description?.includes?.('is not a valid')) {
        Sentry.captureException(e);
      }

      return { success: false, error: e };
    }
  },
);

async function authVerifyPhone(
  phoneNumber: string,
  otp: string,
): Promise<AuthResult> {
  const formattedPhoneNumber = phoneNumber.replace(/ /g, '');

  try {
    if (
      Env.verificationAccount &&
      formattedPhoneNumber === Env.verificationAccount.phoneNumber &&
      otp === Env.verificationAccount.otpCode
    ) {
      return await signInWithPassword(
        Env.verificationAccount.email,
        Env.verificationAccount.password,
      );
    }

    await passwordlessLogin({
      connection: 'sms',
      phoneNumber,
      verificationCode: otp,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
}

async function authVerifyEmail(
  emailAddress: string,
  otp: string,
): Promise<AuthResult> {
  const email = emailAddress.toLocaleLowerCase().replace(/ /g, '');

  try {
    await passwordlessLogin({
      connection: 'email',
      email,
      verificationCode: otp,
    });

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
}

const authVerifyAtom = atom(
  null,
  async (get, _set, otp: string): Promise<AuthResult> => {
    const loginData = get(loginAtom);

    localStorage.setItem(
      'FLOW_TYPE',
      loginData.flowType === FlowType.Delete ? FlowType.Delete : FlowType.Login,
    );

    if (loginData.type === LoginType.PhoneNumber && loginData.phoneNumber) {
      return authVerifyPhone(loginData.phoneNumber, otp);
    }

    if (loginData.type === LoginType.EmailAddress && loginData.emailAddress) {
      return authVerifyEmail(loginData.emailAddress, otp);
    }

    return { success: false, error: new Error('Unrecognized loginData.type') };
  },
);

const sessionAtom = actionAtom(function sessionAtomSetter(
  { get, set },
  authResult: AuthLoginResponse,
) {
  const name: string = authResult.idTokenPayload?.name ?? '';
  const type: LoginType = name.includes('@')
    ? LoginType.EmailAddress
    : LoginType.PhoneNumber;

  const prevAuthState = get(unwrap(authStateAtom));
  set(authStateAtom, {
    accessToken: authResult.accessToken,

    // refresh token should always be null on the web
    refreshToken: authResult.refreshToken,

    // recalculate expiresAt only when accessToken has changed
    expiresAt:
      prevAuthState?.accessToken !== authResult.accessToken
        ? new Date().getTime() + authResult.expiresIn * 1000
        : prevAuthState.expiresAt,
  });

  set(identityAtom, {
    type,
    identity: name,
  });
});

const renewSessionAtom = atom(null, async (_get, set): Promise<AuthResult> => {
  try {
    const authResult = await renewAuthAsync({});
    if (authResult && authResult.accessToken) {
      set(sessionAtom, authResult);
    }

    return { success: true };
  } catch (error) {
    set(authStateAtom, null);
    return { success: false, error };
  }
});

const handleAuthenticationAtom = actionAtom(async ({ get, set }) => {
  const { error, authResult } = await parseHashAsync();

  if (authResult && authResult.accessToken) {
    set(sessionAtom, authResult as AuthLoginResponse);
  } else {
    set(authStateAtom, null);

    if (error) {
      const t = await get(translationAtoms('common'));

      ShowModal.info(
        { get, set },
        {
          title: t('oops-something-went-wrong'),
          theme: 'warning',
          message: '',
          options: [
            { label: t('try-again'), key: 'try-again', variant: 'positive' },
          ],
        },
      );

      Sentry.captureException(error);
    }
  }
});

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const authStart = useSetAtom(authStartAtom);
  const authVerify = useSetAtom(authVerifyAtom);
  const renewSession = useSetAtom(renewSessionAtom);
  const handleAuthentication = useSetAtom(handleAuthenticationAtom);
  const setAuthState = useSetAtom(authStateAtom);
  const renewSessionCalled = useRef(false);

  const context: AuthContextType = {
    logout,
    authStart,
    authVerify,
    renewSession,
    signInWithPassword,
    handleAuthentication,
  };

  useEffect(() => {
    if (renewSessionCalled.current) {
      return;
    }

    renewSessionCalled.current = true;

    // Do not call renewSession on callback page,
    // as it will cause an infinite redirect loop.
    // Note: can't use `useMatch` from react-router-dom
    // here as BrowserRouter is lower in the tree.
    if (areWeInCallback()) {
      if (!/access_token|id_token|error/.test(window.location.hash)) {
        Sentry.captureException(
          new Error(`Invalid auth callback url hash: ${window.location.hash}`),
        );
        return;
      }
      handleAuthentication();
      return;
    }

    renewSession().then((result) => {
      if (result.success) {
        return;
      }

      const error = result.error as Auth0Error;
      // Ignore login_required error as user may not be logged in.
      if (error && error?.error !== 'login_required') {
        Sentry.captureException(error);
      }
    });
  }, [renewSession, setAuthState, handleAuthentication]);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
