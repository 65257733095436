import { toPairs } from 'lodash-es';

import env from '@advisor/api/env';
import type { ToPairs } from '@advisor/utils/typeUtils';
import type { AdvisorInfoFragment } from '@advisor/api/generated/graphql';
import { InvitationData } from '../types';
import {
  EncodeParamsFromInviteLink,
  InviteLinkDefinition,
  instantiateLinkDefinition,
  parseInviteLinkDefinition,
} from './inviteLinkHelpers';

const InviteLinks = {
  agency: {
    baseUrl: 'invite/agency',
    encode: (advisor: AdvisorInfoFragment) =>
      advisor.agency.replace(/\s+/g, '').toLocaleLowerCase(),
  },

  advisor: {
    baseUrl: 'invite/advisor',
    encode: (advisor: AdvisorInfoFragment) => advisor.id,
  },

  /**
   * Legacy invite links, now `invite/advisor` links are generated instead.
   */
  legacy: {
    baseUrl: 'invite/join',
    encode: (advisorId: string) => advisorId,
  },

  student: {
    baseUrl: 'invite/student',
    encode: (studentId: string) => studentId,
  },

  conversation: {
    baseUrl: 'invite/conversation',
    encode: (chatRoomId: string) => chatRoomId,
  },
} satisfies Record<string, InviteLinkDefinition>;

const inviteLinkPairs = toPairs(InviteLinks) as ToPairs<typeof InviteLinks>;

export function createInviteLink<TKey extends keyof typeof InviteLinks>(
  type: TKey,
  ...params: EncodeParamsFromInviteLink<(typeof InviteLinks)[TKey]>
) {
  return instantiateLinkDefinition(
    env.web.originUrl,
    InviteLinks[type],
    params,
  );
}

/**
 * Extracts invitation data from the passed in `url`. Checks against link
 * definitions defined in `InviteLinks`.
 */
export function parseInviteLink(url: string): InvitationData | null {
  const [inviteType, inviteId] =
    inviteLinkPairs
      .map(
        ([key, linkDef]) =>
          [key, parseInviteLinkDefinition(linkDef, url)] as const,
      )
      // Finding the one that parsed `url` correctly (the one that matches)
      .find(([_key, parsedLink]) => parsedLink !== null) ?? [];

  if (!inviteType || !inviteId) {
    return null;
  }

  return {
    inviteType,
    inviteId,
  };
}

export default InviteLinks;
