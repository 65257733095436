/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useMemo, useRef } from 'react';

import { ScreenShareFeedProps } from './types';

function ScreenShareFeed({ participant }: ScreenShareFeedProps) {
  const videoTrack = useMemo(
    () =>
      participant.joinState === 'Joined'
        ? participant.session.tracks.screenVideo
        : undefined,
    [participant],
  );
  const videoElementRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoElementRef.current;

    if (!video || !videoTrack?.track) {
      return;
    }

    video.srcObject = new MediaStream([videoTrack.track]);
  }, [videoTrack?.track]);

  return (
    <div className="relative flex-1 overflow-hidden">
      <video
        ref={videoElementRef}
        autoPlay
        muted
        playsInline
        className="w-full h-full object-center object-contain"
      />
    </div>
  );
}

export default ScreenShareFeed;
