import { AuthLoginResponse } from 'src/context/auth/types';

export type CypressStateMap = {
  session: AuthLoginResponse;
};

export function getCypressState<K extends keyof CypressStateMap>(
  key: K,
): CypressStateMap[K] | null {
  const item = window.localStorage.getItem(key);

  if (item) return JSON.parse(item) as CypressStateMap[K];

  return null;
}
