import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Gender,
  type DefaultSystemMessageData,
} from '@advisor/api/generated/graphql';
import {
  UserName,
  BoldText,
  RichMessage,
  CenteredMessage,
} from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';
import useUserName from '../useUserName';

const GenderToKey = {
  [Gender.Male]: 'you-are-talking-with-user-male',
  [Gender.Female]: 'you-are-talking-with-user-female',
  [Gender.Undefined]: 'you-are-talking-with-user-undefined',
} as const;

const AdvisorMessageInNewConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { chatRoomId, data } = message;
  const { student } = data as DefaultSystemMessageData;
  const studentName = useUserName(student);

  if (!student) {
    return null;
  }

  return (
    <RichMessage users={[student]} chatRoomId={chatRoomId}>
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey={GenderToKey[student.gender]}
        values={{ context: 'female-primary', user: studentName }}
      >
        You’re talking with <UserName user={student} />. They have recently
        joined <BoldText>GS StudentAdvisor</BoldText>.
      </Trans>
    </RichMessage>
  );
};

export default memo(AdvisorMessageInNewConversation);
