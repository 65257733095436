import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useMyId } from '@advisor/api/me';
import Logger from '@advisor/utils/Logger';
import { nullAtom } from '@advisor/utils/atoms';
import { useChatRoom } from '@advisor/api/chatRoom';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import { ChatRoomMemberInfoFragment } from '@advisor/api/generated/graphql';
import {
  useCallState,
  useVideoRoomAPI,
  type CallSession,
  type CallParticipant,
} from '../VideoRoomAPI';

function mapSessionsToMembers(
  meId: string,
  sessions: Record<string, CallSession>,
  members: ChatRoomMemberInfoFragment[],
) {
  const map = new Map<string, CallParticipant>(
    members.map(({ member }) => [
      member.id,
      {
        key: member.id,
        userId: member.id,
        name: member.name,
        __typename: member.__typename,
        avatarUrl: member.avatarUrl ?? undefined,
        role: member.__typename === 'Advisor' ? member.advisorRole : undefined,
        isLocal: member.id === meId,
        joinState: 'WaitingToJoin',
      },
    ]),
  );

  Object.values(sessions).forEach((s) => {
    if (!s.metadata) {
      return;
    }

    const existingParticipant = map.get(s.metadata.userId);

    if (!existingParticipant) {
      Logger.warn(`External participants are not yet supported`);
      return;
    }

    if (
      existingParticipant.joinState === 'Joining' ||
      existingParticipant.joinState === 'Joined'
    ) {
      // More than one session for a user.
      const nonDuplicateKey = `${existingParticipant.userId}${s.sessionId}`;
      map.set(nonDuplicateKey, {
        key: nonDuplicateKey,
        userId: existingParticipant.userId,
        avatarUrl: existingParticipant.avatarUrl,
        joinState: 'Joined',
        session: s,
        name: existingParticipant.name,
        role: existingParticipant.role,
        secondarySession: true,
        __typename: existingParticipant.__typename,
      });
    } else {
      map.set(existingParticipant.key, {
        ...existingParticipant,
        joinState: 'Joined',
        session: s,
      });
    }
  });

  return [...map.values()];
}

function useValidChatRoomMembers() {
  const myId = useMyId();
  const isAuthorized = useIsAuthorized();
  const callState = useCallState();

  const chatRoomCtx = useChatRoom(
    myId && isAuthorized && callState ? callState.chatRoomId : null,
  );
  const members = useAtomValue(chatRoomCtx?.membersAtom ?? nullAtom);

  return useMemo(
    () =>
      (members ?? []).filter(
        // Microbots cannot join video rooms
        ({ member }) => member.__typename !== 'Microbot',
      ),
    [members],
  );
}

function useParticipants() {
  const { sessions } = useVideoRoomAPI();

  const myId = useMyId();
  const members = useValidChatRoomMembers();

  return useMemo(() => {
    if (!myId || !members) {
      return [];
    }

    return mapSessionsToMembers(myId, sessions ?? {}, members);
  }, [myId, sessions, members]);
}

export default useParticipants;
