import { atomWithEvent } from '@advisor/utils/atoms';
import type { ScreenWidthAtom } from './types';

const screenWidthAtom: ScreenWidthAtom = atomWithEvent(
  () => window.innerWidth,
  (listener) => {
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  },
);

export default screenWidthAtom;
