import { atom } from 'jotai';
import { useMolecule } from 'bunshi/react';
import { createScope, molecule } from 'bunshi';

import Sentry from '@advisor/utils/Sentry';

export type ReassignChatRoomScopeType =
  | 'conversations' // mobile
  | 'preferences' // mobile
  | 'global'; // web

export const ReassignChatRoomScope =
  createScope<ReassignChatRoomScopeType | null>(null);

const ReassignChatRoomMolecule = molecule((_mol, scope) => {
  if (scope(ReassignChatRoomScope) === null) {
    Sentry.captureException(
      new Error(`Used a ReassignChatRoomMolecule without a proper scope value`),
    );
  }

  return {
    reassignChatRoomIdAtom: atom<string | null>(null),
  };
});

export function useReassignChatRoomMolecule(
  scopeValue: ReassignChatRoomScopeType,
) {
  return useMolecule(ReassignChatRoomMolecule, {
    withScope: [ReassignChatRoomScope, scopeValue],
  });
}

export default ReassignChatRoomMolecule;
