import { useAtomValue } from 'jotai';

import { useMe } from '@advisor/api/me';
import { nullAtom } from '@advisor/utils/atoms';
import { Member, useChatRoom } from '@advisor/api/chatRoom';

const useChatRoomRepresentative = (chatRoomId?: string | null) => {
  const me = useMe();
  const chatRoomCtx = useChatRoom(chatRoomId ?? null);
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);

  if (!chatRoom) {
    return undefined;
  }

  return Member.chatRoomRepresentative(chatRoom, me);
};

export default useChatRoomRepresentative;
