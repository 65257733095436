import React, { useMemo } from 'react';
import cs from 'classnames';

import { Spacing } from '../../styles';
import { SegmentedGroupSeparatorProps } from './types';

function SegmentedGroupSeparator(props: SegmentedGroupSeparatorProps) {
  const { size } = props;

  const spacing = useMemo(() => {
    if (!size) {
      return '0';
    }

    return `${typeof size === 'number' ? size : Spacing[size]}px`;
  }, [size]);

  return (
    <div
      className={cs('relative border-b border-grey last:hidden')}
      style={{
        marginLeft: spacing,
        marginRight: spacing,
      }}
    />
  );
}

export default SegmentedGroupSeparator;
