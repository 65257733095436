import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import Env from '@advisor/api/env';
import { nonNullable } from '@advisor/utils/typeUtils';
import { setupPlatform, parseDetectedLanguage } from '@advisor/language';

const translationLoaders = import.meta.glob(
  '../../language/generated/**/locales/*/*.json',
);

const getLoader = (language: string, namespace: string) =>
  translationLoaders[
    `../../language/generated/${Env.instanceKey}/locales/${language}/${namespace}.json`
  ] as () => Promise<Record<string, string>>;

const languageDetector = new LanguageDetector(null, {
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'customNavigator',
  ],
});

languageDetector.addDetector({
  name: 'customNavigator',
  lookup() {
    const found: string[] = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i += 1) {
          found.push(navigator.languages[i]);
        }
      }

      // Part of the original navigator detector implementation
      // not sure which browser really use them, so better to keep that :)
      if (
        'userLanguage' in navigator &&
        typeof navigator.userLanguage === 'string'
      ) {
        found.push(navigator.userLanguage);
      }

      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    const parsed = found.map(parseDetectedLanguage).filter(nonNullable);

    return parsed.length > 0 ? parsed : undefined;
  },
});

setupPlatform(
  i18n
    .use(HttpApi)
    .use(languageDetector)
    .use(
      resourcesToBackend((language, namespace, callback) => {
        const loader = getLoader(language, namespace);

        if (loader) {
          loader()
            .then((resource) => {
              callback(null, resource.default ?? resource);
            })
            .catch((error) => {
              callback(error, null);
            });
        } else {
          callback(new Error('Not found'), null);
        }
      }),
    ),
  {
    ns: ['common', 'countries'],
  },
);

export default i18n;
