import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18n, { type TFunction } from 'i18next';

import Sentry from '@advisor/utils/Sentry';
import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const dmyLanguages = ['pl'];

const formatCalendar = (date: string, t: TFunction<'common'>): string => {
  return dayjs(date).calendar(null, {
    nextDay: `[${t('tomorrow')}]`,
    sameDay: `[${t('today')}]`,
    lastDay: `[${t('yesterday')}]`,
    lastWeek: 'dddd',
    nextWeek: 'dddd',
    sameElse: 'LL',
  });
};

export enum CalendarShortFormat {
  MDY,
  DMY,
}

const formatCalendarShort = (
  date: string | Date,
  format?: CalendarShortFormat,
): string => {
  if (
    (dmyLanguages.includes(i18n.language) && !format) ||
    format === CalendarShortFormat.DMY
  ) {
    return dayjs(date).format('DD MMMM YYYY');
  }

  return dayjs(date).format('MMMM DD, YYYY');
};

const formatCalendarTiny = (date: string | Date): string => {
  if (dmyLanguages.includes(i18n.language)) {
    return dayjs(date).format('DD MMM');
  }

  return dayjs(date).format('MMM DD');
};

const formatCalendarLong = (date: string, t: TFunction): string => {
  if (dmyLanguages.includes(i18n.language)) {
    return dayjs(date).calendar(null, {
      sameDay: `[${t('today')}] [${t('at')}] HH:mm`,
      lastDay: `[${t('yesterday')}] [${t('at')}] HH:mm`,
      nextDay: `[${t('tomorrow')}] [${t('at')}] HH:mm`,
      nextWeek: `DD/MMMM/YYYY [${t('at')}] HH:mm`,
      lastWeek: `DD/MMMM/YYYY [${t('at')}] HH:mm`,
      sameElse: `DD/MMMM/YYYY [${t('at')}] HH:mm`,
    });
  }

  return dayjs(date).calendar(null, {
    sameDay: `[${t('today')}] [${t('at')}] h:mm A`,
    lastDay: `[${t('yesterday')}] [${t('at')}] h:mm A`,
    nextDay: `[${t('tomorrow')}] [${t('at')}] h:mm A`,
    nextWeek: `MMMM/DD/YYYY [${t('at')}] h:mm A`,
    lastWeek: `MMMM/DD/YYYY [${t('at')}] h:mm A`,
    sameElse: `MMMM/DD/YYYY [${t('at')}] h:mm A`,
  });
};

const formatDateTime = (dateTime: string, t: TFunction<'common'>): string => {
  if (dmyLanguages.includes(i18n.language)) {
    return dayjs(dateTime).calendar(null, {
      sameDay: `DD MMMM YYYY [${t('at')}] HH:mm`,
      nextDay: `DD MMMM YYYY [${t('at')}] HH:mm`,
      lastDay: `DD MMMM YYYY [${t('at')}] HH:mm`,
      sameElse: `DD MMMM YYYY [${t('at')}] HH:mm`,
      nextWeek: `DD MMMM YYYY [${t('at')}] HH:mm`,
      lastWeek: `DD MMMM YYYY [${t('at')}] HH:mm`,
    });
  }

  return dayjs(dateTime).calendar(null, {
    sameDay: `MMMM DD YYYY [${t('at')}] h:mm A`,
    nextDay: `MMMM DD YYYY [${t('at')}] h:mm A`,
    lastDay: `MMMM DD YYYY [${t('at')}] h:mm A`,
    sameElse: `MMMM DD YYYY [${t('at')}] h:mm A`,
    nextWeek: `MMMM DD YYYY [${t('at')}] h:mm A`,
    lastWeek: `MMMM DD YYYY [${t('at')}] h:mm A`,
  });
};

const formatTime = (date: string) => {
  return dayjs(date).format('LT');
};

const formatRelativeTime = (dateStr: string) => {
  const date = dayjs(dateStr);

  if (dayjs().diff(date, 'years') >= 1) {
    return date.format('MMM DD[, ]YYYY');
  }

  if (dmyLanguages.includes(i18n.language)) {
    return date.calendar(null, {
      sameDay: `LT`,
      nextDay: `DD MMM`,
      lastDay: `DD MMM`,
      lastWeek: 'DD MMM',
      nextWeek: 'DD MMM',
      sameElse: 'DD MMM',
    });
  }

  return date.calendar(null, {
    sameDay: `LT`,
    nextDay: `MMM DD`,
    lastDay: `MMM DD`,
    nextWeek: 'MMM DD',
    lastWeek: 'MMM DD',
    sameElse: 'MMM DD',
  });
};

type FormatDurationParams =
  | {
      start: string | Date;
      end: string | Date;
    }
  | {
      seconds: number;
    };

const formatDuration = (params: FormatDurationParams) => {
  if ('start' in params) {
    const { start, end } = params;
    return dayjs.duration(dayjs(end).diff(start)).format('HH:mm:ss');
  }

  return dayjs.duration(params.seconds, 'seconds').format('HH:mm:ss');
};

const formatTimeElapsed = (date: string | Date) => {
  const diff = dayjs.duration(dayjs().diff(dayjs(date)));
  if (diff.years() > 0) {
    return i18n.t('years-elapsed', { years: diff.years() });
  }
  if (diff.months() > 0) {
    return i18n.t('months-elapsed', { months: diff.months() });
  }
  if (diff.days() > 0) {
    return i18n.t('days-elapsed', { days: diff.days() });
  }
  if (diff.hours() > 0) {
    return i18n.t('hours-elapsed', { hours: diff.hours() });
  }
  if (diff.minutes() > 0) {
    return i18n.t('minutes-elapsed', { minutes: diff.minutes() });
  }
  if (diff.seconds() > 0) {
    return i18n.t('seconds-elapsed', { seconds: diff.seconds() });
  }
  return i18n.t('now');
};

const formatExplicitSign = (number: number) => {
  const formattedNumber = Math.abs(number).toFixed(2).padStart(5, '0');
  return number < 0 ? `-${formattedNumber}` : `+${formattedNumber}`;
};

const formatTimezoneWithGMT = (tz: TimezoneName) => {
  try {
    const now = dayjs.tz(new Date(), tz);
    const GMT = now.utcOffset() / 60;
    const formattedGMT = formatExplicitSign(GMT);
    return `${tz} (GMT${formattedGMT})`;
  } catch (e) {
    // Could be RangeError if the timezone is not in a valid format, e.g. "CET"
    Sentry.captureException(e);
  }

  return tz;
};

export default {
  relativeTime: formatRelativeTime,
  calendarShort: formatCalendarShort,
  calendarTiny: formatCalendarTiny,
  calendarLong: formatCalendarLong,
  calendar: formatCalendar,
  dateTime: formatDateTime,
  duration: formatDuration,
  time: formatTime,
  timeElapsed: formatTimeElapsed,
  timezoneWithGMT: formatTimezoneWithGMT,
};
