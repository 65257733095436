import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';

import { Lazy } from '@advisor/utils/atoms';
import {
  MessageTranslationMolecule,
  ToggleTranslationMolecule,
} from '../../chatLanguage';
import {
  MessageMolecule,
  UserMessageMolecule,
} from '../../messaging/messageMolecule';

export default function useUserMessageApi() {
  const { statusAtom } = useMolecule(MessageTranslationMolecule);
  const { toggleTranslationAtom } = useMolecule(ToggleTranslationMolecule);
  const { isOwnAtom } = useMolecule(MessageMolecule);
  const { messageTextAtom } = useMolecule(UserMessageMolecule);

  const toggleTranslation = useSetAtom(toggleTranslationAtom);

  const translationStatus = useAtomValue(
    Lazy.valueOr(statusAtom, undefined),
  ) ?? {
    type: 'raw' as const,
  };

  return {
    isCurrentUser: useAtomValue(isOwnAtom),
    toggleTranslation,
    translationStatus,
    messageText: useAtomValue(messageTextAtom),
  };
}
