import { useAtomValue } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { chatRoomAtoms } from '@advisor/api/chatRoom';
import { collectionAtom, lazyAtom, nullAtom } from '@advisor/utils/atoms';
import { AutotranslationState } from '../contentTranslation';

export const inProgressChatRoomsAtom = collectionAtom<string>();

export const autotranslationStateAtoms = atomFamily((chatRoomId: string) => {
  return lazyAtom(async (get) => {
    const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);
    const inProgress = get(inProgressChatRoomsAtom).includes(chatRoomId);

    if (inProgress) {
      return AutotranslationState.Loading;
    }

    const memberOfMe = await get(memberOfMeAtom);
    const isAutotranslationEnabled = memberOfMe?.autoTranslate ?? false;

    return isAutotranslationEnabled
      ? AutotranslationState.Enabled
      : AutotranslationState.Disabled;
  });
});

export function useAutotranslationState(chatRoomId?: string) {
  return useAtomValue(
    chatRoomId ? autotranslationStateAtoms(chatRoomId) : nullAtom,
  );
}
