export function isEmailValid(value: string): boolean {
  return !!value && /\S+@\S+\.\S+/.test(value);
}

/**
 * The only overlap is Puerto Rico whose code also starts with a +1 (+1939),
 * but by most documentation it is considered a domestic call in the US.
 */
export const NORTH_AMERICAN_FORMAT = /^\+1/;

export function formatNumber(value: string) {
  const formatted = value
    .replace(/\s/g, '') // Removing whitespace
    .replace(/[^0-9+]/g, '') // Only digits
    .replace(/^(.{15}).*/, '$1'); // Limiting size

  if (NORTH_AMERICAN_FORMAT.test(formatted)) {
    return formatted
      .replace(/^(.{8})(.)/, '$1 $2')
      .replace(/^(.{5})(.)/, '$1 $2')
      .replace(/^(.{2})(.)/, '$1 $2');
  }

  return formatted
    .replace(/^(.{9})(.)/, '$1 $2')
    .replace(/^(.{6})(.)/, '$1 $2')
    .replace(/^(.{3})(.)/, '$1 $2');
}
