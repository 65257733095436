import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spacing } from '@advisor/design/styles';
import { Icon } from '@advisor/design/components';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import { LimitedVisibilityTagProps } from './types';

function LimitedVisibilityTag(props: LimitedVisibilityTagProps) {
  const { author } = props;
  const { t } = useTranslation('common');
  const color = useUserColor(author);

  return (
    <div className="select-none">
      <span
        className="inline-flex gap-0.5 px-2 py-0.5 items-center border-x border-b border-grey rounded-b font-sora font-bold text-2xs"
        style={{ color }}
      >
        <Icon size={Spacing.tiny} name="Show" className="opacity-60" />
        <p>{t('only-visible-to-hosts')}</p>
      </span>
    </div>
  );
}

export default LimitedVisibilityTag;
