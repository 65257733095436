import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import Icon, { BouncingDots } from '@advisor/design/components/Icon';
import Advert, {
  AdRequirements,
  useAdvertForPlacement,
} from '@advisor/ui/components/Advert';
import { VIDEO_ROOM_PLACEMENT_KEY } from '@advisor/ui/components/Advert/staticPlacementKeys';
import { useIdleTimersValue } from '../../CallTimers';
import useInfoToShow, { VideoRoomInfo } from './useInfoToShow';

function InfoOverlay() {
  const { t } = useTranslation('common');
  const timeLeft = useIdleTimersValue();
  const infoToShow = useInfoToShow();
  const advert = useAdvertForPlacement(
    VIDEO_ROOM_PLACEMENT_KEY,
    AdRequirements.withAsset,
  );

  const content = useMemo(() => {
    if (infoToShow === VideoRoomInfo.Joining) {
      return {
        icon: <BouncingDots />,
        title: t('joining...'),
        message: t('please-wait'),
      };
    }

    if (infoToShow === VideoRoomInfo.PermissionsRequired) {
      return {
        icon: <Icon name="ErrorCircle" className="w-8 h-8 text-yellow" />,
        title: t('permissions-required'),
        message: t('for-people-to-see-and-hear-you-during-video-call'),
      };
    }

    if (infoToShow === VideoRoomInfo.WaitingForPeopleToJoin) {
      return {
        icon: <Icon name="VideoRoom" className="w-8 h-8" />,
        title: t('waiting-for-people-to-join'),
        message: t('video-rooms-are-a-place-for-connection'),
      };
    }

    if (infoToShow === VideoRoomInfo.ItsLonelyInHere) {
      return {
        icon: <Icon name="DoubleExclamation" className="w-8 h-8" />,
        title: t('its-lonely-in-here'),
        message: t('seems-like-the-conversation-is-over', {
          count: timeLeft,
        }),
      };
    }

    if (infoToShow === VideoRoomInfo.OopsSomethingWentWrong) {
      return {
        icon: <Icon name="ErrorCircle" className="w-8 h-8 text-yellow" />,
        title: t('oops-something-went-wrong'),
        message: t('try-again'),
      };
    }

    if (infoToShow === null) {
      return null;
    }

    warnExhaustive(infoToShow, '@advisor/videoCall/components/InfoOverlay');
    return null;
  }, [t, infoToShow, timeLeft]);

  if (!content) {
    return null;
  }

  return (
    <div className="flex flex-col items-center w-full pt-[16vh] text-white">
      <div className="relative w-14 h-14 flex justify-center items-center bg-primary-blue/20 rounded-full">
        <span className="relative z-10">{content.icon}</span>
        <div className="absolute inset-0 rounded-full bg-primary-blue/20 animate-small-ping" />
      </div>
      <h3 className="mt-10 font-semibold text-xl">{content.title}</h3>
      <p className="mt-4 max-w-[20rem] text-base text-center">
        {content.message}
      </p>
      {advert && infoToShow === VideoRoomInfo.WaitingForPeopleToJoin && (
        <div className="pt-8">
          <Advert.Banner advert={advert} />
        </div>
      )}
    </div>
  );
}

export default InfoOverlay;
