import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback, useEffect, useState } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import copyToClipboard from '@advisor/utils/copyToClipboard';
import { DeprecatedButton, Layout, Modal } from '@advisor/design/components';
import InvitationMethodButton from './InvitationMethodButton';
import { InvitationModalProps, InvitationOption } from './types';

const InvitationModal: React.FC<InvitationModalProps> = (props) => {
  const { isVisible, onClose, options } = props;
  const { t } = useTranslation(['common', 'onboarding']);

  const [selectedOption, setSelectedOption] = useState<InvitationOption>(
    options[0],
  );

  const onCopyPress = useCallback(async () => {
    onClose();

    try {
      await copyToClipboard(selectedOption.url);
      showToast({
        iconName: 'Link',
        messageI18Key: 'something-copied-to-clipboard',
        variant: 'blue',
        values: {
          something: selectedOption.title,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
      showToast({
        iconName: 'X',
        message: t('common:cant-access-clipboard'),
        variant: 'rose',
      });
    }
  }, [t, onClose, selectedOption]);

  useEffect(() => {
    // Reset state on modal open
    if (isVisible) {
      setSelectedOption(options[0]);
    }
  }, [options, isVisible]);

  return (
    <Modal.Base visible={isVisible} onClose={onClose} maxWidth={406}>
      <Modal.Header
        title={t('onboarding:invite-members')}
        subtitle={t('onboarding:via-secure-referral-link')}
        onClose={onClose}
      />
      <Layout.Block padding={24}>
        {/* Content */}
        {/* We set min height when there are multiple options to avoid jumping height of modal */}
        <div
          className={cs('space-y-4', options.length > 1 && 'min-h-[12.5rem]')}
        >
          {options.map((option) => (
            <InvitationMethodButton
              key={option.url}
              option={option}
              isSelected={selectedOption.url === option.url}
              selectOption={setSelectedOption}
            />
          ))}
        </div>
        <Layout.Spacer.Vertical size={24} />
        {/* Note */}
        <p className="text-xs text-dark-grey-025">
          {t('onboarding:share-this-link-responsibly-as-people-can')}
        </p>
        <Layout.Spacer.Vertical size={24} />
        {/* Copy Button */}
        <div className="flex flex-row">
          <DeprecatedButton.Large variant="primary" onPress={onCopyPress}>
            {t('common:copy-link')}
          </DeprecatedButton.Large>
        </div>
      </Layout.Block>
    </Modal.Base>
  );
};

export default memo(InvitationModal);
