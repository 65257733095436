import { useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';

import { useMe } from '@advisor/api/me';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { transferInvitationToMetadataAtom } from './atoms';

/**
 * Reacting to the user being logged in, and copying over
 * invitation data from device storage into persistent user metadata.
 */
function ProactiveInvitationTransfer() {
  const me = useMe();
  const meExistedRef = useRef(false);
  const transfer = useSetAtom(transferInvitationToMetadataAtom);

  useEffect(() => {
    const meExists = !!me;

    if (meExists && !meExistedRef.current) {
      transfer();
    }

    meExistedRef.current = meExists;
  }, [me, meExistedRef, transfer]);

  return null;
}

export default suspenseHOC(ProactiveInvitationTransfer, null);
