import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NewMemberSystemMessageData } from '@advisor/api/generated/graphql';
import { BoldText, SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const OnboardingNewChatMember: React.FC<SystemMessageProps> = ({ message }) => {
  const {
    newMember: { name },
  } = message.data as NewMemberSystemMessageData;

  const { t } = useTranslation('system-message');

  return (
    <Trans
      t={t}
      parent={SimpleMessage}
      i18nKey="youre-now-connected-with"
      values={{ name }}
      components={{ bold: <BoldText /> }}
    />
  );
};

export default memo(OnboardingNewChatMember);
