import React from 'react';
import { useAtomValue } from 'jotai';

import {
  LanguageMetadata,
  primaryLanguagesAtom,
  useLanguage,
} from '@advisor/language';
import { Icon, Dropdown, SimpleDropdownItem } from '@advisor/design/components';

const LanguageDropDown = () => {
  const { uiLanguage, changeLanguage } = useLanguage();
  const primaryLanguages = useAtomValue(primaryLanguagesAtom);

  return (
    <Dropdown.Root
      trigger={
        <button
          type="button"
          className="text-sm inline-flex content-between items-center text-primary"
        >
          {LanguageMetadata[uiLanguage].native}
          <Icon name="ChevronDown" />
        </button>
      }
    >
      <Dropdown.Items className="w-[163px]">
        {primaryLanguages.map((langKey) => (
          <SimpleDropdownItem
            key={langKey}
            label={LanguageMetadata[langKey].native}
            active={uiLanguage === langKey}
            onPress={() => changeLanguage(langKey)}
          />
        ))}
      </Dropdown.Items>
    </Dropdown.Root>
  );
};

export default LanguageDropDown;
