import { Loadable } from 'jotai/vanilla/utils/loadable';

function unwrapLoadable<Value>(
  loadableResult: Loadable<Value>,
  fallback: Awaited<Value>,
) {
  if (loadableResult.state === 'hasData') {
    return loadableResult.data;
  }
  return fallback;
}

export default unwrapLoadable;
