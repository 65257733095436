import z from 'zod';

export type MilestoneTranslations = z.infer<typeof MilestoneTranslations>;
export const MilestoneTranslations = z.record(
  z.string(), // Language code
  z.object({
    name: z.string().nullable(),
    description: z.string().nullable(),
  }),
);

export type CommentTranslations = z.infer<typeof CommentTranslations>;
export const CommentTranslations = z.record(
  z.string(), // Language code
  z.object({
    comment: z.string(),
  }),
);

export type MilestoneCategoryTranslations = z.infer<
  typeof MilestoneCategoryTranslations
>;
export const MilestoneCategoryTranslations = z.record(
  z.string(), // Language code
  z.object({
    name: z.string(),
  }),
);
