import React from 'react';
import cs from 'classnames';

import { Avatar } from '@advisor/design/components';
import { AvatarGroupProps } from './types';

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  frontAvatar,
  backAvatar,
  className,
}) => (
  <div className={cs('flex flex-row-reverse', className)}>
    <Avatar
      className="ltr:-ml-5 rtl:-mr-5 border-grey"
      size={40}
      avatarUrl={backAvatar}
      bordered={false}
    />
    <Avatar
      className="border-grey"
      size={40}
      avatarUrl={frontAvatar}
      bordered={false}
    />
  </div>
);

export default AvatarGroup;
