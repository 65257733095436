import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useUserMetadata } from '@advisor/api/userMetadata';
import Tooltip from '@advisor/design/components/Tooltip';
import { MagicWandTooltipLocation } from '../metadata';
import ChatIconButton from './ChatIconButton';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const MagicWandButton = ({ disabled, onClick }: Props) => {
  const { t } = useTranslation('microbot');

  const [hideTooltip, setHideTooltip] = useUserMetadata(
    MagicWandTooltipLocation,
  );

  return (
    <Tooltip.Root className="flex">
      <ChatIconButton
        onPress={onClick}
        disabled={disabled}
        iconName="WandFilled"
        testID="sendMessageButton"
      />
      <Tooltip.SimpleContent
        className="max-w-xs"
        visible={!hideTooltip}
        variant="dark"
        title={
          <Trans t={t} i18nKey="easy-access-to-ai-suggested-replies">
            Easy Access to
            <span className="text-secondary">AI Suggested Replies</span>
          </Trans>
        }
        description={t('tap-on-this-icon-to-get-suggested')}
        onClose={() => {
          setHideTooltip(true);
        }}
      />
    </Tooltip.Root>
  );
};

export default memo(MagicWandButton);
