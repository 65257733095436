import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import {
  BoldText,
  RichMessage,
  CenteredMessage,
} from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const FirstServiceProviderMessage: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { chatRoomId, data } = message;
  const { advisor } = data as DefaultSystemMessageData;

  if (!advisor) {
    return null;
  }

  return (
    <RichMessage
      users={[advisor]}
      chatRoomId={chatRoomId}
      userNameTestID="first-service-provider-message-user-details-name"
    >
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey="hi-i-am-host-name-lets-get-started"
        values={{ name: advisor.name }}
        components={{ bold: <BoldText /> }}
      />
    </RichMessage>
  );
};

export default memo(FirstServiceProviderMessage);
