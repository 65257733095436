import { useCallback, useEffect, useState } from 'react';
import type { ParseKeys } from 'i18next';
import type { _DefaultNamespace } from 'react-i18next/TransWithoutContext';
import z from 'zod';

import isValidPhoneNumberAsync from '@advisor/utils/isValidPhoneNumberAsync';
import { CallCode } from '@advisor/utils/country/internationalDiallingCodes';
import { findCallCodeByLanguageTag } from '@advisor/utils/country/callCodeSelection';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import {
  ChatRoomsDocument,
  useInviteStudentMutation,
} from '../generated/graphql';
import { UserMetadataLocation, useUserMetadata } from '../userMetadata';

export enum Tab {
  Info,
  Form,
}

type Field = 'name' | 'email' | 'phone';

export interface ErrorType {
  fields: Field[];
  messages: ParseKeys<['common', 'onboarding']>[];
}

export type StudentProfile = {
  name: string;
  email: string;
  phoneNumber: string;
  callCode: CallCode;
};

const blankProfile = (source: string | null): StudentProfile => ({
  name: '',
  email: '',
  phoneNumber: '',
  callCode: findCallCodeByLanguageTag(source),
});

const CreateStudentConversationOnboardingLocation = new UserMetadataLocation(
  'modals.createStudentConversationOnboarding',
  z.boolean(),
);

const useCreateStudentConversation = (
  visible: boolean,
  onClose: () => void,
  languageTag: string | null,
) => {
  const [userMetadata, setUserMetadata] = useUserMetadata(
    CreateStudentConversationOnboardingLocation,
  );

  const [tab, setTab] = useState<Tab>(!userMetadata ? Tab.Info : Tab.Form);
  const [error, setError] = useState<ErrorType>({ fields: [], messages: [] });

  const [profile, setProfile] = useState<StudentProfile>(
    blankProfile(languageTag),
  );

  const [inviteStudent, { loading }] = useInviteStudentMutation();

  // Reset the current tab and the profile whenever modal is being shown
  useEffect(() => {
    if (visible) {
      setTab(!userMetadata ? Tab.Info : Tab.Form);
      setProfile(blankProfile(languageTag));
      setError({ fields: [], messages: [] });
    }
  }, [visible, userMetadata, languageTag]);

  const onContinue = useCallback(() => {
    if (!userMetadata) {
      setUserMetadata(true);
    }
    setTab(Tab.Form);
  }, [userMetadata, setUserMetadata]);

  const onCreateInner = useCallback(
    async (studentProfile: StudentProfile) => {
      const errorObj: ErrorType = {
        fields: [],
        messages: [],
      };

      // Name validation
      if (studentProfile.name === '') {
        errorObj.fields.push('name');
        errorObj.messages.push('onboarding:please-provide-the-customer-name');
      }

      // Credentials presence validation
      if (studentProfile.email === '' && studentProfile.phoneNumber === '') {
        errorObj.fields.push('email');
        errorObj.fields.push('phone');
        errorObj.messages.push(
          'onboarding:please-provide-at-least-one-of-the-following',
        );
      }

      // Phone number validation
      const phoneNumber = `${studentProfile.callCode.dial_code} ${studentProfile.phoneNumber}`;
      const isValidPhoneNumber = await isValidPhoneNumberAsync(phoneNumber);

      if (studentProfile.phoneNumber !== '' && !isValidPhoneNumber) {
        errorObj.fields.push('phone');
        errorObj.messages.push(
          'onboarding:please-make-sure-provided-phone-is-correct',
        );
      }

      // Email validation
      if (
        studentProfile.email !== '' &&
        !studentProfile.email.match(/\S+@\S+\.\S+/)
      ) {
        errorObj.fields.push('email');
        errorObj.messages.push(
          'onboarding:please-make-sure-provided-email-is-correct',
        );
      }

      // Student account creation
      if (!errorObj.fields.length) {
        try {
          const result = await inviteStudent({
            refetchQueries: [ChatRoomsDocument],
            variables: {
              name: studentProfile.name,
              email:
                studentProfile.email !== ''
                  ? studentProfile.email.toLocaleLowerCase().replace(/\s/g, '')
                  : null,
              phoneNumber:
                studentProfile.phoneNumber !== ''
                  ? phoneNumber.replace(/\s/g, '')
                  : null,
              country: studentProfile.callCode.name,
            },
          });

          if (
            result.data?.inviteStudent.__typename === 'UserAlreadyExistsError'
          ) {
            if (studentProfile.email !== '') {
              errorObj.fields.push('email');
            }

            if (studentProfile.phoneNumber !== '') {
              errorObj.fields.push('phone');
            }

            errorObj.messages.push('onboarding:user-already-exists');
          } else {
            onClose();

            showToast({
              iconName: 'CircleCheck',
              variant: 'blue',
              namespace: 'onboarding',
              messageI18Key: 'customer-conversation-was-successfully-created',
            });
          }
        } catch (e) {
          Sentry.captureException(e);
          showToast({
            iconName: 'X',
            variant: 'rose',
            messageI18Key: 'oops-something-went-wrong',
          });
        }
      }

      setError(errorObj);
    },
    [onClose, inviteStudent],
  );

  const onCreate = useCallback(async () => {
    await onCreateInner(profile);
  }, [profile, onCreateInner]);

  const onSetName = useCallback((newValue: string) => {
    setProfile((prev) => ({ ...prev, name: newValue }));
  }, []);

  const onSetEmail = useCallback((newValue: string) => {
    setProfile((prev) => ({ ...prev, email: newValue }));
  }, []);

  const onSetPhoneNumber = useCallback((newValue: string) => {
    const formatted = newValue
      .replace(/\D/g, '')
      .replace(/^(.{15}).*/, '$1')
      .replace(/^(.{6})(.)/, '$1 $2')
      .replace(/^(.{3})(.)/, '$1 $2');

    setProfile((prev) => ({ ...prev, phoneNumber: formatted }));
  }, []);

  const onSetCallCode = useCallback((newValue: CallCode) => {
    setProfile((prev) => ({ ...prev, callCode: newValue }));
  }, []);

  return {
    profile,
    tab,
    error,
    disabled: loading,
    onContinue,
    onCreate,
    onSetName,
    onSetEmail,
    onSetPhoneNumber,
    onSetCallCode,
  };
};

export default useCreateStudentConversation;
