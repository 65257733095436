import React, { useMemo } from 'react';
import { ScopeProvider } from 'bunshi/react';

import { useEvent } from '@advisor/utils/hooks';
import { ContextMenuScope } from './contextMenuMolecule';
import type { ContextMenuScopeType, StaticMenuOwnerProps } from './types';

function StaticMenuOwner({ children, visible, onClose }: StaticMenuOwnerProps) {
  const handleClose = useEvent(() => {
    onClose?.();
  });

  const visibleScope: ContextMenuScopeType = useMemo(
    () => ({
      onClose: handleClose,
      triggerVisibleOverride: true,
    }),
    [handleClose],
  );

  const hiddenScope: ContextMenuScopeType = useMemo(
    () => ({
      onClose: handleClose,
      triggerVisibleOverride: false,
    }),
    [handleClose],
  );

  return (
    <ScopeProvider
      scope={ContextMenuScope}
      value={visible ? visibleScope : hiddenScope}
    >
      {children}
    </ScopeProvider>
  );
}

export default StaticMenuOwner;
