// @ts-ignore
import packageJson from '../../package.json';
import type { StorageInterface } from './types';

const keyPrefix: string = `storage-${packageJson.version}`;

const applyPrefix = (key: string) => `${keyPrefix}-${key}`;

const deapplyPrefix = (key: string) => {
  if (!keyPrefix) {
    throw new Error('Storage is not initialized!');
  }

  if (key.startsWith(keyPrefix)) {
    return key.slice(keyPrefix.length + 1);
  }

  return undefined;
};

const Storage: StorageInterface = {
  set: (key, value) => {
    let stringValue: string;
    if (typeof value !== 'string') {
      stringValue = JSON.stringify(value);
    } else {
      stringValue = value;
    }

    localStorage.setItem(applyPrefix(key), stringValue);
  },

  getString: (key) => {
    return localStorage.getItem(applyPrefix(key)) ?? undefined;
  },

  delete: (key) => {
    return localStorage.removeItem(applyPrefix(key));
  },

  setItem: (key, value) => {
    return localStorage.setItem(applyPrefix(key), value);
  },

  getItem: (key) => {
    return localStorage.getItem(applyPrefix(key)) ?? undefined;
  },

  removeItem: (key) => {
    return localStorage.removeItem(applyPrefix(key));
  },

  clear: () => {
    localStorage.clear();
  },

  subscribe(callback) {
    if (!window.Storage) {
      return { unsubscribe() {} };
    }

    const storageEventCallback = (e: StorageEvent) => {
      const deprefixedKey = deapplyPrefix(e.key ?? '');

      if (e.storageArea === localStorage && deprefixedKey) {
        callback(deprefixedKey);
      }
    };

    window.addEventListener('storage', storageEventCallback);

    return {
      unsubscribe() {
        window.removeEventListener('storage', storageEventCallback);
      },
    };
  },
};

export default Storage;
