import EmailAddress from './EmailAddress';
import AvatarInput from './AvatarInput';
import PhoneNumber from './PhoneNumber';
import RadioIcon from './RadioIcon';
import Select from './Select';
import Text from './Text';
import TextArea from './TextArea';
import Attachment from './Attachment';
import DateInput from './DateInput';
import DatePicker from './DatePicker';
import ColorInput from './ColorInput';

export { default as useForm } from './useForm';
export { default as ErrorMsg } from './errorMsg';

export type { Option as SelectOption } from './types';

const Form = {
  EmailAddress,
  PhoneNumber,
  AvatarInput,
  RadioIcon,
  Select,
  Text,
  TextArea,
  Attachment,
  DateInput,
  DatePicker,
  ColorInput,
};

export default Form;
