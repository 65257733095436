import React, { useContext } from 'react';

import TooltipContext from './context';
import { ElevateProps } from './types';

const Elevate = (props: ElevateProps) => {
  const { rootDimensions } = useContext(TooltipContext);
  const { children, containerBounds } = props;

  return (
    <div
      className="absolute z-20 pointer-events-none"
      style={{
        top: rootDimensions.y - (containerBounds?.y ?? 0),
        left: rootDimensions.x - (containerBounds?.x ?? 0),
        width: rootDimensions.width,
        height: rootDimensions.height,
      }}
    >
      {children}
    </div>
  );
};

export default Elevate;
