import { FC, PropsWithChildren } from 'react';
import cs from 'classnames';

type Props = PropsWithChildren<{ className?: string }>;

export const Container: FC<Props> = ({ children, className }) => (
  <div className={className ?? 'text-outfit text-base max-w-[65%]'}>
    {children}
  </div>
);

export const PageSubtitle = () => (
  <h3 className="text-2xl text-primary-dark font-sora font-semibold mt-5 mb-2.5 uppercase">
    Global Study Technology Limited
  </h3>
);

export const PolicyInfo: FC<Props> = ({ children, className }) => (
  <p className={className ?? 'font-semibold text-lg'}>{children}</p>
);

export const SectionTitle: FC<Props & { id?: string; sub?: boolean }> = ({
  id,
  sub,
  children,
  className,
}) => (
  <h4
    id={id}
    className={
      className ??
      cs('font-semibold text-2xl font-outfit', sub ? 'mt-5' : 'mt-5 mb-2.5')
    }
  >
    {children}
  </h4>
);

export const SectionSubtitle: FC<Props> = ({ children, className }) => (
  <h5 className={className ?? 'font-normal mt-5 text-lg font-outfit'}>
    {children}
  </h5>
);

export const SectionBody: FC<Props> = ({ children, className }) => (
  <p className={className ?? 'mb-6'}>{children}</p>
);

export const Link: FC<Props & { href: string; className?: string }> = ({
  href,
  children,
  className,
}) => (
  <a href={href} className={className ?? 'underline'}>
    {children}
  </a>
);

export const Ul: FC<Props> = ({ children, className }) => (
  <ul className={className ?? 'list-disc pl-10 text-base'}>{children}</ul>
);

export const Ol: FC<Props> = ({ children, className }) => (
  <ol className={className ?? 'list-decimal pl-5 text-base'}>{children}</ol>
);

export const Li: FC<Props> = ({ children, className }) => (
  <li className={className}>{children}</li>
);
