import AnimatePing from './AnimatePing';
import TextSkeleton from './TextSkeleton';
import AvatarSkeleton from './AvatarSkeleton';

const Skeleton = {
  Ping: AnimatePing,
  Text: TextSkeleton,
  Avatar: AvatarSkeleton,
};

export default Skeleton;
