import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import editArtifactAtom from '../../atoms/editArtifactAtom';
import { ConfirmTabProps } from './types';

const ConfirmTab: React.FC<ConfirmTabProps> = ({ onClose }) => {
  const { t } = useTranslation(['common', 'microbot']);

  const dispatch = useSetAtom(editArtifactAtom);

  const onSave = async () => {
    await dispatch({ type: 'submit' });
    onClose();
  };

  return (
    <div className="px-8 pt-8 text-center">
      <h3 className="font-sora text-sm leading-[1.125rem] font-bold text-dark-grey-03">
        {/* As for now Pam's partner school list is the only artifact */}
        {t('microbot:are-you-sure-you-want-to-make-changes-to-the-artifact', {
          artifact: t('microbot:partner-school-list'),
        })}
      </h3>

      <Layout.Spacer.Vertical size="micro02" />

      <p className="font-outfit text-sm leading-[1.185rem] font-medium text-dark-grey-02">
        {/* As for now Pam's partner school list is the only artifact */}
        {t(
          'microbot:this-action-will-update-recommendation-bot-future-responses',
        )}
      </p>

      <Layout.Spacer.Vertical size="small01" />

      <DeprecatedButton.Large
        className="w-full h-12 !py-2 text-sm leading-[0.875rem] shadow-drop-01"
        label={t('microbot:make-changes')}
        variant="primary"
        onPress={onSave}
      />
      <Layout.Spacer.Vertical size="tiny" />
      <DeprecatedButton.Outline
        className="w-full h-12 text-sm leading-[0.875rem] shadow-drop-01"
        label={t('common:cancel')}
        variant="darkGrey03"
        onPress={onClose}
      />
    </div>
  );
};

export default ConfirmTab;
