import cs from 'classnames';
import { useSetAtom } from 'jotai';
import React, { memo } from 'react';
import { useMolecule } from 'bunshi/react';
import { Trans, useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { useUserMetadata } from '@advisor/api/userMetadata';
import { Icon } from '@advisor/design/components';
import {
  ToggleTranslationMolecule,
  useShowTranslationTooltip,
} from '../../chatLanguage';
import { TranslationTooltipMetadataLocation } from '../../chatLanguage/useShowTranslationTooltip';
import { TranslateTooltipProps } from './types';

const TranslateTooltip: React.FC<TranslateTooltipProps> = (props) => {
  const { message, latestMessageId } = props;
  const { chatRoomId } = message;

  const { t } = useTranslation('common');
  const { toggleTranslationAtom } = useMolecule(ToggleTranslationMolecule);
  const [, setHide] = useUserMetadata(TranslationTooltipMetadataLocation);

  const showTranslationTooltip = useShowTranslationTooltip({
    chatRoomId,
    message,
    latestMessageId,
  });

  const toggleTranslation = useSetAtom(toggleTranslationAtom);

  const handleTranslate = useEvent(() => {
    setHide(true);
    toggleTranslation();
  });

  const handleCancel = useEvent(() => {
    setHide(true);
  });

  if (!showTranslationTooltip) {
    return null;
  }

  return (
    <div className="relative max-w-[16rem] px-6 py-5 mx-4 my-6 rounded-10 bg-white">
      <div className="absolute -top-1.5 inset-x-12 flex justify-start">
        <div className={cs('bg-white w-3 h-3 rounded rotate-45')} />
      </div>
      <h1 className="mt-1 mb-3 text-sm font-sora font-bold flex justify-between items-center">
        <button
          type="button"
          onClick={handleTranslate}
          className="flex justify-start items-center"
        >
          <span className="text-left rtl:text-right">
            <Trans t={t} i18nKey="click-to-translate">
              Click to <span className="text-primary">translate</span>
            </Trans>
          </span>
          <Icon name="Globe" className="text-primary" />
        </button>
        <button type="button" onClick={handleCancel}>
          <Icon name="Close" size={24} className="text-dark-grey-02" />
        </button>
      </h1>
      <p className="font-outfit text-sm mb-4">
        {t(
          'now-you-can-click-on-any-message-and-translate-it-to-your-preferred-language',
        )}
      </p>
      <button
        type="button"
        className="min-w-[7rem] border text-dark-grey-03 rounded-lg py-2 text-sm font-medium transition-colors duration-75 hover:opacity-50"
        onClick={handleTranslate}
      >
        {t('try-it')}
      </button>
    </div>
  );
};

export default memo(TranslateTooltip);
