import { useCallback, useEffect, useState } from 'react';

import { useCallState } from '../VideoRoomAPI';
import { useConnectedParticipants } from '../Participants';
import { TimerScopeKey, useTimer, useTimerValue } from './useTimer';

type TimerState = null | 'Starting' | 'Ongoing' | 'Closing';

const IdleScopeKey = new TimerScopeKey();

export function useIdleTimers(onTimeoutReached: () => void | Promise<void>) {
  const [timerState, setTimerState] = useState<TimerState>(null);
  const participantCount = useConnectedParticipants().length;
  const { timeLeft, startTimer, stopTimer } = useTimer(IdleScopeKey);
  const callState = useCallState();

  const onFinished = useCallback(async () => {
    await onTimeoutReached();
    // Reset timer to initial state
    stopTimer();
  }, [stopTimer, onTimeoutReached]);

  useEffect(() => {
    if (participantCount > 1) {
      setTimerState('Ongoing');
    }

    if (!timerState && (participantCount > 0 || !!callState)) {
      setTimerState('Starting');
      return;
    }

    if (timerState !== 'Starting' && participantCount === 1) {
      setTimerState('Closing');
    }
  }, [participantCount, callState, timerState, setTimerState]);

  useEffect(() => {
    if (timerState === 'Starting') {
      // 1 minute count down, 4 minute wait time
      startTimer(60, 240);
      return;
    }

    if (timerState === 'Closing') {
      // 1 minute countdown
      startTimer(60);
      return;
    }

    if (timerState === 'Ongoing' || !timerState) {
      stopTimer();
    }
  }, [startTimer, stopTimer, timerState]);

  useEffect(() => {
    // Stop timers when component is unmounting
    return stopTimer;
  }, [stopTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      onFinished();
    }
  }, [onFinished, timeLeft]);
}

export function useIdleTimersValue() {
  return useTimerValue(IdleScopeKey);
}
