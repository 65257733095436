import { useCallback, useMemo } from 'react';

import { useRoleRequestsQuery } from '@advisor/api/generated/graphql';
import { NetworkStatus } from '@apollo/client';

export default function useRoleRequests() {
  const { data, error, loading, refetch, fetchMore, networkStatus } =
    useRoleRequestsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const { pageInfo, count } = data?.roleRequests ?? {};

  const roleRequests = useMemo(
    () => (data?.roleRequests.edges ?? []).map(({ node }) => node),
    [data?.roleRequests],
  );

  const loadMore = useCallback(() => {
    if (
      !loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      });
    }
  }, [loading, networkStatus, pageInfo, fetchMore]);

  return {
    count: count ?? 0,
    error,
    loading,
    refetch,
    loadMore,
    roleRequests,
    hasMore: !!pageInfo?.hasNextPage,
  };
}
