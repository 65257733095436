import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { useSendMilestoneReminderMutation } from '@advisor/api/generated/graphql';
import useMilestoneStatus from '../components/MilestoneStatus/useMilestoneStatus';
import { Body, Bold } from './manualReminderComponents';

interface ManualReminderOptions {
  id: string;
  name: string;
  isCompleted: boolean;
  pendingReview: boolean;
  notificationTime?: string | null;
}

export default function useManualReminder(
  options: ManualReminderOptions | null | undefined,
) {
  const { id, name, notificationTime, pendingReview, isCompleted } =
    options ?? {
      pendingReview: false,
      isCompleted: false,
    };

  const [sendReminderMutation] = useSendMilestoneReminderMutation();
  const { t } = useTranslation('task-organiser');
  const showModal = useShowModal();

  const { status } = useMilestoneStatus({
    notificationTime,
    pendingReview,
    isCompleted,
  });

  const isVisible = ['overdue', 'due'].includes(status);

  const send = useCallback(async () => {
    if (!id || !status || !name || !notificationTime) {
      return;
    }

    const confirmed = await showModal.confirm({
      variant: 'neutral',
      title: t('send-reminder-to-customer'),
      message: (
        <Trans
          t={t}
          i18nKey={
            status === 'due'
              ? 'youre-about-to-send-a-reminder-about-the-upcoming-task'
              : 'youre-about-to-send-a-reminder-about-the-overdue-task'
          }
          values={{
            task: name,
            date: DateFormatter.calendarShort(notificationTime),
          }}
          components={{ bold: <Bold /> }}
          parent={Body}
        />
      ),
    });

    if (!confirmed) {
      return;
    }

    await sendReminderMutation({
      variables: { milestoneId: id },
    });
  }, [showModal, t, id, name, status, notificationTime, sendReminderMutation]);

  return {
    isVisible,
    send,
  };
}
