import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Skeleton } from '@advisor/design/components';
import { JourneyProfileSkeleton } from '@advisor/journey/components/JourneyProfile';
import CategoryListItemSkeleton from '@advisor/journey/components/CategoryList/CategoryListItemSkeleton';
import SideModal from '../SideModal';

const StudentJourneyModalLazy = React.lazy(
  () => import('./StudentJourneyModal'),
);

const Fallback = () => {
  const { t } = useTranslation('task-organiser');

  return (
    <SideModal.Default backButton title={t('task-organiser')} scroll={false}>
      <JourneyProfileSkeleton />
      <Layout.Spacer.Vertical size="small02" />
      <Skeleton.Text fontSize={16} lineHeight={20} size={80} />
      <Skeleton.Text fontSize={14} lineHeight={18} />
      <Layout.Spacer.Vertical size="small02" />
      <CategoryListItemSkeleton />
      <Layout.Spacer.Vertical size="tiny" />
      <CategoryListItemSkeleton />
      <Layout.Spacer.Vertical size="tiny" />
      <CategoryListItemSkeleton />
    </SideModal.Default>
  );
};

const StudentJourneyModal = () => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <StudentJourneyModalLazy />
    </React.Suspense>
  );
};

export default StudentJourneyModal;
