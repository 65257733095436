import React from 'react';
import { useTranslation } from 'react-i18next';

import { NameInputProps } from './types';

const NameInput: React.FC<NameInputProps> = ({ value, onSetValue }) => {
  const { t } = useTranslation('common');

  return (
    <div className="relative">
      <span className="h-4 absolute top-4 ltr:left-4 rtl:right-4 font-outfit text-xs text-dark-grey-03 select-none pointer-events-none">
        {t('status-name')}
      </span>
      <input
        className="w-full px-4 pt-8 pb-4 rounded border border-dark-grey-01 font-outfit focus:outline-none text-sm leading-[1.125rem] font-medium placeholder-dark-grey-01"
        value={value}
        onChange={(event) => onSetValue(event.target.value)}
        placeholder={t('add-name')}
      />
    </div>
  );
};

export default NameInput;
