import { unwrap } from 'jotai/utils';
import { produce, Draft } from 'immer';
import { Getter, SetStateAction, Setter, atom } from 'jotai';
import { ApolloClient, OperationVariables } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { DocumentNode } from '../generated/graphql';
import { clientAtom } from './clientAtom';

type Options<Value, Data, Variables extends object = OperationVariables> = {
  initial: Value;
  variables: Variables;
  onData: (ctx: {
    get: Getter;
    set: Setter;
    client: ApolloClient<unknown>;
    data: Data | null | undefined;
    draft: Draft<Value>;
  }) => Draft<Value> | undefined | void;
};

const atomWithSubscription = <
  Value,
  Data,
  Variables extends object = OperationVariables,
>(
  subscriptionDoc: DocumentNode<Data, Variables>,
  options: Options<Value, Data, Variables>,
) => {
  const valueAtom = atom<Value>(options.initial);

  const wrapperAtom = atom((wrapperGet) => {
    const client = wrapperGet(unwrap(clientAtom));

    if (!client) {
      return undefined;
    }

    type Update =
      | { type: 'data'; data: Data | null | undefined }
      | { type: 'error'; error: unknown };

    const innerAtom = atom(
      (get) => get(valueAtom),
      (get, set, update: Update) => {
        if (update.type === 'data') {
          const nextValue = produce(get(valueAtom), (draft) => {
            return options.onData({
              get,
              set,
              draft,
              client,
              data: update.data,
            });
          });

          set(valueAtom, nextValue);
        } else if (update.type === 'error') {
          Sentry.captureException(
            new ComplexError(
              `Received error from ${subscriptionDoc} subscription`,
              update.error,
            ),
          );
        }
      },
    );

    innerAtom.onMount = (setAtom) => {
      const observable = client.subscribe({
        query: subscriptionDoc,
        variables: options?.variables,
      });

      const subscription = observable.subscribe({
        next(fetchResult) {
          setAtom({ type: 'data', data: fetchResult.data });
        },
        error(error) {
          setAtom({ type: 'error', error });
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    };

    return innerAtom;
  });

  return atom(
    (get) => {
      const innerAtom = get(wrapperAtom);

      return innerAtom ? get(innerAtom) : options.initial;
    },
    (_get, set, value: SetStateAction<Value>) => {
      set(valueAtom, value);
    },
  );
};

export default atomWithSubscription;
