import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import SideModal from '../SideModal';

const MemoryBankModalLazy = React.lazy(() => import('./MemoryBankModal'));

const Fallback = () => {
  const { t } = useTranslation('microbot');

  return (
    <SideModal.Default
      backButton
      padding={false}
      scroll={false}
      title={t('alpha-bot-memory-bank')}
    >
      <div className="p-6">
        <Skeleton.Text
          fontSize={Spacing.iconSize}
          lineHeight={Spacing.iconSize}
        />
        <Layout.Spacer.Vertical size="small01" />
      </div>
    </SideModal.Default>
  );
};

const MemoryBankModal: React.FC = () => (
  <React.Suspense fallback={<Fallback />}>
    <MemoryBankModalLazy />
  </React.Suspense>
);

export default MemoryBankModal;
