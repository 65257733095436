/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { fromPairs } from 'lodash-es';
import { produce, Draft } from 'immer';
import { atomWithObservable } from 'jotai/utils';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { nonNullable } from '@advisor/utils/typeUtils';
import type { Observer } from '@advisor/utils/observable';
import type { Sessions } from '../../types';
import { membranePeerToGeneric } from '../membraneSessions';
import { onMembraneEvent, type TrackContext } from './jellyfishClient';

type Producer = (draft: Draft<Sessions>) => Sessions | void;

function makeSubscription(update: (producer: Producer) => void) {
  const onTrackUpdated = (ctx: TrackContext) => {
    update((draft) => {
      const { id } = ctx.endpoint;

      if (!ctx.metadata) {
        return;
      }

      draft[id].tracks[ctx.metadata.type].track = ctx.track;
    });
  };

  const unsubs = [
    // Joined
    onMembraneEvent('joined', (_peerId, peersInRoom) => {
      const sessions = fromPairs(
        peersInRoom
          .map(membranePeerToGeneric)
          .filter(nonNullable)
          .map((session) => [session.sessionId, session]),
      );

      update((_draft) => sessions);
    }),

    // Disconnected
    onMembraneEvent('disconnected', () => update((_draft) => ({}))),

    // Peer Joined
    onMembraneEvent('peerJoined', (peer) => {
      const genericSession = membranePeerToGeneric(peer);

      if (!genericSession) {
        Sentry.captureException(
          new ComplexError(
            `Failed to create generic session for ${peer.id}`,
            peer,
          ),
        );
        return;
      }

      update((draft) => {
        draft[peer.id] = genericSession;
      });
    }),

    // Peer Left
    onMembraneEvent('peerLeft', (peer) => {
      update((draft) => {
        delete draft[peer.id];
      });
    }),

    // Track removed
    onMembraneEvent('trackRemoved', (ctx) => {
      update((draft) => {
        const { id } = ctx.endpoint;

        if (!ctx.metadata) {
          return;
        }

        draft[id].tracks[ctx.metadata.type].track = null;
      });
    }),

    onMembraneEvent('trackAdded', onTrackUpdated),
    onMembraneEvent('trackReady', onTrackUpdated),
    onMembraneEvent('trackUpdated', onTrackUpdated),
  ];

  return {
    unsubscribe() {
      unsubs.forEach((unsub) => unsub());
    },
  };
}

export const remoteSessionsAtom = atomWithObservable<Sessions>(
  () => {
    let value: Sessions = {};

    return {
      subscribe(observer: Observer<Sessions>) {
        observer.next(value); // populating with current value

        const updateFunction = (producer: Producer) => {
          value = produce(value, producer);
          observer.next(value);
        };

        return makeSubscription(updateFunction);
      },
    };
  },
  {
    initialValue: {},
  },
);
