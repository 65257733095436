import React from 'react';
import cs from 'classnames';

import { Icon } from '@advisor/design/components';
import { useAmIAdvisor } from '@advisor/api/me';
import { CompleteButtonProps } from './types';
import { useCompletionStatus } from './utils';

const CompleteButton: React.FC<CompleteButtonProps> = (props) => {
  const { isCompleted, pendingReview, onComplete } = props;
  const amIAdvisor = useAmIAdvisor();

  const status = useCompletionStatus(props);
  const disabled = !amIAdvisor && (isCompleted || pendingReview);

  return (
    <button
      type="button"
      data-cy="complete-button"
      disabled={disabled}
      onClick={onComplete}
      className={cs(
        'rounded-full border-2 w-6 h-6 grid place-items-center transition-colors',
        !disabled && 'hover:bg-positive-light/70 hover:border-transparent',
        {
          // Border is set to transparent so that button has the same size
          incomplete: 'border-grey',
          pending: 'bg-positive-light/50 border-transparent',
          completed: 'bg-positive-light border-positive-light',
        }[status],
      )}
    >
      <Icon name="CheckAlt" size={18} className=" text-white" />
    </button>
  );
};

export default CompleteButton;
