import { useCallback, useMemo, useState } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { useRoleRequest } from '@advisor/api/roleRequest';
import { useAdvisorQuery } from '@advisor/api/generated/graphql';
import useRoleRequestActions from './useRoleRequestActions';

const useApproveRoleRequestBanner = (
  chatRoomId: string,
  onApproveCallback: () => void,
  onDeclineCallback: () => void,
) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const roleRequest = useRoleRequest(chatRoomId);
  const isRoleRequest = !!roleRequest;

  const roleRequestId = roleRequest?.id;

  const { data } = useAdvisorQuery({
    skip: !isRoleRequest,
    variables: {
      advisorId: roleRequest?.userId ?? '',
    },
  });

  const advisor = useMemo(() => {
    if (data && data.advisor.__typename !== 'UserBelongsToAnotherAgencyError') {
      return data.advisor;
    }

    return null;
  }, [data]);

  const { approve, decline } = useRoleRequestActions();

  const onApprove = useCallback(async () => {
    if (
      !!roleRequestId &&
      data &&
      data.advisor.__typename !== 'UserBelongsToAnotherAgencyError'
    ) {
      try {
        setIsProcessing(true);
        if (await approve(roleRequestId, data.advisor.name)) {
          onApproveCallback();
        }
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setIsProcessing(false);
      }
    }
  }, [approve, data, roleRequestId, onApproveCallback]);

  const onDecline = useCallback(async () => {
    if (roleRequestId) {
      try {
        setIsProcessing(true);
        if (await decline(roleRequestId)) {
          onDeclineCallback();
        }
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setIsProcessing(false);
      }
    }
  }, [decline, roleRequestId, onDeclineCallback]);

  return {
    isProcessing,
    isRoleRequest,
    roleRequest,
    advisor,
    onApprove,
    onDecline,
  };
};

export default useApproveRoleRequestBanner;
