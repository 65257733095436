import React, { memo } from 'react';
import {
  resolveValue,
  Toast as HotToast,
  Toaster as HotToaster,
} from 'react-hot-toast';
import { Transition } from '@headlessui/react';

const toastRenderer = (props: HotToast) => {
  const { message, visible } = props;
  return (
    <Transition
      as="div"
      appear
      show={visible}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="translate-y-full opacity-0"
      enterTo="translate-y-0 opacity-full"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-y-0 opacity-full"
      leaveTo="translate-y-full opacity-0"
    >
      {resolveValue(message, props)}
    </Transition>
  );
};

const Toaster = () => {
  return (
    <HotToaster
      position="bottom-center"
      containerClassName="absolute inset-6"
      containerStyle={{
        inset: undefined,
      }}
    >
      {toastRenderer}
    </HotToaster>
  );
};

export default memo(Toaster);
