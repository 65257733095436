import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '@advisor/design/components';
import { VideoChatSystemMessageData } from '@advisor/api/generated/graphql';
import HistoryListItem from '@advisor/videoCall/VideoRoomHistory/HistoryListItem';
import { Container, CenteredMessage } from '../systemMessageComponents';
import { useOpenVideoRoomDetails } from '../navigation';
import type { SystemMessageProps } from '../types';

const MessageWhenVideoRoomInitiated: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { videoChat } = message.data as VideoChatSystemMessageData;
  const openDetails = useOpenVideoRoomDetails();

  const onOpenDetails = useCallback(() => {
    if (!videoChat) {
      return;
    }

    openDetails(videoChat);
  }, [videoChat, openDetails]);

  if (!videoChat) {
    return null;
  }

  return (
    <Container>
      <CenteredMessage>
        {t(
          'video-room-recording-is-complete-all-recordings-are-accessible-from-the-conversation-preferences',
        )}
      </CenteredMessage>
      <Layout.Spacer.Vertical size="tiny" />
      <HistoryListItem
        room={videoChat}
        showParticipants
        openDetails={onOpenDetails}
        marginless
      />
    </Container>
  );
};

export default memo(MessageWhenVideoRoomInitiated);
