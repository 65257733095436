import React from 'react';

import { Typography } from '@advisor/design/styles';
import { Layout, Skeleton } from '@advisor/design/components';

const MilestoneListItemSkeleton: React.FC = () => (
  <Skeleton.Ping>
    <div className="rounded-10 border border-grey transition-colors text-start min-w-0 max-w-full">
      <div className="border-b border-grey p-4 gap-4">
        <Skeleton.Text {...Typography.Sora.subtitleBlack} size={140} />
        <Layout.Spacer.Vertical size="tiny" />
        <Skeleton.Text {...Typography.Outfit.bodySmall} />
        <Skeleton.Text {...Typography.Outfit.bodySmall} />
        <Skeleton.Text {...Typography.Outfit.bodySmall} />
      </div>
      <div className="flex flex-row-reverse p-4 items-center gap-4">
        <Skeleton.Text {...Typography.Outfit.bodySmall} size={80} />
      </div>
    </div>
  </Skeleton.Ping>
);

export default MilestoneListItemSkeleton;
