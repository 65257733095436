import { atom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { getMyCachedIdentity } from '@advisor/api/me';
import { atomWithMutation } from '@advisor/api/apollo';
import ComplexError from '@advisor/utils/ComplexError';
import modifyCachedChatMember from '@advisor/chat/chatMember/modifyCachedChatMember';
import updateChatMemberAtom from '@advisor/chat/chatMember/updateChatMemberAtom';
import {
  EnableStudentJourneyTranslationsDocument,
  EnableStudentJourneyTranslationsMutationOptions,
} from '@advisor/api/generated/graphql';
import { inProgressStudentJourneysAtom } from './journeyTranslationStateAtoms';

const createEnableStudentJourneyTranslationOptions = (
  chatRoomId: string,
): EnableStudentJourneyTranslationsMutationOptions => ({
  update(cache) {
    modifyCachedChatMember(cache, chatRoomId, getMyCachedIdentity(cache), {
      autoTranslateStudentJourney: true,
    });
  },
  refetchQueries: [
    // categories
    'studentJourney',
    'milestoneCategory',
    // milestones
    'milestonesForCategory',
    'milestone',
    'commentsForMilestone',
  ],
});

export const enableJourneyTranslationAtom = (() => {
  const mutationAtom = atomWithMutation(
    EnableStudentJourneyTranslationsDocument,
  );

  return actionAtom(async ({ set }, chatRoomId: string) => {
    set(inProgressStudentJourneysAtom, ['add', chatRoomId]);

    try {
      await set(mutationAtom, {
        variables: { chatRoomId },
        ...createEnableStudentJourneyTranslationOptions(chatRoomId),
      });
    } catch (err) {
      Sentry.captureException(
        new ComplexError(`Failed to enable journey translation`, err),
      );
    }

    set(inProgressStudentJourneysAtom, ['delete', chatRoomId]);
  });
})();

export const disableJourneyTranslationAtom = atom(
  null,
  async (_get, set, chatRoomId: string) => {
    await set(updateChatMemberAtom, {
      chatRoomId,
      values: {
        autoTranslateStudentJourney: false,
      },
      optimistic: true,
    });
  },
);

export const setAutotranslationAtom = actionAtom(
  (
    { set },
    { chatRoomId, enable }: { chatRoomId: string; enable: boolean },
  ) => {
    if (enable) {
      set(enableJourneyTranslationAtom, chatRoomId);
    } else {
      set(disableJourneyTranslationAtom, chatRoomId);
    }
  },
);
