import { useCallback, useState } from 'react';

import { VerificationFormState, VerifyCallback } from './types';

export const VERIFICATION_CODE_LENGTH = 6;

const initialState = {
  state: VerificationFormState.Init,
  code: '',
};

export default function useVerificationForm(verifyCallback: VerifyCallback) {
  const [state, setState] = useState(initialState);

  const onSubmitCode = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      state: VerificationFormState.Loading,
    }));

    const authState = await verifyCallback(state.code);

    if (!authState.success) {
      setState((prevState) => ({
        ...prevState,
        state: VerificationFormState.Failed,
      }));
    }

    return authState;
  }, [state.code, verifyCallback]);

  const onCodeChange = useCallback((code: string) => {
    const filtered = code.replace(/\D/g, '');

    const trimmed =
      filtered.length <= VERIFICATION_CODE_LENGTH
        ? filtered
        : filtered.slice(0, VERIFICATION_CODE_LENGTH);

    if (code.length !== filtered.length) {
      setState({
        state: VerificationFormState.IncorrectCharacters,
        code,
      });
    } else {
      setState({
        state:
          trimmed.length === VERIFICATION_CODE_LENGTH
            ? VerificationFormState.EnableSubmit
            : VerificationFormState.IncorrectLength,
        code,
      });
    }
  }, []);

  return {
    ...state,
    onSubmitCode,
    onCodeChange,
  };
}
