import { atomWithObservable } from 'jotai/utils';

type Observer<T> = {
  next: (value: T) => void;
  error: (error: unknown) => void;
  complete: () => void;
};

type Listener = () => unknown;
type Unsub = () => unknown;

/**
 * Retrieves its value using `getCurrent` once at initialization,
 * then every time `listener` is called.
 */
const atomWithEvent = <T>(
  getCurrent: () => T,
  subscribe: (listener: Listener) => Unsub,
) => {
  return atomWithObservable(
    () => ({
      subscribe(observer: Observer<T>) {
        observer.next(getCurrent());

        const unsubscribe = subscribe(() => {
          observer.next(getCurrent());
        });

        return {
          unsubscribe,
        };
      },
    }),
    { initialValue: getCurrent() },
  );
};

export default atomWithEvent;
