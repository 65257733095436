import React, { PropsWithChildren } from 'react';

export const Body: React.FC<PropsWithChildren> = ({ children }) => (
  <span className="text-sm font-outfit font-normal text-dark-grey-03">
    {children}
  </span>
);

export const Bold: React.FC<PropsWithChildren> = ({ children }) => (
  <span className="font-semibold">{children}</span>
);
