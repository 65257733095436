import { pipe } from 'remeda';
import z, { ZodSchema } from 'zod';
import { atomFamily } from 'jotai/utils';
import { ApolloClient } from '@apollo/client';
import { WritableAtom, atom, useAtom, useSetAtom } from 'jotai';

import soon, { PromiseOrValue } from '@advisor/utils/soon';
import Sentry from '@advisor/utils/Sentry';
import TaskQueue from '@advisor/utils/TaskQueue';
import ComplexError from '@advisor/utils/ComplexError';
import { MeDocument, UpdateUserDocument } from '../generated/graphql';
import { meAtom, getUpdateUserOptimisticResponse } from '../me';
import { clientAtom } from '../apollo';
import UserMetadataLocation from './userMetadataLocation';

export type UserMetadataRecord = z.infer<typeof UserMetadataRecord>;
export const UserMetadataRecord = z.record(z.string(), z.unknown());

async function mutateMetadataRecord(
  apolloClient: ApolloClient<unknown>,
  newMetadata: UserMetadataRecord,
) {
  const metadataJson = JSON.stringify(newMetadata);

  await apolloClient.mutate({
    mutation: UpdateUserDocument,
    variables: {
      metadata: metadataJson,
    },
    update: (cache, mutationResult) => {
      cache.updateQuery(
        {
          query: MeDocument,
        },
        (data) => {
          if (!data || !data.me) {
            return null;
          }

          return {
            __typename: 'Query' as const,
            me: {
              ...data.me,
              metadata: mutationResult.data?.updateUser.metadata ?? '{}',
            },
          };
        },
      );
    },
    optimisticResponse: getUpdateUserOptimisticResponse({
      metadata: metadataJson,
    }),
  });
}

/**
 * Holds all UserMetadata mutations to be performed, so that they can be
 * executed sequentially. This allows two separate updates to be dispatched
 * at the same time, and have them not override each other's changes.
 */
const taskQueue = new TaskQueue();
const metadataRecordAtom = atom(
  // getter
  (get) =>
    pipe(
      get(meAtom),
      soon((me) => {
        try {
          return UserMetadataRecord.parse(JSON.parse(me?.metadata ?? '{}'));
        } catch (e) {
          Sentry.captureException(
            new ComplexError('User metadata is not a valid record', e),
          );
        }

        return {};
      }),
    ),
  // setter
  async (
    get,
    _set,
    location: UserMetadataLocation<ZodSchema>,
    value: unknown,
  ) => {
    return taskQueue.enqueue(async () => {
      const nextRecord = location.computeNext(
        await get(metadataRecordAtom),
        value,
      );

      await mutateMetadataRecord(await get(clientAtom), nextRecord);
    });
  },
);

const resetMetadataAtom = atom(null, (get) =>
  taskQueue.enqueue(async () => {
    await mutateMetadataRecord(await get(clientAtom), {});
  }),
);

/**
 * Provided a location, it returns an atom that allows the retrieval and updates
 * to a specific value from the metadata record.
 */
export const userMetadataAtoms = (() => {
  type UserMetadataAtom<T extends ZodSchema> = WritableAtom<
    PromiseOrValue<z.infer<T> | undefined>,
    [z.infer<T> | undefined],
    Promise<void>
  >;

  const family = atomFamily(
    (
      location: UserMetadataLocation<ZodSchema>,
    ): UserMetadataAtom<ZodSchema> => {
      return atom(
        // getter
        (get) =>
          pipe(
            get(metadataRecordAtom),
            soon((record) => location.getFrom(record)),
          ),
        // setter
        async (_, set, newValue: unknown) => {
          await set(metadataRecordAtom, location, newValue);
        },
      );
    },
  );

  return <T extends ZodSchema>(location: UserMetadataLocation<T>) => {
    return family(location) as UserMetadataAtom<T>;
  };
})();

export function useResetUserMetadata() {
  return useSetAtom(resetMetadataAtom);
}

export default function useUserMetadata<Schema extends ZodSchema>(
  location: UserMetadataLocation<Schema>,
) {
  // An atom representing the metadata value under the specific location.
  const metadataAtom = userMetadataAtoms(location);

  return useAtom(metadataAtom);
}
