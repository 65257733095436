import {
  MicrobotMemoryInfoFragment,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphqlTypes';

const useIsMemoryProcessing = (memory: MicrobotMemoryInfoFragment) => {
  return memory.status === MicrobotMemoryProcessingStatus.InProgress;
};

export default useIsMemoryProcessing;
