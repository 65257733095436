import cs from 'classnames';
import React, { memo } from 'react';

import { ChatRoomStatus } from '@advisor/api/generated/graphql';
import { Dropdown, StatusIcon } from '@advisor/design/components';
import { useStatusName } from '../../../ChatRoomStatus';

interface StatusDropdownItemProps {
  active: boolean;
  status: ChatRoomStatus;
  onPress: () => void;
}

const StatusDropdownItem: React.FC<StatusDropdownItemProps> = (props) => {
  const { active, status, onPress } = props;

  const statusName = useStatusName(status);

  const activeTextColor = status.color ?? '#000000';

  return (
    <Dropdown.Item
      onPress={onPress}
      label={({ focused, open }) => (
        <div
          className={cs(
            'py-1.5 min-h-[2.25rem] px-4 flex items-center justify-between text-left',
            !(active || focused || open) && 'text-dark-grey-02',
            (focused || open) && 'sm:bg-light-grey',
          )}
          style={
            active || focused || open ? { color: activeTextColor } : undefined
          }
        >
          <span>{statusName}</span>
          <StatusIcon
            className={cs(
              (active || focused) && 'after:origin-center after:scale-[1.6]',
            )}
            color={activeTextColor}
          />
        </div>
      )}
    />
  );
};

export default memo(StatusDropdownItem);
