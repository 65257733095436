import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { NetworkStatus } from '@apollo/client';

import {
  useChatRoomsQuery,
  NewChatRoomDocument,
  DeletedChatRoomDocument,
  UpdatedChatRoomDocument,
} from '@advisor/api/generated/graphql';
import { useMyId } from '@advisor/api/me';
import { useDebounced, useEvent } from '@advisor/utils/hooks';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { searchByNameAtom, statusFilterAtom } from './atoms';
import { updateChatRooms, deleteChatRooms } from './utils';

export default function useChatRooms() {
  const statusFilter = useAtomValue(statusFilterAtom);
  const searchByName = useAtomValue(searchByNameAtom);
  const debouncedSearchByName = useDebounced(searchByName, 200);

  const myId = useMyId();
  const {
    data,
    error,
    loading,
    refetch,
    fetchMore,
    networkStatus,
    subscribeToMore,
  } = useChatRoomsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      status: statusFilter?.id ?? null,
      nameContains: debouncedSearchByName,
    },
  });

  const { pageInfo } = data?.chatRooms ?? {};

  const chatRooms = useMemo(
    () => (data?.chatRooms.edges ?? []).map(({ node }) => node),
    [data?.chatRooms.edges],
  );

  const loadMore = useEvent(() => {
    if (
      !loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          status: statusFilter?.id ?? null,
          after: pageInfo.endCursor,
          nameContains: debouncedSearchByName,
        },
      });
    }
  });

  useMoreSubscription(
    NewChatRoomDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { newChatRoom } = subscriptionData.data;

      if (!newChatRoom?.chatRoom || !myId) {
        return prev;
      }

      return updateChatRooms(prev, {
        node: newChatRoom.chatRoom,
        cursor: { myId },
      });
    },
  );

  useMoreSubscription(
    UpdatedChatRoomDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { updatedChatRoom } = subscriptionData.data;

      if (!updatedChatRoom?.chatRoom || !myId) {
        return prev;
      }

      return updateChatRooms(prev, {
        node: updatedChatRoom.chatRoom,
        cursor: { myId },
      });
    },
  );

  useMoreSubscription(
    DeletedChatRoomDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { deletedChatRoom } = subscriptionData.data;

      if (!deletedChatRoom?.chatRoom) {
        return prev;
      }

      return deleteChatRooms(prev, deletedChatRoom.chatRoom.id);
    },
  );

  return {
    error,
    loading: loading || searchByName !== debouncedSearchByName,
    refetch,
    loadMore,
    chatRooms,
    count: data?.chatRooms.count,
    hasMore: !!pageInfo?.hasNextPage,
  };
}
