import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { LoginType } from '@advisor/api/auth';
import { CountDownTimerProps } from './types';

const ReturnKey = '13';

function CountDownTimer(props: CountDownTimerProps) {
  const { onPress, verificationType, onChangeVerificationType } = props;
  const [timeLeft, setTimeLeft] = useState(30);
  const { t } = useTranslation(['common', 'onboarding']);

  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => Math.max(prevTimeLeft - 1, 0));
    }, 1000);

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [timeLeft]);

  const onPressWrapper = useCallback(() => {
    setTimeLeft(30);
    onPress();
  }, [onPress]);

  // Mimic usual button behavior when navigating
  // site elements using keyboard
  const onChangeVerificationTypeKeyDown = useCallback(
    (event: KeyboardEvent<HTMLSpanElement>) => {
      if (event.code === ReturnKey) {
        onChangeVerificationType?.();
      }
    },
    [onChangeVerificationType],
  );

  // span is used because button or other button-like were
  // rendered on the new line even with inline styling
  return (
    <div className="text-sm pt-9">
      {onChangeVerificationType ? (
        <p>
          {t('onboarding:did-not-receive-the-code')}{' '}
          <span
            role="button"
            tabIndex={0}
            className="font-bold underline text-primary hover:text-primary-dark cursor-pointer inline"
            onClick={onChangeVerificationType}
            onKeyDown={onChangeVerificationTypeKeyDown}
          >
            {verificationType === LoginType.EmailAddress
              ? t('onboarding:sign-in-with-phone-number-instead')
              : t('onboarding:sign-in-with-email-instead')}
          </span>{' '}
          {t('common:or')}
        </p>
      ) : (
        <p>{t('onboarding:did-not-receive-the-code')}</p>
      )}
      {timeLeft ? (
        <p className="font-bold">
          {t('onboarding:resend-code-in', {
            time: `00:${String(timeLeft).padStart(2, '0')}`,
          })}
        </p>
      ) : (
        <button
          type="button"
          className="font-bold underline text-primary hover:text-primary-dark"
          onClick={onPressWrapper}
        >
          {t('onboarding:resend-code')}
        </button>
      )}
    </div>
  );
}

export default memo(CountDownTimer);
