import React from 'react';

import MemorySourceStep from './MemorySourceStep';
import UploadConfirmStep from './UploadConfirmStep';
import ChooseMemoryRetrievalStep from './ChooseMemoryRetrievalStep';

const AddMemoryModal: React.FC = () => {
  return (
    <>
      <MemorySourceStep />
      <UploadConfirmStep />
      <ChooseMemoryRetrievalStep />
    </>
  );
};

export default AddMemoryModal;
