import React, { memo, ReactNode } from 'react';

import DeprecatedButton from '../DeprecatedButton';
import { useButtonVariant } from './utils';
import { OptionVariant } from './types';

const ModalOptionButton: React.FC<{
  variant: OptionVariant;
  label: ReactNode;
  testID?: string;
  onPress(): void;
}> = ({ variant, label, testID, onPress }) => {
  const buttonVariant = useButtonVariant(variant);

  return (
    <DeprecatedButton.Large
      testID={testID}
      variant={buttonVariant}
      onPress={onPress}
      outline={variant === 'neutral'}
    >
      {label}
    </DeprecatedButton.Large>
  );
};

export default memo(ModalOptionButton);
