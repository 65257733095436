import type { Length, LengthUnit } from './types';

function useLengthToCSS(
  length: Length | null | undefined,
): string | number | undefined {
  let value = 0;
  let unit: LengthUnit = 'px';
  if (length !== null && length !== undefined) {
    value = typeof length === 'number' ? length : length[0];
    unit = typeof length === 'number' ? 'px' : length[1];
  }

  if (length === null || length === undefined) {
    return undefined;
  }

  if (unit === 'px') {
    return value;
  }

  return `${value}${unit}`;
}

export default useLengthToCSS;
