import React from 'react';
import { useTranslation } from 'react-i18next';
import z from 'zod';

import { UserMetadataLocation } from '@advisor/api/userMetadata';
import OnboardingMessage from '@advisor/design/components/OnboardingMessage';

const ServiceProviderOnboardingMessageMetadataLocation =
  new UserMetadataLocation('serviceProvider.onboardingMessage', z.boolean());

const ConversationsOnboardingMessage: React.FC = () => {
  const { t } = useTranslation('onboarding');

  return (
    <OnboardingMessage.Root
      border
      userMetadataLocation={ServiceProviderOnboardingMessageMetadataLocation}
    >
      <OnboardingMessage.Title>
        {t('guest-host-onboarding-conversation-message-title')}
      </OnboardingMessage.Title>
      <OnboardingMessage.SubTitle>
        {t('guest-host-onboarding-conversation-message-subtitle')}
      </OnboardingMessage.SubTitle>
      <OnboardingMessage.Description>
        {t('guest-host-onboarding-conversation-message-description')}
      </OnboardingMessage.Description>
    </OnboardingMessage.Root>
  );
};

export default ConversationsOnboardingMessage;
