import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import Sentry from '@advisor/utils/Sentry';
import {
  useChatRoomsQuery,
  useCreateChatRoomMutation,
  useJoinViaReferralMutation,
  useLatestMessagesLazyQuery,
  useJoinToConversationViaReferralMutation,
} from '@advisor/api/generated/graphql';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { parseGQLError } from '@advisor/utils/errorParsers';
import { useInvitation } from '@advisor/onboarding';

const isCreatingChatroomAtom = atom(false);

interface Args {
  onSuccess?: (chatId: string) => void | Promise<void>;
}

const useCreateChatRoom = ({ onSuccess }: Args) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const [isCreatingChatRoom, setIsCreatingChatRoom] = useAtom(
    isCreatingChatroomAtom,
  );

  const me = useMe();
  const client = useApolloClient();
  const showModal = useShowModal();
  const invitation = useInvitation();

  const { data: { chatRooms } = {}, loading: isChatRoomsLoading } =
    useChatRoomsQuery();
  const [getLatestMessages] = useLatestMessagesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [createChatRoomMutation] = useCreateChatRoomMutation();
  const [joinViaReferralMutation] = useJoinViaReferralMutation();
  const [joinToConversationViaReferralMutation] =
    useJoinToConversationViaReferralMutation();

  const handleSuccess = useCallback(
    async (chatRoomId: string) => {
      // Fetch messages before calling onSuccess.
      // This reduces the delay between opening new chatRoom
      // and displaying initial message.
      await getLatestMessages({ variables: { chatRoomId } });
      await onSuccess?.(chatRoomId);
      // Refetch after calling onSuccess to avoid remounting MessagesList
      await client.refetchQueries({
        include: ['chatRooms'],
      });
    },
    [client, getLatestMessages, onSuccess],
  );

  const createNewChatRoom = useCallback(async (): Promise<boolean | string> => {
    if (Role.isAdvisor(me)) {
      return false;
    }

    setIsCreatingChatRoom(true);

    try {
      const { data } = await createChatRoomMutation(
        invitation.inviteId
          ? {
              variables: {
                // inviteId contains agency slug
                agency: invitation.inviteId,
              },
            }
          : undefined,
      );

      invitation.clearInvitation();

      if (data?.createChatRoom.id) {
        await handleSuccess(data?.createChatRoom.id);
        setIsCreatingChatRoom(false);
        return true;
      }

      setIsCreatingChatRoom(false);
      return false;
    } catch (error) {
      Sentry.captureException(error);
      setIsCreatingChatRoom(false);
      return parseGQLError(error) ?? false;
    }
  }, [
    me,
    invitation,
    handleSuccess,
    setIsCreatingChatRoom,
    createChatRoomMutation,
  ]);

  const joinExistingChatRoom = useCallback(async (): Promise<
    boolean | string
  > => {
    setIsCreatingChatRoom(true);

    if (
      !invitation.inviteId ||
      (Role.isStudent(me) && (chatRooms?.edges.length ?? 0) > 0)
    ) {
      return false;
    }

    try {
      const { data } = await joinToConversationViaReferralMutation({
        variables: {
          chatRoomId: invitation.inviteId,
        },
      });

      invitation.clearInvitation();

      if (data?.joinToConversationViaReferral.node.id) {
        await handleSuccess(data?.joinToConversationViaReferral.node.id);
        setIsCreatingChatRoom(false);
        return true;
      }

      setIsCreatingChatRoom(false);
      return false;
    } catch (error) {
      Sentry.captureException(error);
      setIsCreatingChatRoom(false);
      return parseGQLError(error) ?? false;
    }
  }, [
    me,
    chatRooms,
    invitation,
    handleSuccess,
    setIsCreatingChatRoom,
    joinToConversationViaReferralMutation,
  ]);

  const createChatRoomWithAdvisor = useCallback(async (): Promise<
    boolean | string
  > => {
    if (!invitation.inviteId || Role.isAdvisor(me)) {
      return false;
    }

    setIsCreatingChatRoom(true);

    try {
      const { data } = await joinViaReferralMutation({
        variables: { advisorId: invitation.inviteId },
      });

      invitation.clearInvitation();

      if (data?.joinViaReferral.__typename === 'StudentChatRoom') {
        await handleSuccess(data.joinViaReferral.id);
        setIsCreatingChatRoom(false);
        return true;
      }

      setIsCreatingChatRoom(false);
      // Typename is 'UserBelongsToAnotherAgencyError'
      return data?.joinViaReferral.__typename ?? false;
    } catch (error) {
      Sentry.captureException(error);
      setIsCreatingChatRoom(false);
      return parseGQLError(error) ?? false;
    }
  }, [
    me,
    invitation,
    handleSuccess,
    setIsCreatingChatRoom,
    joinViaReferralMutation,
  ]);

  const createChatRoom = useCallback(async (): Promise<boolean> => {
    if (isCreatingChatRoom) {
      return true;
    }

    let result: boolean | string;

    switch (invitation.inviteType) {
      case 'conversation':
        result = await joinExistingChatRoom();
        break;
      case 'advisor':
        result = await createChatRoomWithAdvisor();
        break;
      case 'agency':
      default:
        result = await createNewChatRoom();
    }

    if (result === true) {
      return true;
    }

    if (result === 'UserBelongsToAnotherAgencyError') {
      invitation.clearInvitation();
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'please-check-agency-associated-with-invite-link',
        namespace: 'onboarding',
      });
      return false;
    }

    if (!result) {
      invitation.clearInvitation();
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'sorry-you-are-not-allowed-to-use-this-referral-link',
        namespace: 'onboarding',
      });
      return false;
    }

    const retry = await showModal.info({
      title: t('common:oops-something-went-wrong'),
      theme: 'warning',
      message: t(
        'onboarding:we-encountered-an-error-while-joining-you-to-conversation',
      ),
      options: [
        {
          key: 'try-again',
          label: t('common:try-again'),
          variant: 'neutral',
        } as const,
      ],
    });

    if (retry) {
      return createChatRoom();
    }

    invitation.clearInvitation();
    return false;
  }, [
    t,
    showModal,
    invitation,
    createNewChatRoom,
    isCreatingChatRoom,
    joinExistingChatRoom,
    createChatRoomWithAdvisor,
  ]);

  return {
    createChatRoom,
    createChatRoomDisabled: isCreatingChatRoom || isChatRoomsLoading,
  };
};

export default useCreateChatRoom;
