import { HSVColor } from './types';

// We have to correct the position of the pointers by half of their size so that
// they remain centered
export const PointerSize = 24;

export const isValidHexColor = (hex: string) => {
  return /^#[0-9A-F]{6}$/i.test(hex);
};

function RGBtoHSV(rgbHex: string): HSVColor {
  'worklet';

  if (rgbHex.length !== 7) {
    return { hue: 0, saturation: 0, value: 0 };
  }

  let red = Number.parseInt(`${rgbHex[1]}${rgbHex[2]}`, 16);
  let green = Number.parseInt(`${rgbHex[3]}${rgbHex[4]}`, 16);
  let blue = Number.parseInt(`${rgbHex[5]}${rgbHex[6]}`, 16);

  red /= 255;
  green /= 255;
  blue /= 255;

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const delta = max - min;

  let hue = 0;

  if (delta > 0) {
    if (max === red) {
      hue = (60 * ((green - blue) / delta) + 360) % 360;
    }

    if (max === green) {
      hue = (60 * ((blue - red) / delta) + 120) % 360;
    }

    if (max === blue) {
      hue = (60 * ((red - green) / delta) + 240) % 360;
    }
  }

  const saturation = max === 0 ? 0 : delta / max;

  return {
    hue,
    saturation,
    value: max,
  };
}

function toRGBString(red: number, green: number, blue: number) {
  'worklet';

  return `#${Math.floor(red).toString(16).padStart(2, '0')}${Math.floor(green)
    .toString(16)
    .padStart(2, '0')}${Math.floor(blue)
    .toString(16)
    .padStart(2, '0')}`.toUpperCase();
}

function HSVtoRGB(hsv: HSVColor): string {
  'worklet';

  const { hue, saturation, value } = hsv;

  const c = value * saturation;
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = value - c;

  let r = 0;
  let g = 0;
  let b = 0;

  if (hue < 60 || hue === 360) {
    r = c;
    g = x;
  } else if (hue < 120) {
    r = x;
    g = c;
  } else if (hue < 180) {
    g = c;
    b = x;
  } else if (hue < 240) {
    g = x;
    b = c;
  } else if (hue < 300) {
    r = x;
    b = c;
  } else if (hue < 360) {
    r = c;
    b = x;
  }

  return toRGBString((r + m) * 255, (g + m) * 255, (b + m) * 255);
}

function HSVtoHSL(hsv: HSVColor): string {
  'worklet';

  const { hue, saturation, value } = hsv;

  const lightness = value - (saturation * value) / 2;

  let saturationHSL = 0;

  if (lightness !== 0 && lightness !== 1) {
    saturationHSL = (value - lightness) / Math.min(lightness, 1 - lightness);
  }

  return `hsl(${hue} ${saturationHSL * 100}% ${lightness * 100}%)`;
}

export const Colorspace = {
  RGBtoHSV,
  HSVtoRGB,
  HSVtoHSL,
};

export function toCoordinate(normalizedValue: number = 0, maxSize: number) {
  'worklet';

  return normalizedValue * maxSize - PointerSize / 2;
}

function clamp(value: number, min: number, max: number) {
  'worklet';

  return Math.min(Math.max(value, min), max);
}

export function normalize(value: number, maxSize: number) {
  'worklet';

  return clamp(value, 0, maxSize) / maxSize;
}
