export interface EditArtifactModalProps {
  visible: boolean;
  onClose: () => void;
}

export interface EditTabProps {
  onSave: () => void;
}

export interface ConfirmTabProps {
  onClose: () => void;
}

export interface TextAreaWithPlaceholderProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: boolean;
}

export enum Tab {
  Edit,
  Confirm,
}
