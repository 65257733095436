import React from 'react';

import Fallback from './Fallback';

const EditStudentProfileModalLazy = React.lazy(
  () => import('./EditStudentProfileModal'),
);

export default function EditStudentProfileModal() {
  return (
    <React.Suspense fallback={<Fallback />}>
      <EditStudentProfileModalLazy />
    </React.Suspense>
  );
}
