import React from 'react';
import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import {
  Modal,
  Layout,
  Weight,
  DeprecatedButton,
} from '@advisor/design/components';
import { AddMicrobotMemoryMolecule, ModalStep } from '../../api';
import { MessageText } from './components';

const UploadConfirmStep: React.FC = () => {
  const { t } = useTranslation(['common', 'microbot']);
  const { modalStepAtom, advanceAtom, closeEditorAtom, attachmentAtom } =
    useMolecule(AddMicrobotMemoryMolecule);

  const advance = useSetAtom(advanceAtom);
  const modalStep = useAtomValue(modalStepAtom);
  const closeEditor = useSetAtom(closeEditorAtom);
  const attachment = useAtomValue(attachmentAtom);

  const onClose = useEvent(() => {
    closeEditor();
  });

  const onConfirm = useEvent(async () => {
    await advance();
  });

  const isBulkUpload = attachment?.uploadType === 'bulk';

  return (
    <Modal.Base
      maxWidth={326}
      onClose={onClose}
      position="floating"
      visible={modalStep === ModalStep.ConfirmUpload}
    >
      <Modal.PopUpHeader
        title={t('microbot:confirm-csv-upload')}
        onClose={onClose}
        message={
          <>
            <Trans
              t={t}
              parent={MessageText}
              i18nKey={
                isBulkUpload
                  ? 'microbot:this-content-will-be-saved-as-multiple-memories'
                  : 'microbot:this-content-will-be-saved-as-a-single-memory'
              }
              components={{ bold: <Weight.Bold> </Weight.Bold> }}
            />
            {'\n\n'}
            <Trans
              t={t}
              parent={MessageText}
              i18nKey={
                isBulkUpload
                  ? 'microbot:each-row-of-the-csv-file-will-be-saved-as-an-individual-memory'
                  : 'microbot:all-contents-of-the-csv-file-will-be-saved-as-one-memory'
              }
            />
          </>
        }
      />
      <Layout.Block padding={24}>
        <DeprecatedButton.Large variant="primary" onPress={onConfirm}>
          {t('microbot:confirm-and-upload')}
        </DeprecatedButton.Large>
        <Layout.Spacer.Vertical size="tiny" />
        <DeprecatedButton.Large variant="darkGrey03" outline onPress={onClose}>
          {t('common:cancel')}
        </DeprecatedButton.Large>
      </Layout.Block>
    </Modal.Base>
  );
};

export default UploadConfirmStep;
