import { z } from 'zod';

import { UserMetadataLocation } from '@advisor/api/userMetadata';
import { UserInfoFragment } from '@advisor/api/generated/graphql';

export const sortUsers = (users: UserInfoFragment[]) => {
  return [...users].sort((memberA, memberB) => {
    if (memberA.__typename === 'User') {
      return -1;
    }

    if (memberB.__typename === 'User') {
      return 1;
    }

    if (memberA.name > memberB.name) {
      return -1;
    }

    if (memberA.name < memberB.name) {
      return 1;
    }

    return 0;
  });
};

export const AuthorizeCalendarTooltipLocation = new UserMetadataLocation(
  'calendarIntegration.authorizationTooltip',
  z.boolean(),
);
