import React from 'react';
import { useTranslation } from 'react-i18next';

import OnboardingMessage from '@advisor/design/components/OnboardingMessage';
import { AdvisorOnboardingMessageMetadataLocation } from './metadata';

const AdvisorOnboardingMessage = () => {
  const { t } = useTranslation('onboarding');
  return (
    <OnboardingMessage.Root
      border
      userMetadataLocation={AdvisorOnboardingMessageMetadataLocation}
      testID="advisor-onboarding-message"
    >
      <OnboardingMessage.Title>
        {t('host-onboarding-message-title')}
      </OnboardingMessage.Title>
      <OnboardingMessage.SubTitle>
        {t('host-onboarding-message-subtitle')}
      </OnboardingMessage.SubTitle>
      <OnboardingMessage.Description>
        {t('host-onboarding-message-description')}
      </OnboardingMessage.Description>
    </OnboardingMessage.Root>
  );
};

export default AdvisorOnboardingMessage;
