import React, { useMemo } from 'react';
import cs from 'classnames';

import Icon from '../Icon';
import Dropdown from '../Dropdown';
import SimpleDropdownItem from '../Dropdown/SimpleDropdownItem';
import { Container, Label } from './components';
import { SelectProps } from './types';

function Select<T extends string>(props: SelectProps<T>) {
  const { label, value, options, onSelect } = props;

  const selectedOptionLabel = useMemo(() => {
    return options.find((opt) => opt.value === value)?.label ?? '';
  }, [options, value]);

  const hasLabel = !!label;

  return (
    <div className="w-full">
      <Dropdown.Root
        trigger={
          <button type="button" className="ltr:text-left rtl:text-right w-full">
            <Container className="flex items-center flex-1 justify-between">
              {hasLabel && <Label>{label}</Label>}
              <div className={cs('p-4', hasLabel && 'pt-9')}>
                {selectedOptionLabel}
              </div>
              <Icon name="ChevronRight" />
            </Container>
          </button>
        }
      >
        <Dropdown.Items>
          {options.map((option) => (
            <SimpleDropdownItem
              key={option.value}
              label={option.label}
              onPress={() => {
                onSelect(option.value);
              }}
            />
          ))}
        </Dropdown.Items>
      </Dropdown.Root>
    </div>
  );
}

export default Select;
