import { ReactNode, memo } from 'react';
import { useOutlet } from 'react-router';

import SideModalBody from './SideModalBody';
import SideModalTitle from './SideModalTitle';
import SideModalHeader from './SideModalHeader';

type Props = {
  title: string;
  scroll?: boolean;
  subtitle?: string;
  padding?: boolean;
  children?: ReactNode;
  backButton?: boolean;
  backButtonDisabled?: boolean;
  className?: string | boolean;
  onClose?: () => void;
};

const SideModalDefault: React.FC<Props> = (props) => {
  const {
    padding,
    children,
    backButton,
    backButtonDisabled,
    className,
    scroll,
    onClose,
  } = props;

  const outlet = useOutlet();

  return (
    <>
      <div className={outlet ? 'hidden' : 'contents'}>
        <SideModalHeader
          backButton={backButton}
          backButtonDisabled={backButtonDisabled}
          onClose={onClose}
        >
          <SideModalTitle {...props} />
        </SideModalHeader>
        <SideModalBody padding={padding} className={className} scroll={scroll}>
          {children}
        </SideModalBody>
      </div>
      {outlet}
    </>
  );
};

export default memo(SideModalDefault);
