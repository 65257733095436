import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvisorRole,
  UserInfoFragment,
  useKickFromChatRoomMutation,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useMyId } from '@advisor/api/me';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { showToast } from '@advisor/design/components/Toast';
import { Scope } from '@advisor/api/scope';

const useKickFromChatRoom = (
  chatRoomId: string | undefined,
  user: UserInfoFragment,
) => {
  const [kickFromChatRoom, { loading }] = useKickFromChatRoomMutation();

  const [wasKicked, setWasKicked] = useState(false);

  const { t } = useTranslation('common');
  const showModal = useShowModal();

  const myId = useMyId();
  const isLeadingAdvisor = useAtomValue(
    chatRoomId ? Scope.leadingAdvisor(chatRoomId) : Scope.unavailable,
  );

  // Currently we only allow kicking non-leading verified advisors by the leading advisor
  const canBeKicked =
    isLeadingAdvisor &&
    myId !== user.id &&
    user.__typename === 'Advisor' &&
    user.advisorRole !== AdvisorRole.AspiringAdvisor;

  const kick = useCallback(async () => {
    if (chatRoomId) {
      const confirm = await showModal.confirm({
        variant: 'severe',
        title: t('are-you-sure-you-want-to-kick', { name: user.name }),
        message: t('the-user-will-be-able-to-rejoin'),
        confirmActionLabel: t('kick'),
      });

      if (!confirm) {
        return;
      }

      try {
        await kickFromChatRoom({
          variables: { userId: user.id, chatRoomId },
        });
        setWasKicked(true);
        showToast({
          iconName: 'X',
          variant: 'rose',
          messageI18Key: 'selected-user-was-kicked',
        });
      } catch (error) {
        Sentry.captureException(error);
        showToast({
          iconName: 'X',
          variant: 'rose',
          messageI18Key: 'oops-something-went-wrong',
        });
      }
    }
  }, [chatRoomId, kickFromChatRoom, showModal, t, user]);

  return { canBeKicked, kick, loading: loading || wasKicked };
};

export default useKickFromChatRoom;
