import { atom } from 'jotai';

import { PermissionStatus } from './types';

export const cameraPermissionsAtom = atom<PermissionStatus>(
  PermissionStatus.UNDETERMINED,
);

export const microphonePermissionsAtom = atom<PermissionStatus>(
  PermissionStatus.UNDETERMINED,
);
