import { useSetAtom } from 'jotai';
import { useMolecule } from 'bunshi/react';

import MessageInputMolecule from './messageInputMolecule';

export default function useRequestSuggestion() {
  const { requestSuggestionAtom } = useMolecule(MessageInputMolecule);

  return useSetAtom(requestSuggestionAtom);
}
