import { useMolecule } from 'bunshi/react';
import { createScope, molecule } from 'bunshi';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';

const TimerScope = createScope<TimerScopeKey | null>(null);

const TimerMolecule = molecule((_mol, scope) => {
  if (scope(TimerScope) === null) {
    throw new Error(`Please provider a timer scope`);
  }

  return {
    timeLeftAtom: atom<number>(-1),
  };
});

/**
 * Every instance of this class acts as a separate timer scope.
 * Refer to the same instance to refer to the same timer state.
 */
export class TimerScopeKey {
  __emptyClassesAreBad = 'and not typed checked correctly';
}

export function useTimer(scopeKey: TimerScopeKey) {
  const { timeLeftAtom } = useMolecule(TimerMolecule, {
    withScope: [TimerScope, scopeKey],
  });
  const [timeLeft, setTimeLeft] = useAtom(timeLeftAtom);
  const [isRunning, setIsRunning] = useState(false);
  const timeoutHandleRef = useRef<NodeJS.Timeout | null>(null);
  const intervalHandleRef = useRef<NodeJS.Timeout | null>(null);
  const endTimeRef = useRef(0);

  const cleanup = useCallback(() => {
    if (intervalHandleRef.current) {
      clearInterval(intervalHandleRef.current);
      intervalHandleRef.current = null;
    }

    if (timeoutHandleRef.current) {
      clearTimeout(timeoutHandleRef.current);
      timeoutHandleRef.current = null;
    }
  }, []);

  const startInterval = useCallback(
    (time: number) => {
      endTimeRef.current = Date.now() + time * 1000;

      setTimeLeft(time);

      intervalHandleRef.current = setInterval(() => {
        const newTimeLeft = Math.floor(
          (endTimeRef.current - Date.now()) / 1000,
        );

        if (newTimeLeft < 0) {
          cleanup();
          return;
        }

        setTimeLeft(newTimeLeft);
      }, 1000);
    },
    [setTimeLeft, cleanup],
  );

  const startTimer = useCallback(
    (time: number, waitFor?: number) => {
      cleanup();
      setIsRunning(true);

      if (!waitFor) {
        startInterval(time);
        return;
      }

      timeoutHandleRef.current = setTimeout(() => {
        startInterval(time);
      }, waitFor * 1000);
    },
    [startInterval, cleanup],
  );

  const stopTimer = useCallback(() => {
    setIsRunning(false);
    setTimeLeft(-1);
    cleanup();
  }, [setTimeLeft, cleanup]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return { timeLeft, isRunning, startTimer, stopTimer };
}

export function useTimerValue(scopeKey: TimerScopeKey) {
  const { timeLeftAtom } = useMolecule(TimerMolecule, {
    withScope: [TimerScope, scopeKey],
  });
  return useAtomValue(timeLeftAtom);
}
