import LoginLayout from '../components/layouts/LoginLayout';
import LoginForm from '../components/molecules/Login';

function LoginPage() {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
}

export default LoginPage;
