import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Spacing } from '@advisor/design/styles';
import { useMicrobot } from '@advisor/microbots/hooks';
import { Avatar, Icon, Layout } from '@advisor/design/components';
import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import { PersonalityToFeatureKey } from '@advisor/microbots/utils';

const MicrobotCard: React.FC<{ personality: MicrobotPersonality }> = ({
  personality,
}) => {
  const { t } = useTranslation(['common', 'microbot']);

  const isMicrobotsEnabled = useIsFeatureEnabled(Feature.Microbots);
  const isMicrobotEnabled = useIsFeatureEnabled(
    PersonalityToFeatureKey[personality],
  );
  const microbot = useMicrobot(personality, 'required');

  if (!isMicrobotsEnabled || !isMicrobotEnabled) {
    return null;
  }

  return (
    <Link
      to={`/community/microbot/${microbot.personality.toLocaleLowerCase()}`}
    >
      <div className="p-6 rounded-lg bg-white">
        <div className="flex items-center justify-between">
          <div>
            <div className="flex">
              <h4
                className="font-sora leading-5 font-semibold"
                style={{ color: microbot.color }}
              >
                {microbot.name}
              </h4>
              <Icon
                name={microbot.nameIcon}
                color={microbot.color}
                size={Spacing.tiny}
              />
            </div>
            <p className="font-outfit text-dark-grey-02 font-medium text-sm leading-[1.125rem]">
              {t(microbot.communityTitle)}
            </p>
          </div>
          <Avatar
            bordered={false}
            className="rounded-[1.25rem]"
            avatarUrl={microbot.avatarUrl}
            size={Spacing.large}
          />
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <p className="uppercase font-medium font-outfit text-dark-grey-025 text-xs">
          {t('microbot:recommended-for')}
        </p>
        <div className="flex">
          <p className="font-outfit text-sm font-medium leading-[1.125rem] text-dark-grey-03">
            {t(microbot.recommendedFor)}
          </p>
          <Layout.Spacer.Horizontal size={Spacing.large} />
        </div>
      </div>
    </Link>
  );
};

export default MicrobotCard;
