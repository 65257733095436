import React from 'react';

import useUserColor from '@advisor/ui/hooks/useUserColor';
import { StatusBox } from '@advisor/design/components';
import { useMicrobotMessage } from '../../../messaging/messageMolecule';
import { AssistFooterBoxProps } from './types';

function AssistActionsBox(props: AssistFooterBoxProps) {
  const { children } = props;
  const { author } = useMicrobotMessage('required');
  const color = useUserColor(author);

  return (
    <StatusBox width="full" themeColor={color}>
      {children}
    </StatusBox>
  );
}

export default AssistActionsBox;
