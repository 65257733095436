import { useAtomValue } from 'jotai';

import { VideoRoomServiceProvider } from '@advisor/api/generated/graphql';
import callStateAtom from './callStateAtom';

function useIsAPIProviderActive(provider: VideoRoomServiceProvider) {
  const callState = useAtomValue(callStateAtom);
  return callState?.serviceProvider === provider;
}

export default useIsAPIProviderActive;
