import { useEffect, useState } from 'react';

function useWindowSize(): [number, number] {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const recalculate = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    recalculate();

    window.addEventListener('resize', recalculate);
    return () => window.removeEventListener('resize', recalculate);
  }, []);

  return size;
}

export default useWindowSize;
