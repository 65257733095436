import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Icon } from '@advisor/design/components';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';

type Props = {
  className?: string;
};

const ChatSidebarStudent: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation('common');
  const [, setShowSidebar] = useAtom(showSidebarAtom);

  return (
    <div className={className}>
      <div className="grid grid-cols-1 gap-4 p-6">
        <div className="ml-4 mb-2">
          <p className="font-sora font-bold text-sm leading-[1.125rem] text-dark-grey-03 mb-1">
            {t('host-conversation')}
          </p>
          <p className="font-outfit text-sm leading-[1.125rem] font-medium text-dark-grey-0225">
            {/* For now we assume student has only one chatRoom (conversation) - the advisor connect. */}
            {t('count-active', { chatRoomsCount: '01' })}
          </p>
        </div>
        <button
          type="button"
          className="rounded-10 bg-light-grey border border-grey shadow-drop-01 p-4 flex flex-row gap-3 ltr:text-left rtl:text-right"
          onClick={() => setShowSidebar(false)}
        >
          <Icon name="Message" className="text-secondary-dark w-8" />
          <div className="grow text-sm">
            <p className="text-secondary-dark font-sora font-semibold">
              {t('host-connect')}
            </p>
            <p className="text-dark-grey-025">
              {t('conversation-with-host-and-experts')}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ChatSidebarStudent;
