import { useContext, useEffect } from 'react';

import {
  CallSession,
  CustomCallEventTypeOrAll,
  SpecificCustomCallEvent,
} from './types';
import VideoRoomAPIContext from './context';

function useCustomEventsListener<T extends CustomCallEventTypeOrAll>(
  type: T,
  listener: (msg: SpecificCustomCallEvent<T>, sender: CallSession) => void,
) {
  const { onCustomEvent } = useContext(VideoRoomAPIContext) ?? {};

  useEffect(() => {
    if (!onCustomEvent) {
      return undefined;
    }

    const subscription = onCustomEvent(type, listener);

    return () => {
      subscription?.unsubscribe();
    };
  }, [type, listener, onCustomEvent]);
}

export default useCustomEventsListener;
