import cs from 'classnames';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { Spacing } from '@advisor/design/styles';
import { Role, useUserRole } from '@advisor/api/user';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { Avatar, Icon } from '@advisor/design/components';
import useUserColor from '@advisor/ui/hooks/useUserColor';
import { useMicrobotDetailsOf } from '@advisor/microbots/hooks';
import useInterpolatedUsername from '../../hooks/useInterpolatedUsername';
import { AvatarAndNameProps } from './types';

function AvatarAndName({ user }: AvatarAndNameProps) {
  const { chatRoomId } = useActiveChatRoom('required');
  const color = useUserColor(user);
  const role = useUserRole(user);
  const microbot = useMicrobotDetailsOf(user);
  const username = useInterpolatedUsername(user, chatRoomId);

  return (
    <Link
      to={`/chat/${chatRoomId ?? null}/${
        Role.isStudent(user) ? 'user' : 'advisor'
      }/${user.id}`}
      className={cs(
        'flex flex-row flex-nowrap items-center mb-2',
        Role.isAdvisor(user)
          ? 'hover:opacity-75'
          : 'pointer-events-none cursor-default',
      )}
    >
      <Avatar
        size={20}
        className="ltr:mr-2 rtl:ml-2"
        avatarUrl={user.avatarUrl}
        bordered={false}
      />
      <p className="font-bold font-sora text-xs sm:text-sm" style={{ color }}>
        {username}
      </p>
      {microbot && (
        <div className="flex items-start ltr:ml-1 rtl:mr-1 gap-[0.125rem]">
          <p className="font-outfit text-xs sm:text-sm text-dark-grey-01">
            {role}
          </p>
          <Icon name={microbot.nameIcon} color={color} size={Spacing.micro02} />
        </div>
      )}
    </Link>
  );
}

export default memo(AvatarAndName);
