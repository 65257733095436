import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import useConnectionErrorTab from './useConnectionErrorTab';

const ConnectionErrorTab: React.FC = () => {
  const { t } = useTranslation(['common', 'onboarding']);
  const { disabled, onTalkToAssistant, onTryAgain } = useConnectionErrorTab();

  return (
    <div className="text-center p-8 max-w-[20.4rem] flex flex-col items-center mx-auto">
      <h3 className="text-negative font-sora font-bold text-base mb-2">
        {t('onboarding:we-could-not-connect-you')}
      </h3>
      <p className="text-sm font-medium whitespace-pre-line mb-6 text-dark-grey-02">
        {t(
          'onboarding:we-were-unable-to-connect-you-to-conversation-you-were-looking-for',
        )}
      </p>
      <DeprecatedButton.Large
        disabled={disabled}
        label={t('common:try-again')}
        onPress={onTryAgain}
        variant="primary"
      />
      <Layout.Spacer.Vertical size={12} />
      <DeprecatedButton.Large
        disabled={disabled}
        label={t('onboarding:talk-to-an-assistant')}
        onPress={onTalkToAssistant}
        outline
        variant="primary"
      />
    </div>
  );
};

export default ConnectionErrorTab;
