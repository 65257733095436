import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import OnboardingMessage from '@advisor/design/components/OnboardingMessage';
import { Layout } from '@advisor/design/components';
import useChatRoomOnboardingMessage from './useChatRoomOnboardingMessage';

const ChatRoomOnboardingMessage: React.FC = () => {
  const { t } = useTranslation(['common', 'onboarding']);
  const navigate = useNavigate();

  const { hasInvitation, loading, onPress } = useChatRoomOnboardingMessage();

  const handleClick = async () => {
    const result = await onPress();
    if (result) {
      navigate('/chat');
    }
  };

  return (
    <div className="mb-4 max-w-chat-width mx-auto">
      <OnboardingMessage.Root border={false}>
        <OnboardingMessage.Title>
          {t('onboarding:guest-host-onboarding-chatroom-message-title')}
        </OnboardingMessage.Title>
        <OnboardingMessage.SubTitle>
          {t('onboarding:guest-host-onboarding-chatroom-message-subtitle')}
        </OnboardingMessage.SubTitle>
        <p className="font-outfit text-sm font-medium block text-center w-3/4 md:w-2/3 mx-auto text-dark-grey-03">
          {t('onboarding:guest-host-onboarding-chatroom-message-description')}
        </p>
        <Layout.Spacer.Vertical size="tiny" />
        <p className="font-outfit text-sm font-medium block text-center w-3/4 md:w-2/3 mx-auto text-dark-grey-03">
          {t(
            'onboarding:talk-to-an-assistant-host-if-you-have-questions-while-you-wait',
          )}
        </p>
        <Layout.Spacer.Vertical size="small01" />
        <button
          type="button"
          disabled={loading}
          className="bg-primary text-white font-outfit text-sm font-medium rounded-10 py-3 px-10 enabled:hover:opacity-70 transition-opacity disabled:opacity-50"
          onClick={handleClick}
        >
          {hasInvitation
            ? t('common:lets-go')
            : t('onboarding:talk-to-an-assistant')}
        </button>
      </OnboardingMessage.Root>
    </div>
  );
};

export default memo(ChatRoomOnboardingMessage);
