import { useSetAtom } from 'jotai';
import React, { memo } from 'react';
import type { ParseKeys } from 'i18next';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';

import useUserColor from '@advisor/ui/hooks/useUserColor';
import withSeparators from '@advisor/utils/withSeparators';
import { Layout } from '@advisor/design/components';
import SnapList from '@advisor/design/components/SnapList';
import { useMicrobotMessage } from '../../messaging/messageMolecule';
import AssistActionsMolecule from './assistActionsMolecule';
import AssistActionButton from './AssistActionButton';
import AssistFooterLayout, {
  Actions,
  Content,
  Paragraph,
  ExternalLink,
} from './AssistFooterLayout';
import type { AssistActionsBoxProps } from './types';

function ActionsVariant() {
  const { author, actions } = useMicrobotMessage('required');
  const color = useUserColor(author);

  return (
    <Actions>
      {withSeparators(
        actions,
        (index) => (
          <Layout.Spacer.Horizontal key={`separator-${index}`} size="micro" />
        ),
        (action, index) => (
          <SnapList.Item key={`item-${index}`} index={index}>
            <AssistActionButton
              action={action}
              actionIndex={index}
              color={color}
            />
          </SnapList.Item>
        ),
      )}
    </Actions>
  );
}

function MessageVariant() {
  const { t } = useTranslation('microbot');
  const { footerMessage } = useMicrobotMessage('required');
  const { makeArtifactFileName, clickFooterAtom } = useMolecule(
    AssistActionsMolecule,
  );
  const clickFooter = useSetAtom(clickFooterAtom);

  if (!footerMessage) {
    return null;
  }

  const content = footerMessage.content as ParseKeys<'microbot'>;

  if (footerMessage.__typename === 'MicrobotArtifactDownloadFooter') {
    return (
      <Content onPress={clickFooter}>
        <Paragraph>{t(content)}</Paragraph>
        <Layout.Spacer.Vertical size="micro02" />
        <Paragraph>
          {makeArtifactFileName(t, footerMessage.artifactKey)}
        </Paragraph>
      </Content>
    );
  }

  if (footerMessage.__typename === 'MicrobotExternalResourceFooter') {
    return (
      <>
        <Content>
          <Paragraph>{t(content)}</Paragraph>
        </Content>
        <ExternalLink url={footerMessage.url} />
      </>
    );
  }

  return (
    <Content>
      <Paragraph>{t(content)}</Paragraph>
    </Content>
  );
}

function AssistActionsBox(_props: AssistActionsBoxProps) {
  const { footerMessage } = useMicrobotMessage('required');

  return (
    <AssistFooterLayout.Box>
      {footerMessage ? <MessageVariant /> : <ActionsVariant />}
    </AssistFooterLayout.Box>
  );
}

export default memo(AssistActionsBox);
