import cs from 'classnames';
import { useAtomValue } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { useNavigate } from 'react-router';

import { useChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';
import { useLanguage } from '@advisor/language';
import { Spacing } from '@advisor/design/styles';
import {
  Avatar,
  Icon,
  Layout,
  SegmentedGroup,
} from '@advisor/design/components';
import NotApplicable from './web/NotApplicable';
import { JourneyProfileProps } from './types';

const OwnerAvatar = memo(({ chatRoomId }: { chatRoomId?: string }) => {
  const chatRoomCtx = useChatRoom(chatRoomId ?? null);
  const student = useAtomValue(chatRoomCtx?.memberOfOwnerAtom ?? nullAtom);

  return (
    <Avatar
      size={Spacing.iconSize}
      bordered={false}
      className="shrink-0 border border-grey"
      avatarUrl={student?.member.avatarUrl}
    />
  );
});

const JourneyProfile = (props: JourneyProfileProps) => {
  const { name, intake, chatRoomId } = props;

  const navigate = useNavigate();
  const { isRTL } = useLanguage();
  const { t } = useTranslation(['user-role', 'task-organiser']);

  return (
    // Set z-index to 0 to create new stacking content and show background with negative z-index
    <div className="relative z-0" data-cy="student-journey-profile-details">
      <div className="flex flex-row items-center p-4 gap-3 border-b border-grey">
        <OwnerAvatar chatRoomId={chatRoomId} />
        <div className="flex-1 min-w-0 flex flex-col">
          <p className="font-sora font-semibold text-sm">
            {name ?? t('user-role:customer')}
          </p>
          {/* Intake shouldn't be too long */}
          <p className="font-outfit text-sm font-medium text-dark-grey-025 truncate">
            <Trans
              t={t}
              i18nKey="task-organiser:intake-value"
              values={{ intake }}
              components={{
                highlight: intake ? (
                  <span className="text-primary"> </span>
                ) : (
                  <NotApplicable />
                ),
              }}
            />
          </p>
        </div>
      </div>
      <button
        type="button"
        className="w-full pointer-events-auto cursor-pointer"
        onClick={() => navigate('student-profile')}
      >
        <Layout.Row
          natural
          className={cs('group-hover/profile:opacity-80 px-4 py-3')}
        >
          <h5 className="text-sm leading-4-5 text-primary font-medium font-outfit">
            {t('task-organiser:view-complete-profile')}
          </h5>
          <Layout.Spacer.Horizontal />
          <Icon name={isRTL ? 'ChevronLeft' : 'ChevronRight'} size={24} />
        </Layout.Row>
        <SegmentedGroup.Root
          className={cs(
            'absolute inset-0 origin-top transition-transform -z-10',
          )}
        />
      </button>
    </div>
  );
};

export default memo(JourneyProfile);
