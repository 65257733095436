import { useTranslation } from 'react-i18next';
import React, { memo, Suspense } from 'react';

import { DeprecatedButton } from '@advisor/design/components';
import { useMilestoneCategoryQuery } from '@advisor/api/generated/graphql';
import { StepProps } from './types';
import { CompleteAnimationSize } from './web/utils';

const AnimationLazy = React.lazy(() => import('./web/Animation'));

const AnimationFallback = () => (
  <div
    style={{ width: CompleteAnimationSize, height: CompleteAnimationSize }}
  />
);

const CompletedStep: React.FC<StepProps> = (props) => {
  const { onCloseModal, milestone } = props;
  const { t } = useTranslation('task-organiser');

  const { data: { milestoneCategory } = {} } = useMilestoneCategoryQuery({
    variables: { milestoneCategoryId: milestone?.milestoneCategoryId ?? '' },
    skip: !milestone?.milestoneCategoryId,
  });

  return (
    <div className="flex flex-col items-center px-10 pb-8">
      <Suspense fallback={<AnimationFallback />}>
        <AnimationLazy />
      </Suspense>
      <h4 className="font-sora font-bold text-base text-center mb-4 -mt-3">
        {t('one-step-closer')}
      </h4>
      <p className="text-dark-grey-02 font-medium text-sm text-center mb-8">
        {!!milestone?.name && !!milestoneCategory?.name
          ? t('you-have-completed-task-in-category', {
              task: milestone.name,
              category: milestoneCategory.name,
            })
          : t('you-have-completed-a-task')}
      </p>
      <DeprecatedButton.Outline
        variant="primary"
        className="w-64 min-h-[3rem]"
        label={t('return-to-tasks')}
        onPress={onCloseModal}
      />
    </div>
  );
};

export default memo(CompletedStep);
