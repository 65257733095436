import cs from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type SectionListData, type SectionListRenderItem } from 'react-native';

import { DateFormatter } from '@advisor/language';
import {
  DeprecatedButton,
  Icon,
  SectionList,
} from '@advisor/design/components';
import { ArchivalVideoRoomInfoFragment } from '@advisor/api/generated/graphql';
import HistoryListItem from './HistoryListItem';
import useVideoRoomHistory from './useVideoRoomHistory';
import { VideoRoomHistoryBlock, VideoRoomHistoryProps } from './types';
import HistoryLoader from './HistoryLoader';
import useVideoRoomHistoryParams from './useVideoRoomHistoryParams';

const keyExtractor = (videoRoom: ArchivalVideoRoomInfoFragment) =>
  `${videoRoom.chatRoomId}-${videoRoom.count}`;

const SectionHeader = ({
  section,
}: {
  section: SectionListData<
    ArchivalVideoRoomInfoFragment,
    VideoRoomHistoryBlock
  >;
}) => {
  const { t } = useTranslation('common');

  return (
    <h4 className="text-xs font-bold mt-8 mx-6">
      {DateFormatter.calendar(section.date, t)}
    </h4>
  );
};

function LastUsedWrapper(props: {
  children?: React.ReactNode;
  detailsId: number;
}) {
  const { children, detailsId } = props;
  const { detailsId: detailsIdParam } = useVideoRoomHistoryParams();

  const highlighted = detailsId === detailsIdParam;

  return (
    <div
      className={cs(
        'border-2 rounded-10 transition-colors duration-1000',
        highlighted ? 'border-primary' : 'border-transparent',
      )}
    >
      {children}
    </div>
  );
}

const VideoRoomHistory: React.FC<VideoRoomHistoryProps> = (props) => {
  const { t } = useTranslation('common');
  const { chatRoomId, openDetails } = props;
  const { history, hasMore, error, loadMore, isLoading, refetch } =
    useVideoRoomHistory(chatRoomId);

  const renderFooterComponent = useCallback(() => {
    if (isLoading || hasMore) {
      return <HistoryLoader />;
    }

    if (!history.length) {
      return (
        <div className="flex flex-col items-center gap-2">
          <Icon
            name="VideoRoomHistory"
            className="w-16 h-16 text-dark-grey-03"
          />
          <h4>{t('video-room-history-is-empty')}</h4>
        </div>
      );
    }

    return null;
  }, [hasMore, isLoading, history, t]);

  const renderItem: SectionListRenderItem<
    ArchivalVideoRoomInfoFragment,
    VideoRoomHistoryBlock
  > = useCallback(
    ({ item }) => {
      return (
        <div className="mx-6 my-3">
          <LastUsedWrapper detailsId={item.count}>
            <HistoryListItem room={item} openDetails={openDetails} />
          </LastUsedWrapper>
        </div>
      );
    },
    [openDetails],
  );

  if (error) {
    return (
      <div className="flex flex-col items-center gap-2">
        <Icon name="ErrorCircle" className="w-16 h-16 text-dark-grey-03" />
        <h4>{t('oops-something-went-wrong')}</h4>
        <DeprecatedButton.Large
          variant="primary"
          onPress={() => {
            refetch().catch();
          }}
          label={t('try-again')}
        />
      </div>
    );
  }

  return (
    <SectionList
      className="h-full"
      sections={history}
      renderItem={renderItem}
      onEndReached={loadMore}
      onEndReachedThreshold={0.1}
      renderSectionHeader={(info) => <SectionHeader section={info.section} />}
      keyExtractor={keyExtractor}
      ListFooterComponent={renderFooterComponent}
    />
  );
};

export default VideoRoomHistory;
