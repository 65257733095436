import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { NewMessageDocument } from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { useDeleteStreamingBuffer } from './messageStreaming';
import { appendNewMessage } from './cacheUtils';
import useReadMessage from './useReadMessage';
import { SubscriptionParams } from './types';

function useNewUserMessageSubscription({
  subscribeToMore,
}: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;

  const readMessage = useReadMessage(chatRoomId);
  const deleteStreamingBuffer = useDeleteStreamingBuffer();

  useMoreSubscription(
    NewMessageDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      if (!subscriptionData.data.newMessageUser) {
        return prev;
      }

      const { newMessageUser } = subscriptionData.data;

      readMessage();
      deleteStreamingBuffer(newMessageUser.id);
      return appendNewMessage(prev, newMessageUser);
    },
    { chatRoomId: chatRoomId! },
    !!chatRoomId,
  );
}

export default useNewUserMessageSubscription;
