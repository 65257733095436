import React, { memo, useMemo, useRef } from 'react';
import { FloatingParentProps } from './types';
import { FloatingContext } from './web/context';

const FloatingParent = (props: FloatingParentProps) => {
  const { children } = props;
  const sourceRef = useRef<HTMLDivElement>(null);

  const context = useMemo(() => ({ sourceRef }), []);

  return (
    <div className="relative" ref={sourceRef}>
      <FloatingContext.Provider value={context}>
        {children}
      </FloatingContext.Provider>
    </div>
  );
};

export default memo(FloatingParent);
