import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { JourneyCategorySystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import { BoldText, SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const AllMilestonesCompletedInCategory: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const data = message.data as JourneyCategorySystemMessageInfoFragment;

  const category = data.milestoneCategory?.name ?? data.metadata.categoryName;

  return (
    <Trans
      t={t}
      parent={SimpleMessage}
      i18nKey="congratulations-all-tasks-have-been-completed-in-category"
      values={{ category }}
      components={{ bold: <BoldText /> }}
    />
  );
};

export default memo(AllMilestonesCompletedInCategory);
