import React, { Suspense } from 'react';

function suspenseHOC<P extends object>(
  Component: React.ComponentType<P>,
  Fallback: React.ReactNode | React.ComponentType<P> = null,
) {
  const Wrapper = (props: P) => {
    return (
      <Suspense
        fallback={
          typeof Fallback === 'function' ? <Fallback {...props} /> : Fallback
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

  return Wrapper;
}

export default suspenseHOC;
