import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { useCallback } from 'react';

import { ChatRoomStatusInfoFragment } from '@advisor/api/generated/graphql';
import {
  Form,
  Layout,
  StatusIcon,
  DeprecatedButton,
} from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

import { useStatusName } from '../../ChatRoomStatus';
import editStatusesAtom from '../editStatusesAtom';
import { StatusListItemProps } from './types';
import NameInput from './NameInput';

const StatusListItem: React.FC<StatusListItemProps> = ({
  index,
  onDelete,
  status,
}) => {
  const [state, dispatch] = useAtom(editStatusesAtom);
  const statusName = useStatusName(status);

  const onUpdateStatus = useCallback(
    (update: Partial<ChatRoomStatusInfoFragment>) => {
      dispatch({ type: 'updateStatus', update, index });
    },
    [dispatch, index],
  );

  const onUpdateActive = useCallback(() => {
    dispatch({ type: 'updateActive', index });
  }, [dispatch, index]);

  if (state?.activeIndex === index) {
    return (
      <div className="mx-8 p-4 rounded bg-light-grey border border-grey">
        {/* Form header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <StatusIcon color={status.color ?? '#000000'} />
            <p className="font-sora text-dark-grey-03 font-bold text-sm leading-[1.125rem]">
              {status.name}
            </p>
          </div>
          <DeprecatedButton.Icon
            className="text-rose-secondary-dark"
            icon="DeleteLarge"
            size={Spacing.small01}
            onPress={onDelete}
          />
        </div>
        <Layout.Spacer.Vertical size={Spacing.tiny} />

        {/* Name input */}
        <NameInput
          value={status.name ?? ''}
          onSetValue={(name) => onUpdateStatus({ name })}
        />
        <Layout.Spacer.Vertical size={Spacing.micro02} />

        {/* Color input */}
        <Form.ColorInput
          value={status.color ?? ''}
          onChange={(color) => onUpdateStatus({ color })}
        />
      </div>
    );
  }

  const showBorder =
    index !== (state?.statuses ?? []).length - 1 &&
    index !== (state?.activeIndex ?? 0) - 1;

  return (
    <button
      type="button"
      className="px-12 hover:bg-light-grey transition-colors"
      onClick={onUpdateActive}
    >
      <div
        className={cs(
          'flex items-center gap-3 py-4',
          showBorder && 'border-b border-light-grey',
        )}
      >
        <StatusIcon color={status.color ?? '#000000'} />
        <p className="font-sora text-dark-grey-03 font-bold text-sm leading-[1.125rem]">
          {statusName}
        </p>
      </div>
    </button>
  );
};

export default StatusListItem;
