import { useAtomValue } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { CallSession } from '../types';
import subscribeToCallEvent from './subscribeToCallEvent';
import { dailyStateAtom } from './dailyState';
import { dailySessionToGeneric } from './sessions';
import { DailySession } from './types';

function useOnSessionLeft() {
  const dailyState = useAtomValue(dailyStateAtom);

  const onSessionLeft = useEvent((listener: (session: CallSession) => void) => {
    if (!dailyState) {
      return null;
    }

    const unsubscribe = subscribeToCallEvent(
      dailyState.call,
      'participant-left',
      (event) => {
        const dailySession = event?.participant as DailySession;
        const session = dailySession
          ? dailySessionToGeneric(dailySession)
          : null;

        if (session) {
          listener(session);
        }
      },
    );

    return {
      unsubscribe,
    };
  });

  return onSessionLeft;
}

export default useOnSessionLeft;
