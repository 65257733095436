import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';
import copyToClipboard from '@advisor/utils/copyToClipboard';
import { UserMessageInfoFragment } from '@advisor/api/generated/graphql';

export default async function copyMessageToClipboard(
  message: UserMessageInfoFragment,
) {
  try {
    await copyToClipboard(message.message);

    showToast({
      variant: 'blue',
      iconName: 'Copy',
      messageI18Key: 'copied-to-clipboard',
    });
  } catch (e) {
    Sentry.captureException(e);

    showToast({
      iconName: 'X',
      variant: 'rose',
      messageI18Key: 'cant-access-clipboard',
    });
  }
}
