import React from 'react';

import { SegmentedGroup, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { Section } from './components';
import SideModal from '../SideModal';

const Fallback: React.FC = () => {
  return (
    <>
      <SideModal.Header>
        <Skeleton.Text lineHeight={18} fontSize={14} size={Spacing.huge} />
      </SideModal.Header>
      <SideModal.Body>
        <Section header={<Skeleton.Text lineHeight={16} fontSize={16} />}>
          <SegmentedGroup.Root>
            <PreferencesItemSkeleton />
            <PreferencesItemSkeleton />
          </SegmentedGroup.Root>
        </Section>
        <Section header={<Skeleton.Text lineHeight={16} fontSize={16} />}>
          <SegmentedGroup.Root>
            <PreferencesItemSkeleton />
          </SegmentedGroup.Root>
        </Section>
        <Section header={<Skeleton.Text lineHeight={16} fontSize={16} />}>
          <SegmentedGroup.Root>
            <PreferencesItemSkeleton />
            <PreferencesItemSkeleton />
            <PreferencesItemSkeleton />
          </SegmentedGroup.Root>
        </Section>
      </SideModal.Body>
    </>
  );
};

export default Fallback;

const PreferencesItemSkeleton: React.FC = () => (
  <SegmentedGroup.Item>
    <Skeleton.Ping>
      <div className="flex px-6 py-4">
        <Skeleton.Text lineHeight={18} fontSize={14} size={Spacing.huge} />
      </div>
    </Skeleton.Ping>
  </SegmentedGroup.Item>
);
