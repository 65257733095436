import { atom } from 'jotai';

const nullAtom = atom(
  (_get) => null,
  (_get, _set, ..._values: unknown[]) => {
    // noop
  },
);

export default nullAtom;
