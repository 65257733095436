import cs from 'classnames';
import React, { ReactNode } from 'react';

interface ComponentProps {
  children?: ReactNode;
  className?: string;
}

export const Box: React.FC<ComponentProps> = ({ children, className }) => (
  <div className={cs('px-6', 'xl:bg-white xl:p-8 xl:rounded-lg', className)}>
    {children}
  </div>
);

export const BoxTitle: React.FC<ComponentProps> = ({ children, className }) => (
  <h3
    className={cs(
      'leading-6 font-semibold text-sm font-sora text-dark-grey-04',
      className,
    )}
  >
    {children}
  </h3>
);
