import { useMemo } from 'react';

type Options = {
  isFromMe: boolean;
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  noBottom?: boolean;
};

export function computeBubbleCorners({
  isFromMe,
  isFirstInGroup,
  isLastInGroup,
  noBottom,
}: Options) {
  const roundTopRight = !isFromMe || (isFromMe && isLastInGroup);
  const roundTopLeft = isFromMe;
  let roundBottomRight = !isFromMe;
  let roundBottomLeft = isFromMe || (!isFromMe && isFirstInGroup);

  if (noBottom) {
    roundBottomRight = false;
    roundBottomLeft = false;
  }

  return {
    roundTopRight,
    roundBottomRight,
    roundTopLeft,
    roundBottomLeft,
  };
}

function useBubbleCorners({
  isFromMe,
  isFirstInGroup,
  isLastInGroup,
  noBottom,
}: Options) {
  return useMemo(() => {
    return computeBubbleCorners({
      isFromMe,
      isFirstInGroup,
      isLastInGroup,
      noBottom,
    });
  }, [isFromMe, isFirstInGroup, isLastInGroup, noBottom]);
}

export default useBubbleCorners;
