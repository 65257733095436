import React from 'react';
import cs from 'classnames';

import Icon from '../Icon';
import Dropdown from '../Dropdown';
import { SimpleDropdownItemProps } from './types';

function SimpleDropdownItem({
  label,
  icon,
  testID,
  active,
  submenu,
  onPress,
  disabled,
}: SimpleDropdownItemProps) {
  const activeColor = 'text-primary';
  const focusColor = 'text-dark-grey-03';
  return (
    <Dropdown.Item
      submenu={submenu}
      disabled={disabled}
      onPress={onPress}
      testID={testID}
      label={({ focused, open }) => (
        <div
          className={cs(
            'py-1.5 min-h-[2.25rem] px-4 flex items-center justify-between text-left gap-x-1.5',
            'text-dark-grey-02',
            disabled && 'opacity-50',
            !disabled && (focused || open) && `sm:bg-light-grey`,
            !disabled &&
              (active ? activeColor : (focused || open) && focusColor),
          )}
        >
          <span className="flex-initial ltr:-ml-1 rtl:-mr-1">{icon}</span>
          <span className="flex-1">{label}</span>
          {submenu && (
            <Icon
              name="ArrowHeadRight"
              className="mt-1 rotate-90 sm:mt-0 sm:rotate-0 ltr:-mr-3 rtl:-ml-3 rtl:sm:-scale-x-100"
            />
          )}
        </div>
      )}
    />
  );
}

export default SimpleDropdownItem;
