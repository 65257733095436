import React from 'react';
import { pipe } from 'remeda';

import { withPadding } from '../../hoc';
import type { BlockProps } from './types';

const Block: React.FC<BlockProps> = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

export default pipe(Block, withPadding());
