import cs from 'classnames';
import React, { memo, useCallback } from 'react';

import { InvitationMethodButtonProps } from './types';

const InvitationMethodButton: React.FC<InvitationMethodButtonProps> = (
  props,
) => {
  const { option, isSelected, selectOption } = props;
  const { title, subtitle, url, isRadio, showTitle, isSelectable } = option;

  const onPress = useCallback(() => {
    selectOption(option);
  }, [selectOption, option]);

  return (
    <button
      type="button"
      onClick={onPress}
      className={cs(
        'w-full flex flex-row items-center gap-2 p-4 group',
        'text-start rounded-10 border border-light-grey',
        'transition-colors',
        isSelected ? 'bg-light-grey' : 'hover:bg-light-grey/40',
      )}
      disabled={!isSelectable}
    >
      <div className="flex-1 min-w-0">
        {showTitle && (
          <p
            className={cs(
              'font-sora text-sm font-bold transition-colors',
              isSelected
                ? 'text-primary'
                : 'text-dark-grey-03 group-hover:text-primary',
            )}
          >
            {title}
          </p>
        )}
        {isSelected && (
          <>
            <p className="text-sm text-dark-grey-03 mb-4">{subtitle}</p>
            <p className="text-sm leading-4-5 font-medium text-primary break-words select-text cursor-text">
              {url}
            </p>
          </>
        )}
      </div>
      {/* Custom Radio Button */}
      {isRadio && (
        <div
          className={cs(
            'w-6 h-6 border-2 rounded-full bg-white grid place-items-center transition-colors',
            isSelected
              ? 'border-primary'
              : 'border-grey group-hover:border-primary/50',
          )}
        >
          <div
            className={cs(
              'w-3 h-3 rounded-full bg-primary transition-opacity',
              !isSelected && 'opacity-0 group-hover:opacity-50',
            )}
          />
        </div>
      )}
    </button>
  );
};

export default memo(InvitationMethodButton);
