import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { BannerShellProps } from './types';

function BannerShell({ children, bordered, onStopSharing }: BannerShellProps) {
  const { t } = useTranslation('common');

  return (
    <div className="px-6 pt-4">
      <div
        className={cs(
          'px-6 py-4 text-white flex justify-between rounded-10 border bg-dark-grey-02',
          bordered ? 'border-negative' : 'border-dark-grey-02',
        )}
      >
        <p>{children}</p>
        {onStopSharing && (
          <button type="button" className="font-bold" onClick={onStopSharing}>
            {t('stop-sharing')}
          </button>
        )}
      </div>
    </div>
  );
}

export default BannerShell;
