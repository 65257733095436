import { Atom, atom, useSetAtom } from 'jotai';
import { useMemo } from 'react';

/**
 * @returns A function that can read the current value of `atomToRead` without depending on it.
 */
export default function useReadAtom<Value>(
  atomToRead: Atom<Value>,
): () => Value {
  const readAtom = useMemo(
    () => atom(null, (get) => get(atomToRead)),
    [atomToRead],
  );

  return useSetAtom(readAtom);
}
