import { ApolloClient } from '@apollo/client';

import type { DocumentNode } from '../generated/graphql';

/**
 * Workaround for refetching the subset of `queries` that is currently mounted,
 * otherwise Apollo will throw warnings.
 */
async function refetchQueriesOrIgnore(
  client: ApolloClient<unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queries: DocumentNode<any, any>[],
) {
  const activeQueries = [...client.getObservableQueries('all').values()].map(
    (obs) => obs.options.query,
  );

  const queriesToRefetch = queries.filter((query) =>
    activeQueries.includes(query),
  );

  if (queriesToRefetch.length === 0) {
    return;
  }

  await client.refetchQueries({
    include: queriesToRefetch,
  });
}

export default refetchQueriesOrIgnore;
