import React, { useMemo } from 'react';
import { Menu } from '@headlessui/react';

import { useLanguage } from '@advisor/language';
import Floating from '../Floating';
import {
  DropdownContext,
  DropdownNodeContext,
  DEFAULT_TRANSITION_CONFIG,
} from './context';
import { DropdownContextType, DropdownRootProps } from './types';
import { resolveDirection } from './utils';

type InnerProps = DropdownRootProps & {
  open: boolean;
};

function DropdownRootInner({
  upwards,
  align = 'natural',
  trigger,
  children,
  open,
}: InnerProps) {
  const { isRTL } = useLanguage();
  const resolvedAlign = resolveDirection(isRTL, align);

  const menuContext: DropdownContextType = useMemo(
    () => ({
      transitionConfig: DEFAULT_TRANSITION_CONFIG,
      align: resolvedAlign,
    }),
    [resolvedAlign],
  );

  const alignOffset = useMemo(() => {
    const remSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );

    switch (resolvedAlign) {
      case 'right':
        return 13 * remSize;
      default:
        return 0;
    }
  }, [resolvedAlign]);

  const alignVertically = upwards
    ? Floating.VerticalAlignment.Top
    : Floating.VerticalAlignment.Bottom;

  return (
    <Floating.Parent>
      <span>
        <Menu.Button as={React.Fragment}>
          {typeof trigger === 'function' ? trigger({ open }) : trigger}
        </Menu.Button>
      </span>
      <Floating.Content
        show={open}
        alignVertically={alignVertically}
        alignHorizontally={resolvedAlign}
        overflowBoundsMargin={10}
        transition="fade"
        offset={
          // Adjust offset in the floating content
          // so that it is placed correctly but also
          // contained inside the window.
          { x: -alignOffset, y: 0 }
        }
      >
        <DropdownContext.Provider value={menuContext}>
          <DropdownNodeContext.Provider value={{ depth: 0, open }}>
            {children}
          </DropdownNodeContext.Provider>
        </DropdownContext.Provider>
      </Floating.Content>
    </Floating.Parent>
  );
}

function DropdownRoot(props: DropdownRootProps) {
  const { children } = props;

  return (
    <Menu as="div">
      {({ open }) => (
        <DropdownRootInner {...props} open={open}>
          {children}
        </DropdownRootInner>
      )}
    </Menu>
  );
}

export default DropdownRoot;
