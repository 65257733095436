import { useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { useJoinStudentConversationMutation } from '@advisor/api/generated/graphql';
import { SearchFormErrorType, SearchModalState } from '../types';
import { searchFormStateAtom } from '../atoms';
import { StudentSearchFormProps } from './types';
import useTalkToAssistant from '../useTalkToAssistant';

const useSearchForm = (props: StudentSearchFormProps) => {
  const { inputs, searchError, setSearchError } = props;

  const setState = useSetAtom(searchFormStateAtom);

  const { onTalkToAssistant, loading: loadingTalkToAssistant } =
    useTalkToAssistant();

  const [joinConversation, { loading: loadingJoinConversation }] =
    useJoinStudentConversationMutation();

  const showDetailedSearch =
    searchError?.type === SearchFormErrorType.INSUFFICIENT_INFO;
  const loading = loadingJoinConversation || loadingTalkToAssistant;

  const onClose = useEvent(() => {
    setState(undefined);
  });

  const onSearchAndJoin = useEvent(async () => {
    try {
      const { data } = await joinConversation({
        variables: {
          studentName: inputs.studentName.value,
          advisorName: inputs.advisorName.value || undefined,
          studentEmail: inputs.studentEmail.value || undefined,
          studentPhoneNumber: inputs.studentPhone.value || undefined,
        },
      });

      const result = data?.joinStudentConversation;

      if (!result) {
        throw new Error('No data returned from joinConversation mutation');
      }

      if (
        result.__typename === 'JoinStudentConversationInsufficientInfoError'
      ) {
        setSearchError({
          type: SearchFormErrorType.INSUFFICIENT_INFO,
          studentName: inputs.studentName.value,
        });
        return;
      }

      if (result.__typename === 'JoinStudentConversationMissingChatRoomError') {
        setSearchError({
          type: SearchFormErrorType.MISSING_CHATROOM,
        });
        return;
      }

      if (result.__typename === 'JoinStudentConversationInvalidInputError') {
        setState(SearchModalState.ERROR);
        return;
      }

      if (result.__typename === 'FamilyJoinRequest') {
        setState(SearchModalState.SUCCESS);
      }
    } catch (error) {
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'Warning',
      });
      Sentry.captureException(error);
    }
  });

  return {
    inputs,
    loading,
    searchError,
    showDetailedSearch,

    onClose,
    onSearchAndJoin,
    onTalkToAssistant,
  };
};

export default useSearchForm;
