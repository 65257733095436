import cs from 'classnames';
import React, { memo } from 'react';

import { Colors } from '@advisor/design/styles';
import Tooltip from '@advisor/design/components/Tooltip';
import withSeparators from '@advisor/utils/withSeparators';
import { Layout, Icon } from '@advisor/design/components';
import { TooltipLayoutProps } from './types';

const TooltipLayout: React.FC<TooltipLayoutProps> = (props) => {
  const {
    body,
    title,
    actions,
    elevate,
    onClose,
    iconName,
    position,
    isVisible = true,
  } = props;

  return (
    <Tooltip.Content
      visible={isVisible}
      variant="light"
      showBackdrop
      maxWidth={250}
      screenMargin={24}
      elevate={elevate}
      onClose={onClose}
      position={position}
      className="max-w-[230px]"
    >
      <div className="flex flex-row items-center">
        {typeof title === 'string' ? (
          <span className="font-sora font-md font-bold">{title}</span>
        ) : (
          title
        )}
        <Layout.Spacer.Horizontal />
        <Icon name={iconName} size={24} color={Colors.darkGrey03} />
      </div>
      <Layout.Spacer.Vertical size="micro02" />
      <span>{body}</span>
      <Layout.Spacer.Vertical size="tiny" />
      <Layout.Row natural>
        {withSeparators(
          actions,
          (index) => (
            <Layout.Spacer.Horizontal key={index} size="micro02" />
          ),
          (action) => (
            <button
              key={action.label}
              type="button"
              onClick={action.onPress}
              className={cs(
                'px-6 py-[11px] rounded-lg border',
                action.variant === 'primary' &&
                  'bg-primary border-primary shadow-drop-02',
                action.variant === 'outline' &&
                  'border-dark-grey-03 bg-transparent',
              )}
            >
              <span
                className={cs(
                  'text-sm font-md font-outfit leading-none',
                  action.variant === 'primary' && 'text-white',
                  action.variant === 'outline' && 'text-dark-grey-03',
                )}
              >
                {action.label}
              </span>
            </button>
          ),
        )}
      </Layout.Row>
    </Tooltip.Content>
  );
};

export default memo(TooltipLayout);
