import React from 'react';
import { AnimatePingProps } from './types';

const AnimatePing = ({ children, animationDelay = 0 }: AnimatePingProps) => {
  return (
    <div
      className="animate-pulse"
      style={{ animationDelay: `${animationDelay}s` }}
    >
      {children}
    </div>
  );
};

export default AnimatePing;
