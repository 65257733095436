import { Colors } from '../../styles';
import { Theme, ColorPalette } from '../../Theme';
import type { ColorVariant, StaticColor } from './types';

export function isPromise(v: Promise<unknown> | unknown | undefined): boolean {
  return !!v && typeof (v as Promise<unknown>).then === 'function';
}

export function variantToBackground(
  theme: Theme,
  variant: ColorVariant = 'primary',
): string {
  if (theme.colors[variant as keyof ColorPalette]) {
    return theme.colors[variant as keyof ColorPalette];
  }

  return Colors[variant as StaticColor];
}

const whiteForegroundColors = [
  'primary',
  'positiveLight',
  'primaryBlue',
  'purpleDark',
  'negative',
];

export function variantToForeground(variant: ColorVariant = 'primary'): string {
  if (whiteForegroundColors.includes(variant)) {
    return Colors.white;
  }

  return Colors.darkGrey03;
}

export function getBackgroundColor(
  theme: Theme,
  variant: ColorVariant = 'primary',
  isPressed = false,
  isDisabled = false,
  isLoading = false,
): string {
  const isTransparent = isPressed || isDisabled || isLoading;
  const color = variantToBackground(
    theme,
    isTransparent && variant === 'white' ? 'lightGrey' : variant,
  );

  if (isDisabled) {
    return `${color}66`;
  }

  if (isLoading) {
    return `${color}88`;
  }

  if (isPressed) {
    return `${color}CC`;
  }

  return color;
}
