import { useCallback, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';

import { useCommentsForMilestoneQuery } from '@advisor/api/generated/graphql';

const useMilestoneComments = (milestoneId: string) => {
  const {
    data: { commentsForMilestone } = {},
    loading: isLoading,
    error,
    networkStatus,
    refetch,
    fetchMore,
  } = useCommentsForMilestoneQuery({
    fetchPolicy: 'cache-and-network',
    variables: { milestoneId },
  });

  const comments = useMemo(
    () => commentsForMilestone?.edges ?? [],
    [commentsForMilestone],
  );

  const loadMore = useCallback(() => {
    if (
      !isLoading &&
      networkStatus !== NetworkStatus.fetchMore &&
      commentsForMilestone?.pageInfo.hasNextPage &&
      commentsForMilestone?.pageInfo.endCursor
    ) {
      fetchMore({
        variables: {
          milestoneId,
          after: commentsForMilestone.pageInfo.endCursor,
        },
      });
    }
  }, [
    fetchMore,
    isLoading,
    milestoneId,
    networkStatus,
    commentsForMilestone?.pageInfo.endCursor,
    commentsForMilestone?.pageInfo.hasNextPage,
  ]);

  return {
    error,
    comments,
    refetch,
    loadMore,
    isLoading,
    hasMore: !!commentsForMilestone?.pageInfo.hasNextPage,
  };
};

export default useMilestoneComments;
