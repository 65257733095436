import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useCallback } from 'react';

import { myIdAtom } from '@advisor/api/me';
import { Scope } from '@advisor/api/scope';
import {
  Icon,
  Layout,
  Dropdown,
  SegmentedGroup,
  SimpleDropdownItem,
  SimpleDropdownTrigger,
} from '@advisor/design/components';
import withHideIfEmpty from '@advisor/design/components/WithHideIfEmpty';
import JourneyButton from '@advisor/journey/components/JourneyButton';
import { studentJourneyScope } from '@advisor/journey/api/studentJourney';
import { UserInfoFragment } from '@advisor/api/generated/graphql';
import UserList from '@advisor/ui/components/UserList';
import { createInviteLink } from '@advisor/onboarding';
import { InvitationModal } from '@advisor/chat/Invitations';
import {
  LanguageMetadata,
  primaryLanguagesAtom,
  userLanguageAtom,
} from '@advisor/language';
import { canInviteToConversationScope } from '@advisor/chat/UserPreferences/invitePermission';
import { useActiveChatRoom, useChatRoom } from '@advisor/api/chatRoom';
import { nullAtom } from '@advisor/utils/atoms';
import { canAccessPrivateNotes } from '@advisor/chat/UserPreferences';
import useInterpolatedUsername from '@advisor/chat/hooks/useInterpolatedUsername';

type HeaderProps = PropsWithChildren<unknown>;

type SectionProps = PropsWithChildren<{ header?: React.ReactNode }>;

type StudentJourneyButtonProps = { chatId: string | null };

type MemberListProps = {
  members: UserInfoFragment[];
  handleInviteToConversation: () => void;
};

type MediaPreferencesProps = {
  chatId: string | undefined;
};

type ChatRoomInvitationModalProps = {
  chatId: string;
  onClose: () => void;
  isVisible: boolean;
};

export const Header: React.FC<HeaderProps> = ({ children }) => (
  <h1 className="flex justify-between my-3 uppercase text-xs text-dark-grey-02 font-sora font-normal">
    {children}
  </h1>
);

export const Section: React.FC<SectionProps> = withHideIfEmpty(
  ({ header, children }) => (
    <div className="mb-2 xl:mb-4 2xl:mb-8">
      {header && <Header>{header}</Header>}
      {children}
    </div>
  ),
);

export const StudentJourneyButton: React.FC<StudentJourneyButtonProps> = ({
  chatId,
}) => {
  const navigate = useNavigate();

  const showStudentJourney = useAtomValue(
    chatId ? studentJourneyScope(chatId) : Scope.unavailable,
  );

  const openStudentJourney = useCallback(() => {
    navigate(chatId ? `/chat/${chatId}/preferences/journey` : '/chat');
  }, [chatId, navigate]);

  if (!showStudentJourney) {
    return null;
  }

  return (
    <>
      <Layout.Spacer.Vertical size="tiny" />
      <JourneyButton chatRoomId={chatId} onPress={openStudentJourney} />
    </>
  );
};

export const MemberList: React.FC<MemberListProps> = ({
  members,
  handleInviteToConversation,
}) => {
  const { t } = useTranslation(['common', 'onboarding']);

  const chatRoomCtx = useActiveChatRoom();
  const canInviteToConversation = useAtomValue(
    chatRoomCtx
      ? canInviteToConversationScope(chatRoomCtx.chatRoomId)
      : nullAtom,
  );

  return (
    <UserList
      users={members}
      title={t('common:conversation-members')}
      showCount
      variant="grey"
      truncate
      callToAction={
        canInviteToConversation
          ? {
              title: t('onboarding:invite-to-conversation'),
              iconName: 'Link',
              onPress: handleInviteToConversation,
              variant: 'dark',
              appendix: <Icon name="Copy" className="text-primary w-6 h-6" />,
            }
          : undefined
      }
    />
  );
};

export const MediaPreferences: React.FC<MediaPreferencesProps> = ({
  chatId,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const openVideoRoomHistory = () => {
    navigate(
      chatId ? `/chat/${chatId}/preferences/video-room-history` : '/chat',
    );
  };

  const openPrivateNotes = () => {
    navigate(chatId ? `/chat/${chatId}/preferences/notes` : '/chat');
  };

  const isVideoChatHistoryEnabled = useAtomValue(
    chatId ? Scope.videoChatHistoryEnabled(chatId) : Scope.unavailable,
  );

  const canAccessVideoChatHistory = useAtomValue(
    chatId ? Scope.videoChatHistoryAccess(chatId) : Scope.unavailable,
  );

  const arePrivateNotesEnabled = useAtomValue(
    chatId ? canAccessPrivateNotes(chatId) : Scope.unavailable,
  );

  if (!isVideoChatHistoryEnabled && !arePrivateNotesEnabled) {
    return null;
  }

  return (
    <Section header={t('media')}>
      <SegmentedGroup.Root>
        {arePrivateNotesEnabled && (
          <SegmentedGroup.Button
            iconName="ListLocked"
            title={t('private-notes')}
            variant="dark"
            onPress={openPrivateNotes}
          />
        )}
        {canAccessVideoChatHistory && (
          <SegmentedGroup.Button
            testID="videoRoomHistoryButton"
            iconName="VideoRoomHistory"
            title={t('video-room-history')}
            variant="dark"
            onPress={openVideoRoomHistory}
          />
        )}
      </SegmentedGroup.Root>
    </Section>
  );
};

export const ChangeLanguageSegmentedButton: React.FC = () => {
  const [language, changeLanguage] = useAtom(userLanguageAtom);
  const primaryLanguages = useAtomValue(primaryLanguagesAtom);
  const { t } = useTranslation('common');

  return (
    <SegmentedGroup.Item>
      <div className="flex grow flex-col">
        <Dropdown.Root
          trigger={({ open }) => (
            <button
              type="button"
              className="w-full flex justify-between items-center p-4 font-sora text-sm font-semibold transition-colors duration-75 hover:bg-off-white active:bg-white active:text-black rounded-t-10"
            >
              <span className="ltr:mr-3 rtl:ml-3">
                <SimpleDropdownTrigger variant="light" open={open}>
                  <Icon name="Globe" className="text-primary" />
                </SimpleDropdownTrigger>
              </span>
              <span className="grow ltr:text-left rtl:text-right text-primary">
                {t('change-language')}
              </span>
              <span className="font-normal font-outfit">
                {language ? LanguageMetadata[language].native : ''}
              </span>
            </button>
          )}
        >
          <Dropdown.Items>
            {primaryLanguages.map((langKey) => (
              <SimpleDropdownItem
                key={langKey}
                active={language === langKey}
                label={LanguageMetadata[langKey].native}
                onPress={() => changeLanguage(langKey)}
              />
            ))}
          </Dropdown.Items>
        </Dropdown.Root>
      </div>
    </SegmentedGroup.Item>
  );
};

export const ChatRoomInvitationModal: React.FC<
  ChatRoomInvitationModalProps
> = ({ isVisible, onClose, chatId }) => {
  const { t } = useTranslation('onboarding');

  const chatRoomCtx = useChatRoom(chatId);
  const myId = useAtomValue(myIdAtom);
  const owner = useAtomValue(chatRoomCtx.memberOfOwnerAtom)?.member;
  const iAmOwner = myId === owner?.id;

  const ownerName = useInterpolatedUsername(owner, chatId);

  return (
    <InvitationModal
      isVisible={isVisible}
      onClose={onClose}
      options={[
        {
          title: t('invite-to-conversation'),
          url: createInviteLink('conversation', chatId),
          subtitle:
            iAmOwner || !owner
              ? t('please-join-my-conversation-on-platform')
              : t('please-join-my-conversation-with-name-on-platform', {
                  name: ownerName,
                }),
          isRadio: false,
          showTitle: false,
          isSelectable: false,
        },
      ]}
    />
  );
};
