import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import { SimpleMessage, BoldText } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const InviteStudentToConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const { subject } = message.data as DefaultSystemMessageData;

  return (
    <Trans
      t={t}
      i18nKey="conversation-created-for-and-an-invitation-has-been-sent"
      parent={SimpleMessage}
      components={{ bold: <BoldText /> }}
      values={{ member: subject?.name ?? '' }}
    />
  );
};

export default InviteStudentToConversation;
