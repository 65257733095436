import { useSetAtom } from 'jotai';
import { useMemo, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { MilestoneCategoryInfoFragment } from '@advisor/api/generated/graphql';
import { milestoneAddStateAtom } from './atoms';
import useCreateMilestone from './useCreateMilestone';
import useMilestoneForm from './useMilestoneForm';
import { ModalTab } from './types';

function useAddMilestoneModal(
  ownerMilestoneCategory: MilestoneCategoryInfoFragment,
) {
  const setMilestoneAddState = useSetAtom(milestoneAddStateAtom);

  const [activeTab, setActiveTab] = useState(ModalTab.Root);

  const createMilestone = useCreateMilestone();
  const {
    formData: { validateAll, inputs },
    minDueDate,
  } = useMilestoneForm();

  const submit = useEvent(async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validData = validateAll();

    if (!validData) {
      return;
    }

    const success = await createMilestone(ownerMilestoneCategory, validData);

    if (success) {
      showToast({
        messageI18Key: 'new-task-was-added-to-task-organiser',
        namespace: 'task-organiser',
        variant: 'blue',
        iconName: 'CircleCheck',
      });

      setMilestoneAddState(false);
    } else {
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'ErrorCircle',
      });
    }
  });

  const closeModal = useEvent(() => {
    setMilestoneAddState(false);
  });

  const openRootTab = useEvent(() => {
    setActiveTab(ModalTab.Root);
  });

  const openDatePickerTab = useEvent(() => {
    setActiveTab(ModalTab.DatePicker);
  });

  return useMemo(
    () => ({
      minDueDate,
      activeTab,
      inputs,

      submit,
      closeModal,
      openRootTab,
      openDatePickerTab,
    }),
    [
      minDueDate,
      activeTab,
      inputs,
      submit,
      closeModal,
      openRootTab,
      openDatePickerTab,
    ],
  );
}

export default useAddMilestoneModal;
