import { Navigate, To } from 'react-router';
import { FC, PropsWithChildren } from 'react';

import { useIsAuthResolved } from '@advisor/api/auth/isAuthResolved';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';

type Props = PropsWithChildren<{
  redirectTo: To;
}>;

const RequireLoggedIn: FC<Props> = ({ children, redirectTo }) => {
  const isResolved = useIsAuthResolved();
  const isAuthorized = useIsAuthorized();

  if (!isResolved) {
    return null;
  }

  return isAuthorized ? (
    <>
      {/* Hidden element which indicates that user is authorized. */}
      {/* This is used in e2e tests to wait for a successful login. */}
      <p className="hidden" data-cy="authorized-indicator" />
      {children}
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default RequireLoggedIn;
