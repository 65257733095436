import cs from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvisorRole,
  MicrobotPersonality,
} from '@advisor/api/generated/graphql';
import { useMyId } from '@advisor/api/me';
import { Role, useUserRole } from '@advisor/api/user';
import { PermissionGate, Scope } from '@advisor/api/scope';
import useInterpolatedUsername from '@advisor/chat/hooks/useInterpolatedUsername';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { useMicrobotDetailsOf } from '@advisor/microbots/hooks';
import { Avatar, ContextMenu, Icon } from '@advisor/design/components';
import useUserColor from '../../hooks/useUserColor';
import showUserActionsScopeAtom from './showUserActionsScope';
import { UserListItemProps } from './types';
import CalendarTooltip from './CalendarTooltip';
import UserListMenu from './UserListMenu';

const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
  const { t } = useTranslation(['common', 'user-role']);
  const myId = useMyId();
  const userColor = useUserColor(user);

  const chatRoomCtx = useActiveChatRoom();
  const chatRoomId = chatRoomCtx?.chatRoomId;

  const userRole = useUserRole(user);
  const microbot = useMicrobotDetailsOf(user);
  const interpolatedName =
    useInterpolatedUsername(user, chatRoomId) ?? t('user-role:anonymous');

  return (
    <ContextMenu.ManagedOwner className="flex flex-row flex-nowrap p-4 items-center ltr:text-left rtl:text-right w-full min-h-[4.5rem]">
      <Avatar
        size={40}
        className="ltr:mr-3 rtl:ml-3"
        avatarUrl={user.avatarUrl}
        bordered={false}
      />
      <div className="grow">
        <div className="flex flex-row">
          <p
            className="text-sm font-semibold font-sora leading-5"
            style={{ color: userColor }}
          >
            {interpolatedName}
          </p>
          {microbot && (
            <Icon
              name={microbot.nameIcon}
              className={cs(
                microbot.personality === MicrobotPersonality.Astro
                  ? 'h-3 w-3'
                  : 'h-4 w-4',
              )}
              color={userColor}
            />
          )}
          {Role.isAdvisor(user) &&
            user.advisorRole === AdvisorRole.PrimaryAdvisor && (
              <Icon
                name="StarLarge"
                className="my-auto ltr:ml-2 rtl:mr-2"
                color={userColor}
              />
            )}
          <div className="flex-1" />
          {myId === user.id && (
            <p className="text-sm font-semibold text-dark-grey-03">
              {t('common:you')}
            </p>
          )}
        </div>
        <p className="text-sm leading-4 text-dark-grey-025 font-normal">
          {userRole}
        </p>
      </div>
      {microbot?.personality === MicrobotPersonality.Carl && (
        <CalendarTooltip />
      )}
      <PermissionGate
        scope={
          chatRoomId
            ? showUserActionsScopeAtom({
                user,
                chatRoomId,
              })
            : Scope.unavailable
        }
      >
        {() => <UserListMenu user={user} />}
      </PermissionGate>
    </ContextMenu.ManagedOwner>
  );
};

export default UserListItem;
