import { PropsWithChildren } from 'react';

import { LoginType } from '@advisor/api/auth';
import { Gender } from '@advisor/api/generated/graphql';
import { CountryName } from '@advisor/utils/country/internationalDiallingCodes';
import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';
import { BottomSheetProps } from '@advisor/design/components/BottomSheet/types';

export enum VerificationState {
  Unverified,
  Verified,
}

export enum UserRole {
  Student = 'STUDENT',
  Advisor = 'ADVISOR',
  FamilyMember = 'FAMILY_MEMBER',
  ServiceProvider = 'SERVICE_PROVIDER',
}

export interface UserProfile {
  name: string;
  role: UserRole;
  gender: Gender;
  agency: string;
  country: CountryName | '';
  timezone: TimezoneName | '';
  avatarUrl: string;
  email: string;
  emailState: VerificationState;
  phoneNumber: string;
  phoneNumberState: VerificationState;
  emailAuthToken: string;
  phoneNumberAuthToken: string;
  locked: boolean;
}

export interface ErrorState {
  phoneNumber?: boolean;
  email?: boolean;
}

export enum ProfileCreationStep {
  Initial = 'Initial',
  Details = 'Details',
  Verification = 'Verification',
}

export const VerifyError = {
  PhoneNumberAlreadyTakenError: 'PhoneNumberAlreadyTakenError',
  EmailAddressAlreadyTakenError: 'EmailAddressAlreadyTakenError',
  WrongCredentailsEmail: 'Wrong email or verification code.',
  WrongCredentialsPhone: 'Wrong phone number or verification code.',
};

export interface UserRoleSelectOption {
  value: UserRole;
  roleTitle: string;
  roleDescription: string;
}

export interface UserRoleSelectProps {
  options: UserRoleSelectOption[];
  value: UserRole;
  onSelect: (value: UserRole) => void;
  upwards?: boolean;
  disabled?: boolean;
}

export interface UserRoleSelectButtonProps {
  onOpen: () => void;
  roleTitle: string;
  isVisible?: boolean;
  upwards?: boolean;
  disabled?: boolean;
}

export type UserRoleSelectBottomSheetProps = UserRoleSelectProps &
  Omit<BottomSheetProps, 'children'>;

export type UserRoleSelectDropdownProps = PropsWithChildren<{
  upwards?: boolean;
}>;

export interface UserRoleSelectDropdownItemProps {
  active?: boolean;
  onClick: () => void;
  roleTitle: string;
  roleDescription: string;
}

export interface CreateProfileFormProps {
  loginType: LoginType;
  identity: string;
  onRedirectToChat?: () => Promise<void> | void;
}

export interface DetailsStepProps {
  onRedirectToChat?: () => Promise<void> | void;
}
