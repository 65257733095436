import { HorizontalAlignment, Rect, VerticalAlignment } from './types';

export function getX(left: number, width: number, align: HorizontalAlignment) {
  switch (align) {
    case 'right':
      return left + width;
    case 'left':
    default:
      return left;
  }
}

export function getY(top: number, height: number, align: VerticalAlignment) {
  switch (align) {
    case 'bottom':
      return top + height;
    case 'top':
    default:
      return top;
  }
}

export function getBoundsShift(
  overflowBoundsMargin: number | undefined,
  targetRect: Rect,
  screen: { height: number; width: number },
) {
  if (overflowBoundsMargin === undefined) {
    return {
      offsetX: 0,
      offsetY: 0,
    };
  }

  const {
    left: contentLeft,
    top: contentTop,
    width: contentWidth,
    height: contentHeight,
  } = targetRect;

  const leftOverflow = Math.max(0, overflowBoundsMargin - contentLeft);
  const topOverflow = Math.max(0, overflowBoundsMargin - contentTop);
  const rightOverflow = Math.max(
    0,
    contentLeft + contentWidth - screen.width + overflowBoundsMargin,
  );
  const bottomOverflow = Math.max(
    0,
    contentTop + contentHeight - screen.height + overflowBoundsMargin,
  );

  return {
    offsetX: leftOverflow - rightOverflow,
    offsetY: topOverflow - bottomOverflow,
  };
}
