export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string | undefined,
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value ?? '')
    ? (value as unknown as T)
    : undefined;
}

export const nonNullable = <Value>(
  value: Value | null | undefined,
): value is Value => {
  return value !== null && value !== undefined;
};

export type ValueOf<T> = T[keyof T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValueOrReturnType<T> = T extends (...args: any[]) => infer R
  ? R
  : T;

export type RecordFromTuple<
  T extends { [key in TKeyProp]: string | symbol | number }[],
  TKeyProp extends string | symbol | number,
> = {
  [TKey in T[number][TKeyProp]]: Extract<
    T[number],
    { [key in TKeyProp]: TKey }
  >;
};

export type ToPairs<T> = { [key in keyof T]: [key, T[key]] }[keyof T][];

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type WithRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;
