type Props = {
  country: string;
};

function Flag({ country }: Props) {
  return (
    <img
      src={`/images/flags/${country}.jpg`}
      alt={`${country} flag`}
      width={21.33}
      height={16}
    />
  );
}

export default Flag;
