import cs from 'classnames';
import React, { ChangeEvent, useCallback, useId } from 'react';

import { Label, Container, ErrorText } from './components';
import { TextAreaProps } from './types';

const TextArea = ({
  label,
  value,
  errorMessage,
  height = 136,
  onChange,
  testID,
  ...inputProps
}: TextAreaProps) => {
  const textAreaId = useId();

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value),
    [onChange],
  );

  return (
    <>
      <Container hasErrors={!!errorMessage}>
        <label
          className="block outline-primary outline-2 rounded focus-within:outline"
          htmlFor={textAreaId}
        >
          {label && (
            <>
              <div className="h-9" />
              <Label>{label}</Label>
            </>
          )}
          <textarea
            data-cy={testID}
            {...inputProps}
            id={textAreaId}
            value={value}
            onChange={handleOnChange}
            style={{
              height: `${height}px`,
            }}
            className={cs(
              'block ltr:text-left rtl:text-right w-full rounded resize-none px-4 text-sm font-semibold placeholder:font-normal outline-none',
              label ? 'pt-0 pb-4' : 'pt-4 pb-2.5',
            )}
          />
        </label>
      </Container>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
};

export default TextArea;
