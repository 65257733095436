import React from 'react';
import { MoleculeScope, ScopeProvider } from 'bunshi/react';

function scopeProviderHOC<P extends object, T>(
  Component: React.ComponentType<P>,
  scope: MoleculeScope<T>,
  extractScopeValue: (props: P) => T | undefined,
) {
  const Wrapper = (props: P) => {
    return (
      <ScopeProvider scope={scope} value={extractScopeValue(props)}>
        <Component {...props} />
      </ScopeProvider>
    );
  };

  return Wrapper;
}

export default scopeProviderHOC;
