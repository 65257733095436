import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { searchFormStateAtom } from '../atoms';

const useSearchSuccessTab = () => {
  const setModalState = useSetAtom(searchFormStateAtom);

  const onClose = useCallback(() => {
    setModalState(undefined);
  }, [setModalState]);

  return {
    onClose,
  };
};

export default useSearchSuccessTab;
