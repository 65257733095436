import { atomFamily } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import { lazyAtom } from '@advisor/utils/atoms';
import { Scope } from '@advisor/api/scope';
import { chatRoomAtoms } from '@advisor/api/chatRoom';
import { useSetAtomOrNull } from '@advisor/utils/hooks';
import { LanguageCode, userLanguageAtom } from '@advisor/language';
import refetchTranslatableContentAtom from '../contentTranslation/refetchTranslatableContentAtom';
import updateChatMemberAtom from '../chatMember/updateChatMemberAtom';

export const loadingChatRoomLanguageAtom = atom(false);

/**
 * For a verified advisor in a chatroom use membership value
 * otherwise fallback to user language (UI) setting
 */
export const chatRoomLanguageAtoms = atomFamily((chatRoomId: string) => {
  return lazyAtom(
    async (get) => {
      const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);
      const amISingleChatUser = await get(Scope.singleChatUser);

      // 'userLanguageAtom' allows for null value,
      // so lang detection won't fallback to default atom value
      // when i18next and react tree is initializing
      const userLanguage = get(userLanguageAtom) ?? 'en';

      if (!amISingleChatUser) {
        return (
          ((await get(memberOfMeAtom))?.translationLanguage as LanguageCode) ??
          userLanguage
        );
      }

      return userLanguage;
    },
    async (get, set, languageCode: LanguageCode) => {
      set(loadingChatRoomLanguageAtom, true);

      const amISingleChatUser = await get(Scope.singleChatUser);

      try {
        if (!amISingleChatUser) {
          // Changing the chat room language for a Verified Advisor means changing their
          // membership settings
          await set(updateChatMemberAtom, {
            chatRoomId,
            values: {
              translationLanguage: languageCode,
            },
            // Waiting for the backend response instead of optimistically updating to
            // make sure it is safe to refetch translatable content with new translations.
            optimistic: false,
          });
        } else {
          // Changing the chat room language for a Single Chat User means changing their
          // whole app's language
          await set(userLanguageAtom, languageCode);

          await set(refetchTranslatableContentAtom, 'all');
        }
      } finally {
        set(loadingChatRoomLanguageAtom, false);
      }
    },
  );
});

export function useChatRoomLanguage(chatRoomId?: string | undefined | null) {
  return (
    useAtomValue(
      chatRoomId ? chatRoomLanguageAtoms(chatRoomId) : userLanguageAtom,
    ) ?? 'en'
  );
}

export function useSetChatRoomLanguage(chatRoomId?: string | null | undefined) {
  const setChatRoomLanguage = useSetAtomOrNull(
    chatRoomId ? chatRoomLanguageAtoms(chatRoomId) : null,
  );

  return [
    setChatRoomLanguage,
    useAtomValue(loadingChatRoomLanguageAtom),
  ] as const;
}
