import {
  useAnyNewMessageMicrobotSubscription,
  useAnyNewMessageSubscription,
  useAnyNewMessageSystemSubscription,
} from '@advisor/api/generated/graphql';
import { updateChatRoomsAfterMessage } from './utils';

export default function useLatestMessages() {
  useAnyNewMessageSubscription({
    onData: ({ client, data }) => {
      const messageData = data.data?.newMessageUser;

      if (!messageData) {
        return;
      }

      updateChatRoomsAfterMessage(client.cache, messageData);
    },
  });

  useAnyNewMessageSystemSubscription({
    onData: ({ client, data }) => {
      const messageData = data.data?.newMessageSystem;

      if (!messageData) {
        return;
      }

      updateChatRoomsAfterMessage(client.cache, messageData);
    },
  });

  useAnyNewMessageMicrobotSubscription({
    onData: ({ client, data }) => {
      const messageData = data.data?.newMessageMicrobot;

      if (!messageData) {
        return;
      }

      updateChatRoomsAfterMessage(client.cache, messageData);
    },
  });
}
