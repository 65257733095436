import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { DeprecatedButton, Layout, Skeleton } from '@advisor/design/components';
import { Colors } from '@advisor/design/styles';
import { useIsMicrobotActive, useMicrobot } from '@advisor/microbots/hooks';
import { useChatRoomsByTypename } from '@advisor/api/chatRoom';

type Props = {
  personality: MicrobotPersonality;
  onConfigure: () => void;
  onEditAccess: () => void;
};

const Fallback: React.FC = () => (
  <>
    <div className="hidden xl:flex">
      <div className="xl:w-40">
        <Skeleton.Ping>
          <Skeleton.Text fontSize={48} lineHeight={48} />
        </Skeleton.Ping>
      </div>
      <Layout.Spacer.Horizontal size="micro02" />
      <div className="xl:w-40">
        <Skeleton.Ping>
          <Skeleton.Text fontSize={48} lineHeight={48} />
        </Skeleton.Ping>
      </div>
    </div>
    <div className="w-full flex xl:hidden">
      <div className="grow">
        <Skeleton.Ping>
          <Skeleton.Text
            fontSize={48}
            lineHeight={48}
            color={Colors.darkGrey005}
          />
        </Skeleton.Ping>
      </div>
      <Layout.Spacer.Horizontal size="micro02" />
      <div className="grow">
        <Skeleton.Ping>
          <Skeleton.Text
            fontSize={48}
            lineHeight={48}
            color={Colors.darkGrey005}
          />
        </Skeleton.Ping>
      </div>
    </div>
  </>
);

const MicrobotActions: React.FC<Props> = ({
  personality,
  onConfigure,
  onEditAccess,
}) => {
  const { t } = useTranslation(['microbot', 'onboarding']);
  const navigate = useNavigate();

  const { name } = useMicrobot(personality, 'required');
  const { isActive, isLoading } = useIsMicrobotActive(personality);

  const { chatRooms: astroChatRooms, loading } =
    useChatRoomsByTypename('MicrobotChatRoom');

  const onGoToConversation = () => {
    if (astroChatRooms.length) {
      navigate(`/chat/${astroChatRooms[0].id}`);
    } else {
      navigate('/chat');
    }
  };

  if (isLoading || loading) {
    return <Fallback />;
  }

  // As for now Astro is the only microbot with a separate conversation
  if (personality === MicrobotPersonality.Astro) {
    return (
      <>
        <DeprecatedButton.Large
          className="max-xl:flex-1 xl:w-40 text-sm leading-[0.875rem]"
          label={t('microbot:go-to-conversation')}
          variant="primary"
          onPress={onGoToConversation}
        />
        <Layout.Spacer.Horizontal size="micro02" />
        <DeprecatedButton.Large
          className="max-xl:flex-1 xl:w-40 text-sm leading-[0.875rem]"
          label={t('microbot:configure-name', { name })}
          variant="primary"
          onPress={onConfigure}
        />
      </>
    );
  }

  // If the microbot is not active (i.e. the advisor doesn't have any conversation containing it)
  // we only show the 'Invite to Conversation' button
  if (!isActive) {
    return (
      <DeprecatedButton.Large
        className="max-xl:w-full text-sm leading-[0.875rem]"
        label={t('onboarding:invite-to-conversation')}
        variant="primary"
        onPress={onEditAccess}
      />
    );
  }

  return (
    <>
      <DeprecatedButton.Outline
        className="max-xl:flex-1 xl:w-40 text-sm leading-[0.875rem] !py-4"
        label={t('microbot:edit-access')}
        onPress={onEditAccess}
        variant="primary"
      />
      <Layout.Spacer.Horizontal size="micro02" />
      <DeprecatedButton.Large
        className="max-xl:flex-1 xl:w-40 text-sm leading-[0.875rem]"
        label={t('microbot:configure-name', { name })}
        onPress={onConfigure}
        variant="primary"
      />
    </>
  );
};

export default MicrobotActions;
