import React, { Suspense, memo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { PermissionGate, Scope } from '@advisor/api/scope';
import { DeprecatedButton, Layout } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { useLanguage } from '@advisor/language';
import sidebarTabAtom, { SidebarTab } from '@advisor/ui/atoms/sidebarTabAtom';
import { useChatRooms } from '../../ChatRoomList';
import AdvisorOnboardingMessage from '../../AdvisorOnboardingMessage/AdvisorOnboardingMessage';
import SuggestedActions from '../../SuggestedActions/SuggestedActions';
import ServiceProviderOnboardingMessage from '../../StartChatRoom/ServiceProviderOnboardingMessage';
import HeadingTabs from './HeadingTabs';
import SimpleHeading from './SimpleHeading';
import { selectedTabAtom } from '../atoms';
import SearchBar from './SearchBar';
import StatusSelect from './StatusSelect';

const ConversationListHeader: React.FC = () => {
  const conversationsCount = useChatRooms().count;

  const selectedTab = useAtomValue(selectedTabAtom);
  const setSidebarTab = useSetAtom(sidebarTabAtom);

  const { isRTL } = useLanguage();

  return (
    <div className="flex flex-col gap-6 px-6">
      <Suspense fallback={null}>
        <PermissionGate
          scope={Scope.verifiedAdvisor}
          fallback={() => <ServiceProviderOnboardingMessage.Conversations />}
        >
          {() => <AdvisorOnboardingMessage />}
        </PermissionGate>
      </Suspense>
      <PermissionGate scope={Scope.verifiedAdvisor}>
        {() => (
          <SuggestedActions chatRoomsCount={conversationsCount ?? undefined} />
        )}
      </PermissionGate>
      <div className="flex flex-row items-center">
        <DeprecatedButton.Icon
          icon={isRTL ? 'ArrowRight' : 'ArrowLeft'}
          size={Spacing.small01}
          variant="darkGrey03"
          onPress={() => setSidebarTab(SidebarTab.DirectoryList)}
        />
        <Layout.Spacer.Horizontal size="micro02" />
        <PermissionGate
          scope={Scope.approvingAdvisor}
          fallback={() => (
            <SimpleHeading conversationsCount={conversationsCount} />
          )}
        >
          {() => <HeadingTabs conversationsCount={conversationsCount} />}
        </PermissionGate>
      </div>
      {selectedTab === 'chatRooms' && (
        <PermissionGate scope={Scope.verifiedAdvisor}>
          {() => <ConversationsHeader />}
        </PermissionGate>
      )}
    </div>
  );
};

export default memo(ConversationListHeader);

const ConversationsHeader: React.FC = () => {
  return (
    <div className="flex rtl:flex-row-reverse items-center gap-3 justify-between">
      <SearchBar />
      <StatusSelect />
    </div>
  );
};
