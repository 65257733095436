import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Role } from '@advisor/api/user';
import { nullAtom } from '@advisor/utils/atoms';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { useMicrobotDetailsOf } from '@advisor/microbots/hooks';
import { useActiveChatRoom } from '../chatRoom';
import { AdvisorRole, UserInfoFragment } from '../generated/graphql';

const AdvisorRoleToKey = {
  /**
   * Primary hosts in code and primary hosts as shown to the end user are not actually the same.
   * As far as the end user is concerned, there is always exactly one primary advisor per chat-room.
   * In code, these advisors are referred to as 'leading advisor'
   */
  [AdvisorRole.PrimaryAdvisor]: 'user-role:host',
  [AdvisorRole.AssistantAdvisor]: 'user-role:assistant-host',
  [AdvisorRole.AspiringAdvisor]: 'user-role:aspiring-host',
} as const;

const useUserRole = (user?: UserInfoFragment | null | undefined) => {
  const { t } = useTranslation(['common', 'user-role', 'microbot']);
  const microbot = useMicrobotDetailsOf(user);
  const chatRoomCtx = useActiveChatRoom();
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);
  const leadingAdvisorId = chatRoom?.leadingAdvisorId;

  return useMemo(() => {
    if (!user) {
      return null;
    }

    if (Role.isAdvisor(user)) {
      if (user.id === leadingAdvisorId) {
        return t('user-role:primary-host');
      }

      return user.advisorRole in AdvisorRoleToKey
        ? t(AdvisorRoleToKey[user.advisorRole])
        : t('user-role:host');
    }

    if (Role.isFamilyMember(user)) {
      return t('user-role:guest-customer');
    }

    if (Role.isServiceProvider(user)) {
      return t('user-role:guest-host');
    }

    if (Role.isMicrobot(user)) {
      return microbot ? t(microbot.messageSenderTitle) : null;
    }

    if (Role.isStudent(user) && user.isPending) {
      return t('user-role:customer-pending');
    }

    if (Role.isStudent(user)) {
      return t('user-role:customer');
    }

    warnExhaustive(user, '@advisor/api/user/useUserRole');
    return null;
  }, [t, user, microbot, leadingAdvisorId]);
};

export default useUserRole;
