import React, { useCallback } from 'react';
import Upload, { UploadProps } from 'rc-upload';

import Sentry from '@advisor/utils/Sentry';
import { useFileUpload } from '@advisor/api/files';
import { FileUploadProps } from './types';
import resizeImage from './resizeImage';

const FileUpload: React.FC<FileUploadProps> = ({
  children,
  maxResolution,
  onUploadStart,
  onUploadFailure,
  onUploadSuccess,
  ...s3UploadOptions
}) => {
  const uploadFile = useFileUpload('Public', s3UploadOptions);

  type CustomRequestFunction = Exclude<UploadProps['customRequest'], undefined>;
  const customRequest = useCallback<CustomRequestFunction>(
    async ({ file }) => {
      try {
        if (!(file instanceof File)) {
          return;
        }
        onUploadStart?.();

        let fileToUpload = file;
        if (maxResolution) {
          fileToUpload = await resizeImage(file, maxResolution);
        }

        const result = await uploadFile(fileToUpload).promise;

        if (result.ok) {
          onUploadSuccess(result.data);
        } else {
          onUploadFailure?.(result.error);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [
      maxResolution,

      uploadFile,
      onUploadStart,
      onUploadFailure,
      onUploadSuccess,
    ],
  );

  const accept = Array.isArray(s3UploadOptions.accept)
    ? s3UploadOptions.accept.join(',')
    : s3UploadOptions.accept;

  const fileUploadProps: UploadProps = {
    multiple: false,
    type: 'drag',
    accept,
    customRequest,
  };

  return <Upload {...fileUploadProps}>{children}</Upload>;
};

export default FileUpload;
