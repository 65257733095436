import { useLocation, useNavigate } from 'react-router';

import { useEvent } from '@advisor/utils/hooks';
import { MilestoneInfoFragment } from '@advisor/api/generated/graphql';

const useOpenJourneyCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useEvent((milestone: MilestoneInfoFragment) => {
    const canGoBack = location.key !== 'default';

    if (canGoBack) {
      navigate(-1);
      return;
    }

    navigate(
      `/chat/${milestone.chatRoomId}/preferences/journey/${milestone.milestoneCategoryId}`,
      { replace: true },
    );
  });
};

export default useOpenJourneyCategory;
