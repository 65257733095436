import { useMemo } from 'react';

import { useConnectedParticipants } from '../Participants';

function useScreenSharingUsers() {
  const participants = useConnectedParticipants();

  return useMemo(
    () => participants.filter((p) => !!p.session.tracks.screenVideo.track),
    [participants],
  );
}

export default useScreenSharingUsers;
