import { NewMessageMicrobotDocument } from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { appendNewMessage } from './cacheUtils';
import useReadMessage from './useReadMessage';
import { SubscriptionParams } from './types';

function useNewMicrobotMessageSubscription({
  subscribeToMore,
}: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;

  const readMessage = useReadMessage(chatRoomId);

  useMoreSubscription(
    NewMessageMicrobotDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      const { newMessageMicrobot } = subscriptionData.data;

      if (!newMessageMicrobot) {
        return prev;
      }

      readMessage();
      return appendNewMessage(prev, newMessageMicrobot);
    },
    { chatRoomId: chatRoomId! },
    !!chatRoomId,
  );
}

export default useNewMicrobotMessageSubscription;
