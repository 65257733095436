import { createContext } from 'react';

type SideModalContextType = {
  onClose: () => void;
};

const SideModalContext = createContext<SideModalContextType>({
  onClose: () => null,
});

export default SideModalContext;
