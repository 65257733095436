import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Layout } from '@advisor/design/components';
import useSearchSuccessTab from './useSearchSuccessTab';

const SearchSuccess: React.FC = () => {
  const { t } = useTranslation(['common', 'onboarding']);
  const { onClose } = useSearchSuccessTab();

  return (
    <div className="text-center p-8 max-w-[20.4rem] flex flex-col items-center mx-auto">
      <h3 className="text-dark-grey-03 font-sora font-bold text-base mb-2">
        {t('onboarding:request-sent')}
      </h3>
      <p className="text-sm font-medium whitespace-pre-line mb-6 text-dark-grey-02">
        {t('onboarding:please-wait-while-we-try-and-connect-you')}
      </p>
      <DeprecatedButton.Large
        label={t('common:okay')}
        onPress={onClose}
        variant="primary"
      />
      <Layout.Spacer.Vertical size={12} />
    </div>
  );
};

export default SearchSuccess;
