import cs from 'classnames';
import React, { memo } from 'react';

import type { ChatMessageContainerProps } from './types';

const ChatMessageContainer = memo(function Container(
  props: ChatMessageContainerProps,
) {
  const { children, isFromMe, raised } = props;

  return (
    <div
      className={cs(
        'isolate w-fit max-w-[60vw] xl:max-w-sm mx-4 xl:mx-0 mb-1',
        isFromMe
          ? 'self-end rtl:self-start ltr:text-left rtl:text-right'
          : 'rtl:self-end',
        raised && 'z-10',
      )}
    >
      {children}
    </div>
  );
});

export default ChatMessageContainer;
