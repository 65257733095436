import { atom } from 'jotai';

import {
  MicrobotDocument,
  MicrobotPersonality,
  MicrobotQuery,
  MicrobotQueryVariables,
  UpdateMicrobotDocument,
  UpdateMicrobotMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { clientAtom } from '@advisor/api/apollo';
import { showToast } from '@advisor/design/components/Toast';

type EditPrimaryContextState = {
  primaryContext: string;
  changesMade: boolean;
  personality: MicrobotPersonality;
} | null;

type EditPrimaryContextAction =
  | { type: 'open'; initialValue: string; personality: MicrobotPersonality }
  | { type: 'close' }
  | { type: 'update'; value: string }
  | { type: 'submit' };

const PrimaryContextLengthLimit = 5000;

export const { editPrimaryContextAtom, limitExceededAtom } = (() => {
  const internalAtom = atom<EditPrimaryContextState>(null);

  return {
    editPrimaryContextAtom: atom(
      (get) => get(internalAtom),
      async (get, set, action: EditPrimaryContextAction) => {
        if (action.type === 'open') {
          set(internalAtom, {
            primaryContext: action.initialValue,
            changesMade: false,
            personality: action.personality,
          });
        } else if (action.type === 'close') {
          set(internalAtom, null);
        } else if (action.type === 'update') {
          set(internalAtom, {
            primaryContext: action.value,
            changesMade: true,
            personality:
              get(internalAtom)?.personality ?? MicrobotPersonality.Astro,
          });
        } else if (action.type === 'submit') {
          const client = await get(clientAtom);

          try {
            await client.mutate({
              ...mutationOptions(
                get(internalAtom)?.personality ?? MicrobotPersonality.Astro,
              ),
              mutation: UpdateMicrobotDocument,
              variables: {
                personality:
                  get(internalAtom)?.personality ?? MicrobotPersonality.Astro,
                primaryContext: get(internalAtom)?.primaryContext ?? '',
              },
            });

            showToast({
              variant: 'blue',
              iconName: 'CircleCheck',
              namespace: 'microbot',
              messageI18Key: 'primary-context-successfully-edited',
            });
          } catch (error) {
            Sentry.captureException(error);
            showToast({
              iconName: 'X',
              variant: 'rose',
              messageI18Key: 'oops-something-went-wrong',
            });
          } finally {
            set(internalAtom, null);
          }
        }
      },
    ),

    limitExceededAtom: atom((get) => {
      const primaryContext = get(internalAtom)?.primaryContext ?? '';
      return primaryContext.length > PrimaryContextLengthLimit;
    }),
  };
})();

const mutationOptions = (
  personality: MicrobotPersonality,
): UpdateMicrobotMutationOptions => ({
  update(cache, result) {
    const microbot = result.data?.updateMicrobot;

    if (!microbot) {
      return;
    }

    cache.updateQuery<MicrobotQuery, MicrobotQueryVariables>(
      {
        query: MicrobotDocument,
        variables: { personality },
      },
      (prevQuery) => {
        if (!prevQuery) {
          return prevQuery;
        }

        return {
          ...prevQuery,
          microbot: {
            ...prevQuery.microbot,
            ...microbot,
          },
        };
      },
    );
  },
});
