import { atom } from 'jotai';

import { Scope } from '@advisor/api/scope';

export const canDeleteAccountScope = Scope.not(Scope.verifiedAdvisor);

export const canPerformAnyDangerousAccountActionScope = atom((get) =>
  // Note: For now there is only one dangerous action
  get(canDeleteAccountScope),
);
