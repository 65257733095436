import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import useUpdateCategory from '../../hooks/useUpdateCategory';
import { editedCategoryAtom } from '../../usePromptCategoryEditing';
import { CategoryPayload } from '../MilestoneCategoryForm';

const useMilestoneCategoryEditModal = () => {
  const [editedCategory, setEditedCategory] = useAtom(editedCategoryAtom);
  const updateCategory = useUpdateCategory();

  const onClose = useCallback(
    () => setEditedCategory(null),
    [setEditedCategory],
  );

  const visible = !!editedCategory;

  const defaultValues = useMemo(
    () => ({ name: editedCategory?.name ?? '' }),
    [editedCategory?.name],
  );

  const submit = useEvent(async (payload: CategoryPayload) => {
    if (!editedCategory) {
      return;
    }

    setEditedCategory(null);

    const success = await updateCategory(editedCategory, payload.name);

    if (success) {
      showToast({
        messageI18Key: 'selected-category-was-edited',
        namespace: 'task-organiser',
        variant: 'blue',
        iconName: 'CircleCheck',
      });
    } else {
      showToast({
        messageI18Key: 'oops-something-went-wrong',
        variant: 'rose',
        iconName: 'ErrorCircle',
      });
    }
  });

  return {
    visible,
    defaultValues,
    editedCategory,
    submit,
    onClose,
  };
};

export default useMilestoneCategoryEditModal;
