import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';

import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import { AddMicrobotMemoryMolecule } from '../../api';

function useInputPlaceholder() {
  const { t } = useTranslation('microbot');
  const urlsEnabled = useIsFeatureEnabled(Feature.DataHarvestingUrl);
  const filesEnabled = useIsFeatureEnabled(Feature.DataHarvestingFile);

  const { attachmentAtom } = useMolecule(AddMicrobotMemoryMolecule);
  const attachment = useAtomValue(attachmentAtom);

  return useMemo(() => {
    if (attachment) {
      // No placeholder when the attachment is shown.
      return undefined;
    }

    if (urlsEnabled && filesEnabled) {
      return t('add-your-own-text-upload-a-url-or-add-a-document');
    }

    if (urlsEnabled) {
      return t('add-your-own-text-or-upload-a-url');
    }

    if (filesEnabled) {
      return t('add-your-own-text-or-add-a-document');
    }

    return t('add-your-own-text');
  }, [t, urlsEnabled, filesEnabled, attachment]);
}

export default useInputPlaceholder;
