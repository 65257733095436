import React from 'react';
import cs from 'classnames';

import { useLanguage } from '@advisor/language';
import { Spacing } from '../../styles';
import { useTheme } from '../../Theme';
import DeprecatedButton from '../DeprecatedButton';
import { BannerNotificationVariant, BannerPaginationProps } from './types';
import { getPrimaryClass, getPrimaryColor } from './utils';

const BannerPagination: React.FC<BannerPaginationProps> = ({
  count,
  current,
  variant,
  textColor,
  onNext,
  onPrevious,
}) => {
  const theme = useTheme();
  const { isRTL } = useLanguage();

  return (
    <div>
      {count > 1 && (
        <div
          className={cs(
            'flex items-center gap-2 xl:absolute xl:top-3 ltr:xl:right-5 rtl:xl:left-5',
            getPrimaryClass(variant as BannerNotificationVariant),
          )}
          style={{ ...(variant === 'custom' ? { color: textColor } : {}) }}
        >
          <DeprecatedButton.Icon
            disabled={current === 0}
            icon={isRTL ? 'ChevronRight' : 'ChevronLeft'}
            size={Spacing.small01}
            onPress={onPrevious}
            style={{ color: getPrimaryColor(theme, variant, textColor) }}
          />
          <p className="font-outfit font-bold text-xs">
            {current + 1}/{count}
          </p>
          <DeprecatedButton.Icon
            disabled={current === count - 1}
            icon={isRTL ? 'ChevronLeft' : 'ChevronRight'}
            size={Spacing.small01}
            onPress={onNext}
            style={{ color: getPrimaryColor(theme, variant, textColor) }}
          />
        </div>
      )}
    </div>
  );
};

export default BannerPagination;
