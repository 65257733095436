import React, { memo } from 'react';

import { MessageLinkProps } from './types';

const MessageLink = memo(function MessageLink({ children }: MessageLinkProps) {
  // Open links to advisor application in current tab
  // F.e. referral links
  const target = children.includes(document.location.origin)
    ? '_self'
    : '_blank';

  return (
    <a href={children} className="underline" target={target} rel="noreferrer">
      {children}
    </a>
  );
});

export default MessageLink;
