import { useAtomValue } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { CustomCallEvent, EmitCustomEventResult } from '../types';
import { dailyStateAtom } from './dailyState';

function useEmitCustomEvent() {
  const dailyState = useAtomValue(dailyStateAtom);

  const emitCustomEvent = useEvent((msg: CustomCallEvent, to?: string) => {
    if (!dailyState) {
      return EmitCustomEventResult.NotConnected;
    }

    dailyState.call.sendAppMessage(msg, to);
    return EmitCustomEventResult.Sent;
  });

  return emitCustomEvent;
}

export default useEmitCustomEvent;
