import cs from 'classnames';
import React, { ReactNode, InputHTMLAttributes } from 'react';

interface BasicProps {
  children: ReactNode;
  className?: string;
  hasErrors?: boolean;
}

interface LabelProps {
  children: ReactNode;
  className?: string;
  isLighterColor?: boolean;
}

type ErrorProps = Omit<LabelProps, 'isLighterColor'>;

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  testID?: string;
};

export const Container: React.FC<BasicProps> = ({
  children,
  className,
  hasErrors,
}) => (
  <div
    className={cs(
      'border border-dark-grey-01 rounded relative',
      className,
      hasErrors && 'border-negative',
    )}
  >
    {children}
  </div>
);

export const Label: React.FC<LabelProps> = ({
  children,
  className,
  isLighterColor,
}) => (
  <span
    className={cs(
      'font-outfit text-sm font-normal leading-5 pointer-events-none absolute top-4 left-4 right-4',
      isLighterColor ? 'text-dark-grey-01' : 'text-dark-grey-02',
      className,
    )}
  >
    {children}
  </span>
);

export const TextInput: React.FC<TextInputProps> = ({
  className,
  testID,
  ...inputProps
}) => (
  <input
    data-cy={testID}
    {...inputProps}
    className={cs(
      'ltr:text-left rtl:text-right p-4 w-full focus-visible:outline-primary rounded',
      className,
    )}
  />
);

export const ErrorText: React.FC<ErrorProps> = ({ className, children }) => (
  <span className={cs('px-4 font-outfit text-sm text-negative', className)}>
    {children}
  </span>
);

export const ErrorTextSmall: React.FC<ErrorProps> = ({
  className,
  children,
}) => <div className={cs('text-xs text-negative', className)}>{children}</div>;
