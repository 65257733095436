import cs from 'classnames';
import React from 'react';

import { ComponentProps } from './types';

const Paragraph: React.FC<ComponentProps> = ({ children, className }) => {
  return (
    <p
      className={cs(
        'text-sm leading-4-5 md:text-base md:leading-5 font-medium font-outfit',
        className,
      )}
    >
      {children}
    </p>
  );
};

export default Paragraph;
