import Sentry from '@advisor/utils/Sentry';
import { LanguageCode } from '@advisor/language';
import {
  AutotranslationState,
  LangOrToken,
  ToggleTranslationAction,
  TranslationStatus,
  TranslationToken,
} from './types';

/**
 * Determines what action should be taken in order to successfully toggle the given element
 * @param statusType The current translation status of the element
 * @param autotranslationEnabled Is autotranslation enabled for the content space the element is part of
 * @returns The action to perform
 */
export function getContentTranslationToggleAction(
  statusType: TranslationStatus['type'],
  autotranslationEnabled: boolean,
): ToggleTranslationAction | null {
  // Prompting the user to make the decision whether or not they actually
  // wanted to translate the whole content space, or just the single element.
  if (!autotranslationEnabled && statusType === 'raw') {
    return ToggleTranslationAction.PromptTranslationPreference;
  }

  // Prompting the user to make the decision whether or not they actually
  // wanted to undo translation for the whole content space, or just the single element.
  if (
    autotranslationEnabled &&
    (statusType === 'translated' || statusType === 'in_progress')
  ) {
    return ToggleTranslationAction.PromptUndoTranslationPreference;
  }

  if (statusType === 'translated' || statusType === 'in_progress') {
    return ToggleTranslationAction.UndoTranslation;
  }

  if (statusType === 'missing' || statusType === 'raw') {
    return ToggleTranslationAction.Translate;
  }

  Sentry.captureException(
    new Error(
      `Unexpected content translation arguments: status=${statusType}, autotranslation=${autotranslationEnabled}`,
    ),
  );

  return null;
}

type Options = {
  userStatus: LangOrToken | undefined | null;
  inherentLanguage: LanguageCode | null;
  targetLanguage: LanguageCode;
  translations: Partial<Record<LanguageCode, unknown>>;
  autotranslationState: AutotranslationState;
};

export function computeContentTranslationStatus({
  userStatus,
  inherentLanguage,
  targetLanguage,
  translations,
  autotranslationState,
}: Options): TranslationStatus {
  if (
    userStatus === TranslationToken.InProgress ||
    autotranslationState === AutotranslationState.Loading
  ) {
    return { type: 'in_progress' };
  }

  if (
    // Languages match, show raw content.
    inherentLanguage === targetLanguage ||
    // Forcing raw
    userStatus === TranslationToken.Original ||
    // Not automatically, nor manually translated
    (autotranslationState === AutotranslationState.Disabled &&
      userStatus !== targetLanguage) ||
    // TODO: Remove redundant check once no production user has the '__original__' value as their translation status.
    (userStatus as string) === '__original__'
  ) {
    return { type: 'raw' };
  }

  if (!translations[targetLanguage]) {
    return { type: 'missing' };
  }

  return { type: 'translated', language: targetLanguage };
}
