import React, { useCallback } from 'react';

import { useOpenJourneyCategory, useOpenJourneyMilestone } from './navigation';
import { GroupMessageItemProps } from './types';
import MessageButton from './MessageButton';

const GroupMessageItem: React.FC<GroupMessageItemProps> = (props) => {
  const { title, chatRoomId, categoryId, milestoneId, dataTypename } = props;
  const openMilestone = useOpenJourneyMilestone();
  const openCategory = useOpenJourneyCategory();

  const onPress = useCallback(() => {
    switch (dataTypename) {
      case 'JourneyCategorySystemMessageData':
        openCategory(chatRoomId, categoryId);
        return;
      case 'JourneyMilestoneCommentSystemMessageData':
      case 'JourneyMilestoneOverDueSystemMessageData':
      case 'JourneyMilestoneSystemMessageData':
        openMilestone(chatRoomId, categoryId, milestoneId);
        break;
    }
  }, [
    milestoneId,
    categoryId,
    chatRoomId,
    dataTypename,
    openCategory,
    openMilestone,
  ]);

  return <MessageButton label={title} onPress={onPress} border={false} />;
};

export default GroupMessageItem;
