import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';

import Tooltip from '@advisor/design/components/Tooltip';
import { Layout, DeprecatedButton } from '@advisor/design/components';
import { useUserMetadata } from '@advisor/api/userMetadata';
import { useCalendarIntegration } from '@advisor/microbots/hooks';
import { AuthorizeCalendarTooltipLocation } from './utils';

const CalendarTooltip: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('microbot');
  const { isAuthorized, isExpired } = useCalendarIntegration();

  const [hideTooltip, setHideTooltip] = useUserMetadata(
    AuthorizeCalendarTooltipLocation,
  );
  const [isVisible, setIsVisible] = useState(!hideTooltip);

  const onCloseTooltip = () => {
    setIsVisible(false);
    setHideTooltip(true);
  };

  const onShowTooltip = () => {
    setIsVisible(true);
  };

  const onOpenConfiguration = () => {
    onCloseTooltip();
    navigate('/community/microbot/carl/configure');
  };

  if (isAuthorized) {
    return null;
  }

  const title = isExpired
    ? 'google-calendar-bot-needs-re-authorisation'
    : 'configure';

  const description = isExpired
    ? 'google-calendar-needs-your-permission-for-re-access'
    : 'google-calendar-bot-needs-to-be-configured';

  return (
    <Tooltip.Root>
      <DeprecatedButton.Icon
        icon="ErrorTriangle"
        onPress={onShowTooltip}
        variant="negative"
      />
      <Tooltip.Content
        showBackdrop
        variant="light"
        screenMargin={24}
        visible={isVisible}
        onClose={onCloseTooltip}
        position={Tooltip.Position.Below}
        className="max-w-[230px] border-negative-light border"
      >
        <div className="font-sora font-bold text-negative text-sm">
          <Trans t={t} i18nKey={title} components={{ bold: <></> }} />
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <div className="font-outfit font-medium text-dark-grey-03 text-sm">
          <Trans t={t} i18nKey={description} components={{ bold: <></> }} />
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <div className="flex flex-row">
          <DeprecatedButton.Outline
            label={isExpired ? t('re-authorise') : t('configure')}
            onPress={onOpenConfiguration}
          />
        </div>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

export default CalendarTooltip;
