import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';

import ColorPicker from './web/ColorPicker';
import { isValidHexColor } from './utils';
import { ColorInputProps } from './types';

const ColorInput: React.FC<ColorInputProps> = (props) => {
  const { value, onChange, alwaysExpanded } = props;
  const { t } = useTranslation('common');

  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const onChangeInternal = (event: ChangeEvent<HTMLInputElement>) => {
    if (isValidHexColor(event.target.value)) {
      onChange(event.target.value);
    }

    setInputValue(event.target.value);
  };

  const onBlur = () => {
    setInputValue(value);
  };

  const onPickerChange = (hex: string) => {
    onChange(hex);
    setInputValue(hex);
  };

  const isExpanded = alwaysExpanded || expanded;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className="relative rounded border border-dark-grey-01 bg-white"
      onClick={() => setExpanded(true)}
    >
      <span className="h-4 absolute top-4 ltr:left-4 rtl:right-4 font-outfit text-xs text-dark-grey-03 select-none pointer-events-none">
        {t('select-color')}
      </span>
      <div
        className="absolute w-6 h-6 top-5 ltr:right-4 rtl:left-4 rounded border border-grey"
        style={{ backgroundColor: value !== '' ? value : '#FFFFFF' }}
      />
      <input
        className={cs(
          'w-full px-4 pt-8 rounded font-outfit focus:outline-none text-sm leading-[1.125rem] font-medium placeholder-dark-grey-01',
          !isExpanded && 'pb-4',
        )}
        onChange={onChangeInternal}
        onBlur={onBlur}
        value={inputValue}
        placeholder="#FFFFFF"
        maxLength={7}
      />
      {isExpanded && (
        <>
          <div className="h-[1px] bg-light-grey m-4" />
          <div className="px-4 pb-4">
            <ColorPicker
              initialValue={value !== '' ? value : '#FFFFFF'}
              setValue={onPickerChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ColorInput;
