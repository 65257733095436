import { useCallback } from 'react';

import { useMyId } from '@advisor/api/me';
import {
  ChatRoomsQuery,
  ChatRoomsDocument,
  ChatRoomsQueryVariables,
  useUpdateChatRoomMutation,
  ChatRoomWithMembersFragment,
} from '@advisor/api/generated/graphql';
import useUserConfig from '@advisor/api/userConfig/useUserConfig';
import { updateChatRooms, deleteChatRooms } from './utils';

export default function useMarkStatus() {
  const myId = useMyId();
  const [updateChatRoom] = useUpdateChatRoomMutation();

  const { statuses } = useUserConfig();

  return useCallback(
    async (chatRoom: ChatRoomWithMembersFragment, newStatus: string) => {
      const members = chatRoom.members.map((member) => {
        if (member.member.id === myId) {
          return {
            ...member,
            status: newStatus,
          };
        }

        return member;
      });

      const updatedChatRoom: ChatRoomWithMembersFragment = {
        ...chatRoom,
        members,
      };

      await updateChatRoom({
        variables: {
          chatRoomId: chatRoom.id,
          status: newStatus,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateChatRoom: updatedChatRoom,
        },
        update(cache) {
          // Including the `no-filter` query.
          [...statuses.map((s) => s.name), null].forEach((statusFilter) => {
            cache.updateQuery<ChatRoomsQuery, ChatRoomsQueryVariables>(
              {
                query: ChatRoomsDocument,
                variables: { status: statusFilter, nameContains: '' },
              },
              (prev) => {
                if (!prev || !myId) {
                  return prev;
                }

                if (!statusFilter || statusFilter === newStatus) {
                  return updateChatRooms(prev, {
                    node: updatedChatRoom,
                    cursor: { myId },
                  });
                }

                return deleteChatRooms(prev, chatRoom.id);
              },
            );
          });
        },
      });
    },
    [updateChatRoom, myId, statuses],
  );
}
