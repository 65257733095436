import { atomFamily } from 'jotai/utils';

import {
  MicrobotPersonality,
  UserInfoFragment,
} from '@advisor/api/generated/graphql';
import { lazyAtom } from '@advisor/utils/atoms';
import { Scope } from '@advisor/api/scope';
import { Role } from '@advisor/api/user';

const canRemoveMicrobotScope = atomFamily(
  (user: UserInfoFragment) => {
    return lazyAtom(async (get) => {
      const amIPrimaryAdvisor = await get(Scope.primaryAdvisor);

      return (
        amIPrimaryAdvisor &&
        Role.isMicrobot(user) &&
        user.personality !== MicrobotPersonality.Astro
      );
    });
  },
  (a, b) => a.id === b.id,
);

export default canRemoveMicrobotScope;
