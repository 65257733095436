import React from 'react';
import cs from 'classnames';

import styles from './BouncingDots.module.css';
import { BouncingDotsProps } from './types';

const BouncingDots = ({ className }: BouncingDotsProps) => (
  <div className={cs('flex gap-1', className)}>
    <div
      className={cs(
        'w-[0.4em] h-[0.4em] bg-white rounded-full',
        styles.bouncingDotsAnimation,
      )}
    />
    <div
      className={cs(
        'w-[0.4em] h-[0.4em] bg-white rounded-full',
        styles.bouncingDotsAnimation,
        styles.delay1,
      )}
    />
    <div
      className={cs(
        'w-[0.4em] h-[0.4em] bg-white rounded-full',
        styles.bouncingDotsAnimation,
        styles.delay2,
      )}
    />
  </div>
);

export default BouncingDots;
