import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DefaultSystemMessageData } from '@advisor/api/generated/graphql';
import { UserName, SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';
import useUserName from '../useUserName';

const MessageWhenAnyUserLeavesTheRoom: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');

  const { data } = message;
  const { subject } = data as DefaultSystemMessageData;
  const userName = useUserName(subject);

  if (!subject) {
    return null;
  }

  return (
    <Trans
      t={t}
      i18nKey="user-has-left-conversation"
      parent={SimpleMessage}
      values={{ user: userName }}
    >
      <UserName user={subject} /> has left the conversation.
    </Trans>
  );
};

export default memo(MessageWhenAnyUserLeavesTheRoom);
