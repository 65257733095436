import { useCallback } from 'react';

import {
  useCreateMilestoneMutation,
  MilestonesForCategoryQuery,
  MilestonesForCategoryDocument,
  MilestoneCategoryInfoFragment,
  CreateMilestoneMutationOptions,
  MilestoneCategoryInfoFragmentDoc,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { normalizeDueDateForBackend } from './utils';

const mutationConfig: CreateMilestoneMutationOptions = {
  update(cache, { data }) {
    if (!data?.createMilestone) {
      return;
    }

    const { milestoneCategoryId } = data.createMilestone;

    cache.updateQuery<MilestonesForCategoryQuery>(
      {
        query: MilestonesForCategoryDocument,
        variables: {
          milestoneCategoryId,
        },
      },
      (query) => {
        if (!query?.milestonesForCategory) {
          return query;
        }

        const milestonesForCategory = [
          ...query.milestonesForCategory,
          data?.createMilestone,
        ];

        return {
          ...query,
          milestonesForCategory,
        };
      },
    );

    cache.updateFragment<MilestoneCategoryInfoFragment>(
      {
        fragment: MilestoneCategoryInfoFragmentDoc,
        fragmentName: 'MilestoneCategoryInfo',
        id: cache.identify({
          __typename: 'MilestoneCategory',
          id: milestoneCategoryId,
        }),
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          totalMilestones: prev.totalMilestones + 1,
        };
      },
    );
  },
};

function useCreateMilestone() {
  const [createMilestone] = useCreateMilestoneMutation();

  return useCallback(
    async (
      ownerCategory: MilestoneCategoryInfoFragment,
      values: {
        name: string;
        description?: string | undefined;
        dueDate?: Date | undefined;
      },
    ) => {
      try {
        const result = await createMilestone({
          ...mutationConfig,
          variables: {
            milestoneCategoryId: ownerCategory.id,
            name: values.name,
            description: values.description || null,
            dueDate: normalizeDueDateForBackend(values.dueDate),
          },
        });

        if (!!result.errors && result.errors.length > 0) {
          return false;
        }
      } catch (e) {
        Sentry.captureException(e);
        return false;
      }

      return true;
    },
    [createMilestone],
  );
}

export default useCreateMilestone;
