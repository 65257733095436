import React from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingLoader } from '@advisor/videoCall/VideoRoomHistory/RecordingItem/web/RecordingLayout';
import SideModal from '../SideModal';

const VideoRoomDetailsModalLazy = React.lazy(
  () => import('./VideoRoomDetailsModal'),
);

const Fallback = () => <RecordingLoader />;

const VideRoomDetailsModal = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <SideModal.Header backButton>
        <SideModal.Title title={t('video-room-history')} />
      </SideModal.Header>
      <SideModal.Body>
        <React.Suspense fallback={<Fallback />}>
          <VideoRoomDetailsModalLazy />
        </React.Suspense>
      </SideModal.Body>
    </>
  );
};

export default VideRoomDetailsModal;
