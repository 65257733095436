import { z } from 'zod';
import { useState, useEffect } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import useCalendarIntegration from '../../hooks/useCalendarIntegration';

const CalendarAuthStorageKey = 'calendar.auth' as const;

export type CalendarAuthResponse = z.infer<typeof CalendarAuthResponse>;
export const CalendarAuthResponse = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal('code'),
      code: z.string(),
    }),
    z.object({
      type: z.literal('error'),
      message: z.string(),
    }),
  ])
  .nullable();

export type CalendarAuthState = 'waiting' | 'authorizing' | 'success' | 'error';

export function useResolveCalendarAuth() {
  return useEvent((response: CalendarAuthResponse) => {
    localStorage.setItem(CalendarAuthStorageKey, JSON.stringify(response));
  });
}

export function useAuthorizeCalendar() {
  const calendarIntegration = useCalendarIntegration();
  const [authState, setAuthState] = useState<null | CalendarAuthState>(null);

  const startAuthorization = useEvent(() => {
    setAuthState('waiting');

    if (!calendarIntegration.authUrl) {
      Sentry.captureException(
        new Error(
          'Tried to authorize Google Calendar, but authUrl was not defined.',
        ),
      );
      return;
    }

    window.open(calendarIntegration.authUrl, '_blank');
  });

  const finishAuthorization = useEvent(() => {
    setAuthState(null);
  });

  const handleAuthentication = useEvent(async (rawResponse: string) => {
    if (authState !== 'waiting') {
      return;
    }

    setAuthState('authorizing');

    try {
      const response = CalendarAuthResponse.parse(JSON.parse(rawResponse));
      if (response?.type === 'error') {
        throw new Error(response.message);
      }

      if (response?.type === 'code') {
        await calendarIntegration.authorizeCalendar(response.code);
        setAuthState('success');
      } else {
        setAuthState('error');
      }
    } catch (e) {
      Sentry.captureException(e);
      setAuthState('error');
    }
  });

  useEffect(() => {
    const storageEventCallback = async (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === CalendarAuthStorageKey) {
        localStorage.removeItem(CalendarAuthStorageKey);
        handleAuthentication(e.newValue || '');
      }
    };

    window.addEventListener('storage', storageEventCallback);
    return () => {
      window.removeEventListener('storage', storageEventCallback);
    };
  }, [handleAuthentication]);

  return {
    authState,
    startAuthorization,
    finishAuthorization,
  };
}
