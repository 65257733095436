import { useState, useCallback, useLayoutEffect } from 'react';

/**
 * Retrieves the size of an element that can change size only on window resize.
 *
 * Returns a 'recalculate' function as a second value in the return array, which
 * invalidates the latest calculation.
 */
function useStaticElementSize(
  elementRef: React.RefObject<HTMLElement>,
): [[number, number], () => void] {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  const recalculate = useCallback(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    const { width, height } = element.getBoundingClientRect();

    setSize([width, height]);
  }, [elementRef, setSize]);

  useLayoutEffect(() => {
    recalculate();

    window.addEventListener('resize', recalculate);
    return () => {
      window.removeEventListener('resize', recalculate);
    };
  }, [recalculate]);

  return [size, recalculate];
}

export default useStaticElementSize;
