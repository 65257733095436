import React from 'react';

import useUserColor from '@advisor/ui/hooks/useUserColor';
import { Icon, Layout } from '@advisor/design/components';
import { useMicrobotMessage } from '../../../messaging/messageMolecule';
import type { AssistFooterContentProps } from './types';

function AssistFooterContent(props: AssistFooterContentProps) {
  const { children, onPress } = props;
  const { author } = useMicrobotMessage('required');
  const color = useUserColor(author);

  const wrapperClassNames = 'flex justify-between px-4 py-2.5 text-left';
  const wrapperStyle = { color };
  const content = (
    <>
      <div>{children}</div>
      <Layout.Spacer.Horizontal size="micro" />
      <div className="self-stretch flex flex-col justify-start">
        {/** Spacer */}
        <div className="flex-1 max-h-4" />
        <div className="flex-1">
          <Icon name="AICheck" />
        </div>
        {/** Spacer */}
        <div className="flex-1" />
      </div>
    </>
  );

  if (onPress) {
    return (
      <button
        type="button"
        className={wrapperClassNames}
        style={wrapperStyle}
        onClick={onPress}
      >
        {content}
      </button>
    );
  }

  return (
    <div className={wrapperClassNames} style={wrapperStyle}>
      {content}
    </div>
  );
}

export default AssistFooterContent;
