import { z } from 'zod';
import { useEffect } from 'react';
import { unwrap } from 'jotai/utils';
import { defaults } from 'lodash-es';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import Env from '@advisor/api/env';
import { atomOfQuery } from '@advisor/api/apollo';
import { useInvitation } from '@advisor/onboarding';
import { LoginType, loginAtom } from '@advisor/api/auth';
import { atomWithPersistence } from '@advisor/utils/atoms';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import { GreyLabelingDocument } from '@advisor/api/generated/graphql';
import { parseColors, fetchGreyLabelingData } from './utils';
import { Theme, ColorPalette } from './types';

// Allow the stored theme value to be nullable as wel as
// having nullable properties (in case of f.e. colors parsing error)
// theme property provided by the context will still always provide
// values
type StoredTheme = z.infer<typeof StoredTheme>;
const StoredTheme = z
  .object({
    organizationLogo: z.string().nullable(),
    colors: ColorPalette.nullable(),
  })
  .nullable();

const activeThemeAtom = atomWithPersistence('app.theme', StoredTheme, null);

/**
 * Best used only via the `useActiveTheme` hook, as it
 * also provides automatic updating of the active theme
 * based on the user context.
 */
const themeOrDefaultAtom = atom((get) => {
  const { organizationLogo, colors } = get(activeThemeAtom) ?? {};

  return {
    organizationLogo: organizationLogo ?? null,
    colors: defaults(
      { ...colors },
      parseColors(JSON.stringify(Env.theme.colorPalette)),
    ),
  };
});

/**
 * Using an atom here to circumvent having to be below the apollo client provider in the React tree.
 */
const greyLabelingQueryAtom = atomOfQuery(GreyLabelingDocument);

export default function useActiveTheme(): Theme {
  const setActiveTheme = useSetAtom(activeThemeAtom);
  const login = useAtomValue(loginAtom);
  const invitation = useInvitation();
  const isAuthorized = useIsAuthorized();

  const greyLabelingQuery = useAtomValue(unwrap(greyLabelingQueryAtom));

  useEffect(() => {
    async function fetchData() {
      if (isAuthorized) {
        return greyLabelingQuery?.data?.greyLabeling;
      }

      if (invitation.inviteType === 'agency') {
        return fetchGreyLabelingData({ agency: invitation.inviteId });
      }

      if (invitation.inviteType === 'advisor') {
        return fetchGreyLabelingData({ userId: invitation.inviteId });
      }

      if (login.type === LoginType.EmailAddress && login.emailAddress) {
        return fetchGreyLabelingData({ email: login.emailAddress });
      }

      if (login.type === LoginType.PhoneNumber && login.phoneNumber) {
        return fetchGreyLabelingData({ phoneNumber: login.phoneNumber });
      }

      return null;
    }

    async function updateActiveTheme() {
      const greyLabelingData = await fetchData();

      if (!greyLabelingData) {
        return;
      }

      const logoUrl = greyLabelingData.organizationLogo?.url;
      const colors = parseColors(greyLabelingData.colorPalette);
      setActiveTheme({
        organizationLogo: logoUrl ?? null,
        colors: colors ?? null,
      });
    }

    updateActiveTheme();
  }, [login, invitation, isAuthorized, setActiveTheme, greyLabelingQuery]);

  return useAtomValue(themeOrDefaultAtom);
}
