import { useAtom } from 'jotai';
import React, { memo, useCallback, useEffect } from 'react';

import suspenseHOC from '@advisor/utils/suspenseHOC';
import { Modal, Loader } from '@advisor/design/components';
import { showBrandCustomizationModalAtom, customizationTabAtom } from './atoms';
import { BrandCustomizationModalProps, CustomizationTabs } from './types';
import DescriptionTab from './DescriptionTab';
import BrandColorsTab from './BrandColorsTab';
import LogoTab from './LogoTab';

const BrandCustomizationModal: React.FC<BrandCustomizationModalProps> = () => {
  const [showModal, setShowModal] = useAtom(showBrandCustomizationModalAtom);
  const [activeTab, setActiveTab] = useAtom(customizationTabAtom);

  const renderActiveTab = useCallback(() => {
    switch (activeTab) {
      case CustomizationTabs.Description:
        return <DescriptionTab />;
      case CustomizationTabs.Colors:
        return <BrandColorsTab />;
      case CustomizationTabs.Logo:
        return <LogoTab />;
      default:
        return null;
    }
  }, [activeTab]);

  useEffect(() => {
    if (showModal) {
      setActiveTab(CustomizationTabs.Description);
    }
  }, [showModal, setActiveTab]);

  return (
    <Modal.Base
      maxWidth={480}
      visible={showModal}
      onClose={() => setShowModal(false)}
    >
      {renderActiveTab()}
    </Modal.Base>
  );
};

const BrandCustomizationModalLoader = () => (
  <Modal.Base
    maxWidth={480}
    minHeight={320}
    items="center"
    justify="center"
    visible
  >
    <Loader />
  </Modal.Base>
);

export default memo(
  suspenseHOC(BrandCustomizationModal, BrandCustomizationModalLoader),
);
