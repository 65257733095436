import type { DailyCall, DailyEvent, DailyEventObject } from './LibProvider';

/**
 * A wrapper around call.on() and call.off() that is easier to unsubscribe from.
 * @param call
 * @param event
 * @param handler
 */
function subscribeToCallEvent<TKey extends DailyEvent>(
  call: DailyCall,
  event: TKey,
  handler: (event?: DailyEventObject<TKey>) => void,
) {
  if (!call.isDestroyed()) {
    call.on(event, handler);
  }

  return () => {
    if (!call.isDestroyed()) {
      call.off(event, handler);
    }
  };
}

export default subscribeToCallEvent;
