import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { useVideoRoomAPI } from '../VideoRoomAPI';
import { camStatePreferenceAtom, micStatePreferenceAtom } from './atoms';

export default function useLocalSettings() {
  const [isMicEnabled, toggleMicrophone] = useAtom(micStatePreferenceAtom);
  const [isCamEnabled, toggleCamera] = useAtom(camStatePreferenceAtom);
  const { speakerEnabled, toggleSpeaker, cycleCamera } =
    useVideoRoomAPI() ?? {};

  return useMemo(
    () => ({
      isCameraEnabled: isCamEnabled,
      isMicrophoneEnabled: isMicEnabled,
      isSpeakerEnabled: speakerEnabled,

      toggleMicrophone,
      toggleCamera,
      toggleSpeaker,
      cycleCamera,
    }),
    [
      isCamEnabled,
      isMicEnabled,
      speakerEnabled,
      toggleMicrophone,
      toggleCamera,
      toggleSpeaker,
      cycleCamera,
    ],
  );
}
