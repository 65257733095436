import { molecule } from 'bunshi';
import { useEffect } from 'react';
import { unwrap } from 'jotai/utils';
import { useMolecule } from 'bunshi/react';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import { myIdAtom } from '@advisor/api/me';
import { actionAtom } from '@advisor/utils/atoms';
import { atomWithSubscription } from '@advisor/api/apollo';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import { TypingIndicationDocument } from '@advisor/api/generated/graphql';
import { latestForeignMessageAtoms } from '../messaging';

type TypingUser = {
  id: string;
  timestamp: string;
};

type State = { [key: string]: TypingUser };

const CLEANUP_INTERVAL_MS = 3500;

/**
 * How many milliseconds after a message has been sent do we
 * ignore any typing indicators from the same user.
 */
const TYPING_INDICATOR_DISCARD_THRESHOLD = 2000; // 2 seconds

const TypingIndicatorMolecule = molecule(() => {
  // unique per chat-room
  const chatRoomId = requireChatRoomScope();

  const typingUsersAtom = atomWithSubscription(TypingIndicationDocument, {
    initial: {} as State,
    variables: { chatRoomId },
    onData({ data, get, draft }) {
      const event = data?.typingIndication;
      const myId = get(unwrap(myIdAtom));

      if (!event || event.userId === myId) {
        return;
      }

      draft[event.userId] = {
        id: event.userId,
        timestamp: event.timestamp,
      };
    },
  });

  const removeIfAtom = actionAtom(
    ({ set }, condition: (item: TypingUser) => boolean) => {
      set(typingUsersAtom, (prev) =>
        Object.fromEntries(
          Object.entries(prev).filter(([, item]) => !condition(item)),
        ),
      );
    },
  );

  const filteredIndicatorsAtom = atom((get) => {
    const lastMessage = get(unwrap(latestForeignMessageAtoms(chatRoomId)));

    let indicators = Object.values(get(typingUsersAtom));

    if (lastMessage && lastMessage.__typename === 'UserMessage') {
      const { author, sentAt } = lastMessage;

      indicators = indicators.filter((item) => {
        const diff =
          new Date(item.timestamp).getTime() - new Date(sentAt).getTime();

        return (
          item.id !== author.id || diff >= TYPING_INDICATOR_DISCARD_THRESHOLD
        );
      });
    }

    return indicators;
  });

  return { filteredIndicatorsAtom, removeIfAtom };
});

const useListenTyping = () => {
  const { filteredIndicatorsAtom, removeIfAtom } = useMolecule(
    TypingIndicatorMolecule,
  );
  const typingUsers = useAtomValue(filteredIndicatorsAtom);
  const removeIf = useSetAtom(removeIfAtom);

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      const time = new Date(Date.now() - CLEANUP_INTERVAL_MS);

      removeIf(({ timestamp }) => new Date(timestamp) <= time);
    }, CLEANUP_INTERVAL_MS);

    return () => clearInterval(intervalHandle);
  }, [removeIf]);

  return typingUsers;
};

export default useListenTyping;
