import { molecule } from 'bunshi';

import { meAtom } from '@advisor/api/me';
import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { atomWithMutation } from '@advisor/api/apollo';
import { chatRoomAtoms, requireChatRoomScope } from '@advisor/api/chatRoom';
import {
  TranslateMilestoneDocument,
  UpdateMilestoneTranslationStatusDocument,
  TranslationStatusType,
} from '@advisor/api/generated/graphql';
import { LanguageCode } from '@advisor/language';
import { showToast } from '@advisor/design/components/Toast';
import { chatRoomLanguageAtoms } from '@advisor/chat/chatLanguage';
import {
  optimisticTranslationStatusFields,
  makeContentTranslationAtoms,
  localTranslationStatusOverrideAtoms,
  type TranslateOptimisticFields,
} from '@advisor/chat/contentTranslation';
import { milestoneAtoms, requireMilestoneScope } from '../milestone';
import { journeyTranslationStateAtoms } from '../translation/journeyTranslationStateAtoms';
import { MilestoneTranslations } from '../translation/types';

const translateMutationAtom = atomWithMutation(TranslateMilestoneDocument);

const makeTranslateContentAtom = (chatRoomId: string, milestoneId: string) => {
  return actionAtom(
    async (
      { get, set },
      _targetLanguage: LanguageCode,
      optimisticFields: TranslateOptimisticFields,
    ) => {
      const milestone = get(milestoneAtoms(milestoneId));
      const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);
      const myself = await get(memberOfMeAtom);

      if (!myself || !milestone) {
        return false;
      }

      try {
        await set(translateMutationAtom, {
          variables: {
            milestoneId,
          },
          optimisticResponse: () => ({
            __typename: 'Mutation',
            translateMilestone: {
              __typename: 'Milestone',
              id: milestoneId,
              ...optimisticFields,
            },
          }),
          refetchQueries: ['commentsForMilestone'],
        });
      } catch (e) {
        Sentry.captureException(e);
        showToast({
          messageI18Key: 'something-went-wrong-try-again',
          variant: 'rose',
          iconName: 'X',
        });
        return false;
      }

      return true;
    },
  );
};

const updateStatusMutationAtom = atomWithMutation(
  UpdateMilestoneTranslationStatusDocument,
);

const makeUpdateStatusAtom = (id: string) =>
  actionAtom(async ({ get, set }, status: TranslationStatusType | null) => {
    const me = await get(meAtom);

    try {
      await set(updateStatusMutationAtom, {
        variables: {
          id,
          status,
        },
        optimisticResponse: me
          ? () => ({
              __typename: 'Mutation',
              updateMilestoneTranslationStatus: {
                __typename: 'Milestone',
                ...optimisticTranslationStatusFields(me.id, id, status),
              },
            })
          : undefined,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  });

const makeUniqueKey = (milestoneId: string) => `milestone_${milestoneId}`;

export const MilestoneTranslationMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();
  const milestoneId = requireMilestoneScope();

  const milestoneTranslationAtoms = makeContentTranslationAtoms({
    translationsSchema: MilestoneTranslations,
    targetLanguageAtom: chatRoomLanguageAtoms(chatRoomId),
    autotranslationStateAtom: journeyTranslationStateAtoms(chatRoomId),
    translatableAtom: milestoneAtoms(milestoneId),

    statusOverrideAtom: localTranslationStatusOverrideAtoms(
      makeUniqueKey(milestoneId),
    ),
    translateContentAtom: makeTranslateContentAtom(chatRoomId, milestoneId),
    updateStatusAtom: makeUpdateStatusAtom(milestoneId),
  });

  return milestoneTranslationAtoms;
});

export default MilestoneTranslationMolecule;
