import unauthorized from '@advisor/api/unauthorized';
import {
  GreyLabelingDocument,
  GreyLabelingInfoFragment,
  GreyLabelingQueryVariables,
} from '@advisor/api/generated/graphql';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { Colors } from '../styles';
import { ColorPalette, InternalColorPalette, Color, Theme } from './types';
import color from './color';

export function parseColor(theme: Theme, inColor?: Color): string | undefined {
  if (!inColor) {
    return undefined;
  }

  if (inColor in theme.colors) {
    return theme.colors[inColor as keyof typeof theme.colors];
  }

  if (inColor in Colors) {
    return Colors[inColor as keyof typeof Colors];
  }

  return inColor;
}

export async function fetchGreyLabelingData(
  variables: GreyLabelingQueryVariables = {},
): Promise<GreyLabelingInfoFragment | null | undefined> {
  const response = await unauthorized.query({
    query: GreyLabelingDocument,
    variables,
  });

  return response.data?.greyLabeling;
}

export function parseColors(
  colorPalette: string | null | undefined,
): ColorPalette | null | undefined {
  try {
    if (!colorPalette) {
      return null;
    }

    const palette = InternalColorPalette.parse(JSON.parse(colorPalette));

    return {
      primary: palette.primaryColor,
      primaryDark:
        palette.primaryDarkColor ??
        color(palette.primaryColor).alphaBlend('#000000', 0.5).stringify(),
      primaryLight:
        palette.primaryLightColor ??
        color(palette.primaryColor).alphaBlend('#FFFFFF', 0.5).stringify(),
      primaryLight05:
        palette.primaryLight05Color ??
        color(palette.primaryColor).alphaBlend('#FFFFFF', 0.75).stringify(),

      secondary: palette.secondaryColor,
      secondaryLight:
        palette.secondaryLightColor ??
        color(palette.secondaryColor).alphaBlend('#FFFFFF', 0.5).stringify(),
      secondaryDark:
        palette.secondaryDarkColor ??
        color(palette.secondaryColor).alphaBlend('#000000', 0.5).stringify(),
    };
  } catch (e) {
    Sentry.captureException(
      new ComplexError('Failed to parse custom color palette', e),
    );
    return undefined;
  }
}
