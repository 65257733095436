import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';

import {
  Layout,
  SpinnerIcon,
  DeprecatedButton,
} from '@advisor/design/components';
import assets from '../../assets';
import { IntegrationLogo } from '../../hooks/useMicrobot';
import useCalendarIntegration from '../../hooks/useCalendarIntegration';
import ConfigureCalendarModal from './ConfigureCalendarModal';
import { useAuthorizeCalendar } from './webCalendarAuth';

const CarlConfiguration: React.FC = () => {
  const { t } = useTranslation('microbot');
  const calendarIntegration = useCalendarIntegration();
  const { authState, startAuthorization, finishAuthorization } =
    useAuthorizeCalendar();

  const onEditAuthorisation = useCallback(async () => {
    await calendarIntegration.revokeCalendar();

    startAuthorization();
  }, [startAuthorization, calendarIntegration]);

  const onAuthorise = useCallback(async () => {
    startAuthorization();
  }, [startAuthorization]);

  const carlActions = useMemo(() => {
    if (calendarIntegration.isUninitialized) {
      return calendarIntegration.authUrl ? (
        <>
          <Layout.Spacer.Vertical size="micro02" />
          <DeprecatedButton.Bare
            onPress={onAuthorise}
            className="font-outfit text-sm leading-4 font-medium mt-3 ltr:mr-3 rtl:ml-3 px-3 py-2 rounded-md bg-primary text-white"
          >
            {({ loading }) => {
              if (loading) {
                return <SpinnerIcon className="w-4 h-4 mx-auto" />;
              }

              return t('add-calendar');
            }}
          </DeprecatedButton.Bare>
        </>
      ) : null;
    }

    return (
      <div className="flex flex-row flex-wrap items-center">
        <span className="font-outfit text-sm leading-4 font-medium text-white bg-dark-grey-01 rounded-md px-3 py-2 border border-grey mt-3 ltr:mr-3 rtl:ml-3">
          {t('added')}
        </span>
        <DeprecatedButton.Bare
          className="font-outfit text-sm leading-4 font-medium mt-3 ltr:mr-3 rtl:ml-3 border border-primary px-3 py-2 rounded-md text-primary"
          onPress={
            calendarIntegration.isAuthorized ? onEditAuthorisation : onAuthorise
          }
        >
          {({ loading }) => {
            if (loading) {
              return <SpinnerIcon className="w-4 h-4 mx-auto" />;
            }

            return calendarIntegration.isAuthorized
              ? t('edit-authorisation')
              : t('re-authorise');
          }}
        </DeprecatedButton.Bare>
        <DeprecatedButton.Bare
          onPress={calendarIntegration.revokeCalendar}
          className="font-outfit text-sm leading-4 font-medium mt-3 ltr:mr-3 rtl:ml-3 border border-rose-secondary-light px-3 py-2 rounded-md text-negative"
        >
          {({ loading }) => {
            if (loading) {
              return <SpinnerIcon className="w-4 h-4 mx-auto" />;
            }

            return t('revoke');
          }}
        </DeprecatedButton.Bare>
      </div>
    );
  }, [t, onAuthorise, calendarIntegration, onEditAuthorisation]);

  return (
    <div className="p-6 bg-light-grey border border-grey rounded-10">
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <h3 className="font-sora leading-5 font-bold text-dark-grey-03">
            {t('google-calendar')}
          </h3>
          <Layout.Spacer.Vertical size="quantum" />
          <p className="font-outfit font-medium text-sm leading-[1.125rem] text-dark-grey-02">
            {t('configure-google-calendar-to-google-calendar-bot-to-provide')}
          </p>
          <p className="font-outfit font-medium text-sm leading-[1.125rem] text-dark-grey-02">
            {t('required-parentheses')}
          </p>
        </div>
        <Layout.Spacer.Horizontal size="micro02" />
        <img
          src={assets[IntegrationLogo.GoogleCalendar]}
          alt=""
          className="w-8 h-8"
        />
      </div>
      {carlActions}
      <ConfigureCalendarModal state={authState} onClose={finishAuthorization} />
    </div>
  );
};

export default CarlConfiguration;
