import React from 'react';
import { Spacing } from '../../styles';

import { TextSkeletonProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextSkeleton = ({
  lineHeight,
  fontSize,
  size,
  color,
}: TextSkeletonProps) => {
  const margin = (lineHeight - fontSize) / 2;
  return (
    <div
      className="bg-light-grey rounded-lg"
      style={{
        height: `${fontSize}px`,
        marginTop: margin,
        marginBottom: margin,
        maxWidth: typeof size === 'string' ? `${Spacing[size]}px` : size,
        flex: !size ? 1 : undefined,
        backgroundColor: color || 'bg-light-grey',
      }}
    />
  );
};

export default TextSkeleton;
