/**
 * We need to use actual mime types instead of file extensions for native file picker.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types}
 */

// Should be the same with mime_types.py file from the backend
export const SupportedFileTypes = [
  'application/pdf', // '.pdf',
  'application/msword', // '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // '.docx',
  'application/vnd.ms-excel', // '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // '.xlsx',
  'text/plain', // '.txt',
  'text/csv', // .csv
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'image/png', // .png
  'image/jpeg', // .jpeg
];

export const AcceptString = SupportedFileTypes.join(',');

export const MicrobotMemoryFileSizeThresholdMB = 500;
