import { useSetAtom } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { MilestoneInfoFragment } from '@advisor/api/generated/graphql';
import { milestoneEditStateAtom } from './atoms';

const useOpenEditMilestoneModal = (milestone?: MilestoneInfoFragment) => {
  const setEditedMilestone = useSetAtom(milestoneEditStateAtom);

  const openMilestoneModal = useEvent(() => {
    if (!milestone) {
      return;
    }

    setEditedMilestone(milestone);
  });

  return openMilestoneModal;
};

export default useOpenEditMilestoneModal;
