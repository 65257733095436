import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import React, { memo, useCallback, useState } from 'react';

import { useLatestMessagesLazyQuery } from '@advisor/api/generated/graphql';
import { SpinnerIcon } from '@advisor/design/components';
import useCreateChatRoom from '../useCreateChatRoom';
import { StartChatProps } from './types';

const StartChatButton = (props: StartChatProps) => {
  const { show, scrollToBottom, navigateToNewChat } = props;
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);
  const [getLatesMessages] = useLatestMessagesLazyQuery();

  const navigateToChatRoom = useCallback(
    async (chatRoomId: string) => {
      // reduce delay between navigating to chatroom and displaying initial message
      await getLatesMessages({ variables: { chatRoomId } }).catch();
      navigateToNewChat(chatRoomId);
    },
    [navigateToNewChat, getLatesMessages],
  );

  const { createChatRoom, createChatRoomDisabled } = useCreateChatRoom({
    onSuccess: navigateToChatRoom,
  });

  const handleCreateChatRoom = useCallback(async () => {
    setIsLoading(true);

    const success = await createChatRoom();

    setIsLoading(false);

    if (success) {
      scrollToBottom();
    }
  }, [scrollToBottom, createChatRoom]);

  return (
    <Transition
      show={show}
      enter="transition-opacity ease-in duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button
        data-cy="startChatButton"
        type="button"
        disabled={isLoading || createChatRoomDisabled}
        className={cs(
          'relative bg-primary w-32 h-9 text-white rounded-10 text-xs font-sora mt-6 mb-5',
          !isLoading && 'hover:opacity-70 transition-opacity',
        )}
        onClick={handleCreateChatRoom}
      >
        {isLoading ? <SpinnerIcon className="h-5 mx-auto" /> : t('lets-go')}
      </button>
    </Transition>
  );
};

export default memo(StartChatButton);
