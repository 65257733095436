/* eslint-disable global-require */

import dayjs from 'dayjs';
import localizedFormats from 'dayjs/plugin/localizedFormat';

// TODO: We could import this in runtime to reduce bundle size
import ar from 'dayjs/locale/ar';
import es from 'dayjs/locale/es';
import pt from 'dayjs/locale/pt';
import zh from 'dayjs/locale/zh';
import pl from 'dayjs/locale/pl';

dayjs.extend(localizedFormats);

const setupDayjs = (lng: string) => {
  const languageOnly = lng.split('-')[0];
  switch (languageOnly) {
    case 'ar':
      dayjs.locale(ar);
      break;
    case 'pt':
      dayjs.locale(pt);
      break;
    case 'zh':
      dayjs.locale(zh);
      break;
    case 'es':
    case 'es-419':
      dayjs.locale(es);
      break;
    case 'pl':
      dayjs.locale(pl);
      break;
    default:
      // fallback to english, loaded by default
      dayjs.locale('en');
      break;
  }
};

export default setupDayjs;
