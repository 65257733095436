import { atom } from 'jotai';
import { molecule } from 'bunshi';
import { ResourceKey, TFunction } from 'i18next';

import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import { translationAtoms } from '@advisor/language';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import downloadContentAsFile from '@advisor/utils/downloadContentAsFile';
import type { MicrobotAssistActionInfoFragment } from '@advisor/api/generated/graphql';
import {
  MicrobotArtifactKey,
  PerformMicrobotAssistActionDocument,
} from '@advisor/api/generated/graphql';
import ComplexError from '@advisor/utils/ComplexError';
import { atomWithMutation } from '@advisor/api/apollo';
import { showToast } from '@advisor/design/components/Toast';
import { microbotArtifactsAtoms } from '@advisor/microbots/api';
import { MicrobotMessageMolecule } from '../../messaging/messageMolecule';

const AssistActionsMolecule = molecule((mol) => {
  const { messageAtom } = mol(MicrobotMessageMolecule);

  const completeActionMutationAtom = atomWithMutation(
    PerformMicrobotAssistActionDocument,
  );

  const makeArtifactFileName = (
    t: TFunction<'microbot', string>,
    artifactKey: MicrobotArtifactKey,
  ): string => {
    if (artifactKey === MicrobotArtifactKey.PartnerSchoolList) {
      return t('agency-partner-schools-list-csv' as ResourceKey);
    }

    warnExhaustive(artifactKey, 'AssistActionsMolecule/artifactKey');
    return t('unknown-file' as ResourceKey);
  };

  const downloadArtifactAtom = actionAtom(
    async ({ get }, artifactKey: MicrobotArtifactKey) => {
      const { author } = get(messageAtom) ?? {};
      if (!author) {
        return;
      }

      const t = await get(translationAtoms('microbot'));
      const fileName = makeArtifactFileName(t, artifactKey);

      if (artifactKey === MicrobotArtifactKey.PartnerSchoolList) {
        const { PARTNER_SCHOOL_LIST = '' } =
          (await get(microbotArtifactsAtoms(author.personality))) ?? {};

        downloadContentAsFile(
          fileName,
          // replacing two or more consecutive newlines with a single newline
          // NOTE: Currently, commas in the names of universities split the entry into multiple columns
          PARTNER_SCHOOL_LIST.replace(/\r?\n(\r?\n)+/g, '\n'),
          {
            fileType: 'csv',
          },
        );
        return;
      }

      warnExhaustive(artifactKey, 'AssistActionsMolecule/artifactKey');
      showToast({
        iconName: 'X',
        variant: 'rose',
        namespace: 'microbot',
        messageI18Key: 'could-not-find-the-file-to-be-downloaded',
      });
    },
  );

  return {
    makeArtifactFileName,
    downloadArtifactAtom,

    isFooterClickableAtom: atom((get) => {
      const { footerMessage } = get(messageAtom) ?? {};

      if (footerMessage?.__typename === 'MicrobotArtifactDownloadFooter') {
        return true;
      }

      return false;
    }),

    clickFooterAtom: actionAtom(async ({ get, set }) => {
      const { footerMessage } = get(messageAtom) ?? {};

      if (footerMessage?.__typename === 'MicrobotArtifactDownloadFooter') {
        await set(downloadArtifactAtom, footerMessage.artifactKey);
      }
    }),

    performActionAtom: actionAtom(
      async (
        { get, set },
        action: MicrobotAssistActionInfoFragment,
        actionIndex: number,
      ) => {
        const { id: messageId } = get(messageAtom) ?? {};
        if (!messageId) {
          return;
        }

        try {
          set(completeActionMutationAtom, {
            variables: {
              messageId,
              actionIndex,
            },
          });
        } catch (err) {
          Sentry.captureException(
            new ComplexError(
              `Could not complete Microbot action. ${JSON.stringify(
                action,
              )}, index=${actionIndex}`,
              err,
            ),
          );
        }

        // Additional side effects on the front-end

        if (action.__typename === 'MicrobotDownloadArtifactAction') {
          await set(downloadArtifactAtom, action.artifactKey);
          return;
        }

        if (
          action.__typename === 'MicrobotTellMeMoreAction' ||
          action.__typename === 'MicrobotSaveToCalendarAction' ||
          action.__typename === 'MicrobotViewAllPartnerSchoolsAction'
        ) {
          // No additional side effects for these types
          return;
        }

        warnExhaustive(action, 'AssistActionsMolecule/action');
      },
    ),
  };
});

export default AssistActionsMolecule;
