import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useMyId } from '@advisor/api/me';
import { useCachedUser } from '@advisor/api/user';
import { DateFormatter } from '@advisor/language';
import { InitialVideoChatSystemMessageData } from '@advisor/api/generated/graphql';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import useInterpolatedUsername from '../../../hooks/useInterpolatedUsername';
import { SimpleMessage, BoldText } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const MessageWhenVideoRoomInitiated: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const myId = useMyId();
  const { t } = useTranslation(['common', 'system-message']);
  const { chatRoomId } = useActiveChatRoom('required');

  const { createdAt, callInitiatorId, callInitiatorName } =
    message.data as InitialVideoChatSystemMessageData;

  const cachedUser = useCachedUser(callInitiatorId);
  const username =
    useInterpolatedUsername(cachedUser, chatRoomId) ?? callInitiatorName;

  return (
    <Trans
      t={t}
      parent={SimpleMessage}
      i18nKey={
        callInitiatorId === myId
          ? 'system-message:you-have-initiated-a-video-room-conversation'
          : 'system-message:user-has-initiated-a-video-room-conversation'
      }
      components={{ bold: <BoldText /> }}
      values={{
        user: username,
        datetime: DateFormatter.dateTime(createdAt, t),
      }}
    />
  );
};

export default memo(MessageWhenVideoRoomInitiated);
