import { useCallback } from 'react';
import { NetworkStatus } from '@apollo/client';

import { useAdvisorsQuery } from '../generated/graphql';

export default function useAdvisorList(name?: string, skip?: boolean) {
  const { data, loading, error, refetch, fetchMore, networkStatus } =
    useAdvisorsQuery({
      variables: { name },
      fetchPolicy: 'network-only',
      skip: skip ?? false,
    });

  const advisors = data?.advisors.edges.map(({ node }) => node) ?? [];
  const { pageInfo } = data?.advisors ?? {};

  const loadMore = useCallback(() => {
    if (
      !loading &&
      networkStatus !== NetworkStatus.fetchMore &&
      pageInfo?.hasNextPage &&
      pageInfo.startCursor
    ) {
      fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
      }).catch();
    }
  }, [fetchMore, loading, pageInfo, networkStatus]);

  return {
    error,
    loading,
    refetch,
    advisors,
    loadMore,
    hasMore: !!pageInfo?.hasNextPage,
  };
}
