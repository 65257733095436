import { createContext, useContext } from 'react';

import type { AuthContextType } from './types';

const noop = () => {
  throw Error('Auth context is used without initialization');
};

export const AuthContext = createContext<AuthContextType>({
  handleAuthentication: noop,
  authStart: noop,
  authVerify: noop,
  signInWithPassword: noop,
  logout: noop,
  renewSession: noop,
});

export const useAuth = () => {
  return useContext(AuthContext);
};
