import { atomFamily } from 'jotai/utils';
import { atom, SetStateAction } from 'jotai';

import Env from '@advisor/api/env';
import { atomOfQuery } from '@advisor/api/apollo';
import { getRandomAvatar } from '@advisor/api/avatar';
import { keyedAtomFamily, lazyAtom } from '@advisor/utils/atoms';
import { AgencyDocument, Gender } from '@advisor/api/generated/graphql';
import { invitationAtom, InvitationType } from '@advisor/onboarding';
import { CountryName } from '@advisor/utils/country/internationalDiallingCodes';
import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';
import getTimezone from '@advisor/utils/getTimezone';
import findCountryByTimezone from '@advisor/utils/country/findCountryByTimezone';
import {
  ErrorState,
  ProfileCreationStep,
  UserProfile,
  UserRole,
  VerificationState,
} from './types';

const agencyNameAtoms = atomFamily((normalizedName: string) => {
  const queryAtom = atomOfQuery(AgencyDocument, { name: normalizedName });

  return lazyAtom(async (get) => {
    const res = await get(queryAtom);

    return res.data?.agency.name;
  });
});

export const initialUserProfile: UserProfile = {
  name: '',
  role: UserRole.Student,
  gender: Gender.Undefined,
  agency: '',
  country: findCountryByTimezone(getTimezone()),
  timezone: getTimezone(),
  avatarUrl: getRandomAvatar(Env.api.s3Url),

  email: '',
  emailAuthToken: '',
  emailState: VerificationState.Unverified,

  phoneNumber: '',
  phoneNumberAuthToken: '',
  phoneNumberState: VerificationState.Unverified,
  locked: false,
};

export const errorsAtom = atom<ErrorState>({});

type UserProfileAtomParam = {
  inviteType: InvitationType | undefined;
  email: string | null | undefined;
  phone: string | null | undefined;
  name: string | null | undefined;
  country: CountryName | '';
  timezone: TimezoneName | '';
  agency: string | null | undefined;
};

export const userProfileAtom = (() => {
  const userProfileAtomFamily = atomFamily(
    (param: UserProfileAtomParam) => {
      const { inviteType, email, phone, name, country, timezone, agency } =
        param;

      if (inviteType === 'conversation') {
        return atom({
          ...initialUserProfile,
          role: UserRole.FamilyMember,
        });
      }

      if (inviteType === 'student') {
        return atom({
          ...initialUserProfile,
          name: name ?? '',
          email: email ?? '',
          emailState: email
            ? VerificationState.Verified
            : VerificationState.Unverified,
          phoneNumber: phone ?? '',
          phoneNumberState: phone
            ? VerificationState.Verified
            : VerificationState.Unverified,
          country: country ?? '',
          timezone: timezone ?? getTimezone(),
        });
      }

      if (inviteType === 'agency') {
        return atom({
          ...initialUserProfile,
          name: name ?? '',
          email: email ?? '',
          emailState: email
            ? VerificationState.Verified
            : VerificationState.Unverified,
          phoneNumber: phone ?? '',
          phoneNumberState: phone
            ? VerificationState.Verified
            : VerificationState.Unverified,
          country: country ?? '',
          timezone: timezone ?? getTimezone(),
          agency: agency ?? '',
        });
      }

      return atom(initialUserProfile);
    },
    (a, b) =>
      a.inviteType === b.inviteType &&
      a.email === b.email &&
      a.phone === b.phone &&
      a.name === b.name,
  );

  return lazyAtom(
    async (get) => {
      const { inviteType, inviteDetails, inviteId } = get(invitationAtom) ?? {};

      const agency =
        inviteType === 'agency' && inviteId
          ? await get(agencyNameAtoms(inviteId))
          : '';

      return get(
        userProfileAtomFamily({
          inviteType,
          email: inviteDetails?.email,
          phone: inviteDetails?.phone,
          name: inviteDetails?.name,
          country:
            (inviteDetails?.country as CountryName) ??
            findCountryByTimezone(getTimezone()),
          timezone: getTimezone(),
          agency,
        }),
      );
    },
    (get, set, update: SetStateAction<UserProfile>) => {
      const { inviteType, inviteDetails } = get(invitationAtom) ?? {};

      set(
        userProfileAtomFamily({
          inviteType,
          email: inviteDetails?.email,
          phone: inviteDetails?.phone,
          name: inviteDetails?.name,
          country: (inviteDetails?.country as CountryName) ?? '',
          timezone: getTimezone(),
          agency: '',
        }),
        update,
      );
    },
  );
})();

export const profileCreationStepAtom = keyedAtomFamily(
  (email: string | undefined | null, phone: string | undefined | null) => {
    if (!!email || !!phone) {
      // If the user signed in via the 'student' invite, we skip the initial step
      return atom(ProfileCreationStep.Details);
    }

    return atom(ProfileCreationStep.Initial);
  },
);
