import cs from 'classnames';
import React, { ReactNode, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LanguageMetadata, LanguageCode } from '@advisor/language';
import { TranslationStatusBoxProps, AutotranslationState } from './types';

function TranslationStatusBox(props: TranslationStatusBoxProps) {
  const {
    width,
    status,
    detectedLanguage,
    autotranslationState,
    onToggleTranslation,
  } = props;

  const { t } = useTranslation('common');

  if (
    autotranslationState === AutotranslationState.Disabled &&
    (status.type === 'in_progress' || status.type === 'missing')
  ) {
    return (
      <StatusBox width={width} className="bg-grey">
        {t('translating-ellipsis')}
      </StatusBox>
    );
  }

  if (
    autotranslationState === AutotranslationState.Disabled &&
    status.type === 'translated'
  ) {
    return (
      <StatusBox width={width} className="bg-primary-light">
        <p>
          <Trans
            t={t}
            i18nKey="translated-from-lang-to-lang"
            components={{ bold: <strong /> }}
            values={{
              sourceLang:
                detectedLanguage in LanguageMetadata
                  ? LanguageMetadata[detectedLanguage as LanguageCode].native
                  : detectedLanguage,
              targetLang: LanguageMetadata[status.language].native,
            }}
          />
        </p>
        <ActionButton onClick={onToggleTranslation}>
          {t('tap-to-restore')}
        </ActionButton>
      </StatusBox>
    );
  }

  return null;
}

export default memo(TranslationStatusBox);

type ActionButtonProps = {
  children: ReactNode;
  onClick?: () => void;
};

function ActionButton(props: ActionButtonProps) {
  const { children, onClick } = props;

  return (
    <button
      type="button"
      className="text-primary hover:opacity-70 transition-opacity font-semibold"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export type StatusBoxProps = {
  width: 'fit-content' | 'full';
  children?: ReactNode;
  className?: string;
};

const StatusBox = memo(function StatusBox(props: StatusBoxProps) {
  const { width, children, className } = props;

  return (
    <div
      className={cs(
        'w-fit -mt-[10px] px-4 pt-6 pb-4 rounded-b-10 text-xs',
        width === 'fit-content' && 'w-fit',
        width === 'full' && 'w-full',
        className,
      )}
    >
      {children}
    </div>
  );
});
