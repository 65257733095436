import React from 'react';

import MilestoneListSkeleton from '@advisor/journey/components/MilestoneList/MilestoneListSkeleton';
import SideModal from '../SideModal';

const JourneyCategoryModalLazy = React.lazy(
  () => import('./JourneyCategoryModal'),
);

function Fallback() {
  return (
    <SideModal.Default backButton padding={false} title="">
      <MilestoneListSkeleton />
    </SideModal.Default>
  );
}

export default function JourneyCategoryModal() {
  return (
    <React.Suspense fallback={<Fallback />}>
      <JourneyCategoryModalLazy />
    </React.Suspense>
  );
}
