import { atom } from 'jotai';
import { createRef } from 'react';
import { createScope, molecule } from 'bunshi';

import { actionAtom } from '@advisor/utils/atoms';
import { ContextMenuAtoms, ContextMenuScopeType } from './types';

export const ContextMenuScope = createScope<ContextMenuScopeType | null>(null);

const ContextMenuMolecule = molecule<ContextMenuAtoms>((_mol, scope) => {
  const contextMenuScope = scope(ContextMenuScope);

  if (!contextMenuScope) {
    throw new Error(
      `Tried using context menu state either outside of a context menu tree, or by using an invalid scope`,
    );
  }

  /**
   * Used on web only
   */
  const triggerRef = createRef<HTMLButtonElement>();

  const triggerVisibleAtom = atom(false);

  return {
    triggerRef,
    triggerVisibleAtom: atom(
      (get) =>
        contextMenuScope.triggerVisibleOverride ?? get(triggerVisibleAtom),
      (_get, set, value) => set(triggerVisibleAtom, value),
    ),
    showMenuAtom: actionAtom(() => {
      triggerRef.current?.click();
    }),
    hideMenuAtom: actionAtom(({ set }) => {
      set(triggerVisibleAtom, false);
      contextMenuScope.onClose?.();
    }),
  };
});

export default ContextMenuMolecule;
