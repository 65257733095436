import React, { memo } from 'react';
import { useStatusFromId, useStatusName } from '../../ChatRoomStatus';
import { StatusLabelProps } from './types';

const StatusLabel: React.FC<StatusLabelProps> = ({ statusId }) => {
  const statusItem = useStatusFromId(statusId);
  const statusName = useStatusName(statusItem);

  if (!statusItem) {
    return null;
  }

  return (
    <span
      className="inline-block px-3 py-1 rounded text-white text-sm mt-2"
      style={{
        backgroundColor: statusItem.color ?? '#000000',
      }}
    >
      {statusName}
    </span>
  );
};

export default memo(StatusLabel);
