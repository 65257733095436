import {
  MicrobotPersonality,
  useIsMicrobotActiveQuery,
} from '@advisor/api/generated/graphql';

const useIsMicrobotActive = (personality: MicrobotPersonality) => {
  const { data, loading } = useIsMicrobotActiveQuery({
    variables: { personality },
  });

  return { isActive: data?.isMicrobotActive ?? false, isLoading: loading };
};

export default useIsMicrobotActive;
