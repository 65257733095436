import dayjs from 'dayjs';

export function strToDate(text: string): Date | null {
  const date = dayjs(text, 'DD/MM/YYYY');

  if (date.isValid()) {
    return date.toDate();
  }

  return null;
}

export function dateToStr(date: Date) {
  return dayjs(date).format('DD/MM/YYYY');
}
