import React, { createContext, useCallback, useMemo, useState } from 'react';
import { uniqueId } from 'lodash-es';

import {
  BannerContextProviderProps,
  BannerContextType,
  BannerNotification,
} from './types';

export const BannerContext = createContext<BannerContextType | null>(null);

export const NotificationBannerProvider: React.FC<
  BannerContextProviderProps
> = ({ children }) => {
  const [notifications, setNotifications] = useState<BannerNotification[]>([]);

  const createNotification = useCallback((notification: BannerNotification) => {
    const id = uniqueId('notification');
    setNotifications((state) => [...state, { ...notification, id }]);
    return id;
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications((state) =>
      state.filter((notification) => notification.id !== id),
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      notifications,
      createNotification,
      removeNotification,
    }),
    [notifications, createNotification, removeNotification],
  );

  return (
    <BannerContext.Provider value={contextValue}>
      {children}
    </BannerContext.Provider>
  );
};
