export const Spacing = {
  quantum: 2,
  atomic: 4,
  micro: 8,
  micro02: 12,
  tiny: 16,
  small01: 24,
  small02: 32,
  medium: 48,
  large: 64,
  large70: 70,
  huge: 128,
  giant: 256,
  iconSize: 40,
} as const;

export const HEADER_SIZE = 59;

export const REM_SIZE = 16;
