import { useSetAtom } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Modal,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';
import { useShowModal } from '@advisor/design/components/ActionModal';
import useGreyLabeling from '../useGreyLabeling';
import { customizationTabAtom, showBrandCustomizationModalAtom } from './atoms';
import { CustomizationTabs } from './types';

const DescriptionTab: React.FC = () => {
  const { t: commT } = useTranslation('common');
  const { t } = useTranslation('brand-customization');

  const setActiveTab = useSetAtom(customizationTabAtom);
  const { isCustomized, deleteGreyLabeling } = useGreyLabeling();
  const setShowModal = useSetAtom(showBrandCustomizationModalAtom);
  const showModal = useShowModal();

  const onDeletePress = async () => {
    setShowModal(false);

    const confirmed = await showModal.confirm({
      variant: 'severe',
      title: t('are-you-sure-you-want-to-delete-custom-branding'),
      message: (
        <>
          <p className="font-outfit text-sm font-medium text-dark-grey-02">
            {t('this-will-affect-all-users-within-your-agency')}
          </p>
          <Layout.Spacer.Vertical size="tiny" />
          <p className="font-outfit text-sm font-medium text-dark-grey-02">
            {commT('this-action-cannot-be-reversed')}
          </p>
        </>
      ),
    });

    if (confirmed) {
      await deleteGreyLabeling();
    }
  };

  return (
    <>
      <Modal.PopUpHeader
        large
        title={t('brand-customisation')}
        message={t('for-customising-brand-identity')}
        onClose={() => setShowModal(false)}
      />
      <div className="p-12 pt-10">
        <div className="bg-light-grey border border-grey rounded-lg flex flex-row p-4 items-center">
          <div className="flex-1">
            <h5 className="text-dark-grey-03 font-sora text-sm font-bold">
              {t('what-can-you-do')}
            </h5>
            <Layout.Spacer.Vertical size="micro" />
            <p className="font-outfit text-xs text-dark-grey-02">
              {t('welcome-to-brand-customisation-page-here-you-can-tailor')}
            </p>
          </div>
          <Layout.Spacer.Horizontal size="medium" />
          <Icon name="ColorPalette" size={32} className="text-dark-grey-03" />
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <div className="bg-light-grey border border-grey rounded-lg flex flex-row p-4 items-center">
          <div className="flex-1">
            <h5 className="text-dark-grey-03 font-sora text-sm font-bold">
              {commT('how-it-works')}
            </h5>
            <Layout.Spacer.Vertical size="micro" />
            <div className="font-outfit text-xs text-dark-grey-02">
              <ol className="list-decimal ltr:pl-4 rtl:pr-4 list-outside">
                <li>
                  <span className="font-bold">{t('upload-your-logo')}: </span>
                  {t('make-your-mark-by-uploading-your-custom-logo')}
                </li>
                <li>
                  <span className="font-bold">
                    {t('choose-brand-colors')}:{' '}
                  </span>
                  {t('select-your-organizations-brand-colors')}
                </li>
                <li>
                  {t('these-changes-will-be-reflected-throughout-the-app')}
                </li>
              </ol>
            </div>
          </div>
          <Layout.Spacer.Horizontal size="medium" />
          <Icon name="HelpCircle" size={32} className="text-secondary" />
        </div>
        <Layout.Spacer.Vertical size="small02" />
        <div className="flex flex-row justify-center">
          {isCustomized && (
            <>
              <DeprecatedButton.Large
                variant="negative"
                label={t('delete-customisation')}
                onPress={onDeletePress}
                outline
              />
              <Layout.Spacer.Horizontal size="micro" />
            </>
          )}
          <DeprecatedButton.Large
            variant="primary"
            label={commT('continue')}
            onPress={() => setActiveTab(CustomizationTabs.Logo)}
          />
        </div>
      </div>
    </>
  );
};

export default memo(DescriptionTab);
