import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Toaster } from '@advisor/design/components/Toast';
import { useEvent } from '@advisor/utils/hooks';
import { FlowType, LoginState, LoginType, loginAtom } from '@advisor/api/auth';
import { DeleteAccountContent } from 'src/components/atoms/DeleteAccountComponents';
import DeleteAccountLayout from 'src/components/layouts/DeleteAccountLayout';
import DeleteAccountModal from 'src/components/organisms/modals/DeleteAccountModal';
import LoginForm from 'src/components/molecules/Login';

const confirmingDeleteAtom = atom(() => {
  const inDeleteFlow = localStorage.getItem('FLOW_TYPE') === FlowType.Delete;
  localStorage.removeItem('FLOW_TYPE');
  return inDeleteFlow;
});

const DeleteAccountPage: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const [visible, setVisible] = useState(false);
  const setLogin = useSetAtom(loginAtom);
  const confirmingDelete = useAtomValue(confirmingDeleteAtom);

  const onStartDeleteAccount = useEvent(() => {
    setLogin({
      type: LoginType.PhoneNumber,
      state: LoginState.Initial,
      flowType: FlowType.Delete,
    });
    setVisible(true);
  });

  const onClose = useEvent(() => {
    setLogin({
      type: LoginType.PhoneNumber,
      state: LoginState.Initial,
      flowType: FlowType.Login,
    });
    setVisible(false);
  });

  useEffect(() => {
    if (confirmingDelete) {
      setLogin({
        type: LoginType.EmailAddress,
        state: LoginState.ConfirmDelete,
        flowType: FlowType.Delete,
      });

      setVisible(true);
    }
  }, [confirmingDelete, setLogin, setVisible]);

  return (
    <DeleteAccountLayout title={t('delete-your-account')}>
      <DeleteAccountContent onStartDeleteAccount={onStartDeleteAccount} />
      <DeleteAccountModal isOpen={visible} onClose={onClose}>
        <LoginForm insideModal onClose={onClose} />
      </DeleteAccountModal>
      <Toaster />
    </DeleteAccountLayout>
  );
};

export default DeleteAccountPage;
