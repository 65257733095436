import React from 'react';

import { DeletingIndicatorProps } from './types';

function DeletingIndicator({ children, deleting }: DeletingIndicatorProps) {
  if (deleting) {
    return (
      <span className="animate-almost-removed pointer-events-none">
        {children}
      </span>
    );
  }

  return <>{children}</>;
}

export default DeletingIndicator;
