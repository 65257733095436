import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleMessage } from '../systemMessageComponents';

const MessageWhenVideoRoomRecordingAdded: React.FC = () => {
  const { t } = useTranslation('system-message');

  return (
    <SimpleMessage>
      {t(
        'video-room-recording-is-processing-you-will-be-notified-when-it-is-available',
      )}
    </SimpleMessage>
  );
};

export default memo(MessageWhenVideoRoomRecordingAdded);
