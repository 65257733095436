import { useTranslation } from 'react-i18next';
import React, { FormEvent, useCallback } from 'react';

import {
  Title,
  Subtitle,
  StepIndicator,
} from '@advisor/design/components/LoginComponents';
import { Layout, Form } from '@advisor/design/components';
import { useInvitation } from '@advisor/onboarding';
import { CreateProfileFormProps } from './types';
import useBasicStep from './useBasicStep';
import AvatarUpload from '../EditProfileForm/AvatarUpload';
import UserRoleSelect from './UserRoleSelect';

const BasicStep = (props: CreateProfileFormProps) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const {
    onSetName,
    onSetRole,
    userProfile,
    roleOptions,
    uploadingAvatar,
    updateUserAvatar,
    onSubmitBasicForm,
    setUploadingAvatar,
  } = useBasicStep(props);

  const { inviteType, inviteId } = useInvitation();

  const onSubmitWrapper = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmitBasicForm();
    },
    [onSubmitBasicForm],
  );

  return (
    <>
      <Title>{t('onboarding:hi-glad-to-have-you-here')}</Title>
      <Subtitle>{t('onboarding:what-should-we-call-you')}</Subtitle>
      <Layout.Spacer.Vertical size="small01" />
      <StepIndicator step={1} ofSteps={2} />
      <form onSubmit={onSubmitWrapper}>
        <AvatarUpload
          onUploadSuccess={({ url }) => {
            updateUserAvatar(url);
          }}
          onUploadStart={() => setUploadingAvatar(true)}
          onUploadFailure={() => setUploadingAvatar(false)}
        >
          <Form.AvatarInput
            avatarUrl={userProfile.avatarUrl}
            loading={uploadingAvatar}
          />
        </AvatarUpload>
        <UserRoleSelect
          onSelect={onSetRole}
          options={roleOptions}
          value={userProfile.role}
          disabled={
            (!!inviteId && inviteType === 'advisor') || roleOptions.length === 1
          }
          upwards
        />
        <Layout.Spacer.Vertical size="micro02" />
        <Form.Text
          required
          value={userProfile.name}
          maxLength={64}
          onChange={onSetName}
          placeholder={t('common:name-placeholder')}
        />
        <Layout.Spacer.Vertical size="small01" />
        <button
          type="submit"
          className="button button-blue h-12 w-52 mx-auto"
          disabled={!userProfile.name}
        >
          <p className="w-full">{t('common:continue')}</p>
        </button>
      </form>
    </>
  );
};

export default BasicStep;
