import React from 'react';
import cs from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '@advisor/design/hooks';
import {
  AppStoreLink,
  PlayStoreLink,
} from 'src/components/atoms/AppDownloadLinks';
import BasicLayout from 'src/components/layouts/BasicLayout';
import Navbar from 'src/components/organisms/NavBar';
import useScrollTop from 'src/hooks/useScrollTop';
import InteractionShowcaseSection from './InteractionShowcaseSection';
import AutoScrollInfoSection from './AutoScrollInfoSection';
import StartMyJourneyButton from './StartMyJourneyButton';
import HomePageRedirect from './HomePageRedirect';
import PersonBubbles from './PersonBubbles';
import MessageBubble from './MessageBubble';
import Footer from './Footer';

const JOURNEY_STEPS = [
  {
    icon: '/images/homepage/step-always-available-icon.svg',
    alt: 'Hands performing a handshake',
    descriptionKey:
      'global-study-homepage:journey-step-our-experts-are-available-24-7',
  },
  {
    icon: '/images/homepage/step-backed-by-professionals-icon.svg',
    alt: 'A grid of dots with a cursor in the middle',
    descriptionKey:
      'global-study-homepage:journey-step-your-advisor-is-supported-by-a-global-team',
  },
  {
    icon: '/images/homepage/step-secure-an-admission-icon.svg',
    alt: 'A runner crossing the finish line',
    descriptionKey:
      'global-study-homepage:journey-step-secure-an-admission-and-get-settled',
  },
] as const;

function DefaultHomePage() {
  const { t } = useTranslation(['global-study-homepage', 'common']);

  const [, windowHeight] = useWindowSize();
  const scrollTop = useScrollTop();

  return (
    <HomePageRedirect>
      <BasicLayout className="bg-off-white">
        <Helmet>
          <style>{`
          body {
            background-color: ${
              scrollTop > windowHeight ? '#171718' : '#FAFAFA'
            };
          }
        `}</style>
        </Helmet>
        <Navbar />
        {/* Hero jumbotron */}
        <Section className="pt-[5.5rem] pb-1 text-dark-grey-03 overflow-hidden">
          <div className="pt-8 sm:pt-16 relative rounded-20 bg-[#F1F1F1] overflow-hidden min-h-[calc(100vh-5.75rem)] flex flex-col justify-between">
            <h1 className="mb-10 mx-auto font-sora font-bold max-w-[265px] text-2xl sm:text-4xl sm:max-w-[560px] xl:text-6xl xl:max-w-[900px] text-center text-primary-dark">
              {t('global-study-homepage:the-best-study-abroad-experts')}
            </h1>
            <div className="flex flex-col items-center">
              <StartMyJourneyButton />
            </div>
            <div className="relative flex flex-col justify-end items-center">
              <div className="flex flex-row space-x-3 sm:space-x-6 mt-10 mb-6">
                <AppStoreLink className="rounded-10 h-10 md:h-12 hover:bg-off-white flex items-center justify-center aspect-[3.5]" />
                <PlayStoreLink className="rounded-10 h-10 md:h-12 hover:bg-off-white flex items-center justify-center aspect-[3.5]" />
              </div>
              <p className="mb-2 font-sora text-xs font-medium">
                {t(
                  'global-study-homepage:asterisk-available-in-selected-countries',
                )}
              </p>
              <img
                src="/images/homepage/iphone-12-max-bg.svg"
                alt="iPhone 12 Max"
                className="w-[180px] sm:w-[370px]"
              />
              <div className="absolute bottom-0 w-[450px] sm:w-[874px]">
                <img
                  src="/images/homepage/hero-jumbotron.png"
                  alt="Pick an advisor"
                  className="object-cover object-bottom mx-auto"
                />
              </div>
            </div>
          </div>
        </Section>
        {/* Steps to begin your journey */}
        <Section className="pt-16 pb-24 flex flex-col items-center md:flex-row md:items-stretch bg-white">
          <aside className="flex items-center md:pr-10 md:w-[50%] relative flex-shrink-0 pb-10 md:pb-0">
            <img
              className="hidden md:block w-[76%] md:w-full right-0 absolute left-0 object-contain"
              src="/images/homepage/begin-your-international-study-fg.svg"
              alt="Panel foreground"
            />
            <div className="flex flex-col justify-between p-5 md:p-10 rounded-20 md:min-h-[75vh] max-h-[656px] bg-[radial-gradient(at_bottom_left,#0F39D3,#4285F7)]">
              <h1 className="relative z-10 text-3xl mb-10 text-white">
                {t(
                  'global-study-homepage:begin-your-international-study-journey-with-3-simple-steps',
                )}
              </h1>
              <img
                className="md:hidden w-[90%] object-contain relative -left-5 -mt-20 -mb-28"
                src="/images/homepage/begin-your-international-study-fg.svg"
                alt="Panel foreground"
              />
              <p className="relative z-10 font-outfit text-white text-sm xl:text-base">
                {t(
                  'global-study-homepage:global-study-puts-you-in-touch-with-the-best-experts',
                )}
              </p>
            </div>
          </aside>
          <aside className="flex flex-col justify-between py-6 pr-8 space-y-4">
            {JOURNEY_STEPS.map(({ icon, descriptionKey }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="flex flex-row items-start">
                <p className="font-sora text-[90px] xl:text-[144px] text-dark-grey-03 opacity-10 leading-none -mt-5 mr-4 w-20 flex-shrink-0 flex justify-center">
                  {index + 1}
                </p>
                <div>
                  <img
                    src={icon}
                    alt="Hands performing a handshake"
                    className="block w-10 h-10 mb-2"
                  />
                  <p className="font-sora xl:text-2xl font-semibold">
                    {t(descriptionKey)}
                  </p>
                </div>
              </div>
            ))}
          </aside>
        </Section>
        {/* Interaction showcase, how chatting looks like. */}
        <Section className="pt-8">
          <InteractionShowcaseSection />
        </Section>
        {/* Giant "All the benefits you need" marquee section */}
        <Section fullWidth className="overflow-hidden py-12 select-none">
          <p
            className="flex whitespace-nowrap font-semibold text-[#E6E6E7] text-[7.5vw]"
            style={{
              // Doing this explicitly to override the Arabic font switching.
              fontFamily: 'Sora, sans-serif',
              transform: `translateX(${((scrollTop * 0.05) % 100) - 100}%)`,
            }}
          >
            {new Array(2).fill(null).map((_, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="flex flex-grow-0 flex-shrink-0 justify-between w-screen box-border px-[0.5em]"
              >
                <span>All the benefits you need</span>
                <img
                  className="flex-shrink-0 w-[0.5em] ml-[0.3em]"
                  src="/images/homepage/global-study-icon.svg"
                  alt="Global Study Icon"
                />
              </span>
            ))}
          </p>
        </Section>
        {/* Auto-scroll section */}
        <Section className="relative -mt-24 -mb-64">
          <div className="absolute inset-x-0 top-40 bottom-16 flex flex-col items-center">
            <div
              className="flex-1 w-[8px] rounded flex p-[1px]"
              style={{
                backgroundImage:
                  'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #323338 90%, rgba(0, 0, 0, 0))',
                backgroundPosition: 'bottom',
                backgroundSize: '100% 40rem',
                backgroundRepeat: 'repeat-y',
              }}
            >
              <div className="flex-1 bg-off-white rounded" />
            </div>
          </div>
          <AutoScrollInfoSection />
        </Section>
        <Section className="mt-48 overflow-hidden bg-off-white">
          <div className="flex flex-row justify-center items-center">
            <h1 className="relative pt-20 pb-52 xl:pt-56 xl:pb-96 font-sora text-4xl xl:text-6xl font-medium">
              <img
                className="absolute right-0 top-0 xl:top-10"
                src="/images/homepage/paper-plane-bg.svg"
                alt=""
              />
              <span className="block relative text-primary text-center">
                {t('global-study-homepage:are-you-planning-to-study-abroad')}
              </span>
              <img
                className="hidden xl:block absolute left-0 bottom-0"
                src="/images/homepage/paper-plane-fg.svg"
                alt=""
              />
            </h1>
          </div>
        </Section>
        {/* Message bubbles section */}
        <Section fullWidth className="pb-56 relative">
          <div className="relative flex flex-col items-center mx-auto w-full max-w-[34rem] rounded-20 bg-white pb-12 xl:pb-20 z-10">
            <img
              className="hidden xl:block"
              src="/images/homepage/paper-plane-trail.svg"
              alt=""
            />
            <div className="xl:absolute -mt-32 xl:mt-0 -top-32 mx-10 xl:w-0 flex flex-col items-center space-y-16 xl:space-y-10">
              <MessageBubble
                side="left"
                className="relative xl:w-[33rem] xl:self-end xl:right-3"
              >
                {t(
                  'global-study-homepage:do-you-want-to-study-in-a-top-destination',
                )}
              </MessageBubble>
              <MessageBubble
                side="right"
                className="relative xl:w-[33rem] xl:self-start xl:left-3"
              >
                {t(
                  'global-study-homepage:do-you-have-the-financial-means-to-study-abroad',
                )}
              </MessageBubble>
              <MessageBubble
                side="left"
                className="relative xl:w-[27rem] xl:self-end xl:right-3"
              >
                {t('global-study-homepage:do-you-have-good-grades')}
              </MessageBubble>
            </div>
            <div className="xl:mt-0 mt-20 mb-12 px-16 bottom-48 font-outfit text-center">
              <p className="mb-5">
                {t(
                  'global-study-homepage:if-you-answered-yes-to-all-these-questions',
                )}
              </p>
              <p>
                {t(
                  'global-study-homepage:theres-no-obligation-only-the-opportunity',
                )}
              </p>
            </div>
            <StartMyJourneyButton />
          </div>
          <PersonBubbles className="absolute left-0 right-0 -bottom-7" />
        </Section>
        <Footer />
      </BasicLayout>
    </HomePageRedirect>
  );
}

function Section({
  children,
  className,
  fullWidth,
}: {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
}) {
  return (
    <section className={cs(fullWidth ? 'w-full' : 'px-5 lg:px-24', className)}>
      {children}
    </section>
  );
}

export default DefaultHomePage;
