import React, { memo } from 'react';
import { map } from 'lodash-es';

import * as network from '@advisor/utils/network';
import useListenTyping from '../hooks/useListenTyping';
import TypingIndicator from '../TypingIndicator';

const TypingIndicators: React.FC = () => {
  const typingUsers = useListenTyping();

  return (
    <>
      {map(typingUsers, ({ id }) => (
        <TypingIndicator key={id} userId={id} />
      ))}
    </>
  );
};

export default memo(network.makeOnlineOnly(TypingIndicators));
