import React from 'react';
import cs from 'classnames';
import { SegmentedGroupItemProps } from './types';

const SegmentedGroupItem = ({
  children,
  className,
}: SegmentedGroupItemProps) => {
  return (
    <li
      className={cs(
        'relative after:block after:mx-3 after:border-b after:border-grey last-of-type:after:hidden',
        className,
      )}
    >
      {children}
    </li>
  );
};

export default SegmentedGroupItem;
