import { produce } from 'immer';

import {
  UserInfoFragment,
  LatestMessagesQuery,
  MessageInfoFragment,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphql';

/**
 * This should match the cursor calculated on the backend.
 * See function `create_message_details` in {@link lambda/db/message.py}
 */
export function createMessageCursor(node: {
  chatRoomId: string;
  sentAt: string;
  id: string;
}) {
  return JSON.stringify({
    pk: `room_${node.chatRoomId}`,
    sk: `message_${node.sentAt}_${node.id}`,
  });
}

export function mockUserMessage({
  author,
  chatRoomId,
  message = '',
}: {
  author: UserInfoFragment;
  chatRoomId: string;
  message?: string;
}): UserMessageInfoFragment {
  return {
    __typename: 'UserMessage',
    id: String(Math.floor(Math.random() * 1000)),
    author,
    chatRoomId,
    message,
    // Ensures the chunks are in correct order
    sentAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    translations: '{}',
    translationStatus: '{}',
    detectedLanguage: '',
    microbotMemoryId: null,
  };
}

export function appendNewMessage(
  latestMessages: LatestMessagesQuery,
  newMessage: MessageInfoFragment,
): LatestMessagesQuery {
  return produce(latestMessages, (draft) => {
    const cursor = createMessageCursor(newMessage);

    if (draft.messages.edges.some(({ node }) => node.id === newMessage.id)) {
      return;
    }

    draft.messages.edges.push({
      __typename: 'MessageEdge',
      node: newMessage,
      cursor,
    });
  });
}

export function upsertMessage(
  latestMessages: LatestMessagesQuery,
  message: MessageInfoFragment,
): LatestMessagesQuery {
  return produce(latestMessages, (draft) => {
    const edgeToUpdate = draft.messages.edges.find(
      (edge) => edge.node.id === message.id,
    );

    if (edgeToUpdate) {
      edgeToUpdate.node = {
        ...edgeToUpdate.node,
        ...message,
      };
      return;
    }

    const cursor = createMessageCursor(message);
    draft.messages.edges.push({
      __typename: 'MessageEdge',
      node: message,
      cursor,
    });
  });
}

export function deleteMessage(
  latestMessages: LatestMessagesQuery,
  messageId: string,
): LatestMessagesQuery {
  return produce(latestMessages, (draft) => {
    const { edges, pageInfo } = draft.messages;
    const messageIdx = edges.findIndex(({ node }) => node.id === messageId);

    if (messageIdx < 0) {
      return;
    }

    edges.splice(messageIdx, 1);

    const lastEdge = edges[edges.length - 1];
    if (lastEdge && lastEdge.node.id === messageId) {
      pageInfo.startCursor = lastEdge.cursor;
    }
  });
}

export function replaceMessage(
  latestMessages: LatestMessagesQuery,
  message: MessageInfoFragment,
): LatestMessagesQuery {
  return produce(latestMessages, (draft) => {
    const edgeToReplace = draft.messages.edges.find(
      (edge) => edge.node.id === message.id,
    );

    if (!edgeToReplace) {
      return;
    }

    edgeToReplace.node = {
      ...edgeToReplace.node,
      ...message,
    };
  });
}
