import React, { memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import { Layout } from '@advisor/design/components';
import { JourneyCategorySystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import {
  BoldText,
  Container,
  CenteredMessage,
} from '../systemMessageComponents';
import { useOpenJourneyCategory } from '../navigation';
import type { SystemMessageProps } from '../types';
import MessageButton from '../MessageButton';

const CategoryEdited: React.FC<SystemMessageProps> = ({ message }) => {
  const { t } = useTranslation(['common', 'system-message']);
  const data = message.data as JourneyCategorySystemMessageInfoFragment;
  const openCategory = useOpenJourneyCategory();

  const onPress = useCallback(() => {
    openCategory(message.chatRoomId, data.milestoneCategory?.id);
  }, [openCategory, message.chatRoomId, data.milestoneCategory]);

  const username = data.advisor?.name ?? data.advisorName;
  const datetime = DateFormatter.dateTime(message.sentAt, t);
  const categoryName =
    data.milestoneCategory?.name ?? data.metadata.categoryName;

  return (
    <Container>
      <Trans
        t={t}
        parent={CenteredMessage}
        i18nKey="system-message:user-edited-category-on"
        values={{ user: username, datetime, category: categoryName }}
        components={{ bold: <BoldText /> }}
      />
      <Layout.Spacer.Vertical size="micro02" />
      <MessageButton label={categoryName} onPress={onPress} />
    </Container>
  );
};

export default memo(CategoryEdited);
