import { Theme } from '../../Theme';
import { Colors } from '../../styles';
import { TooltipVariant } from './types';

export function getVariantBg(theme: Theme, variant: TooltipVariant): string {
  if (variant === 'primary') {
    return theme.colors.primary;
  }

  if (variant === 'secondary') {
    return theme.colors.secondary;
  }

  if (variant === 'positive') {
    return Colors.positive;
  }

  if (variant === 'neutral') {
    return Colors.primaryBlue;
  }

  if (variant === 'negative') {
    return Colors.negative;
  }

  if (variant === 'light') {
    return Colors.white;
  }

  return Colors.darkGrey03;
}

export function getVariantFg(variant: TooltipVariant): string {
  if (variant === 'light') {
    return Colors.darkGrey03;
  }

  return Colors.white;
}
