import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAddChatMemberMutation,
  AddChatMemberMutationOptions,
} from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';

const addChatMemberOptions: AddChatMemberMutationOptions = {
  refetchQueries: ['chatRooms'],
};

function useReassignUser() {
  const { t } = useTranslation('common');
  const showModal = useShowModal();

  const [addChatMember] = useAddChatMemberMutation(addChatMemberOptions);

  return useCallback(
    async (
      newAdvisorId: string,
      chatRoomId: string,
      customerName: string,
      hostName: string,
    ) => {
      const confirmed = await showModal.confirm({
        variant: 'severe',
        title: t('are-you-sure-to-assign-customer-to-host', {
          customer: customerName,
          host: hostName,
        }),
        message: t('all-the-information-and-files-within'),
      });

      if (!confirmed) {
        return false;
      }

      await addChatMember({ variables: { chatRoomId, newAdvisorId } });
      return true;
    },
    [addChatMember, showModal, t],
  );
}

export default useReassignUser;
