import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import cs from 'classnames';

import Layout from '../Layout';
import {
  Label,
  Container,
  ErrorText,
  TextInput,
  TextInputProps,
} from './components';
import { TextProps } from './types';

const Text = (props: TextProps & Omit<TextInputProps, 'onChange'>) => {
  const {
    label,
    onChange,
    onBlur,
    errorMessage,
    onSubmitEdit,
    value: externalValue,
    className,
    readonly,
    isLighterColor,
    ...textInputProps
  } = props;
  const [value, setValue] = useState(externalValue);

  const onInputBlur = useCallback(() => {
    onSubmitEdit?.(value);
    onBlur?.();
  }, [value, onSubmitEdit, onBlur]);

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      } else {
        setValue(e.target.value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (!onChange) {
      setValue(externalValue);
    }
  }, [onChange, externalValue]);

  if (readonly) {
    return (
      <div className="ltr:text-left rtl:text-right w-full">
        <Container className="flex items-center flex-1">
          {label && <Label isLighterColor={isLighterColor}>{label}</Label>}
          <div className={cs('p-4 flex min-w-0', label && 'pt-9')}>
            <p
              className={cs(
                'shrink truncate',
                isLighterColor ? 'text-dark-grey-01' : 'text-dark-grey-02',
              )}
            >
              {value}
            </p>
            <Layout.Spacer.Horizontal size="atomic" />
          </div>
        </Container>
      </div>
    );
  }

  return (
    <>
      <Container hasErrors={errorMessage !== undefined}>
        {label && <Label>{label}</Label>}
        <TextInput
          {...textInputProps}
          value={onChange ? externalValue : value}
          onBlur={onInputBlur}
          onChange={onChangeValue}
          className={cs(
            'text-base font-outfit font-semibold placeholder:font-normal',
            className,
            label ? 'pt-9' : '',
          )}
        />
      </Container>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
};

export default memo(Text);
