import React from 'react';

import Fallback from './Fallback';

const StudentProfileModalLazy = React.lazy(
  () => import('./StudentProfileModal'),
);

const StudentProfileModal = () => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <StudentProfileModalLazy />
    </React.Suspense>
  );
};

export default StudentProfileModal;
