import {
  MicrobotMemoryInfoFragment,
  MicrobotUrlMemoryInfoFragment,
  MicrobotFileMemoryInfoFragment,
  MicrobotTextMemoryInfoFragment,
} from '@advisor/api/generated/graphqlTypes';

export const messageLengthLimit = 135;

export const truncate = (message: string) => {
  const shouldTruncate = message.length > messageLengthLimit;
  const truncated = shouldTruncate
    ? `${message.substring(0, messageLengthLimit)} ...`
    : message;

  return {
    truncated,
    showButton: shouldTruncate,
  };
};

export function isTextMemory(
  memory: MicrobotMemoryInfoFragment,
): memory is MicrobotTextMemoryInfoFragment {
  return memory.__typename === 'MicrobotTextMemory';
}

export function isFileMemory(
  memory: MicrobotMemoryInfoFragment,
): memory is MicrobotFileMemoryInfoFragment {
  return memory.__typename === 'MicrobotFileMemory';
}

export function isUrlMemory(
  memory: MicrobotMemoryInfoFragment,
): memory is MicrobotUrlMemoryInfoFragment {
  return memory.__typename === 'MicrobotUrlMemory';
}

const NotSupportedFileExtensions = ['.docx', '.xlsx', '.txt'];

export function canAppPreviewFile(url: string) {
  const extension = url.split('.').pop();

  return !NotSupportedFileExtensions.includes(`.${extension}`);
}
