import { Dayjs } from 'dayjs';
import { chunk } from 'lodash-es';

/**
 * Returns a 2D array of days in the month, with each row representing a week.
 * Adds days from the previous and next month to fill out the first and last rows.
 */
export const getCalendarRows = (month: Dayjs) => {
  const start = month.startOf('month');

  const startOfFirstWeek = start.subtract(start.day(), 'day');

  // Add 42 days to get to the end of the last week of the month.
  const endOfLastWeek = startOfFirstWeek.add(42, 'day');

  const days = getDateSpan(startOfFirstWeek, endOfLastWeek).map((date) => ({
    date,
    dayInMonth: date.date(),
    isInMonth: date.month() === month.month(),
  }));

  return chunk(days, 7);
};

export const getNextMonth = (date: Dayjs) =>
  date.startOf('month').add(1, 'month');

export const getPrevMonth = (date: Dayjs) =>
  date.startOf('month').subtract(1, 'month');

/**
 * Returns an array of days in the range [start, end).
 */
export const getDateSpan = (start: Dayjs, end: Dayjs) => {
  let current = start.startOf('day');
  const days: Dayjs[] = [];
  while (current.isBefore(end, 'day')) {
    days.push(current);
    current = current.add(1, 'day');
  }

  return days;
};

export const isMonthDisabled = (month: Dayjs, minDate?: Dayjs) => {
  return minDate?.isAfter(month.endOf('month'), 'day') ?? false;
};

export const CalendarWidth = 326;
export const CalendarHeight = 348;
