import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleMessage } from '../systemMessageComponents';

const OnboardingApprovalDenied: React.FC = () => {
  const { t } = useTranslation('system-message');

  return (
    <SimpleMessage>
      {t('your-account-approval-was-denied-you-can-either')}
    </SimpleMessage>
  );
};

export default memo(OnboardingApprovalDenied);
