import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';
import { Feature } from '@advisor/api/feature';

export const canInviteToAgencyScope = Scope.every([
  Scope.feature(Feature.AgencyInvite),
  Scope.verifiedAdvisor,
]);

export const canInviteToThemselvesScope = Scope.verifiedAdvisor;

export const canInviteToConversationScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.studentConversation(chatRoomId),
    Scope.some([Scope.student, Scope.verifiedAdvisor]),
  ]),
);
