import { useSetAtom } from 'jotai';
import { useMolecule } from 'bunshi/react';

import MessageInputMolecule from './messageInputMolecule';

export default function useResetInput() {
  const { resetInputAtom } = useMolecule(MessageInputMolecule);

  return useSetAtom(resetInputAtom);
}
