import React from 'react';
import { useTranslation } from 'react-i18next';
import { times } from 'lodash-es';

import { SegmentedGroup, Skeleton } from '@advisor/design/components';
import SideModal from '../SideModal';

const TextFallback: React.FC<{ size: number }> = ({ size }) => {
  return (
    <>
      {times(size, (i) => (
        <React.Fragment key={i}>
          <Skeleton.Text fontSize={12} lineHeight={16} />
          <Skeleton.Text fontSize={14} lineHeight={20} />
        </React.Fragment>
      ))}
    </>
  );
};

const Fallback: React.FC = () => {
  const { t } = useTranslation('task-organiser');

  return (
    <SideModal.Default
      backButton
      title={t('task-organiser')}
      scroll
      className="flex flex-col gap-4"
    >
      <Skeleton.Ping>
        <SegmentedGroup.Root>
          <div className="flex flex-row items-center p-4 gap-3 border-grey">
            <div className="w-10 h-10 rounded-full bg-grey" />
            <div className="flex-1 min-w-0 flex flex-col">
              <Skeleton.Text lineHeight={20} fontSize={14} size={100} />
              <Skeleton.Text lineHeight={20} fontSize={14} size={120} />
            </div>
          </div>
        </SegmentedGroup.Root>
      </Skeleton.Ping>
      <Skeleton.Ping>
        <SegmentedGroup.Root>
          <div className="flex flex-col px-4 py-6 gap-6 border-grey">
            <TextFallback size={6} />
          </div>
        </SegmentedGroup.Root>
      </Skeleton.Ping>
      <Skeleton.Ping>
        <SegmentedGroup.Root>
          <div className="flex flex-col px-4 py-6 gap-6 border-grey">
            <TextFallback size={4} />
          </div>
        </SegmentedGroup.Root>
      </Skeleton.Ping>
      <Skeleton.Ping>
        <SegmentedGroup.Root>
          <div className="flex flex-col px-4 py-6 gap-6 border-grey">
            <TextFallback size={2} />
          </div>
        </SegmentedGroup.Root>
      </Skeleton.Ping>
    </SideModal.Default>
  );
};

export default Fallback;
