import { useMemo } from 'react';

import { CompleteButtonProps, CompletionStatus } from './types';

type Params = Pick<CompleteButtonProps, 'isCompleted' | 'pendingReview'>;

// eslint-disable-next-line import/prefer-default-export
export function useCompletionStatus({ isCompleted, pendingReview }: Params) {
  return useMemo((): CompletionStatus => {
    if (isCompleted) {
      return 'completed';
    }

    if (pendingReview) {
      return 'pending';
    }

    return 'incomplete';
  }, [isCompleted, pendingReview]);
}
