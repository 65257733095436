import React from 'react';

import type {
  FileData,
  FileUploadProcess,
  UploadErrorType,
} from '@advisor/api/files/types';
import type { FileInfoFragment } from '@advisor/api/generated/graphql';

export type FailedAttachment = { file: FileData; error: UploadErrorType };
export type UploadedAttachment = { file: FileInfoFragment };

export type Attachment =
  | FileUploadProcess
  | FailedAttachment
  | UploadedAttachment;

export function getAttachmentFile(attachment: Attachment) {
  return 'file' in attachment ? attachment.file : undefined;
}

export function hasUploadFailed(
  attachment: Attachment,
): attachment is FailedAttachment {
  return 'error' in attachment;
}

export function isBeingUploaded(
  attachment: Attachment,
): attachment is FileUploadProcess {
  return 'promise' in attachment;
}

export function isUploaded(
  attachment: Attachment,
): attachment is UploadedAttachment {
  return !isBeingUploaded(attachment) && !hasUploadFailed(attachment);
}

export interface FileAttachmentBaseProps {
  title?: string;
  description?: string;
  preview?: React.ReactNode;

  hasError?: boolean;
  isBeingDownloaded?: boolean;
}

export interface FileAttachmentProps {
  attachment: UploadedAttachment;
}

export type PickedAttachmentProps = {
  attachment: Attachment;
  onRemove: () => void;
};
