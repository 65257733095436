const getRandomAvatar = (path?: string, count = 8): string => {
  if (!path) {
    return '';
  }

  const avatarNumber = Math.floor(Math.random() * count) + 1;

  return `${path}/default_avatar_${avatarNumber}.png`;
};

export default getRandomAvatar;
