import React from 'react';
import hotToast from 'react-hot-toast';
import type { Namespace, ParseKeys } from 'i18next';
import type { _DefaultNamespace } from 'react-i18next/TransWithoutContext';

import type { ToastOptions } from './types';
import Toast from './Toast';

/**
 * options.messageI18Key has to be in 'common' namespace and can't use other tags than bold
 */
const showToast = <
  K extends ParseKeys<N>,
  N extends Namespace = _DefaultNamespace,
>(
  options: ToastOptions<K, N>,
) => {
  hotToast.success(<Toast<K, N> {...options} />);
};

export default showToast;
