import dayjs from 'dayjs';
import { produce } from 'immer';
import { last, sortBy } from 'lodash-es';
import { ApolloCache, FetchResult } from '@apollo/client';

import {
  MeQuery,
  MeDocument,
  ChatRoomsQuery,
  ChatRoomsDocument,
  ChatRoomWithMembersFragment,
  ChatRoomWithMembersFragmentDoc,
  ChatRoomType,
} from '@advisor/api/generated/graphql';
import { identifyChatRoomCache } from '@advisor/api/graphql/customCacheKeys';
import Sentry from '@advisor/utils/Sentry';

export function hasChatRoom(
  edges: ChatRoomsQuery['chatRooms']['edges'],
  id: string,
) {
  return !!edges.some(({ node }) => node.id === id);
}

export function cursorOfChatRoomWithId(
  edges: ChatRoomsQuery['chatRooms']['edges'],
  id: string,
) {
  return edges.find(({ node }) => node.id === id)?.cursor;
}

export function createChatRoomCursor(
  chatRoom: ChatRoomWithMembersFragment,
  userId: string,
) {
  return JSON.stringify({
    pk: `user_${userId}`,
    sk: `member_${chatRoom.id}`,
    memberSkLastMessage: `member_of_${
      chatRoom.latestMessage?.sentAt ?? chatRoom.createdAt
    }_${chatRoom.id}`,
  });
}

interface Edge {
  node: {
    id: string;
  };
}

export function findNode<T extends Edge>(
  connection: { edges: T[] },
  nodeId: string,
) {
  return connection.edges.find((edge) => edge.node.id === nodeId)?.node as
    | T['node']
    | undefined;
}

export function removeNode<T extends Edge>(
  connection: { edges: T[] },
  nodeId: string,
) {
  return connection.edges.filter(({ node }) => node.id !== nodeId);
}

type ChatRoomsQueryEdge = ChatRoomsQuery['chatRooms']['edges'][number];

const InitialChatRoomQuery = (chatRoomEdge: ChatRoomsQueryEdge) => ({
  __typename: 'Query' as const,
  chatRooms: {
    __typename: 'ChatRoomConnection' as const,
    count: 1,
    pageInfo: {
      __typename: 'PageInfo' as const,
      startCursor: chatRoomEdge.cursor,
      endCursor: chatRoomEdge.cursor,
      hasPreviousPage: false,
      hasNextPage: false,
    },
    edges: [chatRoomEdge],
  },
});

/**
 * @param cursorOrCursorOptions If you cannot provide a cursor, then provide parameters needed to create it locally.
 */
export const updateChatRooms = (
  query: ChatRoomsQuery | null | undefined,
  edgeDetails: {
    node: ChatRoomWithMembersFragment;
    cursor: string | { myId: string };
  },
): ChatRoomsQuery => {
  const chatRoomEdge = {
    ...edgeDetails,
    __typename: 'ChatRoomEdge' as const,
    cursor:
      typeof edgeDetails.cursor === 'string'
        ? edgeDetails.cursor
        : createChatRoomCursor(edgeDetails.node, edgeDetails.cursor.myId),
  };

  if (!query) {
    return InitialChatRoomQuery(chatRoomEdge);
  }

  return produce(query, (draft) => {
    const { chatRooms } = draft;

    const edgeIndex = chatRooms.edges.findIndex(
      ({ node }) => node.id === chatRoomEdge.node.id,
    );
    if (edgeIndex === -1) {
      chatRooms.count = (chatRooms.count ?? 0) + 1;
      chatRooms.edges.push(chatRoomEdge);
    } else {
      chatRooms.edges[edgeIndex] = chatRoomEdge;
    }

    chatRooms.edges = sortBy(
      sortBy(
        chatRooms.edges,
        ({ node }) =>
          -dayjs(node.latestMessage?.sentAt ?? node.createdAt).valueOf(),
      ),
      // Chats with microbots are pinned at the top.
      ({ node }) => (node.__typename === 'MicrobotChatRoom' ? 0 : 1),
    );
  });
};

export function deleteChatRooms(
  query: ChatRoomsQuery,
  chatRoomId: string,
): ChatRoomsQuery {
  return produce(query, (draft) => {
    const { chatRooms } = draft;
    const chatRoom = findNode(chatRooms, chatRoomId);

    if (!chatRoom) {
      return;
    }

    chatRooms.edges = removeNode(chatRooms, chatRoom.id);

    if (chatRooms.edges.length > 0) {
      const { pageInfo } = chatRooms;
      pageInfo.startCursor = chatRooms.edges[0].cursor;
      pageInfo.endCursor = last(chatRooms.edges)?.cursor;
    }

    chatRooms.count = (chatRooms.count ?? 1) - 1;
  });
}

/**
 * Update query and pagination for every possible status filter
 * as apollo keeps "copy" of the query for each variable
 * separately
 * @param cache  - Apollo cache instance
 * @param latestMessage - simplified to user message to the data used
 * @param lastSeenChatRoomOn - optional, if provided, will update last seen date-time for current user
 */
export function updateChatRoomsAfterMessage(
  cache: ApolloCache<object>,
  latestMessage:
    | {
        id: string;
        __typename: 'UserMessage';
        chatRoomId: string;
        message: string;
        sentAt: string;
      }
    | {
        id: string;
        __typename: 'SystemMessage';
        chatRoomId: string;
        sentAt: string;
      }
    | {
        id: string;
        __typename: 'MicrobotMessage';
        chatRoomId: string;
        message: string;
        sentAt: string;
      },
  lastSeenChatRoomOn?: string,
) {
  const { me } = cache.readQuery<MeQuery>({ query: MeDocument }) ?? {};

  const chatRoom = cache.readFragment<ChatRoomWithMembersFragment>({
    fragment: ChatRoomWithMembersFragmentDoc,
    fragmentName: 'ChatRoomWithMembers',
    id: identifyChatRoomCache({ id: latestMessage.chatRoomId }),
  });

  if (!me || !chatRoom || chatRoom.type === ChatRoomType.RoleRequest) {
    return;
  }

  const updatedChatRoom = produce(chatRoom, (draft) => {
    draft.latestMessage = { ...draft.latestMessage, ...latestMessage };

    if (lastSeenChatRoomOn) {
      const memberToUpdate = draft.members.find(
        ({ member }) => member.id === me.id,
      );

      if (memberToUpdate) {
        memberToUpdate.lastSeenChatRoomOn = lastSeenChatRoomOn;
      }
    }
  });

  cache.updateQuery(
    {
      query: ChatRoomsDocument,
    },
    (prev) => {
      if (!prev) {
        return prev;
      }

      const cursor = cursorOfChatRoomWithId(
        prev.chatRooms.edges,
        updatedChatRoom.id,
      );

      if (!cursor) {
        Sentry.captureException(
          new Error(
            `Tried to update chat-rooms query using non-existent cursor: '${cursor}'`,
          ),
        );
        return prev;
      }

      return updateChatRooms(prev, {
        node: updatedChatRoom,
        cursor,
      });
    },
  );
}

export const createMarkingHandler = (id: string) => {
  let resolve = () => {
    /* Temporary no-op */
  };

  return {
    id,
    promise: new Promise<void>((_resolve) => {
      resolve = _resolve;
    }),
    resolve: () => resolve(),
  };
};

export const CommonChatRoomMutationOptions = <TMutation>(
  edgeExtractor: (
    data: NonNullable<Omit<FetchResult<TMutation>, 'context'>['data']>,
  ) => {
    node: ChatRoomWithMembersFragment;
    cursor: string | { myId: string };
  },
) => ({
  update(
    cache: ApolloCache<unknown>,
    mutation: Omit<FetchResult<TMutation>, 'context'>,
  ) {
    if (!mutation.data) {
      return;
    }

    const edgeDetails = edgeExtractor(mutation.data);

    cache.updateQuery(
      {
        query: ChatRoomsDocument,
      },
      (prev) => updateChatRooms(prev, edgeDetails),
    );
  },
});
