import { atom } from 'jotai';
import { molecule } from 'bunshi';
import { atomWithImmer } from 'jotai-immer';

import {
  AiResponseStreamInfoFragment,
  RequestSuggestionDocument,
  SuggestionFromAiGeneratedSubscription,
  SuggestionGenerationError,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphqlTypes';
import Sentry from '@advisor/utils/Sentry';
import ifExists from '@advisor/utils/ifExists';
import { clientAtom } from '@advisor/api/apollo';
import { requireChatRoomScope } from '@advisor/api/chatRoom';
import takeWhileDefined from '@advisor/utils/takeWhileDefined';
import { actionAtom, atomWithRefine } from '@advisor/utils/atoms';
import { decodeSpecialCharacters } from '../../utils';
import type { Suggestion } from './types';
import {
  InitialSuggestionMessageCount,
  SuggestionMessageCountIncrease,
  SuggestionResponseTimeout,
} from './constants';

function makeTimeoutAtom() {
  type Value = null | ReturnType<typeof setTimeout>;
  const handleAtom = atom<Value>(null);

  return atom(null, (get, set, newTimeout: Value) => {
    const prev = get(handleAtom);
    if (prev) {
      clearTimeout(prev);
    }
    set(handleAtom, newTimeout);
  });
}

const MessageInputMolecule = molecule(() => {
  const chatRoomId = requireChatRoomScope();

  const messageInputAtom = atomWithRefine('', decodeSpecialCharacters);
  const editedMessageAtom = atom<UserMessageInfoFragment | null>(null);
  const suggestionAtom = atomWithImmer<Suggestion | null>(null);
  const suggestionTimeoutAtom = makeTimeoutAtom();

  const requestSuggestionAtom = actionAtom(async ({ get, set }) => {
    // After receiving each response, `messageCount` is incremented, so that
    // if we run `requestSuggestion` consecutively, the answer should get
    // more precise.
    const inputMessagesCount =
      get(suggestionAtom)?.messageCount ?? InitialSuggestionMessageCount;

    set(resetInputAtom);

    set(suggestionAtom, {
      hasMore: false,
      messageCount: inputMessagesCount,
      request: { status: 'Loading', startedAt: Date.now(), chunks: [] },
    });

    set(
      suggestionTimeoutAtom,
      setTimeout(() => {
        set(
          suggestionAtom,
          ifExists((draft) => {
            draft.request = {
              status: 'Error',
              type: 'TIMEOUT',
            };
          }),
        );
      }, SuggestionResponseTimeout),
    );

    try {
      const client = await get(clientAtom);
      const { data } = await client.mutate({
        mutation: RequestSuggestionDocument,
        variables: {
          chatRoomId,
          inputMessagesCount,
        },
      });

      if (!data) {
        throw new Error(`No response from requestSuggestion mutation.`);
      }

      set(suggestionAtom, (draft) => {
        if (!draft || draft.request.status !== 'Loading') {
          // Not loading anymore, abort
          return;
        }

        draft.request.id = data.requestSuggestion;
      });
    } catch (error) {
      Sentry.captureException(error);

      set(
        suggestionAtom,
        ifExists((draft) => {
          draft.request = {
            status: 'Error',
            type: SuggestionGenerationError.ApiError,
          };
        }),
      );
    }
  });

  const appendToStreamBufferAtom = actionAtom(
    ({ get, set }, data: AiResponseStreamInfoFragment) => {
      if (data.chatRoomId !== chatRoomId) {
        throw new Error(
          `Gotten suggestion stream chunk for wrong chatRoomId: '${data.chatRoomId}'. Expected '${chatRoomId}'`,
        );
      }

      set(suggestionAtom, (draft) => {
        if (!draft || draft.request.status !== 'Loading') {
          // Not loading anymore, abort
          return;
        }

        if (data.messageId !== draft.request.id) {
          // Stream is related to a different suggestion request.
          return;
        }

        draft.request.chunks[data.sequenceNumber] = data.tokens;
      });

      const request = get(suggestionAtom)?.request;
      if (request?.status === 'Loading' && request.id === data.messageId) {
        const availableTokens = takeWhileDefined(request.chunks).flat();
        const messageSoFar = availableTokens.join('');
        set(messageInputAtom, messageSoFar);
      }
    },
  );

  const receiveSuggestionAtom = actionAtom(
    (
      { get, set },
      data: NonNullable<
        SuggestionFromAiGeneratedSubscription['suggestionFromAIGenerated']
      >,
    ) => {
      const { id, message, inputMessagesCount, hasMore, error } = data;

      const request = get(suggestionAtom)?.request;

      if (!request || request.status !== 'Loading' || id !== request.id) {
        return;
      }

      // Clearing the timeout
      set(suggestionTimeoutAtom, null);

      if (error) {
        set(
          suggestionAtom,
          ifExists((draft) => {
            draft.request = {
              status: 'Error',
              type: error,
            };
          }),
        );
        return;
      }

      set(messageInputAtom, message ?? '');
      set(suggestionAtom, {
        hasMore: hasMore ?? false,
        messageCount:
          (inputMessagesCount ?? InitialSuggestionMessageCount) +
          SuggestionMessageCountIncrease,
        request: {
          status: 'Idle',
        },
      });
    },
  );

  const isSuggestionReloadDisabledAtom = atom((get) => {
    const { request, hasMore } = get(suggestionAtom) ?? {};

    const isReloadDisabled =
      request &&
      request.status !== 'Error' &&
      (request.status === 'Loading' || !hasMore);

    return isReloadDisabled;
  });

  const editMessageAtom = actionAtom(
    ({ set }, message: UserMessageInfoFragment) => {
      if (!message) {
        return;
      }

      set(resetInputAtom);
      set(messageInputAtom, message.message);
      set(editedMessageAtom, message);
    },
  );

  const resetInputAtom = actionAtom(({ set }) => {
    set(messageInputAtom, '');
    set(editedMessageAtom, null);
    set(suggestionAtom, null);
    set(suggestionTimeoutAtom, null); // Clearing the timeout
  });

  const isEditingMessageAtom = atom((get) => !!get(editedMessageAtom));
  const isSuggestionAtom = atom((get) => !!get(suggestionAtom));

  return {
    messageInputAtom,
    editedMessageAtom,
    isEditingMessageAtom,
    isSuggestionAtom,
    suggestionAtom: atom((get) => get(suggestionAtom)),
    isSuggestionReloadDisabledAtom,

    resetInputAtom,
    editMessageAtom,
    requestSuggestionAtom,
    appendToStreamBufferAtom,
    receiveSuggestionAtom,
  };
});

export default MessageInputMolecule;
