import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Env from '@advisor/api/env';
import { DeprecatedButton } from '@advisor/design/components';
import BasicLayout from 'src/components/layouts/BasicLayout';

const NotFound404Page = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <BasicLayout>
      <Helmet>
        <title>{Env.web.notFoundPageTitle}</title>
        <style>{`
          body {
            background: linear-gradient(180deg, #FFF 0%, #ECECED 100%);
          }
        `}</style>
      </Helmet>
      <div className="bg-black p-5 xl:py-7 xl:px-12">
        <img src={Env.web.logoWhite} alt={Env.organizationName} width={170} />
      </div>
      <div className="flex-2 bg-[#191919] w-full px-8 py-5 xl:py-12 xl:px-[132px]">
        <h1 className="font-sora text-white text-3xl xl:text-4xl mt-16 xl:mt-[116px]">
          {t('page-not-found')}
        </h1>
      </div>
      <div className="flex-3 w-full py-8 px-6 xl:px-[132px] xl:py-10">
        <h2 className="font-sora font-bold text-base xl:text-2xl">
          {t('oops')}!
        </h2>
        <p className="font-outfit font-medium text-sm mb-6 xl:text-base xl:max-w-[380px] xl:mb-8">
          {t('the-page-you-are-looking-for-cannot-be-found')}{' '}
          {t('please-check-the-url-and-try-again')}
        </p>
        <DeprecatedButton.Large
          width={224}
          variant="primary"
          label={t('go-home')}
          onPress={() => {
            navigate('/');
          }}
        />
        <img
          src="./images/paper-plane-not-found.svg"
          alt=""
          className="absolute -left-[50px] bottom-[12%] w-[295px] xl:w-[571px]"
        />
      </div>
    </BasicLayout>
  );
};

export default NotFound404Page;
