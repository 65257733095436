import React, { memo } from 'react';
import cs from 'classnames';

/**
 * The amount of images in the /images/homepage/people directory.
 */
const PERSON_IMAGES = 22;

const PersonBubbles = memo(({ className }: { className?: string }) => {
  return (
    <div
      className={cs(
        'w-full flex flex-row justify-center overflow-hidden pt-10',
        className,
      )}
    >
      <div className="w-full px-2 min-w-[1000px] flex flex-row justify-between">
        {new Array(9).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PersonBubbleRow key={index} startIndex={index * 3} length={3} />
        ))}
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-off-white" />
    </div>
  );
});

function PersonBubbleRow({
  className,
  startIndex,
  length,
}: {
  className?: string;
  startIndex: number;
  length: number;
}) {
  return (
    <div className={cs('flex flex-col space-y-5 odd:mt-9', className)}>
      {new Array(length).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PersonBubble key={index} index={startIndex + index} />
      ))}
    </div>
  );
}

function PersonBubble({ index }: { index: number }) {
  return (
    <img
      src={`/images/homepage/people/person-${index % PERSON_IMAGES}.png`}
      className="w-20 xl:w-28 rounded-full"
      style={{
        boxShadow: '0px 6px 48px -4px rgba(42, 50, 81, 0.15)',
      }}
      alt="A person's face"
    />
  );
}

export default PersonBubbles;
