import AssistFooterBox from './AssistFooterBox';
import AssistFooterLink from './AssistFooterLink';
import AssistFooterActions from './AssistFooterActions';
import AssistFooterContent from './AssistFooterContent';
import AssistFooterParagraph from './AssistFooterParagraph';

export default {
  Box: AssistFooterBox,
  Actions: AssistFooterActions,
  Content: AssistFooterContent,
  Paragraph: AssistFooterParagraph,
  ExternalLink: AssistFooterLink,
};

export { default as Box } from './AssistFooterBox';
export { default as Actions } from './AssistFooterActions';
export { default as Content } from './AssistFooterContent';
export { default as Paragraph } from './AssistFooterParagraph';
export { default as ExternalLink } from './AssistFooterLink';
