import React from 'react';
import { useAtomValue } from 'jotai';

import { isLazy } from '@advisor/utils/atoms/lazyFrom';
import { nonNullable } from '@advisor/utils/typeUtils';
import { lazyAtom, makeLifetime } from '@advisor/utils/atoms';
import { ScopeAtom } from './types';

type ScopeSelectOption<Props extends object> = {
  condition: 'default' | ScopeAtom;
  Component: React.ComponentType<Props>;
};

const createSelectAtom = <Props extends object>(
  options: ScopeSelectOption<Props>[],
) => {
  const lifetimes = options
    .map((a) =>
      a.condition !== 'default' && isLazy(a.condition)
        ? a.condition.lifetime
        : undefined,
    )
    .filter(nonNullable);

  return lazyAtom(async (get) => {
    const components = await Promise.all(
      options.map(async (option) => {
        const condition =
          option.condition === 'default' ? true : await get(option.condition);
        return condition ? option.Component : null;
      }),
    );

    return components.find((component) => component !== null);
  }, makeLifetime(...lifetimes));
};

/**
 * A higher-order component that renders the first component whose condition is satisfied.
 */
const PermissionSelect = <Props extends object>(
  options: ScopeSelectOption<Props>[],
) => {
  const selectAtom = createSelectAtom(options);

  const SelectComponent: React.FC<Props> = (props) => {
    const Component = useAtomValue(selectAtom);
    return Component ? <Component {...props} /> : null;
  };

  return SelectComponent;
};

export default PermissionSelect;
