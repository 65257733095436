import { useRef } from 'react';
import { isEqual } from 'lodash-es';

function useDeepMemo<T>(obj: T): T {
  const memoizedRef = useRef(obj);

  if (!isEqual(obj, memoizedRef.current)) {
    memoizedRef.current = obj;
  }

  return memoizedRef.current;
}

export default useDeepMemo;
