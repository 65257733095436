import cs from 'classnames';
import { useMolecule } from 'bunshi/react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { useEnterPress, useEvent } from '@advisor/utils/hooks';
import {
  useResetInput,
  MessageInputMolecule,
  suggestionGenerationErrorToTranslationKey,
} from '../../hooks/MessageInput';
import TimeoutDisplay from '../TimeoutDisplay';
import { useMessageLengthLimit } from '../hooks';
import ResizableTextArea from './ResizableTextArea';
import ParagraphLoader from './ParagraphLoader';
import SuggestionBar from './SuggestionBar';

interface Props {
  chatRoomId: string;
  disabled?: boolean;
  handleSend: () => void;
}

const MessageInput = ({ chatRoomId, handleSend, disabled }: Props) => {
  const { messageInputAtom, isEditingMessageAtom, suggestionAtom } =
    useMolecule(MessageInputMolecule);
  const [messageInput, setMessageInput] = useAtom(messageInputAtom);
  const isEditingMessage = useAtomValue(isEditingMessageAtom);
  const suggestion = useAtomValue(suggestionAtom);
  const { t } = useTranslation('common');
  const resetInput = useResetInput();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { approachingLimit, limitReached } = useMessageLengthLimit(chatRoomId);
  const showLimitMessage = limitReached || approachingLimit;

  const onChangeText = useEvent((newText: string) => {
    if (newText === '') {
      resetInput();
    } else {
      setMessageInput(newText);
    }
  });

  // Scroll to bottom of input while AI suggestion is being streamed in.
  useEffect(() => {
    if (suggestion?.request.status === 'Loading') {
      inputRef.current?.scrollTo({
        top: inputRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messageInput, suggestion]);

  const keyboardEventHandlers = useEnterPress(
    useEvent(() => {
      if (limitReached) {
        return;
      }

      handleSend();
    }),
  );

  const renderInput = useCallback(() => {
    if (
      suggestion &&
      suggestion.request.status === 'Loading' &&
      messageInput.trim().length === 0
    ) {
      return (
        <div>
          <TimeoutDisplay />
          <ParagraphLoader />
        </div>
      );
    }

    if (suggestion && suggestion.request.status === 'Error') {
      return (
        <button
          type="button"
          className="text-negative hover:text-negative-dark"
          onClick={resetInput}
        >
          {t(
            suggestionGenerationErrorToTranslationKey(suggestion.request.type),
          )}
        </button>
      );
    }

    return (
      <ResizableTextArea
        ref={inputRef}
        className="w-full resize-none focus:outline-none text-sm max-h-64 min-h-[20px] bg-[transparent]"
        placeholder={t('type-a-message...')}
        value={messageInput}
        disabled={disabled || suggestion?.request.status === 'Loading'}
        onChange={({ target }) => onChangeText(target.value)}
        data-cy="messageInput"
        {...keyboardEventHandlers}
      />
    );
  }, [
    t,
    disabled,
    onChangeText,
    resetInput,
    keyboardEventHandlers,
    messageInput,
    suggestion,
  ]);

  return (
    <div
      className={cs(
        'flex flex-col rounded-10 grow shrink bg-white',
        disabled && 'shadow-drop-02',
        suggestion ? 'min-h-10.5 min-w-0' : 'min-h-12.5 hover:shadow-drop-02',
      )}
    >
      {isEditingMessage && (
        <div className="select-none px-4 py-2 bg-grey self-stretch text-sm font-outfit rounded-tl-10 rounded-tr-10">
          {t('edit-message')}
        </div>
      )}
      <div
        className={cs(
          'grow px-4 flex flex-col',
          !!suggestion || isEditingMessage || showLimitMessage
            ? 'py-3.5 justify-between'
            : 'py-1 justify-center',
        )}
      >
        {renderInput()}
        {!!suggestion && <SuggestionBar className="mt-2" />}
        {showLimitMessage && (
          <p
            className={cs(
              'font-outfit text-xs pt-7',
              limitReached ? 'text-negative' : 'text-dark-grey-01',
            )}
          >
            {limitReached
              ? t('message-is-over-limit-please-revise')
              : t('message-is-close-to-the-word-limit')}
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(MessageInput);
