import { useMemo } from 'react';

import type { CallParticipant } from '../VideoRoomAPI';

function usePickUniqueParticipants<T extends CallParticipant>(
  participants: T[],
): T[] {
  return useMemo(
    () => participants.filter((p) => !p.secondarySession),
    [participants],
  );
}

export default usePickUniqueParticipants;
