import React from 'react';

import suspenseHOC from '@advisor/utils/suspenseHOC';
import Fallback from './Fallback';

const PreferencesModal = suspenseHOC(
  React.lazy(() => import('./PreferencesModal')),
  <Fallback />,
);

export default PreferencesModal;
