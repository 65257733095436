import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from '@apollo/client';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { createAuthLink } from 'aws-appsync-auth-link';
import React, { PropsWithChildren, useMemo } from 'react';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  useErrorLink,
  initJotaiApollo,
  useReFetchQueries,
  useRefreshTokensLink,
  useSubscriptionRetryLink,
} from '@advisor/api/apollo';
import Env from '@advisor/api/env';
import { useAuth } from '@advisor/api/auth';
import { useReadAtom } from '@advisor/utils/hooks';
import authStateAtom from '@advisor/api/auth/authStateAtom';
import typePolicies from '@advisor/api/graphql/typePolicies';
import generatedIntrospection from '@advisor/api/generated/graphql';

const { region } = Env.appsyncConfig;

if (Env.DEBUG) {
  loadDevMessages();
  loadErrorMessages();
}

const accessTokenOrEmpty = atom(async (get) => {
  const authState = await get(authStateAtom);
  return authState?.accessToken ?? '';
});

const expiresAtAtom = atom((get) => {
  const authState = get(unwrap(authStateAtom));
  return authState?.expiresAt;
});

const globalApolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  defaultOptions: {
    query: {},
  },
});
initJotaiApollo(globalApolloClient);

const AuthorizedApolloProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { renewSession, logout } = useAuth();

  const readAccessToken = useReadAtom(accessTokenOrEmpty);
  const readExpiresAt = useReadAtom(expiresAtAtom);

  const errorLink = useErrorLink();
  const retryLink = useSubscriptionRetryLink(renewSession);
  const refreshLink = useRefreshTokensLink({
    getExpirationTime: readExpiresAt,
    renewSession,
    logout,
  });

  const client = useMemo(() => {
    const auth = {
      type: Env.appsyncConfig.authType,
      jwtToken: readAccessToken,
    };

    const httpLink = createHttpLink({ uri: Env.api.graphQLUrl });
    const authLink = createAuthLink({ url: Env.api.graphQLUrl, region, auth });
    const subscriptionLink = createSubscriptionHandshakeLink(
      { url: Env.api.graphQLUrl, region, auth },
      httpLink,
    );

    const combinedLink = from([
      errorLink,
      retryLink,
      refreshLink,
      authLink,
      subscriptionLink,
    ]);

    globalApolloClient.setLink(combinedLink);

    return globalApolloClient;
  }, [readAccessToken, refreshLink, errorLink, retryLink]);

  useReFetchQueries(client);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
