import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Avatar, SpinnerIcon } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { BannerView } from '@advisor/design/components/Banner';
import {
  ApproveRoleRequestBannerContentProps,
  ApproveRoleRequestBannerProps,
} from './types';
import useApproveRoleRequestBanner from './useApproveRoleRequestBanner';

const ApproveRoleRequestBanner: React.FC<ApproveRoleRequestBannerProps> = ({
  chatRoomId,
}) => {
  const navigate = useNavigate();
  const onActionCallback = () => navigate('/chat');

  const { isRoleRequest, advisor, isProcessing, onApprove, onDecline } =
    useApproveRoleRequestBanner(chatRoomId, onActionCallback, onActionCallback);

  if (!isRoleRequest || !advisor) {
    return null;
  }

  return (
    <BannerView variant="primary">
      <ApproveRoleRequestBannerContent
        advisor={advisor}
        isProcessing={isProcessing}
        onApprove={onApprove}
        onDecline={onDecline}
      />
    </BannerView>
  );
};

const ApproveRoleRequestBannerContent = (
  props: ApproveRoleRequestBannerContentProps,
) => {
  const { onApprove, onDecline, isProcessing, advisor } = props;

  const { t } = useTranslation(['common', 'onboarding']);

  return (
    <div className="bg-primary-light">
      <p className="md:hidden text-sm font-semibold mb-3 basis-full">
        <Trans
          t={t}
          i18nKey="onboarding:approval-request-host"
          components={{ bold: <b> </b> }}
        />
      </p>
      <div className="flex items-center justify-between md:w-chat-width md:mx-auto">
        <div className="flex items-center gap-2 overflow-hidden">
          <Avatar
            bordered={false}
            avatarUrl={advisor.avatarUrl}
            size={Spacing.small02}
            className="min-w-[2rem]"
          />
          <div className="font-outfit text-dark-grey-03 text-sm font-medium">
            <p className="hidden md:block text-left rtl:text-right">
              <Trans
                t={t}
                i18nKey="onboarding:approval-request-host"
                components={{ bold: <b> </b> }}
              />
            </p>
            <p className="hidden md:block">
              <span className="font-bold truncate">{advisor.name}</span> (
              {advisor.agency} • {advisor.email ?? t('common:n-a')})
            </p>
            <p className="md:hidden text-sm font-bold">{advisor.name}</p>
            <p className="md:hidden text-xs font-medium">{advisor.agency}</p>
            <p className="md:hidden text-xs font-medium truncate">
              {advisor.email ?? t('common:n-a')}
            </p>
          </div>
        </div>
        {isProcessing ? (
          <SpinnerIcon className="w-6 h-6 text-dark-grey-03" />
        ) : (
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="py-2 px-3 font-bold font-outfit text-xs bg-white text-primary rounded-lg hover:bg-off-white active:bg-off-white"
              onClick={onApprove}
            >
              {t('onboarding:approve')}
            </button>
            <button
              type="button"
              className="py-2 px-3 font-bold font-outfit text-xs bg-white text-negative rounded-lg hover:bg-off-white active:bg-off-white"
              onClick={onDecline}
            >
              {t('onboarding:decline')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApproveRoleRequestBanner;
