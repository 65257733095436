import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useMessageSeenBy } from '@advisor/chat/messaging';
import UserList from '@advisor/ui/components/UserList';
import { Layout } from '@advisor/design/components';
import { MessageInfoParams } from 'src/routes/params';
import useChatIdParam from 'src/hooks/useChatIdParam';
import SideModal from './SideModal';

const MessageInfoModal = () => {
  const chatRoomId = useChatIdParam();
  const { t } = useTranslation('common');
  const { sentAt } = useParams<MessageInfoParams>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const seenStats = useMessageSeenBy(sentAt!, chatRoomId!);

  return (
    <>
      <SideModal.Header>
        <SideModal.Title title={t('message-info')} />
      </SideModal.Header>
      <SideModal.Body>
        {seenStats.seen.length > 0 && (
          <UserList
            users={seenStats.seen}
            title={t('seen')}
            showCount
            variant="grey"
          />
        )}
        {seenStats.notSeen.length > 0 && (
          <>
            <Layout.Spacer.Vertical size="small01" />
            <UserList
              users={seenStats.notSeen}
              title={t('not-yet-seen')}
              showCount
              variant="grey"
            />
          </>
        )}
        {seenStats.notVisibleTo.length > 0 && (
          <>
            <Layout.Spacer.Vertical size="small01" />
            <UserList
              users={seenStats.notVisibleTo}
              title={t('not-visible-to')}
              showCount
              variant="grey"
              transparent
            />
            <Layout.Spacer.Vertical size="tiny" />
            <p className="font-outfit text-xs text-dark-grey-025 font-normal opacity-50">
              {t(
                'this-message-will-not-be-seen-by-some-members-who-joined-after-it-was-created',
              )}
            </p>
          </>
        )}
      </SideModal.Body>
    </>
  );
};

export default MessageInfoModal;
