import React, { Suspense } from 'react';

import { Layout, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

const PrivateNotesModalContentLazy = React.lazy(
  () => import('./PrivateNotesModalContent'),
);

const PrivateNotesModal = () => (
  <Suspense fallback={<Fallback />}>
    <PrivateNotesModalContentLazy />
  </Suspense>
);

export default PrivateNotesModal;

const Fallback: React.FC = () => (
  <>
    <div className="h-header-height flex items-center border-b border-grey px-6">
      <Skeleton.Text fontSize={16} lineHeight={20} />
    </div>
    <div className="my-6 ml-6">
      <Skeleton.Text fontSize={16} lineHeight={20} size={Spacing.huge} />
    </div>
    <div className="flex flex-col px-6 gap-4">
      <div className="w-full rounded border border-grey py-4 px-6">
        <Skeleton.Text fontSize={14} lineHeight={20} size={Spacing.large70} />
        <Layout.Spacer.Vertical size="quantum" />
        <Skeleton.Text fontSize={14} lineHeight={20} size={Spacing.huge} />
      </div>
      <div className="w-full rounded border border-grey py-4 px-6">
        <Skeleton.Text fontSize={14} lineHeight={20} size={Spacing.large70} />
        <Layout.Spacer.Vertical size="quantum" />
        <Skeleton.Text fontSize={14} lineHeight={20} size={Spacing.huge} />
      </div>
    </div>
  </>
);
