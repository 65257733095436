import { atom } from 'jotai';

/**
 * ID of the bulk upload that is being shown in the details modal.
 * Setting an non-null value will automatically open the modal.
 */
export const currentBulkUploadIdAtom = atom<string | null>(null);

/**
 * Index of the bulk upload that is highlighted in the widget/banner.
 * If mutliple bulk uploads are in progress or queue,
 * the widget will allow the user to move between them.
 */
export const highlightedBulkUploadIndexAtom = atom<number | null>(null);
