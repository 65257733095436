import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { cameraPermissionsAtom, microphonePermissionsAtom } from './atoms';
import { UsePermissionsReturnType, PermissionStatus } from './types';

function parseNavigatorPermissionState(
  navigatorState: PermissionState,
): PermissionStatus {
  switch (navigatorState) {
    case 'granted':
      return PermissionStatus.GRANTED;
    case 'denied':
      return PermissionStatus.DENIED;
    case 'prompt':
    default:
      return PermissionStatus.UNDETERMINED;
  }
}

export default function usePermissions(): UsePermissionsReturnType {
  const [cameraPermissions, setCameraPermissions] = useAtom(
    cameraPermissionsAtom,
  );

  const [microphonePermissions, setMicrophonePermissions] = useAtom(
    microphonePermissionsAtom,
  );

  const checkPermissions = useCallback(async () => {
    if (
      cameraPermissions === PermissionStatus.GRANTED &&
      microphonePermissions === PermissionStatus.GRANTED
    ) {
      return true;
    }

    try {
      const camera = await navigator.permissions.query({
        // @ts-ignore not all browsers support camera name
        name: 'camera',
      });
      const microphone = await navigator.permissions.query({
        // @ts-ignore not all browsers support microphone name
        name: 'microphone',
      });

      setCameraPermissions(parseNavigatorPermissionState(camera.state));
      setMicrophonePermissions(parseNavigatorPermissionState(microphone.state));

      camera.onchange = () => {
        setCameraPermissions(parseNavigatorPermissionState(camera.state));
      };

      microphone.onchange = () => {
        setMicrophonePermissions(
          parseNavigatorPermissionState(microphone.state),
        );
      };

      return camera.state === 'granted' && microphone.state === 'granted';
    } catch (e) {
      Sentry.captureException(e);

      // Without permissions api assume they are granted
      setCameraPermissions(PermissionStatus.GRANTED);
      setMicrophonePermissions(PermissionStatus.GRANTED);
      return true;
    }
  }, [
    cameraPermissions,
    setCameraPermissions,

    microphonePermissions,
    setMicrophonePermissions,
  ]);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  const hasAllPermissions =
    cameraPermissions === PermissionStatus.GRANTED &&
    microphonePermissions === PermissionStatus.GRANTED;

  return {
    hasAllPermissions,
    cameraPermissions,
    microphonePermissions,
    // Permission request ist automatically handled by browser
    // when daily call is started, only check them for convenience
    requestPermissions: checkPermissions,
  };
}
