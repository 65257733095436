/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentClass, memo, useMemo } from 'react';

import { useTheme } from '../../Theme/context';
import { parseColor } from '../../Theme/utils';
import { IconProps } from './types';
import Svgs from './svgs';

const Icon: React.FC<IconProps> = (props) => {
  const { name, color, size, ...otherProps } = props;
  const IconComponent = Svgs[name] as unknown as ComponentClass<
    Record<string, any>,
    any
  >;
  const theme = useTheme();

  const iconColor = useMemo(() => parseColor(theme, color), [theme, color]);

  const sizeProps = size ? { width: size, height: size } : {};

  return (
    <IconComponent
      style={{
        ...(iconColor ? { color: iconColor } : {}),
      }}
      {...sizeProps}
      {...otherProps}
    />
  );
};

export default memo(Icon);
