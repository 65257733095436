import React, { memo, useCallback, useEffect, useState } from 'react';

import Layout from '../../../Layout';
import { Colorspace } from '../utils';
import { HSVColor } from '../types';
import SaturationValueSlider from './SaturationValueSlider';
import HueSlider from './HueSlider';
import { ColorPickerProps } from './types';

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const { initialValue, setValue } = props;

  const [hsv, setHSV] = useState<HSVColor>(Colorspace.RGBtoHSV(initialValue));

  const setHue = useCallback(
    (hue: number) => {
      const newValue = { ...hsv, hue };

      setHSV(newValue);
      setValue(Colorspace.HSVtoRGB(newValue));
    },
    [setValue, hsv],
  );

  const setSaturationAndValue = useCallback(
    (saturation: number, value: number) => {
      const newValue = { ...hsv, saturation, value };

      setHSV(newValue);
      setValue(Colorspace.HSVtoRGB(newValue));
    },
    [hsv, setValue],
  );

  useEffect(() => {
    setHSV(Colorspace.RGBtoHSV(initialValue));
  }, [initialValue]);

  return (
    <>
      <SaturationValueSlider value={hsv} setValue={setSaturationAndValue} />
      <Layout.Spacer.Vertical size="small01" />
      <HueSlider value={hsv.hue} setValue={setHue} />
      <Layout.Spacer.Vertical size="micro" />
    </>
  );
};

export default memo(ColorPicker);
