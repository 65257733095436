import { ScopeProvider } from 'bunshi/react';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import Logger from '@advisor/utils/Logger';
import ChatRoomScope from './chatRoomScope';
import { ChatRoomContext, useChatRoom } from './chatRoomAtoms';

const ActiveChatRoomContext = createContext<string | null | 'no-provider'>(
  'no-provider',
);

export function ActiveChatRoomProvider({
  chatRoomId,
  children,
}: {
  chatRoomId?: string;
  children?: ReactNode;
}) {
  const chatId = (chatRoomId !== 'null' && chatRoomId) || null;

  /**
   * `bunshi` ScopeProviders provide stale values. To both mitigate errors
   * relating to null chatRoomIds, but also reduce the amount of remounting,
   * a stable key is constructed that flips its value between `yay` or `nay`.
   *
   * https://github.com/saasquatch/bunshi/issues/71
   */
  const stabilityKey = chatId ? 'yay' : 'nay';

  return (
    <ActiveChatRoomContext.Provider value={chatId} key={stabilityKey}>
      <ScopeProvider scope={ChatRoomScope} value={chatId ?? undefined}>
        {children}
      </ScopeProvider>
    </ActiveChatRoomContext.Provider>
  );
}

export function useActiveChatRoom(flag: 'required'): ChatRoomContext;
export function useActiveChatRoom(flag?: undefined): ChatRoomContext | null;
export function useActiveChatRoom(
  flag: 'required' | undefined = undefined,
): ChatRoomContext | null {
  const value = useContext(ActiveChatRoomContext);

  const chatRoomId = useMemo(() => {
    if (value === 'no-provider') {
      Logger.warn(
        `Using 'useActiveChatRoom' without an 'ActiveChatRoomProvider' upstream.`,
      );
      return null;
    }

    return value || null;
  }, [value]);

  if (chatRoomId === null && flag === 'required') {
    throw new Error(`An active chat-room is required for a part of the app.`);
  }

  return useChatRoom(chatRoomId);
}
