import OnboardingMessageComponent from './OnboardingMessage';
import * as Components from './MessageComponents';

const OnboardingMessage = {
  Root: OnboardingMessageComponent,
  Title: Components.Title,
  SubTitle: Components.SubTitle,
  Description: Components.Description,
};

export default OnboardingMessage;
