import { Getter, Setter, atom } from 'jotai';

export type Context = {
  get: Getter;
  set: Setter;
};

/**
 * An alternative syntax for creating a getter-less atom.
 *
 * No dependencies, nor mutations
 * ```
 * // vanilla syntax
 * const anAtom = atom(null, (_, __, firstParam: string, secondParam: number) => ...);
 * // actionAtom syntax
 * const anAtom = actionAtom((_, firstParam: string, secondParam: number) => ...);
 * ```
 *
 * No mutations
 * ```
 * // vanilla syntax
 * const anAtom = atom(null, (get, _, firstParam: string, secondParam: number) => ...);
 * // actionAtom syntax
 * const anAtom = actionAtom(({ get }, firstParam: string, secondParam: number) => ...);
 * ```
 *
 * Dependencies and mutations
 * ```
 * // vanilla syntax
 * const anAtom = atom(null, (get, set, firstParam: string, secondParam: number) => ...);
 * // actionAtom syntax
 * const anAtom = actionAtom(({ get, set }, firstParam: string, secondParam: number) => ...);
 * ```
 */
function actionAtom<Params extends unknown[], Result>(
  setter: (ctx: Context, ...params: Params) => Result,
) {
  return atom(null, (get, set, ...params: Params) => {
    return setter({ get, set }, ...params);
  });
}

export default actionAtom;
