import { SegmentedGroupRadioButtonProps } from './types';

// TODO: implement if needed :-)
function SegmentedGroupRadioButton<T extends string>(
  // @ts-ignore
  // eslint-disable-next-line
  props: SegmentedGroupRadioButtonProps<T>,
) {
  // eslint-disable-next-line
  console.warn(
    '@advisor/design/components/SegmentedGroupRadioButton not implemented for web!',
  );
  return null;
}

export default SegmentedGroupRadioButton;
