let LibPhoneNumber: typeof import('libphonenumber-js') | undefined;

const isValidPhoneNumberAsync = async (phoneNumber: string) => {
  // Lazy load libphonenumber-js
  // to avoid increasing bundle size.
  if (!LibPhoneNumber) {
    LibPhoneNumber = await import('libphonenumber-js');
  }
  const { isValidPhoneNumber } = LibPhoneNumber;
  return isValidPhoneNumber(phoneNumber);
};

export default isValidPhoneNumberAsync;
