import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { memo, useCallback, useState } from 'react';

import { Scope } from '@advisor/api/scope';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import OnboardingMessage from '@advisor/design/components/OnboardingMessage';
import { nullAtom } from '@advisor/utils/atoms';
import { Layout } from '@advisor/design/components';
import { useInvitation } from '@advisor/onboarding';
import { FamilyJoinRequestStatus } from '@advisor/api/generated/graphql';
import { useOpenStudentSearchForm } from '../../FamilyMember/StudentSearchFormModal';
import useCreateChatRoom from '../useCreateChatRoom';

const FamilyMemberOnboardingMessage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const amISingleChatUser = useAtomValue(Scope.singleChatUser);

  const chatRoomCtx = useActiveChatRoom();
  const invitation = useInvitation();

  const handleNavigateToNewChat = useCallback(
    (chatRoomId: string) => {
      navigate(
        `/chat/${chatRoomId}`,
        amISingleChatUser ? { replace: true } : undefined,
      );
    },
    [navigate, amISingleChatUser],
  );

  const { createChatRoom } = useCreateChatRoom({
    onSuccess: handleNavigateToNewChat,
  });
  const chatRoom = useAtomValue(chatRoomCtx?.chatRoomAtom ?? nullAtom);
  const hasStatusNone =
    !!chatRoom &&
    chatRoom.__typename === 'RequestorChatRoom' &&
    chatRoom.joinRequest?.status === FamilyJoinRequestStatus.None;

  const openSearchForm = useOpenStudentSearchForm();

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    if (invitation.inviteType === 'conversation') {
      await createChatRoom();
      setIsLoading(false);
      return;
    }

    openSearchForm();
    setIsLoading(false);
  }, [createChatRoom, openSearchForm, invitation]);

  return (
    <div className="mb-4 max-w-chat-width mx-auto">
      <OnboardingMessage.Root border={false}>
        <OnboardingMessage.Title>
          {t('guest-customer-onboarding-message-title')}
        </OnboardingMessage.Title>
        <OnboardingMessage.SubTitle>
          {t('guest-customer-onboarding-message-subtitle')}
        </OnboardingMessage.SubTitle>
        <p className="font-outfit text-sm font-medium block text-center w-3/4 md:w-2/3 mx-auto">
          {t('guest-customer-onboarding-message-description')}
        </p>
        <Layout.Spacer.Vertical size="small01" />
        {hasStatusNone && (
          <button
            type="button"
            className="bg-primary text-white font-outfit text-sm font-medium rounded-10 py-3 px-10 hover:opacity-70 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleClick}
            disabled={isLoading}
          >
            {t('join-a-conversation')}
          </button>
        )}
      </OnboardingMessage.Root>
    </div>
  );
};

export default memo(FamilyMemberOnboardingMessage);
