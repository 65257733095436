import React from 'react';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { Avatar, Icon, Layout, Loader } from '@advisor/design/components';
import { PrimaryContextSection } from '@advisor/microbots/components';
import { Spacing } from '@advisor/design/styles';
import {
  useMicrobot,
  useMicrobotConfigurationComponent,
} from '@advisor/microbots/hooks';
import { ComponentPosition } from '@advisor/microbots/hooks/useMicrobotConfigurationComponent';
import { useGoBackOrGoHome } from '@advisor/utils/navigation/web';
import { enumFromStringValue } from '@advisor/utils/typeUtils';
import { MicrobotInfoParams } from 'src/routes/params';
import SideModal from './SideModal';

const ConfigureMicrobotModal: React.FC = () => {
  const { t } = useTranslation('microbot');
  const { microbotName } = useParams<MicrobotInfoParams>();

  const personality = enumFromStringValue(
    MicrobotPersonality,
    (microbotName ?? '').toLocaleUpperCase(),
  );

  const onGoBack = useGoBackOrGoHome(`/community/microbot/${microbotName}`);

  const microbot = useMicrobot(personality, 'required');
  const { Component, position } =
    useMicrobotConfigurationComponent(personality) ?? {};

  return (
    <>
      <SideModal.Header onClose={onGoBack}>
        <SideModal.Title title={t('configure-name', { name: microbot.name })} />
      </SideModal.Header>
      <SideModal.Body padding={false}>
        <div className="px-6 pb-6">
          {/* Header */}
          <div className="flex flex-row items-center justify-between pt-6 pb-8 border-b border-grey">
            <div>
              <h3 className="font-sora leading-5 font-semibold text-dark-grey-03">
                {t('configure-bot-title')}
              </h3>
              <Layout.Spacer.Vertical size="atomic" />
              <div className="flex flex-row">
                <p
                  className="font-sora text-xl leading-6 font-bold"
                  style={{ color: microbot.color }}
                >
                  {microbot.name}
                </p>
                <Icon
                  name={microbot.nameIcon}
                  size={Spacing.tiny}
                  color={microbot.color}
                />
              </div>
            </div>
            <div className="relative">
              <Avatar
                bordered={false}
                className="!rounded-2xl"
                avatarUrl={microbot.avatarUrl}
                size={60}
              />
              <Icon
                name="GlobalStudyBadge"
                className="absolute bottom-0 right-0"
                size={Spacing.tiny}
              />
            </div>
          </div>

          <Layout.Spacer.Vertical size="small02" />

          {/* Message */}
          <p className="font-outfit leading-5 font-medium text-dark-grey-02">
            {t('configure-bot-message', { name: microbot.name })}
          </p>

          <Layout.Spacer.Vertical size="small02" />

          {/* Microbot specific component
              (if it should be displayed above the
              Primary Context section) */}
          {!!Component && position === ComponentPosition.Top && (
            <>
              <Component />
              <Layout.Spacer.Vertical size="tiny" />
            </>
          )}

          {/* Primary context edit/reset section */}
          <PrimaryContextSection.Other microbot={microbot} />
          <Layout.Spacer.Vertical size="tiny" />

          {/* Microbot specific component
              (if it should be displayed below the
              Primary Context section) */}
          {!!Component && position === ComponentPosition.Bottom && (
            <>
              <Component />
              <Layout.Spacer.Vertical size="tiny" />
            </>
          )}

          <p className="font-outfit text-sm font-medium text-dark-grey-02">
            {t('you-can-also-use-default-preferences')}
          </p>
        </div>
      </SideModal.Body>
      <Outlet />
    </>
  );
};

export default suspenseHOC(ConfigureMicrobotModal, () => (
  <Loader className="mx-auto my-2" />
));
