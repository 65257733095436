import { Layout } from '@advisor/design/components';

import Env from '@advisor/api/env';
import {
  Ul,
  Ol,
  Li,
  Link,
  Container,
  PolicyInfo,
  SectionBody,
  PageSubtitle,
  SectionTitle,
  SectionSubtitle,
} from './components';
import PageLayout from './PageLayout';

const PrivacyPolicyPage = () => {
  return (
    <PageLayout
      title="Privacy Policy"
      headerTitle={Env.web.privacyPolicyPageTitle}
    >
      <Container>
        <Layout.Spacer.Vertical size="huge" />
        <PageSubtitle />
        <PolicyInfo>Effective: January 4, 2024</PolicyInfo>
        <Layout.Spacer.Vertical size="small01" />
        <SectionBody>
          This Privacy Policy describes how Global Study collects, uses, and
          discloses information associated with an identified or identifiable
          individual (referred to in this Privacy Policy as “Personal Data”) and
          what choices you have around this activity. If you have any questions,
          please don’t hesitate to contact us.
        </SectionBody>
        <SectionBody>
          When we refer to “Global Study”, we mean Global Study Technology
          Limited, as explained in more detail in the “Identifying the Data
          Controller and Processor” section below.
        </SectionBody>
        <Layout.Spacer.Vertical size="small01" />
        <TableOfContents />
        <Layout.Spacer.Vertical size="small01" />
        <ApplicabilityOfThisPrivacyPolicy />
        <Layout.Spacer.Vertical size="small01" />
        <InformationWeCollectAndReceive />
        <Layout.Spacer.Vertical size="small01" />
        <HowWeProcessYourInformation />
        <Layout.Spacer.Vertical size="small01" />
        <HowWeShareAndDiscloseInformation />
        <Layout.Spacer.Vertical size="small01" />
        <DataRetention />
        <Layout.Spacer.Vertical size="small01" />
        <Security />
        <Layout.Spacer.Vertical size="small01" />
        <AgeLimitations />
        <Layout.Spacer.Vertical size="small01" />
        <ChangesToThisPrivacyPolicy />
        <Layout.Spacer.Vertical size="small01" />
        <InternationalDataTransfers />
        <Layout.Spacer.Vertical size="small01" />
        <DataProtectionOfficer />
        <Layout.Spacer.Vertical size="small01" />
        <IdentifyingTheDataControllerAndProcessor />
        <Layout.Spacer.Vertical size="small01" />
        <YourRights />
        <Layout.Spacer.Vertical size="small01" />
        <YourCaliforniaPrivacyRights />
        <Layout.Spacer.Vertical size="small01" />
        <DataProtectionAuthority />
        <Layout.Spacer.Vertical size="small01" />
        <ContactingGlobalStudy />
      </Container>
    </PageLayout>
  );
};

export default PrivacyPolicyPage;

const TableOfContents = () => (
  <>
    <SectionSubtitle className="font-normal text-2xl font-outfit">
      Table of Contents
    </SectionSubtitle>
    <Layout.Spacer.Vertical size="small01" />
    <SectionBody>
      <Ol className="text-lg list-decimal pl-5">
        <Li>
          <Link href="#applicability-of-this-privacy-policy" className="">
            Applicability of this Privacy Policy
          </Link>
        </Li>
        <Li>
          <Link href="#information-we-collect-and-receive" className="">
            Information We Collect and Receive
          </Link>
        </Li>
        <Li>
          <Link href="#how-we-process-your-information" className="">
            How We Process Your Information and Our Legal Bases for Doing So
          </Link>
        </Li>
        <Li>
          <Link href="#how-we-share-and-disclose-information" className="">
            How we Access, Use, Share, and Store Your Information
          </Link>
        </Li>
        <Li>
          <Link href="#data-retention" className="">
            Data Retention
          </Link>
        </Li>
        <Li>
          <Link href="#security" className="">
            Security
          </Link>
        </Li>
        <Li>
          <Link href="#age-limitations" className="">
            Age Limitations
          </Link>
        </Li>
        <Li>
          <Link href="#changes-to-this-privacy-policy" className="">
            Changes to this Privacy Policy
          </Link>
        </Li>
        <Li>
          <Link href="#international-data-transfers" className="">
            International Data Transfers
          </Link>
        </Li>
        <Li>
          <Link href="#data-protection-officer" className="">
            Data Protection Officer
          </Link>
        </Li>
        <Li>
          <Link
            href="#identifying-the-data-controller-and-processor"
            className=""
          >
            Identifying the Data Controller and Processor
          </Link>
        </Li>
        <Li>
          <Link href="#your-rights" className="">
            Your Rights
          </Link>
        </Li>
        <Li>
          <Link href="#california-privacy-rights" className="">
            California Privacy Rights
          </Link>
        </Li>
        <Li>
          <Link href="#data-protection-authority" className="">
            Data Protection Authority
          </Link>
        </Li>
        <Li>
          <Link href="#contacting-global-study" className="">
            Contacting Global Study
          </Link>
        </Li>
      </Ol>
    </SectionBody>
  </>
);

const ApplicabilityOfThisPrivacyPolicy = () => (
  <>
    <SectionTitle id="applicability-of-this-privacy-policy">
      1. Applicability of this Privacy Policy
    </SectionTitle>
    <SectionBody>
      This Privacy Policy applies to Global Study’s online Student Advisor, AI
      Lead Qualifier and other productivity tools and platforms(collectively,
      the “<b>Software</b>”), including the associated Global Study mobile and
      desktop applications (collectively, the “<b>Services</b>”),
      GlobalStudy.chat and other Global Study websites (collectively, the “
      <b>Websites</b>”) and other interactions (e.g., customer service
      inquiries, user conferences, etc.) you may have with Global Study. If you
      do not agree with this Privacy Policy, then do not access or use the
      Services, Websites or any other aspect of Global Study’s business. For the
      avoidance of doubt, this is the only privacy policy that applies to Global
      Study.
    </SectionBody>
    <SectionBody>
      This Privacy Policy does not apply to any third-party applications or
      software that integrate with the Services through the Global Study
      platform (“<b>Third-Party Services</b>”), or any other third-party
      products, services or businesses who will provide their services under
      their own terms of service and privacy policy. In addition, a separate
      agreement, which may include our separate Software Terms of Service,
      governs delivery, access and use of the Services (the “
      <b>Customer Agreement</b>”), including the processing of data such as
      messages, files or other content submitted through Services accounts
      (collectively, “<b>Customer Data</b>”). The organization (e.g., your
      employer or another entity or person) that entered into the Customer
      Agreement (“<b>Customer</b>”) controls its instance of the Services and
      any associated Customer Data. If you have any questions about specific
      settings and privacy practices, please contact the Customer with which you
      work. If you have received an invitation to join Student Advisor but have
      not yet created an account, you should request assistance from the
      Customer that sent the invitation.
    </SectionBody>
    <SectionBody className="text-sm italic">
      California Notice of Collection of Personal Information: We collect the
      information described below under “Information We Collect and Receive” for
      the business and commercial purposes described below under “Information
      Use.” To learn more about exercising your California Privacy Rights please
      review the “California Privacy Rights” section below.
    </SectionBody>
  </>
);

const InformationWeCollectAndReceive = () => (
  <>
    <SectionTitle id="information-we-collect-and-receive">
      2. Information We Collect and Receive
    </SectionTitle>
    <SectionBody>
      Global Study will collect and receive information through operating the
      Services and Websites, and through other interactions with Global Study.
      Such information will include Customer Data and other information and data
      (“Other Information”) in a variety of ways:
    </SectionBody>
    <SectionBody>
      <Ul>
        <Li>
          <b>Customer Data.</b> Customers or individuals granted access to our
          Software by a Customer (“<b>Authorized Users</b>”) routinely submit
          Customer Data (such as messages, files or other content submitted
          through Services accounts) to Global Study when using the Services.
        </Li>
        <Li>
          <b>Other Information.</b> Global Study also collects, generates and/or
          receives the following categories of other information.
          <Ol className="list-inside list-decimal pl-5 text-base">
            <Li>
              <b>Account information:</b> To create or update an account using
              our Software, you or our Customer (e.g. your employer) supply
              Global Study with an email address, phone number, and/or other
              account set up. In addition, Customers that purchase a paid
              version of the Services provide Global Study (or its payment
              processors) with billing details such as credit card information,
              banking information, and/or a billing address.
            </Li>
            <Li>
              Usage information:
              <Ul>
                <Li>
                  <i>Services metadata.</i> When an Authorized User interacts
                  with the Services, metadata is generated that provides
                  additional context about the way that an Authorized User uses
                  the Services. For example, Global Study logs the sessions,
                  people, features, content, and links you view or interact
                  with, the types of files shared and what Third-Party Services
                  are used (if any).
                </Li>
                <Li>
                  <i>Log data.</i> As with most websites and technology services
                  delivered over the Internet, our servers automatically collect
                  information when you access or use our Websites or Services
                  and record it in log files. This log data may include your
                  Internet Protocol (IP) address, the address of the web page
                  you visited before using the Website or Services, browser type
                  and settings, the date and time the Services were used,
                  information about browser configuration and plugins, and your
                  language preferences.
                </Li>
                <Li>
                  <i>Device information.</i> Global Study may collect
                  information about devices accessing the Services, including
                  type of device, what operating system is used, device
                  settings, application IDs, unique device identifiers and crash
                  data. Whether we collect some or all of this Other Information
                  often depends on the type of device used and its settings.
                </Li>
                <Li>
                  <i>Location information.</i> We may receive information from
                  you, our Customers and other third-parties that helps us
                  approximate your location. We may, for example, use a business
                  address submitted by your employer (who is our Customer) or an
                  IP address received from your browser or device to determine
                  approximate location to assist with localization or for
                  security purposes.
                </Li>
              </Ul>
            </Li>
            <Li>
              <b>Cookie information:</b> Global Study uses a variety of cookies
              and similar technologies in our Websites and Services to help us
              collect Other Information.
            </Li>
            <Li>
              <b>Third-Party Services information:</b> A Customer can choose to
              permit or restrict Third-Party Services for its accounts and
              Global Study can receive personal data from such Third-Party
              Services. Typically, Third-Party Services are software that
              integrate with our Services, and a Customer can permit its
              Authorized Users to enable and disable these integrations for its
              accounts. Global Study may also develop and offer Global Study
              applications that connect the Services with a Third-Party Service.
              Once enabled, the provider of a Third-Party Service may share
              certain information with Global Study. For example, if a cloud
              storage application you are using is enabled to permit files to be
              imported to a Student Advisor student journey tracker, we may
              receive the user name and email address of Authorized Users, along
              with additional information that the application makes available
              to Global Study to facilitate the integration. Authorized Users
              should check the privacy settings and notices in these Third-Party
              Services to understand what data may be disclosed to Global Study.
              When a Third-Party Service is enabled, Global Study is authorized
              to connect and access Other Information made available to Global
              Study in accordance with our agreement with the provider of the
              Third-Party Service and any permission(s) granted by our Customer
              (including, by its Authorized User(s)). Examples of information
              which Global Study may receive in this manner include whether you
              successfully created a new account or interacted with a
              third-party application in a way that is attributable to Global
              Study usage activity. We do not, however, receive or store
              passwords for any of these Third-Party Services when connecting
              them to the Services.
            </Li>
            <Li>
              <b>Contact information:</b> In accordance with the consent process
              provided by your device or other third-party API, any contact
              information that an Authorized User chooses to import (such as
              importing student information from a device or API), forward or
              upload to the Services (for example, when sending emails to the
              Services) is collected when using the Services.
            </Li>
            <Li>
              <b>Third-Party data:</b> Global Study may receive data about
              organizations, industries, lists of companies that are customers,
              Website visitors, marketing campaigns and other matters related to
              our business from parent corporation(s), affiliates and
              subsidiaries, our partners, or others that we use to make our own
              information better or more useful. This data may be combined with
              Other Information we collect and might include aggregate-level
              data, such as which IP addresses correspond to zip codes or
              countries. Or it might be more specific: for example, how well an
              online marketing or email campaign performed.
            </Li>
            <Li>
              <b>Audio and video metadata:</b> Global Study may receive,
              capture, and store metadata derived from your use of features such
              as video room conversations, and additional related data such as
              data regarding the date and time of your video room conversations
              and the Authorized User that you connected with.
            </Li>
            <Li>
              <b>Additional information provided to Global Study:</b> We also
              receive Other Information when submitted to our Websites or in
              other ways, such as responses or opinions you provide if you
              participate in a focus group, contest, activity or event, feedback
              you provide about our products or services, information you
              provide if you apply for a job with Global Study, enroll in a
              certification program or other educational program hosted by
              Global Study or a vendor, request support, interact with our
              social media accounts or otherwise communicate with Global Study.
            </Li>
          </Ol>
        </Li>
      </Ul>
    </SectionBody>
    <SectionBody>
      Generally, no one is under a statutory or contractual obligation to
      provide any Customer Data or Other Information (collectively,
      “Information”). However, certain Information is collected automatically
      and, if some Information, such as account setup details, is not provided,
      we may be unable to provide the Services.
    </SectionBody>
  </>
);

const HowWeProcessYourInformation = () => (
  <>
    <SectionTitle id="how-we-process-your-information">
      3. How We Process Your Information and Our Legal Bases for Doing So
    </SectionTitle>
    <SectionBody>
      Customer Data will be used by Global Study in accordance with a Customer’s
      instructions, including to provide the Services, any applicable terms in
      the Customer Agreement, a Customer’s use of Services functionality, and as
      required by applicable law. Global Study is a processor of Customer Data
      and the Customer is the controller. Customer may, for example, use the
      Services to grant and remove access to a Student Advisor account, assign
      roles and configure settings, access, modify, export, share, and remove
      Customer Data, and otherwise apply its policies to the Services.
    </SectionBody>
    <SectionBody>
      Global Study uses Other Information to operate our Services, Websites, and
      business. More specifically, Global Study uses Other Information for the
      following purposes:
    </SectionBody>
    <SectionTitle sub>Compliance With A Legal Obligation:</SectionTitle>
    <SectionBody>
      Global Study processes Other Information when we comply with a legal
      obligation including, for example, to access, preserve or disclose certain
      information if there is a valid legal request from a regulator, law
      enforcement or others. For example, a search warrant or production order
      from law enforcement to provide information in relation to an
      investigation, such as your profile picture or IP address.
    </SectionBody>
    <SectionBody>
      We use account information, Usage information, Cookie information,
      Third-Party Services Information, Contact information, Third-Party data,
      Audio and video metadata, and Additional information provided to Global
      Study for compliance with a legal obligation.
    </SectionBody>

    <SectionTitle sub>Legitimate Interests:</SectionTitle>
    <SectionBody>
      We rely on our legitimate interests or the legitimate interests of a
      third-party where they are not outweighed by your interests or fundamental
      rights and freedoms ("legitimate interests").
    </SectionBody>
    <SectionBody>
      We use account information, Usage information, Cookie information,
      Third-Party Services Information, Contact information, Third-Party data,
      Audio and video metadata, and Additional information provided to Global
      Study for the following legitimate interests:
      <Ul>
        <Li>
          <b>
            To provide, update, maintain and protect our Services, Websites, and
            business:
          </b>{' '}
          This includes the use of Other Information to support delivery of the
          Services under a Customer Agreement, prevent or address service
          errors, security or technical issues, analyze and monitor usage,
          trends and other activities, or at an Authorized User’s request.
          <Ul>
            <Li>
              It is in our and your interests to provide, update, maintain and
              protect our Services, Websites, and business.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>
            To develop and provide search, learning and productivity tools and
            additional features:
          </b>{' '}
          Global Study tries to make the Services as useful as possible for
          specific accounts and Authorized Users. For example, we may:
          <Ul>
            <Li>
              Improve the Cosmo AI Memory Bank by using Other Information to
              help determine and rank the relevance of content, channels or
              expertise to an Authorized User.
            </Li>
            <Li>
              Make Services or Third-Party Service suggestions based on
              historical use and predictive models.
            </Li>
            <Li>Identify organizational trends and insights.</Li>
            <Li>
              Customize a Services experience or create new productivity
              features and products.
            </Li>
            <Li>
              It is in our interest and in the interest of Customers and
              Authorized Users to continuously improve and develop the customer
              support we provide.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>To investigate and help prevent security issues and abuse:</b> We
          may use a variety of tools such as device fingerprinting to prevent
          issues and abuse. We may process, including in an automated fashion,
          Other Information to better understand how Global Study is used or to
          prevent spam or abuse.
          <Ul>
            <Li>
              It is in our interest to keep the Service secure and to detect,
              prevent, and address abuse (such as spam) and to investigate and
              take action in respect of suspicious activity on the Services.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>To aggregate or de-identify information:</b> In some cases, we
          aggregate or de-identify information we have associated with you and
          use the resulting information, for example, to improve the Services.
          <Ul>
            <Li>It is in our interest to research and improve the Services.</Li>
            <Li>
              It is in the interests of Customers and Authorized Users to
              practice data minimization and privacy by design in respect of
              their information.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>
            Share information with others, including law enforcement and to
            respond to legal requests:
          </b>
          <Ul>
            <Li>
              It is in our interest and the interest of the general public to
              prevent and address fraud, unauthorized use of Global Study,
              violations of our terms or other harmful or illegal activity; to
              protect ourselves, our users or others, including as part of
              investigations or regulatory enquiries; or to prevent death or
              imminent bodily harm.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>
            Transfer, store or process your information outside the European
            Economic Area:
          </b>{' '}
          As the Websites and Services operate globally, with Customers around
          the world, we need to share information we collect globally. We carry
          out necessary transfers outside the European Economic Area, including
          to Australia, Canada, Japan, India, South Korea, and the United
          States, to provide, update, maintain and protect our Services,
          Websites and business. For more information, review the “International
          Data Transfers” section below.
          <Ul>
            <Li>
              In our and your interests to provide, update, maintain and protect
              our Services, Websites and business
            </Li>
          </Ul>
        </Li>
      </Ul>
    </SectionBody>
    <SectionBody>
      We use account information, Third-Party Services Information, Third-Party
      data, and Additional information provided to Global Study for the
      following legitimate interests:
      <Ul>
        <Li>
          <b>
            To communicate with you by responding to your requests, comments,
            and questions.
          </b>{' '}
          If you contact us, we may use your Other Information to respond.
          <Ul>
            <Li>
              It is in our, our Customers’ and Authorized Users’ interests to
              facilitate communication (for example to answer questions from
              Customers).
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>To send service emails and other communications:</b> For example,
          we may:
          <Ul>
            <Li>
              Send you service, technical, and other administrative emails,
              messages, and other types of communications; or
            </Li>
            <Li>
              Contact you to inform you about changes in our Services, our
              Service offerings, and important Service-related notices, such as
              security and fraud notices. These communications are considered
              part of the Services, and you may not opt out of them. It is in
              our Customers and Authorized Users’ interests to address
              service-related issues.
            </Li>
          </Ul>
        </Li>
      </Ul>
    </SectionBody>
    <SectionBody>
      We use account information and Usage information for the following
      legitimate interests:
      <Ul>
        <Li>
          <b>
            For billing, account management, and other administrative matters:
          </b>
          Global Study may need to contact you for invoicing, account
          management, and similar reasons, and we use account data to administer
          accounts and keep track of billing and payments.
          <Ul>
            <Li>
              It is in our interest to facilitate the effective provision and
              administration of the Websites and Services.
            </Li>
          </Ul>
        </Li>
      </Ul>
    </SectionBody>
    <SectionBody>
      We use Workspace and account information, Usage Information, Cookie
      information, Third-Party Services Information, Third-Party data, and
      Additional information provided to Global Study for the following
      legitimate interests:
      <Ul>
        <Li>
          <b>To send marketing emails and other communications:</b> We sometimes
          send emails about new product features, promotional communications, or
          other news about Global Study. These are marketing messages, so you
          can control whether you receive them. If you have additional questions
          about a message you have received from Global Study, please get in
          touch through the contact mechanisms described below.
          <Ul>
            <Li>
              It is in our interest to promote the Websites and Services and
              send our direct marketing.
            </Li>
          </Ul>
        </Li>
      </Ul>
    </SectionBody>
  </>
);

const HowWeShareAndDiscloseInformation = () => (
  <>
    <SectionTitle id="how-we-share-and-disclose-information">
      4. How we Access, Use, Share, and Store Your Information
    </SectionTitle>
    <SectionBody>
      This section describes how Global Study may access, use, share and store
      Information, as described in the section entitled 'Information We Collect
      and Receive' above. Customers determine their own policies and practices
      for the sharing and disclosure of Information to third parties. Global
      Study does not control how a Customer or any third party chooses to share
      or disclose Information.
    </SectionBody>
    <SectionBody>
      Student Advisor allows you to create and automatically manage events
      related to your activity within Student Advisor. To do so, Student Advisor
      uses OAuth token- based authentication mechanism, which gives access to
      your Google account data without letting it know your password. This token
      is securely stored on our servers and used to access Google Calendar API
      to manage events related to your activity in Student Advisor.
    </SectionBody>
    <SectionBody>
      A. In particular, Student Advisor:
      <Ol className="list-decimal pl-12 text-base">
        <Li>
          will only use access to read, modify and delete events within your
          calendar including event metadata, summary, start and end date.
        </Li>
        <Li>
          does not store any data related to your Google calendar content.
        </Li>
        <Li>
          will read and store your primary Google account email address in order
          to correctly identify you and link to a proper account in the calendar
          event link.
        </Li>
        <Li>
          will only create new events based on your direct interaction by
          pressing the ‘Add to Google Calendar’ button.
        </Li>
        <Li>
          will only modify or delete google calendar events that were created
          through Student Advisor.
        </Li>
        <Li>
          will not use this Google Calendar data for serving advertisements.
        </Li>
        <Li>will not allow humans to read this data.</Li>
        <Li>
          Google Calendar integration is optional, thus authorization of your
          Google account is not mandatory to use Student Advisor.
        </Li>
      </Ol>
    </SectionBody>
    <SectionBody>
      You can revoke Student Advisor access to your Google account anytime by
      going to Carl configuration or directly at{' '}
      <Link href="/community/microbot/carl/configure">
        https://globalstudy.chat/community/microbot/carl/configure
      </Link>{' '}
      (address requires being signed in).
    </SectionBody>
    <SectionBody>
      Student Advisor's use and transfer to any other app of information
      received from Google APIs will adhere to{' '}
      <Link href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
        Google API Services User Data Policy
      </Link>
      , including the Limited Use requirements.
    </SectionBody>
    <SectionBody>
      B. We may share and disclose your Information when providing the Services:
    </SectionBody>
    <SectionBody>
      <Ul>
        <Li>
          <b>The Customer’s Instructions.</b> Global Study may share and
          disclose Information in accordance with a Customer’s instructions and
          with appropriate consent, including any applicable terms in the
          Customer Agreement and the Customer’s use of Services functionality
          and in compliance with applicable law and legal process. Some sharing
          at a Customer’s request may incur additional fees.
          <Ul>
            <Li>
              To enable Global Study to follow our Customers’ instructions,
              Global Study provides administrator controls to allow Customers to
              manage their accounts. For example, we follow our Customers’
              instructions to enable or disable Authorized Users' use of various
              features of the Services, such as the Retool administrative
              dashboard.
            </Li>
            <Li>
              We also follow Customer and Authorized User instructions on how an
              Authorized User’s profile may be displayed within a Customer’s
              account or when shared through other features.
            </Li>
            <Li>
              Customers may also provide their Authorized Users with the ability
              to adjust the audience and visibility of certain Customer Data.
            </Li>
          </Ul>
        </Li>
        <Li>
          <b>Displaying the Services:</b> When an Authorized User submits
          Information, it may be displayed or discoverable to other Authorized
          Users in the same or connected account(s). For example, an Authorized
          User’s email address may be displayed with their profile, or other
          profile and organizational information may be displayed to Authorized
          Users.
        </Li>
        <Li>
          <b>Collaborating with Others:</b> The Services provide different ways
          for Authorized Users working to collaborate and interact. Information,
          such as an Authorized User’s profile and organizational information,
          may be shared, subject to the policies and practices of the account(s)
          you use. For example, depending on the settings of your account, to
          enable connections with other Authorized Users, your profile may be
          shared or searchable or discoverable by Authorized Users. In many
          instances, Global Study includes either administrator controls or user
          controls, depending on the use case, with respect to external
          collaboration. Authorized Users may also decide to expand the
          visibility of certain content and Customer Data, such as files.
        </Li>
        <Li>
          <b>Customer Access:</b> Owners, administrators, Authorized Users, and
          other Customer representatives and personnel may be able to access,
          modify, or restrict access to Information. This may include, for
          example, your employer using features of the Services to access or
          modify your profile details or to export logs of account activity.
        </Li>
        <Li>
          <b>Third-Party Service Providers and Partners:</b> We may engage
          third-party companies or individuals as service providers or business
          partners to process Information and support our business. These third
          parties may, for example, provide virtual computing and storage
          services, assist Global Study with verifying Owners and Customers, or
          we may share business information to develop strategic partnerships
          with Third-Party service providers to support our common customers. In
          this respect, depending on the Third-Party service provided, Global
          Study may share your Information.
        </Li>
        <Li>
          <b>Third-Party Services:</b> A Customer may enable, or permit
          Authorized Users to enable, Third-Party Services. We require each
          Third-Party Service provider to disclose all permissions for
          information accessible through the Services, but we do not guarantee
          that they do so. When Third-Party Services are enabled by a Customer
          or an Authorized User, Global Study may share Information with
          Third-Party Services. Third-Party Services are not owned or controlled
          by Global Study, and third parties that have been granted access to
          Information may have their own policies and practices for its
          collection, use, and sharing. Please check the permissions, privacy
          settings, and notices for these Third-Party Services or contact the
          provider for any questions.
        </Li>
        <Li>
          <b>Forums:</b> The Information you choose to provide in a community
          forum, including personal data, will be publicly available.
        </Li>
        <Li>
          <b>Organizers and Sponsors of Events/Webinars:</b> If you attend an
          event or webinar organized by Global Study, we may share your profile
          and organizational information with the event or webinar sponsors when
          you register, have your badge scanned, or join a breakout room. If
          required by applicable law, you may consent to such sharing via the
          registration form or by allowing your attendee badge to be scanned at
          a sponsor booth. In these circumstances, your information will be
          subject to the sponsors’ privacy statements. For more information,
          please refer to the terms provided when you register for such an event
          or webinar.
        </Li>
        <Li>
          <b>Professional Advisers:</b> We may share your Information with
          professional advisers acting as service providers, processors,
          controllers, or joint controllers - including lawyers, bankers,
          auditors, and insurers who provide consultancy, banking, legal,
          insurance and accounting services, and to the extent we are legally
          obliged to share or have a legitimate interest in sharing your
          Information containing personal data.
        </Li>
        <Li>
          <b>Corporate Affiliates:</b> Global Study may share Information with
          our corporate affiliates, parents, and/or subsidiaries.
        </Li>
        <Li>
          <b>During a Change to Global Study’s Business:</b> If Global Study
          engages in a merger, acquisition, bankruptcy, dissolution,
          reorganization, sale of some or all of Global Study’s assets or stock,
          financing, public offering of securities, acquisition of all or a
          portion of our business, a similar transaction or proceeding, or steps
          in contemplation of such activities, some or all of the Information
          described in the ‘Information We Collect and Receive’ section may be
          shared or transferred, subject to standard confidentiality
          arrangements.
        </Li>
        <Li>
          <b>Aggregated or De-identified Data:</b> We may disclose or use
          aggregated or de-identified Information for any purpose. For example,
          we may share aggregated or de-identified Information with prospects or
          partners for business or research.
        </Li>
        <Li>
          <b>Law Enforcement and Regulators:</b> If we receive a request for
          information, we may disclose Other Information if we reasonably
          believe disclosure is in accordance with or required by any applicable
          law, regulation, or legal process.
        </Li>
        <Li>
          <b> To Enforce Our Rights, Prevent Fraud, and for Safety:</b> To
          protect and defend the rights, property, or safety of Global Study,
          its users, or third parties, including enforcing its contracts or
          policies, or in connection with investigating and preventing illegal
          activity, fraud, or security issues, including to prevent death or
          imminent bodily harm.
        </Li>
        <Li>
          <b>With Consent:</b> Global Study may share Information with third
          parties when we have consent to do so or as otherwise permitted in
          this Privacy Policy. For accounts registered to corporate entities,
          Global Study may share Information with the consent of the account
          primary owner or authorized corporate officer, or their designee. For
          accounts created without a formal corporate entity affiliation, Global
          Study may require user consent.
        </Li>
      </Ul>
    </SectionBody>
  </>
);

const DataRetention = () => (
  <>
    <SectionTitle id="data-retention">5. Data Retention</SectionTitle>
    <SectionBody>
      Global Study will retain Customer Data in accordance with a Customer’s
      instructions (including to perform any applicable terms in the Customer
      Agreement and through Customer’s use of Services functionality) and as
      required by applicable law. Customer may customize its retention settings
      and, depending on the Services plan, apply those customized settings. The
      Customer may also apply different settings to messages, files or other
      types of Customer Data. The deletion of Customer Data and other use of the
      Services by the Customer may result in the deletion and/or
      de-identification of certain associated Other Information.
    </SectionBody>
    <SectionBody>
      Global Study may retain Other Information pertaining to you for as long as
      necessary for the purposes described in this Privacy Policy (such as to
      provide the Services, including any optional features you use, and to
      provide customer support). This may include keeping your Other Information
      after you have deactivated your account for the period of time needed for
      Global Study to pursue legitimate business interests, conduct audits,
      comply with (and demonstrate compliance with) legal obligations, resolve
      disputes, and enforce our agreements.
    </SectionBody>
  </>
);

const Security = () => (
  <>
    <SectionTitle id="security">6. Security</SectionTitle>
    <SectionBody>
      Global Study takes security of data very seriously. Global Study works
      hard to protect Information you provide from loss, misuse, and
      unauthorized access or disclosure. These steps take into account the
      sensitivity of the Information we collect, process and store, and the
      current state of technology. Given the nature of communications and
      information processing technology, Global Study cannot guarantee that
      Information during transmission through the Internet or while stored on
      our systems or otherwise in our care will be absolutely safe from
      intrusion by others. When you click a link to a third-party site, you will
      be leaving our site and we don’t control or endorse what is on third-party
      sites.
    </SectionBody>
  </>
);

const AgeLimitations = () => (
  <>
    <SectionTitle id="age-limitations">7. Age Limitations</SectionTitle>
    <SectionBody>
      Global Study does not allow use of our Services and Websites by anyone
      younger than 13years old, to the extent prohibited by applicable law. If
      you learn that any one younger than 16 has unlawfully provided us with
      personal data, please contact us and we will take steps to delete such
      information.
    </SectionBody>
  </>
);

const ChangesToThisPrivacyPolicy = () => (
  <>
    <SectionTitle id="changes-to-this-privacy-policy">
      8. Changes to this Privacy Policy
    </SectionTitle>
    <SectionBody>
      Global Study may change this Privacy Policy from time to time. Laws,
      regulations, and industry standards evolve, which may make those changes
      necessary, or we may make changes to our services or business. We will
      post the changes to this page and encourage you to review our Privacy
      Policy to stay informed. If we make changes that materially alter your
      privacy rights, Global Study will provide additional notice, such as via
      email or through the Services. If you disagree with the changes to this
      Privacy Policy, you should deactivate your Services account. Contact the
      Customer if you wish to request the removal of Personal Data under their
      control.
    </SectionBody>
  </>
);

const InternationalDataTransfers = () => (
  <>
    <SectionTitle id="international-data-transfers">
      9. International Data Transfers
    </SectionTitle>
    <SectionBody>
      Global Study may transfer your Personal Data to countries other than the
      one in which you live, including transfers to the United States. To the
      extent that Personal Data is transferred abroad, Global Study will ensure
      compliance with the requirements of the applicable laws in the respective
      jurisdiction in line with Global Study’s obligations.
    </SectionBody>
    <SectionBody>
      In particular, we offer the following safeguards if Global Study transfers
      Personal Data from jurisdictions with differing data protection laws:
    </SectionBody>
    <SectionBody>
      <Ul>
        <Li>
          <b>European Commission’s Standard Contractual Clauses.</b> Global
          Study uses Standard Contractual Clauses approved by the European
          Commission (and the equivalent standard contractual clauses for the UK
          where appropriate) for transfers to, among others, Australia, Canada,
          India, Japan, South Korea, and the United States. Global Study will
          transfer your Personal Data to facilitate the provision of the
          Services.
        </Li>
        <Li>
          <b>
            Asia-Pacific Economic Cooperation Cross-Border Privacy Rules System
            And Privacy Recognition For Processors.
          </b>{' '}
          Global Study’s privacy practices, described in this Privacy Policy,
          comply with the Asia-Pacific Economic Cooperation (“APEC”) Cross
          Border Privacy Rules (“CBPR”) system and the Privacy Recognition for
          Processors (“PRP”). The APEC CBPR system provides a framework for
          organizations to ensure protection of personal data transferred among
          participating APEC economies and the PRP demonstrates an
          organization’s ability to provide effective implementation of a
          personal data controller’s privacy obligations related to the
          processing of personal information. More information about the APEC
          framework can be{' '}
          <Link href="https://cbprs.blob.core.windows.net/files/CBPR%20Policies,%20Rules%20and%20Guidelines%20Revised%20For%20Posting%203-16.pdf">
            found here
          </Link>
          {'. '}
          If you have an unresolved privacy or data use concern related to our
          <Link href="https://www.schellman.com/apec-certificate-directory">
            APEC CBPR or PRP
          </Link>{' '}
          certifications that we have not addressed satisfactorily, you may
          contact us at legal@global.study.
        </Li>
      </Ul>
    </SectionBody>
  </>
);

const DataProtectionOfficer = () => (
  <>
    <SectionTitle id="data-protection-officer">
      10. Data Protection Officer
    </SectionTitle>
    <SectionBody>
      To communicate with our Data Protection Officer, please email
      <Link href="mailto:dpo#global.study">dpo@global.study</Link>.
    </SectionBody>
  </>
);

const IdentifyingTheDataControllerAndProcessor = () => (
  <>
    <SectionTitle id="identifying-the-data-controller-and-processor">
      11. Identifying the Data Controller and Processor
    </SectionTitle>
    <SectionBody>
      Data protection law in certain jurisdictions differentiates between the
      “controller” and “processor” of information. In general, Customer is the
      controller of Customer Data. In general, Global Study is the processor of
      Customer Data and the controller of Other Information.
    </SectionBody>
  </>
);

const YourRights = () => (
  <>
    <SectionTitle id="your-rights">12. Your Rights</SectionTitle>
    <SectionBody>
      Individuals in the European Economic Area, the United Kingdom, Brazil, and
      across the globe have certain statutory rights in relation to their
      personal data. Subject to any exemptions provided by law, you may have the
      right to request access to your personal information, as well as to seek
      to update, delete, or correct this information. You can do this using the
      settings and tools provided in your Services account. If you cannot use
      the settings and tools, contact the Customer who controls your account for
      additional access and assistance.
    </SectionBody>
    <SectionBody>
      To the extent that Global Study’s processing of your Personal Data is
      subject to the General Data Protection Regulation or other applicable laws
      requiring a legal basis for processing Personal Data, such as the UK Data
      Protection Act and the Brazilian General Data Protection Act (Lei Geral de
      Proteção de Dados), Global Study primarily relies on its legitimate
      interests, described above, to process your Personal Data. Where we rely
      on legitimate interests to process your Personal Data, you can object to
      that processing by contacting us as described in the “Contacting Global
      Study” section below. In response to your objection, we will stop
      processing your information for the relevant purposes unless we have
      compelling grounds in the circumstances or the processing is necessary in
      the context of legal claims. Global Study may also process Other
      Information that constitutes your Personal Data for direct marketing
      purposes and you have a right to object to Global Study’s use of your
      Personal Data for this purpose at any time.
    </SectionBody>
  </>
);

const YourCaliforniaPrivacyRights = () => (
  <>
    <SectionTitle id="california-privacy-rights">
      13. Your California Privacy Rights
    </SectionTitle>
    <SectionBody>
      This section provides additional details about the personal information we
      collect about California consumers and the rights afforded to them under
      the California Consumer Privacy Act or “CCPA,” as amended by the
      California Privacy Rights Actor “CPRA”.
    </SectionBody>
    <SectionBody>
      California law requires that we detail the categories of personal
      information that we collect and disclose for certain “business purposes,”
      such as to service providers that assist us with securing our services or
      marketing our products, and to such other entities as described in earlier
      sections of Privacy Policy. In addition to the information provided above
      in the ‘Information We Collect And Receive’ section, we collect the
      following categories of personal information from you, your employer, data
      analytics providers, data brokers, and Third-Party Services for our
      business purposes:
    </SectionBody>
    <SectionBody>
      <Ul>
        <Li>Identifiers/contact information;</Li>
        <Li>Commercial information;</Li>
        <Li>Internet or electronic network activity information;</Li>
        <Li>Financial information;</Li>
        <Li>Geolocation information;</Li>
        <Li>Professional or employment-related information;</Li>
        <Li>Audio and visual data;</Li>
        <Li>
          In limited circumstances where allowed by law, information that may be
          protected under California or United States law; and
        </Li>
        <Li>Inferences drawn from any of the above categories.</Li>
      </Ul>
    </SectionBody>
    <SectionBody>
      Global Study does not sell (as such term is defined in the CCPA or
      otherwise) the personal information we collect (and will not sell it
      without providing a right to opt out). We may also share personal
      information (in the form of identifiers and internet activity information)
      with third party advertisers for purposes after getting advertisements on
      non-Global Study websites, applications, and services. In addition, we may
      allow third parties to collect personal information from our sites or
      services if those third parties are authorized service providers who have
      agreed to our contractual limitations as to their retention, use, and
      disclosure of such personal information, or if you use our sites or
      services to interact with third parties or direct us to disclose your
      personal information to third parties.
    </SectionBody>
    <SectionBody>
      Subject to certain limitations, the CCPA provides California consumers the
      right to request to know more details about the categories or specific
      pieces of personal information we collect (including how we use, disclose,
      or may sell this information), to delete their personal information, to
      opt out of any“ sales”, to know and opt out of sharing of personal
      information for delivering advertisements on non-Global Study websites,
      and to not be discriminated against for exercising these rights.
    </SectionBody>
    <SectionBody>
      California consumers may make a request pursuant to their rights under the
      CCPA by contacting us at{' '}
      <Link href="mailto:privacy@global.study">privacy@global.study</Link>. We
      will verify your request using the information associated with your
      account, including email address. Government identification may be
      required. Consumers can also designate an authorized agent to exercise
      these rights on their behalf. Authorized agents must submit proof of
      authorization.
    </SectionBody>
    <SectionBody>
      If you would like to opt-out of sharing activity based on your cookie
      identifiers, turn on a Global Privacy Control in your web browser or
      browser extension. Please see the California Privacy Protection Agency’s
      website at{' '}
      <Link href="https://oag.ca.gov/privacy/ccpa">
        https://oag.ca.gov/privacy/ccpa
      </Link>{' '}
      for more information on valid Global Privacy Controls. If you would like
      to opt-out of sharing activity based on other identifiers (like email
      address or phone number), contact us in accordance with the “Contacting
      Global Study” section, below.
    </SectionBody>
    <SectionBody>
      For more information on Global Study’s role and obligations under the
      CCPA, please visit their website.
    </SectionBody>
  </>
);

const DataProtectionAuthority = () => (
  <>
    <SectionTitle id="data-protection-authority">
      14. Data Protection Authority
    </SectionTitle>
    <SectionBody>
      Subject to applicable law, you also have the right to (i) restrict Global
      Study’s use of Other Information that constitutes your Personal Data and
      (ii) lodge a complaint with your local data protection authority. If,
      however, you believe that we have not been able to assist with your
      complaint or concern, and you are located in the European Economic Area or
      the United Kingdom, you have the right to lodge a complaint with the
      competent supervisory authority. If you work or reside in a country that
      is a member of the European Union or that is in the EEA, you may find the
      contact details for your appropriate data protection authority on the
      following website. If you are a resident of the United Kingdom you may
      contact the UK supervisory authority, the Information Commissioner’s
      Office.
    </SectionBody>
  </>
);

const ContactingGlobalStudy = () => (
  <>
    <SectionTitle id="contacting-global-study">
      15. Contacting Global Study
    </SectionTitle>
    <SectionBody>
      Please also feel free to contact Global Study if you have any questions
      about this Privacy Policy or Global Study’s practices, or if you are
      seeking to exercise any of your statutory rights. Global Study will
      respond within a reasonable time frame. You may contact us at
      <Link href="mailto:privacy@global.study">privacy@global.study</Link>.
    </SectionBody>
  </>
);
