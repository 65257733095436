export type Timezone = {
  code: string;
  timezones: Array<string>;
};

export type CountryTimezone = (typeof TimezonesByCountryCode)[number]['code'];

export type TimezoneName =
  (typeof TimezonesByCountryCode)[number]['timezones'][number];

const TimezonesByCountryCode = [
  //     {
  //         code: 'AF',
  //         timezones: [
  //             'Asia/Kabul',
  //         ],
  //     },
  //     {
  //         code: 'AX',
  //         timezones: [
  //             'Europe/Mariehamn',
  //         ],
  //     },
  //     {
  //         code: 'AL',
  //         timezones: [
  //             'Europe/Tirane',
  //         ],
  //     },
  {
    code: 'DZ',
    timezones: ['Africa/Algiers'],
  },
  //     {
  //         code: 'AS',
  //         timezones: [
  //             'Pacific/Pago_Pago',
  //         ],
  //     },
  //     {
  //         code: 'AD',
  //         timezones: [
  //             'Europe/Andorra',
  //         ],
  //     },
  //     {
  //         code: 'AO',
  //         timezones: [
  //             'Africa/Luanda',
  //         ],
  //     },
  //     {
  //         code: 'AI',
  //         timezones: [
  //             'America/Anguilla',
  //         ],
  //     },
  //     {
  //         code: 'AG',
  //         timezones: [
  //             'America/Antigua',
  //         ],
  //     },
  {
    code: 'AR',
    timezones: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia',
    ],
  },
  {
    code: 'AM',
    timezones: ['Asia/Yerevan'],
  },
  //     {
  //         code: 'AW',
  //         timezones: [
  //             'America/Aruba',
  //         ],
  //     },
  {
    code: 'AU',
    timezones: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla',
    ],
  },
  {
    code: 'AT',
    timezones: ['Europe/Vienna'],
  },
  {
    code: 'AZ',
    timezones: ['Asia/Baku'],
  },
  //     {
  //         code: 'BS',
  //         timezones: [
  //             'America/Nassau',
  //         ],
  //     },
  {
    code: 'BH',
    timezones: ['Asia/Bahrain'],
  },
  {
    code: 'BD',
    timezones: ['Asia/Dhaka'],
  },
  //     {
  //         code: 'BB',
  //         timezones: [
  //             'America/Barbados',
  //         ],
  //     },
  //     {
  //         code: 'BY',
  //         timezones: [
  //             'Europe/Minsk',
  //         ],
  //     },
  {
    code: 'BE',
    timezones: ['Europe/Brussels'],
  },
  //     {
  //         code: 'BZ',
  //         timezones: [
  //             'America/Belize',
  //         ],
  //     },
  {
    code: 'BJ',
    timezones: ['Africa/Porto-Novo'],
  },
  //     {
  //         code: 'BM',
  //         timezones: [
  //             'Atlantic/Bermuda',
  //         ],
  //     },
  //     {
  //         code: 'BT',
  //         timezones: [
  //             'Asia/Thimphu',
  //         ],
  //     },
  {
    code: 'BO',
    timezones: ['America/La_Paz'],
  },
  //     {
  //         code: 'BA',
  //         timezones: [
  //             'Europe/Sarajevo',
  //         ],
  //     },
  //     {
  //         code: 'BW',
  //         timezones: [
  //             'Africa/Gaborone',
  //         ],
  //     },
  {
    code: 'BR',
    timezones: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco',
    ],
  },
  //     {
  //         code: 'IO',
  //         timezones: [
  //             'Indian/Chagos',
  //         ],
  //     },
  //     {
  //         code: 'BN',
  //         timezones: [
  //             'Asia/Brunei',
  //         ],
  //     },
  {
    code: 'BG',
    timezones: ['Europe/Sofia'],
  },
  //     {
  //         code: 'BF',
  //         timezones: [
  //             'Africa/Ouagadougou',
  //         ],
  //     },
  //     {
  //         code: 'BI',
  //         timezones: [
  //             'Africa/Bujumbura',
  //         ],
  //     },
  //     {
  //         code: 'KH',
  //         timezones: [
  //             'Asia/Phnom_Penh',
  //         ],
  //     },
  {
    code: 'CM',
    timezones: ['Africa/Douala'],
  },
  {
    code: 'CA',
    timezones: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Blanc-Sablon',
      'America/Toronto',
      'America/Iqaluit',
      'America/Atikokan',
      'America/Winnipeg',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Creston',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Whitehorse',
      'America/Dawson',
      'America/Vancouver',
    ],
  },
  //     {
  //         code: 'CV',
  //         timezones: [
  //             'Atlantic/Cape_Verde',
  //         ],
  //     },
  //     {
  //         code: 'KY',
  //         timezones: [
  //             'America/Cayman',
  //         ],
  //     },
  //     {
  //         code: 'CF',
  //         timezones: [
  //             'Africa/Bangui',
  //         ],
  //     },
  {
    code: 'TD',
    timezones: ['Africa/Ndjamena'],
  },
  {
    code: 'CL',
    timezones: ['America/Santiago', 'America/Punta_Arenas', 'Pacific/Easter'],
  },
  {
    code: 'CN',
    timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
  },
  //     {
  //         code: 'CX',
  //         timezones: [
  //             'Indian/Christmas',
  //         ],
  //     },
  //     {
  //         code: 'CC',
  //         timezones: [
  //             'Indian/Cocos',
  //         ],
  //     },
  {
    code: 'CO',
    timezones: ['America/Bogota'],
  },
  //     {
  //         code: 'KM',
  //         timezones: [
  //             'Indian/Comoro',
  //         ],
  //     },
  //     {
  //         code: 'CG',
  //         timezones: [
  //             'Africa/Brazzaville',
  //         ],
  //     },
  //     {
  //         code: 'CK',
  //         timezones: [
  //             'Pacific/Rarotonga',
  //         ],
  //     },
  {
    code: 'CR',
    timezones: ['America/Costa_Rica'],
  },
  //     {
  //         code: 'CI',
  //         timezones: [
  //             'Africa/Abidjan',
  //         ],
  //     },
  //     {
  //         code: 'HR',
  //         timezones: [
  //             'Europe/Zagreb',
  //         ],
  //     },
  {
    code: 'CU',
    timezones: ['America/Havana'],
  },
  {
    code: 'CZ',
    timezones: ['Europe/Prague'],
  },
  {
    code: 'CD',
    timezones: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
  },
  {
    code: 'DK',
    timezones: ['Europe/Copenhagen'],
  },
  //     {
  //         code: 'DJ',
  //         timezones: [
  //             'Africa/Djibouti',
  //         ],
  //     },
  //     {
  //         code: 'DM',
  //         timezones: [
  //             'America/Dominica',
  //         ],
  //     },
  {
    code: 'DO',
    timezones: ['America/Santo_Domingo'],
  },
  {
    code: 'EC',
    timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
  },
  {
    code: 'EG',
    timezones: ['Africa/Cairo'],
  },
  //     {
  //         code: 'SV',
  //         timezones: [
  //             'America/El_Salvador',
  //         ],
  //     },
  //     {
  //         code: 'GQ',
  //         timezones: [
  //             'Africa/Malabo',
  //         ],
  //     },
  //     {
  //         code: 'ER',
  //         timezones: [
  //             'Africa/Asmara',
  //         ],
  //     },
  {
    code: 'EE',
    timezones: ['Europe/Tallinn'],
  },
  //     {
  //         code: 'SZ',
  //         timezones: [
  //             'Africa/Mbabane',
  //         ],
  //     },
  //     {
  //         code: 'ET',
  //         timezones: [
  //             'Africa/Addis_Ababa',
  //         ],
  //     },
  //     {
  //         code: 'FK',
  //         timezones: [
  //             'Atlantic/Stanley',
  //         ],
  //     },
  //     {
  //         code: 'FO',
  //         timezones: [
  //             'Atlantic/Faroe',
  //         ],
  //     },
  //     {
  //         code: 'FJ',
  //         timezones: [
  //             'Pacific/Fiji',
  //         ],
  //     },
  {
    code: 'FI',
    timezones: ['Europe/Helsinki'],
  },
  {
    code: 'FR',
    timezones: ['Europe/Paris'],
  },
  //     {
  //         code: 'GF',
  //         timezones: [
  //             'America/Cayenne',
  //         ],
  //     },
  //     {
  //         code: 'PF',
  //         timezones: [
  //             'Pacific/Tahiti',
  //             'Pacific/Marquesas',
  //             'Pacific/Gambier',
  //         ],
  //     },
  {
    code: 'GA',
    timezones: ['Africa/Libreville'],
  },
  {
    code: 'GE',
    timezones: ['Asia/Tbilisi'],
  },
  //     {
  //         code: 'GM',
  //         timezones: [
  //             'Africa/Banjul',
  //         ],
  //     },
  {
    code: 'DE',
    timezones: ['Europe/Berlin', 'Europe/Busingen'],
  },
  //     {
  //         code: 'GH',
  //         timezones: [
  //             'Africa/Accra',
  //         ],
  //     },
  //     {
  //         code: 'GI',
  //         timezones: [
  //             'Europe/Gibraltar',
  //         ],
  //     },
  {
    code: 'GR',
    timezones: ['Europe/Athens'],
  },
  //     {
  //         code: 'GL',
  //         timezones: [
  //             'America/Nuuk',
  //             'America/Danmarkshavn',
  //             'America/Scoresbysund',
  //             'America/Thule',
  //         ],
  //     },
  //     {
  //         code: 'GD',
  //         timezones: [
  //             'America/Grenada',
  //         ],
  //     },
  //     {
  //         code: 'GP',
  //         timezones: [
  //             'America/Guadeloupe',
  //         ],
  //     },
  //     {
  //         code: 'GU',
  //         timezones: [
  //             'Pacific/Guam',
  //         ],
  //     },
  {
    code: 'GT',
    timezones: ['America/Guatemala'],
  },
  //     {
  //         code: 'GG',
  //         timezones: [
  //             'Europe/Guernsey',
  //         ],
  //     },
  {
    code: 'GN',
    timezones: ['Africa/Conakry'],
  },
  //     {
  //         code: 'GW',
  //         timezones: [
  //             'Africa/Bissau',
  //         ],
  //     },
  //     {
  //         code: 'GY',
  //         timezones: [
  //             'America/Guyana',
  //         ],
  //     },
  //     {
  //         code: 'HT',
  //         timezones: [
  //             'America/Port-au-Prince',
  //         ],
  //     },
  {
    code: 'HN',
    timezones: ['America/Tegucigalpa'],
  },
  //     {
  //         code: 'HK',
  //         timezones: [
  //             'Asia/Hong_Kong',
  //         ],
  //     },
  {
    code: 'HU',
    timezones: ['Europe/Budapest'],
  },
  //     {
  //         code: 'IS',
  //         timezones: [
  //             'Atlantic/Reykjavik',
  //         ],
  //     },
  {
    code: 'IN',
    timezones: ['Asia/Kolkata'],
  },
  {
    code: 'ID',
    timezones: [
      'Asia/Jakarta',
      'Asia/Pontianak',
      'Asia/Makassar',
      'Asia/Jayapura',
    ],
  },
  {
    code: 'IR',
    timezones: ['Asia/Tehran'],
  },
  //     {
  //         code: 'IQ',
  //         timezones: [
  //             'Asia/Baghdad',
  //         ],
  //     },
  {
    code: 'IE',
    timezones: ['Europe/Dublin'],
  },
  //     {
  //         code: 'IM',
  //         timezones: [
  //             'Europe/Isle_of_Man',
  //         ],
  //     },
  {
    code: 'IL',
    timezones: ['Asia/Jerusalem'],
  },
  {
    code: 'IT',
    timezones: ['Europe/Rome'],
  },
  //     {
  //         code: 'JM',
  //         timezones: [
  //             'America/Jamaica',
  //         ],
  //     },
  {
    code: 'JP',
    timezones: ['Asia/Tokyo'],
  },
  //     {
  //         code: 'JE',
  //         timezones: [
  //             'Europe/Jersey',
  //         ],
  //     },
  //     {
  //         code: 'JO',
  //         timezones: [
  //             'Asia/Amman',
  //         ],
  //     },
  //     {
  //         code: 'KZ',
  //         timezones: [
  //             'Asia/Almaty',
  //             'Asia/Qyzylorda',
  //             'Asia/Qostanay',
  //             'Asia/Aqtobe',
  //             'Asia/Aqtau',
  //             'Asia/Atyrau',
  //             'Asia/Oral',
  //         ],
  //     },
  {
    code: 'KE',
    timezones: ['Africa/Nairobi'],
  },
  //     {
  //         code: 'KI',
  //         timezones: [
  //             'Pacific/Tarawa',
  //             'Pacific/Kanton',
  //             'Pacific/Kiritimati',
  //         ],
  //     },
  //     {
  //         code: 'KP',
  //         timezones: [
  //             'Asia/Pyongyang',
  //         ],
  //     },
  {
    code: 'KR',
    timezones: ['Asia/Seoul'],
  },
  {
    code: 'KW',
    timezones: ['Asia/Kuwait'],
  },
  {
    code: 'KG',
    timezones: ['Asia/Bishkek'],
  },
  //     {
  //         code: 'LA',
  //         timezones: [
  //             'Asia/Vientiane',
  //         ],
  //     },
  {
    code: 'LV',
    timezones: ['Europe/Riga'],
  },
  {
    code: 'LB',
    timezones: ['Asia/Beirut'],
  },
  //     {
  //         code: 'LS',
  //         timezones: [
  //             'Africa/Maseru',
  //         ],
  //     },
  //     {
  //         code: 'LR',
  //         timezones: [
  //             'Africa/Monrovia',
  //         ],
  //     },
  //     {
  //         code: 'LY',
  //         timezones: [
  //             'Africa/Tripoli',
  //         ],
  //     },
  //     {
  //         code: 'LI',
  //         timezones: [
  //             'Europe/Vaduz',
  //         ],
  //     },
  {
    code: 'LT',
    timezones: ['Europe/Vilnius'],
  },
  {
    code: 'LU',
    timezones: ['Europe/Luxembourg'],
  },
  //     {
  //         code: 'MO',
  //         timezones: [
  //             'Asia/Macau',
  //         ],
  //     },
  {
    code: 'MK',
    timezones: ['Europe/Skopje'],
  },
  {
    code: 'MG',
    timezones: ['Indian/Antananarivo'],
  },
  //     {
  //         code: 'MW',
  //         timezones: [
  //             'Africa/Blantyre',
  //         ],
  //     },
  //     {
  //         code: 'MY',
  //         timezones: [
  //             'Asia/Kuala_Lumpur',
  //             'Asia/Kuching',
  //         ],
  //     },
  //     {
  //         code: 'MV',
  //         timezones: [
  //             'Indian/Maldives',
  //         ],
  //     },
  {
    code: 'ML',
    timezones: ['Africa/Bamako'],
  },
  //     {
  //         code: 'MT',
  //         timezones: [
  //             'Europe/Malta',
  //         ],
  //     },
  //     {
  //         code: 'MH',
  //         timezones: [
  //             'Pacific/Majuro',
  //             'Pacific/Kwajalein',
  //         ],
  //     },
  //     {
  //         code: 'MQ',
  //         timezones: [
  //             'America/Martinique',
  //         ],
  //     },
  //     {
  //         code: 'MR',
  //         timezones: [
  //             'Africa/Nouakchott',
  //         ],
  //     },
  //     {
  //         code: 'MU',
  //         timezones: [
  //             'Indian/Mauritius',
  //         ],
  //     },
  //     {
  //         code: 'YT',
  //         timezones: [
  //             'Indian/Mayotte',
  //         ],
  //     },
  {
    code: 'MX',
    timezones: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Chihuahua',
      'America/Ciudad_Juarez',
      'America/Ojinaga',
      'America/Mazatlan',
      'America/Bahia_Banderas',
      'America/Hermosillo',
      'America/Tijuana',
    ],
  },
  //     {
  //         code: 'FM',
  //         timezones: [
  //             'Pacific/Chuuk',
  //             'Pacific/Pohnpei',
  //             'Pacific/Kosrae',
  //         ],
  //     },
  //     {
  //         code: 'MD',
  //         timezones: [
  //             'Europe/Chisinau',
  //         ],
  //     },
  //     {
  //         code: 'MC',
  //         timezones: [
  //             'Europe/Monaco',
  //         ],
  //     },
  //     {
  //         code: 'MN',
  //         timezones: [
  //             'Asia/Ulaanbaatar',
  //             'Asia/Hovd',
  //             'Asia/Choibalsan',
  //         ],
  //     },
  //     {
  //         code: 'ME',
  //         timezones: [
  //             'Europe/Podgorica',
  //         ],
  //     },
  //     {
  //         code: 'MS',
  //         timezones: [
  //             'America/Montserrat',
  //         ],
  //     },
  {
    code: 'MA',
    timezones: ['Africa/Casablanca'],
  },
  //     {
  //         code: 'MZ',
  //         timezones: [
  //             'Africa/Maputo',
  //         ],
  //     },
  //     {
  //         code: 'MM',
  //         timezones: [
  //             'Asia/Yangon',
  //         ],
  //     },
  //     {
  //         code: 'NA',
  //         timezones: [
  //             'Africa/Windhoek',
  //         ],
  //     },
  //     {
  //         code: 'NR',
  //         timezones: [
  //             'Pacific/Nauru',
  //         ],
  //     },
  {
    code: 'NP',
    timezones: ['Asia/Kathmandu'],
  },
  {
    code: 'NL',
    timezones: ['Europe/Amsterdam'],
  },
  //     {
  //         code: 'NC',
  //         timezones: [
  //             'Pacific/Noumea',
  //         ],
  //     },
  //     {
  //         code: 'NZ',
  //         timezones: [
  //             'Pacific/Auckland',
  //             'Pacific/Chatham',
  //         ],
  //     },
  {
    code: 'NI',
    timezones: ['America/Managua'],
  },
  //     {
  //         code: 'NE',
  //         timezones: [
  //             'Africa/Niamey',
  //         ],
  //     },
  {
    code: 'NG',
    timezones: ['Africa/Lagos'],
  },
  //     {
  //         code: 'NU',
  //         timezones: [
  //             'Pacific/Niue',
  //         ],
  //     },
  //     {
  //         code: 'NF',
  //         timezones: [
  //             'Pacific/Norfolk',
  //         ],
  //     },
  //     {
  //         code: 'MP',
  //         timezones: [
  //             'Pacific/Saipan',
  //         ],
  //     },
  {
    code: 'NO',
    timezones: ['Europe/Oslo'],
  },
  {
    code: 'OM',
    timezones: ['Asia/Muscat'],
  },
  {
    code: 'PK',
    timezones: ['Asia/Karachi'],
  },
  //     {
  //         code: 'PW',
  //         timezones: [
  //             'Pacific/Palau',
  //         ],
  //     },
  //     {
  //         code: 'PS',
  //         timezones: [
  //             'Asia/Gaza',
  //             'Asia/Hebron',
  //         ],
  //     },
  {
    code: 'PA',
    timezones: ['America/Panama'],
  },
  //     {
  //         code: 'PG',
  //         timezones: [
  //             'Pacific/Port_Moresby',
  //             'Pacific/Bougainville',
  //         ],
  //     },
  {
    code: 'PY',
    timezones: ['America/Asuncion'],
  },
  {
    code: 'PE',
    timezones: ['America/Lima'],
  },
  {
    code: 'PH',
    timezones: ['Asia/Manila'],
  },
  {
    code: 'PL',
    timezones: ['Europe/Warsaw'],
  },
  {
    code: 'PT',
    timezones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  },
  {
    code: 'PR',
    timezones: ['America/Puerto_Rico'],
  },
  //     {
  //         code: 'QA',
  //         timezones: [
  //             'Asia/Qatar',
  //         ],
  //     },
  //     {
  //         code: 'RE',
  //         timezones: [
  //             'Indian/Reunion',
  //         ],
  //     },
  {
    code: 'RO',
    timezones: ['Europe/Bucharest'],
  },
  {
    code: 'RU',
    timezones: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Kirov',
      'Europe/Volgograd',
      'Europe/Astrakhan',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Europe/Samara',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Barnaul',
      'Asia/Tomsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Chita',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Kamchatka',
      'Asia/Anadyr',
    ],
  },
  //     {
  //         code: 'RW',
  //         timezones: [
  //             'Africa/Kigali',
  //         ],
  //     },
  //     {
  //         code: 'BL',
  //         timezones: [
  //             'America/St_Barthelemy',
  //         ],
  //     },
  //     {
  //         code: 'SH',
  //         timezones: [
  //             'Atlantic/St_Helena',
  //         ],
  //     },
  //     {
  //         code: 'KN',
  //         timezones: [
  //             'America/St_Kitts',
  //         ],
  //     },
  //     {
  //         code: 'LC',
  //         timezones: [
  //             'America/St_Lucia',
  //         ],
  //     },
  //     {
  //         code: 'MF',
  //         timezones: [
  //             'America/Marigot',
  //         ],
  //     },
  //     {
  //         code: 'PM',
  //         timezones: [
  //             'America/Miquelon',
  //         ],
  //     },
  //     {
  //         code: 'VC',
  //         timezones: [
  //             'America/St_Vincent',
  //         ],
  //     },
  //     {
  //         code: 'WS',
  //         timezones: [
  //             'Pacific/Apia',
  //         ],
  //     },
  //     {
  //         code: 'SM',
  //         timezones: [
  //             'Europe/San_Marino',
  //         ],
  //     },
  //     {
  //         code: 'ST',
  //         timezones: [
  //             'Africa/Sao_Tome',
  //         ],
  //     },
  {
    code: 'SA',
    timezones: ['Asia/Riyadh'],
  },
  {
    code: 'SN',
    timezones: ['Africa/Dakar'],
  },
  //     {
  //         code: 'RS',
  //         timezones: [
  //             'Europe/Belgrade',
  //         ],
  //     },
  //     {
  //         code: 'SC',
  //         timezones: [
  //             'Indian/Mahe',
  //         ],
  //     },
  {
    code: 'SL',
    timezones: ['Africa/Freetown'],
  },
  //     {
  //         code: 'SG',
  //         timezones: [
  //             'Asia/Singapore',
  //         ],
  //     },
  //     {
  //         code: 'SK',
  //         timezones: [
  //             'Europe/Bratislava',
  //         ],
  //     },
  //     {
  //         code: 'SI',
  //         timezones: [
  //             'Europe/Ljubljana',
  //         ],
  //     },
  //     {
  //         code: 'SB',
  //         timezones: [
  //             'Pacific/Guadalcanal',
  //         ],
  //     },
  //     {
  //         code: 'SO',
  //         timezones: [
  //             'Africa/Mogadishu',
  //         ],
  //     },
  {
    code: 'ZA',
    timezones: ['Africa/Johannesburg'],
  },
  //     {
  //         code: 'GS',
  //         timezones: [
  //             'Atlantic/South_Georgia',
  //         ],
  //     },
  //     {
  //         code: 'SS',
  //         timezones: [
  //             'Africa/Juba',
  //         ],
  //     },
  {
    code: 'ES',
    timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  },
  {
    code: 'LK',
    timezones: ['Asia/Colombo'],
  },
  //     {
  //         code: 'SD',
  //         timezones: [
  //             'Africa/Khartoum',
  //         ],
  //     },
  //     {
  //         code: 'SR',
  //         timezones: [
  //             'America/Paramaribo',
  //         ],
  //     },
  //     {
  //         code: 'SJ',
  //         timezones: [
  //             'Arctic/Longyearbyen',
  //         ],
  //     },
  {
    code: 'SE',
    timezones: ['Europe/Stockholm'],
  },
  {
    code: 'CH',
    timezones: ['Europe/Zurich'],
  },
  //     {
  //         code: 'SY',
  //         timezones: [
  //             'Asia/Damascus',
  //         ],
  //     },
  //     {
  //         code: 'TW',
  //         timezones: [
  //             'Asia/Taipei',
  //         ],
  //     },
  //     {
  //         code: 'TJ',
  //         timezones: [
  //             'Asia/Dushanbe',
  //         ],
  //     },
  //     {
  //         code: 'TZ',
  //         timezones: [
  //             'Africa/Dar_es_Salaam',
  //         ],
  //     },
  {
    code: 'TH',
    timezones: ['Asia/Bangkok'],
  },
  //     {
  //         code: 'TG',
  //         timezones: [
  //             'Africa/Lome',
  //         ],
  //     },
  //     {
  //         code: 'TK',
  //         timezones: [
  //             'Pacific/Fakaofo',
  //         ],
  //     },
  //     {
  //         code: 'TO',
  //         timezones: [
  //             'Pacific/Tongatapu',
  //         ],
  //     },
  //     {
  //         code: 'TT',
  //         timezones: [
  //             'America/Port_of_Spain',
  //         ],
  //     },
  {
    code: 'TN',
    timezones: ['Africa/Tunis'],
  },
  {
    code: 'TR',
    timezones: ['Europe/Istanbul'],
  },
  //     {
  //         code: 'TM',
  //         timezones: [
  //             'Asia/Ashgabat',
  //         ],
  //     },
  //     {
  //         code: 'TC',
  //         timezones: [
  //             'America/Grand_Turk',
  //         ],
  //     },
  //     {
  //         code: 'TV',
  //         timezones: [
  //             'Pacific/Funafuti',
  //         ],
  //     },
  {
    code: 'UG',
    timezones: ['Africa/Kampala'],
  },
  {
    code: 'UA',
    timezones: ['Europe/Simferopol', 'Europe/Kyiv'],
  },
  {
    code: 'AE',
    timezones: ['Asia/Dubai'],
  },
  {
    code: 'GB',
    timezones: ['Europe/London'],
  },
  {
    code: 'US',
    timezones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu',
    ],
  },
  {
    code: 'UY',
    timezones: ['America/Montevideo'],
  },
  //     {
  //         code: 'UZ',
  //         timezones: [
  //             'Asia/Samarkand',
  //             'Asia/Tashkent',
  //         ],
  //     },
  //     {
  //         code: 'VU',
  //         timezones: [
  //             'Pacific/Efate',
  //         ],
  //     },
  {
    code: 'VE',
    timezones: ['America/Caracas'],
  },
  {
    code: 'VN',
    timezones: ['Asia/Ho_Chi_Minh'],
  },
  //     {
  //         code: 'VG',
  //         timezones: [
  //             'America/Tortola',
  //         ],
  //     },
  //     {
  //         code: 'VI',
  //         timezones: [
  //             'America/St_Thomas',
  //         ],
  //     },
  //     {
  //         code: 'WF',
  //         timezones: [
  //             'Pacific/Wallis',
  //         ],
  //     },
  {
    code: 'YE',
    timezones: ['Asia/Aden'],
  },
  //     {
  //         code: 'ZM',
  //         timezones: [
  //             'Africa/Lusaka',
  //         ],
  //     },
  //     {
  //         code: 'ZW',
  //         timezones: [
  //             'Africa/Harare',
  //         ],
  //     },
] as const;

export default TimezonesByCountryCode;
