import React from 'react';

const Footer: React.FC = () => (
  <footer className="bg-dark-grey-05 z-10 px-12 py-[3rem]">
    {/* Copyrights and policies */}
    <div className="border-t border-white pt-8 mt-4 text-white font-sora text-base font-normal flex flex-col md:flex-row md:items-center md:justify-center">
      <p className="mb-8 text-base p-0 md:mb-0">
        {new Date().getFullYear()} Global Study. All rights reserved.
      </p>
      <span className="mx-4" />
      <a href="/privacy-policy">Privacy policy</a>
      <span className="mx-4" />
      <a href="/terms">Terms of Service</a>
      <span className="mx-4" />
      <a href="https://www.globalstudy.ai" target="_blank" rel="noreferrer">
        www.globalstudy.ai
      </a>
    </div>
  </footer>
);

export default Footer;
