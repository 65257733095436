import React, { memo, useCallback, useMemo } from 'react';
import type { SectionListRenderItem } from 'react-native';

import { SectionList } from '@advisor/design/components';
import useMilestoneComments from './useMilestoneComments';
import CommentsListLoader from './CommentsListLoader';
import { MilestoneCommentsListProps } from './types';
import CommentListItem from './CommentsListItem';
import { keyExtractor } from './utils';

const MilestoneCommentsList: React.FC<MilestoneCommentsListProps> = (props) => {
  const { milestoneId, ListHeaderComponent } = props;

  const { comments, hasMore, loadMore, isLoading } =
    useMilestoneComments(milestoneId);

  type ItemType = (typeof comments)[number];

  const packedComments = useMemo(() => [{ data: comments }], [comments]);

  const renderItem: SectionListRenderItem<ItemType> = useCallback(
    ({ item }) => <CommentListItem comment={item.node} />,
    [],
  );

  const footerComponent = useCallback(() => {
    if (isLoading || hasMore) {
      return <CommentsListLoader />;
    }

    return null;
  }, [hasMore, isLoading]);

  return (
    // We use SectionList here because it is implemented on the web version
    // and it has support for pagination.
    <SectionList
      className="flex-1"
      contentContainerClassName="pb-10"
      sections={packedComments}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={footerComponent}
      onEndReached={loadMore}
      onEndReachedThreshold={0.5}
    />
  );
};

export default memo(MilestoneCommentsList);
