import { useCallback, useMemo, useState } from 'react';

const CommentCharacterLimit = 150;
const CharacterLimitMargin = 10;

const useCommentText = (text: string) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isLargeText = useMemo(() => {
    const isLong = text.length > CommentCharacterLimit + CharacterLimitMargin;
    const hasNewLineOrTab = /\r|\n|\t/.test(text);
    return isLong || hasNewLineOrTab;
  }, [text]);

  const visibleText = useMemo(() => {
    if (isExpanded || !isLargeText) {
      return text;
    }

    return `${text.replace(/\s+/g, ' ').slice(0, CommentCharacterLimit)}...`;
  }, [isExpanded, isLargeText, text]);

  const onShowMore = useCallback(() => setIsExpanded(true), []);
  const onShowLess = useCallback(() => setIsExpanded(false), []);

  const isTextExpanded = isLargeText && isExpanded;
  const isShowMoreVisible = isLargeText && !isExpanded;

  return {
    visibleText,
    isShowLessVisible: isTextExpanded,
    isShowMoreVisible,
    onShowLess,
    onShowMore,
  };
};

export default useCommentText;
