import { useApolloClient } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import ComplexError from '@advisor/utils/ComplexError';
import { rejectAfter } from '@advisor/utils/promiseTimeouts';
import { usePushNotifications } from '../PushNotifications';
import jotaiStore from '../jotaiStore';
import { useAuth } from './context';

/**
 * @param redirectTo Used only on web
 */
function useLogout(redirectTo?: string) {
  const { logout } = useAuth();
  const client = useApolloClient();

  const { unregisterFromNotifications } = usePushNotifications();

  return useEvent(async () => {
    try {
      await Promise.race([
        unregisterFromNotifications(),
        // or timeout after 5 seconds
        rejectAfter(5000),
      ]);
    } catch (e) {
      // Reporting the error, and logging out anyway
      Sentry.captureException(
        new ComplexError('Failed to unregister from notifications', e),
      );
    }

    try {
      logout(redirectTo);

      // Web sometimes does not reach some of these calls, but that is okay, since the
      // redirect will cause both caches to reset.
      await client.clearStore();
      jotaiStore.reset();
    } catch (e) {
      Sentry.captureException(new ComplexError('Failed to properly logout', e));
    }
  });
}

export default useLogout;
