import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { EditedMessageDocument } from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { replaceMessage } from './cacheUtils';
import { SubscriptionParams } from './types';

function useEditedMessageSubscription({ subscribeToMore }: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;

  useMoreSubscription(
    EditedMessageDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      if (!subscriptionData.data.editedMessage) {
        return prev;
      }

      const { editedMessage } = subscriptionData.data;

      return replaceMessage(prev, editedMessage);
    },
    { chatRoomId },
    !!chatRoomId,
  );
}

export default useEditedMessageSubscription;
