import { LoginType } from '@advisor/api/auth';

interface VerifyCallbackResults {
  success: boolean;
}

export type VerifyCallback = (otp: string) => Promise<VerifyCallbackResults>;

export enum VerificationFormState {
  Init = 'Init',
  Failed = 'Failed',
  IncorrectCharacters = 'IncorrectCharacters',
  IncorrectLength = 'IncorrectLength',
  Loading = 'Loading',
  EnableSubmit = 'EnableSubmit',
}

export interface CountDownTimerProps {
  onPress: () => void;
  verificationType: LoginType;
  onChangeVerificationType?: () => void;
}

export interface VerificationInputProps {
  code: string;
  invalid?: boolean;
  onCodeChange: (code: string) => void;
}

export interface VerificationFormProps {
  verificationType: LoginType;
  identity: string;
  onGoBack: () => void;
  onVerify: VerifyCallback;
  onChangeVerificationType?: () => void;
  onResendOTP: () => Promise<void>;
}
