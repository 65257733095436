import cs from 'classnames';

const AppStoreUrl =
  'https://apps.apple.com/us/app/student-advisor/id1615216342';

type Props = {
  className?: string;
};

const AppStoreLink = ({ className }: Props) => {
  return (
    <a
      href={AppStoreUrl}
      className={cs(
        'inline-block border transition-colors duration-75',
        className,
      )}
    >
      <img
        src="/images/homepage/get-on-apple-store.svg"
        className="h-full max-h-[auto]  object-contain"
        alt="Download on the Apple Store"
      />
    </a>
  );
};

export default AppStoreLink;
