import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { NewMemberSystemMessageData } from '@advisor/api/generated/graphql';
import { UserName, SimpleMessage } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';

const NewMember: React.FC<SystemMessageProps> = ({ message }) => {
  const { t } = useTranslation('system-message');
  const { newMember } = message.data as NewMemberSystemMessageData;

  if (!newMember) {
    return null;
  }

  return (
    <Trans
      t={t}
      i18nKey="user-has-joined-the-conversation"
      parent={SimpleMessage}
    >
      <UserName user={newMember} /> has joined the conversation.
    </Trans>
  );
};

export default memo(NewMember);
