import {
  ChatRoomStatusInfoFragment,
  ChatRoomStatusInput,
} from '@advisor/api/generated/graphql';

export enum Tab {
  Filter,
  Confirm,
  NewStatus,
  StatusList,
}

export type EditStatusesState = {
  activeIndex: number;
  statuses: ChatRoomStatusInfoFragment[];
} | null;

export type DeleteStatusAction = { type: 'deleteStatus' };

export type UpdateStatusAction = {
  type: 'updateStatus';
  index: number;
  update: Partial<ChatRoomStatusInfoFragment>;
};

export type UpdateActiveAction = {
  type: 'updateActive';
  index: number;
};

export type CreateStatusAction = {
  type: 'createStatus';
  input: ChatRoomStatusInput;
};

export type EditStatusesAction =
  | { type: 'save' }
  | { type: 'open' }
  | { type: 'close' }
  | { type: 'restore' }
  | DeleteStatusAction
  | UpdateStatusAction
  | UpdateActiveAction
  | CreateStatusAction;

export interface StatusModalProps {
  visible: boolean;
  onClose: () => void;
  statusFilter?: ChatRoomStatusInfoFragment | null;
  setStatusFilter?: (status: ChatRoomStatusInfoFragment) => void;
}

export interface TabProps {
  onClose: () => void;
  onGoBack: () => void;
}
