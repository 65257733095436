import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { useShowModal } from '@advisor/design/components/ActionModal';
import { UserMessageInfoFragment } from '@advisor/api/generated/graphql';
import { deleteMessageAtom, messagesBeingDeletedAtom } from '../messaging';

export default function useDeleteMessage(message: UserMessageInfoFragment) {
  const { t } = useTranslation('common');
  const showModal = useShowModal();

  const messagesBeingDeleted = useAtomValue(messagesBeingDeletedAtom);
  const deleteMessage = useSetAtom(deleteMessageAtom);

  return useEvent(async () => {
    if (messagesBeingDeleted.includes(message.id)) {
      // Already being deleted.
      return;
    }

    try {
      const confirmed = await showModal.confirm({
        variant: 'severe',
        title: t('are-you-sure-you-want-to-delete-this-message'),
        message: t('this-action-cannot-be-reversed'),
      });

      if (!confirmed) {
        return;
      }

      await deleteMessage(message);

      showToast({
        messageI18Key: 'selected-message-was-deleted',
        iconName: 'Delete',
        variant: 'rose',
      });
    } catch (error) {
      Sentry.captureException(error);

      showToast({
        messageI18Key: 'oops-something-went-wrong',
        iconName: 'Delete',
        variant: 'rose',
      });
    }
  });
}
