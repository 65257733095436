import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useLayoutEffect, useState } from 'react';

import {
  Dropdown,
  SpinnerIcon,
  SimpleDropdownItem,
  SizeAdaptingWrapper,
  SimpleDropdownTrigger,
} from '@advisor/design/components';
import { showToast } from '@advisor/design/components/Toast';
import { useEvent } from '@advisor/utils/hooks';
import { type RoomRecordingState } from '../../RecordingManager';
import usePermissions from '../../usePermissions';
import RecordingTimer from './RecordingTimer';
import { RecordButtonProps } from './types';

type CommonProps = {
  isDropdownOpen: boolean;
  recordingState: RoomRecordingState;
  readOnly: boolean;
};

function IdleContent(_: CommonProps) {
  const { t } = useTranslation('common');

  return <span className="uppercase text-left">{t('record')}</span>;
}

function nowAsDateString() {
  return new Date().toDateString();
}

function RecordingContent(props: CommonProps) {
  const { recordingState: status, isDropdownOpen, readOnly } = props;

  // Deferring and caching this value so that it shows properly
  // during the state transition.
  const [latestStartDate, setLatestStartDate] = useState(nowAsDateString);
  useLayoutEffect(() => {
    if (status.type === 'recording') {
      setLatestStartDate(status.startDate);
    }
  }, [status]);

  return (
    <span className="flex items-center">
      <RecordingTimer startDate={latestStartDate} />
      {!readOnly && (
        <SimpleDropdownTrigger variant="dark" open={isDropdownOpen} />
      )}
    </span>
  );
}

function RecordButton(props: RecordButtonProps) {
  const { variant, readOnly, recordingState, onToggle } = props;
  const isLoading =
    recordingState.type === 'starting' || recordingState.type === 'stopping';

  const { t } = useTranslation('common');
  const { hasAllPermissions } = usePermissions();

  const handleClick = useEvent((e: React.MouseEvent) => {
    if (readOnly || recordingState.type !== 'recording') {
      // Do not open dropdown.
      e.preventDefault();
    }

    // Do not toggle only when the button is meant to
    // open the dropdown.
    if (recordingState.type === 'recording') {
      return;
    }

    if (!hasAllPermissions) {
      showToast({
        messageI18Key:
          'you-have-to-give-camera-and-microphone-permissions-in-order-to-start-recording',
        variant: 'rose',
        iconName: 'Warning',
      });
      return;
    }

    onToggle?.();
  });

  return (
    <div className="flex flex-col relative">
      <Dropdown.Root
        upwards
        trigger={({ open }) => (
          <button
            type="button"
            onClick={handleClick}
            className={cs(
              'flex items-center px-4 h-11 gap-2 font-outfit font-bold text-base leading-5 border rounded-lg',
              'transition-[opacity,border-color] duration-200 bg-dark-grey-02/0',
              variant === 'light' ? 'text-dark-grey-03' : 'text-light-grey',
              isLoading || readOnly
                ? 'cursor-default'
                : 'hover:bg-dark-grey-025/10',
              (isLoading ||
                (readOnly && recordingState.type !== 'recording')) &&
                'opacity-70',
              recordingState.type === 'recording' &&
                (open ? 'border-dark-grey-02' : 'border-dark-grey-02/0'),
            )}
          >
            {isLoading ? (
              <div className="flex justify-center items-center w-2.5 h-2.5">
                <SpinnerIcon className="shrink-0 w-4 h-4" />
              </div>
            ) : (
              <div
                className={cs(
                  'grow-0 shrink-0 w-2.5 h-2.5 rounded-full',
                  recordingState.type === 'recording'
                    ? 'bg-negative animate-pop'
                    : 'bg-current',
                )}
              />
            )}
            <SizeAdaptingWrapper
              inner={
                recordingState.type === 'recording'
                  ? RecordingContent
                  : IdleContent
              }
              commonProps={{
                recordingState,
                isDropdownOpen: open,
                readOnly: !!readOnly,
              }}
            />
          </button>
        )}
      >
        <Dropdown.Items className="-top-20">
          <SimpleDropdownItem label={t('stop-recording')} onPress={onToggle} />
        </Dropdown.Items>
      </Dropdown.Root>
    </div>
  );
}

export default RecordButton;
