import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import calendar from 'dayjs/plugin/calendar';

import { MilestoneCommentInfoFragment } from '@advisor/api/generated/graphql';

dayjs.extend(calendar);

export const keyExtractor = (item: { node: MilestoneCommentInfoFragment }) =>
  item.node.createdAt;

export const formatCommentDate = (
  date: string,
  t: TFunction<'common'>,
): string => {
  return dayjs(date).calendar(null, {
    sameDay: `[${t('today')}]`,
    lastDay: `[${t('yesterday')}]`,
    lastWeek: `MMM DD`,
    sameElse: `MMM DD`,
  });
};
