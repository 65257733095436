import React from 'react';

import { Typography } from '@advisor/design/styles';
import { Layout, Skeleton } from '@advisor/design/components';

const CategoryListItemSkeleton: React.FC = () => (
  <Skeleton.Ping>
    <div className="flex flex-row border px-6 py-4 rounded border-grey">
      <div className="w-10 h-10 rounded-full bg-grey" />
      <Layout.Spacer.Horizontal size="tiny" />
      <div className="flex-auto">
        <Skeleton.Text {...Typography.Sora.subtitle} size={170} />
        <Skeleton.Text {...Typography.Outfit.bodySmall} size={100} />
      </div>
      <Layout.Spacer.Horizontal size={40} />
    </div>
  </Skeleton.Ping>
);

export default CategoryListItemSkeleton;
