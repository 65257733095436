import cs from 'classnames';
import React from 'react';

import { AudioWaveIndicatorProps } from './types';

const Ball: React.FC<{
  duration: number;
  isSpeaking?: boolean;
  phase: number;
}> = ({ duration, isSpeaking, phase }) => {
  return (
    <div
      className={cs(
        '-mt-0.5 transition-[height] ease-in-out animate-sine-y',
        isSpeaking ? 'h-1' : 'h-0',
      )}
      style={{
        transitionDuration: `${duration}ms`,
        animationDuration: `${duration}ms`,
        animationDelay: `${duration * phase - 1.5 * duration}ms`,
        transitionDelay: `${duration * phase}ms`,
      }}
    >
      <div className="w-1 h-1 bg-primary rounded-full" />
    </div>
  );
};

function AudioWaveIndicator({ isSpeaking }: AudioWaveIndicatorProps) {
  return (
    <span className="flex items-center gap-0.5 h-1">
      <Ball duration={300} isSpeaking={isSpeaking} phase={0.8} />
      <Ball duration={300} isSpeaking={isSpeaking} phase={0.4} />
      <Ball duration={300} isSpeaking={isSpeaking} phase={0} />
    </span>
  );
}

export default AudioWaveIndicator;
