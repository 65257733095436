import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '@advisor/design/components';
import {
  Form,
  Title,
  Subtitle,
  ConfirmButton,
  CancelButton,
} from '@advisor/design/components/LoginComponents';
import ConfirmDeleteInput from './ConfirmDeleteInput';
import { useConfirmDeleteForm } from './useConfirmDeleteForm';

const ConfirmDeleteForm: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const { isValid, isDeletingAccount, onSubmit, ...inputProps } =
    useConfirmDeleteForm();

  return (
    <Form className="flex-none" onSubmit={onSubmit}>
      <div>
        <Title className="!text-sm leading-4-5 text-center max-w-[300px] mx-auto font-sora">
          {t('common:are-you-sure-you-want-to-delete-your-account')}
        </Title>
        <Subtitle className="text-sm leading-4-5 text-center font-outfit mt-5">
          {t('onboarding:delete-account-permanently-erase-data')}
          <br />
          <span className="block mt-5">
            {t('onboarding:type-delete-to-confirm')}
          </span>
        </Subtitle>
        <Layout.Spacer.Vertical size="small01" />
        <ConfirmDeleteInput {...inputProps} />
        <ConfirmButton
          disabled={!isValid || isDeletingAccount}
          className="w-full !bg-negative disabled:!bg-negative-light"
        >
          {t('onboarding:delete-account')}
        </ConfirmButton>
        <CancelButton className="w-full" onPress={onClose}>
          {t('common:cancel')}
        </CancelButton>
      </div>
    </Form>
  );
};

export default ConfirmDeleteForm;
