import React from 'react';

import { Layout, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import useMicrobotList from '@advisor/microbots/hooks/useMicrobotList';
import MicrobotCard from './MicrobotCard';

const MicrobotGrid: React.FC = () => {
  const { microbots } = useMicrobotList();

  return (
    <div className="px-6 grid grid-cols-1 md:grid-cols-2 gap-6">
      {microbots.map((microbot) => (
        <MicrobotCard
          key={microbot.personality}
          personality={microbot.personality}
        />
      ))}
    </div>
  );
};

const Fallback: React.FC = () => (
  <div className="px-6 grid grid-cols-1 md:grid-cols-2 gap-6">
    <FallbackItem />
    <FallbackItem />
    <FallbackItem />
  </div>
);

const FallbackItem: React.FC = () => (
  <div className="rounded-lg p-6 bg-white">
    <div className="flex items-center justify-between">
      <div className="flex-1">
        <Skeleton.Text fontSize={38} lineHeight={38} size={Spacing.huge} />
      </div>
      <Skeleton.Avatar className="rounded-[1.25rem]" size={Spacing.large} />
    </div>
    <Layout.Spacer.Vertical size="tiny" />
    <div className="flex">
      <Skeleton.Text fontSize={52} lineHeight={52} />
      <Layout.Spacer.Horizontal size={Spacing.huge} />
    </div>
  </div>
);

export default suspenseHOC(MicrobotGrid, Fallback);
