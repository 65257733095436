import { createContext } from 'react';
import { noop } from 'lodash-es';

export interface MobileNavContext {
  onLeaveVideoScreen: () => void;
  onOpenVideoScreen: () => void;
}

export const MobileNavContext = createContext<MobileNavContext>({
  onLeaveVideoScreen: noop,
  onOpenVideoScreen: noop,
});
