import { ReactNode } from 'react';
import { Link, useInRouterContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Env from '@advisor/api/env';
import { Title } from '../atoms/DeleteAccountComponents';
import BasicLayout from './BasicLayout';

interface DeleteAccountLayoutProps {
  children: ReactNode;
  title: string;
}

const DeleteAccountLayout: React.FC<DeleteAccountLayoutProps> = ({
  children,
  title,
}) => {
  const { t } = useTranslation('common');

  // If this component is render as a Fallback we are not inside a Router context. We have to detect it and replace Link to avoid app error.
  const insideRouterContext = useInRouterContext();

  return (
    <BasicLayout>
      <div className="h-20 bg-[#0E0E0F] px-10 flex items-center">
        <img src={Env.web.logoWhite} alt={Env.organizationName} width={170} />
      </div>
      <div className="flex flex-col justify-between px-7 pt-6 pb-4 md:px-32 md:pt-24 md:pb-10 min-h-[140px] md:min-h-[294px] bg-[#191919]">
        {insideRouterContext ? (
          <Link
            to="/"
            className="w-fit text-[13.7px] leading-[16.38px] md:text-sm font-semibold font-sora md:leading-[16.76px] text-white"
          >
            {t('go-home')}
          </Link>
        ) : (
          <p className="w-fit text-[13.7px] leading-[16.38px] md:text-sm font-semibold font-sora md:leading-[16.76px] text-white">
            {t('go-home')}
          </p>
        )}
        <Title>{title}</Title>
      </div>
      <div className="px-7 pt-4 pb-6 md:px-32 md:pt-10 md:pb-24">
        {children}
      </div>
    </BasicLayout>
  );
};

export default DeleteAccountLayout;
