import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleMessage } from '../systemMessageComponents';

const OnboardingAwaitingApproval: React.FC = () => {
  const { t } = useTranslation('system-message');

  return (
    <SimpleMessage>
      {t('your-account-is-awaiting-approval-it-might-take-some-time')}
    </SimpleMessage>
  );
};

export default memo(OnboardingAwaitingApproval);
