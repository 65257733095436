import { Atom } from 'jotai';

import { derive } from '@advisor/utils/atoms';
import { ChatRoomWithMembersFragment } from '../generated/graphql';
import { myIdAtom } from '../me';

type PromiseOrValue<T> = Promise<T> | T;

function createMemberAtoms(
  chatRoomAtom: Atom<PromiseOrValue<ChatRoomWithMembersFragment | undefined>>,
) {
  const membersAtom = derive(
    [chatRoomAtom],
    (chatRoom) => chatRoom?.members ?? [],
  );

  // Relative to owner

  const memberOfOwnerAtom = derive([chatRoomAtom], (chatRoom) =>
    chatRoom?.members.find(({ member }) => member.id === chatRoom.ownerId),
  );

  const membersOtherThanOwnerAtom = derive([chatRoomAtom], (chatRoom) =>
    chatRoom?.members.filter(({ member }) => member.id !== chatRoom.ownerId),
  );

  // Relative to me

  const memberOfMeAtom = derive([myIdAtom, membersAtom], (myId, members) =>
    members.find(({ member }) => member.id === myId),
  );

  const membersOtherThanMeAtom = derive(
    [myIdAtom, membersAtom],
    (myId, members) => members.filter(({ member }) => member.id !== myId),
  );

  const hasLeadingAdvisorAtom = derive(
    [chatRoomAtom],
    (chatRoom) => !!chatRoom?.leadingAdvisorId,
  );

  return {
    membersAtom,

    memberOfOwnerAtom,
    membersOtherThanOwnerAtom,

    memberOfMeAtom,
    membersOtherThanMeAtom,

    hasLeadingAdvisorAtom,
  };
}

export default createMemberAtoms;
