/* eslint-disable import/prefer-default-export */

import Floating from '../Floating';

const { Left, Right } = Floating.HorizontalAlignment;

export function resolveDirection(
  isRTL: boolean,
  dir: 'natural' | 'reversed-natural',
) {
  if (!isRTL) {
    return dir === 'natural' ? Left : Right;
  }

  return dir === 'natural' ? Right : Left;
}
