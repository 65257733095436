import { RefObject, useEffect } from 'react';

/**
 * Fires callback whenever the size of referenced element changes.
 * Web only!
 *
 * @param callback
 * wrap it with useCallback to prevent creating ResizeObserver on every render.
 *
 * @example
 * useResizeObserver(
 *  ref,
 *  useCallback(({ contentRect }) => setHeight(contentRect.height), [])
 * )
 */
export default function useResizeObserver(
  ref: RefObject<Element>,
  callback: (entry: ResizeObserverEntry) => void,
) {
  useEffect(() => {
    if (!ref.current) {
      return () => null;
    }
    const observer = new ResizeObserver((entries) => {
      if (!entries) {
        return;
      }
      const [entry] = entries;
      callback(entry);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);
}
