import cs from 'classnames';
import { useParams } from 'react-router';
import { ScopeProvider } from 'bunshi/react';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { memo, PropsWithChildren, Suspense, useCallback } from 'react';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import { useSetAtomOrNull } from '@advisor/utils/hooks';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { PermissionGate, Scope } from '@advisor/api/scope';
import Advert, {
  AdRequirements,
  useAdvertForPlacement,
} from '@advisor/ui/components/Advert';
import {
  Icon,
  Layout,
  Skeleton,
  ErrorView,
  DeprecatedButton,
} from '@advisor/design/components';
import CommentInput from '@advisor/journey/components/CommentInput';
import MilestoneCommentsList from '@advisor/journey/components/MilestoneCommentsList';
import CompleteButton from '@advisor/journey/components/CompleteButton/CompleteButton';
import MilestoneStatus from '@advisor/journey/components/MilestoneStatus/MilestoneStatus';
import PendingReviewTooltip from '@advisor/journey/JourneyTutorial/PendingReviewTooltip';
import useToggleCompleteMilestone from '@advisor/journey/hooks/useToggleCompleteMilestone';
import CommentsListLoader from '@advisor/journey/components/MilestoneCommentsList/CommentsListLoader';
import {
  milestoneAtoms,
  MilestoneScope,
  useMilestone,
} from '@advisor/journey/api/milestone';
import MilestoneActionModals, {
  useOpenEditMilestoneModal,
} from '@advisor/journey/components/MilestoneActionModals';
import useManualReminder from '@advisor/journey/hooks/useManualReminder';
import { useMilestoneTranslation } from '@advisor/journey/api/translation';
import { TranslatingIndicator } from '@advisor/chat/contentTranslation';
import { JourneyMilestoneParams } from 'src/routes/params';
import SideModal from './SideModal';

const JourneyMilestoneModal = () => {
  const { milestoneId } = useParams<JourneyMilestoneParams>();

  const actOnMilestone = useSetAtomOrNull(
    milestoneId ? milestoneAtoms(milestoneId) : null,
  );

  const refetchMilestone = useCallback(() => {
    actOnMilestone?.({ type: 'refetch' });
  }, [actOnMilestone]);

  return (
    <ErrorBoundary
      fallback={<ErrorFallback refetchMilestone={refetchMilestone} />}
    >
      <Suspense fallback={<Fallback />}>
        <ScopeProvider scope={MilestoneScope} value={milestoneId}>
          <JourneyMilestoneModalContent />
        </ScopeProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

const ErrorFallback = ({
  refetchMilestone,
}: {
  refetchMilestone: (() => void) | null;
}) => {
  return (
    <Container>
      <ErrorView
        onTryAgain={() => {
          refetchMilestone?.();
        }}
      />
    </Container>
  );
};

const Fallback = () => {
  return (
    <Container>
      <div className="h-full flex flex-col">
        <CommentsListSkeleton />
      </div>
    </Container>
  );
};

const JourneyMilestoneModalContent = () => {
  const milestone = useMilestone('required');
  const chatRoomCtx = useActiveChatRoom('required');
  const { chatRoomId } = chatRoomCtx;

  const [translation] = useMilestoneTranslation();

  return (
    <Container title={translation.content?.name ?? milestone.name}>
      <div className="h-full flex flex-col">
        <MilestoneCommentsList
          milestoneId={milestone.id}
          chatRoomId={chatRoomId}
          ListHeaderComponent={<Header />}
        />
        <CommentInput
          chatRoomId={chatRoomId}
          milestoneId={milestone.id}
          milestoneCategoryId={milestone.milestoneCategoryId}
        />
      </div>
      <MilestoneActionModals.Edit />
    </Container>
  );
};

const Container = (props: PropsWithChildren<{ title?: string }>) => {
  const { children, title } = props;

  return (
    <SideModal.Default
      backButton
      scroll={false}
      padding={false}
      title={title ?? ''}
    >
      {children}
    </SideModal.Default>
  );
};

function Header() {
  const { t } = useTranslation(['common', 'task-organiser']);

  const me = useMe();
  const milestone = useMilestone('required');
  const manualReminder = useManualReminder(milestone);
  const onCompleteMilestone = useToggleCompleteMilestone();
  const [translation] = useMilestoneTranslation();

  const openMilestoneModal = useOpenEditMilestoneModal(milestone);

  const advert = useAdvertForPlacement(
    milestone.advertisementKey,
    AdRequirements.withAsset,
  );

  return (
    <>
      <PendingReviewTooltip pendingReview={milestone.pendingReview}>
        <div className="bg-white border-b border-grey py-4 px-6 empty:hidden text-sm flex flex-row justify-between">
          <MilestoneStatus {...milestone} />
          {Role.isVerifiedAdvisor(me) && manualReminder.isVisible && (
            <DeprecatedButton.Text weight={700} onPress={manualReminder.send}>
              {t('task-organiser:remind')}
            </DeprecatedButton.Text>
          )}
        </div>
        <div className="p-6 bg-white">
          <TranslatingIndicator status={translation.status.type}>
            <div className="flex flex-row items-center gap-4 mb-4">
              <CompleteButton {...milestone} onComplete={onCompleteMilestone} />
              {/* Title */}
              <h5 className="font-sora font-bold text-sm">
                {translation.content?.name ?? milestone.name}
              </h5>
            </div>
            {/* Description */}
            <p className="font-outfit font-medium text-sm text-dark-grey-025 ">
              {translation.content?.description ?? milestone.description}
            </p>
          </TranslatingIndicator>
          {/* Edit */}
          <PermissionGate scope={Scope.verifiedAdvisor}>
            {() => (
              <button
                type="button"
                onClick={openMilestoneModal}
                className="flex flex-row gap-0.5 items-center opacity-100 hover:opacity-60 transition-opacity text-primary mt-6"
              >
                <Icon name="Edit" size={24} />
                <p className="font-sora font-bold text-xs uppercase">
                  {t('common:edit')}
                </p>
              </button>
            )}
          </PermissionGate>
        </div>
      </PendingReviewTooltip>
      {advert && (
        <>
          <Advert.Banner advert={advert} />
          <Layout.Spacer.Vertical size="tiny" />
        </>
      )}
      <div className="mx-6 border-t border-b border-grey flex flex-row gap-4 items-center py-4 text-base">
        <p className="grow text-dark-grey-025 font-normal">
          {t('task-organiser:comments')}
        </p>
        <Counter>
          <Icon name="Attachments" className="text-dark-grey-01" size={16} />
          <p>{milestone.attachmentsCount}</p>
        </Counter>
        <Counter>
          <Icon name="Comments" className="text-soft-blue-light" size={16} />
          <p>{milestone.commentsCount}</p>
        </Counter>
      </div>
    </>
  );
}

function CommentsListSkeleton() {
  const { t } = useTranslation('task-organiser');

  return (
    <>
      <Skeleton.Ping>
        <div className="p-6">
          <div className="flex flex-row gap-4 items-center mb-3">
            <div className="w-6 h-6 border-2 border-light-grey rounded-full" />
            {/* Title */}
            <div className="flex-1">
              <Skeleton.Text fontSize={14} lineHeight={20} size="huge" />
            </div>
          </div>
          <Layout.Spacer.Vertical size="atomic" />
          {/* Description */}
          <Skeleton.Text fontSize={14} lineHeight={20} />
          <Skeleton.Text fontSize={14} lineHeight={20} />
          <Skeleton.Text fontSize={14} lineHeight={20} size="huge" />
        </div>
        <div className="mx-6 border-t border-b border-grey flex flex-row gap-4 items-center py-4 text-base">
          <p className="grow text-dark-grey-025 font-normal">{t('comments')}</p>
        </div>
      </Skeleton.Ping>
      <CommentsListLoader />
    </>
  );
}

const Counter: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="flex flex-row gap-1 items-center text-dark-grey-01">
      {children}
    </div>
  );
};

export default memo(JourneyMilestoneModal);
