import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { ArchivalVideoChatRoom } from '@advisor/api/generated/graphql';
import { showToast } from '@advisor/design/components/Toast';

export function useOpenVideoRoomDetails() {
  const navigate = useNavigate();

  return useCallback(
    (videoChat: ArchivalVideoChatRoom) => {
      navigate(
        `/chat/${videoChat.chatRoomId}/preferences/video-room-history/${videoChat.count}`,
      );
    },
    [navigate],
  );
}

export function useOpenJourneyCategory() {
  const navigate = useNavigate();

  return useCallback(
    (chatRoomId: string, categoryId?: string) => {
      if (!categoryId) {
        showToast({
          messageI18Key: 'this-item-is-no-longer-available',
          variant: 'rose',
          iconName: 'ErrorTriangle',
        });

        return;
      }

      navigate(`/chat/${chatRoomId}/preferences/journey/${categoryId}`);
    },
    [navigate],
  );
}

export function useOpenJourneyMilestone() {
  const navigate = useNavigate();

  return useCallback(
    (chatRoomId: string, categoryId?: string, milestoneId?: string) => {
      if (!milestoneId || !categoryId) {
        showToast({
          messageI18Key: 'this-item-is-no-longer-available',
          variant: 'rose',
          iconName: 'ErrorTriangle',
        });

        return;
      }

      navigate(
        `/chat/${chatRoomId}/preferences/journey/${categoryId}/${milestoneId}`,
      );
    },
    [navigate],
  );
}

export function useOpenJourneyScreen() {
  const navigate = useNavigate();

  return useCallback(
    (chatRoomId: string) => {
      navigate(`/chat/${chatRoomId}/preferences/journey`);
    },
    [navigate],
  );
}
