import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LoginResult, useLoginToBackend, FlowType } from '@advisor/api/auth';
import { useReadAtom } from '@advisor/utils/hooks';
import { Loader } from '@advisor/design/components';
import { invitationAtom } from '@advisor/onboarding';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import { DeleteAccountContent } from 'src/components/atoms/DeleteAccountComponents';
import DeleteAccountLayout from 'src/components/layouts/DeleteAccountLayout';
import DeleteAccountModal from 'src/components/organisms/modals/DeleteAccountModal';
import LoginLayout from '../components/layouts/LoginLayout';

/**
 * For use in code which cannot use hooks or is too high in the tree.
 */
export function areWeInCallback() {
  return window.location.pathname.includes('/callback');
}

/**
 * Purely visual, no functionality, as it is used as a Suspense fallback.
 */
export const CallbackLoader = () => {
  const { t } = useTranslation('onboarding');
  const [isDeleteFlow] = useState(
    localStorage.getItem('FLOW_TYPE') === FlowType.Delete,
  );

  return (
    <>
      {isDeleteFlow ? (
        <DeleteAccountLayout title="Delete your account">
          <DeleteAccountContent />
          <DeleteAccountModal isOpen>
            <div className="min-h-[452px] flex flex-col items-center justify-center">
              <Loader />
              <p className="text-xl font-semibold mt-8 whitespace-pre-line md:whitespace-normal">
                {t('verifying-credentials')}
              </p>
            </div>
          </DeleteAccountModal>
        </DeleteAccountLayout>
      ) : (
        <LoginLayout>
          <div className="flex flex-col bg-white rounded md:shadow-drop-01 p-28 md:mt-[114px]  h-[600px] w-[438px] items-center">
            <Loader />
            <p className="text-xl font-semibold mt-8 whitespace-pre-line md:whitespace-normal">
              {t('logging-you-in')}
            </p>
          </div>
        </LoginLayout>
      )}
    </>
  );
};

/**
 * Actual authentication is performed in AuthProvider
 */
const Callback = () => {
  const loginToBackend = useLoginToBackend();
  const isAuthorized = useIsAuthorized();
  const navigate = useNavigate();
  const readInvitation = useReadAtom(invitationAtom);

  const handlingRedirectRef = useRef(false);
  useEffect(() => {
    (async () => {
      if (!isAuthorized || handlingRedirectRef.current) {
        return;
      }

      handlingRedirectRef.current = true;

      const { result, id } = await loginToBackend();
      const flowType = localStorage.getItem('FLOW_TYPE');
      const isDeleteFlow = flowType === FlowType.Delete;

      const { inviteType, inviteId } = (await readInvitation()) ?? {};

      if (
        (result === LoginResult.NewUser && !isDeleteFlow) ||
        (inviteType === 'student' && id === inviteId)
      ) {
        navigate('/create-profile', { replace: true });
        localStorage.removeItem('FLOW_TYPE');
      } else if (result === LoginResult.LoggedIn && !isDeleteFlow) {
        navigate('/chat', { replace: true });
        localStorage.removeItem('FLOW_TYPE');
      } else if (isDeleteFlow) {
        navigate('/delete-account', { replace: true });
      }
    })();
  }, [loginToBackend, isAuthorized, navigate, readInvitation]);

  return <CallbackLoader />;
};

export default Callback;
