import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { nullAtom } from '@advisor/utils/atoms';
import { videoChatQueryAtoms } from '../atoms';

const useVideoChat = (chatRoomId: string, skip = false) => {
  const videoChatAtom = useMemo(
    () => (skip ? nullAtom : videoChatQueryAtoms(chatRoomId)),
    [chatRoomId, skip],
  );

  return useAtomValue(videoChatAtom) ?? null;
};

export default useVideoChat;
