import React from 'react';

import { HeaderTextProps } from './types';

const HeaderText = ({ children, monospace }: HeaderTextProps) => {
  return (
    <p
      className="text-white text-xs sm:text-sm"
      style={monospace ? { fontVariantNumeric: 'tabular-nums' } : {}}
    >
      {children}
    </p>
  );
};

export default HeaderText;
