import { useAtomValue } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { chatRoomAtoms } from '@advisor/api/chatRoom';
import { collectionAtom, lazyAtom } from '@advisor/utils/atoms';
import { AutotranslationState } from '@advisor/chat/contentTranslation';

export const inProgressStudentJourneysAtom = collectionAtom<string>();

export const journeyTranslationStateAtoms = atomFamily((chatRoomId: string) => {
  const { memberOfMeAtom } = chatRoomAtoms(chatRoomId);

  return lazyAtom(async (get) => {
    const inProgress = get(inProgressStudentJourneysAtom).includes(chatRoomId);

    if (inProgress) {
      return AutotranslationState.Loading;
    }

    const isAutotranslationEnabled =
      (await get(memberOfMeAtom))?.autoTranslateStudentJourney ?? false;

    return isAutotranslationEnabled
      ? AutotranslationState.Enabled
      : AutotranslationState.Disabled;
  });
});

export function useJourneyTranslationState(chatRoomId: string) {
  return useAtomValue(journeyTranslationStateAtoms(chatRoomId));
}
