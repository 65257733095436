import React, { Suspense } from 'react';

import { Layout, Skeleton } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

const EditNoteModalContentLazy = React.lazy(
  () => import('./EditNoteModalContent'),
);

const EditNoteModal = () => (
  <Suspense fallback={<Fallback />}>
    <EditNoteModalContentLazy />
  </Suspense>
);

export default EditNoteModal;

const Fallback: React.FC = () => (
  <>
    <div className="h-header-height flex items-center border-b border-grey px-6">
      <Skeleton.Text fontSize={16} lineHeight={20} />
    </div>
    <div className="p-6">
      <Skeleton.Text fontSize={14} lineHeight={18} />
      <Layout.Spacer.Vertical size={Spacing.micro02} />
      <Skeleton.Text fontSize={14} lineHeight={18} />
      <Layout.Spacer.Vertical size={Spacing.micro02} />
      <Skeleton.Text fontSize={14} lineHeight={18} />
    </div>
  </>
);
