import cs from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCachedUser } from '@advisor/api/user';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import useInterpolatedUsername from '../hooks/useInterpolatedUsername';
import { TypingIndicatorProps } from './types';

interface DotProps {
  className?: string;
}

const Dot: React.FC<DotProps> = memo(({ className }) => (
  <div
    className={cs(
      'w-1.5 h-1.5 bg-primary rounded-full animate-pulse duration-[900ms]',
      className,
    )}
  />
));

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ userId }) => {
  const { t } = useTranslation('common');

  const { chatRoomId } = useActiveChatRoom() ?? {};
  const user = useCachedUser(userId);
  const username = useInterpolatedUsername(user, chatRoomId);

  return (
    <div className="px-4">
      <div className="bg-white border border-light-grey h-10 w-14 rounded-b-10 rounded-tr-10 mb-2 flex flex-row justify-center items-center gap-1">
        <Dot />
        <Dot className="animation-delay-300" />
        <Dot className="animation-delay-600" />
      </div>
      {username && (
        <p className="text-xs text-dark-grey-01 mb-4 mx-2">
          {t('user-is-typing', { user: username })}
        </p>
      )}
    </div>
  );
};

export default memo(TypingIndicator);
