import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';

import { useEvent, useMutationObservable } from '@advisor/utils/hooks';
import TooltipContext, { initialDimensions } from './context';
import { Rect, TooltipRootProps } from './types';

const TooltipRoot = ({ children, className }: TooltipRootProps) => {
  const [dimensions, setDimensions] = useState<Rect>(initialDimensions);
  const divRef = useRef<HTMLDivElement>(null);

  const handleResize = useEvent(() => {
    if (divRef.current) {
      const { x, y, width, height } = divRef.current.getBoundingClientRect();
      setDimensions({
        x,
        y,
        width,
        height,
      });
    }
  });

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useMutationObservable(divRef, handleResize);

  return (
    <TooltipContext.Provider value={{ rootDimensions: dimensions }}>
      <div ref={divRef} className={className}>
        {children}
      </div>
    </TooltipContext.Provider>
  );
};

export default memo(TooltipRoot);
