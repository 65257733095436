const newOnlinePromise = (): Promise<void> => {
  return new Promise((resolve) => {
    if (navigator.onLine) {
      resolve();
    }

    const handleOnline = () => {
      resolve();
      window.removeEventListener('online', handleOnline);
    };

    window.addEventListener('online', handleOnline);
  });
};

export default newOnlinePromise;
