import { atom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const layoutVersionAtom = atom(0);

export default function useReflowLayout() {
  const setLayoutVersion = useSetAtom(layoutVersionAtom);

  return useCallback(() => {
    setLayoutVersion((v) => v + 1);
  }, [setLayoutVersion]);
}
