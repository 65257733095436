import React, { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';

import { ActiveChatRoomProvider } from '@advisor/api/chatRoom';

function RouteChatRoomProvider({ children }: { children?: ReactNode }) {
  const chatMatch = useMatch({
    path: '/chat/:chatId',
    end: false,
  });
  const videoChatMatch = useMatch({
    path: '/video-call/:chatId',
    end: false,
  });

  const chatId = chatMatch?.params.chatId ?? videoChatMatch?.params.chatId;

  return (
    <ActiveChatRoomProvider chatRoomId={chatId || undefined}>
      {children}
    </ActiveChatRoomProvider>
  );
}

export default RouteChatRoomProvider;
