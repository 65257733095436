import React, { useEffect, useMemo, useRef } from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { useWindowSize, useStaticElementSize } from '@advisor/design/hooks';
import useScrollTop from 'src/hooks/useScrollTop';

const PANEL_VIRTUAL_HEIGHT = 1000;
const SMOOTH_RADIUS = 400;

const INFO_PANELS = [
  {
    titleKey: 'early-bird-study-abroad-preparation',
    asset: 'early-bird',
    descriptionKey: 'register-with-us-before-our-launch',
  },
  {
    titleKey: 'school-&-program-matching',
    asset: 'school-program-matching',
    descriptionKey:
      'our-expert-advisors-will-help-you-search-for-the-perfect-course-and-university',
  },
  {
    titleKey: 'application-&-personal-statements',
    asset: 'app-personal-statements',
    descriptionKey:
      'on-demand-assistance-with-university-applications-and-personal-statements',
  },
  {
    titleKey: 'post-admission-and-in-university-support',
    asset: 'post-admission-support',
    descriptionKey:
      'global-study-will-continue-to-support-you-throughout-the-course-of-your-international-study',
  },
] as const;

/**
 * Computes a 2d bezier curve (x0=0, x1=1/3, x2=2/3, x3=1) that smoothly interpolates two slopes: from and to
 * Meant to smooth transitions between linear functions.
 *
 * @param t Permitted values: 0-1 (inclusive). t == 0 => returns 0, t == 1 => returns 1
 * @param slopeOfEntry Slope of linear function to transition from
 * @param slopeOfExit Slope of linear function to transition into
 * @returns Interpolated t, taking into account the slopes of entry and exit
 */
const interpolateSlope = (
  t: number,
  slopeOfEntry: number,
  slopeOfExit: number,
) => {
  const y1 = (slopeOfEntry * 2) / 3;
  const y2 = 1 - (slopeOfExit * 2) / 3;

  return 3 * t * (1 - t) * (1 - t) * y1 + 3 * t * t * (1 - t) * y2 + t * t * t;
};

function linearWithSmoothStop(
  t: number,
  enter: number,
  exit: number,
  radius: number,
) {
  if (t <= enter - radius) return t;

  if (t < enter + radius) {
    const enterProgress = (t - (enter - radius)) / (radius * 2);
    return enter - radius + interpolateSlope(enterProgress, 1, 0) * radius;
  }

  if (t < exit - radius) return enter;

  if (t < exit + radius) {
    const exitProgress = (t - (exit - radius)) / (radius * 2);
    return enter + interpolateSlope(exitProgress, 0, 1) * radius;
  }

  return t - (exit - enter);
}

function AutoScrollInfoSection() {
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('global-study-homepage');
  const scrollTop = useScrollTop();
  const [, windowHeight] = useWindowSize();

  const [containerTop, containerHeight] = useMemo<[number, number]>(() => {
    if (!containerRef.current) return [0, 0];

    const { top: bodyTop } = document.body.getBoundingClientRect();
    const { top, height } = containerRef.current.getBoundingClientRect();

    return [top - bodyTop, height - windowHeight];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop, windowHeight]);

  const progress = useMemo(() => {
    if (containerRef.current === null) return 0;

    const { top } = containerRef.current.getBoundingClientRect();
    const progressPx = Math.max(0, -top);

    return progressPx;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, scrollTop]);

  const openPanelIndex = useMemo(() => {
    return Math.min(
      Math.floor(progress / PANEL_VIRTUAL_HEIGHT),
      INFO_PANELS.length - 1,
    );
  }, [progress]);

  const smoothScrollWithStop = useMemo(
    () =>
      containerTop -
      linearWithSmoothStop(
        scrollTop,
        containerTop,
        containerTop + containerHeight,
        SMOOTH_RADIUS,
      ),
    [scrollTop, containerTop, containerHeight],
  );

  return (
    <div
      ref={containerRef}
      style={{
        height: `${PANEL_VIRTUAL_HEIGHT * (INFO_PANELS.length + 1)}px`,
      }}
    >
      <div
        className={cs(
          'fixed inset-x-5 lg:inset-x-24 h-[100vh] flex flex-col justify-center',
          // If containerTop not calculated yet, do not show this section.
          containerTop === 0 && 'invisible',
        )}
        style={{
          top: `${smoothScrollWithStop}px`,
        }}
      >
        <div
          className="relative rounded-20 max-w-[83rem] overflow-hidden mx-auto bg-[#323338] top-5"
          style={{
            boxShadow: '2px 8px 16px 0px #00000022',
          }}
        >
          {INFO_PANELS.map(({ titleKey, asset, descriptionKey }, index) => (
            <InfoPanel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="odd:bg-[#292A2E] even:bg-[#323338]"
              title={t(titleKey)}
              open={index === openPanelIndex}
              icon={`/images/homepage/info-${asset}-icon.svg`}
              image={`/images/homepage/info-${asset}.png`}
            >
              {t(descriptionKey)}
            </InfoPanel>
          ))}
        </div>
      </div>
    </div>
  );
}

function InfoPanel({
  className,
  icon,
  title,
  image,
  children,
  open,
}: {
  className?: string;
  icon: string;
  title: string;
  image: string;
  children?: React.ReactNode;
  open?: boolean;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [[, contentHeight], recalculateContentHeight] =
    useStaticElementSize(contentRef);

  // Recalculating content size on image load.
  useEffect(() => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      recalculateContentHeight();
    };

    return () => {
      img.onload = null;
    };
  }, [image, recalculateContentHeight]);

  return (
    <article className={cs('', className)}>
      <header className="flex items-center space-x-2 px-5 h-16">
        <img src={icon} alt="Section icon" />
        <h1 className="text-white font-outfit text-base">{title}</h1>
      </header>
      <main
        className={cs('transition-all ease-out duration-500 overflow-hidden')}
        style={{
          height: open ? `${contentHeight}px` : '0px',
        }}
      >
        <div
          className="flex flex-col sm:flex-row items-center md:space-x-7 px-3 md:px-3 pb-3 h-[45vh] md:h-aut md:max-h-[calc(50vh-5rem)]"
          ref={contentRef}
        >
          <p className="md:ml-[5vw] md:mr-10 my-10 md:mt-0 text-primary font-sora font-medium text-xl lg:text-3xl">
            {children}
          </p>
          <div
            className="sm:hidden w-full flex-1 bg-cover rounded-10 bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <div className="hidden sm:block flex-shrink-0 w-[46%] h-full">
            <img
              className="flex-shrink flex-grow-0 block w-full h-full object-contain object-right"
              src={image}
              alt=""
            />
          </div>
        </div>
      </main>
    </article>
  );
}

export default AutoScrollInfoSection;
