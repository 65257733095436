import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { DateFormatter } from '@advisor/language';
import { useCachedUser } from '@advisor/api/user';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { JourneyMilestoneSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import useInterpolatedUsername from '../../../hooks/useInterpolatedUsername';
import { BoldText, SimpleMessage, Message } from '../systemMessageComponents';
import type { SystemMessageProps } from '../types';
import { useOpenJourneyMilestone } from '../navigation';

const MilestoneMarkedCompleteByStudent: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation(['common', 'user-role', 'system-message']);
  const { chatRoomId } = useActiveChatRoom('required');
  const data = message.data as JourneyMilestoneSystemMessageInfoFragment;
  const openMilestone = useOpenJourneyMilestone();

  const milestone = data.milestone?.name ?? data.metadata.milestoneName;
  const datetime = DateFormatter.dateTime(message.sentAt, t);
  const cachedUser = useCachedUser(data.studentId);
  const username =
    useInterpolatedUsername(cachedUser, chatRoomId) ??
    data.studentName ??
    t('user-role:anonymous');

  const onPress = useEvent(() =>
    openMilestone(
      message.chatRoomId,
      data.milestone?.milestoneCategoryId,
      data.milestone?.id,
    ),
  );

  return (
    <SimpleMessage onPress={onPress}>
      <Trans
        t={t}
        parent={Message}
        i18nKey="system-message:user-marked-task-as-complete-on"
        values={{ task: milestone, user: username, datetime }}
        components={{ bold: <BoldText /> }}
      />
    </SimpleMessage>
  );
};

export default memo(MilestoneMarkedCompleteByStudent);
