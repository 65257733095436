import { useSetAtom } from 'jotai';
import { kebabCase, map } from 'lodash-es';
import type { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';

import { showToast } from '@advisor/design/components/Toast';
import {
  Form,
  Modal,
  Layout,
  DeprecatedButton,
} from '@advisor/design/components';
import withSeparators from '@advisor/utils/withSeparators';
import useGreyLabeling, { ColorPalette } from '../useGreyLabeling';
import { customizationTabAtom, showBrandCustomizationModalAtom } from './atoms';
import { colorDescriptionKeys } from './utils';
import {
  BrandColor,
  IterableColor,
  CustomizationTabs,
  BrandColorItemProps,
} from './types';

const BrandColorItem: React.FC<BrandColorItemProps> = (props) => {
  const { name, value, onChange, isActive, description, onSetActive } = props;
  const { t } = useTranslation('brand-customization');

  const onChangeInternal = useCallback(
    (newValue: string) => onChange(name, newValue),
    [name, onChange],
  );

  if (!isActive) {
    return (
      <button
        type="button"
        onClick={() => onSetActive(name)}
        className="flex flex-row items-center p-4 rounded-10 hover:bg-super-light-grey border border-transparent hover:border-light-grey w-full"
      >
        <div className="w-2 h-2 rotate-45" style={{ backgroundColor: value }} />
        <Layout.Spacer.Horizontal size="micro02" />
        <div className="">{t(kebabCase(name) as ResourceKey)}</div>
      </button>
    );
  }

  return (
    <div className="border border-grey bg-light-grey rounded-10 p-4">
      <div className="flex flex-row items-center w-full">
        <div className="w-2 h-2 rotate-45" style={{ backgroundColor: value }} />
        <Layout.Spacer.Horizontal size="micro02" />
        <div className="">{t(kebabCase(name) as ResourceKey)}</div>
      </div>
      <Layout.Spacer.Vertical size="micro" />
      <p className="font-outfit text-xs text-dark-grey-02">{description}</p>
      <Layout.Spacer.Vertical size="tiny" />
      <Form.ColorInput value={value} onChange={onChangeInternal} />
    </div>
  );
};

const BrandColorsTab: React.FC = () => {
  const { t: commT } = useTranslation('common');
  const { t } = useTranslation('brand-customization');

  const setActiveTab = useSetAtom(customizationTabAtom);
  const setShowModal = useSetAtom(showBrandCustomizationModalAtom);
  const [activeColor, setActiveColor] = useState<BrandColor>('primaryColor');

  const { colorPalette, setColor, updateGreyLabeling } = useGreyLabeling();

  const onSavePress = useCallback(async () => {
    if (activeColor === 'primaryColor') {
      setActiveColor('secondaryColor');

      updateGreyLabeling();
      return;
    }

    await updateGreyLabeling();
    setShowModal(false);

    showToast({
      iconName: 'CircleCheck',
      variant: 'blue',
      namespace: 'brand-customization',
      messageI18Key: 'custom-branding-was-successfully-added',
    });
  }, [activeColor, updateGreyLabeling, setShowModal]);

  const iterableColors = useMemo(
    () =>
      map<ColorPalette, IterableColor>(colorPalette, (value, name) => ({
        name: name as BrandColor,
        value,
      })),
    [colorPalette],
  );

  const renderColorItem = useCallback(
    (color: IterableColor) => (
      <BrandColorItem
        key={color.name}
        name={color.name}
        value={color.value}
        onChange={setColor}
        onSetActive={setActiveColor}
        isActive={activeColor === color.name}
        description={t(colorDescriptionKeys[color.name])}
      />
    ),
    [activeColor, setColor, t],
  );

  const renderSeparator = useCallback(
    (idx: number) => (
      <Fragment key={idx}>
        <Layout.Spacer.Vertical size="micro02" />
        <hr className="text-grey" />
        <Layout.Spacer.Vertical size="micro02" />
      </Fragment>
    ),
    [],
  );

  return (
    <>
      <Modal.Header
        title={t('choose-brand-colors')}
        subtitle={t('for-customising-brand-identity')}
        onBack={() => setActiveTab(CustomizationTabs.Logo)}
        onClose={() => setShowModal(false)}
      />
      <div className="px-8 py-6">
        {withSeparators(iterableColors, renderSeparator, renderColorItem)}
      </div>
      <Modal.Footer>
        <DeprecatedButton.Large variant="primary" onPress={onSavePress}>
          {activeColor === 'secondaryColor' ? commT('save') : commT('next')}
        </DeprecatedButton.Large>
      </Modal.Footer>
    </>
  );
};

export default memo(BrandColorsTab);
