import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { DeletedMessageDocument } from '@advisor/api/generated/graphql';
import useMoreSubscription from '@advisor/utils/hooks/useMoreSubscription';
import { SubscriptionParams } from './types';
import { deleteMessage } from './cacheUtils';

function useDeletedMessageSubscription({
  subscribeToMore,
}: SubscriptionParams) {
  const chatRoomId = useActiveChatRoom()?.chatRoomId;

  useMoreSubscription(
    DeletedMessageDocument,
    subscribeToMore,
    (prev, { subscriptionData }) => {
      if (!subscriptionData.data.deletedMessage) {
        return prev;
      }

      const { deletedMessage } = subscriptionData.data;

      return deleteMessage(prev, deletedMessage.id);
    },
    { chatRoomId: chatRoomId! },
    !!chatRoomId,
  );
}

export default useDeletedMessageSubscription;
