import React, {
  useRef,
  useMemo,
  Fragment,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
  CSSProperties,
  useImperativeHandle,
} from 'react';
import cs from 'classnames';

import useResizeObserver from '@advisor/utils/hooks/useResizeObserver';
import type { FlatListRef, FlatListProps } from './types';
import { fakeBaseSyntheticEvent } from './utils';

type ItemWithKey = { key?: string };
type ItemWithId = { id?: string };

// Matches the behavior of default react native keyExtractor
function defaultKeyExtractor<ItemT>(item: ItemT, index: number): string {
  const asItemWKey = item as ItemWithKey;
  const asItemWId = item as ItemWithId;

  return asItemWKey.key ?? asItemWId.id ?? index.toString();
}

const DefaultThreshold = 0.4;

const fakeSeparators = {
  highlight: () => null,
  unhighlight: () => null,
  updateProps: () => null,
};

const FlatList = forwardRef(function WebFlatList<ItemT>(
  props: FlatListProps<ItemT>,
  ref: ForwardedRef<FlatListRef>,
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    data,
    onLayout,
    inverted,
    className,
    renderItem,
    keyExtractor,
    onEndReached,
    scrollEnabled,
    ListEmptyComponent,
    ListHeaderComponent,
    ListFooterComponent,
    onContentSizeChange,
    onEndReachedThreshold,
    contentContainerStyle,
    contentContainerClassName,
  } = props;

  useImperativeHandle(ref, () => {
    const handler: FlatListRef = {
      getScrollResponder() {
        return {
          scrollTo(options) {
            if (typeof options !== 'object') {
              throw new Error('Deprecated');
            }
            const { x, y, animated } = options;
            containerRef.current?.scrollTo({
              top: y,
              left: x,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
          scrollToEnd(options) {
            const scrollHeight = containerRef.current?.scrollHeight ?? 0;
            containerRef.current?.scrollTo({
              top: inverted ? -scrollHeight : scrollHeight,
              behavior: options?.animated ? 'smooth' : 'auto',
            });
          },
        };
      },
    };

    return handler;
  });

  const onScroll = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    // The property `.scrollTop` is negative `flex-direction` is set to ` column-reverse`.
    const scrollTop = inverted
      ? -containerRef.current.scrollTop
      : containerRef.current.scrollTop;

    const distanceFromEnd =
      containerRef.current.scrollHeight -
      containerRef.current.clientHeight -
      scrollTop;

    const distanceThreshold =
      (onEndReachedThreshold ?? DefaultThreshold) *
      containerRef.current.clientHeight;

    if (distanceFromEnd <= distanceThreshold) {
      onEndReached?.({ distanceFromEnd });
    }
  }, [inverted, onEndReached, onEndReachedThreshold]);

  useEffect(() => {
    onScroll();
  }, [onScroll]);

  useResizeObserver(
    contentRef,
    useCallback(
      ({ contentRect: { width, height } }) =>
        onContentSizeChange?.(width, height),
      [onContentSizeChange],
    ),
  );

  useResizeObserver(
    containerRef,
    useCallback(
      ({ contentRect }) =>
        onLayout?.({
          ...fakeBaseSyntheticEvent,
          nativeEvent: { layout: contentRect },
        }),
      [onLayout],
    ),
  );

  const items = useMemo(
    () =>
      data?.map((item, index) => (
        <Fragment
          key={keyExtractor?.(item, index) ?? defaultKeyExtractor(item, index)}
        >
          {renderItem?.({ item, index, separators: fakeSeparators })}
        </Fragment>
      )),
    [data, keyExtractor, renderItem],
  );

  return (
    <div
      ref={containerRef}
      className={cs(
        'relative min-h-0 flex',
        inverted ? 'flex-col-reverse' : 'flex-col',
        scrollEnabled ? 'overflow-auto' : 'overflow-hidden',
        className,
      )}
      onScroll={onScroll}
    >
      <div
        ref={contentRef}
        className={cs(
          'flex flex-shrink-0',
          inverted ? 'flex-col-reverse' : 'flex-col',
          contentContainerClassName,
        )}
        // This may cause some problems
        style={contentContainerStyle as CSSProperties}
      >
        {typeof ListHeaderComponent === 'function' ? (
          <ListHeaderComponent />
        ) : (
          ListHeaderComponent
        )}
        {items}
        {items?.length === 0 &&
          (typeof ListEmptyComponent === 'function' ? (
            <ListEmptyComponent />
          ) : (
            ListEmptyComponent
          ))}
        {typeof ListFooterComponent === 'function' ? (
          <ListFooterComponent />
        ) : (
          ListFooterComponent
        )}
      </div>
    </div>
  );
});

export default FlatList as <ItemT>(
  props: FlatListProps<ItemT> & { ref?: ForwardedRef<FlatListRef> },
) => ReturnType<typeof FlatList>;
