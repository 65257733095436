import dayjs from 'dayjs';
import { useMemo } from 'react';

import {
  useChatRoomQuery,
  UserInfoFragment,
} from '@advisor/api/generated/graphql';

interface SeenStats {
  seenByAll: boolean;
  seen: UserInfoFragment[];
  notSeen: UserInfoFragment[];
  notVisibleTo: UserInfoFragment[];
}

export default function useMessageSeenBy(
  messageSentAt: string,
  chatRoomId: string,
) {
  const { data: { chatRoom } = {} } = useChatRoomQuery({
    variables: { chatRoomId },
    fetchPolicy: 'cache-only',
  });

  return useMemo(() => {
    const sentAt = dayjs(messageSentAt);
    const stats: SeenStats = {
      seenByAll: false,
      seen: [],
      notSeen: [],
      notVisibleTo: [],
    };

    // TODO: Add logic for `notVisibleTo` group
    chatRoom?.members.forEach((member) => {
      if (sentAt.isBefore(member.lastSeenChatRoomOn)) {
        stats.seen.push(member.member);
      } else {
        stats.notSeen.push(member.member);
      }
    });

    stats.seenByAll = chatRoom?.members.length === stats.seen.length;

    return stats;
  }, [chatRoom?.members, messageSentAt]);
}
