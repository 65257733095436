import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { derive } from '@advisor/utils/atoms';
import { atomWithQuery } from '@advisor/api/apollo';
import { VideoChatDocument } from '@advisor/api/generated/graphql';
import { VideoCallStatus, callStateAtom } from './VideoRoomAPI';

/*
 * Querying with an atom instead of a hook to easily create derived atoms
 * and minimize the use of Listener components and useEffects.
 */
export const videoChatQueryAtoms = atomFamily((chatRoomId: string) => {
  const queryAtom = atomWithQuery(() => ({
    query: VideoChatDocument,
    variables: {
      chatRoomId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  }));

  return derive([queryAtom], ({ data }) => data?.videoChat);
});

export const activeVideoChatAtom = atom((get) => {
  const callState = get(callStateAtom);

  if (
    callState?.chatRoomId === undefined ||
    callState.status !== VideoCallStatus.Joined
  ) {
    return undefined;
  }

  return get(videoChatQueryAtoms(callState.chatRoomId));
});

export const isParticipantListOpenAtom = atom(false);
