import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import setupDayjs from './setupDayjs';

const commonI18nOptions = {
  returnNull: false,
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  defaultNS: 'common',
  ns: ['common'],
  interpolation: {
    escapeValue: false,
  },
  // using languageOnly will break the non-default language variations
  // f.e. es-419 will fallback to just es
  load: 'currentOnly',
} as const;

// eslint-disable-next-line import/prefer-default-export
export function setupPlatform(
  i18nInstance: typeof i18n,
  options: InitOptions = {},
) {
  i18nInstance.use(initReactI18next).init({
    ...commonI18nOptions,
    ...options,
  });

  i18nInstance.on('languageChanged', (lng) => setupDayjs(lng));

  return i18nInstance;
}
