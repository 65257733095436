import React, { PropsWithChildren, useEffect } from 'react';
import { Navigate } from 'react-router';

import { useMeLazyQuery } from '@advisor/api/generated/graphql';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';

const HomePageRedirect: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized = useIsAuthorized();

  const [getMeQuery, { loading, data: { me } = { me: undefined } }] =
    useMeLazyQuery();

  useEffect(() => {
    if (isAuthorized) {
      getMeQuery();
    }
  }, [getMeQuery, isAuthorized]);

  if (isAuthorized && !loading && me === null) {
    return <Navigate to="/create-profile" />;
  }

  if (isAuthorized && me) {
    return <Navigate to="/chat" />;
  }

  return children;
};

export default HomePageRedirect;
