import React, { memo } from 'react';
import { ScopeProvider } from 'bunshi/react';

import { warnExhaustive } from '@advisor/utils/typeAssertions';
import { MessageScope } from '../messaging/messageMolecule';
import MicrobotMessage from './MicrobotMessage';
import SystemMessage from './SystemMessage';
import UserMessage from './UserMessage';
import { MessageProps } from './types';

function Message(props: MessageProps) {
  const { message } = props;

  if (message.__typename === 'SystemMessage') {
    return <SystemMessage message={message} />;
  }

  if (message.__typename === 'UserMessage') {
    return <UserMessage {...props} message={message} />;
  }

  if (message.__typename === 'MicrobotMessage') {
    return <MicrobotMessage {...props} />;
  }

  warnExhaustive(message, '@advisor/chat/Message/Message');
  return null;
}

export default memo(function MessageWrapper(props: MessageProps) {
  const { message } = props;

  return (
    <ScopeProvider scope={MessageScope} value={message.id}>
      <Message {...props} />
    </ScopeProvider>
  );
});
