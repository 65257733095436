import React, { memo, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { Helmet } from 'react-helmet-async';

import Env from '@advisor/api/env';
import { meAtom } from '@advisor/api/me';
import suspenseHOC from '@advisor/utils/suspenseHOC';

const tagScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${Env.google.analyticsId}');
`;

const GoogleAnalyticsTag: React.FC = () => {
  const me = useAtomValue(meAtom);

  useEffect(() => {
    // Disable for SWM emails
    if (me?.email?.includes('@swmansion.com') && Env.google.analyticsId) {
      // @ts-expect-error
      window[`ga-disable-${Env.google.analyticsId}`] = true;
    }
  }, [me?.email]);

  if (!Env.google.analyticsId) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Env.google.analyticsId}`}
      />
      <script type="text/javascript">{tagScript}</script>
    </Helmet>
  );
};

export default suspenseHOC(memo(GoogleAnalyticsTag));
