import { useAtomValue } from 'jotai';

import featureEnabledAtoms from './featureEnabledAtoms';
import type { FeatureKey } from './types';

function useIsFeatureEnabled(feature: FeatureKey) {
  return useAtomValue(featureEnabledAtoms(feature));
}

export default useIsFeatureEnabled;
