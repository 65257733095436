import React from 'react';

import { SnapList } from '@advisor/design/components';
import { AssistFooterActionsProps } from './types';

const AssistFooterActions = ({ children }: AssistFooterActionsProps) => (
  <SnapList.Root className="px-4 py-2.5">{children}</SnapList.Root>
);

export default AssistFooterActions;
