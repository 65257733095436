import React, { memo } from 'react';
import cs from 'classnames';

import { HeadingProps } from './types';

const Heading = ({ title, titleAppendix, className }: HeadingProps) => (
  <div className={cs('justify-between', className)}>
    <h5 className="font-outfit text-xs font-semibold uppercase leading-4">
      {title}
    </h5>
    {!!titleAppendix && (
      <span className="font-outfit text-xs font-semibold uppercase leading-4">
        {titleAppendix}
      </span>
    )}
  </div>
);

export default memo(Heading);
