import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash-es';

import {
  CommentsForMilestoneDocument,
  CommentsForMilestoneQuery,
  CommentsForMilestoneQueryVariables,
  DeleteMilestoneCommentMutationOptions,
  MilestoneInfoFragment,
  MilestoneInfoFragmentDoc,
  useDeleteMilestoneCommentMutation,
} from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';
import Sentry from '@advisor/utils/Sentry';
import { showToast } from '@advisor/design/components/Toast';

const useDeleteComment = (commentId: string) => {
  const { t } = useTranslation(['common', 'task-organiser']);
  const showModal = useShowModal();

  const [deleteMilestoneComment] = useDeleteMilestoneCommentMutation();

  const deleteComment = useCallback(async () => {
    const confirm = await showModal.confirm({
      variant: 'severe',
      title: t('task-organiser:are-you-sure-you-want-to-delete-this-comment'),
      message: t('common:this-action-cannot-be-reversed'),
      confirmActionLabel: t('common:delete'),
    });

    if (!confirm) {
      return;
    }

    try {
      await deleteMilestoneComment({
        ...mutationOptions,
        variables: { milestoneCommentId: commentId },
      });

      showToast({
        variant: 'rose',
        iconName: 'Delete',
        messageI18Key: 'selected-comment-was-deleted',
      });
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });
    }
  }, [showModal, t, deleteMilestoneComment, commentId]);

  return deleteComment;
};

export default useDeleteComment;

const mutationOptions: DeleteMilestoneCommentMutationOptions = {
  update(cache, result) {
    const { id, milestoneId } = result.data?.deleteMilestoneComment ?? {};

    if (!id || !milestoneId) {
      return;
    }

    cache.updateQuery<
      CommentsForMilestoneQuery,
      CommentsForMilestoneQueryVariables
    >(
      {
        query: CommentsForMilestoneDocument,
        variables: { milestoneId },
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        const edges = prev.commentsForMilestone.edges.filter(
          ({ node }) => node.id !== id,
        );

        let { startCursor, endCursor } = prev.commentsForMilestone.pageInfo;

        if (edges.length > 0) {
          startCursor = edges[0].cursor;
          endCursor = last(edges)?.cursor;
        }

        return {
          ...prev,
          commentsForMilestone: {
            ...prev.commentsForMilestone,
            edges,
            pageInfo: {
              ...prev.commentsForMilestone.pageInfo,
              startCursor,
              endCursor,
            },
          },
        };
      },
    );

    cache.updateFragment<MilestoneInfoFragment>(
      {
        fragment: MilestoneInfoFragmentDoc,
        fragmentName: 'MilestoneInfo',
        id: cache.identify({
          __typename: 'Milestone',
          id: milestoneId,
        }),
      },
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          commentsCount: prev.commentsCount - 1,
        };
      },
    );
  },
};
