import { pipe } from 'remeda';
import { atomFamily } from 'jotai/utils';
import { ExtractAtomArgs, atom, useAtomValue } from 'jotai';

import soon from '@advisor/utils/soon';
import { Scope } from '@advisor/api/scope';
import {
  StudentJourneyDocument,
  ChatRoomMemberInfoFragment,
  StudentInfoFragment,
} from '@advisor/api/generated/graphql';
import { derive, lazyAtom, nullAtom } from '@advisor/utils/atoms';
import { chatRoomAtoms, useActiveChatRoom } from '@advisor/api/chatRoom';
import { atomOfQuery } from '@advisor/api/apollo';
import { Feature } from '@advisor/api/feature';
import { Role } from '@advisor/api/user';

export const studentJourneyAtoms = atomFamily((chatRoomId: string) => {
  const queryAtom = atomOfQuery(StudentJourneyDocument, {
    chatRoomId,
  });

  return atom(
    // getter
    (get) =>
      pipe(
        get(queryAtom),
        soon(({ data }) => data?.studentJourney),
      ),
    // setter
    async (_get, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
      set(queryAtom, ...args),
  );
});

export function useStudentJourney(chatRoomId: string | null) {
  return useAtomValue(chatRoomId ? studentJourneyAtoms(chatRoomId) : nullAtom);
}

/**
 * Notifications are going to go through only after the student journey is started by advisor
 */
export const isJourneyStartedAtoms = atomFamily((chatRoomId: string) => {
  const studentJourneyAtom = studentJourneyAtoms(chatRoomId);

  return lazyAtom(async (get) => {
    const studentJourney = await get(studentJourneyAtom);
    return !!studentJourney?.journeyProfile?.isStarted;
  });
});

export function useIsJourneyStarted(): boolean {
  const { chatRoomId } = useActiveChatRoom() ?? {};

  return (
    useAtomValue(chatRoomId ? isJourneyStartedAtoms(chatRoomId) : nullAtom) ??
    false
  );
}

/**
 * Student journey exists for the specific chat-room, and is
 * visible as an option in conversation preferences.
 */
export const studentJourneyScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.feature(Feature.StudentJourney),
    //
    // Student Journey is only available for chat rooms with student.
    Scope.studentConversation(chatRoomId),
    //
    // Only students, advisors and family members can access Student Journey.
    Scope.some([Scope.student, Scope.verifiedAdvisor, Scope.familyMember]),
    //
    // Student Journey is not available when there is no active advisor in a chat room
    Scope.doesChatHaveLeadingAdvisor(chatRoomId),
  ]),
);

/**
 * Student journey exists for the specific chat-room, and can
 * be accessed.
 */
export const studentJourneyAccessScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    studentJourneyScope(chatRoomId),
    Scope.some([
      //
      // A verified advisor always has access to the student journey.
      Scope.verifiedAdvisor,
      //
      // Other chat members can access the student journey only if it's started.
      isJourneyStartedAtoms(chatRoomId),
    ]),
  ]),
);

type StudentJourneyOwner = Omit<ChatRoomMemberInfoFragment, 'member'> & {
  member: StudentInfoFragment;
};

export const studentJourneyOwnerAtoms = atomFamily((chatRoomId: string) => {
  const { membersAtom } = chatRoomAtoms(chatRoomId);

  return derive([membersAtom], (members) => {
    // Assuming that there is only one student in a chat-room, and that
    // the student journey is theirs.
    return members.find((m): m is StudentJourneyOwner =>
      Role.isStudent(m.member),
    );
  });
});
