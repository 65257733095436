import { ApolloClient } from '@apollo/client';

import Sentry from '@advisor/utils/Sentry';
import {
  ConfirmFileUploadDocument,
  ConfirmFileUploadMutationVariables,
} from '../../generated/graphql';
import { UploadErrorType } from '../types';

async function confirmS3Upload(
  client: ApolloClient<unknown>,
  toConfirm: ConfirmFileUploadMutationVariables,
  signal?: AbortSignal,
) {
  try {
    const { data: result } = await client.mutate({
      mutation: ConfirmFileUploadDocument,
      variables: toConfirm,
      context: {
        fetchOptions: {
          signal,
        },
      },
    });

    if (!result) {
      return { error: UploadErrorType.UploadFailed };
    }

    return {
      ok: true as const,
      data: result.confirmFileUpload,
    };
  } catch (error) {
    Sentry.captureException(error);
    return { error: UploadErrorType.UploadFailed };
  }
}

export default confirmS3Upload;
