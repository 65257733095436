import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useResolveCalendarAuth } from '@advisor/microbots/components/MicrobotConfiguration/webCalendarAuth';
import AppLoader from '../AppLoader';

const CalendarCallback: React.FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const resolveAuth = useResolveCalendarAuth();

  useEffect(() => {
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    if (!code && !error) {
      return;
    }

    if (code) {
      resolveAuth({
        type: 'code',
        code,
      });
    }

    if (error === 'access_denied') {
      resolveAuth(null);
    } else if (error) {
      resolveAuth({
        type: 'error',
        message: error,
      });
    }

    window.close();
  }, [resolveAuth, searchParams]);

  return <AppLoader />;
};

export default CalendarCallback;
