import { useMemo } from 'react';

import { useVideoRoomAPI } from '../VideoRoomAPI';

function useLocalSession() {
  const { sessions } = useVideoRoomAPI() ?? {};

  return useMemo(() => {
    const sessionList = Object.values(sessions ?? {});

    return sessionList.find((s) => s.isLocal);
  }, [sessions]);
}

export default useLocalSession;
