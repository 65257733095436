import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Icon, Avatar } from '@advisor/design/components';
import type { ParticipantProps } from './types';

const Participant: React.FC<ParticipantProps> = ({ avatarUrl, name }) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row items-center">
      <div className="relative">
        <Avatar avatarUrl={avatarUrl} size={32} bordered={false} />
        <Icon
          name="VideoRoomFillOutline"
          className="w-4 h-4 absolute -bottom-1 ltr:-right-1 rtl:-left-1 text-primary"
        />
      </div>
      <Layout.Spacer.Horizontal size="micro02" />
      <p className="text-white text-xs sm:text-sm font-normal">
        <span className="font-bold tracking-wide">{name}</span>{' '}
        {t('has-joined-the-video-room')}
      </p>
    </div>
  );
};

export default Participant;
