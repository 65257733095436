import React from 'react';
import cs from 'classnames';

import Icon from '../Icon';
import DeprecatedButton from '../DeprecatedButton';
import SegmentedGroupItem from './SegmentedGroupItem';
import type { SegmentedGroupButtonProps } from './types';

const SegmentedGroupButton = (props: SegmentedGroupButtonProps) => {
  const {
    iconName,
    title,
    disabled,
    appendix,
    variant = 'neutral',
    ...rest
  } = props;

  return (
    <SegmentedGroupItem>
      <DeprecatedButton.Bare
        disabled={disabled}
        className={cs(
          'w-full flex items-center px-4 py-4 font-sora text-sm font-semibold transition-colors duration-75 active:bg-white hover:bg-off-white ltr:text-left rtl:text-right',
          'disabled:bg-light-grey disabled:border-grey disabled:text-dark-grey-01',
          {
            neutral: 'text-primary',
            negative: 'text-negative',
            positive: 'text-positive',
            dark: 'text-dark-grey-03',
          }[variant],
        )}
        {...rest}
      >
        {(renderProps) => (
          <>
            {!!iconName && (
              <Icon name={iconName} className="ltr:mr-3 rtl:ml-3" />
            )}
            <span className="grow">{title}</span>
            {typeof appendix === 'function' ? appendix(renderProps) : appendix}
          </>
        )}
      </DeprecatedButton.Bare>
    </SegmentedGroupItem>
  );
};

export default SegmentedGroupButton;
