import cs from 'classnames';

const PlayStoreUrl =
  'https://play.google.com/store/apps/details?id=chat.globalstudy.advisor';

type Props = {
  className?: string;
};

const PlayStoreLink = ({ className }: Props) => {
  return (
    <a
      href={PlayStoreUrl}
      className={cs(
        'inline-block border hover:bg-off-white transition-colors duration-75',
        className,
      )}
    >
      <img
        src="/images/homepage/get-on-google-play.svg"
        className="h-full max-h-fit object-contain"
        alt="Download on the Google Play"
      />
    </a>
  );
};

export default PlayStoreLink;
