import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { DeprecatedButton, Layout } from '@advisor/design/components';
import editStatusesAtom from '../editStatusesAtom';
import { TabProps } from '../types';

const Confirm: React.FC<TabProps> = ({ onGoBack }) => {
  const { t } = useTranslation('common');
  const dispatch = useSetAtom(editStatusesAtom);

  const onDelete = useEvent(async () => {
    await dispatch({ type: 'deleteStatus' });
    onGoBack();
  });

  return (
    <div className="px-6 py-8">
      {/* Title */}
      <h3 className="font-sora font-bold text-dark-grey-03 text-sm leading-[1.125rem] text-center">
        {t('are-you-sure-you-want-to-delete-this-status')}
      </h3>

      <Layout.Spacer.Vertical size="micro02" />

      {/* Message */}
      <p className="font-outfit text-dark-grey-02 font-medium text-sm leading-[1.125rem] text-center">
        {t('this-action-cannot-be-reversed')}
      </p>

      <Layout.Spacer.Vertical size="small01" />

      {/* Action buttons */}
      <div className="px-4">
        <DeprecatedButton.Large
          className="w-full text-sm h-12 leading-4"
          variant="negative"
          label={t('delete')}
          onPress={onDelete}
        />

        <Layout.Spacer.Vertical size="tiny" />

        <DeprecatedButton.Outline
          className="w-full text-sm h-12 leading-4"
          variant="darkGrey03"
          label={t('cancel')}
          onPress={onGoBack}
        />
      </div>
    </div>
  );
};

export default Confirm;
