import React, { memo } from 'react';

import Icon from '../Icon';
import { CheckboxProps } from './types';

const Checkbox = ({ checked }: CheckboxProps) => {
  return (
    <span className="block box-content w-5 h-5 border border-dark-grey-03 rounded shrink-0">
      {checked && <Icon name="Check" className="w-5 h-5 scale-150" />}
    </span>
  );
};

export default memo(Checkbox);
