import { useSetAtom } from 'jotai';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMyId } from '@advisor/api/me';
import { DateFormatter } from '@advisor/language';
import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import {
  Icon,
  Layout,
  SpinnerIcon,
  ContextMenu,
  DeprecatedButton,
} from '@advisor/design/components';
import {
  useIsMemoryProcessing,
  useMemoryFailedMessage,
  useDeleteMicrobotMemory,
} from '../../hooks';
import { currentBulkUploadIdAtom } from '../../BulkUpload/atoms';
import useMemoryListItem from './useMemoryListItem';
import { MemoryListItemProps } from './types';
import { isFileMemory } from './utils';

const MemoryListItem: React.FC<MemoryListItemProps> = (props) => {
  const { memory } = props;

  const { t } = useTranslation('microbot');

  const { memoryContent, editMemory } = useMemoryListItem(memory);

  /* Bulk Upload */
  const isBulkUploadEnabled = useIsFeatureEnabled(Feature.BulkUpload);
  const setCurrentBulkUploadId = useSetAtom(currentBulkUploadIdAtom);
  const isBulkSourced =
    memory.__typename !== 'MicrobotFileMemory' &&
    isBulkUploadEnabled &&
    memory.bulkUploadId;

  const isProcessing = useIsMemoryProcessing(memory);
  const failedMessage = useMemoryFailedMessage(memory);

  const myId = useMyId();
  const deleteMemory = useDeleteMicrobotMemory(memory.id);

  const amIAuthor = memory.author.id === myId;

  const openBulkUpload = () => {
    if (memory.__typename === 'MicrobotFileMemory' || !memory.bulkUploadId) {
      return;
    }

    setCurrentBulkUploadId(memory.bulkUploadId);
  };

  if (!memoryContent) {
    return null;
  }

  return (
    <ContextMenu.ManagedOwner>
      <div className="rounded-xl border border-grey">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <p className="font-sora font-semibold text-sm leading-[1.125rem] text-dark-grey-03">
              {memory.author.name}
            </p>
            {isProcessing ? (
              <SpinnerIcon className="w-4 h-4 text-primary" />
            ) : (
              <p className="text-dark-grey-025 font-sora text-[10px] leading-5">
                {DateFormatter.calendarTiny(memory.updatedAt)}
              </p>
            )}
          </div>
          <Layout.Spacer.Vertical size="quantum" />
          {memoryContent}
          {!!failedMessage && (
            <p className="text-negative font-outfit text-xs leading-4 pt-1">
              {failedMessage}
            </p>
          )}
          {amIAuthor && (
            <span className="absolute top-0 ltr:right-0 rtl:left-0">
              <ContextMenu.Root>
                <ContextMenu.Item
                  disabled={isFileMemory(memory)}
                  iconName="Edit"
                  onPress={() => {
                    editMemory();
                  }}
                  label={t('edit-memory')}
                  color="primary"
                />
                <ContextMenu.Item
                  iconName="Delete"
                  onPress={deleteMemory}
                  label={t('remove-from-alpha-bot-memory')}
                  color="negative"
                />
              </ContextMenu.Root>
            </span>
          )}
        </div>
        {isBulkSourced && (
          <div className="px-4 py-2 border-t border-grey">
            <DeprecatedButton.Text size="xs" onPress={openBulkUpload}>
              <Icon name="Attachments" size={16} className="text-primary" />
              <Layout.Spacer.Horizontal size="atomic" />
              {t('file-source')}
            </DeprecatedButton.Text>
          </div>
        )}
      </div>
    </ContextMenu.ManagedOwner>
  );
};

export default memo(MemoryListItem);
