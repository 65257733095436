import cs from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Spacing } from '@advisor/design/styles';
import { DeprecatedButton, SpinnerIcon } from '@advisor/design/components';
import FileAttachmentBase from './FileAttachmentBase';
import {
  PickedAttachmentProps,
  hasUploadFailed,
  isBeingUploaded,
} from './types';
import { useAttachmentDisplayName, useFormattedAttachmentSize } from './utils';

/**
 * Use in flows where users can add attachments (and remove them before submitting).
 */
const PickedAttachment: React.FC<PickedAttachmentProps> = (props) => {
  const { attachment, onRemove } = props;

  const { t } = useTranslation('common');

  const displayName = useAttachmentDisplayName(attachment);
  const formattedFileSize = useFormattedAttachmentSize(attachment);
  const beingUploaded = isBeingUploaded(attachment);
  const hasError = hasUploadFailed(attachment);

  return (
    <div className="w-11/12 only:w-full relative">
      <FileAttachmentBase
        title={displayName}
        description={formattedFileSize}
        hasError={hasError}
      />
      <div className="group w-6 h-6 bg-light-grey rounded-full absolute -top-2 ltr:-right-2 rtl:-left-2">
        <DeprecatedButton.Icon
          icon="X"
          variant="negative"
          onPress={onRemove}
          className={cs(beingUploaded && 'hidden group-hover:block')}
          size={Spacing.small01}
        />
        {beingUploaded && (
          <SpinnerIcon className="w-6 h-6 text-primary group-hover:hidden" />
        )}
      </div>
      {hasError && (
        <p className="text-negative text-xs font-outfit font-medium absolute top-full inset-x-4 truncate">
          {t('oops-something-went-wrong')}
        </p>
      )}
    </div>
  );
};

export default memo(PickedAttachment);
