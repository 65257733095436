import React from 'react';

import { Modal } from '@advisor/design/components';
import useCreateStudentConversation, {
  Tab,
} from '@advisor/api/hooks/useCreateStudentConversation';
import InfoTab from './InfoTab';
import FormTab from './FormTab';
import { CreateStudentModalProps } from './types';

const CreateStudentModal: React.FC<CreateStudentModalProps> = ({
  visible,
  onClose,
}) => {
  const {
    tab,
    error,
    disabled,
    profile,
    onContinue,
    onCreate,
    onSetName,
    onSetEmail,
    onSetPhoneNumber,
    onSetCallCode,
  } = useCreateStudentConversation(visible, onClose, navigator.language);

  return (
    <Modal.Base maxWidth={438} visible={visible} onClose={onClose}>
      {tab === Tab.Info ? (
        <InfoTab onContinue={onContinue} onClose={onClose} />
      ) : (
        <FormTab
          disabled={disabled}
          error={error}
          profile={profile}
          onCreate={onCreate}
          onSetName={onSetName}
          onSetEmail={onSetEmail}
          onSetPhoneNumber={onSetPhoneNumber}
          onSetCallCode={onSetCallCode}
          onClose={onClose}
        />
      )}
    </Modal.Base>
  );
};

export default CreateStudentModal;
