import { actionAtom, collectionAtom } from '@advisor/utils/atoms';
import {
  CreateMessageDocument,
  DeleteMessageDocument,
  EditMessageDocument,
  LatestMessagesDocument,
  MicrobotMemoriesDocument,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphqlTypes';
import { meAtom } from '@advisor/api/me';
import { clientAtom } from '@advisor/api/apollo';
import { updateChatRoomsAfterMessage } from '../ChatRoomList/utils';
import {
  appendNewMessage,
  deleteMessage,
  mockUserMessage,
  replaceMessage,
} from './cacheUtils';

export const messagesBeingDeletedAtom = collectionAtom<string>([]);

export const deleteMessageAtom = actionAtom(
  async ({ get, set }, messageToDelete: UserMessageInfoFragment) => {
    if (get(messagesBeingDeletedAtom).includes(messageToDelete.id)) {
      // Already being deleted.
      return;
    }

    set(messagesBeingDeletedAtom, ['add', messageToDelete.id]);

    try {
      const client = await get(clientAtom);

      await client.mutate({
        mutation: DeleteMessageDocument,
        variables: { messageId: messageToDelete.id },
        update(cache) {
          cache.updateQuery(
            {
              query: LatestMessagesDocument,
              variables: {
                chatRoomId: messageToDelete.chatRoomId,
              },
            },
            (prev) => {
              if (!prev) {
                return prev;
              }
              return deleteMessage(prev, messageToDelete.id);
            },
          );
        },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteMessage: {
            __typename: 'UserMessage',
            chatRoomId: 'chatRoomId',
            id: 'deletedId',
          },
        },
        refetchQueries: messageToDelete.microbotMemoryId
          ? [MicrobotMemoriesDocument]
          : undefined,
      });
    } finally {
      set(messagesBeingDeletedAtom, ['delete', messageToDelete.id]);
    }
  },
);

export const createMessageAtom = actionAtom(
  async ({ get }, params: { chatRoomId: string; messageContent: string }) => {
    const { chatRoomId, messageContent } = params;
    const me = await get(meAtom);

    if (!me) {
      throw new Error(`Tried creating message, but 'me' is missing.`);
    }

    const client = await get(clientAtom);

    return client.mutate({
      mutation: CreateMessageDocument,
      variables: {
        chatRoomId,
        message: messageContent,
      },
      update(cache, mutationResult) {
        const newMessage = mutationResult.data?.createMessage;

        if (!newMessage) {
          return;
        }

        cache.updateQuery(
          { query: LatestMessagesDocument, variables: { chatRoomId } },
          (prev) => (prev ? appendNewMessage(prev, newMessage) : prev),
        );

        // Update chat rooms sorting after sending a message
        updateChatRoomsAfterMessage(cache, newMessage, newMessage.sentAt);
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createMessage: mockUserMessage({
          author: me,
          chatRoomId,
          message: messageContent,
        }),
      },
    });
  },
);

export const messagesBeingUpdatedAtom = collectionAtom<string>([]);

export const updateMessageAtom = actionAtom(
  async (
    { get, set },
    messageToUpdate: UserMessageInfoFragment,
    updates: { message: string },
  ) => {
    if (get(messagesBeingUpdatedAtom).includes(messageToUpdate.id)) {
      // Already being deleted.
      return;
    }

    set(messagesBeingUpdatedAtom, ['add', messageToUpdate.id]);

    try {
      const updatedAt = new Date().toISOString();

      const client = await get(clientAtom);

      await client.mutate({
        mutation: EditMessageDocument,
        variables: {
          messageId: messageToUpdate.id,
          newMessage: updates.message,
        },
        update(cache, mutationResult) {
          const editedMessage = mutationResult.data?.editMessage;

          if (!editedMessage) {
            return;
          }

          cache.updateQuery(
            {
              query: LatestMessagesDocument,
              variables: { chatRoomId: messageToUpdate.chatRoomId },
            },
            (prev) => (prev ? replaceMessage(prev, editedMessage) : prev),
          );
        },
        refetchQueries: messageToUpdate.microbotMemoryId
          ? [MicrobotMemoriesDocument]
          : undefined,
        optimisticResponse: {
          __typename: 'Mutation',
          editMessage: {
            ...messageToUpdate,
            message: updates.message,
            updatedAt,
          },
        },
      });
    } finally {
      set(messagesBeingUpdatedAtom, ['delete', messageToUpdate.id]);
    }
  },
);
