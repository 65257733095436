import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { useEvent } from '@advisor/utils/hooks';
import { DeprecatedButton, Modal } from '@advisor/design/components';
import editStatusesAtom from '../editStatusesAtom';
import { StatusListItem } from '../components';
import { StatusListProps } from './types';

const StatusList: React.FC<StatusListProps> = ({ onAddNew, onDelete }) => {
  const { t } = useTranslation('common');
  const [state, dispatch] = useAtom(editStatusesAtom);

  const onClose = useEvent(() => {
    dispatch({ type: 'close' });
  });

  const onSave = useEvent(async () => {
    await dispatch({ type: 'save' });
    onClose();
  });

  return (
    <>
      {/* Header */}
      <Modal.Header title={t('customise-status')} onClose={onClose} />

      <div className="flex flex-col py-2 max-h-[23.25rem] overflow-y-auto">
        {state?.statuses.map((status, idx) => (
          <StatusListItem
            index={idx}
            status={status}
            key={status.id}
            onDelete={onDelete}
          />
        ))}
      </div>

      <Modal.Footer>
        <DeprecatedButton.Large variant="primary" outline onPress={onAddNew}>
          {t('add-new')}
        </DeprecatedButton.Large>
        <DeprecatedButton.Large variant="primary" onPress={onSave}>
          {t('save')}
        </DeprecatedButton.Large>
      </Modal.Footer>
    </>
  );
};

export default StatusList;
