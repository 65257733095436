import { ApolloClient } from '@apollo/client';

import { PrivateFileScope } from '../../generated/graphqlTypes';
import { FileData, FileUploadOptions } from '../types';
import uploadFile from './uploadFile';

export { default as uploadFile } from './uploadFile';
export { default as uploadMultipleFiles } from './uploadMultipleFiles';
export { default as useFileUpload } from './useFileUpload';
export { default as useMultipleFileUpload } from './useMultipleFileUpload';

export function uploadPublicFile(
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions['Public'],
) {
  return uploadFile(client, 'Public', file, options);
}

export function uploadAgencyFile(
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions[PrivateFileScope.Agency],
) {
  return uploadFile(client, PrivateFileScope.Agency, file, options);
}

export function uploadChatRoomFile(
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions[PrivateFileScope.ChatRoom],
) {
  return uploadFile(client, PrivateFileScope.ChatRoom, file, options);
}

export function uploadUserFile(
  client: ApolloClient<unknown>,
  file: FileData,
  options: FileUploadOptions[PrivateFileScope.User],
) {
  return uploadFile(client, PrivateFileScope.User, file, options);
}
