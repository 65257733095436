import { noop } from 'lodash-es';
import { useEffect } from 'react';

import { useLeaveVideoChatMutation } from '@advisor/api/generated/graphql';

export default function useBeforeUnload(chatRoomId?: string) {
  const [leaveVideoChat] = useLeaveVideoChatMutation();

  useEffect(() => {
    if (!chatRoomId) {
      return noop;
    }

    const onBeforeUnload = () => {
      leaveVideoChat({
        variables: { chatRoomId },
        context: {
          fetchOptions: { keepalive: true },
        },
      });
    };

    // react-router onBeforeUnload and window.addEventListener('beforeUnload')
    // doesn't seem to work f.e. on macos chrome
    window.onbeforeunload = onBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [chatRoomId, leaveVideoChat]);
}
