import React from 'react';

import { FileCategory } from '@advisor/api/generated/graphql';
import FileUpload, { FileUploadProps } from '../FileUpload';
import { AvatarUploadSizeThreshold } from './constants';

const avatarMaxResolution = {
  maxWidth: 1024,
  maxHeight: 1024,
  quality: 0.8,
} as const;

type AvatarUploadProps = Omit<
  FileUploadProps,
  'accept' | 'maxResolution' | 'fileCategory' | 'sizeThreshold'
>;

const AvatarUpload = (props: AvatarUploadProps) => (
  <FileUpload
    {...props}
    accept="image/*"
    fileCategory={FileCategory.Image}
    maxResolution={avatarMaxResolution}
    sizeThreshold={AvatarUploadSizeThreshold}
  />
);

export default AvatarUpload;
