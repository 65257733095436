import type { Atom } from 'jotai/vanilla';

/**
 * Based on Tailwind
 */
export const ScreenBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
} as const;

export type ScreenBreakpoint = keyof typeof ScreenBreakpoints;

export type ScreenWidthAtom = Atom<number>;
export type ScreenHeightAtom = Atom<number>;

export type LengthUnit = 'px' | 'vw' | 'dvh';

/**
 * Type representing length alongside the unit it is represented in.
 * Default unit is pixels.
 */
export type Length<ExtraUnits extends LengthUnit = LengthUnit> =
  | number
  | readonly [number, 'px' | ExtraUnits];
