import cs from 'classnames';
import React from 'react';

import { ComponentProps } from './types';

const Subtitle: React.FC<ComponentProps> = ({ children, className }) => {
  return (
    <h2
      className={cs(
        'font-sora font-semibold text-base md:text-2xl text-dark-grey-03 uppercase',
        className,
      )}
    >
      {children}
    </h2>
  );
};

export default Subtitle;
