import { findCallCodeByTimezoneName } from './callCodeSelection';
import { TimezoneName } from './timezonesByCountryCodes';

const findCountryByTimezone = (timezoneName: TimezoneName | '') => {
  if (!timezoneName) {
    return '';
  }

  const callCode = findCallCodeByTimezoneName(timezoneName);
  if (callCode) {
    return callCode.name;
  }

  return '';
};

export default findCountryByTimezone;
