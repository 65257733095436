import { pipe } from 'remeda';
import { atomFamily } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import soon, { soonAll } from '@advisor/utils/soon';
import { nullAtom } from '@advisor/utils/atoms';
import { RoleRequestDocument } from '../generated/graphql';
import { Feature, featureEnabledAtoms } from '../feature';
import { atomOfQuery } from '../apollo';
import { Role } from '../user';
import { meAtom } from '../me';

const roleRequestAtoms = atomFamily((chatRoomId: string) => {
  const queryAtom = atomOfQuery(RoleRequestDocument, {
    chatRoomId,
  });

  return atom((get) =>
    pipe(
      soonAll([
        get(meAtom),
        get(featureEnabledAtoms(Feature.ApprovingAdvisors)),
      ]),
      soon(([me, areRoleRequestsEnabled]) => {
        if (
          !areRoleRequestsEnabled ||
          (!Role.isAspiringAdvisor(me) && !Role.isApprovingAdvisor(me))
        ) {
          return null;
        }

        return get(queryAtom);
      }),
      soon((query) => query?.data?.roleRequest ?? null),
    ),
  );
});

export function useRoleRequest(chatRoomId?: string | null) {
  return useAtomValue(chatRoomId ? roleRequestAtoms(chatRoomId) : nullAtom);
}

export default roleRequestAtoms;
