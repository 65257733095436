import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useRef } from 'react';

import Sentry from '@advisor/utils/Sentry';
import { nullAtom } from '@advisor/utils/atoms';
import { ChatRoomContext } from '@advisor/api/chatRoom';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import { useLatestMessagesQuery } from '@advisor/api/generated/graphql';
import { useChatRoomLanguage } from '../chatLanguage';
import { useStreamingMessageSubscription } from './messageStreaming';
import useNewMicrobotMessageSubscription from './useNewMicrobotMessageSubscription';
import useNewSystemMessageSubscription from './useNewSystemMessageSubscription';
import useDeletedMessageSubscription from './useDeletedMessageSubscription';
import useNewUserMessageSubscription from './useNewUserMessageSubscription';
import useEditedMessageSubscription from './useEditedMessageSubscription';
import useMessageBlocks from './useMessageBlocks';
import { PageSize } from './consts';

export default function useMessageList(chatRoomCtx?: ChatRoomContext) {
  const { chatRoomId, memberOfMeAtom } = chatRoomCtx ?? {};

  const isAuthorized = useIsAuthorized();
  const chatRoomLanguage = useChatRoomLanguage(chatRoomId);

  const initialChatId = useRef(chatRoomId);
  const creatingChatFlow = !initialChatId.current;

  const myself = useAtomValue(memberOfMeAtom ?? nullAtom);

  const {
    data,
    fetchMore,
    subscribeToMore,
    loading: areMessagesLoading,
  } = useLatestMessagesQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      chatRoomId: chatRoomId!,
      last: PageSize,
      // Invalidating the query whenever the chat-room language changes to
      // refetch new translations.
      // @ts-ignore
      chatRoomLanguage,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !isAuthorized || !chatRoomId,
  });

  const messageList = useMessageBlocks(data, myself ?? undefined);

  useEditedMessageSubscription({ subscribeToMore });
  useDeletedMessageSubscription({ subscribeToMore });
  useNewMicrobotMessageSubscription({ subscribeToMore });
  useNewUserMessageSubscription({ subscribeToMore });
  useNewSystemMessageSubscription({ subscribeToMore });
  useStreamingMessageSubscription({ subscribeToMore });

  const latestMessageId: string | undefined = messageList[0]?.data[0]?.id;

  const showMessagePlaceholders =
    areMessagesLoading && !messageList.length && !creatingChatFlow;

  const hasMore = data?.messages.pageInfo.hasPreviousPage ?? false;
  const showLoader = !!chatRoomId && hasMore && !creatingChatFlow;

  const loadOlder = useCallback(async () => {
    if (!areMessagesLoading && data?.messages.pageInfo.startCursor && hasMore) {
      try {
        await fetchMore({
          variables: {
            before: data.messages.pageInfo.startCursor,
            last: PageSize,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [
    hasMore,
    fetchMore,
    areMessagesLoading,
    data?.messages.pageInfo.startCursor,
  ]);

  return useMemo(
    () => ({
      hasMore,
      messageList,
      latestMessageId,

      showLoader,
      showMessagePlaceholders,
      loadOlder,
    }),
    [
      hasMore,
      loadOlder,
      showLoader,
      messageList,
      latestMessageId,
      showMessagePlaceholders,
    ],
  );
}
