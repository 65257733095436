import { useEffect, memo } from 'react';
import { atom, useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { actionAtom } from '@advisor/utils/atoms';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import Env from '../env';
import { useAmIVerifiedAdvisor, useMyId } from '../me';
import useAccessToken from './useAccessToken';

type SignedCookieRequest = {
  accessToken: string;
  endpoint: string;
  body: string;
};

const areRequestsEqual = (a: SignedCookieRequest, b: SignedCookieRequest) =>
  a.accessToken === b.accessToken &&
  a.endpoint === b.endpoint &&
  a.body === b.body;

const sentRequestsAtom = atom<SignedCookieRequest[]>([]);

const requestSignedCookieAtom = actionAtom(
  async function requestSignedCookieAtomSetter(
    { get, set },
    request: SignedCookieRequest,
  ) {
    const requests = get(sentRequestsAtom);

    if (requests.some((req) => areRequestsEqual(req, request))) {
      // already sent
      return;
    }

    set(sentRequestsAtom, [...requests, request]);

    try {
      await fetch(request.endpoint, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Authorization: request.accessToken,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        body: request.body,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  },
);

function SignedCookieFetcher() {
  const requestSignedCookie = useSetAtom(requestSignedCookieAtom);
  const amIVerifiedAdvisor = useAmIVerifiedAdvisor();
  const accessToken = useAccessToken();
  const myId = useMyId();

  useEffect(() => {
    if (!accessToken || !myId) {
      // making sure we are authenticated
      return;
    }

    requestSignedCookie({
      accessToken,
      endpoint: `${Env.api.userSignedCookieUrl}?me=${myId}`,
      body: '{}',
    });
  }, [accessToken, myId, requestSignedCookie]);

  useEffect(() => {
    if (!accessToken || !myId) {
      // making sure we are authenticated
      return;
    }

    if (!amIVerifiedAdvisor) {
      // permission lock
    }

    requestSignedCookie({
      accessToken,
      endpoint: `${Env.api.agencySignedCookieUrl}?me=${myId}`,
      body: '{}',
    });
  }, [accessToken, myId, amIVerifiedAdvisor, requestSignedCookie]);

  return null;
}

export default suspenseHOC(memo(SignedCookieFetcher));

/**
 * Use wherever chat-room scope uploaded assets could be viewed by the user.
 * Can be mounted in multiple places at once.
 *
 * @param chatRoomId id of the chat-room to request the signed cookie for
 */
export function useChatRoomSignedCookies(chatRoomId?: string) {
  const requestSignedCookie = useSetAtom(requestSignedCookieAtom);
  const accessToken = useAccessToken();
  const myId = useMyId();

  useEffect(() => {
    if (!accessToken || !myId || !chatRoomId) {
      return;
    }

    requestSignedCookie({
      accessToken,
      endpoint: `${Env.api.chatRoomSignedCookieUrl}?me=${myId}`,
      body: JSON.stringify({ chatRoomId }),
    });
  }, [accessToken, myId, chatRoomId, requestSignedCookie]);
}
