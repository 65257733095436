import React, { ReactNode } from 'react';

import { Typography } from '@advisor/design/styles';
import { Skeleton } from '@advisor/design/components';

// Recording Loader is in separate file from RecordingItem to allow for code splitting on web
export const RecordingLoader: React.FC = () => {
  return (
    <Skeleton.Ping>
      <InfoBoxRoot>
        <InfoBoxRow>
          <div className="flex-1">
            <Skeleton.Text {...Typography.Sora.subtitleBlack} size="huge" />
            <Skeleton.Text {...Typography.Outfit.bodyTinyBlack} size="large" />
          </div>
        </InfoBoxRow>
        <InfoBoxRow>
          <div className="flex-1">
            <Skeleton.Text {...Typography.Outfit.bodySmallBlack} size="huge" />
          </div>
        </InfoBoxRow>
      </InfoBoxRoot>
    </Skeleton.Ping>
  );
};

export const InfoBoxRoot = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="border border-grey rounded-lg mt-3 mb-8">{children}</div>
  );
};

export const InfoBoxRow = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="flex flex-row items-center border-t border-grey first:border-t-0 p-4 min-h-[4.375rem]">
      {children}
    </div>
  );
};
