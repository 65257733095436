import cs from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import { Logo } from '@advisor/ui/Brand';
import { useWindowSize } from '@advisor/design/hooks';
import useScrollTop from 'src/hooks/useScrollTop';

interface NavbarProps {
  variant?: 'light' | 'dark';
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { variant = 'light' } = props;

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const scrollTop = useScrollTop();
  const [, windowHeight] = useWindowSize();

  const showStartMyJourneyButton = useMemo(
    () => scrollTop > windowHeight * 0.5,
    [scrollTop, windowHeight],
  );

  return (
    <>
      <div
        className={cs(
          'fixed top-0 left-0 right-0 z-50 h-20 px-5 lg:px-24 flex flex-row justify-between items-center',
          variant === 'light' ? 'bg-off-white' : 'bg-dark-grey-05',
        )}
      >
        <div className="py-2 h-full w-[194px]">
          <Logo type="full" fit="start" />
        </div>
        <Transition
          show={showStartMyJourneyButton}
          enter="duration-200 transition-all"
          leave="duration-200 transition-all"
          enterFrom="opacity-0"
          enterTo="opacity-1"
          leaveFrom="opacity-1"
          leaveTo="opacity-0"
        >
          <button
            type="button"
            className={cs(
              'button text-off-white text-xs font-sora font-medium tracking-widest uppercase px-3 sm:px-6 py-5 transition-colors bg-primary hover:bg-primary-light',
            )}
            onClick={() => navigate('/login')}
            data-cy="start-my-journey"
          >
            {t('start-my-journey')}
          </button>
        </Transition>
      </div>
      <div className="h-20" />
    </>
  );
};

export default Navbar;
