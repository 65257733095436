export enum PermissionStatus {
  UNDETERMINED = 'undetermined',
  GRANTED = 'granted',
  DENIED = 'denied',
}

export enum NotificationEnabledStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type UsePushNotificationsHook = () => {
  enabled: boolean;
  granted: boolean;
  denied: boolean;
  permissionStatus: PermissionStatus;
  requestPermissions: () => Promise<boolean>;
  registerForNotifications: () => Promise<
    'no-permission' | 'error' | 'success'
  >;
  unregisterFromNotifications: () => Promise<void>;
};
