import { useAtomValue } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import { useEvent } from '@advisor/utils/hooks';
import {
  CustomCallEvent,
  CustomCallEventListener,
  CustomCallEventTypeOrAll,
  SpecificCustomCallEvent,
} from '../types';
import subscribeToCallEvent from './subscribeToCallEvent';
import { dailySessionToGeneric } from './sessions';
import { dailyStateAtom } from './dailyState';

function useOnCustomEvent() {
  const dailyState = useAtomValue(dailyStateAtom);

  const onCustomEvent = useEvent(
    <T extends CustomCallEventTypeOrAll>(
      type: T,
      listener: CustomCallEventListener<T>,
    ) => {
      if (!dailyState) {
        return null;
      }

      const unsubscribe = subscribeToCallEvent(
        dailyState.call,
        'app-message',
        (event) => {
          if (!event) {
            return;
          }

          const dailySender = dailyState?.call.participants()[event.fromId];
          const sender = dailySender
            ? dailySessionToGeneric(dailySender)
            : null;

          if (!sender) {
            return;
          }

          try {
            const msg = CustomCallEvent.parse(event.data);

            if (type === 'all' || msg.type === type) {
              listener(msg as SpecificCustomCallEvent<T>, sender);
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        },
      );

      return {
        unsubscribe,
      };
    },
  );

  return onCustomEvent;
}

export default useOnCustomEvent;
