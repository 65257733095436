import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { JourneyFirstInteractionSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import type { SystemMessageProps } from '../types';
import { BoldText, SimpleMessage, Message } from '../systemMessageComponents';
import { useOpenJourneyScreen } from '../navigation';
import useUserName from '../useUserName';

const AdvisorMessageInNewConversation: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const data = message.data as JourneyFirstInteractionSystemMessageInfoFragment;
  const openJourneyScreen = useOpenJourneyScreen();

  const username = useUserName(data.advisor) ?? data.advisorName;

  const onPress = useEvent(() => openJourneyScreen(message.chatRoomId));

  return (
    <SimpleMessage onPress={onPress}>
      <Trans
        t={t}
        values={{ user: username }}
        parent={Message}
        components={{ bold: <BoldText /> }}
        i18nKey="user-has-set-up-your-personalised-task-organiser-please-review"
      />
    </SimpleMessage>
  );
};

export default memo(AdvisorMessageInNewConversation);
