import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@advisor/design/components';
import { Colors } from '@advisor/design/styles';
import { UserRoleSelectButtonProps } from './types';

const UserRoleSelectButton: React.FC<UserRoleSelectButtonProps> = ({
  onOpen,
  roleTitle,
  isVisible,
  upwards = false,
  disabled,
}) => {
  const { t } = useTranslation();

  const openIcon = upwards ? 'ChevronUp' : 'ChevronDown';

  return (
    <button
      type="button"
      onClick={onOpen}
      disabled={disabled}
      className="ltr:text-left rtl:text-right w-full border border-dark-grey-01 rounded focus-visible:outline-primary disabled:bg-light-grey "
    >
      <div className="flex items-center flex-1  relative justify-between py-4 pl-4 pr-2">
        <div className="flex flex-col items-start justify-center">
          <span className="font-normal font-outfit text-xs text-dark-grey-02">
            {t('profile')}
          </span>
          <div className="text-sm font-medium text-dark-grey-03">
            {roleTitle}
          </div>
        </div>
        {!disabled && (
          <Icon
            size={24}
            name={isVisible ? openIcon : 'ChevronRight'}
            color={Colors.darkGrey03}
          />
        )}
      </div>
    </button>
  );
};

export default UserRoleSelectButton;
