import React, { useContext, useEffect } from 'react';

import { BannerContext } from './BannerContext';
import { BannerContextType, BannerViewProps } from './types';

const BannerView: React.FC<BannerViewProps> = (props) => {
  const {
    title,
    message,
    variant,
    backgroundColor,
    textColor,
    children,
    onPress,
  } = props;

  const { createNotification, removeNotification } = useContext(
    BannerContext,
  ) as BannerContextType;

  useEffect(() => {
    const notificationId = createNotification({
      title,
      message,
      variant,
      backgroundColor,
      textColor,
      onPress,
      children: () => children as JSX.Element,
    });

    return () => {
      removeNotification(notificationId);
    };
  }, [
    backgroundColor,
    children,
    createNotification,
    message,
    onPress,
    removeNotification,
    textColor,
    title,
    variant,
  ]);

  return null;
};

export default BannerView;
