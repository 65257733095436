import cs from 'classnames';
import React, { MouseEvent } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PermissionGate } from '@advisor/api/scope';
import { Icon, Layout } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';
import { useEvent } from '@advisor/utils/hooks';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import sidebarTabAtom, { SidebarTab } from '@advisor/ui/atoms/sidebarTabAtom';
import communityDirectoryAccessAtom from '@advisor/ui/atoms/communityDirectoryAccessAtom';
import lastChatRoomAtom from 'src/atoms/lastChatRoomAtom';

const PrivacyPolicyLink = 'https://globalstudy.chat/privacy-policy';

function ConversationsLink() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const setShow = useSetAtom(showSidebarAtom);
  const setSidebarTab = useSetAtom(sidebarTabAtom);
  const lastChatRoom = useAtomValue(lastChatRoomAtom);

  const onNavigateToChatRoom = useEvent(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setSidebarTab(SidebarTab.Conversations);
      setShow(false);
      navigate(`/chat/${lastChatRoom ?? ''}`);
    },
  );

  return (
    <NavLink
      onClick={onNavigateToChatRoom}
      className={({ isActive }) =>
        cs(
          'p-4 flex items-center border',
          isActive
            ? 'bg-light-grey border border-grey rounded-10'
            : 'border-transparent',
        )
      }
      to="/chat"
    >
      <Icon name="MessageAlt" size={Spacing.iconSize} color="primary" />
      <Layout.Spacer.Horizontal size="tiny" />
      <div>
        <h4 className="font-sora font-semibold text-sm leading-4 text-black">
          {t('conversations')}
        </h4>
        <Layout.Spacer.Vertical size="quantum" />
        <p className="font-ouftit font-medium text-sm leading-[1.125rem] text-dark-grey-0225">
          {t('customers-hosts-and-guest-hosts')}
        </p>
      </div>
    </NavLink>
  );
}

function CommunityLink() {
  const { t } = useTranslation(['common', 'microbot']);

  return (
    <NavLink
      className={({ isActive }) =>
        cs(
          'p-4 flex items-center border',
          isActive
            ? 'bg-light-grey border border-grey rounded-10'
            : 'border-transparent',
        )
      }
      to="/community"
    >
      <Icon
        name="UserProfileMultiple"
        size={Spacing.iconSize}
        color="secondary"
      />
      <Layout.Spacer.Horizontal size="tiny" />
      <div>
        <h4 className="font-sora font-semibold text-sm leading-4 text-black">
          {t('common:community')}
        </h4>
        <Layout.Spacer.Vertical size="quantum" />
        <p className="font-ouftit font-medium text-sm leading-[1.125rem] text-dark-grey-0225">
          {t('microbot:connect-with-host-ais')}
        </p>
      </div>
    </NavLink>
  );
}

const DirectoriesSidebarContent: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="px-6 pt-6 pb-10 flex-1 flex flex-col justify-between">
      <div>
        <h3 className="px-4 font-sora font-bold text-sm text-dark-grey-03">
          {t('your-account')}
        </h3>
        <Layout.Spacer.Vertical size="tiny" />
        <ConversationsLink />
        <PermissionGate scope={communityDirectoryAccessAtom}>
          {() => <CommunityLink />}
        </PermissionGate>
      </div>

      <Link
        className="px-4 font-sora text-sm font-semibold leading-4 text-dark-grey-03"
        to={PrivacyPolicyLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('privacy-policy')}
      </Link>
    </div>
  );
};

export default DirectoriesSidebarContent;
