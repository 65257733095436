import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router';

import identityAtom from '@advisor-client/web/src/atoms/identityAtom';
import CreateProfileForm from '@advisor/ui/components/CreateProfileForm';
import LoginLayout from 'src/components/layouts/LoginLayout';

const CreateProfilePage = () => {
  const identity = useAtomValue(identityAtom);
  const navigate = useNavigate();

  const onRedirectToChat = useCallback(() => {
    navigate('/chat');
  }, [navigate]);

  return (
    <LoginLayout>
      <CreateProfileForm
        loginType={identity.type}
        identity={identity.identity}
        onRedirectToChat={onRedirectToChat}
      />
    </LoginLayout>
  );
};

export default CreateProfilePage;
