import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMeAdvisor } from '@advisor/api/me';
import { createInviteLink } from '@advisor/onboarding';
import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import { InvitationOption } from './types';

export default function usePlatformInvitationOptions(): InvitationOption[] {
  const { t } = useTranslation('onboarding');
  const agencyInviteEnabled = useIsFeatureEnabled(Feature.AgencyInvite);
  const me = useMeAdvisor();

  return useMemo(() => {
    if (!me) {
      return [];
    }

    const personalOptionBase: InvitationOption = {
      title: t('name-personal-invite-link', { name: me?.name }),
      url: createInviteLink('advisor', me),
      subtitle: t('this-link-will-connect-the-user-to-you-directly'),
    };

    if (!agencyInviteEnabled) {
      return [
        {
          ...personalOptionBase,
          isRadio: false,
          showTitle: true,
          isSelectable: true,
        },
      ];
    }

    return [
      {
        ...personalOptionBase,
        isRadio: true,
        showTitle: true,
        isSelectable: true,
      },
      {
        title: t('organization-invite-link'),
        url: createInviteLink('agency', me),
        subtitle: t(
          'this-link-will-connect-the-user-with-an-host-from-your-agency',
        ),
        isRadio: true,
        showTitle: true,
        isSelectable: true,
      },
    ];
  }, [me, agencyInviteEnabled, t]);
}
