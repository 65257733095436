import { useMemo } from 'react';
import { useMolecule } from 'bunshi/react';
import { useAtomValue, useSetAtom } from 'jotai';

import { Lazy } from '@advisor/utils/atoms';
import {
  MessageTranslationMolecule,
  ToggleTranslationMolecule,
} from '../chatLanguage';

export default function useToggleTranslation() {
  const { statusAtom, shouldTranslationBeDisabledAtom } = useMolecule(
    MessageTranslationMolecule,
  );
  const { toggleTranslationAtom } = useMolecule(ToggleTranslationMolecule);

  const isDisabled = useAtomValue(
    Lazy.valueOr(shouldTranslationBeDisabledAtom, true),
  );
  const status = useAtomValue(Lazy.valueOr(statusAtom, undefined));
  const toggle = useSetAtom(toggleTranslationAtom);

  return useMemo(
    () => ({
      isDisabled,
      status: status ?? {
        type: 'raw' as const,
      },
      toggle,
    }),
    [isDisabled, status, toggle],
  );
}
