import React from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleTooltipContentProps } from './types';
import Content from './TooltipContent';

const SimpleTooltipContent = (props: SimpleTooltipContentProps) => {
  const {
    title,
    description,
    confirmButtonText,
    onClose,
    testID,
    variant,
    ...contentProps
  } = props;
  const { t } = useTranslation('common');

  return (
    <Content {...contentProps} onClose={onClose} variant={variant}>
      <p className="font-sora font-bold mb-1.5 text-sm">{title}</p>
      <p className="max-w-fit mb-4 font-outfit text-sm font-light">
        {description}
      </p>
      <div className="flex flex-row justify-end items-center">
        <button
          data-cy={testID}
          type="button"
          onClick={onClose}
          className="bg-primary py-2 px-4 rounded-md text-outfit text-sm"
        >
          {confirmButtonText ?? t('okay')}
        </button>
      </div>
    </Content>
  );
};

export default SimpleTooltipContent;
