import React from 'react';
import cs from 'classnames';
import { useAtomValue } from 'jotai';

import Advert, {
  AdRequirements,
  useAdvertForPlacement,
} from '@advisor/ui/components/Advert';
import { VIDEO_ROOM_PLACEMENT_KEY } from '@advisor/ui/components/Advert/staticPlacementKeys';
import useVideoRoomAdvertLocation, {
  VideoRoomAdvertLocation,
} from '../../useVideoRoomAdvertLocation';
import { ScreenShareFeed, useScreenSharingUsers } from '../../ScreenShare';
import { isParticipantListOpenAtom } from '../../atoms';
import ParticipantTile from '../ParticipantTile';
import InfoOverlay from '../InfoOverlay';
import { useParticipantsGrid } from './utils';

const TilesPerPage = 8;

function VideoFeedGrid() {
  const { corneredParticipant, gridParticipantsOnPage, rows, columns } =
    useParticipantsGrid({
      tilesPerPage: TilesPerPage,
    });
  const isParticipantListOpen = useAtomValue(isParticipantListOpenAtom);
  const screenSharingUsers = useScreenSharingUsers();

  const advert = useAdvertForPlacement(
    VIDEO_ROOM_PLACEMENT_KEY,
    AdRequirements.withAsset,
  );

  const shouldShowBannerAdvert =
    useVideoRoomAdvertLocation() === VideoRoomAdvertLocation.Banner && !!advert;

  const renderParticipantsInGrid = () => {
    return gridParticipantsOnPage.map((participant, idx) => {
      const column = Math.floor(idx / rows);
      const row = idx % rows;

      return (
        <ParticipantTile
          key={participant.key}
          rounded={{
            topLeft: row === 0 && column === 0 && !shouldShowBannerAdvert,
            topRight:
              row === 0 && column === columns - 1 && !shouldShowBannerAdvert,
            bottomRight: row === rows - 1 && column === columns - 1,
            bottomLeft: row === rows - 1 && column === 0,
          }}
          participant={participant}
        />
      );
    });
  };

  return (
    <div
      className={cs(
        'relative grow rounded-xl bg-gradient-to-t from-[#101015] to-[#222328] text-white',
        isParticipantListOpen && 'hidden md:block',
      )}
    >
      <InfoOverlay />
      <div
        className={cs(
          'absolute inset-0 grid grid-flow-col rounded-xl overflow-hidden auto-cols-fr',
          gridParticipantsOnPage.length < 3 ? 'grid-rows-1' : 'grid-rows-2',
          shouldShowBannerAdvert && 'pt-20',
        )}
      >
        {shouldShowBannerAdvert && (
          <div className="absolute top-0 inset-x-0 bg-[#242429]">
            <Advert.Banner advert={advert} />
          </div>
        )}
        {screenSharingUsers.length > 0 ? (
          <ScreenShareFeed participant={screenSharingUsers[0]} />
        ) : (
          renderParticipantsInGrid()
        )}
      </div>
      {corneredParticipant && screenSharingUsers.length === 0 && (
        <span className="absolute right-6 bottom-6 flex justify-center items-stretch max-w-[40vw] w-80 h-48 bg-[#101015] rounded-xl">
          <ParticipantTile rounded participant={corneredParticipant} />
        </span>
      )}
    </div>
  );
}

export default VideoFeedGrid;
