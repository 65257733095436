import { atom } from 'jotai';

import { ActionStore, ActionStoreAtom } from './types';

const initialValue: ActionStore = {
  title: '',
  message: '',
  options: [],
};

export const decisionModalAtom: ActionStoreAtom = atom(initialValue);

export const infoModalAtom: ActionStoreAtom = atom(initialValue);
