import React from 'react';
import cs from 'classnames';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { microbotTabAtom } from '@advisor/microbots/hooks/useMicrobot';
import { Layout } from '@advisor/design/components';

const SectionTabs: React.FC = () => {
  const [currentTab, setCurrentTab] = useAtom(microbotTabAtom);
  const { t } = useTranslation(['common', 'microbot']);

  const onTabPress = (tab: 'about' | 'howItWorks') => () => {
    setCurrentTab(tab);
  };

  return (
    <div className="flex flex-row justify-center items-center xl:hidden">
      <button
        type="button"
        onClick={onTabPress('about')}
        className={cs(
          'px-4 py-2 rounded-md text-sm leading-4 font-bold text-center min-w-[132px]',
          currentTab === 'about'
            ? 'bg-light-grey text-dark-grey-03'
            : 'text-dark-grey-025',
        )}
      >
        {t('microbot:ai-profile')}
      </button>
      <Layout.Spacer.Horizontal size="micro02" />
      <button
        type="button"
        onClick={onTabPress('howItWorks')}
        className={cs(
          'px-4 py-2 rounded-md text-sm leading-4 font-bold text-center min-w-[132px]',
          currentTab === 'howItWorks'
            ? 'bg-light-grey text-dark-grey-03'
            : 'text-dark-grey-025',
        )}
      >
        {t('common:how-it-works')}
      </button>
    </div>
  );
};

export default SectionTabs;
