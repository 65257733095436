import type { UserInfoFragment } from '@advisor/api/generated/graphql';
import type { ParseOptions } from './parseRawMessage';

export enum MessageTextSegmentWeight {
  Normal,
  Bold,
}

export type MessageTextSegment = {
  weight: MessageTextSegmentWeight;
  value: string;
};

export type MessageTextChunk = {
  type: 'text';
  key: string;
  segments: MessageTextSegment[];
};

export type MessageLinkChunk = { type: 'link'; key: string; href: string };

export type MessageChunk = MessageTextChunk | MessageLinkChunk;

// Props

export type AvatarAndNameProps = {
  user: UserInfoFragment;
};

export type LimitedVisibilityTagProps = {
  author: UserInfoFragment;
};

export type MessageContentProps = ParseOptions & {
  children: string;
};

export type MessageTextProps = {
  children: string | MessageTextSegment[];
};

export type MessageLinkProps = {
  children: string;
};
