import { atomFamily } from 'jotai/utils';
import { createScope, use } from 'bunshi';
import { atom, ExtractAtomArgs } from 'jotai';

import {
  CommentsForMilestoneDocument,
  MilestoneCommentInfoFragment,
  MilestoneCommentInfoFragmentDoc,
} from '@advisor/api/generated/graphql';
import { ephemeralAtomFamily } from '@advisor/utils/atoms';
import { atomOfQuery, atomOfFragment } from '@advisor/api/apollo';

export const MilestoneCommentScope = createScope<string | null | undefined>(
  null,
);

export function requireMilestoneCommentScope() {
  const milestoneCommentId = use(MilestoneCommentScope);

  if (!milestoneCommentId) {
    throw new Error(
      `Tried to use a molecule scoped to a Student Journey Milestone Comment without providing its scope`,
    );
  }

  return milestoneCommentId;
}

export const commentsForMilestoneAtoms = ephemeralAtomFamily(
  (milestoneId: string) => {
    const queryAtom = atomOfQuery(CommentsForMilestoneDocument, {
      milestoneId,
    });

    return atom(
      async (get) => (await get(queryAtom)).data?.commentsForMilestone.edges,
      (_get, set, ...args: ExtractAtomArgs<typeof queryAtom>) =>
        set(queryAtom, ...args),
    );
  },
);

export type MilestoneCommentSlice = {
  id: string;
  milestoneId: string;
  milestoneCategoryId: string;
  createdAt: string;
  author: { id: string };
};

export const makeUniqueKey = (commentId: string) =>
  `milestoneComment_${commentId}`;

export const milestoneCommentAtoms = atomFamily((commentId: string) => {
  const fragmentAtom = atomOfFragment<MilestoneCommentInfoFragment>(() => ({
    fragment: MilestoneCommentInfoFragmentDoc,
    fragmentName: 'MilestoneCommentInfo',
    from: {
      __typename: 'MilestoneComment',
      id: commentId,
    },
    optimistic: true,
  }));

  return atom((get) => get(fragmentAtom).result);
});
