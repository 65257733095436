import { useCallback, useMemo } from 'react';
import z from 'zod';

import { useMe } from '@advisor/api/me';
import { Role } from '@advisor/api/user';
import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { useChatRooms } from '@advisor/chat/ChatRoomList';

const AppDownloadPopupLocation = new UserMetadataLocation(
  'chat.appDownloadPopup',
  z.boolean(),
);

const useDownloadAppModal = () => {
  const [hide, setHide] = useUserMetadata(AppDownloadPopupLocation);
  const me = useMe();

  const { chatRooms } = useChatRooms();

  const hasActiveChatRoom = useMemo(() => {
    return Role.isSingleChatUser(me) ? chatRooms.length > 0 : true;
  }, [me, chatRooms.length]);

  const onClose = useCallback(() => setHide(true), [setHide]);

  return {
    visible: me?.id ? !hide && hasActiveChatRoom : false,
    onClose,
  };
};

export default useDownloadAppModal;
