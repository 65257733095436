import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import { useMolecule } from 'bunshi/react';

import { useIndicateTypingMutation } from '@advisor/api/generated/graphql';
import useThrottledFunction from '@advisor/utils/hooks/useThrottledFunction';
import MessageInputMolecule from './messageInputMolecule';
import { HookParams } from './types';

const TYPING_PING_INTERVAL_MS = 3000;

export default function useTypingIndicator({ chatRoomId }: HookParams) {
  const [indicateTypingMutation] = useIndicateTypingMutation();
  const { messageInputAtom } = useMolecule(MessageInputMolecule);
  const message = useAtomValue(messageInputAtom);
  const chatRoomIdRef = useRef(chatRoomId);

  useEffect(() => {
    chatRoomIdRef.current = chatRoomId;
  }, [chatRoomId]);

  const throttledTypingRef = useThrottledFunction(() => {
    indicateTypingMutation({
      variables: { chatRoomId: chatRoomIdRef.current },
    }).catch();
  }, TYPING_PING_INTERVAL_MS);

  useEffect(() => {
    if (message.length > 0) {
      throttledTypingRef();
    }
  }, [message, throttledTypingRef]);
}
