import {
  DownloadContentAsFileFunction,
  DownloadContentAsFileResult,
} from './types';

const downloadContentAsFile: DownloadContentAsFileFunction = (
  filename,
  content,
  options,
) => {
  const { fileType = 'plain-text', charset = 'utf-8' } = options ?? {};
  const element = document.createElement('a');

  const webDataType = (() => {
    if (fileType === 'csv') {
      return 'text/csv';
    }

    return 'text/plain';
  })();

  element.setAttribute(
    'href',
    `data:${webDataType};charset=${charset},${encodeURIComponent(content)}`,
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

  return DownloadContentAsFileResult.Success;
};

export default downloadContentAsFile;
