import React from 'react';

import { Icon } from '@advisor/design/components';
import { Spacing } from '@advisor/design/styles';

import { ErrorMessageProps } from './types';
import FadeIn from './FadeIn';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <FadeIn show={!!message}>
      <div className="flex flex-row gap-2 text-negative items-center">
        <Icon name="Warning" className="flex-none" size={Spacing.small01} />
        <p className="text-xs font-medium">{message}</p>
      </div>
    </FadeIn>
  );
};

export default ErrorMessage;
