import React from 'react';
import cs from 'classnames';

import {
  Icon,
  ChevronIcon,
  DeprecatedButton,
} from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import { useLanguage } from '@advisor/language';
import { ButtonIconWrapper } from './systemMessageComponents';
import { MessageButtonProps } from './types';

const MessageButton: React.FC<MessageButtonProps> = (props) => {
  const {
    label,
    onPress,
    showGlobeIcon = true,
    border = true,
    fontBold = true,
    opacity = false,
  } = props;

  const { isRTL } = useLanguage();

  const renderIcon = () => {
    return (
      <ButtonIconWrapper>
        <Icon name="GlobeGradient" size={24} />
      </ButtonIconWrapper>
    );
  };

  return (
    <DeprecatedButton.Large
      label={label}
      onPress={onPress}
      variant="transparent"
      style={{
        padding: Spacing.tiny,
        gap: Spacing.micro02,
        height: 'auto',
        borderWidth: border ? 1 : 0,
        borderColor: Colors.grey,
        ...(!showGlobeIcon && {
          justifyContent: 'space-between',
        }),
      }}
      className={cs(
        'flex gap-3 w-full h-auto items-center border-grey px-4 py-2',
        !border && '!border-none',
      )}
      labelClassName={cs(
        'w-full text-sm font-normal break-all',
        fontBold && 'font-semibold',
      )}
      labelStyle={{
        width: showGlobeIcon ? '70%' : '90%',
        flexWrap: 'wrap',
        fontWeight: fontBold ? '600' : '400',
        textAlign: isRTL ? 'right' : 'left',
      }}
      Icon={showGlobeIcon ? renderIcon : undefined}
      opacity={opacity}
    >
      <ChevronIcon size={24} color={Colors.darkGrey03} />
    </DeprecatedButton.Large>
  );
};

export default MessageButton;
