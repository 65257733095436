import { TypeOptions } from 'i18next';
import z, { SomeZodObject } from 'zod';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEvent } from '@advisor/utils/hooks';
import { FormInputs } from './types';

/**
 * @param schema A Zod schema describing valid form data
 * @param defaultValues The initial values for each field
 * @returns .inputs - a map of props for each field's input
 * @returns .validateAll - checks the validity of all fields and populates errors, and returns either valid data or `null`.
 *
 * For custom error messages see @see{ErrorMsg}
 */
function useForm<T extends SomeZodObject>(
  schema: T,
  defaultValues: z.input<T>,
  labels: { [key in keyof T['shape']]: string },
  errorNamespaces: (keyof TypeOptions['resources'])[] = [],
) {
  const { t } = useTranslation(['common', ...errorNamespaces]);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState<
    Partial<Record<keyof T['shape'], string | undefined>>
  >({});

  const parseError = useEvent((fieldName: keyof T['shape'], raw: string) => {
    const label = labels[fieldName] ?? 'This field';

    if (!!raw && raw.startsWith('i18n:')) {
      return t(raw.substring('i18n:'.length), {
        defaultValue: 'common:{{fieldName}}-is-invalid',
        fieldName: label,
      });
    }

    return t('common:{{fieldName}}-is-invalid', { fieldName: label });
  });

  const validate = useEvent((prop: keyof T['shape']) => {
    setErrors((prev) => ({
      ...prev,
      [prop]: (() => {
        const result = schema.shape[prop as string].safeParse(values[prop]);

        if (result.success) {
          return undefined;
        }

        return parseError(prop, result.error.message);
      })(),
    }));
  });

  const onChange = useEvent(
    <K extends keyof T['shape']>(prop: K, value: z.input<T>[K]) => {
      setErrors((prev) => ({
        ...prev,
        [prop]: undefined,
      }));

      setValues((prev) => ({
        ...prev,
        [prop]: value,
      }));
    },
  );

  const onBlur = useEvent((prop: keyof T['shape']) => {
    validate(prop);
  });

  const inputs = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(schema.shape).map((prop: keyof T['shape']) => [
          prop,
          {
            value: values[prop],
            errorMessage: errors[prop] ?? undefined,
            onChange: (newValue: z.input<T>[typeof prop]) => {
              onChange(prop, newValue);
            },
            onBlur: () => {
              onBlur(prop);
            },
          },
        ]),
      ) as unknown as FormInputs<T>,
    [schema, values, errors, onChange, onBlur],
  );

  const validateAll = useEvent(() => {
    const result = schema.safeParse(values);

    if (!result.success) {
      setErrors(
        Object.fromEntries(
          result.error.issues.map((issue) => [
            issue.path[0],
            parseError(String(issue.path[0]), issue.message),
          ]),
        ) as Record<keyof T['shape'], string>,
      );

      return null;
    }

    return result.data as z.infer<T>;
  });

  return { inputs, validateAll };
}

export default useForm;
