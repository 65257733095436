import { produce } from 'immer';

import {
  StudentJourneyQuery,
  StudentJourneyDocument,
  StudentJourneyEventType,
  CommentsForMilestoneQuery,
  MilestonesForCategoryQuery,
  CommentsForMilestoneDocument,
  MilestonesForCategoryDocument,
  StudentJourneyEventSubscription,
  useStudentJourneyEventSubscription,
} from '@advisor/api/generated/graphql';
import { ApolloClient, SubscriptionResult } from '@apollo/client';

import { useMyId } from '@advisor/api/me';
import { useIsAuthorized } from '@advisor/api/auth/isAuthorized';
import {
  addCommentToQuery,
  removeCommentFromQuery,
} from '../CommentInput/utils';

type Updater = (
  client: ApolloClient<object>,
  data: SubscriptionResult<StudentJourneyEventSubscription>['data'],
) => void;

const handleCategoryChange: Updater = (client, data) => {
  const { studentJourneyEvent } = data ?? {};

  if (!studentJourneyEvent) {
    return;
  }

  // Handle only category create and delete events,
  // update events are handled by the apollo automatically
  client.cache.updateQuery<StudentJourneyQuery>(
    {
      query: StudentJourneyDocument,
      variables: { chatRoomId: studentJourneyEvent.chatRoomId },
    },
    (query) => {
      return produce(query, (draft) => {
        const { milestoneCategory, eventType } = studentJourneyEvent;

        if (!draft || !milestoneCategory) {
          return;
        }

        const { milestoneCategories } = draft.studentJourney;

        if (eventType === StudentJourneyEventType.CategoryCreated) {
          const idx = milestoneCategories.findIndex(
            (c) => c.id === milestoneCategory.id,
          );

          if (idx === -1) {
            draft.studentJourney.milestoneCategories.push(milestoneCategory);
          }
        }

        if (eventType === StudentJourneyEventType.CategoryDeleted) {
          draft.studentJourney.milestoneCategories = milestoneCategories.filter(
            (c) => c.id !== milestoneCategory.id,
          );
        }
      });
    },
  );
};

const handleMilestoneChange: Updater = (client, data) => {
  const { studentJourneyEvent } = data ?? {};

  if (!studentJourneyEvent) {
    return;
  }

  // Handle only milestone create and delete events,
  // update events are handled by the apollo automatically
  client.cache.updateQuery<MilestonesForCategoryQuery>(
    {
      query: MilestonesForCategoryDocument,
      variables: {
        milestoneCategoryId:
          studentJourneyEvent.milestoneCategory?.id ??
          studentJourneyEvent.milestone?.milestoneCategoryId,
      },
    },
    (query) => {
      return produce(query, (draft) => {
        const { milestone, eventType } = studentJourneyEvent;

        if (!draft || !milestone) {
          return;
        }

        const { milestonesForCategory } = draft;

        if (eventType === StudentJourneyEventType.MilestoneCreated) {
          const milestoneIdx = milestonesForCategory.findIndex(
            (m) => m.id === milestone.id,
          );

          if (milestoneIdx === -1) {
            draft.milestonesForCategory.push(milestone);
          }
        }

        if (eventType === StudentJourneyEventType.MilestoneDeleted) {
          draft.milestonesForCategory = milestonesForCategory.filter(
            (m) => m.id !== milestone.id,
          );
        }
      });
    },
  );
};

const handleCommentChange: Updater = (client, data) => {
  const { studentJourneyEvent } = data ?? {};

  if (!studentJourneyEvent) {
    return;
  }

  // Handle only comment create and delete events,
  // update events are handled by the apollo automatically
  client.cache.updateQuery<CommentsForMilestoneQuery>(
    {
      query: CommentsForMilestoneDocument,
      variables: {
        milestoneId:
          studentJourneyEvent.milestone?.id ??
          studentJourneyEvent.milestoneComment?.milestoneId,
      },
    },
    (query) => {
      if (!query) {
        return null;
      }

      const { milestoneComment, eventType } = studentJourneyEvent;

      if (!milestoneComment) {
        return query;
      }

      if (eventType === StudentJourneyEventType.MilestoneCommentCreated) {
        return addCommentToQuery(query, milestoneComment);
      }

      if (eventType === StudentJourneyEventType.MilestoneCommentDeleted) {
        return removeCommentFromQuery(query, milestoneComment);
      }

      return query;
    },
  );
};

const handleCategoriesReorder: Updater = (client, data) => {
  const { studentJourneyEvent } = data ?? {};

  if (!studentJourneyEvent) {
    return;
  }

  client.cache.updateQuery<StudentJourneyQuery>(
    {
      query: StudentJourneyDocument,
      variables: { chatRoomId: studentJourneyEvent.chatRoomId },
    },
    (query) => {
      return produce(query, (draft) => {
        const { milestoneCategories, eventType } = studentJourneyEvent;

        if (!draft || !milestoneCategories) {
          return;
        }

        if (eventType === StudentJourneyEventType.CategoriesReordered) {
          draft.studentJourney.milestoneCategories = milestoneCategories;
        }
      });
    },
  );
};

export default function useStudentJourneyLiveUpdate(
  chatRoomId: string | undefined,
) {
  const isAuthorized = useIsAuthorized();
  const myId = useMyId();

  useStudentJourneyEventSubscription({
    variables: {
      chatRoomId: chatRoomId ?? '',
    },
    skip: !chatRoomId || !isAuthorized || !myId,
    onData: ({ client, data: { data } }) => {
      handleCommentChange(client, data);
      handleCategoryChange(client, data);
      handleMilestoneChange(client, data);
      handleCategoriesReorder(client, data);
    },
  });
}
