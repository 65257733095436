import React from 'react';
import cs from 'classnames';

import { ChatRoomStatusInfoFragment } from '@advisor/api/generated/graphql';
import { StatusIcon } from '@advisor/design/components';

interface StatusStackProps {
  focusedStatus?: ChatRoomStatusInfoFragment | null;
}

const StatusStack: React.FC<StatusStackProps> = ({ focusedStatus }) => (
  <span className={cs('relative text-left h-2', focusedStatus ? 'w-3' : 'w-4')}>
    <StatusIcon
      className={cs(
        'absolute top-0 transition-all ease-out text-dark-grey-02',
        focusedStatus ? 'ltr:left-0 rtl:right-0' : 'ltr:left-2 rtl:right-0',
      )}
    />
    <StatusIcon
      className={cs(
        'absolute top-0 transition-all ease-out text-yellow-dark',
        focusedStatus ? 'ltr:left-0 rtl:right-0' : 'ltr:left-1 rtl:right-1',
      )}
    />
    <StatusIcon
      className={cs(
        'absolute top-0 ltr:left-0 transition-colors',
        focusedStatus ? 'rtl:right-0' : 'transition-all rtl:right-2',
        !focusedStatus && 'text-rose-secondary-dark',
      )}
      color={focusedStatus ? focusedStatus.color ?? '#000000' : undefined}
    />
  </span>
);

export default StatusStack;
