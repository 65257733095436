import { createContext } from 'react';

import type { VideoRoomAPI } from '../types';

/**
 * Always contains the Membrane implementation of the video room API.
 *
 * Used specifically to initialize the API with a specific url and token,
 * since that happens in the same event loop cycle that the provider is selected.
 */
const StaticMembraneAPIContext = createContext<VideoRoomAPI | null>(null);

export default StaticMembraneAPIContext;
