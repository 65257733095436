import { useMemo } from 'react';

import type { AdvertisementInfoFragment } from '@advisor/api/generated/graphqlTypes';

function useParsedImpressionTagUri(
  advert: AdvertisementInfoFragment | null | undefined,
) {
  return useMemo(
    // capture the timestamp on mount
    () => advert?.impressionTagUri?.replace('[timestamp]', `${Date.now()}`),
    [advert?.impressionTagUri],
  );
}

export default useParsedImpressionTagUri;
