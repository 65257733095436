import { clientAtom, refetchQueriesOrIgnore } from '@advisor/api/apollo';
import {
  CommentsForMilestoneDocument,
  MilestoneCategoryDocument,
  MilestoneDocument,
  MilestonesForCategoryDocument,
  StudentJourneyDocument,
} from '@advisor/api/generated/graphqlTypes';
import { actionAtom } from '@advisor/utils/atoms';

type ContentSpace = 'messages' | 'task-organiser';

// TODO: Figure out a way to reverse this dependency, because this is very easy to overlook once we add new
//       translatable content. Additionally, this refetches content from all chatRooms, not only from
//       from the one that requires a refetch.

const refetchTranslatableContentAtom = actionAtom(
  // NOTE: Making the setter async so that the refetches happen in the next transition.
  async ({ get }, spaces: ContentSpace[] | 'all') => {
    const client = await get(clientAtom);
    refetchQueriesOrIgnore(client, [
      // NOTE: Right now, refetching the 'messages' space is done in the useLatestMessages hook.
      ...(spaces === 'all' || spaces.includes('task-organiser')
        ? [
            //
            // categories
            StudentJourneyDocument,
            MilestoneCategoryDocument,
            //
            // milestones
            MilestonesForCategoryDocument,
            MilestoneDocument,
            CommentsForMilestoneDocument,
          ]
        : []),
    ]);
  },
);

export default refetchTranslatableContentAtom;
