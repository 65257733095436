import { ValueOrReturnType } from '@advisor/utils/typeUtils';

export enum RecordingRequestState {
  IDLE = 'idle',
  PENDING = 'pending',
}

export enum RecordingConsentState {
  NOT_GIVEN_YET = 'not-given-yet',
  AWAITING_DECISION = 'awaiting-decision',
  DENIED = 'denied',
  GIVEN = 'given',
}

export const RoomRecordingStates = {
  NOT_IN_CALL: { type: 'not-in-call' },
  NOT_RECORDING: { type: 'not-recording' },
  STARTING: { type: 'starting' },
  RECORDING: (startDate: string) => ({ type: 'recording' as const, startDate }),
  STOPPING: { type: 'stopping' },
  /**
   * The meeting is no longer being recorded, but cannot be started yet
   * due to processing.
   */
  PROCESSING: { type: 'processing' },
  /**
   * TODO: This is a temporary status, added only until Daily.co fully supports
   */
  ENDED: { type: 'ended' },
} as const;

export type RoomRecordingState =
  | {
      [K in keyof typeof RoomRecordingStates]: ValueOrReturnType<
        (typeof RoomRecordingStates)[K]
      >;
    }[keyof typeof RoomRecordingStates];

export type UseNavigateOutOfRoomType = () => (
  leaveCall?: boolean,
) => Promise<void>;
