import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';

import {
  useCallState,
  useConnect,
  useDisconnect,
  useIdleTimers,
  useOnConsentDenied,
} from '@advisor/videoCall';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { useChatRoomSignedCookies } from '@advisor/api/auth';
import { useAuthNavigationGuard } from '@advisor/utils/navigation/web';
import { Toaster } from '@advisor/design/components/Toast';
import { useMeQuery } from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';
import {
  Toolbar,
  VideoFeedGrid,
  ParticipantList,
} from '@advisor/videoCall/components';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { Scope } from '@advisor/api/scope';
import { useMarkVideoRoomPresence } from '@advisor/videoCall/Presence';
import { ScreenShareStatusBanner } from '@advisor/videoCall/ScreenShare';
import { useEvent } from '@advisor/utils/hooks';
import VideoCallNavbar, { VideoCallNavbarShell } from './VideoCallNavbar';

const VideoCallPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { t } = useTranslation('common');
  const { chatRoomId } = useActiveChatRoom('required');
  const { loading, data: { me } = { me: undefined } } = useMeQuery();
  const callState = useCallState();
  const connect = useConnect();
  const disconnect = useDisconnect();
  const navigate = useNavigate();
  const showModal = useShowModal();
  const modalShown = useRef(false);

  useAuthNavigationGuard(
    Scope.videoChatAccess(chatRoomId),
    `/chat/${chatRoomId}`,
  );

  useChatRoomSignedCookies(chatRoomId);
  useMarkVideoRoomPresence();

  const handleHangUp = useEvent(async () => {
    navigate(`/chat/${chatRoomId}`);
    await disconnect();
  });

  useIdleTimers(handleHangUp);
  useOnConsentDenied(handleHangUp);

  const showJoinConfirmModal = useEvent(() => {
    if (!callState && !modalShown.current && connect) {
      modalShown.current = true;
      showModal
        .confirm({
          title: t('would-you-like-to-join-a-video-room'),
          message: t(
            'you-are-about-to-join-a-video-room-do-you-want-to-proceed',
          ),
          variant: 'neutral',
        })
        .then((value) => {
          if (value) {
            connect(chatRoomId);
          } else {
            navigate(`/chat/${chatRoomId}`);
          }
        });
    }
  });

  useEffect(() => {
    if (!callState) {
      showJoinConfirmModal();
    }
  }, [connect, showJoinConfirmModal, callState]);

  if (!loading && me === null) {
    return <Navigate to="/create-profile" />;
  }

  return (
    <div className="relative flex flex-row h-dynamic-screen max-h-dynamic-screen min-h-0 items-stretch">
      {/* ^ 'relative' class necessary for proper <Toaster /> positioning */}
      <div className="h-full grow flex flex-col bg-dark-grey-03">
        <VideoCallNavbar />
        {callState !== null && (
          <>
            <ScreenShareStatusBanner />
            <div className="grow flex pt-6 px-6">
              {/* Negative margin hack to ensure that participants list algins with chat-sidebar */}
              <div className="empty:hidden w-chat-sidebar-xl 2xl:w-chat-sidebar-2xl px-6 grid justify-items-stretch rtl:-mr-6 ltr:-ml-6">
                <ParticipantList />
              </div>
              <VideoFeedGrid />
            </div>
            <div className="shrink-0 grow-0">
              <Toolbar onLeave={handleHangUp} />
            </div>
          </>
        )}
      </div>
      <Toaster />
    </div>
  );
};

function VideoCallPageFallback() {
  return (
    <div className="relative flex flex-row h-dynamic-screen max-h-dynamic-screen min-h-0 items-stretch">
      {/* ^ 'relative' class necessary for proper <Toaster /> positioning */}
      <div className="h-full grow flex flex-col bg-dark-grey-03">
        <VideoCallNavbarShell />
      </div>
      <Toaster />
    </div>
  );
}

export default suspenseHOC(VideoCallPage, <VideoCallPageFallback />);
