import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, SearchInput } from '@advisor/design/components';
import UserList from '@advisor/ui/components/UserList';
import useChatRoomMembers from '@advisor/chat/hooks/useChatRoomMembers';
import SideModal from './SideModal';

const ChatMembersModal: React.FC = () => {
  const { t } = useTranslation('common');

  const { members, query, onSetQuery } = useChatRoomMembers();

  return (
    <Suspense
      fallback={
        <div className="pt-20 flex flex-row justify-center w-full">
          <Loader />
        </div>
      }
    >
      <SideModal.Header backButton>
        <SideModal.Title title={t('view-all-members')} />
      </SideModal.Header>
      <SideModal.Body padding={false}>
        <div className="p-6">
          <div className="mb-6">
            <SearchInput
              className="py-2 ltr:pl-11 rtl:pr-11 placeholder:text-gray-01 placeholder:font-normal text-sm"
              onChange={onSetQuery}
              value={query}
              placeholder={t('search')}
            />
          </div>
          <UserList
            users={members}
            title={t('conversation-members')}
            showCount
            variant="grey"
          />
        </div>
      </SideModal.Body>
    </Suspense>
  );
};

export default ChatMembersModal;
