import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { actionAtom } from '@advisor/utils/atoms';
import { translationAtoms } from '@advisor/language';
import { showToast } from '@advisor/design/components/Toast';
import { ShowModal } from '@advisor/design/components/ActionModal';
import { ScreenShareStatus, useVideoRoomAPI } from '../VideoRoomAPI';

const promptUserForConsentAtom = actionAtom(async ({ get, set }) => {
  const t = await get(translationAtoms('common'));

  const decision = await ShowModal.decide(
    { get, set },
    {
      title: t('share-screen'),
      message: t(
        'participants-will-have-visibility-to-everything-displayed-on-your-screen-including-notifications',
      ),
      options: [
        {
          key: 'start-sharing' as const,
          label: t('start-sharing'),
          variant: 'positive',
        },
        {
          key: 'cancel' as const,
          label: t('cancel'),
          variant: 'neutral',
        },
      ],
      dismissible: true,
    },
  );

  return decision === 'start-sharing';
});

function useScreenShare() {
  const { screenShareStatus, startScreenShare, stopScreenShare } =
    useVideoRoomAPI() ?? {};
  const promptUserForConsent = useSetAtom(promptUserForConsentAtom);

  const state = screenShareStatus ?? ScreenShareStatus.NotSharing;

  const setState = useCallback(
    async (action: 'share' | 'unshare') => {
      if (!startScreenShare || !stopScreenShare) {
        showToast({
          messageI18Key: 'oops-something-went-wrong',
          variant: 'rose',
        });
        return;
      }

      if (action === 'share') {
        const canScreenShare = await promptUserForConsent();

        if (!canScreenShare) {
          return;
        }

        startScreenShare();
      }

      if (action === 'unshare') {
        stopScreenShare();
      }
    },
    [startScreenShare, stopScreenShare, promptUserForConsent],
  );

  return [state, setState] as [typeof state, typeof setState];
}

export default useScreenShare;
