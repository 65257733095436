import { noop } from 'lodash-es';
import React, { Fragment, memo } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { BareModalProps } from './types';

const BareModal: React.FC<BareModalProps> = ({
  visible,
  onClose,
  children,
  appear = false,
}) => (
  <Transition show={visible} appear={appear} as={Fragment}>
    <Dialog static onClose={onClose ?? noop}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={onClose}
        />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
      >
        {children}
      </Transition.Child>
    </Dialog>
  </Transition>
);

export default memo(BareModal);
