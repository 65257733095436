import cs from 'classnames';
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Icon } from '@advisor/design/components';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
}

const DeleteAccountModal = ({
  isOpen,
  children,
  onClose,
  className,
}: Props) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose || (() => {})}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-in duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className="fixed inset-0 bg-black bg-opacity-50 z-20"
            onClick={onClose}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="pointer-events-none fixed inset-0 flex justify-center items-end md:items-center z-30">
            <section
              className={cs(
                'pointer-events-auto relative bg-white w-full rounded-t-lg md:rounded-lg md:max-w-[438px]',
                className,
              )}
            >
              <header className="flex justify-end items-center p-6 pb-0">
                <button
                  type="button"
                  className="text-dark-grey-01 hover:text-dark-grey-03 w-5 h-5 items-center justify-center outline-none focus:outline-none"
                  onClick={onClose}
                >
                  <Icon name="X" />
                </button>
              </header>
              <div className="flex items-center justify-center">{children}</div>
            </section>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default DeleteAccountModal;
