import React from 'react';
import cs from 'classnames';

import { AvatarSkeletonProps } from './types';

const AvatarSkeleton: React.FC<AvatarSkeletonProps> = ({ className, size }) => (
  <div
    className={cs('bg-light-grey rounded-full', className)}
    style={{ width: size, height: size }}
  />
);

export default AvatarSkeleton;
