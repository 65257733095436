import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { Feature } from '@advisor/api/feature';
import { PermissionGate, Scope } from '@advisor/api/scope';
import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import {
  Layout,
  Avatar,
  Skeleton,
  DeprecatedButton,
} from '@advisor/design/components';
import SnapList from '@advisor/design/components/SnapList';
import withSeparators from '@advisor/utils/withSeparators';
import suspenseHOC from '@advisor/utils/suspenseHOC';
import { useMicrobot } from '../../hooks';
import { MicrobotOnboardingMessageProps } from './types';
import useContent, { ActionType } from './useContent';
import MemoryBankInfoModal from './MemoryBankInfoModal';

const MicrobotOnboardingMessage: React.FC<MicrobotOnboardingMessageProps> = ({
  personality,
}) => {
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const microbot = useMicrobot(personality, 'required');

  const onHandleAction = (actionType: ActionType) => () => {
    if (actionType === ActionType.OpenMicrobotInfo) {
      navigate(
        `/community/microbot/${(
          personality ?? MicrobotPersonality.Astro
        ).toLocaleLowerCase()}`,
      );
      return;
    }

    if (actionType === ActionType.OpenMemoryBankInfo) {
      setModalVisible(true);
    }
  };

  const { title, description, buttons } = useContent(MicrobotPersonality.Astro);

  return (
    <>
      <div className="mb-4 max-w-chat-width bg-white p-6 text-center mx-auto w-full rounded-md">
        <div className="flex justify-center">
          {microbot.avatarUrl ? (
            <Avatar bordered={false} avatarUrl={microbot.avatarUrl} size={60} />
          ) : (
            <Skeleton.Avatar size={60} />
          )}
        </div>
        <Layout.Spacer.Vertical size={20} />
        <h3 className="font-sora text-dark-grey-03 font-bold">{title}</h3>
        <Layout.Spacer.Vertical size="micro" />
        <div
          className="mx-auto fon-outfit text-sm"
          style={{ maxWidth: '400px' }}
        >
          {description}
        </div>
        <Layout.Spacer.Vertical size="tiny" />
        <div className="flex flex-row justify-center">
          <SnapList.Root>
            {withSeparators(
              buttons,
              (index: number) => (
                <Layout.Spacer.Horizontal key={`sep-${index}`} size="micro" />
              ),
              (button) => (
                <React.Fragment key={button.label}>
                  {button.action === ActionType.OpenMemoryBankInfo ? (
                    <PermissionGate scope={Scope.feature(Feature.MemoryBank)}>
                      {() => (
                        <SnapList.Item index={0}>
                          <DeprecatedButton.Outline
                            label={button.label}
                            variant={button.variant}
                            onPress={onHandleAction(button.action)}
                          />
                        </SnapList.Item>
                      )}
                    </PermissionGate>
                  ) : (
                    <SnapList.Item index={0}>
                      <DeprecatedButton.Outline
                        label={button.label}
                        variant={button.variant}
                        onPress={onHandleAction(button.action)}
                      />
                    </SnapList.Item>
                  )}
                </React.Fragment>
              ),
            )}
          </SnapList.Root>
        </div>
      </div>
      <MemoryBankInfoModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};

export default suspenseHOC(MicrobotOnboardingMessage, null);
