import dayjs from 'dayjs';
import utcExtension from 'dayjs/plugin/utc';
import timezoneExtension from 'dayjs/plugin/timezone';

import { TimezoneName } from '@advisor/utils/country/timezonesByCountryCodes';

dayjs.extend(utcExtension);
dayjs.extend(timezoneExtension);

export default function getTimezone(): TimezoneName {
  return dayjs.tz.guess() as TimezoneName;
}
