import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '@advisor/design/components';
import useMilestoneStatus from './useMilestoneStatus';
import { MilestoneStatusProps } from './types';

const MilestoneStatus: React.FC<MilestoneStatusProps> = (props) => {
  const { className } = props;
  const status = useMilestoneStatus(props);
  const { t } = useTranslation('task-organiser');

  if (status.status === 'still') {
    return <div className={className} />;
  }

  const label = (() => {
    switch (status.status) {
      case 'complete':
        return t('completed');
      case 'pending':
        return t('pending-review');
      case 'due':
        if (status.notificationTime === 'NIL') {
          return null;
        }

        return t('due-datetime', { datetime: status.notificationTime });
      case 'overdue':
        if (status.notificationTime === 'NIL') {
          return null;
        }

        return t('overdue-datetime', { datetime: status.notificationTime });
      default:
        return null;
    }
  })();

  const textClassName = (() => {
    switch (status.status) {
      case 'complete':
        return 'text-dark-grey-02';
      case 'pending':
        return 'text-primary';
      case 'due':
        return 'text-positive';
      case 'overdue':
        return 'text-negative';
      default:
        return '';
    }
  })();

  if (!label) {
    return null;
  }

  return (
    <div
      className={cs(
        'flex flex-row gap-3 items-center',
        textClassName,
        className,
      )}
    >
      <Icon name="Clock" size={12} />
      <p className="min-w-0 truncate flex-1">{label}</p>
    </div>
  );
};

export default MilestoneStatus;
