import { Outlet, Navigate, useOutlet, useNavigate } from 'react-router';
import { ErrorBoundary } from '@sentry/react';

import { Toaster } from '@advisor/design/components/Toast';
import { useMeQuery } from '@advisor/api/generated/graphql';
import MilestoneCategoryEditModal from '@advisor/journey/components/MilestoneCategoryEditModal';
import ReassignStudentModal from '@advisor/chat/ReassignStudentModal';
import DownloadMobileAppModal from 'src/components/organisms/modals/DownloadMobileAppModal';
import ChatSidebar from 'src/components/organisms/ChatSidebar';
import SideModal from 'src/components/organisms/modals/SideModal';
import ChatRoomHeader from 'src/components/molecules/ChatRoomHeader';
import useChatIdParam from 'src/hooks/useChatIdParam';
import Chat from 'src/components/organisms/Chat';

const ChatPage = () => {
  const { loading, data: { me } = { me: undefined } } = useMeQuery();

  if (!loading && me === null) {
    return <Navigate to="/create-profile" />;
  }

  return (
    // When user try to enter chat with microbot and feature flag is disabled redirect to 404 to avoid app crash
    <ErrorBoundary fallback={<Navigate to="/404" />}>
      <div className="flex flex-row h-dynamic-screen max-h-dynamic-screen min-h-0 items-stretch">
        <ChatSidebar />
        <div className="relative h-full grow flex flex-col">
          {/* ^ 'relative' class necessary for proper <Toaster /> positioning */}
          <ChatRoomHeader className="h-header-height shrink-0" />
          <Chat className="grow" />
          <Toaster />
        </div>
        <Outlet />
      </div>
      <MilestoneCategoryEditModal />
      <DownloadMobileAppModal />
      <ReassignStudentModal scope="global" />
    </ErrorBoundary>
  );
};

const ChatPageSideModal = () => {
  const outlet = useOutlet();
  const chatId = useChatIdParam();
  const navigate = useNavigate();

  const close = () => navigate(chatId ? `/chat/${chatId}` : '/chat');

  return (
    <SideModal.Root show={!!outlet} onClose={close}>
      <Outlet />
    </SideModal.Root>
  );
};

export default {
  Page: ChatPage,
  SideModal: ChatPageSideModal,
};
