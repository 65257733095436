import { useMemo } from 'react';

import { useJoinRequestsQuery } from '@advisor/api/generated/graphql';

const useJoinRequests = (chatRoomId: string, limit: number) => {
  const { data, error, loading } = useJoinRequestsQuery({
    variables: {
      chatRoomId,
      limit,
    },
  });

  const joinRequests = useMemo(() => {
    if (!error && !loading && data) {
      return data.joinRequests;
    }

    return [];
  }, [data, error, loading]);

  return joinRequests;
};

export default useJoinRequests;
