/* eslint-disable import/prefer-default-export */
import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';

export const canArchiveChatRoomScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.some([Scope.verifiedAdvisor, Scope.serviceProvider]),
    Scope.studentConversation(chatRoomId),
  ]),
);
