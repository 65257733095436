import React from 'react';
import { useTranslation } from 'react-i18next';
import { times } from 'lodash-es';

import { Skeleton } from '@advisor/design/components';
import SideModal from '../SideModal';

const TextFallback: React.FC<{ size: number }> = ({ size }) => {
  return (
    <>
      {times(size, (i) => (
        <div key={i} className="w-full rounded">
          <Skeleton.Text fontSize={78} lineHeight={78} />
        </div>
      ))}
    </>
  );
};

const Fallback: React.FC = () => {
  const { t } = useTranslation('task-organiser');

  return (
    <SideModal.Default
      backButton
      scroll
      title={t('edit-customer-profile')}
      className="flex flex-col gap-4"
    >
      <TextFallback size={9} />
      <div className="w-full rounded-sm">
        <Skeleton.Text fontSize={18} lineHeight={18} />
      </div>
      <TextFallback size={4} />
      <div className="w-full rounded-sm">
        <Skeleton.Text fontSize={18} lineHeight={18} />
      </div>
      <TextFallback size={2} />
    </SideModal.Default>
  );
};

export default Fallback;
