import { useScreenSharingUsers } from './ScreenShare';
import { useConnectedParticipants } from './Participants';

export enum VideoRoomAdvertLocation {
  Banner,
  UnderAvatar,
}

export default function useVideoRoomAdvertLocation() {
  const connectedParticipantsCount = useConnectedParticipants().length;
  const screenSharingUsers = useScreenSharingUsers();
  const shouldShowBannerAdvert =
    connectedParticipantsCount > 2 || screenSharingUsers.length > 0;

  return shouldShowBannerAdvert
    ? VideoRoomAdvertLocation.Banner
    : VideoRoomAdvertLocation.UnderAvatar;
}
