import { derive } from '@advisor/utils/atoms';
import { FeatureFlagsDocument } from '../generated/graphql';
import { atomOfQuery } from '../apollo';
import type { FeatureKey } from './types';

/**
 * For internal use, better to use the wrappers, or create new ones.
 */
const queryAtom = atomOfQuery(FeatureFlagsDocument);

const enabledFeaturesAtom = derive(
  [queryAtom],
  ({ data }) =>
    (data?.featureFlags ?? {}) as Partial<Record<FeatureKey, boolean>>,
);

export default enabledFeaturesAtom;
