import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
};

const ParagraphLoader = ({ className, children }: Props) => {
  return (
    <div
      className={classNames(
        'bg-grey animate-pulse h-line-height w-full rounded',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ParagraphLoader;
