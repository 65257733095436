import React, { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@advisor/design/components';
import Tooltip from '@advisor/design/components/Tooltip';
import useMinimizeVideoCallTooltip from './useMinimizeVideoCallTooltip';

const MinimizeVideoCallTooltip = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useMinimizeVideoCallTooltip();
  const { t } = useTranslation('common');

  const onHide = () => {
    setShow(false);
  };

  return (
    <Tooltip.Root>
      {children}
      <Tooltip.Content
        variant="primary"
        /* Match display moment with mobile version */
        visible={show}
        position={Tooltip.Position.Below}
      >
        <div className="flex flex-row gap-4 text-white max-w-full">
          <p className="whitespace-pre-line font-medium text-xs sm:text-base">
            {t('tap-here-to-view-your-conversation-space')}
          </p>
          <button type="button" className="hover:opacity-50" onClick={onHide}>
            <Icon name="X" className="w-8 h-8" />
          </button>
        </div>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

export default memo(MinimizeVideoCallTooltip);
