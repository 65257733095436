import { useEffect } from 'react';
import { atom, useSetAtom, Atom } from 'jotai';

/**
 * Helps keep track of the existence of arbitrary content.
 *
 * `isMountedAtom` is a boolean atom that holds the value `true` if
 * at least one component using the `useMark` hook is mounted.
 *
 * @returns `[useMark, isMountedAtom]`
 */
function createMountTracker(): [() => void, Atom<boolean>] {
  const numberOfMountsAtom = atom(0);

  const useMark = () => {
    const setMountsCount = useSetAtom(numberOfMountsAtom);

    useEffect(() => {
      setMountsCount((prev) => prev + 1);
      return () => setMountsCount((prev) => prev - 1);
    }, [setMountsCount]);
  };

  return [useMark, atom((get) => get(numberOfMountsAtom) > 0)];
}

export default createMountTracker;
