/* eslint-disable import/prefer-default-export */
import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';
import { Feature } from '@advisor/api/feature';

export const canAccessPrivateNotes = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.feature(Feature.PrivateNotes),
    Scope.verifiedAdvisor,
    Scope.studentConversation(chatRoomId),
    Scope.doesChatHaveLeadingAdvisor(chatRoomId),
  ]),
);
