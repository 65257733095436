import cs from 'classnames';
import React, { Suspense } from 'react';
import { ScopeProvider } from 'bunshi/react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  useActiveChatRoom,
  useChatRoomRepresentative,
} from '@advisor/api/chatRoom';
import { Feature } from '@advisor/api/feature';
import { AddMicrobotMemoryScope } from '@advisor/microbots/api';
import { useCallState, useConnect } from '@advisor/videoCall';
import { PermissionGate, PermissionSelect, Scope } from '@advisor/api/scope';
import {
  VideoCallButton,
  PreferencesButton,
} from '@advisor/chat/ChatRoomHeader';
import { nullAtom } from '@advisor/utils/atoms';
import { useLanguage } from '@advisor/language';
import { useEvent } from '@advisor/utils/hooks';
import { Icon, Layout, Skeleton } from '@advisor/design/components';
import { AddMemoryButton } from '@advisor/microbots/components';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import { useMicrobot } from '@advisor/microbots/hooks';
import { MicrobotPersonality } from '@advisor/api/generated/graphqlTypes';
import useInterpolatedUsername from '@advisor/chat/hooks/useInterpolatedUsername';

type Props = {
  className?: string;
};

const AstroTitle: React.FC = () => {
  const microbot = useMicrobot(MicrobotPersonality.Astro);

  return (
    <div className="flex">
      <h3 className="font-sora text-sm font-semibold">
        {microbot?.name ?? 'Cosmo'}
      </h3>
      <Icon name="StarsSup" className="w-3 h-3 -ml-[2px]" />
    </div>
  );
};

const Title = PermissionSelect([
  {
    condition: Scope.some([Scope.verifiedAdvisor, Scope.serviceProvider]),
    Component: () => {
      const { chatRoomId, chatRoomAtom } = useActiveChatRoom() ?? {};
      const chatRoom = useAtomValue(chatRoomAtom ?? nullAtom);
      const representative = useChatRoomRepresentative(chatRoomId);
      const representativeName = useInterpolatedUsername(
        representative,
        chatRoomId,
      );

      if (!chatRoom) {
        return <Skeleton.Text fontSize={14} lineHeight={20} size="huge" />;
      }

      if (chatRoom.__typename === 'MicrobotChatRoom') {
        return <AstroTitle />;
      }

      return (
        <h3 className="font-sora text-sm font-semibold truncate">
          {representativeName}
        </h3>
      );
    },
  },
  {
    condition: Scope.some([Scope.student, Scope.familyMember]),
    Component: () => {
      const { t } = useTranslation('common');
      return (
        <h3 className="font-sora font-semibold text-sm text-secondary-dark">
          {t('host-connect')}
        </h3>
      );
    },
  },
  {
    condition: Scope.aspiringAdvisor,
    Component: () => null,
  },
  {
    condition: 'default',
    Component: () => (
      <Skeleton.Text fontSize={14} lineHeight={20} size="huge" />
    ),
  },
]);

const Members = PermissionSelect([
  {
    condition: Scope.some([
      Scope.verifiedAdvisor,
      Scope.serviceProvider,
      Scope.student,
      Scope.familyMember,
    ]),
    Component: () => {
      const { t } = useTranslation(['common', 'microbot']);
      const { chatRoomAtom } = useActiveChatRoom() ?? {};
      const chatRoom = useAtomValue(chatRoomAtom ?? nullAtom);

      if (!chatRoom?.members) {
        return <Skeleton.Text fontSize={14} lineHeight={20} size={80} />;
      }

      const subtitle =
        chatRoom.__typename === 'MicrobotChatRoom'
          ? t('microbot:personal-space-for-all-your-queries')
          : t('common:members', {
              membersCount: String(chatRoom?.members.length).padStart(2, '0'),
            });

      return (
        <p className="text-sm font-medium leading-4 text-dark-grey-025">
          {subtitle}
        </p>
      );
    },
  },
  {
    condition: Scope.aspiringAdvisor,
    Component: () => null,
  },
  {
    condition: 'default',
    Component: () => <Skeleton.Text fontSize={14} lineHeight={20} size={80} />,
  },
]);

function ChatRoomHeaderContent() {
  const navigate = useNavigate();
  const { chatRoomId } = useActiveChatRoom() ?? {};
  const callState = useCallState();
  const connect = useConnect();

  const handleOpenVideoCall = useEvent(async () => {
    if (!chatRoomId) {
      return;
    }

    if (!callState) {
      connect(chatRoomId);
    }

    navigate(`/video-call/${chatRoomId}`);
  });

  const handleOpenPreferences = useEvent(() => {
    navigate(`/chat/${chatRoomId ?? 'null'}/preferences`);
  });

  return (
    <>
      <div className="grow self-stretch flex flex-col justify-center">
        <Title />
        <Members />
      </div>
      <PermissionGate
        scope={
          chatRoomId
            ? Scope.microbotConversation(chatRoomId)
            : Scope.unavailable
        }
      >
        {() => (
          <ScopeProvider scope={AddMicrobotMemoryScope} value="chat-room">
            <AddMemoryButton.Header />
            <Layout.Spacer.Horizontal size="atomic" />
          </ScopeProvider>
        )}
      </PermissionGate>
      <PermissionGate
        scope={
          chatRoomId
            ? Scope.canSeeHeaderVideoCallButtonScope(chatRoomId)
            : Scope.unavailable
        }
      >
        {() => (
          <>
            <VideoCallButton onOpenVideoCall={handleOpenVideoCall} />
            <Layout.Spacer.Horizontal size="micro02" />
          </>
        )}
      </PermissionGate>
      <PreferencesButton onPress={handleOpenPreferences} />
    </>
  );
}

function ChatRoomHeaderSkeleton() {
  return (
    <>
      <div className="grow self-stretch flex flex-col gap-1 justify-center">
        {/* Title */}
        <Skeleton.Ping>
          <Skeleton.Text fontSize={14} lineHeight={20} size="huge" />
        </Skeleton.Ping>
        {/* Members */}
        <Skeleton.Ping>
          <Skeleton.Text fontSize={14} lineHeight={20} size={80} />
        </Skeleton.Ping>
      </div>
      {/* VideoCallButton */}
      <PermissionGate scope={Scope.feature(Feature.VideoChat)}>
        {() => (
          <div className="w-6 h-6 bg-light-grey border border-grey rounded" />
        )}
      </PermissionGate>
      <Layout.Spacer.Horizontal size="micro02" />
      {/* PreferencesButton */}
      <div className="w-6 h-6 bg-light-grey border border-grey rounded" />
    </>
  );
}

const ChatRoomHeader = ({ className }: Props) => {
  const setShowSidebar = useSetAtom(showSidebarAtom);
  const { isRTL } = useLanguage();

  return (
    <div
      className={cs(
        'bg-white flex flex-row items-center px-6 border-b border-grey',
        className,
      )}
    >
      <button
        type="button"
        className="xl:hidden hover:bg-grey w-6 h-6 rounded-full ltr:mr-6 rtl:ml-6"
        onClick={() => setShowSidebar(true)}
        data-cy="show-sidebar-button"
      >
        <Icon name={isRTL ? 'ArrowRight' : 'ArrowLeft'} className="mx-auto" />
      </button>

      <Suspense fallback={<ChatRoomHeaderSkeleton />}>
        <ChatRoomHeaderContent />
      </Suspense>
    </div>
  );
};

export default ChatRoomHeader;
