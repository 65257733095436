import { produce } from 'immer';
import { StyleProp, ViewStyle } from 'react-native';
import React, { CSSProperties, ComponentType, useMemo } from 'react';

import { useLanguage } from '@advisor/language';
import useDeepMemo from '@advisor/utils/hooks/useDeepMemo';
import type { LaxPaddingConfig, PaddingConfig, WithPadding } from './types';

const withPadding: WithPadding = <
  TProps extends { style?: CSSProperties | StyleProp<ViewStyle> },
>(
  defaultConfig?: PaddingConfig,
) => {
  return (InComponent: ComponentType<TProps>) => {
    const PaddedComponent = (props: TProps & { padding?: PaddingConfig }) => {
      const { padding = defaultConfig, style, ...otherProps } = props;

      const stableStyle = useDeepMemo(style);
      const values = useDeepMemo((padding ?? {}) as LaxPaddingConfig);
      const { isRTL } = useLanguage();

      const alteredStyle = useMemo(
        () =>
          produce(stableStyle ?? {}, (draft: CSSProperties) => {
            if (typeof values === 'number') {
              draft.paddingTop = values;
              draft.paddingBottom = values;
              draft.paddingLeft = values;
              draft.paddingRight = values;
              return;
            }

            draft.paddingTop = values.top ?? values.y;
            draft.paddingBottom = values.bottom ?? values.y;
            draft.paddingLeft =
              (isRTL ? values.end : values.start) ?? values.left ?? values.x;
            draft.paddingRight =
              (isRTL ? values.start : values.end) ?? values.right ?? values.x;
          }),
        [stableStyle, values, isRTL],
      );

      return <InComponent {...(otherProps as TProps)} style={alteredStyle} />;
    };

    return PaddedComponent;
  };
};

export default withPadding;
