import { useAtomValue } from 'jotai';

import { Advisor } from '../generated/graphql';
import { Scope } from '../scope';
import { meAtom, myIdAtom } from './atoms';

export function useMe() {
  return useAtomValue(meAtom);
}

export function useMyId() {
  return useAtomValue(myIdAtom);
}

export function useMeAdvisor() {
  const me = useMe();

  if (me && me.__typename !== 'Advisor') {
    throw new Error(
      `useMeAdvisor used with an account with role ${me.__typename}!`,
    );
  }

  return me;
}

export function useMeStudent() {
  const me = useMe();

  if (me && me.__typename !== 'User') {
    throw new Error(
      `useMeAdvisor used with an account with role ${me.__typename}!`,
    );
  }

  return me;
}

export function useMeApprovingAdvisor() {
  const me = useMe();

  if (me && (me.__typename !== 'Advisor' || !(me as Advisor).isApproving)) {
    throw new Error(
      `useMeApprovingAdvisor used with an account with role ${me.__typename}!`,
    );
  }

  return me;
}

export function useAmIAdvisor() {
  return useAtomValue(Scope.advisor);
}

export function useAmIVerifiedAdvisor() {
  return useAtomValue(Scope.verifiedAdvisor);
}

export function useAmIAspiringAdvisor() {
  return useAtomValue(Scope.aspiringAdvisor);
}

export function useAmIStudent() {
  return useAtomValue(Scope.student);
}

export function useAmIFamilyMember() {
  return useAtomValue(Scope.familyMember);
}

export function useAmIServiceProvider() {
  return useAtomValue(Scope.serviceProvider);
}

export function useAmIApprovingAdvisor() {
  return useAtomValue(Scope.approvingAdvisor);
}
