import React from 'react';

import { ChatNotificationsProps } from './types';

const ChatModifiersLazy = React.lazy(() => import('./ChatNotifications'));

const ChatNotifications: React.FC<ChatNotificationsProps> = (props) => (
  <React.Suspense fallback={null}>
    <ChatModifiersLazy {...props} />
  </React.Suspense>
);

export default {
  /**
   * Contains only components that rendered independently from their place in virtual DOM,
   * like modals, banners, etc.
   */
  ChatNotifications,
};
