import dayjs from 'dayjs';
import { useMemo } from 'react';
import utc from 'dayjs/plugin/utc';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useForm } from '@advisor/design/components/Form';
import { milestoneEditStateAtom } from './atoms';
import { getMilestoneFormSchema } from './types';

dayjs.extend(utc);

function useMilestoneForm() {
  const { t } = useTranslation('common');
  const milestoneEditState = useAtomValue(milestoneEditStateAtom);
  const editedMilestone = milestoneEditState?.milestone;

  const minDueDate = useMemo(() => {
    // min date is adjusted to student timezone on the backed side
    return dayjs().add(1, 'day').startOf('date');
  }, []);

  const formSchema = useMemo(
    () => getMilestoneFormSchema(minDueDate.toDate()),
    [minDueDate],
  );

  const formData = useForm(
    formSchema,
    {
      name: editedMilestone?.name || '',
      description: editedMilestone?.description || '',
      dueDate:
        editedMilestone?.notificationTime &&
        editedMilestone.notificationTime !== 'NIL'
          ? new Date(editedMilestone.notificationTime)
          : undefined,
    },
    {
      name: t('title'),
      description: t('description'),
      dueDate: t('due-date'),
    },
  );

  return {
    formData,
    minDueDate,
  };
}

export default useMilestoneForm;
