import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { DateFormatter } from '@advisor/language';
import { JourneyMilestoneOverDueSystemMessageInfoFragment } from '@advisor/api/generated/graphql';
import { BoldText, Message, SimpleMessage } from '../systemMessageComponents';
import { useOpenJourneyMilestone } from '../navigation';
import type { SystemMessageProps } from '../types';

const WheneverMilestoneDueDateReminder: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { t } = useTranslation('system-message');
  const data = message.data as JourneyMilestoneOverDueSystemMessageInfoFragment;

  const openMilestone = useOpenJourneyMilestone();

  const onPress = useCallback(() => {
    openMilestone(
      message.chatRoomId,
      data.milestone?.milestoneCategoryId,
      data.milestone?.id,
    );
  }, [openMilestone, message.chatRoomId, data]);

  const milestone = data.milestone?.name ?? data.metadata.milestoneName;

  const isOverdue =
    !data.milestone?.notificationTime ||
    dayjs(data.milestone.notificationTime).diff(dayjs()) <= 0;

  const date = DateFormatter.calendarShort(
    data.milestone?.notificationTime ?? '',
  );

  return (
    <SimpleMessage onPress={onPress}>
      <Trans
        t={t}
        parent={Message}
        values={{ task: milestone, date }}
        components={{ bold: <BoldText /> }}
        i18nKey={
          isOverdue
            ? 'the-task-is-now-overdue-please-complete-is-as-soon-as-possible'
            : 'the-task-is-due-on-date-please-complete-it-on-time'
        }
      />
    </SimpleMessage>
  );
};

export default memo(WheneverMilestoneDueDateReminder);
