import { atom } from 'jotai';
import type { ParseKeys } from 'i18next';

import { translationAtoms } from '@advisor/language';
import { ShowModal } from '@advisor/design/components/ActionModal';

// eslint-disable-next-line import/prefer-default-export
export const createTranslationPromptAtoms = (
  singleChoiceI18nKey: ParseKeys<('common' | 'task-organiser')[]>,
) => {
  return atom(null, async (get, set, action: 'translate' | 'undo') => {
    const t = await get(translationAtoms(['common', 'task-organiser']));

    return ShowModal.decide(
      { get, set },
      {
        title: t(
          action === 'translate'
            ? 'task-organiser:would-you-like-to-translate-the-task-organiser'
            : 'task-organiser:would-you-like-to-restore-translation-on-the-task-organiser',
        ),
        message: t(
          action === 'translate'
            ? 'task-organiser:this-will-translate-the-task-organiser-into-your-preferred-language'
            : 'task-organiser:this-will-restore-the-task-organiser-back-into-the-original-language',
        ),
        dismissible: true,
        options: [
          {
            key: 'all' as const,
            label: t('common:yes'),
            variant: 'positive',
          },
          {
            key: 'single' as const,
            label: t(singleChoiceI18nKey),
            variant: 'neutral',
          },
        ],
      },
    );
  });
};
