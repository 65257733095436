import { MicrobotPersonality } from '@advisor/api/generated/graphql';

export enum Tab {
  Configure,
  Confirm,
  ConversationAccess,
}

export interface AccessModalProps {
  visible: boolean;
  onClose: () => void;
  onConfigure: () => void;
  personality: MicrobotPersonality;
}
