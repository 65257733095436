import React from 'react';

import { Icon } from '@advisor/design/components';

const HistoryLoaderItem = ({ animationDelay }: { animationDelay: number }) => {
  return (
    <div
      className="my-3 mx-6 p-4 rounded-lg flex flex-row items-center border gap-3 border-grey animate-pulse"
      style={{ animationDelay: `${animationDelay}ms` }}
    >
      <div className="w-10 h-10 shrink-0 bg-grey rounded-lg" />
      <div className="grow shrink flex flex-col justify-center gap-1 min-w-0">
        <div className="max-w-[8rem] h-3.5 bg-grey rounded-lg" />
        <div className="max-w-[3rem] h-3 bg-grey rounded-lg" />
      </div>
      <div className="flex flex-row -space-x-5">
        <div className="w-10 h-10 rounded-full bg-grey" />
        <div className="w-10 h-10 rounded-full bg-grey" />
      </div>
      <Icon name="ChevronRight" className="rounded text-grey" />
    </div>
  );
};

const HistoryLoader: React.FC = () => {
  return (
    <>
      <HistoryLoaderItem animationDelay={0} />
      <HistoryLoaderItem animationDelay={300} />
      <HistoryLoaderItem animationDelay={600} />
    </>
  );
};

export default HistoryLoader;
