import { Theme } from '../../Theme';
import { Colors } from '../../styles';
import { BannerNotificationVariant } from './types';

export const getBackgroundColor = (
  theme: Theme,
  variant: BannerNotificationVariant = 'primary',
  backgroundColor?: string,
) => {
  if (variant === 'primary') {
    return theme.colors.primaryLight;
  }

  if (variant === 'negative') {
    return Colors.negativeLight;
  }

  return backgroundColor ?? theme.colors.primaryLight;
};

const BACKGROUND_CLASS = {
  primary: 'bg-primary-light',
  negative: 'bg-negative-light',
} as const;

export const getBackgroundClass = (variant: BannerNotificationVariant) => {
  return variant !== 'custom'
    ? BACKGROUND_CLASS[variant as keyof typeof BACKGROUND_CLASS]
    : '';
};

export const getPrimaryColor = (
  theme: Theme,
  variant: BannerNotificationVariant = 'primary',
  textColor?: string,
) => {
  if (variant === 'primary') {
    return theme.colors.primary;
  }

  if (variant === 'negative') {
    return Colors.negative;
  }

  return textColor ?? Colors.primaryBlue;
};

const PRIMARY_CLASS = {
  primary: 'text-primary',
  negative: 'text-negative',
} as const;

export const getPrimaryClass = (variant: BannerNotificationVariant) => {
  return variant !== 'custom'
    ? PRIMARY_CLASS[variant as keyof typeof PRIMARY_CLASS]
    : '';
};
