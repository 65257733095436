import React, { memo, useMemo } from 'react';

import { useMyId } from '@advisor/api/me';
import { GroupedMessage } from '../Message/SystemMessage';
import Message from '../Message';
import MessageListItemFooter from './MessageListItemFooter';
import MessageListItemHeader from './MessageListItemHeader';
import { MessageListItemProps } from './types';

function MessageListItem(props: MessageListItemProps) {
  const { item, latestMessageId, onOpenUserMessageMenu } = props;

  const myId = useMyId();
  const firstMessage = useMemo(() => item.data[0], [item.data]);

  const lastMessage = useMemo(
    () => item.data[item.data.length - 1],
    [item.data],
  );

  const messages = useMemo(
    () =>
      item.data.map((message) => (
        <Message
          key={message.id}
          message={message}
          isFirstInGroup={firstMessage.id === message.id}
          isLastInGroup={lastMessage.id === message.id}
          latestMessageId={latestMessageId}
          onOpenUserMessageMenu={onOpenUserMessageMenu}
        />
      )),
    [
      item.data,
      lastMessage,
      firstMessage,
      latestMessageId,
      onOpenUserMessageMenu,
    ],
  );

  if (item.type === 'UserMessage' || item.data.length === 1) {
    // List order is reversed
    return (
      <>
        {(item.type === 'UserMessage' || item.type === 'MicrobotMessage') && (
          <MessageListItemFooter
            isMostRecent={item.isMostRecent}
            isCurrentUser={myId === item.authorId}
            dateTime={lastMessage.sentAt}
            chatRoomId={lastMessage.chatRoomId}
            isSavedToMemory={
              lastMessage.__typename === 'UserMessage'
                ? !!lastMessage.microbotMemoryId
                : false
            }
          />
        )}
        {messages}
        {item.showFooter && (
          <MessageListItemHeader dateTime={firstMessage.sentAt} />
        )}
      </>
    );
  }

  return <GroupedMessage group={item} />;
}

export default memo(MessageListItem);
