import React, { memo } from 'react';

import Modal from '../Modal';
import DeprecatedButton from '../DeprecatedButton';
import Layout from '../Layout';
import Icon from '../Icon';
import ModalOptionButton from './ModalOptionButton';
import { useActionModal } from './useActionModal';
import { infoModalAtom } from './atoms';

const InfoModal = () => {
  const { show, details, handleCancel, handleDecide } =
    useActionModal(infoModalAtom);

  return (
    <Modal.Base visible={show} onClose={handleCancel} maxWidth={326}>
      <Layout.Row reversed padding={{ x: 16, top: 16 }}>
        <DeprecatedButton.Icon
          icon="X"
          variant="darkGrey01"
          onPress={handleCancel}
        />
      </Layout.Row>

      <Layout.Column items="center" padding={{ x: 32, bottom: 32 }}>
        {details.theme === 'warning' && (
          <Icon name="Warning" className="text-negative w-14 h-14 mb-3" />
        )}
        <h4 className="text-center font-sora font-bold whitespace-pre-line mb-4">
          {details.title}
        </h4>
        <p className="text-center text-sm mb-8 text-dark-grey-02">
          {details.message}
        </p>
        <div className="space-x-4">
          {details.options.map((option) => (
            <ModalOptionButton
              key={option.key}
              variant={option.variant}
              label={option.label}
              onPress={() => handleDecide(option.key)}
            />
          ))}
        </div>
      </Layout.Column>
    </Modal.Base>
  );
};

export default memo(InfoModal);
