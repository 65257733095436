import * as network from '@advisor/utils/network';
import useStudentJourneyLiveUpdate from './useStudentJourneyLiveUpdate';

type Props = {
  chatRoomId: string | undefined;
};

const StudentJourneyLiveUpdateProvider: React.FC<Props> = ({ chatRoomId }) => {
  useStudentJourneyLiveUpdate(chatRoomId);
  return null;
};

export default network.makeOnlineOnly(StudentJourneyLiveUpdateProvider);
