import { useMemo, useState } from 'react';
import { useAtom, atom } from 'jotai';

import { useDeleteUserDataMutation } from '@advisor/api/generated/graphql';
import { showToast } from '@advisor/design/components/Toast';
import { useEvent } from '@advisor/utils/hooks';
import { useLogout } from '@advisor/api/auth';
import Sentry from '@advisor/utils/Sentry';

export interface ConfirmDeleteInputProps {
  onChange: (deleteText: string) => void;
  value: string;
}

const deletingAccountAtom = atom(false);

export function useConfirmDeleteForm() {
  const [inputText, setInputText] = useState('');
  const [isDeletingAccount, setIsDeletingAccount] =
    useAtom(deletingAccountAtom);
  const logout = useLogout('/delete-account-success');
  const [deleteAccountMutation] = useDeleteUserDataMutation();

  const isValid = useMemo(() => inputText === 'DELETE', [inputText]);

  const onChange = useEvent((value: string) => {
    setInputText(value);
  });

  const onSubmit = useEvent(async () => {
    if (isDeletingAccount) {
      return;
    }

    setIsDeletingAccount(true);

    try {
      const user = await deleteAccountMutation();

      if (
        user.data?.deleteUserData?.__typename ===
        'AdvisorCannotDeleteTheirAccountError'
      ) {
        showToast({
          iconName: 'X',
          variant: 'rose',
          messageI18Key: 'you-cannot-delete-host-account',
          namespace: 'onboarding',
        });
        return;
      }

      await logout();
    } catch (e) {
      showToast({
        iconName: 'X',
        variant: 'rose',
        messageI18Key: 'oops-something-went-wrong',
      });

      Sentry.captureException(e);
    } finally {
      setIsDeletingAccount(false);
    }
  });

  return {
    value: inputText,
    isValid,
    isDeletingAccount,
    onChange,
    onSubmit,
  };
}
