import React, { Fragment, memo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Layout from '../Layout';
import Icon from '../Icon';
import { decisionModalAtom } from './atoms';
import { useActionModal } from './useActionModal';
import ModalOptionButton from './ModalOptionButton';

const DecisionModal = () => {
  const { show, handleCancel, handleDecide, details } =
    useActionModal(decisionModalAtom);

  const onClose = () => {
    if (details.dismissible) {
      handleCancel();
    }
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog open={show} onClose={onClose}>
        <div className="fixed inset-0 flex justify-center items-center z-20">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-out duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-dark-grey-02 bg-opacity-50 z-20" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform delay-150"
            enterFrom="translate-y-10 opacity-0"
            enterTo="translate-y-0 opacity-full"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0 opacity-full"
            leaveTo="translate-y-10 opacity-0"
          >
            <div className="px-10 py-8 bg-white text-sm text-center rounded-10 m-4 z-20 md:max-w-[326px] relative">
              {details.dismissible && (
                <button
                  type="button"
                  className="absolute top-4 ltr:right-4 rtl:left-4"
                  onClick={handleCancel}
                >
                  <Icon name="Close" size={30} className="text-dark-grey-02" />
                </button>
              )}
              <h4 className="font-sora font-bold whitespace-pre-line mb-4">
                {details.title}
              </h4>
              {typeof details.message === 'string' ? (
                <p className="mb-6 mx-auto max-w-fit text-sm">
                  {details.message}
                </p>
              ) : (
                <div className="mb-6 mx-auto max-w-fit">{details.message}</div>
              )}
              {details.options.map((option, idx) => (
                <div key={option.key}>
                  {idx > 0 && <Layout.Spacer.Vertical size="tiny" />}
                  <ModalOptionButton
                    variant={option.variant}
                    testID={option.key}
                    label={option.label}
                    onPress={() => handleDecide(option.key)}
                  />
                </div>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default memo(DecisionModal);
