import React from 'react';
import cs from 'classnames';

import { useLanguage } from '@advisor/language';
import DeprecatedButton from '../../DeprecatedButton';
import useMonth from './useMonth';
import DateButton from './DateButton';
import { MonthProps } from './types';

const Month: React.FC<MonthProps> = (props) => {
  const { onDateClick, onNextPress, onPrevPress } = props;
  const { weekdays, calendarRows, calendarTitle, isPrevDisabled } =
    useMonth(props);

  const { isRTL } = useLanguage();

  return (
    <>
      <div className="flex flex-row gap-1 items-center mb-3">
        <DeprecatedButton.Icon
          size={20}
          icon={isRTL ? 'ChevronRight' : 'ChevronLeft'}
          variant="darkGrey03"
          onPress={onPrevPress}
          disabled={isPrevDisabled}
        />
        <h4 className="flex-1 text-center font-outfit text-sm font-medium">
          {calendarTitle}
        </h4>
        <DeprecatedButton.Icon
          size={20}
          icon={isRTL ? 'ChevronLeft' : 'ChevronRight'}
          variant="darkGrey03"
          onPress={onNextPress}
        />
      </div>
      <div className="flex flex-row text-center text-sm py-2 mb-1 rtl:flex-row-reverse">
        {weekdays.map(({ label, selected }) => (
          <div
            key={label}
            className={cs('flex-1', selected && 'text-primary font-semibold')}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="text-center text-sm flex flex-col gap-3">
        {calendarRows.map((row, idx) => (
          <div
            // No harm in using index as key here since the array has fixed length
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className="flex flex-row h-8 items-center rtl:flex-row-reverse"
          >
            {row.map((day) => (
              <DateButton
                key={day.date.unix()}
                {...day}
                onDateClick={onDateClick}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default Month;
