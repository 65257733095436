const SideModalTitle: React.FC<{
  title: string;
  subtitle?: string;
}> = ({ title, subtitle }) => (
  <>
    <h2 className="text-sm font-semibold font-sora">{title}</h2>
    <p className="text-sm text-dark-grey-02">
      {/* The &nbsp; is treated as content, so it takes the same amount of
        space as content after loading, preventing "content-pop"  */}
      {subtitle === '' ? <>&nbsp;</> : subtitle}
    </p>
  </>
);

export default SideModalTitle;
