/* eslint-disable import/prefer-default-export */

import { useMemo } from 'react';

import { OptionVariant } from './types';

export function useButtonVariant(variant: OptionVariant) {
  const buttonVariant = useMemo(() => {
    switch (variant) {
      case 'severe':
        return 'negative';
      case 'positive':
        return 'primaryBlue';
      case 'neutral':
      default:
        return 'darkGrey03';
    }
  }, [variant]);

  return buttonVariant;
}
