import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Icon } from '@advisor/design/components';

const JoinButton = () => {
  const { t } = useTranslation('common');

  return (
    <div className="bg-white flex flex-row items-center py-2 px-3 rounded-lg">
      <Icon name="VideoRoomFill" className="text-primary" />
      <Layout.Spacer.Horizontal size="micro" />
      <span className="text-primary font-bold font-outfit text-xs sm:text-sm">
        {t('join')}
      </span>
    </div>
  );
};

export default JoinButton;
