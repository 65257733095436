import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import TimezonesByCountryCode, {
  TimezoneName,
} from '@advisor/utils/country/timezonesByCountryCodes';
import { Container, Label } from '@advisor/design/components/Form/components';
import { DateFormatter } from '@advisor/language';
import {
  SearchInput,
  Icon,
  ChevronIcon,
  Modal,
} from '@advisor/design/components';
import { TimezoneSelectProps } from './types';

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  value,
  disabled,
  onSelect,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation(['onboarding', 'common']);

  const onToggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const onTimezoneChange = useCallback(
    (newValue: TimezoneName) => {
      onSelect(newValue);
      setShowModal(false);
    },
    [onSelect],
  );

  const renderTimezone = useCallback(
    (timezone: TimezoneName) => (
      <button
        type="button"
        key={timezone}
        className="flex w-full py-3"
        onClick={() => {
          onTimezoneChange(timezone);
        }}
      >
        <span className="text-left rtl:text-right font-medium font-outfit">
          {DateFormatter.timezoneWithGMT(timezone)}
        </span>
      </button>
    ),
    [onTimezoneChange],
  );

  const timezones = useMemo(
    () => TimezonesByCountryCode.flatMap((timezone) => timezone.timezones),
    [],
  );

  const filteredTimezones = useMemo(() => {
    if (!searchText) {
      return timezones;
    }

    return timezones.filter((timezone) =>
      timezone.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
    );
  }, [searchText, timezones]);

  return (
    <>
      <button
        type="button"
        className="ltr:text-left rtl:text-right w-full"
        onClick={onToggleModal}
        disabled={disabled}
      >
        <Container className="flex items-center flex-1 justify-between">
          <Label>{t('onboarding:timezone')}</Label>
          <div className={cn('p-4 pt-9 w-full', !value && 'text-dark-grey-01')}>
            {value
              ? DateFormatter.timezoneWithGMT(value)
              : t('common:please-select')}
          </div>
          <ChevronIcon className="shrink-0" />
        </Container>
      </button>
      <Modal.Base visible={showModal} onClose={onToggleModal} maxWidth={406}>
        <div className="flex justify-between px-8 h-20 items-center border-b border-b-grey">
          <h3 className="font-bold font-outfit text-lg">
            {t('onboarding:select-timezone')}
          </h3>
          <button type="button" className="" onClick={onToggleModal}>
            <Icon name="X" />
          </button>
        </div>
        <div className="px-8 pt-6">
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            placeholder={t('onboarding:search-timezone')}
          />
        </div>
        <div className="px-8 py-6 overflow-auto max-h-[310px]">
          {filteredTimezones.map(renderTimezone)}
        </div>
      </Modal.Base>
    </>
  );
};

export default memo(TimezoneSelect);
