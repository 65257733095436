import {
  MeDocument,
  useUpdateUserIdentityMutation,
  UpdateUserIdentityMutationOptions,
} from '@advisor/api/generated/graphql';

const mutationOptions: UpdateUserIdentityMutationOptions = {
  update(cache, results) {
    cache.updateQuery({ query: MeDocument }, (data) => {
      if (!data || !data.me) {
        return null;
      }

      return {
        __typename: 'Query' as const,
        me: {
          ...data.me,
          ...results.data?.updateUserIdentity,
        },
      };
    });
  },
};

export default function useUpdateUserIdentity() {
  return useUpdateUserIdentityMutation(mutationOptions);
}
