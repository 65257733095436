import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { getToken } from 'firebase/messaging';

import Sentry from '@advisor/utils/Sentry';
import {
  PushNotificationTokenProvider,
  useAddPushNotificationTokenMutation,
  useDeletePushNotificationTokenMutation,
} from '../generated/graphql';
import Env from '../env';
import { notificationsEnabledAtom, permissionStatusAtom } from './atoms';
import {
  PermissionStatus,
  NotificationEnabledStatus,
  UsePushNotificationsHook,
} from './types';
import * as Firebase from './firebase';

const mapNotificationStatus = (
  status: NotificationPermission,
): PermissionStatus => {
  return {
    default: PermissionStatus.UNDETERMINED,
    granted: PermissionStatus.GRANTED,
    denied: PermissionStatus.DENIED,
  }[status];
};

const usePushNotifications: UsePushNotificationsHook = () => {
  const [enabledStatus, setEnabledStatus] = useAtom(notificationsEnabledAtom);
  const [permissionStatus, setPermissionStatus] = useAtom(permissionStatusAtom);
  const [addPushNotificationToken] = useAddPushNotificationTokenMutation();
  const [deletePushNotificationToken] =
    useDeletePushNotificationTokenMutation();

  const requestPermissions = useCallback(async () => {
    if (!('Notification' in window)) {
      // No 'Notification' API support in this browser, we silently fail.
      return false;
    }

    if (permissionStatus === PermissionStatus.GRANTED) {
      return true;
    }

    const status = mapNotificationStatus(
      await Notification.requestPermission(),
    );

    setPermissionStatus(status);
    return status === PermissionStatus.GRANTED;
  }, [setPermissionStatus, permissionStatus]);

  const checkPermissions = useCallback(async () => {
    if (!('Notification' in window)) {
      // No 'Notification' API support in this browser, we silently fail.
      setPermissionStatus(PermissionStatus.DENIED);
      return;
    }

    const status = {
      default: PermissionStatus.UNDETERMINED,
      granted: PermissionStatus.GRANTED,
      denied: PermissionStatus.DENIED,
    }[Notification.permission];

    setPermissionStatus(status);
  }, [setPermissionStatus]);

  const registerForNotifications = useCallback(async () => {
    if (permissionStatus === PermissionStatus.DENIED) {
      return 'no-permission';
    }

    if (permissionStatus === PermissionStatus.UNDETERMINED) {
      const granted = await requestPermissions();

      if (!granted) {
        return 'no-permission';
      }
    }

    try {
      const token = await getToken(Firebase.messaging, {
        vapidKey: Env.firebaseMessaging.vapidKey,
      });

      if (token !== null) {
        addPushNotificationToken({
          variables: {
            token,
            type: PushNotificationTokenProvider.Web,
          },
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    setEnabledStatus(NotificationEnabledStatus.ENABLED);
    return 'success';
  }, [
    permissionStatus,
    setEnabledStatus,
    requestPermissions,
    addPushNotificationToken,
  ]);

  const unregisterFromNotifications = useCallback(async () => {
    if (permissionStatus !== PermissionStatus.GRANTED) {
      return;
    }

    try {
      const token = await getToken(Firebase.messaging, {
        vapidKey: Env.firebaseMessaging.vapidKey,
      });

      await deletePushNotificationToken({
        variables: {
          token,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }

    setEnabledStatus(NotificationEnabledStatus.DISABLED);
  }, [deletePushNotificationToken, permissionStatus, setEnabledStatus]);

  useEffect(() => {
    if (permissionStatus === PermissionStatus.UNDETERMINED) {
      checkPermissions();
    }
  }, [permissionStatus, checkPermissions]);

  return {
    enabled: enabledStatus === NotificationEnabledStatus.ENABLED,
    granted: permissionStatus === PermissionStatus.GRANTED,
    denied: permissionStatus === PermissionStatus.DENIED,
    permissionStatus,
    requestPermissions,
    registerForNotifications,
    unregisterFromNotifications,
  };
};

export default usePushNotifications;
