import cs from 'classnames';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '@advisor/design/hooks';
import useScrollTop from 'src/hooks/useScrollTop';

function InteractionShowcaseSection() {
  const typingAreaRef = useRef<HTMLParagraphElement>(null);
  const scrollTop = useScrollTop();
  const { t } = useTranslation('global-study-homepage');
  const [, windowHeight] = useWindowSize();

  const typingProgress = useMemo(() => {
    if (!typingAreaRef.current) return 0;

    const { top } = typingAreaRef.current.getBoundingClientRect();

    // -1 - about to appear, 1 - just left the screen.
    let prog = (1 - top / windowHeight) * 2 - 1;

    // adjusting the typing speed
    prog *= 1.6;

    // Remapping into 0-1
    return prog * 0.5 + 0.5;
    // eslint-disable-next-line
  }, [scrollTop, windowHeight]);

  return (
    <main className="relative bg-white rounded-20 overflow-hidden flex flex-row justify-center pb-40 sm:pb-32">
      <div className="absolute inset-0 py-4 flex flex-col justify-end sm:justify-center items-start space-y-2 sm:space-y-3 select-none">
        <AutoScrollRow
          elements={[
            t('keyword-document-proofreading'),
            t('keyword-personal-essays'),
            t('keyword-visa-assistance'),
            t('keyword-student-loans'),
            t('keyword-scholarships'),
            t('keyword-university-search'),
            t('keyword-applications'),
          ]}
        />
        <AutoScrollRow
          // reverse
          elements={[
            t('keyword-accomodation-arrangement'),
            t('keyword-medical-insurance'),
            t('keyword-online-skill-enrichment'),
            t('keyword-summer-schools'),
            t('keyword-virtual-internships'),
            t('keyword-destination-support'),
          ]}
        />
        <AutoScrollRow
          elements={[
            t('keyword-volunteer-abroad-programs'),
            t('keyword-english-language-courses'),
            t('keyword-cv-writing-workshops'),
            t('keyword-virtual-community-service'),
          ]}
        />
      </div>
      <div className="relative w-[23em] text-[10px] sm:text-[1.7vw] lg:text-[16px]">
        <div
          className="px-[3.5%] pb-[3.5%] rounded-b-[3em] bg-[#3D3D3D]"
          style={{
            boxShadow: '20px 40px 48px 10px rgba(31, 34, 46, 0.2)',
          }}
        >
          <div className="relative rounded-b-[2.5em] -mt-[2px] overflow-hidden">
            <img
              className="relative top-[2px] select-none"
              src="/images/homepage/recurring-interaction.png"
              alt="Recurring interaction"
            />
            <p
              ref={typingAreaRef}
              className="absolute left-[2em] right-[6em] bottom-[18em] font-outfit text-[0.8em] bg-off-white"
            >
              <Typing progress={typingProgress}>
                Also, what are the best schools fo
              </Typing>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

function AutoScrollRow({
  className,
  reverse,
  elements,
}: {
  className?: string;
  reverse?: boolean;
  elements: string[];
}) {
  const singleRow = useMemo(
    () => (
      <div className="flex justify-start min-w-[50vw]">
        {elements.map((e) => (
          <span
            className="py-2 px-3 flex-grow bg-off-white rounded-lg text-dark-grey-03 text-opacity-30 text-center whitespace-nowrap mr-2 text-sm sm:text-base"
            key={e}
          >
            {e}
          </span>
        ))}
      </div>
    ),
    [elements],
  );

  return (
    <div
      className={cs(
        'flex items-start overflow-hidden',
        reverse
          ? 'animate-move-one-thirds-right'
          : 'animate-move-one-thirds-left',
        className,
      )}
    >
      {/* The first two cover the whole screen width (both are 50vw at min),
          the third one aids in scrolling seemlessly */}
      {singleRow}
      {singleRow}
      {singleRow}
    </div>
  );
}

function Typing({
  children,
  progress,
}: {
  children: string;
  progress: number;
}) {
  const typedContents = useMemo(() => {
    const end = Math.max(
      0,
      Math.min(Math.floor(children.length * progress), children.length),
    );
    return children.substring(0, end);
  }, [children, progress]);

  return (
    <span>
      {typedContents}
      <span
        /* Done so that the blink animations reset on each "key-press" */
        key={typedContents}
        className="animate-blink relative text-dark-grey-02"
      >
        |
      </span>
    </span>
  );
}

export default InteractionShowcaseSection;
