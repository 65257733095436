import z from 'zod';

import {
  useLatestMessagesQuery,
  UserMessageInfoFragment,
} from '@advisor/api/generated/graphql';
import {
  UserMetadataLocation,
  useUserMetadata,
} from '@advisor/api/userMetadata';
import { useCallback } from 'react';

const MemoryBankModalLocation = new UserMetadataLocation(
  'modals.MemoryBankModal',
  z.boolean(),
);

const useShowMemoryBankModal = (message?: UserMessageInfoFragment) => {
  const [userMetadata, setUserMetadata] = useUserMetadata(
    MemoryBankModalLocation,
  );

  const { chatRoomId } = message ?? {};

  const { data } = useLatestMessagesQuery({
    variables: { chatRoomId: chatRoomId ?? '' },
    skip: !message,
  });
  const isNotFirstMessage = data?.messages && data.messages.edges.length > 2;

  const toggleShow = useCallback(() => {
    setUserMetadata(userMetadata !== undefined ? !userMetadata : true);
  }, [userMetadata, setUserMetadata]);

  return {
    show: !userMetadata && isNotFirstMessage,
    showSetting: userMetadata,
    toggleShow,
  };
};

export default useShowMemoryBankModal;
