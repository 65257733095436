import { createContext } from 'react';

import type { TooltipContextType } from './types';

export const initialDimensions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

const TooltipContext = createContext<TooltipContextType>({
  rootDimensions: initialDimensions,
});

export default TooltipContext;
