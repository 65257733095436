import React from 'react';
import { useTranslation } from 'react-i18next';

const NotApplicable = () => {
  const { t } = useTranslation('common');
  return (
    <span className="text-sm font-medium text-dark-grey-01">{t('n-a')}</span>
  );
};

export default NotApplicable;
