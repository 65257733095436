/* eslint-disable import/prefer-default-export */
import { atomFamily } from 'jotai/utils';

import { Scope } from '@advisor/api/scope';

export const canReassignStudentScope = atomFamily((chatRoomId: string) =>
  Scope.every([
    Scope.leadingAdvisor(chatRoomId),
    Scope.studentConversation(chatRoomId),
  ]),
);
