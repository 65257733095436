import { useApolloClient } from '@apollo/client';

import { useEvent } from '@advisor/utils/hooks';
import { FileData, FileUploadOptions, FileVisibility } from '../types';
import uploadFile from './uploadFile';

function useFileUpload<T extends FileVisibility>(
  visibility: T,
  options: FileUploadOptions[T],
) {
  const apolloClient = useApolloClient();

  return useEvent((file: FileData) => {
    return uploadFile(apolloClient, visibility, file, options);
  });
}

export default useFileUpload;
