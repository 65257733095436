import React from 'react';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  TagDescription,
  AboutSubsection,
} from '@advisor/microbots/hooks/useMicrobot';
import assets from '@advisor/microbots/assets';
import { Icon, Layout } from '@advisor/design/components';
import withSeparators from '@advisor/utils/withSeparators';
import Gallery from './Gallery';

interface SubsectionProps {
  details: AboutSubsection;
}

const Subsection: React.FC<SubsectionProps> = (props) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { details } = props;

  const {
    hr,
    tags,
    title,
    subtitle,
    description,
    screenshots,
    galleryBgColor,
    integrationLogo,
  } = details;

  if (hr) {
    return <hr className="text-grey" />;
  }

  return (
    <div
      className={cs(
        integrationLogo !== undefined && 'flex items-center justify-between',
      )}
    >
      <div>
        {!!title && (
          <h3 className="font-sora text-sm font-semibold leading-5 text-dark-grey-04 mb-3 xl:text-base xl:leading-6">
            {t(title)}
          </h3>
        )}
        {!!subtitle && (
          <h5 className="font-outfit text-xs font-semibold leading-5 uppercase text-dark-grey-025 xl:font-medium xl:text-sm">
            {t(subtitle)}
          </h5>
        )}
        {!!description && (
          <p className="font-outfit text-sm font-medium leading-4 text-dark-grey-02">
            {t(description)}
          </p>
        )}
        {!!screenshots && (
          <Gallery items={screenshots} backgroundColor={galleryBgColor} />
        )}
        {!!tags && tags.length && (
          <div className="flex flex-row flex-wrap mt-3">
            {withSeparators(
              tags,
              (key) => (
                <Layout.Spacer.Horizontal
                  key={`separator-${key}`}
                  size="micro02"
                />
              ),
              (tag) => (
                <TagAbout tag={tag} key={tag.key} />
              ),
            )}
          </div>
        )}
      </div>
      {integrationLogo !== undefined && (
        <img src={assets[integrationLogo]} alt="" className="w-9 h-9" />
      )}
    </div>
  );
};

export default Subsection;

export const TagAbout: React.FC<{ tag: TagDescription }> = ({ tag }) => {
  const { t } = useTranslation(['common', 'microbot']);
  const { iconName, title, color } = tag;

  return (
    <div
      className="bg-light-grey py-2.5 px-4 rounded-md text-sm leading-4 whitespace-nowrap mb-2"
      style={{ color }}
    >
      {t(title)}{' '}
      {!!iconName && <Icon name={iconName} className="inline" size={16} />}
    </div>
  );
};
