import cs from 'classnames';
import React from 'react';

import { ComponentProps } from './types';

const Title: React.FC<ComponentProps> = ({ children, className }) => {
  return (
    <h1
      className={cs(
        'text-white font-sora font-semibold text-[31.2px] leading-[38px] tracking-[-0.62px] md:text-[37.4px] md:leading-[45.68px] md:tracking-[-0.75px]',
        className,
      )}
    >
      {children}
    </h1>
  );
};

export default Title;
