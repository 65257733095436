/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  atom,
  ExtractAtomResult,
  ExtractAtomArgs,
  useSetAtom,
  WritableAtom,
} from 'jotai';

const noopAtom = atom(null, () => {
  // noop
});

type SetAtom<Args extends any[], Result> = (...args: Args) => Result;

export default function useSetAtomOrNull<
  AtomType extends WritableAtom<any, any, any>,
>(
  atomToSet: AtomType | null | undefined,
  options?: Parameters<typeof useSetAtom>[1],
) {
  const setAtom = useSetAtom(
    atomToSet ?? (noopAtom as unknown as AtomType),
    options,
  );

  if (atomToSet) {
    return setAtom as SetAtom<
      ExtractAtomArgs<AtomType>,
      ExtractAtomResult<AtomType>
    >;
  }

  return null;
}
