import React from 'react';
import { useAtomValue } from 'jotai';

import { Icon } from '@advisor/design/components';
import { nullAtom } from '@advisor/utils/atoms';
import { useVideoChat } from '@advisor/videoCall';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { Scope } from '@advisor/api/scope';
import { VideoCallButtonProps } from './types';

function VideoCallButton(props: VideoCallButtonProps) {
  const { onOpenVideoCall } = props;

  const { chatRoomId } = useActiveChatRoom() ?? {};
  const isVideoChatEnabled = useAtomValue(
    chatRoomId ? Scope.videoChatEnabled(chatRoomId) : nullAtom,
  );
  const videoChat = useVideoChat(chatRoomId ?? '', !chatRoomId);
  const isVideoCallActive = videoChat?.isActive;

  return (
    <button
      type="button"
      className="relative w-12.5 h-12.5 flex justify-center items-center rounded-10 text-primary transition-opacity duration-75 hover:opacity-70 disabled:opacity-20"
      onClick={onOpenVideoCall}
      disabled={!isVideoChatEnabled}
    >
      <Icon name="VideoRoom" />
      {isVideoCallActive && (
        <span className="absolute top-2.5 right-2.5 w-1.5 h-1.5 rounded-full bg-negative" />
      )}
    </button>
  );
}

export default VideoCallButton;
