import { atom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import {
  EnableAutotranslationsDocument,
  EnableAutotranslationsMutationOptions,
} from '@advisor/api/generated/graphql';
import { getMyCachedIdentity } from '@advisor/api/me';
import ComplexError from '@advisor/utils/ComplexError';
import { atomWithMutation } from '@advisor/api/apollo';
import modifyCachedChatMember from '../chatMember/modifyCachedChatMember';
import updateChatMemberAtom from '../chatMember/updateChatMemberAtom';
import { oldestForeignMessageAtoms } from '../messaging/latestMessagesAtoms';
import { inProgressChatRoomsAtom } from './autotranslationStateAtoms';

const createEnableAutotranslationOptions = (
  chatRoomId: string,
): EnableAutotranslationsMutationOptions => ({
  update(cache) {
    modifyCachedChatMember(cache, chatRoomId, getMyCachedIdentity(cache), {
      autoTranslate: true,
    });
  },
});

export const enableAutotranslationAtom = (() => {
  const mutationAtom = atomWithMutation(EnableAutotranslationsDocument);

  return atom(null, async (get, set, chatRoomId: string) => {
    const oldestForeignMessage = await get(
      oldestForeignMessageAtoms(chatRoomId),
    );

    set(inProgressChatRoomsAtom, ['add', chatRoomId]);

    try {
      await set(mutationAtom, {
        variables: {
          chatRoomId,
          firstMessageId: oldestForeignMessage?.id,
        },
        ...createEnableAutotranslationOptions(chatRoomId),
      });
    } catch (err) {
      Sentry.captureException(
        new ComplexError('Could not enable automatic translations', err),
      );
    }

    set(inProgressChatRoomsAtom, ['delete', chatRoomId]);
  });
})();

export const disableAutotranslationAtom = atom(
  null,
  async (_get, set, chatRoomId: string) => {
    await set(updateChatMemberAtom, {
      chatRoomId,
      values: {
        autoTranslate: false,
      },
      optimistic: true,
    });
  },
);

export const setAutotranslationAtom = atom(
  null,
  (
    _get,
    set,
    { chatRoomId, enable }: { chatRoomId: string; enable: boolean },
  ) => {
    if (enable) {
      set(enableAutotranslationAtom, chatRoomId);
    } else {
      set(disableAutotranslationAtom, chatRoomId);
    }
  },
);
