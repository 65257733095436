import {
  DataOrError,
  S3RequestOptions,
  S3RequestResponse,
  FileData,
  UploadErrorType,
} from '../types';

/**
 * @param file File to upload to S3
 * @param options Upload configuration returned from useRequestFileUploadMutation
 * @returns object with url of uploaded file or error
 */
const sendS3HttpRequest = (
  file: FileData,
  options: S3RequestOptions,
  signal?: AbortSignal,
) => {
  const s3Request = new XMLHttpRequest();

  signal?.addEventListener('abort', () => {
    s3Request.abort();
  });

  const promise = new Promise<DataOrError<S3RequestResponse>>((resolve) => {
    if (signal?.aborted) {
      resolve({ error: UploadErrorType.UploadCanceled });
      return;
    }

    const formData = createFormData(file, options);

    s3Request.open('POST', options.uploadUrl);
    s3Request.setRequestHeader('Access-Control-Expose-Headers', 'Location');

    s3Request.onerror = () => {
      resolve({ error: UploadErrorType.XMLHttpRequestError });
    };

    s3Request.onabort = () => {
      resolve({ error: UploadErrorType.UploadCanceled });
    };

    s3Request.onreadystatechange = async () => {
      if (
        s3Request.readyState !== XMLHttpRequest.DONE &&
        s3Request.status !== 204 // No Content
      ) {
        return;
      }

      const fileUrl = s3Request.getResponseHeader('Location');
      if (typeof fileUrl !== 'string') {
        resolve({ error: UploadErrorType.XMLHttpRequestError });
        return;
      }

      resolve({ ok: true, data: { fileUrl } });
    };
    s3Request.send(formData);
  });

  return promise;
};

const createFormData = (file: FileData, payload: S3RequestOptions) => {
  const formData = new FormData();
  Object.entries(payload.formDataFields).forEach(([key, val]) =>
    formData.append(key, val),
  );

  // RN can receive { uri, name, type } in FormData
  formData.append('file', file as unknown as Blob);

  return formData;
};

export default sendS3HttpRequest;
