import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import {
  MicrobotArtifactKey,
  MicrobotPersonality,
} from '@advisor/api/generated/graphql';
import { Spacing } from '@advisor/design/styles';
import { DeprecatedButton, Icon, Layout } from '@advisor/design/components';
import editArtifactAtom from '../../atoms/editArtifactAtom';
import { useMicrobotArtifacts } from '../../api';
import useMicrobot from '../../hooks/useMicrobot';
import EditArtifactModal from '../EditArtifactModal';
import { CommonConfigurationComponentProps } from './types';

const PamConfiguration: React.FC<CommonConfigurationComponentProps> = () => {
  const { t } = useTranslation('microbot');

  const [state, dispatch] = useAtom(editArtifactAtom);
  const artifacts = useMicrobotArtifacts(MicrobotPersonality.Pam);
  const microbot = useMicrobot(MicrobotPersonality.Pam);
  const partnerSchoolList = artifacts?.PARTNER_SCHOOL_LIST ?? '';

  const onOpenEditArtifact = () => {
    const partnerSchoolListContent = partnerSchoolList.trim();

    dispatch({
      type: 'open',
      personality: MicrobotPersonality.Pam,
      artifactKey: MicrobotArtifactKey.PartnerSchoolList,
      content: partnerSchoolListContent.length
        ? partnerSchoolList
        : `${t(
            'heres-a-list-of-partner-schools-which-we-have-an-agreement-with',
          )}\n\n`,
      placeholder: !partnerSchoolListContent.length,
    });
  };

  return (
    <>
      <div className="p-6 bg-light-grey border border-grey rounded-10">
        <div className="flex flex-row items-center">
          <div className="flex-1">
            <h3 className="font-sora font-bold leading-5 text-dark-grey-03">
              {t('partner-school')}
            </h3>
            <Layout.Spacer.Vertical size="atomic" />
            <p className="font-outfit font-medium text-sm leading-[1.125rem] text-dark-grey-02">
              {t(
                'configure-partner-schools-which-would-affect-recommendations-for-recommendation-bot',
              )}
            </p>
          </div>
          <Layout.Spacer.Horizontal size="micro02" />
          <Icon
            name="School"
            size={Spacing.iconSize}
            color={microbot?.color ?? 'primary'}
          />
        </div>

        <Layout.Spacer.Vertical size="micro02" />

        <DeprecatedButton.Outline
          variant="primary"
          label={t('edit-list')}
          onPress={onOpenEditArtifact}
          className="text-sm leading-[1.125rem]"
        />
      </div>

      <EditArtifactModal
        visible={!!state}
        onClose={() => dispatch({ type: 'close' })}
      />
    </>
  );
};

export default PamConfiguration;
