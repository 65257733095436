import BannerAdvert from './BannerAdvert';

export { useAdvertForPlacement, AdRequirements } from './activeSponsorships';
export { default as ImpressionTag } from './ImpressionTag';

const Advert = {
  Banner: BannerAdvert,
};

export default Advert;
