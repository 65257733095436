import { useMemo } from 'react';

import { Role } from '@advisor/api/user';
import { Colors } from '@advisor/design/styles';
import { useMicrobotDetailsOf } from '@advisor/microbots/hooks';
import { UserInfoFragment } from '@advisor/api/generated/graphql';
import { useTheme } from '@advisor/design/Theme';

function useUserColor(user?: UserInfoFragment | null | undefined): string {
  const microbot = useMicrobotDetailsOf(user);
  const theme = useTheme();

  return useMemo(() => {
    if (!user) {
      return theme.colors.primary;
    }

    if (Role.isStudent(user) || Role.isFamilyMember(user)) {
      return theme.colors.primary;
    }

    if (Role.isAdvisor(user) || Role.isServiceProvider(user)) {
      return theme.colors.secondary;
    }

    if (microbot) {
      return microbot.color;
    }

    return Colors.darkGrey03;
  }, [user, microbot, theme]);
}

export default useUserColor;
