import { z } from 'zod';
import { useMemo } from 'react';
import { Operation } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';

import * as network from '@advisor/utils/network';
import { AuthResult } from '../auth/types';
import RefreshTokensNetworkError from './errors/RefreshTokensNetworkError';
import RefreshTokensDeniedError from './errors/RefreshTokensDeniedError';

/**
 * Schema of network errors thrown by aws-appsync-subscription-link
 * see {@link https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/master/packages/aws-appsync-subscription-link/src/realtime-subscription-handshake-link.ts}
 */
const SubscriptionDisconnectedError = z.object({
  errors: z.array(
    z.object({
      message: z
        .string()
        .refine((message) =>
          [
            'Timeout disconnect',
            'Connection closed',
            'Subscription timeout',
            'Token has expired.',
          ].some((beginning) => message.startsWith(beginning)),
        ),
    }),
  ),
});

/**
 * When subscription fails due to network error, this link will retry it.
 * Retrying is done by refreshing auth tokens as it causes subscription link to reconnect.
 * This link fixes issues when subscriptions disconnect when the app is in background mode.
 * (Subscriptions fail if for 5 min there is no connection to the server)
 *
 * @param renewSession function refreshing auth tokens
 */
const useSubscriptionRetryLink = (renewSession: () => Promise<AuthResult>) => {
  return useMemo(() => {
    return new RetryLink({
      attempts: async (_count, operation, error) => {
        if (error instanceof RefreshTokensDeniedError) {
          return false;
        }

        if (error instanceof RefreshTokensNetworkError) {
          await network.newOnlinePromise();
          return true;
        }

        if (
          !isSubscription(operation) ||
          !SubscriptionDisconnectedError.safeParse(error).success
        ) {
          return false;
        }

        await network.newOnlinePromise();
        await renewSession();

        return true;
      },
    });
  }, [renewSession]);
};

export default useSubscriptionRetryLink;

const isSubscription = (operation: Operation) => {
  const { query } = operation;
  const definition = getMainDefinition(query);

  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};
