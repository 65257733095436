import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitationModal, usePlatformInvitationOptions } from '../Invitations';
import inviteActionIcon from './inviteActionIcon.jpg';
import SuggestedActionItem from './SuggestedActionItem';
import { SuggestedActionsProps } from './types';

const SuggestedActions: React.FC<SuggestedActionsProps> = (props) => {
  const { chatRoomsCount } = props;
  const { t } = useTranslation('onboarding');

  const invitationOptions = usePlatformInvitationOptions();
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);

  const showInvitationModal = useCallback(() => {
    setInvitationModalVisible(true);
  }, []);

  const hideInvitationModal = useCallback(() => {
    setInvitationModalVisible(false);
  }, []);

  const showInviteAction = chatRoomsCount === 0;

  if (!showInviteAction) {
    return null;
  }

  return (
    <section>
      <h4 className="font-sora font-bold text-xs mb-3">
        {t('suggested-actions')}
      </h4>
      <SuggestedActionItem
        title={t('invite-customers-to-join')}
        message={t('use-our-referral-link-to-invite-customers-to-join')}
        imageSrc={inviteActionIcon}
        imageAlt={t('invite-customers-to-join')}
        onPress={showInvitationModal}
      />
      <InvitationModal
        isVisible={invitationModalVisible}
        onClose={hideInvitationModal}
        options={invitationOptions}
      />
    </section>
  );
};

export default SuggestedActions;
