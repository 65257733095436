import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Layout } from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import { Transition } from '@headlessui/react';

const transitionProperties = {
  enter: 'transition-all duration-250 ease-in-out',
  enterFrom: 'scale-y-0 opacity-0',
  enterTo: 'scale-y-100 opacity-100',
  leave: 'transition-all duration-250 ease-in-out',
  leaveFrom: 'scale-y-100 opacity-100',
  leaveTo: 'scale-y-0 opacity-0',
};

const InviteDisclosure: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const [show, setShow] = useState(false);

  return (
    <>
      <Layout.Spacer.Vertical size="large70" />
      <button
        type="button"
        className="flex flex-row items-center"
        onClick={() => setShow(!show)}
      >
        <p className="font-outfit text-xs font-semibold underline text-dark-grey-03">
          {t('want-a-different-way-to-sign-in')}
        </p>
        <Icon
          name={show ? 'ChevronUp' : 'ChevronDown'}
          size={Spacing.small01}
          color={Colors.darkGrey03}
        />
      </button>
      <Layout.Spacer.Vertical size="atomic" />
      <Transition {...transitionProperties} show={show}>
        <p className="font-outfit text-dark-grey-025 text-xs">
          {t(
            'if-youd-like-to-use-an-alternative-email-or-phone-number-to-join',
          )}
        </p>
      </Transition>
      <Layout.Spacer.Vertical size="small01" />
    </>
  );
};

export default InviteDisclosure;
