import React from 'react';

import useParsedImpressionTagUri from './useParsedImpressionTagUri';
import { ImpressionTagProps } from './types';

function ImpressionTag({ advert }: ImpressionTagProps) {
  const impressionTagUri = useParsedImpressionTagUri(advert);

  if (!impressionTagUri) {
    return null;
  }

  return (
    <img src={impressionTagUri} height="1" width="1" alt="Advertisement" />
  );
}

export default ImpressionTag;
