import { atomFamily } from 'jotai/utils';
import { atom, useAtomValue } from 'jotai';

import { InvitationDetailsDocument } from '@advisor/api/generated/graphql';
import unauthorized from '@advisor/api/unauthorized';
import { nullAtom } from '@advisor/utils/atoms';

async function fetchInvitationDetails(invitationId: string) {
  const json = await unauthorized.query({
    query: InvitationDetailsDocument,
    variables: {
      invitationId,
    },
  });

  const { name, country, email, phoneNumber } =
    json.data?.invitationDetails ?? {};

  return {
    name,
    country,
    email,
    phoneNumber,
  };
}

const invitationDetailsAtoms = atomFamily((invitationId: string) => {
  return atom(() => fetchInvitationDetails(invitationId));
});

/**
 * Used to fetch a preassigned name, country, email and phone number for a specific
 * invitation ID. They get copied into a writable atom later down the road so the
 * invited used can tweak the details.
 */
export default function useInvitationDetails(invitationId?: string) {
  const invitationDetailsAtom =
    invitationId !== undefined
      ? invitationDetailsAtoms(invitationId)
      : nullAtom;

  return useAtomValue(invitationDetailsAtom) ?? undefined;
}
