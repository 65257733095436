import { useAtomValue } from 'jotai';
import { useMolecule } from 'bunshi/react';

import { Scope } from '@advisor/api/scope';
import { MessageInputMolecule } from '../../hooks/MessageInput';

// Message length limit for chat rooms with Astro
const microbotRoomLengthLimit = 5000;

// Fraction of the limit which we consider as being close
// to the limit
const approachingLimitFactor = 0.98;

const useMessageLengthLimit = (chatRoomId: string) => {
  const isMicrobotConversation = useAtomValue(
    Scope.microbotConversation(chatRoomId),
  );

  const { messageInputAtom } = useMolecule(MessageInputMolecule);
  const message = useAtomValue(messageInputAtom);

  return {
    approachingLimit:
      isMicrobotConversation &&
      message.length >=
        Math.floor(microbotRoomLengthLimit * approachingLimitFactor),
    limitReached:
      isMicrobotConversation && message.length > microbotRoomLengthLimit,
  };
};

export default useMessageLengthLimit;
