import cs from 'classnames';
import React, { memo, useCallback } from 'react';
import type { ListRenderItem } from 'react-native';

import { FlatList, ErrorView, Layout } from '@advisor/design/components';
import { ChatRoomWithMembersFragment } from '@advisor/api/generated/graphql';
import ChatRoomRow, { ChatRoomRowEmpty, RowSeparator } from './ChatRoomRow';
import useLatestMessages from './useLatestMessages';
import ChatRoomsLoader from './ChatRoomListLoader';
import useChatRooms from './useChatRooms';

const keyExtractor = (item: ChatRoomWithMembersFragment) => item.id;

const ChatRoomList: React.FC = () => {
  const { error, loading, hasMore, loadMore, chatRooms, refetch } =
    useChatRooms();

  useLatestMessages();

  const renderEmptyComponent = useCallback(() => {
    if (loading) {
      return null;
    }

    return <ChatRoomRowEmpty />;
  }, [loading]);

  const renderListLoadingIndicator = useCallback(() => {
    if (loading || hasMore) {
      return <ChatRoomsLoader />;
    }

    return null;
  }, [loading, hasMore]);

  const renderChatRoom: ListRenderItem<ChatRoomWithMembersFragment> =
    useCallback(({ item }) => <ChatRoomRow chatRoom={item} />, []);

  if (error) {
    return (
      <div className="w-full">
        <Layout.Spacer.Vertical size="medium" />
        <ErrorView onTryAgain={() => refetch()} />
      </div>
    );
  }

  return (
    <FlatList
      className={cs(
        'overflow-y-auto overflow-x-visible w-full max-h-full',
        loading
          ? 'opacity-60 transition-opacity pointer-events-none'
          : 'opacity-100',
      )}
      contentContainerClassName="p-6"
      data={chatRooms}
      renderItem={renderChatRoom}
      keyExtractor={keyExtractor}
      ListEmptyComponent={renderEmptyComponent}
      ListFooterComponent={renderListLoadingIndicator}
      ItemSeparatorComponent={RowSeparator}
      onEndReached={loadMore}
    />
  );
};

export default memo(ChatRoomList);
