import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeprecatedButton, Form, Modal } from '@advisor/design/components';
import { AddTabProps, ModalTab } from './types';
import SwipeTransition from './SwipeTransition';

function AddMilestoneTab({
  inputProps,
  activeTab,
  onClose,
  onSubmit,
  onOpenDatePicker,
}: AddTabProps) {
  const { t } = useTranslation(['common', 'task-organiser']);

  return (
    <SwipeTransition direction="left" show={activeTab === ModalTab.Root}>
      <div>
        <Modal.Header
          title={t('task-organiser:new-task')}
          subtitle={t('task-organiser:for-customer-profile')}
          onClose={onClose}
        />
        <div className="py-4">
          <div className="px-8 py-2 flex flex-col">
            <Form.Text
              placeholder={t('task-organiser:add-title')}
              label={t('task-organiser:task-title')}
              required
              {...inputProps.name}
            />
          </div>
          <div className="px-8 py-2 flex flex-col">
            <Form.TextArea
              placeholder={t('task-organiser:add-description')}
              label={`${t('task-organiser:task-description')} (${t(
                'task-organiser:optional',
              )})`}
              height={190}
              {...inputProps.description}
            />
          </div>
          <div className="px-8 py-2 flex flex-col">
            <Form.DateInput
              placeholder="DD/MM/YYYY"
              label={`${t('common:due-date')} (${t(
                'task-organiser:optional',
              )})`}
              onPickerPress={onOpenDatePicker}
              {...inputProps.dueDate}
            />
          </div>
        </div>
        <Modal.Footer>
          <DeprecatedButton.Large variant="primary" onPress={onSubmit}>
            {t('common:save')}
          </DeprecatedButton.Large>
        </Modal.Footer>
      </div>
    </SwipeTransition>
  );
}

export default AddMilestoneTab;
