import React from 'react';
import cs from 'classnames';

import { Icon } from '@advisor/design/components';
import { OptionButtonProps } from './types';

function OptionButton({
  onPress,
  iconName,
  muted,
  disabled,
}: OptionButtonProps) {
  return (
    <button
      type="button"
      className={cs(
        'relative flex justify-center items-center w-12 h-12 rounded-lg text-white transition-all duration-150',
        (muted || disabled) && 'opacity-60',
      )}
      onClick={onPress}
    >
      <Icon name={iconName} />
      <span className="absolute inset-0 flex justify-center items-center">
        <span
          className={cs(
            'w-[0.25rem] h-9 origin-center -rotate-[35.5deg] bg-white border-[0.1rem] border-dark-grey-03 transition-all duration-150',
            muted ? 'scale-y-100' : 'scale-y-0',
          )}
        />
      </span>
    </button>
  );
}

export default OptionButton;
