import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import DeprecatedButton from '../../DeprecatedButton';
import { Container, ErrorText, Label, TextInput } from '../components';
import { dateToStr, strToDate } from './utils';
import { DateInputProps } from './types';

function DateInput(props: DateInputProps) {
  const {
    value,
    placeholder,
    label,
    errorMessage,
    onChange,
    onBlur,
    onPickerPress,
  } = props;

  const { t } = useTranslation('common');
  const [isValid, setIsValid] = useState(true);
  const [textValue, setTextValue] = useState(() =>
    value !== undefined ? dateToStr(value) : '',
  );

  useEffect(() => {
    setTextValue(value !== undefined ? dateToStr(value) : '');
  }, [value]);

  const handleChange = useEvent((e: ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);

    setIsValid(true);
  });

  const handleBlur = useEvent(() => {
    if (textValue === '') {
      onChange?.(undefined);
    } else {
      const validDate = strToDate(textValue);

      if (!validDate) {
        setIsValid(false);
        return;
      }

      onChange?.(validDate);
    }

    setIsValid(true);

    // Letting the new values propagate
    setTimeout(() => {
      onBlur?.();
    }, 0);
  });

  return (
    <>
      <Container className="rounded" hasErrors={!isValid || !!errorMessage}>
        {label && <Label>{label}</Label>}
        {onPickerPress && (
          <div className="absolute inset-y-0 ltr:right-3 rtl:left-3 flex justify-center items-center">
            <DeprecatedButton.Icon
              icon="Calendar"
              variant="darkGrey01"
              onPress={onPickerPress}
            />
          </div>
        )}
        <TextInput
          placeholder={placeholder ?? t('date')}
          value={textValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className={cs(
            'text-sm font-outfit font-semibold placeholder:font-normal',
            label ? 'pt-9' : '',
          )}
        />
      </Container>
      {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
}

export default DateInput;
