import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { useEvent } from '@advisor/utils/hooks';
import { invitationAtom } from './atoms';

export default function useInvitation() {
  const [invitation, setInvitation] = useAtom(invitationAtom);

  const clearInvitation = useEvent(() => {
    return setInvitation(null);
  });

  return useMemo(
    () => ({
      ...invitation,
      setInvitation,
      clearInvitation,
    }),
    [invitation, setInvitation, clearInvitation],
  );
}
