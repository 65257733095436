import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ContextMenu } from '@advisor/design/components';
import { PermissionGate, Scope } from '@advisor/api/scope';
import { Role } from '@advisor/api/user';
import { Colors } from '@advisor/design/styles';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { useRemoveMicrobotFromConversation } from '@advisor/microbots/hooks';
import { UserListMenuProps } from './types';
import canKickFromChatRoomScope from './canKickFromChatRoomScope';
import useKickFromChatRoom from './useKickFromChatRoom';
import canRemoveMicrobotScope from './canRemoveMicrobotScope';

const UserListMenu: React.FC<UserListMenuProps> = ({ user }) => {
  const { t } = useTranslation(['common', 'task-organiser', 'microbot']);
  const navigate = useNavigate();

  const chatRoomCtx = useActiveChatRoom();
  const chatRoomId = chatRoomCtx?.chatRoomId;
  const { kick, loading } = useKickFromChatRoom(chatRoomId, user);
  const removeMicrobot = useRemoveMicrobotFromConversation(chatRoomId, user);

  const onViewProfile = () => {
    if (Role.isAdvisor(user)) {
      navigate(`/chat/${chatRoomId}/advisor/${user.id}`);
      return;
    }

    if (Role.isStudent(user)) {
      navigate(`/chat/${chatRoomId}/user/${user.id}`);
      return;
    }

    if (Role.isMicrobot(user)) {
      navigate(`/community/microbot/${user.personality.toLocaleLowerCase()}`);
    }
  };

  const onViewStudentJourneyProfile = () => {
    navigate(`journey/student-profile`);
  };

  return (
    <ContextMenu.Root>
      <PermissionGate scope={Scope.canViewUserProfileScope(user)}>
        {() => (
          <ContextMenu.Item
            iconName="Show"
            label={t('common:view-profile')}
            onPress={onViewProfile}
          />
        )}
      </PermissionGate>
      <PermissionGate scope={Scope.hasStudentProfileInUserMenuScope(user)}>
        {() => (
          <ContextMenu.Item
            iconName="Show"
            label={t('task-organiser:customer-profile')}
            onPress={onViewStudentJourneyProfile}
          />
        )}
      </PermissionGate>
      <PermissionGate
        scope={
          chatRoomId
            ? canKickFromChatRoomScope({ chatRoomId, user })
            : Scope.unavailable
        }
      >
        {() => (
          <ContextMenu.Item
            iconName="Disconnect"
            label={t('common:kick')}
            onPress={kick}
            color={Colors.roseSecondaryDark}
            disabled={loading}
          />
        )}
      </PermissionGate>
      <PermissionGate scope={canRemoveMicrobotScope(user)}>
        {() => (
          <ContextMenu.Item
            iconName="Remove"
            destructive
            label={t('microbot:remove-from-conversation')}
            onPress={removeMicrobot}
            color={Colors.roseSecondaryDark}
          />
        )}
      </PermissionGate>
    </ContextMenu.Root>
  );
};

export default UserListMenu;
