import React, { memo } from 'react';

import { useTheme } from '@advisor/design/Theme';
import { SvgImageProps } from './types';

const PlaneFirstType: React.FC<SvgImageProps> = (props) => {
  const { className } = props;
  const theme = useTheme();

  return (
    <svg
      fill="none"
      width="617"
      height="658"
      viewBox="0 0 617 658"
      className={className}
    >
      <path d="M 507.031,233 617,157 439,207.976 Z" fill="url(#paint1)" />
      <path d="M 507,233.466 617,157 541.492,309 Z" fill="url(#paint2)" />
      <path
        stroke="#bcbec0"
        strokeDasharray="8, 8"
        d="m 504,236.712 c -90.698,71.835 -220.465,68.591 -251.628,-17.611 -31.163,-86.203 88.372,-87.593 44.186,17.611 C 228.221,399.419 58.419,368.333 4,233.004"
      />
      <defs id="defs89">
        <linearGradient
          id="paint1"
          x1="576"
          y1="195"
          x2="576"
          y2="271"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-48,-38)"
        >
          <stop stopColor={theme.colors.primaryLight} id="stop70" />
          <stop offset="1" stopColor={theme.colors.primaryDark} id="stop72" />
        </linearGradient>
        <linearGradient
          id="paint2"
          x1="610"
          y1="195"
          x2="610"
          y2="347"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-48,-38)"
        >
          <stop stopColor="#BCBEC0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(PlaneFirstType);
