import React, { PropsWithChildren, useState } from 'react';
import { useMolecule } from 'bunshi/react';
import { useSetAtom } from 'jotai';

import { useUserMetadata } from '@advisor/api/userMetadata';
import { DeprecatedButton } from '@advisor/design/components';
import { useEvent } from '@advisor/utils/hooks';
import Tooltip from '@advisor/design/components/Tooltip';
import AddMicrobotMemoryMolecule from '../../api/addMicrobotMemoryMolecule';
import { MemoryBankTooltipLocation } from './metadata';
import AddMemoryModal from '../AddMemoryModal';
import TooltipContent from './TooltipContent';

const MemoryBankButton: React.FC = () => {
  const { openEditorAtom } = useMolecule(AddMicrobotMemoryMolecule);
  const openEditor = useSetAtom(openEditorAtom);

  return (
    <DeprecatedButton.Create
      onPress={openEditor}
      tooltip={<TooltipWrapper onOpenModal={openEditor} />}
    />
  );
};

export default MemoryBankButton;

const TooltipWrapper: React.FC<
  PropsWithChildren<{ onOpenModal: () => void }>
> = ({ children, onOpenModal }) => {
  const [hideTooltip, setHideTooltip] = useUserMetadata(
    MemoryBankTooltipLocation,
  );

  const [isVisible, setIsVisible] = useState(!hideTooltip);

  const onCloseTooltip = useEvent(() => {
    setIsVisible(false);
    setHideTooltip(true);
  });

  const onTry = useEvent(() => {
    onCloseTooltip();
    onOpenModal();
  });

  return (
    <Tooltip.Root>
      {children}
      <Tooltip.Content
        className="max-w-[14.25rem]"
        elevate={children}
        maxWidth={228}
        showBackdrop
        variant="light"
        visible={isVisible}
        onClose={onCloseTooltip}
      >
        <TooltipContent onTry={onTry} />
      </Tooltip.Content>
      <AddMemoryModal />
    </Tooltip.Root>
  );
};
