/* eslint-disable no-param-reassign */
import { atomWithImmer } from 'jotai-immer';

import ifExists from '@advisor/utils/ifExists';
import { actionAtom } from '@advisor/utils/atoms';
import type { WithOptional } from '@advisor/utils/typeUtils';
import type { CallSession } from '../../types';
import type { TrackType } from './jellyfishClient';

type LocalSession = WithOptional<CallSession, 'sessionId'>;

export const localSessionAtom = atomWithImmer<LocalSession | null>(null);

export const updateLocalTrackAtom = actionAtom(
  ({ set }, trackType: TrackType, track: MediaStreamTrack | null) => {
    set(
      localSessionAtom,
      ifExists((draft) => {
        draft.tracks[trackType] = { track };
      }),
    );
  },
);
