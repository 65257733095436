import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Logo } from '@advisor/ui/Brand';
import LanguageDropDown from 'src/components/molecules/LanguageDropDown';
import LoginBgLogo from '../atoms/LoginBgLogo';
import BasicLayout from './BasicLayout';

type LoginLayoutProps = PropsWithChildren<unknown>;

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  const { t } = useTranslation('common');

  return (
    <BasicLayout>
      <div className="hidden md:flex h-20 bg-off-white border-b border-grey md:shadow-drop-01 px-5 lg:px-10 flex-row items-center">
        <div className="py-1 h-full w-[194px]">
          <Logo type="platform" fit="stretch" />
        </div>
        <div className="flex-1 flex flex-row justify-end gap-4">
          <LanguageDropDown />
          <p className="text-sm font-medium leading-6">{t('need-help')}</p>
        </div>
      </div>
      <div className="md:bg-[#F9F9F9] flex flex-col flex-1 items-center justify-between overflow-hidden">
        <div className="z-10">{children}</div>
        <div className="hidden md:flex py-10 max-w-[360px]">
          <Logo type="organization" fit="center" />
        </div>
        <LoginBgLogo />
      </div>
    </BasicLayout>
  );
};

export default LoginLayout;
