import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import cs from 'classnames';

import { Icon } from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';

type TextInputProps = {
  label: string;
  placeholder: string;
  value: string;
  onChange: (newValue: string) => void;
  hasErrors?: boolean;
  type?: HTMLInputTypeAttribute;
};

type ErrorMessageProps = {
  message: string;
};

export const TextInput: React.FC<TextInputProps> = ({
  onChange,
  label,
  placeholder,
  hasErrors,
  value,
  type = 'text',
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative">
      <span
        className={cs(
          'h-4 absolute top-4 ltr:left-4 rtl:right-4 font-outfit text-xs pointer-events-none',
          hasErrors ? 'text-negative' : 'text-dark-grey-02',
        )}
      >
        {label}
      </span>
      <input
        className={cs(
          'pt-8 pb-4 px-4 w-full focus:outline-none rounded border text-sm text-dark-grey-03 leading-[1.125rem] font-semibold placeholder:font-normal',
          hasErrors ? 'border-negative' : 'border-grey',
        )}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        data-cy={`createConversationInput${type}`}
      />
    </div>
  );
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => (
  <div className="flex items-center gap-2">
    <Icon name="Warning" color={Colors.negative} size={Spacing.small01} />
    <span
      className="text-negative text-xs font-outfit font-medium flex-1"
      data-cy="createConversationError"
    >
      {message}
    </span>
  </div>
);
