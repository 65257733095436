import React from 'react';
import { ScopeProvider } from 'bunshi/react';

import { useEvent } from '@advisor/utils/hooks';
import {
  useSetTriggerVisibility,
  useShowContextMenu,
} from './useContextMenuState';
import { ContextMenuScope } from './contextMenuMolecule';
import useOrCreateContextMenuScope from './useOrCreateContextMenuScope';
import type { ManagedMenuOwnerProps } from './types';

const ManagedMenuOwner = (props: ManagedMenuOwnerProps) => {
  const { children, className = 'relative', scope: explicitScope } = props;

  const scope = useOrCreateContextMenuScope(explicitScope);
  const setVisible = useSetTriggerVisibility(scope);
  const showMenu = useShowContextMenu(scope);

  const handleMouseEnter = useEvent(() => {
    setVisible(true);
  });

  const handleMouseLeave = useEvent(() => {
    setVisible(false);
  });

  const handleContextMenu = useEvent(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      showMenu();
    },
  );

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleContextMenu}
    >
      <ScopeProvider scope={ContextMenuScope} value={scope}>
        {children}
      </ScopeProvider>
    </div>
  );
};

export default ManagedMenuOwner;
