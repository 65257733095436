import cs from 'classnames';
import React, { memo } from 'react';
import { useAtomValue } from 'jotai';

import { PermissionGate, Scope } from '@advisor/api/scope';
import ChatRoomList from '../ChatRoomList';
import RoleRequestList from '../RoleRequestList/RoleRequestList';
import ConversationListHeader from './ConversationListHeader/ConversationListHeader';
import { selectedTabAtom } from './atoms';

const ConversationList: React.FC = () => {
  const selectedTab = useAtomValue(selectedTabAtom);

  // Note: min-h-0 is to prevent the elements from expanding to fit their content
  // which is basic behavior with `min-height: auto`.
  // transition elements and container div are need to be limited in height
  // to their max-parent height, so <FlatList /> implementation of "load more"
  // works correctly.
  return (
    <div className="flex flex-col flex-1 min-h-0 pt-6 overflow-x-hidden">
      <ConversationListHeader />
      <div
        className={cs(
          'w-[200%] flex items-start transition duration-200 overflow-y-hidden',
          selectedTab === 'chatRooms'
            ? 'translate-x-0'
            : '-translate-x-1/2 rtl:translate-x-1/2',
        )}
      >
        <div className="w-1/2 h-full max-h-full">
          <ChatRoomList />
        </div>
        <PermissionGate scope={Scope.approvingAdvisor}>
          {() => (
            <div className="w-1/2 h-full max-h-full">
              <RoleRequestList />
            </div>
          )}
        </PermissionGate>
      </div>
    </div>
  );
};

export default memo(ConversationList);
