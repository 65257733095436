import React, { memo, useCallback } from 'react';

import { SpinnerIcon } from '../Icon';
import Checkbox from '../Checkbox';
import type { BareButtonRenderProps } from '../DeprecatedButton/types';
import type { SegmentedGroupCheckboxProps } from './types';
import SegmentedGroupButton from './SegmentedGroupButton';

function SegmentedGroupCheckbox(props: SegmentedGroupCheckboxProps) {
  const { isSelected, forceLoading, onChange, ...buttonProps } = props;

  const onChangeWrapper = useCallback(
    () => onChange(!isSelected),
    [isSelected, onChange],
  );

  const icon = useCallback(
    ({ loading }: BareButtonRenderProps) =>
      loading ? (
        <SpinnerIcon className="w-6 h-6 text-dark-grey-03" />
      ) : (
        <Checkbox checked={isSelected} />
      ),
    [isSelected],
  );

  return (
    <SegmentedGroupButton
      {...buttonProps}
      forceLoading={forceLoading}
      onPress={onChangeWrapper}
      appendix={icon}
    />
  );
}

export default memo(SegmentedGroupCheckbox);
