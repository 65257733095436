import { useTranslation } from 'react-i18next';

import { Role } from '@advisor/api/user';
import { useEvent } from '@advisor/utils/hooks';
import { showToast } from '@advisor/design/components/Toast';
import { UserInfoFragment } from '@advisor/api/generated/graphql';
import { useShowModal } from '@advisor/design/components/ActionModal';
import useUpdateMicrobotMembership from './useUpdateMicrobotMembership';
import useMicrobot from './useMicrobot';

const useRemoveMicrobotFromConversation = (
  chatRoomId: string | undefined,
  user: UserInfoFragment,
) => {
  const { t } = useTranslation(['common', 'microbot']);
  const showModal = useShowModal();

  const personality = Role.isMicrobot(user) ? user.personality : undefined;
  const updateMicrobotMembership = useUpdateMicrobotMembership(personality);
  const microbot = useMicrobot(personality);

  return useEvent(async () => {
    if (!microbot || !chatRoomId) {
      return;
    }

    const { name } = microbot;

    const confirm = await showModal.confirm({
      variant: 'severe',
      title: t(
        'microbot:are-you-sure-you-want-to-remove-name-from-the-conversation',
        {
          name,
        },
      ),
      message: t(
        'microbot:name-will-no-longer-be-a-part-of-this-conversation',
        {
          name,
        },
      ),
      confirmActionLabel: t('common:confirm'),
    });

    if (!confirm) {
      return;
    }

    const result = await updateMicrobotMembership([], [chatRoomId]);

    if (result) {
      showToast({
        variant: 'rose',
        iconName: 'CircleCheck',
        namespace: 'microbot',
        messageI18Key: 'selected-member-was-successfully-removed',
      });
    }
  });
};

export default useRemoveMicrobotFromConversation;
