import { useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { useAmIStudent } from '@advisor/api/me';
import OnboardingMessage from '@advisor/design/components/OnboardingMessage';
import useResizeObserver from '@advisor/utils/hooks/useResizeObserver';
import { StudentOnboardingMessageProps } from './types';
import { onboardingMessageHeightAtom } from './atoms';
import StartChatButton from './StartChatButton';
import StudentAvatars from './StudentAvatars';

const StudentOnboardingMessage = (props: StudentOnboardingMessageProps) => {
  const { showStartChatButton, scrollToBottom, navigateToNewChat } = props;

  const amIStudent = useAmIStudent();
  const { t } = useTranslation('onboarding');
  const setMessageHeight = useSetAtom(onboardingMessageHeightAtom);
  const containerRef = useRef<HTMLDivElement>(null);

  useResizeObserver(
    containerRef,
    useCallback(
      ({ contentRect }) => setMessageHeight(contentRect.height),
      [setMessageHeight],
    ),
  );

  useEffect(() => {
    // Set message height to 0 when message is not mounted.
    return () => setMessageHeight(0);
  }, [setMessageHeight]);

  if (!amIStudent) {
    return null;
  }

  return (
    <div ref={containerRef} className="mb-4 max-w-chat-width mx-auto">
      <OnboardingMessage.Root border={false}>
        <OnboardingMessage.Title>
          {t('customer-onboarding-message-title')}
        </OnboardingMessage.Title>
        <OnboardingMessage.SubTitle>
          {t('customer-onboarding-message-subtitle')}
        </OnboardingMessage.SubTitle>
        <OnboardingMessage.Description>
          {t('customer-onboarding-message-description-one')}
        </OnboardingMessage.Description>
        <StudentAvatars />
        <Trans
          t={t}
          i18nKey="customer-onboarding-message-description-two"
          parent={OnboardingMessage.Description}
          components={{ bold: <b> </b> }}
        />
        <StartChatButton
          show={showStartChatButton}
          scrollToBottom={scrollToBottom}
          navigateToNewChat={navigateToNewChat}
        />
      </OnboardingMessage.Root>
    </div>
  );
};

export default memo(StudentOnboardingMessage);
