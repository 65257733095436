/**
 * This allows us to add context to a specific exception while referring to the
 * actual cause via Sentry.
 */
class ComplexError extends Error {
  constructor(public message: string, public cause?: unknown) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ComplexError.prototype);
  }
}

export default ComplexError;
