import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { truncate } from 'lodash-es';

import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { Icon, Layout } from '@advisor/design/components';
import { Colors, Spacing } from '@advisor/design/styles';
import { useMicrobot } from '../../hooks';
import PrimaryContextModal from '../PrimaryContextModal';
import { PrimaryContextPreviewLength } from './utils';
import useMicrobotPermissions from './useMicrobotPermissions';
import ButtonRow from './ButtonRow';

const Astro: React.FC = () => {
  const { t } = useTranslation('microbot');

  const { primaryContext } = useMicrobot(MicrobotPersonality.Astro, 'required');
  const { editPermissions } = useMicrobotPermissions();

  const [contextPreviewModalVisible, setContextPreviewModalVisible] =
    useState(false);

  return (
    <>
      <div className="bg-primary-light rounded-10 border border-grey overflow-hidden">
        <div className="px-4 pt-4 pb-[0.375rem] bg bg-light-grey">
          <button
            type="button"
            onClick={() => setContextPreviewModalVisible(true)}
            className="flex items-center justify-between gap-4 w-full"
          >
            <p className="flex-1 font-outfit text-sm leading-[1.125rem] text-dark-grey-03 ltr:text-left rtl:text-right">
              {truncate(primaryContext, {
                length: PrimaryContextPreviewLength,
              })}
            </p>
            <Icon
              name="ChevronRight"
              size={Spacing.small01}
              color={Colors.darkGrey03}
            />
          </button>

          <Layout.Spacer.Vertical size="micro02" />

          <ButtonRow />
        </div>

        <div className="h-2.5 -mx-px bg-light-grey border border-t-0 border-grey rounded-b-10" />
        <div className="px-4 pt-[0.625rem] pb-4">
          <p className="font-outfit text-xs text-dark-grey-03">
            <Trans
              t={t}
              i18nKey={
                editPermissions
                  ? 'you-have-edit-access-for-this-primary-context'
                  : 'you-have-view-only-access-for-this-primary-context'
              }
              components={{
                bold: <span className="text-primary font-medium" />,
              }}
            />
          </p>
        </div>
      </div>

      <PrimaryContextModal
        visible={contextPreviewModalVisible}
        onClose={() => setContextPreviewModalVisible(false)}
      />
    </>
  );
};

export default Astro;
