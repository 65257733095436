import { useSetAtom } from 'jotai';
import { useMolecule } from 'bunshi/react';

import {
  useAiResponseStreamSubscription,
  useSuggestionFromAiGeneratedSubscription,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';
import { useAmIAdvisor } from '@advisor/api/me';
import MessageInputMolecule from './messageInputMolecule';
import { HookParams } from './types';

export default function useListenForSuggestions({ chatRoomId }: HookParams) {
  const amIAdvisor = useAmIAdvisor();
  const { appendToStreamBufferAtom, receiveSuggestionAtom } =
    useMolecule(MessageInputMolecule);
  const appendToStreamBuffer = useSetAtom(appendToStreamBufferAtom);
  const receiveSuggestion = useSetAtom(receiveSuggestionAtom);

  useAiResponseStreamSubscription({
    skip: !amIAdvisor, // only advisors should listen for ai streams
    variables: {
      chatRoomId,
    },
    onData: ({ data }) => {
      if (!data.data?.aiResponseStream) {
        return;
      }

      try {
        appendToStreamBuffer(data.data.aiResponseStream);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
  });

  useSuggestionFromAiGeneratedSubscription({
    skip: !amIAdvisor, // only advisors should listen for ai streams
    variables: {
      chatRoomId,
    },
    onData: ({ data }) => {
      const { suggestionFromAIGenerated } = data.data ?? {};

      if (!suggestionFromAIGenerated) {
        return;
      }

      try {
        receiveSuggestion(suggestionFromAIGenerated);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
  });
}
