import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useState } from 'react';

import { useLogout } from '@advisor/api/auth';
import { myAvatarUrlAtom } from '@advisor/api/me';
import { PermissionGate, Scope } from '@advisor/api/scope';
import {
  Avatar,
  Dropdown,
  SimpleDropdownItem,
} from '@advisor/design/components';
import {
  LanguageCode,
  LanguageMetadata,
  primaryLanguagesAtom,
  useLanguage,
} from '@advisor/language';
import { useCopyInviteLinks } from '@advisor/onboarding';
import {
  canInviteToAgencyScope,
  canInviteToThemselvesScope,
  logoutWidgetSlotScopes,
} from '@advisor/chat/UserPreferences';
import {
  BrandCustomizationModal,
  showBrandCustomizationModalAtom,
} from '@advisor/ui/GreyLabeling';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import CreateStudentModal from './modals/CreateStudentModal';

const LanguageOptions: React.FC = () => {
  const { uiLanguage, changeLanguage } = useLanguage();
  const primaryLanguages = useAtomValue(primaryLanguagesAtom);

  const onChangeLanguage = useCallback(
    (language: LanguageCode) => {
      changeLanguage(language);
    },
    [changeLanguage],
  );

  return (
    <>
      {primaryLanguages.map((langKey) => (
        <SimpleDropdownItem
          testID={`buttonLanguage${langKey.toUpperCase()}`}
          key={langKey}
          label={LanguageMetadata[langKey].native}
          active={uiLanguage === langKey}
          onPress={() => onChangeLanguage(langKey)}
        />
      ))}
    </>
  );
};

const AgencyInviteLinkButton: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const { onCopyAgencyInviteLink } = useCopyInviteLinks();

  return (
    <SimpleDropdownItem
      testID="inviteAgencyStudentButton"
      label={t('organization-invite-link')}
      onPress={onCopyAgencyInviteLink}
    />
  );
};

const PersonalInviteLinkButton: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const { onCopyPersonalInviteLink } = useCopyInviteLinks();

  return (
    <SimpleDropdownItem
      label={t('personal-invite-link')}
      onPress={onCopyPersonalInviteLink}
    />
  );
};

const UserDropDown: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'onboarding', 'brand-customization']);

  const myAvatarUrl = useAtomValue(myAvatarUrlAtom);
  const setShowSidebar = useSetAtom(showSidebarAtom);
  const logout = useLogout();

  const onEditProfile = () => {
    setShowSidebar(false);
    navigate('/edit-profile');
  };

  const setShowBrandCustomizationModal = useSetAtom(
    showBrandCustomizationModalAtom,
  );

  const canInviteToThemselves = useAtomValue(canInviteToThemselvesScope);
  const canInviteToAgency = useAtomValue(canInviteToAgencyScope);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Dropdown.Root
        align="natural"
        trigger={
          <button
            type="button"
            className="block"
            data-cy="advisorAvatarDropDownButton"
          >
            <Avatar avatarUrl={myAvatarUrl} size={40} bordered={false} />
          </button>
        }
      >
        <Dropdown.Items className="w-56">
          <SimpleDropdownItem
            label={t('common:edit-profile')}
            onPress={onEditProfile}
            testID="editAdvisorProfileButton"
          />
          <SimpleDropdownItem
            testID="changeLanguageAdvisorButton"
            label={t('common:change-language')}
            submenu={() => (
              <Dropdown.Items>
                <LanguageOptions />
              </Dropdown.Items>
            )}
          />
          {(canInviteToThemselves || canInviteToAgency) && (
            <SimpleDropdownItem
              testID="invitePersonalStudentButton"
              label={t('onboarding:invite-to-platform')}
              submenu={() => (
                <Dropdown.Items>
                  {canInviteToThemselves && <PersonalInviteLinkButton />}
                  {canInviteToAgency && <AgencyInviteLinkButton />}
                </Dropdown.Items>
              )}
            />
          )}
          <PermissionGate scope={Scope.verifiedAdvisor}>
            {() => (
              <SimpleDropdownItem
                testID="createStudentConversationDropDown"
                label={t('onboarding:create-customer-conversation')}
                onPress={() => setShowModal(true)}
              />
            )}
          </PermissionGate>

          <PermissionGate scope={Scope.canEditCustomBrandingScope}>
            {() => (
              <SimpleDropdownItem
                testID="brandCustomizationDropDown"
                label={t('brand-customization:custom-branding')}
                onPress={() => setShowBrandCustomizationModal(true)}
              />
            )}
          </PermissionGate>

          <PermissionGate scope={logoutWidgetSlotScopes('sidebar-menu')}>
            {() => (
              <SimpleDropdownItem
                label={t('onboarding:logout')}
                onPress={logout}
                testID="logoutAdvisorButton"
              />
            )}
          </PermissionGate>
        </Dropdown.Items>
      </Dropdown.Root>
      <CreateStudentModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      <PermissionGate scope={Scope.primaryAdvisor}>
        {() => <BrandCustomizationModal />}
      </PermissionGate>
    </>
  );
};

export default UserDropDown;
