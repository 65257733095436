import React, { memo } from 'react';

import { Spacing } from '@advisor/design/styles';
import type { SpacerProps } from './types';

const Horizontal = ({ size }: SpacerProps) => {
  if (size === undefined) {
    return <div className="flex-1 ml-1" />;
  }

  return (
    <div
      style={{
        marginLeft: `${typeof size === 'number' ? size : Spacing[size]}px`,
      }}
    />
  );
};

const Vertical = ({ size }: SpacerProps) => {
  if (size === undefined) {
    return <div className="flex-1 h-1" />;
  }

  return (
    <div
      style={{
        height: `${typeof size === 'number' ? size : Spacing[size]}px`,
      }}
    />
  );
};

const Spacer = {
  Horizontal: memo(Horizontal),
  Vertical: memo(Vertical),
};

export default Spacer;
