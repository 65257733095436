import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import {
  ChatRoomWithMembersFragment,
  MicrobotPersonality,
} from '@advisor/api/generated/graphql';
import { Member, useChatRoomsByTypename } from '@advisor/api/chatRoom';
import useUpdateMicrobotMembership from '../../hooks/useUpdateMicrobotMembership';
import chatRoomStatusAtom, {
  ChatRoomStatusState,
} from './atoms/chatRoomStatus';
import searchAtom from './atoms/searchAtom';
import { Tab } from './types';

type Params = {
  personality: MicrobotPersonality;
  visible?: boolean;
};

const useAccessModal = ({ personality, visible }: Params) => {
  const [tab, setTab] = useState<Tab>(Tab.ConversationAccess);

  const search = useAtomValue(searchAtom);
  const { chatRooms } = useChatRoomsByTypename('StudentChatRoom', search);

  const [allChatRooms, setAllChatRooms] = useState<
    ChatRoomWithMembersFragment[]
  >([]);

  const [statusAtom, setStatusAtom] = useAtom(chatRoomStatusAtom);

  const updateMicrobotMembership = useUpdateMicrobotMembership(personality);

  // Reset tab on modal open
  useEffect(() => {
    if (visible) {
      setTab(Tab.ConversationAccess);
    }
  }, [visible]);

  // Update `allChatRooms` when chat rooms change
  useEffect(() => {
    if (search === '') {
      setAllChatRooms(chatRooms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRooms]);

  // Update state when the microbot personality or the modal visiblity changes
  useEffect(() => {
    if (visible) {
      const state: ChatRoomStatusState = {};

      chatRooms.forEach((chatRoom) => {
        const personalities = Member.microbots(chatRoom).map(
          (microbot) => microbot.personality,
        );

        state[chatRoom.id] = personalities.includes(personality);
      });

      setStatusAtom(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personality, setStatusAtom, visible]);

  // Update state when new chat rooms are fetched
  useEffect(() => {
    const state: ChatRoomStatusState = {};

    chatRooms.forEach((chatRoom) => {
      const personalities = Member.microbots(chatRoom).map(
        (microbot) => microbot.personality,
      );

      state[chatRoom.id] = personalities.includes(personality);
    });

    if (search === '') {
      setStatusAtom((prev) => ({ ...prev, ...state }));
    } else {
      setStatusAtom((prev) => ({ ...state, ...prev }));
    }
  }, [chatRooms, personality, search, setStatusAtom]);

  const anyRemoved = useMemo(
    () =>
      allChatRooms.some((chatRoom) => {
        const personalities = Member.microbots(chatRoom).map(
          (microbot) => microbot.personality,
        );

        return personalities.includes(personality) && !statusAtom[chatRoom.id];
      }),
    [allChatRooms, personality, statusAtom],
  );

  const setAll = useCallback(
    (value: boolean) => {
      const state: ChatRoomStatusState = {};

      chatRooms.forEach((chatRoom) => {
        state[chatRoom.id] = value;
      });

      setStatusAtom(state);
    },
    [chatRooms, setStatusAtom],
  );

  const getDiff = useCallback(
    (state: ChatRoomStatusState) => {
      const toInvite: string[] = [];
      const toRemove: string[] = [];

      allChatRooms.forEach((chatRoom) => {
        const { id } = chatRoom;

        const personalities = Member.microbots(chatRoom).map(
          (microbot) => microbot.personality,
        );

        const containsPersonality = personalities.includes(personality);

        if (!containsPersonality && state[id]) {
          toInvite.push(id);
        }

        if (containsPersonality && !state[id]) {
          toRemove.push(id);
        }
      });

      return { toInvite, toRemove };
    },
    [allChatRooms, personality],
  );

  const onSave = useCallback(
    async (state: ChatRoomStatusState, confirm?: boolean) => {
      const { toInvite, toRemove } = getDiff(state);

      if (toRemove.length && !confirm) {
        setTab(Tab.Confirm);
      } else if (toRemove.length && confirm) {
        await updateMicrobotMembership(toInvite, toRemove);
      } else if (toInvite.length) {
        await updateMicrobotMembership(toInvite, toRemove);
        setTab(Tab.Configure);
      }
    },
    [getDiff, setTab, updateMicrobotMembership],
  );

  return {
    anyRemoved,
    onSave,
    setAll,
    tab,
  };
};

export default useAccessModal;
