export interface GQLError {
  message: string;
  graphQLErrors: {
    data: unknown;
    errorInfo: unknown;
    errorType?: string;
    message: string;
    path: string[];
  }[];
}

export interface ErrorWithMessage {
  message?: string;
}

export function parseAnyError(e: unknown) {
  if (!e) {
    return '';
  }

  const error = e as ErrorWithMessage;

  return error.message ?? '';
}

export function parseGQLError(e: unknown) {
  const error = e as GQLError;

  if (!error.graphQLErrors || !error.graphQLErrors.length) {
    return error.message;
  }

  const gqlError = error.graphQLErrors.find((gqle) => !!gqle.errorType);

  if (!gqlError?.errorType) {
    return error.message;
  }

  return gqlError.errorType;
}
