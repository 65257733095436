import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconName } from '@advisor/design/components/Icon';
import { Icon } from '@advisor/design/components';
import Page from '../../components/Page';

interface Props {
  onGoBack: () => void;
  name?: string;
  iconName?: IconName;
  bgLight: string;
  bgDark: string;
}

const MicrobotPageHeader: React.FC<Props> = ({
  name,
  iconName,
  onGoBack,
  bgLight,
  bgDark,
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div
        className="xl:hidden h-32 flex rtl:flex-row-reverse items-end justify-between rounded-b-3xl inset-x-0"
        style={{ backgroundColor: bgLight }}
      >
        <div
          className="h-[85%] w-5/12 rounded-tr-[3rem] rounded-bl-3xl"
          style={{ backgroundColor: bgDark }}
        />
        <div
          className="h-[47.5%] w-1/3 rounded-tl-[3rem] rounded-br-3xl"
          style={{ backgroundColor: bgDark }}
        />
      </div>
      <Page.Header
        onGoBack={onGoBack}
        transparent
        className="absolute top-0 xl:bg-white xl:border-b xl:border-grey xl:static"
      >
        <div className="hidden xl:block">
          <div
            className="font-sora text-sm font-semibold relative text-dark-grey-03 leading-5 inline"
            style={{ letterSpacing: '-0.28px' }}
          >
            {name}
            <Icon
              size={12}
              name={iconName ?? 'StarsSup'}
              className="absolute top-0 -right-3"
            />
          </div>
          <div className="font-outfit text-sm font-medium text-dark-grey-025 leading-4">
            {t('profile')}
          </div>
        </div>
      </Page.Header>
    </>
  );
};

export default MicrobotPageHeader;
