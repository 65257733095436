import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, memo, useCallback } from 'react';

import Icon from '../Icon';
import { TextInput } from '../Form/components';
import { SearchInputProps } from './types';

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { value, onChange, placeholder, className } = props;
  const { t } = useTranslation('common');

  const onChangeWrapper = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className="relative rounded-[11px] border border-grey">
      <div className="absolute top-0 ltr:left-3 bottom-0 rtl:right-3 flex justify-center items-center">
        <Icon name="Search" className="text-dark-grey-01" />
      </div>
      <TextInput
        testID="searchInput"
        placeholder={placeholder ?? t('search')}
        value={value}
        onChange={onChangeWrapper}
        className={cs(
          'ltr:pl-9 rtl:pr-9 rounded-[10px] placeholder-shown:text-grey text-dark-grey-03',
          className,
        )}
      />
    </div>
  );
};

export default memo(SearchInput);
