import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { LangOrToken } from './types';

/**
 * Useful for content that does not have a persistent `translationStatus` field on the backend,
 * for example default Student Journey items.
 */
const localTranslationStatusOverrideAtoms = atomFamily((_key: string) => {
  return atom<LangOrToken | null>(null);
});

export default localTranslationStatusOverrideAtoms;
