import { useCallback } from 'react';

import {
  MilestoneCategoryInfoFragment,
  useUpdateMilestoneCategoryMutation,
  UpdateMilestoneCategoryMutationOptions,
} from '@advisor/api/generated/graphql';
import Sentry from '@advisor/utils/Sentry';

const createMutationConfig = (
  oldCategory: MilestoneCategoryInfoFragment,
): UpdateMilestoneCategoryMutationOptions => ({
  optimisticResponse({ categoryId, name }) {
    return {
      __typename: 'Mutation',
      updateMilestoneCategory: {
        ...oldCategory,
        id: categoryId,
        name,
      },
    };
  },
});

function useUpdateCategory() {
  const [updateMilestoneCategory] = useUpdateMilestoneCategoryMutation();

  return useCallback(
    async (category: MilestoneCategoryInfoFragment, name: string) => {
      try {
        const result = await updateMilestoneCategory({
          ...createMutationConfig(category),
          variables: {
            categoryId: category.id,
            name,
          },
        });

        if (!!result.errors && result.errors.length > 0) {
          return false;
        }
      } catch (e) {
        Sentry.captureException(e);
        return false;
      }

      return true;
    },
    [updateMilestoneCategory],
  );
}

export default useUpdateCategory;
