import cs from 'classnames';
import React, { useCallback } from 'react';

import { SimpleDropdownItem, Icon } from '@advisor/design/components';
import { useHideContextMenu } from './useContextMenuState';
import { MenuItemProps } from './types';

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    label,
    color,
    textColor,
    iconName,
    onPress,
    disabled,
    destructive,
    testID,
  } = props;

  const hideMenu = useHideContextMenu();
  const handlePress = useCallback(() => {
    hideMenu();
    onPress();
  }, [onPress, hideMenu]);

  return (
    <SimpleDropdownItem
      onPress={handlePress}
      disabled={disabled}
      testID={testID}
      icon={<Icon name={iconName} color={color} />}
      label={
        <span
          className={cs('flex-1', destructive && 'text-rose-secondary-dark')}
          style={{ color: textColor }}
        >
          {label}
        </span>
      }
    />
  );
};

export default MenuItem;
