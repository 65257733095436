import { MicrobotPersonality } from '@advisor/api/generated/graphql';
import { Feature, useIsFeatureEnabled } from '@advisor/api/feature';
import { useMicrobot } from '../../hooks';

const useMicrobotPermissions = (
  personality: MicrobotPersonality = MicrobotPersonality.Astro,
) => {
  const microbotCustomization = useIsFeatureEnabled(
    Feature.MicrobotCustomization,
  );

  const { isPrimaryContextDefault } = useMicrobot(personality, 'required');

  return {
    editPermissions: microbotCustomization,
    resetPermissions: microbotCustomization && !isPrimaryContextDefault,
  };
};

export default useMicrobotPermissions;
