import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import cs from 'classnames';
import { Link } from 'react-router-dom';

import { DateFormatter } from '@advisor/language';
import showSidebarAtom from '@advisor/ui/atoms/showSidebarAtom';
import { Avatar, Icon, Layout } from '@advisor/design/components';
import { useActiveChatRoom } from '@advisor/api/chatRoom';
import { useLatestChatRoomActivity } from '../ChatRoomList/ChatRoomRow/utils';
import { RoleRequestRowProps } from './types';

const RoleRequestRow: React.FC<RoleRequestRowProps> = (props) => {
  const { roleRequest } = props;
  const { chatRoom, createdAt } = roleRequest;

  const { t } = useTranslation(['common', 'user-role']);

  const activeChatRoomCtx = useActiveChatRoom();
  const activity = useLatestChatRoomActivity(chatRoom);
  const setShowSidebar = useSetAtom(showSidebarAtom);

  const mainMember = chatRoom?.members?.find(
    (memberInfo) => memberInfo.member.name === chatRoom.name,
  );

  const chatRoomAvatar = mainMember?.member.avatarUrl;

  const isCurrentChatRoom = chatRoom.id === activeChatRoomCtx?.chatRoomId;

  const hideSidebar = useCallback(() => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  return (
    <Link
      to={`/chat/${chatRoom.id}`}
      className={cs(
        'flex flex-row px-4 border w-full',
        isCurrentChatRoom
          ? 'bg-light-grey border-grey rounded-md shadow-drop-01'
          : 'border-white',
      )}
      onClick={hideSidebar}
    >
      <div className="shrink-0 pt-4 pb-4 relative self-start">
        <Avatar size={32} className="border-0" avatarUrl={chatRoomAvatar} />
        {chatRoom?.isVideoChatActive && (
          <Icon
            name="VideoRoomFillOutline"
            className="absolute text-primary w-[1.125rem] h-[1.125rem] -right-1 bottom-0"
          />
        )}
      </div>
      <Layout.Spacer.Horizontal size="tiny" />
      <div
        className={cs(
          'flex-1 flex flex-row justify-between py-4 min-h-[3rem] min-w-0',
          !isCurrentChatRoom && 'border-b border-light-grey',
        )}
      >
        <div className="min-w-0">
          <p
            className={cs(
              'text-sm font-bold font-sora text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px] xl:max-w-[135px] 2xl:max-w-[230px]',
              isCurrentChatRoom ? 'text-primary-dark' : 'text-dark-grey-03',
            )}
          >
            {chatRoom?.name}
          </p>
          <p className="truncate text-sm text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px] xl:max-w-[135px] 2xl:max-w-[230px] text-primary">
            {t('user-role:host')}
          </p>
        </div>
      </div>
      <div className="shrink-0 flex flex-col items-end pt-3">
        <div className="flex flex-col items-end">
          <p
            className={cs(
              'font-sora text-10 leading-5',
              activity.unread ? 'text-dark-grey-03' : 'text-dark-grey-025',
              activity.unread || isCurrentChatRoom
                ? 'font-bold'
                : 'font-normal',
            )}
          >
            {createdAt ? DateFormatter.timeElapsed(createdAt) : ''}
          </p>
          <Layout.Spacer.Vertical size="atomic" />
          {activity.unread && (
            <span
              className={cs('bg-primary float-right w-2 h-2 rounded-full')}
              role="img"
              aria-label={t('unread-messages')}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default memo(RoleRequestRow);
