import { useState } from 'react';
import { useAtomValue } from 'jotai';

import { Spacing } from '@advisor/design/styles';
import { onboardingMessageHeightAtom } from './atoms';

/**
 * Use this hook to hide StudentOnboardingMessage under scroll view
 */
const useOnboardingMessageBumper = () => {
  const [viewHeight, setViewHeight] = useState(0);
  const onboardingMessageHeight = useAtomValue(onboardingMessageHeightAtom);

  // Don't hide onboarding message completely
  const croppedMessageHeight = Math.max(
    onboardingMessageHeight - Spacing.large,
    0,
  );

  // We want the user/app to be able to scroll past the onboarding message.
  const minScrollHeight = viewHeight + croppedMessageHeight;

  return {
    minScrollHeight,
    setViewHeight,
  };
};

export default useOnboardingMessageBumper;
