import { useState, useEffect } from 'react';

function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handler = () => {
      setScrollTop(
        Math.max(document.documentElement.scrollTop, document.body.scrollTop),
      );
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, [setScrollTop]);

  return scrollTop;
}

export default useScrollTop;
