import React from 'react';
import cs from 'classnames';
import { pipe } from 'remeda';

import type { RowProps } from './types';
import { withPadding } from '../../hoc';

function Row(props: RowProps) {
  const {
    children,
    className,
    natural = true,
    reversed,
    align = 'start',
    justify = 'start',
    ...restProps
  } = props;

  const directionClassName = (() => {
    if (natural) {
      return reversed ? 'flex-row-reverse' : 'flex-row';
    }

    return reversed
      ? 'ltr:flex-row-reverse rtl:flex-row'
      : 'ltr:flex-row rtl:flex-row-reverse';
  })();

  return (
    <div
      {...restProps}
      className={cs(
        'flex',
        directionClassName,
        className,
        {
          start: 'justify-start',
          center: 'justify-center',
          end: 'justify-end',
          'space-between': 'justify-between',
        }[justify],
        {
          start: 'items-start',
          center: 'items-center',
          end: 'items-end',
          stretch: 'items-stretch',
        }[align],
      )}
    >
      {children}
    </div>
  );
}

export default pipe(Row, withPadding());
