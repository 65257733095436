import cs from 'classnames';
import React, { memo, useMemo } from 'react';

import Icon from '../Icon';
import { SimpleDropdownTriggerProps } from './types';

function SimpleDropdownTrigger(props: SimpleDropdownTriggerProps) {
  const { variant, open, isBusy, children } = props;

  const content = useMemo(() => {
    if (isBusy) {
      return <Icon name="Spinner" />;
    }

    if (open) {
      return (
        <Icon
          name="ChevronDown"
          className={cs(
            'w-6 h-6 scale-125',
            variant === 'light' ? 'text-dark-grey-02' : 'text-white',
          )}
        />
      );
    }

    if (children) {
      return children;
    }

    return (
      <Icon
        name="ChevronDown"
        className={cs(
          'w-6 h-6 -scale-125',
          variant === 'light' ? 'text-dark-grey-02' : 'text-white',
        )}
      />
    );
  }, [variant, isBusy, open, children]);

  return (
    <span
      className={cs(
        'w-6 h-6 flex items-center justify-center',
        variant === 'light' && open && 'bg-grey rounded',
      )}
    >
      {content}
    </span>
  );
}

export default memo(SimpleDropdownTrigger);
