import React, { memo } from 'react';

import { useUserMetadata } from '@advisor/api/userMetadata';
import { OnboardingMessageProps } from './types';
import { Root } from './MessageComponents';

const OnboardingMessage = (props: OnboardingMessageProps) => {
  const { border, userMetadataLocation, children, testID } = props;

  if (userMetadataLocation) {
    return (
      <ClosableOnboardingMessage
        {...props}
        userMetadataLocation={userMetadataLocation}
      />
    );
  }

  return (
    <Root testID={testID} border={border}>
      {children}
    </Root>
  );
};

type ClosableProps = OnboardingMessageProps &
  Required<Pick<OnboardingMessageProps, 'userMetadataLocation'>>;

const ClosableOnboardingMessage = (props: ClosableProps) => {
  const { border, userMetadataLocation, children, testID } = props;

  const [userMetadata, setUserMetadata] = useUserMetadata(userMetadataLocation);

  const onClose = () => {
    setUserMetadata(true);
  };

  if (userMetadata) {
    return null;
  }

  return (
    <Root onClose={onClose} testID={testID} border={border}>
      {children}
    </Root>
  );
};

export default memo(OnboardingMessage);
