import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '@advisor/design/components';
import {
  editPrimaryContextAtom,
  limitExceededAtom,
} from '../../atoms/editPrimaryContextAtom';
import { useMicrobot } from '../../hooks';
import EditTab from './EditTab';
import GuideTab from './GuideTab';
import ConfirmTab from './ConfirmTab';
import { EditContextModalProps, Tab } from './types';

const EditContextModal: React.FC<EditContextModalProps> = ({
  personality,
  visible,
  onClose,
}) => {
  const [tab, setTab] = useState<Tab>(Tab.Edit);

  const [state, setState] = useAtom(editPrimaryContextAtom);
  const limitExceeded = useAtomValue(limitExceededAtom);

  const microbot = useMicrobot(personality, 'required');
  const { avatarUrl, name, createdAt, updatedAt, communityTitle } = microbot;

  // Reset the active tab on modal open
  useEffect(() => {
    if (visible) {
      setTab(Tab.Edit);
    }
  }, [visible]);

  const renderTab = useCallback(
    (activeTab: Tab) => {
      if (activeTab === Tab.Edit) {
        return (
          <EditTab
            avatarUrl={avatarUrl}
            lastUpdated={updatedAt ?? createdAt}
            limitExceeded={limitExceeded}
            microbotName={name}
            primaryContext={state?.primaryContext ?? ''}
            onSave={state?.changesMade ? () => setTab(Tab.Confirm) : onClose}
            onSetPrimaryContext={(value) => setState({ type: 'update', value })}
            onViewGuide={() => setTab(Tab.Guide)}
          />
        );
      }

      if (activeTab === Tab.Guide) {
        return <GuideTab onGoBack={() => setTab(Tab.Edit)} />;
      }

      return (
        <ConfirmTab
          microbotName={name}
          communityTitle={communityTitle}
          onClose={onClose}
          onSave={() => setState({ type: 'submit' })}
        />
      );
    },
    [
      avatarUrl,
      createdAt,
      updatedAt,
      name,
      communityTitle,
      onClose,
      setState,
      limitExceeded,
      state?.primaryContext,
      state?.changesMade,
    ],
  );

  return (
    <Modal.Base
      position={tab === Tab.Confirm ? 'floating' : 'responsive'}
      maxWidth={{ [Tab.Edit]: 675, [Tab.Guide]: 675, [Tab.Confirm]: 326 }[tab]}
      maxHeight={tab === Tab.Edit ? 612 : undefined}
      visible={visible}
      onClose={onClose}
    >
      {renderTab(tab)}
    </Modal.Base>
  );
};

export default EditContextModal;
