import { atom } from 'jotai';
import { mapValues, pick } from 'lodash-es';
import { atomWithObservable } from 'jotai/utils';

import Sentry from '@advisor/utils/Sentry';
import ComplexError from '@advisor/utils/ComplexError';
import { nonNullable } from '@advisor/utils/typeUtils';
import { type CallSession, SessionMetadata } from '../types';
import subscribeToCallEvent from './subscribeToCallEvent';
import type { DailyEvent } from './LibProvider';
import { callObjectAtom } from './dailyState';
import type { DailySession } from './types';

type Observer<T> = {
  next: (value: T) => void;
  error: (error: unknown) => void;
  complete: () => void;
};

const ParticipantEvent = [
  'participant-joined',
  'participant-updated',
  'participant-left',
  // reset participant atom on join and disconnect events
  'joined-meeting',
  'left-meeting',
  'error',
] satisfies DailyEvent[];

const noopObservable = {
  subscribe() {
    return {
      unsubscribe: () => {
        // noop
      },
    };
  },
};

const dailySessionsAtom = atomWithObservable<Record<string, DailySession>>(
  (get) => {
    const call = get(callObjectAtom);

    if (!call) {
      return noopObservable;
    }

    return {
      subscribe(observer: Observer<Record<string, DailySession>>) {
        const onUpdateParticipants = () => {
          // Daily updates existing participants object
          // which results in jotai not picking up the change
          observer.next({ ...call.participants() });
        };

        // Load initial participants
        onUpdateParticipants();

        const unsubscribeList = ParticipantEvent.map((eventName) =>
          subscribeToCallEvent(call, eventName, onUpdateParticipants),
        );

        return {
          unsubscribe: () =>
            unsubscribeList.forEach((unsubscribe) => unsubscribe()),
        };
      },
    };
  },
  {
    initialValue: {},
  },
);

export const localSessionAtom = atom((get) => {
  const sessions = get(dailySessionsAtom);
  return sessions.local as DailySession | undefined;
});

/** Daily sessions mapped into the generic session type */
export const sessionsAtom = atom((get) => {
  const sessions = get(dailySessionsAtom);

  const genericSessions = Object.values(sessions)
    .map(dailySessionToGeneric)
    .filter(nonNullable)
    .map((s) => [s.sessionId, s]);

  return Object.fromEntries(genericSessions) as Record<string, CallSession>;
});

export const activeParticipantIdAtom = atomWithObservable<string | undefined>(
  (get) => {
    const call = get(callObjectAtom);

    if (!call) {
      return noopObservable;
    }

    return {
      subscribe(observer: Observer<string | undefined>) {
        const onUpdateActiveSpeaker = () => {
          try {
            observer.next(call?.getActiveSpeaker().peerId ?? undefined);
          } catch (err) {
            // The `getActiveSpeaker()` call is not supported on some platforms.
            observer.next(undefined);
          }
        };

        // Load initial active speaker
        onUpdateActiveSpeaker();

        return {
          unsubscribe: subscribeToCallEvent(
            call,
            'active-speaker-change',
            onUpdateActiveSpeaker,
          ),
        };
      },
    };
  },
  { initialValue: undefined },
);

export function dailySessionToGeneric(
  session: DailySession,
): CallSession | null {
  const { userData } = session;
  const result = SessionMetadata.safeParse(
    typeof userData === 'string' ? JSON.parse(userData) : userData,
  );

  if (!result.success) {
    // Invalid session metadata
    Sentry.captureException(
      new ComplexError(
        'Invalid call session metadata (Daily.js)',
        result.error,
      ),
    );
    return null;
  }

  return {
    sessionId: session.session_id,
    isLocal: session.local,
    metadata: result.data,
    tracks: mapValues(
      pick(session.tracks, ['video', 'audio', 'screenVideo', 'screenAudio']),
      (track) => {
        if (
          !track.off &&
          track.persistentTrack &&
          (track.state === 'playable' || track.state === 'sendable')
        ) {
          return {
            track: track.persistentTrack,
          };
        }

        return {
          track: null,
        };
      },
    ),
  };
}
