import dayjs from 'dayjs';
import React, { memo, useCallback, useState } from 'react';

import Month from './Month';
import { DatePickerProps } from './types';
import { getNextMonth, getPrevMonth } from './utils';

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { minDate, selectedDate, onDateClick } = props;

  const [month, setMonth] = useState(() => {
    if (selectedDate) {
      return selectedDate.startOf('month');
    }
    if (minDate) {
      return minDate.startOf('month');
    }
    return dayjs().startOf('month');
  });

  const onNextPress = useCallback(() => {
    setMonth((prev) => getNextMonth(prev));
  }, []);

  const onPrevPress = useCallback(() => {
    setMonth((prev) => getPrevMonth(prev));
  }, []);

  return (
    <div className="border border-grey rounded-lg p-4">
      <Month
        month={month}
        minDate={minDate}
        selectedDate={selectedDate}
        onDateClick={onDateClick}
        onNextPress={onNextPress}
        onPrevPress={onPrevPress}
      />
    </div>
  );
};

export default memo(DatePicker);
