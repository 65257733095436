import cs from 'classnames';
import React, { CSSProperties } from 'react';

import { useTheme } from '../../Theme';
import { TextButtonProps } from './types';
import { getBackgroundColor } from './utils';
import Bare from './Bare';

const TextButton = ({
  children,
  variant = 'primary',
  family = 'Outfit',
  size = 'sm',
  tall = false,
  weight = 400,

  disabled,
  underline = false,
  style,
  ...rest
}: TextButtonProps) => {
  const theme = useTheme();

  return (
    <Bare
      {...rest}
      disabled={disabled}
      className={({ loading }) =>
        cs(
          'inline-flex items-center font-outfit text-sm ltr:text-left rtl:text-right',
          { Outfit: 'font-outfit', Sora: 'font-sora' }[family],
          { xs: 'text-xs', sm: 'text-sm', base: 'text-base' }[size],
          tall && 'leading-5',
          underline && 'underline',
          disabled && 'opacity-40',
          !disabled && loading && 'opacity-50',
          !disabled && !loading && 'active:opacity-80',
        )
      }
      style={{
        ...((style as CSSProperties) ?? {}),
        color: getBackgroundColor(theme, variant),
        fontWeight: weight,
      }}
    >
      {children}
    </Bare>
  );
};

export default TextButton;
